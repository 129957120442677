import LinkButton from './LinkButton'
import React, { StatelessComponent } from 'react'

interface IPdfLinkButtonProps {
  caption: string
  href: string
  title?: string
}
type TProps = IPdfLinkButtonProps

const PdfLinkButton: StatelessComponent<TProps> = ({
  caption,
  href,
  title
}) => {
  return (
    <LinkButton
      caption={caption + ' (PDF)'}
      href={href}
      target='_blank'
      title={title}
    />
  )
}

export default PdfLinkButton
