import { ContractState, SettlementPaymentType } from '@omnicar/sam-types'
import {
  initBalanceStatistics,
  TInitBalanceStatistics,
  TUpdateSettleActions,
  updateSettleActionsThunk,
} from 'actions/contractSettlement'
import { getStripeSettlementPaymentProcessingStatus, postSettlementPayment, settleContract } from 'api/api'
import { FormikBag, withFormik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { t } from 'translations/translationFunctions'
import { bindDispatch } from 'utils/redux'
import * as Yup from 'yup'
import SettlementRegulationForm from './SettlementRegulationForm'

interface IOwnProps {
  customerName: string
  balance: number
  id: string
  isSettled: boolean
  settledUnits?: number | null
  calculationMethod?: number
  lockSettleTab: (locked: boolean) => void
  isRefund: boolean
  contractState?: ContractState
}

interface IReduxDispatchProps {
  initBalanceStatistics: TInitBalanceStatistics
  updateSettleActionsThunk: TUpdateSettleActions
}

interface ISettleFormValues {
  paymentAmount: number
  type: SettlementPaymentType
  comment: string
  shareComment: boolean
}

type TProps = IOwnProps & IReduxDispatchProps

const EnhancedSettlementRegulationForm = withFormik<TProps, ISettleFormValues>({
  mapPropsToValues: ({ balance, contractState }: IOwnProps) => {
    const chargeType =
      contractState === ContractState.CancelledSubscription
        ? SettlementPaymentType.cashPayment
        : SettlementPaymentType.stripePayment

    return {
      paymentAmount: Math.abs(balance),
      type: balance < 0 ? SettlementPaymentType.cashPayout : chargeType,
      comment: '',
      shareComment: false,
    }
  },
  validationSchema: (props: IOwnProps) =>
    Yup.object().shape({
      paymentAmount: Yup.number().required(t('Required field')).positive().min(1, t('Amount cannot be zero')),
    }),
  handleSubmit: async (values: ISettleFormValues, formikBag: FormikBag<TProps, ISettleFormValues>) => {
    const { setSubmitting, resetForm } = formikBag
    const { id, initBalanceStatistics, updateSettleActionsThunk, lockSettleTab } = formikBag.props
    setSubmitting(true)
    const res = await postSettlementPayment(id, values)
    if (res.data) {
      if (values.type === SettlementPaymentType.stripePayment) {
        const startTime = new Date().getTime()
        const stripeOperationsStatusPolling = async () => {
          // quit after 20 sec
          if (new Date().getTime() - startTime > 20000) {
            await Promise.all([initBalanceStatistics(id), updateSettleActionsThunk(id)])
            return lockSettleTab(false)
          }
          const response = await getStripeSettlementPaymentProcessingStatus(id)
          if (response && response.data) {
            lockSettleTab(true)
            setTimeout(stripeOperationsStatusPolling, 1500)
          } else {
            await settleContract(id, values.shareComment)
            window.location.reload(true) // (!) Parameter seems important, even though it is deprecated in the specs (at the moment).
            return lockSettleTab(false)
          }
        }
        return stripeOperationsStatusPolling()
      }
      await settleContract(id, values.shareComment)
      window.location.reload(true) // (!) Parameter seems important, even though it is deprecated in the specs (at the moment).
    }
    resetForm()
  },
  enableReinitialize: true,
  validateOnMount: true,
})((props: any) => <SettlementRegulationForm {...props} />)

export default compose<TProps, IOwnProps>(
  connect(null, bindDispatch({ initBalanceStatistics, updateSettleActionsThunk })),
)(EnhancedSettlementRegulationForm)
