import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ zIndex, spacing, breakpoints }: Theme) => {
  return createStyles({
    header: {
      position: 'relative',
      zIndex: zIndex.drawer + 1,
      backgroundColor: theme.palette.primary[500],
    },
    content: {
      display: 'flex',
    },
    main: {
      display: 'flex',
      paddingTop: spacing(8),
      paddingBottom: spacing(6),
      width: '100%',
      [breakpoints.up('sm')]: {
        paddingTop: spacing(15),
        paddingBottom: 0,
      },
    },
    aside: {
      display: 'none',
      overflowY: 'scroll',
      minHeight: '100vh',
      minWidth: '380px',
      paddingTop: spacing(15),
      borderRight: `1px solid ${theme.palette.border.light}`,
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      backgroundColor: theme.palette.background.light,
      [breakpoints.up('lg')]: {
        display: 'inherit ',
      },
    },
    cpBackground: {
      backgroundColor: 'white',
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tab: {
      height: '40px',
      paddingTop: '5px',
      paddingBottom: '5px',
      backgroundColor: theme.palette.primary[500],
    },
    activeTab: {
      border: '2px solid',
      borderColor: theme.palette.primary[500],
      backgroundColor: 'white',
    },
    minimumPaddingTopAside: {
      paddingTop: spacing(11),
    },
    minimumPaddingTopMain: {
      [breakpoints.up('sm')]: {
        paddingTop: spacing(12),
      },
    },
  })
}
