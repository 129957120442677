import React, { Component } from 'react'
import { WithStyles, withStyles, TextField, Typography } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import styles from './styles'
import { t } from 'translations/translationFunctions'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { IUserPasswordChangeRequest } from '@omnicar/sam-types'
import SpinnerButton from 'components/Mui/SpinnerButton'

interface IProps extends WithStyles<typeof styles> {
  onSubmit: (body: IUserPasswordChangeRequest) => void
}

class AccountSettingsPasswordForm extends Component<IProps> {
  public validationSchema = Yup.object().shape({
    current: Yup.string().required(t('Required field')),
    new: Yup.string()
      .min(6, t('Password must have at least %count characters', { count: 6 }))
      .matches(/(?=.*[a-z])(?=.*[A-Z])/, t('Password must contain at least 1 lower case and 1 upper case character'))
      .required(t('Password must have at least %count characters', { count: 6 })),
    confirm: Yup.string()
      .oneOf([Yup.ref('new')], t('Passwords do not match'))
      .required(t('Required field')),
  })

  public render() {
    const { classes, onSubmit } = this.props
    return (
      <Formik
        initialValues={{ current: '', new: '', confirm: '' }}
        validationSchema={this.validationSchema}
        // tslint:disable-next-line jsx-no-lambda
        onSubmit={async (values, { setSubmitting }) => {
          const body = {
            password: values.current,
            newPassword: values.new,
          }

          setSubmitting(true)
          await onSubmit(body)
          setSubmitting(false)
        }}
        enableReinitialize={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <>
              <Form className={classes.form}>
                <TextField
                  autoFocus={true}
                  name="current"
                  type="password"
                  label={t('Current password')}
                  className={classes.input}
                  value={values.current}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
                <Typography variant="body2" className={classes.inputError}>
                  {errors.current && touched.current && errors.current}
                </Typography>
                <TextField
                  name="new"
                  type="password"
                  label={t('New password')}
                  className={classes.input}
                  value={values.new}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
                <Typography variant="body2" className={classes.inputError}>
                  {errors.new && touched.new && errors.new}
                </Typography>
                <TextField
                  name="confirm"
                  type="password"
                  label={t('Re-type password')}
                  className={classes.input}
                  value={values.confirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                />
                <Typography variant="body2" className={classes.inputError}>
                  {errors.confirm && touched.confirm && errors.confirm}
                </Typography>
                <SpinnerButton
                  type="submit"
                  className={classes.btnSubmit}
                  variant="contained"
                  color="primary"
                  IconComponent={CheckIcon}
                  showSpinner={isSubmitting}
                >
                  {t('Change password')}
                </SpinnerButton>
              </Form>
            </>
          )
        }}
      </Formik>
    )
  }
}
export default withStyles(styles)(AccountSettingsPasswordForm)
