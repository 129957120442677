import convert from 'color-convert'
import materialpalette from 'material-palette'

export const createMuiPalette = (hex: string) => {
  const hslSource = convert.hex.hsl(hex)
  const hslPalette = materialpalette({ h: hslSource[0], s: hslSource[1], l: hslSource[2] })
  const hexPalette: IMuiPalette | {} = {}

  Object.keys(hslPalette).forEach((key: string | number) => {
    const hsl = hslPalette[key]

    // If lightness is 100% (#FFF) -> turn it down to 98%!
    hexPalette[key] = `#${convert.hsl.hex([hsl.h, hsl.s, hsl.l === 100 ? 98 : hsl.l])}`
  })

  return hexPalette as IMuiPalette
}

const labelColor = '#215557'
const buttonColor = '#198e98' // Button color

// Base colors values
const primary = labelColor
const secondary = buttonColor
const activeContract = '#5bb5c2'
const attention = '#ff8c00'
const warning = '#A13D42'
const freeContract = '#7cc8d0'
const dealerPaid = '#FF00FF'
const accept = '#008000'
export const activeTab = '#0e7679'

export interface IMuiPalette {
  50: string
  100: string
  200: string
  300: string
  500: string
  600: string
  700: string
  800: string
  900: string
  A100: string
  A200: string
  A400: string
  A700: string
}

export interface IThemePaletteScale {
  lighter: string
  light: string
  regular: string
  dark?: string
  darker?: string
}

export interface IThemePaletteContext {
  warning: IMuiPalette
  attention: IMuiPalette
  accept: IMuiPalette
}

export interface IThemePalette {
  primary: IMuiPalette
  secondary: IMuiPalette
  error: IMuiPalette
  freeContract: IMuiPalette
  dealerPaid: IMuiPalette
  context: IThemePaletteContext
  background: IThemePaletteScale
  border: IThemePaletteScale
  text: IThemePaletteScale
  contractTypes: {
    productContract: IMuiPalette
    vehicleContract: IMuiPalette
  }
  contractStates: {
    offer: IMuiPalette
    offerExpired: IMuiPalette
    active: IMuiPalette
    paymentFailed: IMuiPalette
    contractTerminated: IMuiPalette
    contractExpired: IMuiPalette
    contractSettled: IMuiPalette
    cancelledSubsctiption: IMuiPalette
  }
  gradients: any
}

export const palette: IThemePalette = {
  primary: createMuiPalette(primary),
  secondary: createMuiPalette(secondary),
  error: createMuiPalette('#c61b38'),
  freeContract: createMuiPalette(freeContract),
  dealerPaid: createMuiPalette(dealerPaid),
  context: {
    attention: createMuiPalette(attention),
    warning: createMuiPalette(warning),
    accept: createMuiPalette(accept),
  },
  background: { lighter: 'rgba(39,62,98,0.05)', light: '#F4F5F7', regular: '#EEEEEE', dark: '', darker: '' },
  border: { lighter: 'rgba(39,62,98,0.05)', light: '#DEDEDE', regular: '', dark: '', darker: '' },
  text: {
    lighter: '#CBCBCB',
    light: '#7B7B7B',
    regular: '#2a2b28',
    dark: '#1c1b1b',
    darker: '#111111',
  },
  contractTypes: {
    vehicleContract: createMuiPalette('#2C5CB0'),
    productContract: createMuiPalette('#2C5CB0'),
  },
  contractStates: {
    offer: createMuiPalette('#061630'),
    offerExpired: createMuiPalette('#607392'),
    active: createMuiPalette(activeContract),
    paymentFailed: createMuiPalette('#b71c1c'),
    cancelledSubsctiption: createMuiPalette('#b71c1c'),
    contractTerminated: createMuiPalette(attention),
    contractExpired: createMuiPalette(warning),
    contractSettled: createMuiPalette(primary),
  },
  gradients: {
    secondary: {
      backgroundImage: 'linear-gradient(-180deg, #fff 80%, #FAFAFA 98%)',
    },
  },
}
