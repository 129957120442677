import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import { FormControl, FormLabel, FormHelperText /*, TextField*/ } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { t } from 'translations/translationFunctions'
import { IVehicleTypeOption, IFueltypeOption as IOption } from '../types'
import { defaultDateFormat } from 'utils/date'
import DatePicker from 'components/DatePicker'
import moment from 'moment-timezone'
import { theme, theme as ourTheme } from 'theme'
import StyledSelect from 'components/StyledSelect'

export const padString = (str: string | undefined): string => {
  return ' ' + str + ' '
}

export type TFieldType = 'options' | 'date' | 'string'

// Whether to also hide the selector/datepick when it is disabled.
const HIDE_WHEN_DISABLED: boolean = true

interface IProps {
  fieldType?: TFieldType
  inputLabel: string
  optionsLabel?: string
  placeholder?: string
  e2eTestAttribute?: string
  inputValue: string
  prefilledValue?: string
  defaultValue?: string
  options?: IOption[]
  onChange?: any
  error?: boolean // Whether to show helperText or not, prop named the same as in Material TextField.
  helperText?: string // The text to show when error is true, prop named the same as in Material TextField.
  translateCaption?: boolean
  isDisabled?: boolean // Whether to disable selector/datepick.
  strikePrefilledValue?: boolean
}

interface IState {
  prefilledValue: string
  defaultValue: string | undefined // Note: This is the currently selected option in the react-select.
  selectOptions: IVehicleTypeOption[]
  reactSelectCustomStyles: {
    weight: number
    color: string
  }
}

const HIGHLIGHT_FONT_WEIGHT = 500 // 400 is the same as normal, and 700 is the same as bold.
const UNHIGHLIGHT_FONT_WEIGHT = 300 // 400 is the same as normal, and 700 is the same as bold.

const HIGHLIGHT_FONT_COLOR = theme.palette.text.dark
const UNHIGHLIGHT_FONT_COLOR = theme.palette.text.regular

const ERROR_BG_COLOR = 'LightYellow'

const initialState: IState = {
  prefilledValue: '',
  defaultValue: undefined,
  selectOptions: [],
  reactSelectCustomStyles: {
    weight: HIGHLIGHT_FONT_WEIGHT,
    color: !HIGHLIGHT_FONT_COLOR ? '#111' : HIGHLIGHT_FONT_COLOR,
  },
}

type TProps = IProps & WithStyles<typeof styles>

// @TODO: Changable date not yet highlighted.
const styles = ({ spacing }: Theme) =>
  createStyles({
    formInnerContainer: {
      width: '100%',
    },
    formLabel: {
      fontSize: '0.8em',
      color: theme.palette.text.light,
      marginTop: spacing(-0.25),
    },
    formLabelForStringField: {
      paddingTop: spacing(2),
    },
    prefilledValue: {
      paddingBottom: spacing(1),
    },
    select: {
      width: '18vw',
      marginBottom: spacing(0.25),
      borderColor: 'hsl(0,0%,10%)',
      borderRadius: '4px',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    highlightFont: {
      fontWeight: HIGHLIGHT_FONT_WEIGHT,
      color: HIGHLIGHT_FONT_COLOR,
    },
    unhighlightFont: {
      fontWeight: UNHIGHLIGHT_FONT_WEIGHT,
      color: UNHIGHLIGHT_FONT_COLOR,
    },
    selectLabelContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    selectLabel: {
      textTransform: 'capitalize',
    },
    helperText: {
      width: '100%',
      textAlign: 'right',
      paddingRight: spacing(1.25),
      fontSize: '1em',
    },
    hidden: {
      display: 'none',
    },
    strike: {
      textDecoration: 'line-through',
    },
    ghost: {
      opacity: 0.65,
    },
    formLabelDate: {
      fontSize: '0.8em',
      color: theme.palette.text.light,
      marginBottom: '4px',
    },
    containerDate: {
      display: 'flex',
      width: '100%',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    dateLeft: {
      width: '50%',
      marginTop: '-1px',
    },
    dateRight: {
      width: '50%',
      marginTop: '-12px',
      paddingTop: 0,
      paddingBottom: '4px',
    },
    label: {
      color: ourTheme.palette.text.light,
      '&$focusedLabel': {
        color: ourTheme.palette.text.light,
      },
    },
    focusedLabel: {},
    stringInput: {
      marginTop: '4px',
    },
  })

class PrefillAndSelectorField extends React.Component<TProps, IState> {
  public state: IState = { ...initialState }
  private mapKeyToValue: Map<string, IVehicleTypeOption> = new Map()

  public componentDidUpdate(prevProps: TProps) {
    if (this.props.prefilledValue && !this.state.prefilledValue) {
      this.setState({ prefilledValue: this.props.prefilledValue })
    }

    if (this.props.defaultValue && !this.state.defaultValue) {
      this.setState({ defaultValue: this.props.defaultValue })
    }

    if (this.props.options && this.props.options !== prevProps.options) {
      const selectOptions: IVehicleTypeOption[] = this.initAndGetOptions(this.props.options)

      this.setState({ selectOptions: selectOptions })
    }
  }

  public render() {
    const {
      classes,
      fieldType,
      inputLabel,
      optionsLabel,
      placeholder,
      e2eTestAttribute,
      inputValue,
      defaultValue,
      onChange,
      error,
      helperText,
      translateCaption,
      isDisabled,
      strikePrefilledValue,
    } = this.props
    const { selectOptions, prefilledValue } = this.state

    switch (fieldType) {
      case 'string':
        return (
          <this.StringField
            inputLabel={inputLabel}
            // placeholder={placeholder} /* TODO: Not yet implemented. */
            inputValue={inputValue}
            // prefilledValue={prefilledValue} /* TODO: Not yet implemented. */
            // onChange={onChange} /* TODO: Not yet implemented. */
            error={error}
            helperText={helperText}
            // translateCaption={translateCaption} /* TODO: Not yet implemented. */
            isDisabled={isDisabled}
            strikePrefilledValue={strikePrefilledValue}
          />
        )
      case 'date':
        return (
          <this.DatePickField
            inputLabel={inputLabel}
            placeholder={placeholder}
            inputValue={inputValue}
            prefilledValue={prefilledValue}
            onChange={onChange}
            error={error}
            helperText={helperText}
            translateCaption={translateCaption}
            isDisabled={isDisabled}
            strikePrefilledValue={strikePrefilledValue}
          />
        )
    }

    const value = this.getSelectOption(defaultValue)
    const options = !optionsLabel
      ? selectOptions
      : [
          {
            label: optionsLabel,
            options: selectOptions,
          },
        ]

    const formatGroupLabel = (data: any) => (
      <div className={classes.selectLabelContainer}>
        <span className={classes.selectLabel}>{data.label}</span>
        <span>{data.options.length}</span>
      </div>
    )

    const isPrefillHighlighted = isDisabled
    const fontWeightValue = isPrefillHighlighted ? HIGHLIGHT_FONT_WEIGHT : UNHIGHLIGHT_FONT_WEIGHT

    return (
      <FormControl fullWidth={true} margin="normal">
        <div className={classes.formInnerContainer}>
          <FormLabel component="legend" className={classes.formLabel}>
            {inputLabel}
          </FormLabel>
          <Input
            className={classNames(
              classes.prefilledValue,
              strikePrefilledValue ? classes.strike : '',
              isPrefillHighlighted ? classes.highlightFont : classes.unhighlightFont,
            )}
            classes={
              {
                input: strikePrefilledValue ? classes.strike : '',
              } /* Note: This fixes line/strike-through on certain browsers like Firefox and Edge. */
            }
            style={{
              textDecoration: strikePrefilledValue ? 'line-through' : '',
              fontWeight: fontWeightValue,
            }}
            value={strikePrefilledValue ? padString(prefilledValue) : prefilledValue} // Note: When line/strike-through, pad the string as well so the striking stands out more.
            readOnly={true}
            fullWidth={true}
            endAdornment={
              <InputAdornment position="start" className={!(isDisabled && HIDE_WHEN_DISABLED) ? '' : classes.hidden}>
                {fieldType === 'options' && (
                  <span data-e2e={e2eTestAttribute + '_select'}>
                    <StyledSelect
                      styles={{
                        // React-Select custom style.
                        control: (provided, state) => {
                          const backgroundColor = ERROR_BG_COLOR
                          // Below, disabled for the moment.
                          // const borderColor = 'red'
                          // const borderWidth = '2px'

                          if (!error) {
                            return { ...provided }
                          } else {
                            return { ...provided, backgroundColor }
                          }
                        },
                        singleValue: (provided, state) => {
                          const color = this.state.reactSelectCustomStyles.color
                          const fontWeight = this.state.reactSelectCustomStyles.weight

                          return { ...provided, color, fontWeight }
                        },
                        placeholder: (provided, state) => {
                          const color = this.state.reactSelectCustomStyles.color
                          const fontWeight = this.state.reactSelectCustomStyles.weight

                          return { ...provided, color, fontWeight }
                        },
                      }}
                      className={classNames(
                        classes.select,
                        !isPrefillHighlighted ? classes.highlightFont : classes.unhighlightFont,
                      )}
                      isDisabled={isDisabled}
                      options={options}
                      defaultValue={null}
                      value={value}
                      onChange={onChange}
                      formatGroupLabel={formatGroupLabel}
                      placeholder={!placeholder ? t('Please select...') : placeholder}
                      noOptionsMessage={() => {
                        return t('No results found')
                      }}
                    />
                  </span>
                )}
              </InputAdornment>
            }
          />
        </div>
        <FormHelperText className={classNames(classes.helperText, !error && classes.hidden)} error={true}>
          {helperText || ''}
        </FormHelperText>
      </FormControl>
    )
  }

  StringField = (props: IProps) => {
    const { classes } = this.props
    const { inputLabel, inputValue, error, isDisabled } = props

    return (
      <>
        <FormLabel component="legend" className={classNames(classes.formLabel, classes.formLabelForStringField)}>
          {inputLabel}
        </FormLabel>
        <Input
          className={classNames(classes.highlightFont, classes.stringInput)}
          classes={
            {
              // TODO: Not yet implemented.
            } /* Note: This fixes line/strike-through on certain browsers like Firefox and Edge. */
          }
          style={{
            fontWeight: HIGHLIGHT_FONT_WEIGHT,
          }}
          value={inputValue}
          error={error}
          margin="dense"
          fullWidth={true}
          readOnly={isDisabled}
        />
      </>
    )
  }

  DatePickField = (props: IProps) => {
    const { classes } = this.props
    const {
      inputLabel,
      placeholder,
      inputValue,
      prefilledValue,
      onChange,
      error,
      helperText,
      isDisabled,
      strikePrefilledValue,
    } = props

    const isPrefillHighlighted = isDisabled
    const fontWeightValue = isPrefillHighlighted ? HIGHLIGHT_FONT_WEIGHT : UNHIGHLIGHT_FONT_WEIGHT

    return (
      <FormControl fullWidth={true} margin="dense" data-e2e={'PrefillAndSelectorField__datePicker'}>
        <FormLabel component="legend" className={classes.formLabelDate}>
          {inputLabel}
        </FormLabel>
        <div className={classes.containerDate}>
          <div className={classes.dateLeft}>
            <Input
              className={classNames(
                classes.prefilledValue,
                strikePrefilledValue ? classes.strike : '',
                isPrefillHighlighted ? classes.highlightFont : classes.unhighlightFont,
              )}
              classes={
                {
                  input: strikePrefilledValue ? classes.strike : '',
                } /* Note: This fixes line/strike-through on certain browsers like Firefox and Edge. */
              }
              style={{
                textDecoration: strikePrefilledValue ? 'line-through' : '',
                fontWeight: fontWeightValue,
              }}
              value={strikePrefilledValue ? padString(prefilledValue) : prefilledValue}
              readOnly={true}
              fullWidth={true}
              disableUnderline={true}
              data-e2e={'VehicleMapperForm__date'}
            />
          </div>
          <div className={classNames(classes.dateRight, !(isDisabled && HIDE_WHEN_DISABLED) ? '' : classes.hidden)}>
            {/* TODO: Style the DatePicker element (width ERROR_BG_COLOR, etc...) */}
            <DatePicker
              id="contract-flow-regdate-date-picker"
              isDisabled={isDisabled}
              disableUnderline={true}
              date={!inputValue ? null : (moment(inputValue, defaultDateFormat.moment) as any)}
              placeholder={!placeholder ? t('Date') + '...' : placeholder}
              onDateChange={onChange}
            />
          </div>
        </div>
        <FormHelperText className={classNames(classes.helperText, !error && classes.hidden)} error={true}>
          {helperText || ''}
        </FormHelperText>
      </FormControl>
    )
  }

  private toMapKey = (str: string): string => {
    return str.trim().toLowerCase()
  }

  public toSelectOption = (value: string | undefined | null): IVehicleTypeOption | null => {
    if (!value) {
      return null
    }

    return { value: this.toMapKey(value), label: value }
  }

  private getSelectOption = (value: string | undefined | null): IVehicleTypeOption | null => {
    const mapKeyToValue = this.mapKeyToValue
    if (!value) {
      return null
    }

    const key = this.toMapKey(value)
    const option: IVehicleTypeOption | undefined = mapKeyToValue.get(key)

    return option || null
  }

  /**
   * Inits options in structure suitable for 'react-select'.
   *
   * @param options The options in the original format.
   */
  private initAndGetOptions = (options: IOption[]): IVehicleTypeOption[] => {
    const arrayOptions: IOption[] | undefined = options || this.props.options
    const selectOptions: IVehicleTypeOption[] = []
    const mapKeyToValue = this.mapKeyToValue

    if (!arrayOptions) {
      return selectOptions
    }

    arrayOptions.forEach((option: IOption) => {
      const selectOption: IVehicleTypeOption = {
        value: option.name,
        label: option.caption || option.name,
      }
      const key = this.toMapKey(option.name)

      selectOptions.push(selectOption) // Save the option for 'react-select'.
      mapKeyToValue.set(key, selectOption) // Index the option.
    })

    return selectOptions
  }
}

export default withStyles(styles)(PrefillAndSelectorField)
