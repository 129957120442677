import { Button, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import {
  DescriptionOutlined as DescriptionIcon,
  WarningSharp as FailureIcon,
  SettingsBackupRestore as RefundIcon,
  CheckCircle as SuccessIcon,
} from '@material-ui/icons'
import { formatCurrency, formatDate } from '@omnicar/sam-format'
import {
  IApiVehicleContract,
  IInvoice,
  InvoiceOrderBy,
  IPaginatedQueryParams,
  IPaginationParams,
} from '@omnicar/sam-types'
import { getContractInvoiceList, invoiceDownloadUrl } from 'api/api'
import cn from 'classnames'
import RetryButton from 'components/admin/Contract/Details/InvoiceList/RetryButton'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import Typography from 'components/Typography'
import React, { Component, ReactNode } from 'react'
import ReactTable, { Column, RowInfo, SortingRule } from 'react-table'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'
import { createTableStateFromPagination, defaultTableState, ITableQuery, ITableState } from 'utils/react-table'

interface IProps extends WithStyles<typeof styles> {
  contract: IApiVehicleContract
  hidden: boolean
  disabled: boolean
  allowRetryPayment: boolean
  onInvoicesSave: (i: IInvoice[]) => void
  onSuccessfulRetry: () => void
}

interface IState {
  data: IInvoice[]
  loading: boolean
  tableState: ITableState
  tableQuery: IPaginatedQueryParams<InvoiceOrderBy>
}

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    alignRight: {
      textAlign: 'right',
    },
    icon: {
      fontSize: 18,
    },
    noResults: {
      textAlign: 'center',
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      [breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    loadButton: {
      minWidth: spacing(7),
      marginRight: spacing(0.5),
      [breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    smallCellContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },
    unpaidState: {
      color: theme.palette.error[500],
    },
    stateIcon: {
      marginRight: spacing(2),
      display: 'flex',
    },
  })

class CustomerContractDetailsInvoices extends Component<IProps, IState> {
  public state: IState = {
    data: [],
    loading: false,
    tableQuery: {
      search: '',
      pagination: {
        limit: 10,
        offset: 0,
        orderBy: 'dateIssuedAt',
        orderDirection: 'DESC',
      },
    },
    tableState: {
      ...defaultTableState(10),
      sorted: [{ desc: true, id: 'dateIssuedAt' } as SortingRule],
      resized: [],
    },
  }

  public getColumns = (): Column[] => [
    {
      Header: t('Date'),
      accessor: 'dateIssuedAt',
      headerClassName: 'e2e__header__dateIssuedAt',
      Cell: ({ value }: { value: string }) => <div>{formatDate(new Date(value))}</div>,
    },
    {
      Header: t('Status'),
      accessor: 'state',
      headerClassName: 'e2e__header__state',
      Cell: this.resolveStateCell,
    },
    {
      Header: () => <div>{t('Sub total')}</div>,
      accessor: 'amount.priceInclVat',
      headerClassName: 'e2e__header__total',
      Cell: ({ value }: { value: number }) => <div>{formatCurrency(value)}</div>,
    },
    {
      Header: '',
      accessor: 'url',
      headerClassName: 'e2e__header__url',
      Cell: (props) => this.resolveInvoiceCell(props),
      minWidth: 135,
    },
  ]

  public componentDidUpdate(prevProps: IProps) {
    const { contract } = this.props
    const { contract: prevContract } = prevProps

    // check if new contract is selected
    const contractChanged = prevContract && contract && prevContract.serviceContractId !== contract.serviceContractId

    if (contractChanged) {
      this.loadData()
    }
  }

  public componentDidMount() {
    this.loadData()
  }

  public render() {
    const { classes } = this.props
    const { data, loading, tableState } = this.state
    const tableColumns = this.getColumns()

    return (
      <React.Fragment>
        {data.length ? (
          <div className="CustomerContractDetailsInvoices">
            <ReactTable
              className="react-table"
              columns={tableColumns}
              data={data}
              defaultPageSize={tableState.pageSize}
              defaultSorted={tableState.sorted}
              loading={loading}
              LoadingComponent={TableLoadingIndicator}
              noDataText={!loading ? t('No Results Found') : ''}
              manual={true}
              minRows={3}
              onPageChange={this.handlePageChange}
              page={tableState.page}
              pageSize={tableState.pageSize}
              pages={tableState.pages}
              showPaginationBottom={data.length > 0}
              onSortedChange={this.handleSortChange}
              pageText={t('Page')}
              ofText={t('of')}
              rowsText={t('rows')}
            />
          </div>
        ) : (
          <div className={`CustomerContractDetailsInvoices ${classes.noResults}`}>{t('No Results Found')}</div>
        )}
      </React.Fragment>
    )
  }

  // this function is a duplicate from components/admin/Contract/Details/InvoiceList. We keep this duplicate till MyPage (new customer portal) release
  private resolveStateCell = (cellProps: RowInfo) => {
    const { state, amount } = cellProps.original as IInvoice
    const processedState = amount.price > 0 ? state : 'Refund'
    const { smallCellContainer, stateIcon, unpaidState } = this.props.classes
    let Icon: ReactNode

    switch (processedState) {
      case 'Refund':
        Icon = <RefundIcon color="secondary" />
        break
      case 'Paid':
        Icon = <SuccessIcon color="primary" />
        break
      case 'Pending':
        Icon = <FailureIcon color="error" />
        break

      default:
        break
    }

    return (
      <div className={cn(smallCellContainer, state === 'Pending' ? unpaidState : '')}>
        <div className={stateIcon}>{Icon}</div>
        <div>{t(processedState)}</div>
      </div>
    )
  }

  private resolveInvoiceCell = (cellProps: RowInfo) => {
    const { classes, allowRetryPayment } = this.props
    const { url, state } = cellProps.original as IInvoice
    return (
      <div className={cn('ContractDetailsInvoiceList__table-header-url', classes.cellContainer)}>
        {state !== 'Pending' && (
          <Button
            classes={{ root: classes.loadButton }}
            href={invoiceDownloadUrl(url)}
            onClick={this.trackInvoiceClick}
            target="_blank"
          >
            <Typography variant="subheading">
              <DescriptionIcon className={this.props.classes.icon} />
            </Typography>
          </Button>
        )}
        <span>
          {state === 'Pending' && allowRetryPayment ? (
            <RetryButton
              invoice={cellProps.original as IInvoice}
              onSucceededPayment={this.handleUpdateInvoiceOnRetry}
              isCustomerPortal={true}
            />
          ) : (
            ''
          )}
        </span>
      </div>
    )
  }

  private handleUpdateInvoiceOnRetry = (invoice: IInvoice) =>
    this.setState(({ data }) => ({
      data: data.map((r) => (r.invoiceId === invoice.invoiceId ? { ...r, state: 'Paid' } : r)),
    }))

  private loadData = async () => {
    const { contract, disabled } = this.props
    const { loading } = this.state

    if (contract && !disabled && !loading) {
      this.setState({ loading: true })

      const response = await getContractInvoiceList(contract.prettyIdentifier, this.state.tableQuery, false)

      if (response.data) {
        const { result, pagination } = response.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }

        this.setState({ loading: false, data: result, tableState })
        this.props.onInvoicesSave(result) // needed here to pass invoices info to credit cards tab
      } else if (response.errorData) {
        this.setState({ loading: false })
      }
    }
  }

  private handlePageChange = (page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = this.createQueryFromTable(tableState)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private createQueryFromTable = (tableState: ITableState): ITableQuery<InvoiceOrderBy> => {
    const sortParams = this.getSortedParamsFromTableState(tableState)

    return {
      ...this.state.tableQuery,
      pagination: {
        ...this.state.tableQuery.pagination,
        ...sortParams,
        limit: tableState.pageSize,
        offset: tableState.page,
      },
    }
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState: ITableState = { ...this.state.tableState, sorted }
    const tableQuery = this.createQueryFromTable(tableState)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private getSortedParamsFromTableState = (tableState: ITableState) => {
    const { sorted } = tableState
    let tableSorted: SortingRule
    let querySorted: Partial<IPaginationParams<InvoiceOrderBy>> = {}

    if (this.state.tableQuery.pagination) {
      querySorted = {
        orderBy: this.state.tableQuery.pagination.orderBy,
        orderDirection: this.state.tableQuery.pagination.orderDirection,
      }

      if (sorted && sorted.length) {
        tableSorted = sorted[0]

        const sortedOrderBy = tableSorted.id as InvoiceOrderBy
        const sortedOrderDirection = tableSorted.desc ? 'DESC' : 'ASC'

        if (sortedOrderDirection) {
          querySorted.orderBy = sortedOrderBy
          querySorted.orderDirection = sortedOrderDirection
        }
      }
    }
    return querySorted
  }

  private trackInvoiceClick = () => {
    trackEvent('Customer portal', 'Download invoice')
  }
}

export default withStyles(styles)(CustomerContractDetailsInvoices)
