import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  IAdminCustomer,
  IContractInfo,
  IOptionInfo,
  ISetPaymentMethodResponse,
  ISubscription,
  Vehicle as IVehicle,
  PriceSpecification,
} from '@omnicar/sam-types'
import { Other as IProduct } from '@omnicar/sam-types/types/product'
import { Stripe } from '@stripe/stripe-js'
import { getContractCustomer, patchCustomer } from 'api/api'
import cn from 'classnames'
import CustomerDetails from 'components/admin/Customer/Details'
import ProductDetails from 'components/admin/Product/Details'
import { LayoutColumn, LayoutRow } from 'components/Mui/Layout'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Paper from 'components/Mui/Paper'
import React from 'react'
import { t } from 'translations/translationFunctions'
import BillingSummary from './Billing'
import ContractInfo from './ContractInfo'
import PaymentForm from './PaymentForm'

interface IProps {
  onBack: () => void
  onSuccessfulPayment: (status: ISetPaymentMethodResponse['status']) => void
  subscriptions: ISubscription[]
  downpayment: PriceSpecification
  additionalOptions: IOptionInfo[]
  termsOfTradeUrl: string
  stripePromise: Promise<Stripe | null> | null
  contractPrettyIdentifier: string
  customer: IAdminCustomer
  product: IProduct | IVehicle
  contract: IContractInfo
  totalAmount: PriceSpecification | null
  minimumTotalAmount: PriceSpecification | null
}

type TProps = IProps & WithStyles<typeof styles>

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      background: '#F7F7F7',
      padding: `${spacing(0.5)}px 3% 0`,
      width: '100%',
    },
    page: {
      padding: spacing(3),
    },
    topRowPanel: { height: '100%' },
    vehiclePanel: { marginBottom: 0 },
    paymentPanel: { border: `2px solid ${palette.secondary[600]}` },
  })

interface IState {
  customer: IAdminCustomer | undefined
}

const initialState: IState = {
  customer: undefined,
}

class Billing extends React.Component<TProps, IState> {
  state = initialState

  componentDidMount = async () => {
    const result = await getContractCustomer(this.props.contractPrettyIdentifier!)
    const customer: IAdminCustomer = result.data!

    this.setState({ customer })
  }

  public render() {
    const {
      subscriptions,
      downpayment,
      additionalOptions,
      termsOfTradeUrl,
      onBack,
      onSuccessfulPayment,
      stripePromise,
      classes,
      product,
      contract,
      contractPrettyIdentifier,
      totalAmount,
      minimumTotalAmount,
    } = this.props

    const { customer } = this.state

    return (
      <section className={classes.root}>
        <LayoutRow columns={2}>
          <LayoutColumn>
            <CustomerDetails
              customerIdentifier={customer?.prettyIdentifier!}
              contractPrettyId={contractPrettyIdentifier}
              customerRecord={customer!}
              onPatchCallback={patchCustomer}
              allowEdit={true}
            />
          </LayoutColumn>
          <LayoutColumn>
            <ProductDetails
              id={contractPrettyIdentifier}
              record={product}
              allowEdit={true}
              forbidEditingVIN={true}
              loading={false}
              loaded={true}
            />
          </LayoutColumn>
        </LayoutRow>
        <LayoutRow columns={2}>
          <LayoutColumn>
            <ContractInfo contract={contract} />
          </LayoutColumn>
          <LayoutColumn>
            <Panel>
              <PanelHeader>
                <PanelTitle>{t('Payment')}</PanelTitle>
              </PanelHeader>
              <PanelContent>
                <Paper className={cn(classes.page, classes.paymentPanel)}>
                  <BillingSummary
                    downpayment={downpayment}
                    subscriptions={subscriptions}
                    minimumPaymentsCount={contract.minimumPaymentsCount}
                    totalAmount={totalAmount}
                    minimumTotalAmount={minimumTotalAmount}
                  />
                  <PaymentForm
                    onBack={onBack}
                    onSuccessfulPayment={onSuccessfulPayment}
                    stripePromise={stripePromise}
                    additionalOptions={additionalOptions}
                    termsOfTradeUrl={termsOfTradeUrl}
                    termsOfServiceUrl={contract.termsUrl}
                    contractPrettyIdentifier={this.props.contractPrettyIdentifier}
                  />
                </Paper>
              </PanelContent>
            </Panel>
          </LayoutColumn>
        </LayoutRow>
      </section>
    )
  }
}

export default withStyles(styles)(Billing)
