import {
  InputAdornment,
  Theme,
  WithStyles,
  withStyles,
  createStyles,
  InputLabel,
  CircularProgress,
  FormControl,
  Input,
} from '@material-ui/core'
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons'
import classNames from 'classnames'
import React, { ChangeEvent } from 'react'
import { theme as customTheme, palette } from 'theme'

interface IOwnProps {
  onChange: (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void
  onClear: () => void
  filledFromSearch: boolean
  searchFieldLabel: string
  searching: boolean
  searchQuery: string
}

type TProps = WithStyles<typeof styles> & IOwnProps

const styles = (theme: Theme) =>
  createStyles({
    alignCenter: {
      textAlign: 'center',
    },
    colorLight: {
      color: palette.text.light,
    },
    searchIcon: {
      color: theme.palette.primary.dark,
    },
    clearIcon: {
      cursor: 'pointer',
    },
    search: {
      color: theme.palette.primary.contrastText,
      width: 300,
    },
    bouncedEmail: {
      verticalAlign: 'middle',
    },
    validEmail: {
      color: customTheme.palette.context.accept[500],
    },
    textAlignCenter: {
      textAlign: 'center',
    },
  })

const SearchBar: React.SFC<TProps> = (props: TProps) => {
  const { classes, onClear, onChange, searchFieldLabel, searching, searchQuery } = props
  return (
    <FormControl margin="dense" className={classNames(classes.search)}>
      <InputLabel htmlFor="customer-search">{searchFieldLabel}</InputLabel>
      <Input
        id="customer-search" // tslint:disable-next-line jsx-no-lambda
        onChange={onChange}
        value={searchQuery}
        startAdornment={
          <InputAdornment position="start">
            <>
              {searching && <CircularProgress color="secondary" size={20} />}
              {searchQuery ? (
                <ClearIcon
                  // tslint:disable-next-line jsx-no-lambda
                  onClick={() => onClear()}
                  className={classes.clearIcon}
                />
              ) : (
                <SearchIcon className={classes.searchIcon} />
              )}
            </>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

export default withStyles(styles)(SearchBar)
