import React from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Input as InputIcon, Close as CloseIcon } from '@material-ui/icons'
import Typography from 'components/Typography'
import { t } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'
import { exportUrl } from 'api/api'
import DateRangePicker, { IOnDatesChangeArgs } from 'components/DateRangePicker'
import moment, { Moment } from 'moment'

interface IOwnProps {
  open: boolean
  onClose: () => void
}

interface IState {
  startDate: Moment | null
  endDate: Moment | null
  minDate: Moment | null
  maxDate: Moment | null
}

const initialState: IState = {
  startDate: moment().subtract(1, 'month').startOf('day'),
  endDate: moment().endOf('day'),
  minDate: null,
  maxDate: moment().add(1, 'month').endOf('day'),
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    allowOverflow: {
      overflow: 'visible',
    },
    content: {
      minWidth: spacing(62),
    },
    button: {
      marginTop: spacing(3),
      marginRight: spacing(1),
    },
    icon: {
      marginRight: spacing(1),
      fontSize: 16,
    },
  })

class DialogInvoicesDownload extends React.Component<TProps, IState> {
  public state: IState = { ...initialState }

  public componentDidUpdate(prevProps: IOwnProps) {
    // reset state when dialog is closed
    if (!prevProps.open && this.props.open) {
      this.setState({ ...initialState })
    }
  }

  public render() {
    const { open, onClose, classes } = this.props
    const { startDate, endDate, minDate, maxDate } = this.state

    const link = exportUrl({
      type: 'invoices',
      format: 'xls',
      dateFrom: startDate ? startDate.toDate() : undefined,
      dateTo: endDate ? endDate.toDate() : undefined,
    })

    return (
      <Dialog
        open={open}
        onClose={onClose}
        data-e2e="Dialog--download-economy-report"
        classes={{ paper: classes.allowOverflow }}
      >
        <DialogTitle>
          <Typography variant="subtitle">{`${t('Reports')} - ${t('Download All Invoices')}`}</Typography>
        </DialogTitle>
        <DialogContent className={classes.content} classes={{ root: classes.allowOverflow }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            startDateId="reports-economy-start-date"
            endDateId="reports-economy-end-date"
            // tslint:disable-next-line jsx-no-lambda
            onDatesChange={({ startDate, endDate }: IOnDatesChangeArgs) => this.setState({ startDate, endDate })}
            data-e2e="Dialog--download-economy-datepicker"
          />
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            onClick={this.handleXLS}
            href={link}
            data-e2e="Dialog--download-economy-report__download-xls-button"
          >
            <InputIcon className={classes.icon} />
            {t('XLS')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            <CloseIcon className={classes.icon} />
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private handleXLS = () => {
    trackEvent('File export', 'Financial', 'xls')
    // @TODO - Inform the user that we're downloading the file
    this.props.onClose()
  }
}

export default withStyles(styles)(DialogInvoicesDownload)
