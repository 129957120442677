import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { ISettlementInfo } from '@omnicar/sam-types'
import FinalInfoMessage from './InfoMessage'

interface IOwnProps {
  settlementInfo?: ISettlementInfo
  isSettleMessageShown: boolean
  isSettled: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    infoMessage: {
      backgroundColor: '#D0E2EC',
      borderRadius: '5px',
      color: '#126EA1',
      width: '20%',
      position: 'absolute',
      top: -spacing(5),
      padding: spacing(1.25),
    },
    infoMessageContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
    },
    snackbarContent: {
      maxWidth: '100%',
      color: '#C61B38',
      backgroundColor: '#F4D1D7',
      fontSize: spacing(1.75),
      marginBottom: spacing(1.5),
    },
  })

const SettleInfoMessage: React.SFC<TProps> = ({ settlementInfo, isSettleMessageShown, isSettled, classes }) => {
  const finalMessage =
    settlementInfo && settlementInfo.autoSettlement
      ? 'This contract has been settled automatically on %date'
      : 'This contract has been settled by %user on %date'
  return (
    <>
      {settlementInfo && isSettled && (
        <FinalInfoMessage
          autoSettlement={settlementInfo.autoSettlement}
          settlementDate={settlementInfo.settlementDate}
          userName={settlementInfo.userName}
          snackbarContentClassName={classes.snackbarContent}
          message={finalMessage}
        />
      )}
    </>
  )
}
export default withStyles(styles)(SettleInfoMessage)
