import { UserRole } from '@omnicar/sam-types'
import { AdministrationTab } from 'pages/admin/AdministrationPage'
import { getAuth } from './localStorage'

interface IProviderRoleAccess {
  invalidTabsFilter: (t: { name: string }) => boolean
  startingAdministrationTab: AdministrationTab
}

export function getAdministrationTabFiltering(role: UserRole | undefined, inactive: boolean) {
  let filters: ((t: { name: string }) => boolean)[] = []

  if (inactive) filters.push(InactiveProviderAccess.invalidTabsFilter)

  switch (role) {
    case 'seller':
      filters.push(SellerAccess.invalidTabsFilter)
      break
  }

  return filters
}

export function getStartingAdministrationTab(role: UserRole | undefined, inactive: boolean) {
  let startTab: AdministrationTab = 'REPORTS'

  switch (role) {
    case 'seller':
      startTab = SellerAccess.startingAdministrationTab
      break
  }

  if (inactive) startTab = InactiveProviderAccess.startingAdministrationTab

  return startTab
}

export const SellerAccess: IProviderRoleAccess = {
  invalidTabsFilter: (t: { name: string }) => t.name !== 'REPORTS' && t.name !== 'FINANCE',
  startingAdministrationTab: 'USERS',
}

export const InactiveProviderAccess: IProviderRoleAccess = {
  invalidTabsFilter: (t: { name: string }) => t.name !== 'FINANCE',
  startingAdministrationTab: 'REPORTS',
}
/**
 *
 * @param providerIds only positive numbers
 */

export const verifyAccessForProvider = (providerIds: number[]): boolean =>
  process.env.NODE_ENV !== 'production' || providerIds.includes(getAuth()?.providerInfo?.providerId || -1)
