import { Typography } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatDate, formatLocalizedDate } from '@omnicar/sam-format'
import {
  ICellDataPoint,
  IChartDateValue,
  IContractCharts,
  IContractFilterDate,
  IDashboardContractCharts,
} from '@omnicar/sam-types'
import { Card } from 'components/Mui/Card'
import * as _ from 'lodash'
import React from 'react'
import {
  Crosshair,
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineMarkSeries,
  LineMarkSeriesPoint,
  RVNearestXData,
  XAxis,
  YAxis,
} from 'react-vis'
import 'react-vis/dist/style.css'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { t, tChartName } from 'translations/translationFunctions'
import { firstCharUpper } from 'utils/string'

interface IOwnProps {
  data: IDashboardContractCharts
  activeFilters: {
    contractStates: number[]
    sellers: number[]
    created: IContractFilterDate
  }
  maxTicks?: number
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  tooltipValue: IChartDateValue | undefined
  crosshairValues: IChartDateValue[]
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    chartContainer: {
      display: 'inline-flex',
      flexDirection: 'column',
      maxWidth: '100%',
      '&:first-child': {
        marginBottom: spacing(5),
      },
    },
    cardContent: {
      padding: spacing(1),
    },
    toolTip: {
      fontSize: '1rem',
      '& svg': {
        height: '6px !important',
      },
    },
    legendContainer: {
      display: 'inline-flex',
      '& span': {
        fontSize: '14px',
      },
      '& svg': {
        height: '6px !important',
      },
    },
    noDataPlaceholderContainer: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100px',
    },
    noDataPlaceholder: {
      color: palette.text.secondary,
    },
  })

class ContractsChart extends React.Component<TProps, IState> {
  public state: IState = {
    tooltipValue: undefined,
    crosshairValues: [],
  }

  public render() {
    const { data, classes, maxTicks } = this.props
    const curveName = 'curveMonotoneX'
    const strokeWidth = 4
    const legendStrokeWidth = 6
    const chartName = data.chartName ? data.chartName : ''
    const charts = data.charts ? data.charts : []
    const max = _.chain(data.charts)
      .map((val: IContractCharts) => _.maxBy(val.chartsData, 'y'))
      .maxBy('y')
      .value()
    const maxY = Math.max(5, ((max && max.y) || 0) * 1.1)
    let tickTotal = maxTicks ? this.calcTickTotal(maxTicks, charts[0].chartsData.length) : maxTicks

    return (
      <div className={classes.chartContainer} key={chartName}>
        <Typography variant="subtitle1">{tChartName(chartName)}</Typography>
        <div className={classes.legendContainer}>
          <DiscreteColorLegend
            items={charts.map((data) =>
              data.sellerName
                ? { title: data.sellerName, strokeWidth: legendStrokeWidth }
                : { title: t('All'), strokeWidth: legendStrokeWidth },
            )}
            orientation="horizontal"
          />
        </div>
        <div>
          {charts.length ? (
            <FlexibleWidthXYPlot onMouseLeave={this.handleMouseLeave} height={220} xType="time-utc" yDomain={[0, maxY]}>
              <XAxis tickTotal={tickTotal} tickFormat={this.tickDataFormat} tickLabelAngle={-45} />
              <YAxis />
              <HorizontalGridLines />
              {charts.map((c: IContractCharts) => {
                return (
                  <LineMarkSeries
                    key={c.sellerId}
                    data={c.chartsData}
                    strokeWidth={strokeWidth}
                    size={strokeWidth}
                    curve={curveName}
                    onNearestX={this.handleOnNearestX}
                  />
                )
              })}
              <Crosshair values={this.state.crosshairValues}>
                <Card className={classes.cardContent}>
                  <DiscreteColorLegend
                    className={classes.toolTip}
                    items={this.state.crosshairValues.map((value) => {
                      return { title: value.y.toString(), strokeWidth: legendStrokeWidth }
                    })}
                    orientation="horizontal"
                  />
                </Card>
              </Crosshair>
            </FlexibleWidthXYPlot>
          ) : (
            <div className={classes.noDataPlaceholderContainer}>
              <Typography className={classes.noDataPlaceholder} variant="h6">
                {t('No data found')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    )
  }

  private calcTickTotal(maxTicks: number, chartData: number) {
    return chartData > maxTicks ? maxTicks : chartData
  }

  private handleOnNearestX = (value: LineMarkSeriesPoint, { index }: RVNearestXData<LineMarkSeriesPoint>) => {
    // ToDo: For each value that is added the function runs again and for some reason doesn't stop at return
    // Todo: Find a way to stop the function when there are no new values

    if (this.state.crosshairValues.length && this.state.crosshairValues[0].x === value.x) {
      return
    }

    const processedData: ICellDataPoint[][] = []
    const { data } = this.props
    data.charts.forEach((c: IContractCharts) => {
      processedData.push(c.chartsData)
    })
    const newValues = processedData.map((d) => d[index])

    this.setState({ crosshairValues: newValues })
  }

  private handleMouseLeave = () => {
    this.setState({ crosshairValues: [] })
  }

  private tickDataFormat = (d: Date) => (
    <tspan>
      <AppContext.Consumer>
        {({ locale }) => (
          <tspan y="1" x="-3">
            {firstCharUpper(formatLocalizedDate(d, 'MMM', locale))}
          </tspan>
        )}
      </AppContext.Consumer>
      <tspan x="0" dy="1em">
        {formatDate(d, { rawFormat: 'yyyy' })}
      </tspan>
    </tspan>
  )
}

export default compose<TProps, IOwnProps>(withStyles(styles))(ContractsChart)
