import React from 'react'
import { IIncludedContractOption } from '@omnicar/sam-types'
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import { Check as CheckmarkIcon, CloudDownload as CloudDownloadIcon } from '@material-ui/icons'
import Typography from 'components/Typography'
import { Button } from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import cn from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(0.75),
    },
    itemAbbreviation: {
      minWidth: theme.spacing(7),
    },
    itemCondition: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontStyle: 'italic',
    },
    checkmark: {
      color: theme.palette.secondary[500],
      fontSize: 20,
    },
    abbreviation: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    downloadBtnLabel: {
      justifyContent: 'flex-start',
    },
    downloadIcon: {
      marginRight: theme.spacing(3),
      fontSize: 14,
    },
    termsOfServiceButton: {
      padding: '0 0 0.7em 2em',
    },
    description: {
      paddingLeft: '15px',
    },
  })

interface IOwnProps {
  options: IIncludedContractOption[] | null
  filterBy?: 'PROPERTY' | 'ADDITIONAL'
  showAbbreviation: boolean
  useDefaultAbbreviationClass: boolean
  termsButtonClassName?: string
  itemsListWrapperClassName?: string
  itemsWrapperClassName?: string
  checkmarkIconClassName?: string
  descriptionClassName?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

class IncludedOptionsList extends React.Component<TProps, never> {
  public render() {
    const {
      classes,
      options,
      filterBy,
      showAbbreviation,
      useDefaultAbbreviationClass,
      itemsWrapperClassName,
      termsButtonClassName,
      checkmarkIconClassName,
      descriptionClassName,
      itemsListWrapperClassName,
    } = this.props

    return (
      <div className={itemsListWrapperClassName || ''}>
        {options &&
          options
            // filter list by specified 'filterBy' prop
            .filter((item) => {
              let res
              res = filterBy ? item.assoc === filterBy : true
              return res
            })
            .map((item, i) => (
              <div className={cn(classes.item, itemsWrapperClassName)} key={`IncludedOptionsList-${i}`}>
                <div className={cn(useDefaultAbbreviationClass && classes.itemAbbreviation)}>
                  <React.Fragment>
                    {showAbbreviation && item.abbreviation ? (
                      <Typography variant="display1" className={classes.abbreviation}>
                        {item.abbreviation}
                      </Typography>
                    ) : (
                      <CheckmarkIcon className={cn(classes.checkmark, checkmarkIconClassName)} />
                    )}
                  </React.Fragment>
                </div>
                <Typography className={descriptionClassName || classes.description} variant="body1">
                  {item.description}
                  {item.conditionText && item.conditionText.trim() !== item.description.trim() && (
                    <Typography className={classes.itemCondition}>{item.conditionText}</Typography>
                  )}
                </Typography>
                {item.termsOfService && item.assoc !== 'PROPERTY' && item.termsOfService.ref && (
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    href={item.termsOfService.ref}
                    target="_blank"
                    classes={{ root: cn(classes.downloadBtnLabel, termsButtonClassName) }}
                    className={classes.termsOfServiceButton}
                  >
                    <CloudDownloadIcon className={classes.downloadIcon} />
                    {t('Terms')} (PDF)
                  </Button>
                )}
              </div>
            ))}
      </div>
    )
  }
}

export default withStyles(styles)(IncludedOptionsList)
