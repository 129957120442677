import { WithStyles, Theme, withStyles, CardContent, TextField } from '@material-ui/core'
import { Panel, PanelHeader, PanelTitle, PanelContent } from 'components/Mui/Panel'
import { t } from 'translations/translationFunctions'
import { createPanelStyles, theme as customTheme } from 'theme'
import React, { ChangeEvent, FocusEvent } from 'react'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
  })

interface IOwnProps {
  active: boolean
  freeContract: boolean
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
}

interface IState {
  value: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

class ContractFlowCustomTerms extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      value: props.value,
    }
  }

  public render() {
    const { active, classes, onBlur, freeContract } = this.props
    return (
      <Panel disabled={!active}>
        <PanelHeader>
          <PanelTitle>{t('Custom Terms')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card className={active ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}>
            <CardContent>
              <TextField
                fullWidth={true}
                disabled={!active}
                value={this.state.value}
                margin="dense"
                label={t('Add an optional custom terms', { count: 380 })}
                onChange={this.handleChange}
                onBlur={(e: FocusEvent<any>) => {
                  onBlur(e)
                }}
                multiline={true}
              />
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const customTerms = event.target.value.substr(0, 380)
    this.setState({ value: customTerms })
  }
}

export default withStyles(styles)(ContractFlowCustomTerms)
