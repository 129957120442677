import { MenuItem, Input, InputLabel, FormControl, ListItemText, Select, Checkbox, Chip } from '@material-ui/core'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { IContractFilterOption } from '@omnicar/sam-types'

const styles = (theme: Theme) =>
  createStyles({
    chip: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      height: theme.spacing(3),
    },
    chipLabel: {
      fontSize: theme.typography.button.fontSize,
      color: theme.palette.grey['700'],
      fontWeight: theme.typography.fontWeightMedium,
    },
    select: {
      minHeight: theme.spacing(5),
    },
  })

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface IProps {
  classes: any
  helperText: string
  label: string
  name: 'templates' | 'sellers' | 'options'
  onChange: (name: string, value: any) => void
  options: IContractFilterOption[]
  selectedValues: number[]
  placeholder?: string
}

class DetailsField extends React.Component<WithStyles<typeof styles> & IProps> {
  public render() {
    const { handleChange } = this
    const { name, classes, label, options, selectedValues } = this.props

    return (
      <FormControl fullWidth={true} data-e2e={`filterDetailsField-${name}`}>
        <InputLabel htmlFor={`filterDetailsField-${name}`}>{label}</InputLabel>
        <Select
          className={classes.select}
          multiple={true}
          value={selectedValues}
          onChange={handleChange}
          name={name}
          input={<Input id={`filterDetailsField-${name}`} />}
          renderValue={this.renderValue}
          MenuProps={MenuProps}
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={`filter-details-field-${name}-${option.id}`}
                value={option.id}
                data-e2e={`filterField-chip-${name}`}
              >
                <Checkbox checked={selectedValues && selectedValues.indexOf(option.id) > -1} />
                <ListItemText primary={option.title} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  private renderValue = (selected: any) => {
    const { name, classes } = this.props

    const renderValues: any = selected || []
    return (
      <div className={classes.chips}>
        {renderValues.map((val: string) => {
          const title = this.getTitleFromId(val)
          return (
            <Chip
              classes={{ root: classes.chip, label: classes.chipLabel }}
              key={`filterDetailsField-chip-${name}-${val}`}
              label={title}
              className={classes.chip}
            />
          )
        })}
      </div>
    )
  }

  private getTitleFromId = (id: string) => {
    const { options } = this.props
    let title = ''

    options.forEach((option) => {
      if (option.id === +id) {
        title = option.title
      }
    })

    return title
  }

  private handleChange = (e: any) => {
    const { value } = e.target
    const { onChange, name } = this.props
    onChange(name, value)
  }
}

export default withStyles(styles)(DetailsField)
