import { CONFIG } from 'config'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { AppContext, IAppContext } from '../../../store/appContext'
import { Page, PageHeader, PageTitle, PageContent } from '../../../components/Mui/Page'
import './index.css'
import Paper from '../../../components/Mui/Paper'

interface IState {}

type TProps = RouteComponentProps<never>

class OperationStatusPage extends React.Component<TProps, IState> {
  /*
  constructor(props: TProps) {
    super(props)
  }
  */

  /*
  public componentDidMount() {
    this.loadData()
  }
  */

  render() {
    return (
      <AppContext.Consumer>
        {(appContext: IAppContext) => {
          const isAdmin = appContext.role === 'admin'
          const env = CONFIG.env

          console.info('appContext:')
          console.info(appContext)

          return (
            <Page>
              <PageHeader>
                <PageTitle>{`Operation Status`}</PageTitle>
              </PageHeader>
              <PageContent noPadding>
                <Paper className="OperationStatus__paper">
                  <table>
                    <tr>
                      <td>ENV:</td>
                      <td>{env}</td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td>Email:</td>
                      <td>{appContext.userInfo?.email}</td>
                    </tr>
                    <tr>
                      <td>Name:</td>
                      <td>{appContext.userInfo?.name}</td>
                    </tr>
                    <tr>
                      <td>Role:</td>
                      <td>{appContext.role}</td>
                    </tr>
                    <tr>
                      <td>isAdmin:</td>
                      <td>{('' + isAdmin).toUpperCase()}</td>
                    </tr>
                    <tr>
                      <td>isSuperAdmin:</td>
                      <td>{('' + appContext.isSuperAdmin).toUpperCase()}</td>
                    </tr>
                    <tr>
                      <td>Locale:</td>
                      <td>{'' + appContext.locale}</td>
                    </tr>
                  </table>
                </Paper>
              </PageContent>
            </Page>
          )
        }}
      </AppContext.Consumer>
    )
  }

  // private loadData = async () => {
  // }
}

export default OperationStatusPage
