import { Theme, createStyles } from '@material-ui/core'

export default ({ spacing }: Theme) =>
  createStyles({
    scrollPaper: {
      padding: spacing(2),
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      left: '-20px',
    },
    menu: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    menuItem: {
      width: 'inherit',
      '&:not(:last-child)': {
        marginRight: spacing(2),
      },
    },
    menuItemIcon: {
      marginRight: spacing(1),
    },
  })
