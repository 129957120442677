import { whiteBG } from '..'
import { Theme, createStyles } from '@material-ui/core/styles'

export const createPanelStyles = (theme: Theme, customStyles?: any) =>
  createStyles({
    panelActionsButton: {
      //  color: palette.text.light,
    },
    panelActionsButtonIcon: {
      marginRight: theme.spacing(1),
      fontSize: '16px',
    },
    cardTitle: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
    },
    cardTitleIcon: {
      fontSize: 36,
      marginRight: theme.spacing(1),
      color: whiteBG.primary[400],
    },
    cardTitleText: {},
    cardSubtitle: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      color: whiteBG.text.light,
    },
    cardSubtitleIcon: {
      fontSize: 24,
      marginRight: theme.spacing(1),
      color: whiteBG.secondary[700],
    },
    cardSubtitleText: {},
    cardActive: {
      borderLeft: `${theme.spacing(1)}px solid ${whiteBG.primary[500]}`,
    },
    cardActiveLight: {
      borderLeft: `${theme.spacing(1)}px solid ${'#149dac'}`,
    },
    inputLabel: {
      marginBottom: theme.spacing(2),
      display: 'block',
    },
    presentationField: {
      marginBottom: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
    },
    presentationIcon: {
      marginRight: theme.spacing(1.5),
      fontSize: 18,
      color: whiteBG.primary[300],
    },
    inputField: {},
    inputIcon: {
      marginRight: theme.spacing(1),
      fontSize: 18,
      color: whiteBG.secondary[600],
      marginTop: -3,
    },
    cardContentTitleRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    ...(customStyles || {}),
  })
