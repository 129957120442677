import React from 'react'
import classNames from 'classnames'
import { Paper as MUIPaper } from '@material-ui/core'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'

interface IOwnProps {
  className?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    root: {},
    rounded: {
      borderRadius: 10,
    },
  })

const Paper: React.SFC<TProps> = ({ children, className, classes, ...rest }) => (
  <MUIPaper {...rest} className={classNames(classes.root, className)} classes={{ rounded: classes.rounded }}>
    {children}
  </MUIPaper>
)

export default withStyles(styles)(Paper)
