import { getCurrency } from 'utils/localStorage'

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"æøå]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function validEmail(value: string) {
  return new RegExp(emailPattern).test(value)
}

export function validPassword(pass: string) {
  return (
    pass.length >= 6 && // Minimum string length of 6 characters.
    pass.length <= 128 && // Maximum string length of 128 characters
    /[a-z]+/.test(pass) && // At least one non-capitalized letter.
    /[A-Z]+/.test(pass) // At least one capitalized letter.
  )
}

/**
 * Incuding umlauts for these languages:
 *  - Swedish and Finnish:  åäö + ÅÄÖ
 *  - Danish and Norwegian: øÆ + ØÅ (å + Å already included)
 */
export function validRegnumber(regnumber: string) {
  return /^[a-zA-Z0-9-åäöÅÄÖæøÆØÅ]+$/.test(regnumber)
}

export function validPhonenumber(phone: string) {
  return /^[0-9 +#-]{6,20}$/.test(phone)
}

export function validEan(ean: string) {
  return /^[0-9]{13}$/.test(ean.replace(' ', ''))
}

export function validZipcode(zipcode: string) {
  return /^[0-9 -]{3,20}$/.test(zipcode)
}

export const shortenPrettyIdentifier = (prettyIdentifier: string) =>
  prettyIdentifier ? prettyIdentifier.split('-').pop() : prettyIdentifier

export const zeroOrNumber = (value: string) => {
  if (!value || value.length === 0) {
    return 0
  }
  const parsedValue = parseNumber(value)
  return parsedValue || 0
}

// See https://stackoverflow.com/questions/9716468/pure-javascript-a-function-like-jquerys-isnumeric
export const parseNumber = (value: string) => {
  const parsedValue = parseFloat(value)
  if (!isNaN(parsedValue) && isFinite(+value)) {
    return parsedValue
  }
  return
}

export const numberWithSpaces = (n: number, digits: number = 0) => {
  if (n === 0) return n
  const parts = n.toFixed(digits).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export const formatToNumberWithSign = (n: number) =>
  n < 0 ? `- ${numberWithSpaces(Math.abs(n), 2)}` : n === 0 ? `${n}` : `+ ${numberWithSpaces(n, 2)}`

export const REQUIRED_VIN_LENGTH = 17

export const MAXIMUM_ADMITED_VALUE = 999999999

export const formatToNumberWithCurrency = (n: number) => `${numberWithSpaces(Math.abs(n), 2)} ${getCurrency()}`
