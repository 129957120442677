import {
  ContractType,
  ContractValueType,
  DurationOptions,
  IAdminCustomer,
  IContractCalculationResponse,
  IContractOptionResponse,
  IContractTemplateResponse,
  IGenericContractOptionResponse,
  IProductContractTemplateResponse,
  Other,
  PaymentGateway,
  PriceSpecification,
  ProductAlongItsContracts,
  TContractObject,
  TV4PTPaymentType,
  TV4PTProductType,
  Vehicle,
  VehicleAlongItsContracts,
  VehiclePartial,
} from '@omnicar/sam-types'

export enum ContractFlowActivePanel {
  searchVehicle = 0, // The Search Vehicle form, by reg. number or VIN.
  vehicle = 1, // Show vehicle details or vehicle model mapper, eg.
  startMileage = 2,
  template = 3,
  durationMileage = 4,
  options = 5,
  customer = 6,
  paymentGateway = 7,
}

export enum ContractFlowActiveStep {
  details = 1,
  payment = 2,
  send = 3,
}

export interface IContractFlowProviderCost {
  providerPayments: number
  providerShare: PriceSpecification
}

export type ContractFlowType = 'CREATE' | 'ADJUST'

export interface ICustomPrice extends IContractCalculationResponse {
  customAmountPrPayment: number
  noPayment: boolean
}

export interface IContractFlow {
  // v4ProductId: number // new
  v4ProductType: null | TV4PTProductType
  v4SupportedPaymentTypes: TV4PTPaymentType[]
  contractType: ContractType
  customMessage: string
  customTerms: string
  customer: IAdminCustomer
  customerId?: number
  duration: IContractFlowMileageDuration
  contractStartDateISO: null | Date
  flowType: ContractFlowType
  invalidPaymentData?: boolean
  invoiceCustomer: IAdminCustomer
  invoiceCustomerId?: number
  isCopiedContract?: boolean
  mileage: IContractFlowMileageDuration
  options: IGenericContractOptionResponse[]
  paymentGateway: PaymentGateway
  payments: ICustomPrice
  product: Vehicle | Other // The vehicle or product that is selected.
  contractObjectType: TContractObject
  reference: string
  startMileage: number | undefined
  startValue: number | undefined
  startValueType: ContractValueType
  state: IContractFlowState
  template: IContractTemplateResponse | IProductContractTemplateResponse | undefined
  value: IContractFlowMileageDuration | undefined
  valueType: ContractValueType
  vehicleAlongItsContracts: VehicleAlongItsContracts | ProductAlongItsContracts
  vehicleLookedUp?: VehiclePartial // The vehicle or product that will be prefilled.
  serviceInfo?: string[] // Service information mainly for ax.
  previousContractPrice?: PriceSpecification // only for adjustment/extending info
}

export interface IContractFlowState {
  active: ContractFlowActivePanel
  customerSearched: boolean
  durations: DurationOptions[] | null
  mileageDurationsMap: any | null
  licenseLookup: boolean
  licenseSkip: boolean
  options: IContractOptionResponse[]
  startMileage: boolean
  step: ContractFlowActiveStep
  templates: IContractTemplateResponse[]
  valid: { customer: boolean }
  isMileageDisabled: boolean // In case of if vehicleType is a 'Caravan'.
  hasGivenEnginePower?: boolean // Has the user set/given any engine power yet (only make sense if the looked up vehicle is missing engine power).
  userGivenEnginePowerKW?: number | null // The actual engine power value user has given, null means unknown engine power.
  originalEnginePowerKWFromLookup: number | null
  // hasVehicleActiveWarranty: boolean | undefined //NEWWWW // TODO: marko, this to be removed
  vehicleLookedUpFoundInPricefile?: boolean // Was the vehicle looked up, found in the pricefile or not?
  wasModelNameReMappedAtStartup?: boolean // After a lookup was the model name automatically (re)mapped to "correct" model name matched to one in pricefile, or not?
  activeTab?: FreeContractTab
}

export type FreeContractTab = 'VEHICLE' | 'PRODUCT'

export interface IContractFlowMileageDuration {
  max: number
  min: number
  step: number
  value: number
  wantedMax: number
  wantedValue: number
}

export enum ContractCalculationMethod {
  FirstRegDate = 100,
  ContractCreationDate = 200,
}
