import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogContent,
  FormControlLabel,
  Link,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import CookiesPolicyPopup from 'components/admin/CookiesPolicy'
import React, { ChangeEvent } from 'react'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { ICookiesConsent, saveCookiesConsent } from 'utils/cookies'

interface IProps extends WithStyles<typeof styles> {}

const cookiesList: {
  label: TranslationKey
  name: keyof ICookiesConsent
}[] = [
  {
    label: 'Analytics',
    name: 'analytics',
  },
]

const styles = () =>
  createStyles({
    button: {
      minWidth: '160px',
    },
    header: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    acceptSelectionButton: {
      marginRight: '1rem',
    },
    content: {
      padding: '25px',
      minWidth: '500px',
      minHeight: '200px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    link: {
      cursor: 'pointer',
    },
  })

interface IState {
  cookiesConsent: ICookiesConsent
  showDetails: boolean
}

class CookiesConsentBanner extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      cookiesConsent: {
        analytics: false,
      },
      showDetails: false,
    }
  }

  render = () => {
    const { classes } = this.props
    const { showDetails } = this.state
    return (
      <>
        <Dialog open={true}>
          <DialogContent className={classes.content}>
            <Typography variant="body2" className={classes.header}>
              {t('This website uses cookies')}
            </Typography>
            <Box>
              <FormControlLabel
                control={<Checkbox checked={true} name="necessary" disabled={true} />}
                label={<Typography variant="body2">{t('Necessary')}</Typography>}
              />
              {cookiesList.map(({ label, name }) => (
                <FormControlLabel
                  key={name}
                  control={<Checkbox checked={this.state[name]} onChange={this.handleChange} name={name} />}
                  label={<Typography variant="body2">{t(label)}</Typography>}
                />
              ))}
              <Link onClick={this.handleOpenPolicy} className={classes.link}>
                {t('Details')}
              </Link>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={this.handleAcceptSelection}
                className={classNames(classes.button, classes.acceptSelectionButton)}
              >
                {t('Allow selection')}
              </Button>
              <Button variant="contained" onClick={this.handleAcceptAll} color="primary" className={classes.button}>
                {t('Allow all cookies')}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        <CookiesPolicyPopup onClose={this.handleClosePolicy} open={showDetails} />
      </>
    )
  }

  handleOpenPolicy = () => this.setState({ showDetails: true })

  handleClosePolicy = () => this.setState({ showDetails: false })

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const cookiesName = e.target.name as keyof ICookiesConsent
    this.setState((state) => ({ cookiesConsent: { [cookiesName]: !state.cookiesConsent[cookiesName] } }))
  }

  handleAcceptSelection = () => {
    saveCookiesConsent(this.state.cookiesConsent)
    window.location.reload(true) // (!) Parameter seems important, even though it is deprecated in the specs (at the moment).
  }

  handleAcceptAll = () => {
    saveCookiesConsent({
      analytics: true,
    })
    window.location.reload(true) // (!) Parameter seems important, even though it is deprecated in the specs (at the moment).
  }
}
export default withStyles(styles)(CookiesConsentBanner)
