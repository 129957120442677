import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { HelpOutline as HelpIcon } from '@material-ui/icons'
import { IInvoice } from '@omnicar/sam-types'
import { getRetryAttemptsCount, postInvoicePayment } from 'api/api'
import SpinnerButton from 'components/Mui/SpinnerButton'
import React from 'react'
import { compose } from 'recompose'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { formatYearMonthDate } from 'utils/date'
import notify from 'utils/notify/notify'

interface IProps {
  invoice: IInvoice
  onSucceededPayment: (invoice: IInvoice) => void
  isCustomerPortal?: boolean
}

type TProps = IProps & WithStyles<typeof styles>

interface IState {
  isRetrySuccessful: boolean
  handlingRetry: boolean
  retryAttemptsLeft: number
  openDialog: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    buttonSpinner: {
      color: theme.palette.context.attention[300],
      marginRight: spacing(1),
    },
    tooltip: {
      maxWidth: spacing(75),
      padding: spacing(2),
      fontSize: spacing(1.5),
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    iconMargin: {
      color: theme.palette.error[500],
      marginLeft: spacing(1),
    },
  })

class RetryButton extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      isRetrySuccessful: false,
      handlingRetry: false,
      retryAttemptsLeft: 0,
      openDialog: false,
    }
  }

  public async componentDidMount() {
    this.loadRetryAttempts()
  }

  public render() {
    const { classes, invoice } = this.props
    const { isRetrySuccessful, handlingRetry, openDialog, retryAttemptsLeft } = this.state
    const hasRetryAttemptsLeft = retryAttemptsLeft && retryAttemptsLeft > 0

    return (
      <>
        <div className={classes.buttonContainer}>
          <SpinnerButton
            showSpinner={handlingRetry}
            disabled={isRetrySuccessful || !hasRetryAttemptsLeft}
            spinnerClasses={classes.buttonSpinner}
            variant="contained"
            color="primary"
            onClick={this.openDialog}
          >
            {t('Retry')}
          </SpinnerButton>
          {!hasRetryAttemptsLeft && (
            <Tooltip
              title={t('Contact support for help with retry failed payment')}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              classes={{ tooltip: classes.tooltip }}
            >
              <HelpIcon className={classes.iconMargin} />
            </Tooltip>
          )}
        </div>
        <Dialog open={openDialog}>
          <DialogContent>
            <Typography variant="h6">
              {t('There is a limitation related to retry attempts. Are you sure you want to retry failed payment?')}
            </Typography>

            <Typography variant="subtitle1" color="error">
              {t('You have %retryAttemptsLeft from 5 attempts left for the invoice: %invoiceDate', {
                retryAttemptsLeft,
                invoiceDate: formatYearMonthDate(invoice.dateIssuedAt),
              })}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRetryPaymentSubmit} variant="contained" color="secondary">
              {t('Retry')}
            </Button>
            <Button onClick={this.closeDialog} variant="text" color="primary">
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  private loadRetryAttempts = async () => {
    const { invoice, isCustomerPortal } = this.props
    const attemptsCountResponse = await getRetryAttemptsCount(invoice.invoiceId, !isCustomerPortal)

    if (attemptsCountResponse.statusCode === 200 && attemptsCountResponse.data !== undefined) {
      // Important - 5 attempts is the current limit!
      const retryAttemptsLeft = 5 - attemptsCountResponse.data

      this.setState({
        retryAttemptsLeft,
      })
    }
  }

  private openDialog = () => this.setState({ openDialog: true })

  private closeDialog = () => this.setState({ openDialog: false })

  private handleRetryPaymentSubmit = async () => {
    const { invoice, onSucceededPayment, isCustomerPortal } = this.props
    const { handlingRetry } = this.state

    if (handlingRetry) {
      return
    }

    this.setState({ handlingRetry: true })

    try {
      const res = await postInvoicePayment(invoice.invoiceId, !isCustomerPortal)

      if (!res.data) return
      if (res.data && res.data.status !== 'succeeded') return notify.error({ message: t('Something went wrong') })

      notify.success({ message: t('Success') })

      this.setState({
        isRetrySuccessful: true,
      })

      onSucceededPayment(invoice)
    } finally {
      this.setState({ handlingRetry: false })
      this.closeDialog()
      this.loadRetryAttempts()
    }
  }
}

export default compose<TProps, IProps>(withStyles(styles))(RetryButton)
