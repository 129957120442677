import { createStyles } from '@material-ui/core'
import { isShowLogoOnWeb, isShowLogoOnWebNonWhiteBG, isUseWhiteBGTheme } from 'utils/localStorage'

export default (theme: any) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(3),
      paddingTop: isShowLogoOnWebNonWhiteBG() ? theme.spacing(10.5) : theme.spacing(3),
      paddingRight: theme.spacing(1),
    },
    logoCutOut: {
      borderRadius: '50%',
      backgroundColor: 'white',
    },
    goHome: {
      paddingBottom: '20px',
      cursor: 'pointer',
    },
    absoluteBrandContainer: {
      position: 'absolute',
      borderRadius: '30px',
      backgroundColor: 'white',
      display: 'flex',
      flexWrap: 'wrap',
      top: '-10px',
      left: '-30px',
      padding: '20px 13px 7px 40px', // top right bottom left.
    },
    brandContainer: {
      display: 'flex',
    },
    brandLogo: {
      maxWidth: '400px',
      maxHeight: '110px',
      marginRight: '16px',
    },
    brand: {
      display: 'flex',
      alignItems: 'center',
    },
    companyName: {
      color: isUseWhiteBGTheme() || isShowLogoOnWeb() ? theme.palette.primary.main : theme.palette.primary.contrastText,
    },
    options: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(-2), // Note: To pull search field upwards.
    },
    search: {
      color: theme.palette.text.light,
      marginRight: theme.spacing(1),
    },
    languageSelector: {
      paddingTop: theme.spacing(2),
    },
    presentCountry: {
      paddingTop: theme.spacing(2),
      paddingLeft: '0.6em',
      paddingRight: '0.6em',
    },
    menu: {
      color: isUseWhiteBGTheme() ? theme.palette.primary.main : '#eee',
      fontSize: 26,
    },
    headerMenuIcon: {
      marginTop: '6px',
      paddingLeft: '0.4em',
      paddingRight: '0.4em',
      marginRight: '-0.2em',
    },
    accountCircleIcon: {
      marginLeft: '.1em',
    },
    moreVertIcon: {
      marginLeft: '-0.2em',
    },
  })
