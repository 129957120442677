import React from 'react'
import { Checkbox, createStyles, FormControl, FormControlLabel, Theme, WithStyles, withStyles } from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import { AppContext } from 'store/appContext'
import SettlementFilesUpload from 'components/admin/Contract/Details/SettlementFilesUpload'

interface IOwnProps {
  sendEmail: boolean
  handleChange: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    formControl: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      display: 'flex',
      justifyContent: 'start',
      minWidth: 120,
    },
  })

const SettlementConfirmationForm: React.SFC<TProps> = ({ classes, sendEmail, handleChange }) => {
  return (
    <AppContext.Consumer>
      {() => (
        <>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <FormControlLabel
              control={<Checkbox checked={sendEmail} onChange={handleChange} color="primary" />}
              label={t('Send settlement email to the customer')}
            />
          </FormControl>
          {sendEmail && <SettlementFilesUpload />}
        </>
      )}
    </AppContext.Consumer>
  )
}

export default withStyles(styles)(SettlementConfirmationForm)
