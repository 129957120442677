import React from 'react'
import ContractDetailsPage from 'pages/customer/ContractDetailsPage'
import Error404Page from 'pages/Error404Page/Error404Page'
import { WithStyles, withStyles } from '@material-ui/core'
import styles from './styles'
import { Switch, Route } from 'react-router'
import { homePagePath, stripeAddCardPath, customerContractDetailsPath } from 'routes/paths'
import StripeAddCardPage from 'pages/payment/StripeAddCardPage'
import { WithTracker } from 'components/Tracker/Tracker'

interface IOwnProps {
  initTranslations: () => Promise<void>
}
type TProps = WithStyles<typeof styles> & IOwnProps

class CustomerPortal extends React.Component<TProps> {
  public async componentDidMount() {
    this.props.initTranslations()
  }
  public render() {
    return (
      <Switch>
        <Route exact={true} path={homePagePath} render={WithTracker(ContractDetailsPage)} />
        <Route exact={false} path={customerContractDetailsPath()} render={WithTracker(ContractDetailsPage)} />
        <Route exact={true} path={stripeAddCardPath()} render={WithTracker(StripeAddCardPage)} />
        <Route path={'*'} render={WithTracker(Error404Page)} />
      </Switch>
    )
  }
}

export default withStyles(styles)(CustomerPortal)
