import { Button, TextField } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons'
import {
  Attachment,
  IInternalContractNoteResponse as Note,
} from '@omnicar/sam-types/types/admin/contract/internalContractNotes'
import classNames from 'classnames'
import SpinnerButton from 'components/Mui/SpinnerButton'
import { Form, Formik } from 'formik'
import React /*, { ChangeEvent }*/ from 'react'
import { AppContext } from 'store/appContext'
import { createPanelStyles } from 'theme'
import { t } from 'translations/translationFunctions'
import InternalNoteAttachments from './InternalNoteAttachments'
import InternalNoteFileUpload from './InternalNoteFileUpload'

interface IOwnProps {
  initialNote: Note
  onSubmit: (note: Note, uploadFiles?: File[]) => boolean
  onCancel: () => void
  initialDisplayAttachments: boolean
  disabled: boolean
  maxNoteLenght: number
  maxNrOfAttachmentFiles: number
  isExistingNote: boolean
  allowAttachmentsOnly?: boolean
  cancelDisabled?: boolean
  label?: string
  willUnmount?: boolean
  saving: boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    iconButton: {
      maxHeight: '30px',
      minWidth: '30px',
      width: 'fit-content',
      float: 'right',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      marginRight: theme.spacing(1),
    },
    flexGrow: {
      flexGrow: '1',
      marginRight: '10px',
    },
    marginBottom: {
      marginBottom: theme.spacing(1),
    },
    paddingTop: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>
interface IState {
  updatedAttachments: Attachment[]
  filesToUpload?: File[]
  attachmentsWasUpdated: boolean
  noteContent: Note
  saving: boolean
}

interface IFormValues {
  note: string
}

class InternalNoteInputForm extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      updatedAttachments: this.props.initialNote.attachments || [],
      attachmentsWasUpdated: false,
      noteContent: this.props.initialNote,
      saving: false,
      filesToUpload: [],
    }
  }

  public render() {
    const {
      onSubmit,
      onCancel,
      classes,
      disabled,
      maxNoteLenght,
      label,
      cancelDisabled,
      willUnmount,
      isExistingNote,
      initialDisplayAttachments,
    } = this.props
    const parentSaving = this.props.saving
    const { updatedAttachments, filesToUpload, saving, noteContent } = this.state
    const imageAttachments = updatedAttachments.filter((a) => a.fileType.toLocaleLowerCase() !== 'pdf')
    const pdfAttachments = updatedAttachments.filter((a) => a.fileType.toLocaleLowerCase() === 'pdf')

    return (
      <Formik
        initialValues={noteContent}
        validate={this.validate}
        // tslint:disable-next-line jsx-no-lambda
        onSubmit={async (values) => {
          // This component will be unmounted and thus should not update state right before/in the middle of unmounting
          !willUnmount && this.setState({ saving: true })
          const result = await onSubmit({ ...values, attachments: updatedAttachments }, filesToUpload)
          if (result) {
            !willUnmount && this.setState({ noteContent: this.props.initialNote, filesToUpload: [] })
          }
          !willUnmount && this.setState({ saving: false })
        }}
        enableReinitialize={true}
      >
        {({ values, errors, handleChange, handleSubmit }) => {
          const handleSave = () => {
            if (!errors.note) {
              handleSubmit()
            }
          }
          const handleUpdate = (e: React.ChangeEvent<any>) => {
            handleChange(e)
          }

          return (
            <Form>
              <div>
                <div className={classes.flexGrow}>
                  <TextField
                    name="note"
                    color="primary"
                    label={
                      !!label
                        ? `${label} (${values.note.length}/${maxNoteLenght})`
                        : ` (${values.note.length}/${maxNoteLenght})`
                    }
                    disabled={disabled || saving}
                    multiline={true}
                    fullWidth={true}
                    minRows={4}
                    variant="outlined"
                    value={values.note}
                    onChange={handleUpdate}
                    error={!!errors.note}
                    helperText={!!errors.note && errors.note}
                    inputProps={{ maxLength: maxNoteLenght }}
                  />
                </div>
                <div className={classes.paddingTop}>
                  <SpinnerButton
                    className={classNames(classes.iconButton, classes.marginBottom)}
                    onClick={handleSave}
                    disabled={
                      disabled ||
                      saving ||
                      parentSaving ||
                      !!errors.note ||
                      (values.note === noteContent.note &&
                        !updatedAttachments &&
                        (!filesToUpload || !filesToUpload.length))
                    }
                    variant="outlined"
                    showSpinner={saving}
                    IconComponent={SaveIcon}
                    color="secondary"
                  >
                    {t('Save')}
                  </SpinnerButton>
                  {!cancelDisabled && (
                    <Button
                      className={classes.iconButton}
                      onClick={onCancel}
                      variant="outlined"
                      disabled={disabled || saving || parentSaving}
                    >
                      <CancelIcon className={classes.panelActionsButtonIcon} />
                      {t('Cancel')}
                    </Button>
                  )}
                  <AppContext.Consumer>
                    {({ isSuperAdmin, providerInfo, role }) =>
                      (isSuperAdmin ||
                        (role === 'admin' &&
                          (providerInfo?.providerId === 461 || providerInfo?.parentProviderId === 461))) && (
                        // added temporary fix for allowing admins belogning to provider moberg bil (461) to add files.
                        <InternalNoteFileUpload
                          noteId={noteContent.internalContractNoteId || 0}
                          attachments={filesToUpload}
                          onSubmit={this.handleSaveAttachmentsOnly}
                          onUpdateAttachmentsList={this.handleUpdateFilesToUpload}
                          disabled={disabled || saving || parentSaving}
                          totalNrOfAttachements={updatedAttachments.length}
                          maxNrOfAttachmentFiles={this.props.maxNrOfAttachmentFiles}
                          allowAttachmentsOnly={isExistingNote}
                        />
                      )
                    }
                  </AppContext.Consumer>
                </div>
              </div>
              {!!updatedAttachments.length && (
                <AppContext.Consumer>
                  {({ isSuperAdmin }) => (
                    <InternalNoteAttachments
                      imageAttachments={imageAttachments}
                      pdfAttachments={pdfAttachments}
                      editing={isSuperAdmin}
                      initialDisplayAttachments={initialDisplayAttachments}
                      onRemoveAttachment={this.removeAttachment}
                    />
                  )}
                </AppContext.Consumer>
              )}
            </Form>
          )
        }}
      </Formik>
    )
  }

  private handleSaveAttachmentsOnly = async () => {
    const { onSubmit, initialNote } = this.props
    const { filesToUpload } = this.state
    if (filesToUpload && filesToUpload.length) {
      this.setState({ saving: true })
      const result = await onSubmit(initialNote, filesToUpload)
      if (!!result) {
        this.setState({ filesToUpload: [] })
      }
      this.setState({ saving: false })
    }
  }

  private validate = (values: IFormValues) => {
    const { allowAttachmentsOnly } = this.props
    const errors: Partial<IFormValues> = {}
    if (!allowAttachmentsOnly && (!values.note || !values.note.trim().length)) {
      errors.note = t('Note cannot be empty')
    }

    return errors
  }

  private handleUpdateFilesToUpload = (files: File[]) => {
    if (!files) return

    this.setState({ filesToUpload: files })
  }

  private removeAttachment = (attachmentUrl: string) => {
    const { updatedAttachments } = this.state
    const newList = updatedAttachments ? updatedAttachments.filter((a) => a.url !== attachmentUrl) : []
    this.setState({ updatedAttachments: newList, attachmentsWasUpdated: true })
  }
}

export default withStyles(styles)(InternalNoteInputForm)
