import {
  Checkbox,
  createStyles,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { Check as CheckIcon, Edit as EditIcon } from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import { ContractActionType } from '@omnicar/sam-types'
import { editSettleAction, TEditSettleAction } from 'actions/contractSettlement'
import classnames from 'classnames'
import { Form, FormikBag, FormikProps, withFormik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { compose, shallowEqual, withState } from 'recompose'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { getCurrency, getRole } from 'utils/localStorage'
import { bindDispatch } from 'utils/redux'

interface IOwnProps {
  id: string
  actionId: number
  created: Date | string
  shareComment: boolean
  amount: number
  settlementComment: string
  type: ContractActionType
  username: string
  isSettled: boolean
  isReadOnly: boolean
}

interface IStateProps {
  editable: boolean
  setEditable: (e: boolean) => boolean
}

interface ISettleActionFormValues {
  id: number
  shareComment: boolean
  settlementComment: string
}

interface IReduxDispatchProps {
  editSettleAction: TEditSettleAction
}

type TProps = IOwnProps &
  WithStyles<typeof styles> &
  FormikProps<ISettleActionFormValues> &
  IReduxDispatchProps &
  IStateProps

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    actionItem: {
      backgroundColor: '#C2CEE2',
      borderRadius: spacing(0.5),
      marginBottom: spacing(1.5),
      overflow: 'auto',
      padding: spacing(1),
    },
    headerActionContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerBottom: {
      display: 'flex',
      marginTop: -spacing(1),
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5),
      fontStyle: 'italic',
    },
    headerTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5),
    },
    rootComment: {
      resize: 'none',
      width: '100%',
      boxSizing: 'border-box',
      padding: spacing(1),
      overflowY: 'initial',
      border: 'none',
      '& fieldset': {
        backgroundColor: palette.background.paper,
      },
    },
    disabledComment: {
      overflowY: 'hidden',
      borderColor: 'transparent',
      '& fieldset': {
        borderColor: 'transparent !important',
        backgroundColor: 'unset',
      },
    },
    paymentAmount: {
      color: palette.secondary[500],
      fontWeight: 500,
    },
    payoutAmount: {
      color: palette.error.main,
      fontWeight: 500,
    },
    operationText: {
      color: palette.primary.main,
      paddingLeft: spacing(1.5),
    },
    createdText: {
      color: palette.primary.main,
      fontSize: spacing(1.5),
    },
    authorText: {
      color: palette.primary.main,
      fontSize: spacing(1.5),
      paddingRight: spacing(2),
    },
    actionText: {
      color: palette.primary.main,
      fontStyle: 'italic',
      fontSize: spacing(1.5),
      marginBottom: -spacing(1.25),
    },
    iconButton: {
      color: palette.primary.main,
      fontSize: spacing(2.5),
    },
    inputMultiline: {
      minHeight: `${spacing(2)}px !important`, //@TODO: fix this
    },
  })

const ActionItem: React.SFC<TProps> = ({
  classes,
  type,
  amount,
  created,
  actionId,
  username,
  isSettled,
  values,
  handleChange,
  handleSubmit,
  editable,
  setEditable,
  isReadOnly,
}) => {
  const isSettlementAllowed = getRole() === 'admin'
  const handleSaveComment = () => {
    setEditable(false)
    handleSubmit()
  }

  return (
    <AppContext.Consumer>
      {() => (
        <div className={classes.actionItem}>
          <Form>
            <div className={classes.headerActionContainer}>
              <div className={classes.headerTop}>
                <div>
                  <span className={classnames(classes.paymentAmount)}>{`${amount} ${getCurrency()} `}</span>
                  <span className={classes.operationText}>
                    {/* {t(ContractActionType[type] as keyof typeof ContractActionType)} */}
                    {t(ContractActionType['' + type])}
                  </span>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.shareComment}
                        onChange={handleChange}
                        name="shareComment"
                        color="primary"
                      />
                    }
                    label={t('Email was sent to the customer')}
                    disabled={
                      !editable ||
                      !isSettlementAllowed ||
                      (!values.shareComment && values.settlementComment === '') ||
                      isReadOnly
                    }
                  />
                </div>
                {!isSettled &&
                  !isReadOnly &&
                  (editable ? (
                    <IconButton onClick={handleSaveComment} aria-label={`save ${actionId} action`}>
                      <CheckIcon classes={{ root: classes.iconButton }} />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setEditable(true)} aria-label={`edit ${actionId} action`}>
                      <EditIcon classes={{ root: classes.iconButton }} />
                    </IconButton>
                  ))}
              </div>
            </div>
            <div className={classes.headerBottom}>
              <div className={classes.authorText}>{t('Author: %username', { username })}</div>
              <div className={classes.createdText}>{formatDate(created, { showTime: true })}</div>
            </div>
            <div>
              <OutlinedInput
                disabled={!editable || isSettled || !isSettlementAllowed || isReadOnly}
                labelWidth={0}
                value={values.settlementComment}
                classes={{
                  root: classes.rootComment,
                  disabled: classes.disabledComment,
                  inputMultiline: classes.inputMultiline,
                }}
                multiline
                name="settlementComment"
                onChange={handleChange}
              />
            </div>
          </Form>
        </div>
      )}
    </AppContext.Consumer>
  )
}

const EnhancedSettlementActionForm = withFormik<TProps, ISettleActionFormValues>({
  mapPropsToValues: (props: IOwnProps) => ({
    id: props.actionId,
    shareComment: props.shareComment,
    settlementComment: props.settlementComment,
    isReadOnly: props.isReadOnly,
  }),
  handleSubmit: async (values: ISettleActionFormValues, formikBag: FormikBag<TProps, ISettleActionFormValues>) => {
    const { setSubmitting, resetForm } = formikBag
    const { id, editSettleAction, actionId, shareComment, settlementComment } = formikBag.props
    if (!shallowEqual(values, { id: actionId, shareComment, settlementComment })) {
      setSubmitting(true)
      await editSettleAction(id, values)
    }
    resetForm()
  },
  enableReinitialize: true,
})(ActionItem)

export default compose<TProps, IOwnProps>(
  withStyles(styles),
  withState('editable', 'setEditable', false),
  connect(null, bindDispatch({ editSettleAction })),
)(EnhancedSettlementActionForm)
