import { Button, CardActions, CardContent, TextField } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core/'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { Edit as EditIcon, Search as SearchIcon } from '@material-ui/icons'
import { getLocale } from '@omnicar/sam-translate'
import { IsoLocale, UserRole } from '@omnicar/sam-types'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import { IReturnArgsVehicleLicenseLookup } from 'pages/admin/ContractFlowPage/Details'
import React, { ChangeEvent, KeyboardEvent } from 'react'
import { createPanelStyles } from 'theme'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { getCountry } from 'utils/localStorage'
import errors from 'utils/notify/errors'
import notify from 'utils/notify/notify'
import { validRegnumber } from 'utils/regex'

interface IOwnProps {
  onVehicleLicenseLookup: (regNoOrVIN: string, startMileage: number | null) => Promise<IReturnArgsVehicleLicenseLookup>
  onNoContractsFoundDuringReLookup: any
  onSkip: () => void
  onFreeContract: () => void
  userRole: UserRole
  flowType: string
  isFreeContract: boolean
  freeContractsAvailable: boolean
  showHeader: boolean
  productContractsAvailable: boolean
  areAllTemplatesDisabled: boolean
}

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  regNumberOrVIN: string
  error: string // Would like to use TError
  errorKey: TranslationKey
  errorTranslationReplacements?: { [key in string]: string }
  errorComment?: string
  loading: boolean
}

const styles = (theme: any) =>
  createPanelStyles(theme, {
    searchActions: {
      paddingTop: 1 * theme.spacing(1),
      paddingLeft: 2.5 * theme.spacing(1),
      paddingBottom: 3 * theme.spacing(1),
    },
    searchField: {
      marginRight: 2 * theme.spacing(1),
    },
    spinner: {
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginLeft: '1px',
      animationDuration: '550ms',
      color: '#111111',
    },
    searchButton: {
      marginTop: '14px',
      height: theme.spacing(4.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    icon: {
      fontSize: 16,
      marginRight: theme.spacing(1),
      verticalAlign: 'middle',
    },
    button: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
    manualSearchButton: {
      backgroundColor: theme.palette.primary[500],
      '&:hover': {
        backgroundColor: theme.palette.primary[700],
      },
    },
    freeContractButton: {
      marginLeft: theme.spacing(1),
      width: 'inherit',
      height: 'inherit',
      backgroundColor: theme.palette.primary[500],
      '&:hover': {
        backgroundColor: theme.palette.primary[700],
      },
    },
  })

class ContractFlowVehicleLookup extends React.Component<TProps, IState> {
  private locale: IsoLocale = 'da-DK'

  public state: IState = {
    regNumberOrVIN: '',
    error: '',
    errorKey: '',
    loading: false,
    errorComment: undefined,
  }

  public componentDidUpdate(oldProps: TProps) {
    const newLocale = getLocale()
    if (newLocale && newLocale !== this.locale) {
      this.locale = newLocale
      this.updateErrorTranslations()
    }
  }

  public render() {
    const {
      classes,
      onFreeContract,
      userRole,
      flowType,
      isFreeContract,
      freeContractsAvailable,
      showHeader,
      productContractsAvailable,
      areAllTemplatesDisabled,
    } = this.props
    const { loading, error, regNumberOrVIN, errorComment } = this.state

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{showHeader ? t('Vehicle') : ''}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card className={classes.cardActive} data-e2e="contractFlowVehicleLookup">
            <CardContent>
              <div style={{ width: '100%', display: 'flex' }}>
                <TextField
                  className={classNames(classes.searchField)}
                  autoFocus={true}
                  error={!!error}
                  fullWidth={true}
                  helperText={`${error}. ${errorComment || ''}`}
                  label={t('Enter License Plate Number or VIN')}
                  margin="dense"
                  onChange={this.handleChange}
                  onKeyUp={this.handleKeyPress}
                  value={regNumberOrVIN}
                  data-e2e="contractFlowVehicleLookup__input"
                  disabled={userRole === 'observer'}
                  InputProps={{ inputProps: { style: { fontSize: '1.25rem' } } }}
                />
                <span title={t('Search by license plate number or VIN')}>
                  <Button
                    className={classNames(classes.searchButton)}
                    onClick={this.handleLookup}
                    variant="contained"
                    disabled={userRole === 'observer'}
                  >
                    {loading ? (
                      <CircularProgress
                        className={classNames(classes.spinner)}
                        size={'1.5rem'}
                        variant="indeterminate"
                        disableShrink={true}
                      />
                    ) : (
                      <SearchIcon className={classNames(classes.icon)} />
                    )}
                    <span style={{ margin: '4px' }}>{t('Search')}</span>
                  </Button>
                </span>
              </div>
            </CardContent>
            {freeContractsAvailable && (
              <CardActions className={classNames(classes.searchActions)}>
                <span
                  title={
                    areAllTemplatesDisabled
                      ? t('Contract templates must be activated in order to perform a manual search')
                      : undefined
                  }
                >
                  <Button
                    className={classNames(classes.manualSearchButton)}
                    onClick={this.handleSkip}
                    variant="contained"
                    color="primary"
                    disabled={areAllTemplatesDisabled}
                    data-e2e={'ContractFlowActions__manualContract'}
                  >
                    <span title={t('Search manually on cars')}>{t('Manual Search')}</span>
                  </Button>
                </span>

                {(userRole === 'admin' || userRole === 'seller') && flowType !== 'ADJUST' && (
                  <span
                    title={
                      areAllTemplatesDisabled
                        ? t('Contract templates must be activated in order to create free contracts')
                        : undefined
                    }
                  >
                    <Button
                      className={classNames(classes.button, classes.freeContractButton)}
                      variant="contained"
                      color="primary"
                      onClick={onFreeContract}
                      disabled={isFreeContract || areAllTemplatesDisabled}
                      data-e2e={'ContractFlowActions__freeContract'}
                    >
                      <span
                        title={
                          productContractsAvailable
                            ? t(
                                'Here you can create a monthly billable contract for any vehicle or product and you can set the price and content yourself',
                              )
                            : t(
                                'Here you can create a monthly billable contract for any vehicle and you can set the price and content yourself',
                              )
                        }
                      >
                        <EditIcon className={classNames(classes.icon)} />
                        {t('Create Free/Custom Contract')}
                      </span>
                    </Button>
                  </span>
                )}
              </CardActions>
            )}
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      this.handleLookup(0)
    }
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchString: string = e.target.value
    const regNumberOrVIN: string = searchString.trim().toUpperCase()

    this.setState({ regNumberOrVIN, error: '', errorComment: '' })
  }

  private handleSkip = () => {
    this.props.onSkip()
  }

  private validateRegnumber = (regNumberOrVIN: string) => {
    // reg number should contain at least one char
    if (!regNumberOrVIN || regNumberOrVIN.length === 0) {
      return t('Field is required')
    }
    if (!validRegnumber(regNumberOrVIN)) {
      return t('Only letters and numbers are allowed')
    }
    return
  }

  private updateErrorTranslations = () => {
    const { error, errorKey, errorTranslationReplacements } = this.state
    if (error && errorKey) {
      this.setState({ error: t(errorKey || errors.GENERIC_ERROR, errorTranslationReplacements) })
    }
  }

  private handleLookup = async (startMileage: number) => {
    const regNumberOrVIN = this.state.regNumberOrVIN.trim()
    const country: string | null = getCountry()

    if (country && country.toLowerCase() === 'fi' && process.env.REACT_APP_ENVIRONMENT === 'development') {
      const messageToDeveloper: string =
        'Notice: Currently the price of a fresh lookup at netwheels.fi costs at least about 7 SEK (~0,66 euro) OR MORE per lookup (sometimes two request "light" + "tech").)'
      console.warn('Message to developer:\n\n' + messageToDeveloper)
      notify.warning({ message: 'Message to developer: ' + messageToDeveloper })
    }

    const error = this.validateRegnumber(regNumberOrVIN)

    if (error !== undefined) {
      this.setState({ error })
    } else {
      this.setState({ loading: true })
      const returnArgs: IReturnArgsVehicleLicenseLookup = await this.props.onVehicleLicenseLookup(
        regNumberOrVIN,
        startMileage,
      )
      this.setState({ loading: false })

      this.setState({
        error: returnArgs.error,
        errorKey: returnArgs.errorKey || '',
        errorTranslationReplacements: returnArgs.errorTranslationReplacements,
        errorComment: returnArgs.comment,
      })
    }
  }
}

export default withStyles(styles)(ContractFlowVehicleLookup)
