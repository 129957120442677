import { Button } from '@material-ui/core'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { t } from 'translations/translationFunctions'
import history from 'utils/history'
import './LogInPanel.css'

interface IParams {
  user?: string
}

interface IState {
  message: string
  backLink: string
  user: string
}

class User extends React.Component<RouteComponentProps<IParams>, IState> {
  public state = {
    user: '',
    message: '',
    backLink: '/',
  }

  // public UNSAFE_componentWillMount() {
  public componentDidMount() {
    const locationState: any = this.props.location.state
    const user = this.props.match.params.user || ''
    const message = (locationState as any).message ? (locationState as any).message : this.state.message
    const backLink =
      locationState && (locationState as any).backLink ? (locationState as any).backLink : this.state.backLink

    this.setState({
      user,
      message,
      backLink,
    })
  }

  public render() {
    const { handleBack } = this
    const { user, message } = this.state

    return (
      <div className="LogInPanel LogInPanel-User">
        <div className="LogInPanel__inner">
          <div className="LogInPanel__header">
            <h2 className="LogInPanel__title">{user}</h2>
          </div>
          <div className="LogInPanel__main">{message}</div>
          <div className="LogInPanel__action">
            <div className="LogInPanel__action-secondary" />
            <div className="LogInPanel__action-primary">
              <Button variant="contained" color="primary" onClick={handleBack}>
                {t('Back')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private handleBack = () => {
    history.replace(this.state.backLink)
  }
}

export default User
