import { CardContent, TextField } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import { CONFIG } from 'config'
import React, { ChangeEvent } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel, ContractFlowType } from 'types/contractFlow'
import { debugPrint } from 'utils/miscs'
import { IS_DEBUG_CALCULATE_PRICE_PRINT } from '../Payments/index'

const maxDigitsStartMileage: number = ('' + CONFIG.startMileageComponent.MAX_KM).length

const RESTRICTOR_MS = {
  interval: 500,
  timeout: 500,
}

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  hidden: boolean
  startMileageValue: number | undefined
  isUsingV4PTAsSource: boolean
  freeContract: boolean
  onStartMileageChange: (startMileage: number | undefined) => void
  flowType: ContractFlowType
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
  })

interface IState {
  startMileage: number | null
  oldStartMileage: number | undefined
}
class ContractFlowStartMileage extends React.Component<TProps, IState> {
  public state: IState = { startMileage: null, oldStartMileage: undefined }

  private delayedFunc: any = undefined

  public componentDidUpdate(oldProps: TProps) {
    const { startMileageValue } = this.props
    const { startMileage } = this.state

    if (!startMileage && startMileageValue) {
      this.setStartMileage(startMileageValue || 1)
    } else if (!this.props.startMileageValue && oldProps.startMileageValue) {
      this.setState({ startMileage: null })
    }
  }

  componentDidMount(): void {
    setInterval(() => {
      if (this.delayedFunc) {
        const env = CONFIG.env
        env !== 'production' &&
          console.debug('Triggering delayedFunc for relookup restrictor (this msg not shown in live)')

        this.delayedFunc()
        this.delayedFunc = undefined
      }
    }, RESTRICTOR_MS.interval)
  }

  public render() {
    const { active, activeEnum, classes, hidden, freeContract, flowType } = this.props
    const { startMileage } = this.state
    const isActive = active >= activeEnum

    return (
      <Panel disabled={!isActive} hidden={hidden}>
        <PanelHeader>
          <PanelTitle>{t('Start Mileage')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card
            className={isActive ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}
            data-e2e={'ContractFlowStartMileage'}
          >
            <CardContent>
              <TextField
                disabled={!isActive || flowType === 'ADJUST'}
                hidden={hidden}
                fullWidth={true}
                value={!startMileage ? '' : startMileage.toString()}
                margin="dense"
                label={t('Select start mileage for vehicle')}
                onChange={this.handleValueChange}
                type="number"
                InputProps={{
                  inputProps: { min: CONFIG.startMileageComponent.MIN_KM, max: CONFIG.startMileageComponent.MAX_KM },
                }}
              />
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private setStartMileage(startMileage: number | string) {
    let parsedValue: number = Number.parseInt('' + startMileage) || 0
    if (!parsedValue) {
      parsedValue = 0
    }

    this.setState({ startMileage: parsedValue, oldStartMileage: this.state.startMileage || undefined })
  }

  private handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    // Only use 6 first digits in string
    const valueDigits: string = value
      .replace(/[^0-9]/g, '')
      .replace(/[e]/g, 'x')
      .substr(0, 6)

    if (valueDigits.length > maxDigitsStartMileage) {
      return
    }

    this.setStartMileage(valueDigits) // Update UI of startMileage.

    if (!this.props.isUsingV4PTAsSource) {
      const parsedValue: number = Number.parseInt(valueDigits) || 0
      this.props.onStartMileageChange(parsedValue)
    } else {
      this.delayedFunc = () => {
        debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, 'MOUNTED: startMileageUpdate')

        setTimeout(() => {
          debugPrint(IS_DEBUG_CALCULATE_PRICE_PRINT, 'TRIGGERED: startMileageUpdate')
          this.delayedFunc = undefined
          const { startMileage } = this.state

          debugPrint(
            IS_DEBUG_CALCULATE_PRICE_PRINT,
            'ContractFlowStartMileage: firered new startMileage, startMileage = ' + startMileage,
          )
          this.props.onStartMileageChange(startMileage || 0)
        }, RESTRICTOR_MS.timeout)
      }
    }
  }
}

export default withStyles(styles)(ContractFlowStartMileage)
