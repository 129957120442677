import React, { ChangeEvent } from 'react'
import {
  Button,
  createStyles,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { CloudUpload as UploadIcon, Clear as ClearIcon, AttachFile as AttachFileIcon } from '@material-ui/icons'
import { t } from 'translations/translationFunctions'
import { getFileType } from 'utils/file'
import SpinnerButton from 'components/Mui/SpinnerButton'
import notify from 'utils/notify/notify'
import { trackEvent } from 'utils/analytics'
import { uploadVehicleBlacklistFile } from 'api/api'
import classNames from 'classnames'
import BlacklistFileUploadInstructions from './Instructions/index'
import Typography from '@material-ui/core/Typography'
import { LayoutActions, LayoutActionsLeft } from 'components/Mui/Layout'

interface IOwnProps {
  onUpload: () => void
  disabled: boolean
  allowedFileTypes: string
  activeProvider: number
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    icon: {
      fontSize: 14,
    },
    buttonIcon: {
      marginRight: spacing(1),
      fontSize: 14,
    },
    submitButton: {
      margin: 'auto',
      height: '10px',
      marginLeft: '10px',
    },
    instructionsWrapper: {
      marginTop: spacing(3),
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  uploadFile: File | undefined
  isLoading: boolean
}

class BlacklistFileUpload extends React.Component<TProps, IState> {
  state: Readonly<IState> = {
    uploadFile: undefined,
    isLoading: false,
  }

  render() {
    const { uploadFile, isLoading } = this.state
    const { classes, allowedFileTypes, disabled } = this.props
    return (
      <>
        <LayoutActions>
          <LayoutActionsLeft>
            {uploadFile ? (
              <FormControl>
                <Input
                  value={uploadFile.name}
                  placeholder={t('No file selected...')}
                  disableUnderline={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            // tslint:disable-next-line jsx-no-lambda
                            uploadFile: undefined,
                          })
                        }
                        data-e2e="DialogWorkshopOperationsUpload__clear-uploaded-file-button"
                      >
                        <ClearIcon className={classes.icon} />
                      </IconButton>
                    </InputAdornment>
                  }
                  data-e2e="DialogWorkshopOperationsUpload__filename-textfield"
                />
              </FormControl>
            ) : (
              <div /> // empty div to keep flexbox layout
            )}
          </LayoutActionsLeft>
          <input
            id="blacklist-file-picker"
            accept={allowedFileTypes}
            type="file"
            value={''}
            hidden={true}
            onChange={this.handleFileSelect}
            data-e2e="DialogWorkshopOperationsUpload__file-upload-input"
          />
          <label htmlFor="blacklist-file-picker">
            <Button variant="outlined" component="span" disabled={isLoading}>
              <AttachFileIcon className={classes.icon} />
              {t('Select file')}
            </Button>
          </label>
          <SpinnerButton
            className={classNames(classes.submitButton)}
            color="primary"
            variant="contained"
            disabled={!uploadFile || disabled || isLoading}
            showSpinner={isLoading}
            onClick={this.handleUploadFile}
          >
            <UploadIcon className={classes.buttonIcon} />
            {t('Upload')}
          </SpinnerButton>
        </LayoutActions>
        <div className={classes.instructionsWrapper}>
          <Typography variant="h6">{t('Upload instructions')}</Typography>
          <BlacklistFileUploadInstructions />
        </div>
      </>
    )
  }

  private handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.currentTarget.files

    // if file selected
    if (fileList && fileList.length) {
      const uploadFile: File = fileList[0]
      this.setState({ uploadFile })
    }
  }

  private handleUploadFile = async () => {
    const { uploadFile } = this.state
    const { activeProvider } = this.props

    // if file selected
    if (uploadFile) {
      const filetype: string = getFileType(uploadFile.name)
      trackEvent('File import', 'Vehicle blacklist upload', filetype)
      this.setState({ isLoading: true })

      const formData: FormData = new FormData()
      formData.append('vehicleblacklistdata', uploadFile)

      try {
        // disable buttons
        const res = await uploadVehicleBlacklistFile(filetype, formData, activeProvider)
        const data = res && (await res.json())
        if (data) {
          this.setState({ isLoading: false, uploadFile: undefined })
          notify.success({ message: t('File was uploaded') })
          this.props.onUpload()
          return
        }
      } catch (error) {
        notify.error()
        console.error(error)
      }
    }

    this.setState({ isLoading: false })
  }
}

export default withStyles(styles)(BlacklistFileUpload)
