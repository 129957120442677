import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, typography, breakpoints }: Theme) =>
  createStyles({
    providerCard: {
      padding: '14px',
      marginBottom: '10px',
    },
    providerInfoContainer: {
      paddingBottom: spacing(2),
    },
    flexbox: {
      display: 'flex',
    },
    providerName: {
      marginBottom: spacing(2),
      fontWeight: typography.fontWeightLight,
      color: theme.palette.text.dark,
      [breakpoints.down('md')]: {
        fontSize: 24,
      },
    },
    providerInfo: {
      color: theme.palette.text.regular,
      fontWeight: typography.fontWeightLight,
      [breakpoints.down('md')]: {
        color: 'white',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontSize: 16,
      },
    },
    linkIcon: {
      display: 'block',
      marginTop: 'auto',
      marginBottom: 'auto',
      padding: '5px',
      margin: '2px',
      marginRight: '10px',
      width: '30px',
      height: '30px',
      color: theme.palette.text.regular,
      [breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: '#0000001a',
          borderRadius: '20px',
        },
      },
      [breakpoints.down('md')]: {
        color: 'white',
      },
    },
    linkButton: {
      display: 'flex',
      '&, &:link, &:visited, &:hover, &:active': {
        textDecoration: 'none',
      },
      padding: '10px',
      [breakpoints.down('md')]: {
        marginBottom: '20px',
        borderRadius: '5px',
        color: 'white',
        backgroundColor: theme.palette.primary[500],
        '&:hover': {
          backgroundColor: theme.palette.primary[700],
          borderRadius: '5px',
        },
      },
    },
  })
