import React from 'react'
import classNames from 'classnames'
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles'

interface IOwnProps {
  className?: string
  disabled?: boolean
  hidden?: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      opacity: 1,
      marginBottom: spacing(3),
    },
    disabled: {
      opacity: 0.4,
    },
  })

const Panel: React.SFC<TProps> = ({ children, className, classes, disabled, hidden }) => (
  <div hidden={hidden} className={classNames(classes.root, className, disabled && classes.disabled)}>
    {children && children}
  </div>
)

export default withStyles(styles)(Panel)
