import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, typography }: Theme) =>
  createStyles({
    scrollPaper: {
      padding: spacing(2),
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      overflowY: 'visible',
      left: '-20px',
    },
    optionsScrollPaper: {
      padding: '0',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      overflowY: 'visible',
      minWidth: '320px',
    },
    grayedOut: {
      backgroundColor: 'rgba(0,0,0,0.2)',
      fontStyle: 'italic',
      padding: '5px',
      borderRadius: '5px',
    },
    menu: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    menuItem: {
      width: 'inherit',
      '&:not(:last-child)': {
        marginRight: spacing(2),
      },
    },
    optionsMenuItem: {
      minWidth: '320px',
      width: '320px',
      padding: '8px 10px',
      height: '50px',
    },
    indented: {
      paddingLeft: '40px',
    },
    menuItemIcon: {
      marginRight: spacing(1),
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterButton: {
      fontWeight: 'normal',
      color: theme.palette.context.attention[500],
      border: '1px solid',
      borderColor: theme.palette.context.attention[500],
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '2px',
      marginTop: '10px',
      marginBottom: '3px',
    },
    filterButtonClicked: {
      color: 'white',
      backgroundColor: theme.palette.context.attention[500],
    },
    infoWrapper: { width: '100%' },
    name: { marginBottom: spacing(1) },
    roleCaption: { marginTop: spacing(1) },
    button: {
      minWidth: '240px',
      width: '240px',
    },
    buttonIcon: { marginRight: spacing(1) },
    link: { textDecoration: 'none' },
    primaryIcons: {
      color: theme.palette.primary[500],
      marginLeft: 2 * spacing(1),
      marginRight: -1.5 * spacing(1),
    },
    seperatingLine: {
      minWidth: '320px',
      backgroundColor: theme.palette.border.light,
      height: '1.5px',
    },
    expandMoreLess: {
      opacity: 0.9,
      paddingRight: spacing(1),
      margingTop: 0,
      margingBottom: 0,
      paddingTop: '4px',
      paddingBottom: 0,
    },
  })
