import { Theme, createStyles } from '@material-ui/core'

export default ({ spacing }: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '75vh',
      opacity: 0.97,
    },
    content: {
      textAlign: 'center',
      marginBottom: '8%',
      paddingRight: spacing(4),
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '10rem',
      fontWeight: 700,
    },
    logo: {
      textAlign: 'center',
      width: '9rem',
    },
    subtitle: {
      marginTop: spacing(2),
    },
    link: {
      display: 'block',
      marginTop: spacing(2),
    },
  })
}
