import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'
import { createTypographyStyles } from 'theme'
import { isUseWhiteBGTheme } from 'utils/localStorage'

const labelColor = '#2a2b28'

interface IOwnProps {
  className?: string
  fullHeight?: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createTypographyStyles(theme, {
    root: {
      height: 70,
      color: isUseWhiteBGTheme() ? labelColor : theme.palette.primary.contrastText,
      letterSpacing: '1px',
      marginTop: isUseWhiteBGTheme() ? '30px' : '17px',
      paddingTop: '4px',
    },
    fullHeight: {
      height: 130,
    },
  })

const PageTitle: React.SFC<TProps> = ({ children, classes, className, fullHeight }) => (
  <div className={classNames(classes.root, fullHeight && classes.fullHeight, classes.header, className)}>
    {children && children}
  </div>
)

export default withStyles(styles)(PageTitle)
