import React from 'react'
import Typography from '../../../../../../../../components/Typography'
import image from '../background-img-product-workshop-import.jpg'
import { t } from '../../../../../../../../translations/translationFunctions'
import { formatMileage } from '@omnicar/sam-format'
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles'

interface IOwnProps {
  verticalSpacingClass: string
  validImportHeaders?: string[]
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    imageWrapper: {
      position: 'relative',
    },
    image: { width: '100%' },
    blackbox: {
      padding: spacing(1),
      backgroundColor: 'rgba(0,0,0,.9)',
      position: 'absolute',
      height: 'fit-content',
      top: '1%',
      bottom: '0%',
      left: '10%',
      right: '10%',
    },
    blackboxText: {
      color: 'white',
    },
    headers: {
      fontStyle: 'italic',
    },
    columnList: {
      listStyle: 'none',
      padding: 0,
      margin: `${spacing(1)} 0`,
    },
  })

class ImportInstructionsProduct extends React.Component<TProps> {
  render() {
    const { classes, verticalSpacingClass, validImportHeaders } = this.props
    const headers = this.getHeaders(validImportHeaders)

    return (
      <>
        <Typography className={verticalSpacingClass} variant="body1">
          <a href="/workshop_import_template.xlsx" target="_blank">
            {t('Download XLS example')}
          </a>
        </Typography>
        <div className={classes.imageWrapper}>
          <img className={classes.image} src={image} alt="" />
          <div className={classes.blackbox}>
            <Typography className={classes.blackboxText} variant="body1">
              {t('The first line must contain the headers ')}
              <em className={classes.headers}>
                {validImportHeaders
                  ? validImportHeaders.map((h, i) => (
                      <span key={`Import-Instructions-Product-${h}_${i}`}>
                        {h}
                        {i < validImportHeaders.length - 1 && ','}{' '}
                      </span>
                    ))
                  : `date, price, mileage/hours, item number, secondary item number, serial number, secondary serial number, description`}
              </em>
            </Typography>
            <ul className={classes.columnList}>
              <Typography className={classes.blackboxText} variant="body1">{`A:
                ${(headers && headers.dateHeader) || 'Date'} (${t(
                'Repair Date (date format: day/month/year)',
              )})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`B:
                ${(headers && headers.priceHeader) || 'Price'} (${t('Invoiced amount ex. tax')})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`C:
                ${(headers && headers.mileageHoursHeader) || 'Mileage/Hours'} (${t(
                'Current hours or mileage (km)',
              )})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">
                {`D:
               ${(headers && headers.itemNumberHeader) || 'Item Number'} (${t('Item Number')})`}
              </Typography>
              <Typography className={classes.blackboxText} variant="body1">{`E: ${
                (headers && headers.secondaryItemNumHeader) || 'Secondary Item Number'
              } ( ${t('Secondary Item Number')})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`F: ${
                (headers && headers.serialNumberHeader) || 'Serial Number'
              } (${t('Serial Number')})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`G: ${
                (headers && headers.secondarySerialNumHeader) || 'Secondary Serial Number'
              } (${t('Secondary Serial Number')})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`H: ${
                (headers && headers.descriptionHeader) || 'Description'
              } (${t('Text on invoice line')})`}</Typography>
            </ul>
            <Typography className={classes.blackboxText} variant="body1">
              {t('Max. %number lines per upload', {
                number: formatMileage(1000),
              })}
            </Typography>
          </div>
        </div>
      </>
    )
  }

  private getHeaders = (headers?: string[]) => {
    if (!headers || !headers.length) return undefined

    return {
      dateHeader: headers[0],
      priceHeader: headers[1],
      mileageHoursHeader: headers[2],
      itemNumberHeader: headers[3],
      secondaryItemNumHeader: headers[4],
      serialNumberHeader: headers[5],
      secondarySerialNumHeader: headers[6],
      descriptionHeader: headers[7],
    }
  }
}

export default withStyles(styles)(ImportInstructionsProduct)
