import React, { SFC } from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core'
import Blacklist from './BlacklistComponent'
import { IBlacklistedVehicle } from './BlacklistInput'

interface IProps extends WithStyles<typeof styles> {
  active: boolean
}

export type BlacklistedVehicleOrderByType = keyof IBlacklistedVehicle

const styles = ({ spacing }: Theme) =>
  createStyles({
    section: {
      '&:not(:last-child)': {
        marginBottom: spacing(3),
      },
    },
  })

const BlacklistTab: SFC<IProps> = ({ classes, active }) => (
  <div hidden={!active}>
    <section className={classes.section}>
      <Blacklist />
    </section>
  </div>
)

export default withStyles(styles)(BlacklistTab)
