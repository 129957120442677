import { ListItemIcon, ListItemText, Dialog, List, ListItem } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { Person as PersonIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons'
import React from 'react'
import { t } from 'translations/translationFunctions'
import styles from './styles'
import { AppContext } from 'store/appContext'
import Typography from 'components/Typography'

interface IOwnProps {
  open: boolean
  onToggle: () => void
  onLogOut: () => void
  onToggleAccountSettings: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

class AppHeaderMenuFloating extends React.Component<TProps> {
  public render() {
    const { classes, open, onToggle, onLogOut, onToggleAccountSettings } = this.props

    return (
      <AppContext.Consumer>
        {({ userInfo }) => (
          <Dialog
            onClose={onToggle}
            open={open}
            classes={{ scrollPaper: classes.scrollPaper, paper: classes.scrollPaper }}
            data-e2e={'AppHeader__account-dialog'}
          >
            <div>
              <Typography variant="title">{userInfo && userInfo.name}</Typography>
              <Typography variant="subtitle">{userInfo && userInfo.email}</Typography>
            </div>
            <List className={classes.menu} component="nav">
              <ListItem
                className={classes.menuItem}
                button={true}
                disableGutters={true}
                onClick={onToggleAccountSettings}
              >
                <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={t('My account')} />
              </ListItem>
              <ListItem className={classes.menuItem} button={true} disableGutters={true} onClick={onLogOut}>
                <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('Sign out')} />
              </ListItem>
            </List>
          </Dialog>
        )}
      </AppContext.Consumer>
    )
  }
}

export default withStyles(styles)(AppHeaderMenuFloating)
