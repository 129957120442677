import React, { SFC } from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core'
import ReportsSection from './ReportsComponent'

interface IProps extends WithStyles<typeof styles> {
  active: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    section: {
      '&:not(:last-child)': {
        marginBottom: spacing(3),
      },
    },
  })

const ReportsTab: SFC<IProps> = ({ classes, active }) => (
  <div hidden={!active}>
    <section className={classes.section}>
      <ReportsSection isActive={active} />
    </section>
  </div>
)

export default withStyles(styles)(ReportsTab)
