import { IOutageResponse } from '@omnicar/sam-types'
import { IContractFlow } from 'types/contractFlow'
import { applicationInitialState, IApplicationState } from './application/applicationInitialState'
import { contractDisplayConfigInitialState, IContractDisplayConfig } from './contractDisplayConfig/initialState'
import { contractFilterInitialState, IContractFilters } from './contractFilterParams/contractFilterInitialState'
import { contractFlowInitialState } from './contractFlow/contractFlowInitialState'
import {
  contractSearchInitialState,
  IContractSearchState,
  IWarrantySearchState,
  warrantySearchInitialState,
} from './contractSearch/contractSearchInitialState'
import { contractSettlementInitialState, IContractSettlementState } from './contractSettlement/contractSettlementState'
import { IReactRouterState, reactRouterInitialState } from './reactRouter/reactRouterInitialState'

export interface IRootState {
  readonly application: IApplicationState
  readonly router: IReactRouterState
  readonly contractFlow: IContractFlow
  readonly contractSearch: IContractSearchState
  readonly warrantySearch: IWarrantySearchState
  readonly outage: IOutageResponse | null
  readonly contractSettlement: IContractSettlementState
  readonly contractFilterParams: IContractFilters
  readonly contractDisplayConfig: IContractDisplayConfig
}

export const initialState: IRootState = {
  application: applicationInitialState,
  router: reactRouterInitialState,
  contractFlow: contractFlowInitialState,
  contractSearch: contractSearchInitialState,
  warrantySearch: warrantySearchInitialState,
  outage: null,
  contractSettlement: contractSettlementInitialState,
  contractFilterParams: contractFilterInitialState,
  contractDisplayConfig: contractDisplayConfigInitialState,
}
