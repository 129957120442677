import { createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default () => {
  return createStyles({
    root: {
      width: '100%',
    },
    paper: {
      position: 'relative',
      backgroundColor: theme.palette.background.light,
      border: 0,
      padding: 0,
      overflowY: 'visible',
    },
  })
}
