import React, { StatelessComponent, ChangeEvent } from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { MenuItem, FormControl, Select, FormHelperText, ListItemText, InputLabel, Input } from '@material-ui/core'
import styles from './styles'

interface IOwnProps {
  classes?: any
  helperText?: string
  label: string
  name: string
  onChange: (name: string, value: any) => void
  options: any[]
  selectedValue: number[]
  placeholder?: string
}
type TProps = IOwnProps & WithStyles<typeof styles>

const SingleSelect: StatelessComponent<TProps> = ({
  classes,
  selectedValue,
  name,
  placeholder,
  options,
  onChange,
  helperText,
  label,
}) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === '' ? [] : [e.target.value]
    onChange(name, value)
  }

  const value = selectedValue && selectedValue.length ? selectedValue[0] : ''

  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <InputLabel shrink={placeholder ? true : undefined} htmlFor={`select-${name}`}>
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={(e: ChangeEvent<any>) => handleChange(e)}
        input={<Input name={name} id={`${name}-label-placeholder`} />}
        name={name}
        className={classes.selectEmpty}
        displayEmpty={true}
      >
        {placeholder && (
          <MenuItem value="">
            <em className={classes.placeholder}>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={`select-${name}-${option.id}`} value={option.id}>
            <ListItemText primary={option.title} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default compose<TProps, IOwnProps>(withStyles(styles))(SingleSelect)
