import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'

interface IOwnProps {
  className?: string
  columns: 1 | 2 | 3 | 4 | 5 | 6
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      '&>*': {
        marginLeft: spacing(1.5),
        marginRight: spacing(1.5),
      },
      '&>*:first-child': {
        marginLeft: 0,
      },
      '&>*:last-child': {
        marginRight: 0,
      },
    },
    'columns-2': {
      '&>*': {
        flexBasis: '50%',
      },
    },
    'columns-3': {
      '&>*': {
        flexBasis: '33.3333%',
      },
    },
    'columns-4': {
      '&>*': {
        flexBasis: '25%',
      },
    },
    'columns-5': {
      '&>*': {
        flexBasis: '20%',
      },
    },
    'columns-6': {
      '&>*': {
        flexBasis: '16.6666%',
      },
    },
  })

const LayoutRow: React.SFC<TProps> = ({ children, classes, className, columns }) => (
  <section className={classNames(classes.root, classes[`columns-${columns}`], className)}>
    {children && children}
  </section>
)

export default withStyles(styles)(LayoutRow)
