import React, { Component } from 'react'
import { IReportSearchResponse, RowData, CellData, ColData, IsoLocale } from '@omnicar/sam-types'
import { WithStyles, withStyles, createStyles } from '@material-ui/core'
import { DbType } from '@omnicar/sam-types'
import { IDisplayConfigs, reportColor, borders } from './ReportConstants'
import { t } from 'translations/translationFunctions'
import { getProviderCountry } from 'utils/localStorage'
import { countryToLocale } from 'utils/locale'
import moment from 'moment'
import classNames from 'classnames'
import { theme } from 'theme'

const styles = () =>
  createStyles({
    tBody: {
      display: 'block',
      borderCollapse: 'collapse',
      whiteSpace: 'nowrap',
    },
    trOdd: {
      backgroundColor: theme.palette.secondary[50],
      border: 'none',
    },
    trEven: {
      backgroundColor: reportColor.evenRowBgColor,
      border: 'none',
    },
    trEmpty: {
      // not actually used, maybe if we reintroduze scroll cache
      backgroundColor: reportColor.emptyRowBgColor,
      border: 'none',
    },
    tdNumeric: {
      borderRight: borders.tableCellBorder,
      borderLeft: borders.tableCellBorder,
      whiteSpace: 'nowrap',
      height: '1.2em',
      maxHeight: '1.5em',
      paddingRight: '0.2em',
      paddingLeft: '0.2em',
      textAlign: 'right',
    },
    tdDate: {
      borderRight: borders.tableCellBorder,
      borderLeft: borders.tableCellBorder,
      whiteSpace: 'nowrap',
      height: '1.2em',
      maxHeight: '1.5em',
      paddingRight: '0.2em',
      paddingLeft: '0.2em',
      textAlign: 'right',
    },
    tdGeneral: {
      borderRight: borders.tableCellBorder,
      borderLeft: borders.tableCellBorder,
      whiteSpace: 'nowrap',
      height: '1.2em',
      paddingRight: '0.2em',
      paddingLeft: '0.2em',
      maxHeight: '1.5em',
    },
  })

interface IOwnProps {
  reportData: IReportSearchResponse | null
  searchId: number
  minRow: number
  maxRow: number
  displayConfigs: IDisplayConfigs
}
type TProps = IOwnProps & WithStyles<typeof styles>
interface IState {
  defaultDateLocale: IsoLocale
}

// ========================================================================
// Main Component
// ========================================================================

class ReportResultTableBody extends Component<TProps, IState> {
  private nfInt: Intl.NumberFormat
  private nfMileage: Intl.NumberFormat
  private nfMonetary: Intl.NumberFormat
  private dateFormat: string = 'YYYY-MM-DD'
  public state: IState = {
    defaultDateLocale: countryToLocale(getProviderCountry()),
  }

  constructor(props: TProps) {
    super(props)
    const locale = props.displayConfigs.locale || undefined
    this.nfInt = new Intl.NumberFormat(locale, { minimumFractionDigits: 0, useGrouping: false })
    this.nfMileage = new Intl.NumberFormat(locale, { minimumFractionDigits: 0, useGrouping: true })
    this.nfMonetary = new Intl.NumberFormat(locale, { minimumFractionDigits: 2, useGrouping: true })
    const country = getProviderCountry()
    const providerLocale = countryToLocale(country)
    console.log(locale || providerLocale)
  }

  public shouldComponentUpdate = (nextProps: TProps) => {
    return this.props.searchId !== nextProps.searchId
  }

  public render() {
    const { locale, localeFormats } = this.props.displayConfigs
    const rd = this.props.reportData
    const links = rd && rd.links
    this.dateFormat = (locale && localeFormats[locale]) || localeFormats[this.state.defaultDateLocale] || 'YYYY-MM-DD'

    if (!rd || !Object.keys(rd.cols).length) {
      return <div />
    }
    if (!rd.data || !rd.data.length) {
      return <h1>{t('No data found')}</h1>
    }
    return (
      <tbody key={this.key('body')}>
        {rd.data.map((r, ix) => this.renderReportRow(r, rd.cols, ix, links && links[ix]))}
      </tbody>
    )
  }

  // ========================================================================
  // Sub Renders
  // ========================================================================

  private renderReportRow = (row: RowData, cols: ColData, rowno: number, rowLinks: any) => {
    const { classes } = this.props
    const className = rowno % 2 ? classes.trOdd : classes.trEven
    const colEntries = Object.entries(cols)
    return (
      <tr className={classNames(className)} key={this.key('tr', rowno)}>
        {colEntries.map(([colName, colDef]) => this.renderCell(row[colName], colDef.type, rowno, colName, rowLinks))}
      </tr>
    )
  }

  private mapDbType2ClassName = new Map<DbType, string>([
    [DbType.monetary, this.props.classes.tdNumeric],
    [DbType.int, this.props.classes.tdNumeric],
    [DbType.date, this.props.classes.tdDate],
    [DbType.mileage, this.props.classes.tdNumeric],
    [DbType.text, this.props.classes.tdGeneral],
  ])

  private renderCell = (cellValue: CellData, type: DbType, rowno: number, colName: string, links: any) => {
    const { showLinks } = this.props.displayConfigs
    const link = links && links[colName]
    const className = this.mapDbType2ClassName.get(type)
    return (
      <td className={className} key={this.key('td', rowno, colName)}>
        {showLinks && link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {this.formatCellValue(cellValue, type)}
          </a>
        )}
        {!(showLinks && link) && this.formatCellValue(cellValue, type)}
      </td>
    )
  }

  private formatCellValue = (cellValue: CellData, type: DbType): string => {
    try {
      if (cellValue === null || cellValue === undefined) {
        return ''
      }
      if (typeof cellValue === 'object') {
        cellValue = JSON.stringify(cellValue)
      }
      switch (type) {
        case DbType.int:
          return this.nfInt.format(cellValue as number)
        case DbType.mileage:
          return this.nfMileage.format(cellValue as number)
        case DbType.monetary:
          return this.nfMonetary.format(cellValue as number)
        case DbType.text:
          return cellValue as string
        case DbType.date:
          return this.renderDateFormat(cellValue as string)
        default:
          return ''
      }
    } catch (err) {
      console.error(err)
      return '<ERR>'
    }
  }

  private renderDateFormat = (dateString: string): string => {
    // The date is returned in ISO format, with time set to 00:00 etc
    // which means that time values 23:30 (UTC) would be rendered as next day...
    // so we need truncate to ther 10 first characters ('YYYY-MM-DD'.length)
    return moment(dateString.substr(0, 10)).format(this.dateFormat)
  }

  // ========================================================================
  // Helpers
  // ========================================================================

  private key = (elem: string, rowIndex?: number, colName?: string): string => {
    return `key-${this.props.searchId}-${elem}-${rowIndex || 0}-${colName || ''}`
  }
}

export default withStyles(styles)(ReportResultTableBody)
