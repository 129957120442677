import React from 'react'
import { t } from 'translations/translationFunctions'
import { IStripeTOSAcceptance } from '@omnicar/sam-types'
import { getTOS, updateTOS } from 'api/api'
import { formatDate } from '@omnicar/sam-format'
import { Button } from '@material-ui/core'
import Typography from 'components/Typography/'
import './index.css'

const stripeAgreementUrl = {
  '': 'https://stripe.com/legal/ssa',
  dk: 'https://stripe.com/en-dk/legal/ssa',
  se: 'https://stripe.com/en-se/legal/ssa',
  fi: 'https://stripe.com/en-fi/legal/ssa',
  no: 'https://stripe.com/en-no/legal/ssa',
  de: 'https://stripe.com/en-de/legal/ssa',
}

interface IOwnProps {
  providerCountry: string | undefined | null
  onClick?: () => void
}

interface IState {
  loading: boolean
  tos: IStripeTOSAcceptance | undefined
  tosAccepted: boolean
  stripeAgreementUrl?: string
}

const initialState: IState = {
  loading: false,
  tos: undefined,
  tosAccepted: false,
}

export class StripeTOS extends React.Component<IOwnProps, IState> {
  public state: IState = {
    loading: false,
    tos: undefined,
    tosAccepted: false,
  }

  public constructor(props: IOwnProps) {
    super(props)

    let countryIsoCode = (this.props.providerCountry + '').toLowerCase()

    let state: IState = initialState
    state.stripeAgreementUrl = !stripeAgreementUrl[countryIsoCode]
      ? stripeAgreementUrl['']
      : stripeAgreementUrl[countryIsoCode]

    this.state = state
  }

  public componentDidMount() {
    this.loadTOS()
  }

  public render() {
    const { tos, tosAccepted } = this.state
    return (
      <section className="StripeTOS">
        <header className="StripeTOS__header">
          <Typography variant="title">{t('Stripe Conditions')}</Typography>
        </header>
        <div className="StripeTOS__main">
          {tosAccepted && (
            <>
              <div className="StripeTOS__main-info-accepted">
                <span>{t('Accepted')}</span>
                <span>{tos ? ' ' + formatDate(new Date(tos.date * 1000)) + ' ' + t('at ip') + tos.ip : ''}</span>
              </div>
            </>
          )}
          {!tosAccepted && tos && (
            <>
              <div className="StripeTOS__main-info-accept">{t('Click here to accept stripe conditions')}</div>
              <Button onClick={this.updateTOS}> {t('Accept Terms')}</Button>
            </>
          )}
          <div>
            {t('Click here to read about')}{' '}
            <a
              href={!this.state.stripeAgreementUrl ? 'https://stripe.com/legal' : this.state.stripeAgreementUrl}
              title={t('Stripe Services Agreement')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Stripe Services Agreement')}
            </a>
          </div>
        </div>
      </section>
    )
  }

  private loadTOS = async () => {
    const { loading } = this.state

    if (!loading) {
      this.setState({ loading: true })

      const tosResponse = await getTOS()

      if (tosResponse.data) {
        const tosData = tosResponse.data
        if (tosData.date != null) {
          this.setState({ loading: false, tos: tosResponse.data, tosAccepted: true })
        } else {
          this.setState({ loading: false, tos: tosResponse.data, tosAccepted: false })
        }
      } else {
        this.setState({ loading: false })
      }
    }
  }

  private updateTOS = async () => {
    const { loading } = this.state

    if (!loading) {
      this.setState({ loading: true })

      const tosResponse = await updateTOS()

      if (tosResponse.data) {
        const tosData = tosResponse.data
        if (tosData.date != null) {
          this.setState({ loading: false, tos: tosResponse.data, tosAccepted: true })
        } else {
          this.setState({ loading: false, tos: tosResponse.data, tosAccepted: false })
        }
      } else {
        this.setState({ loading: false })
      }
    }
  }
}

export default StripeTOS
