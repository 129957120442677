import Cookies from 'js-cookie'

// keys
const REFRESH_TOKEN_KEY = `auth_${process.env.REACT_APP_REFRESH_TOKEN_KEY || 'token'}`
const COOKIES_CONSENT_KEY = 'CookieConsent'
// durations
const REFRESH_TOKEN_DURATION = 5 // 5 days
const COOKIES_CONSENT_DURATION = 365 // 365 days
// config
const cookiesBaseConfig: Cookies.CookieAttributes = {
  secure: true,
  sameSite: 'Strict',
}
// we don't need to mention neccessary cookies here, because they are always enabled
export interface ICookiesConsent {
  analytics: boolean
}
/**
 * Gets refresh-token
 */
export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN_KEY)

/**
 * Saves refresh-token
 * @param token
 */
export const saveRefreshToken = (token: string) => {
  Cookies.set(REFRESH_TOKEN_KEY, token, {
    expires: REFRESH_TOKEN_DURATION,
    ...cookiesBaseConfig,
  })
}
/**
 * Removes refresh-token
 */
export const removeRefreshToken = () => Cookies.remove(REFRESH_TOKEN_KEY)

/**
 * Gets consent for cookies
 */
export const getCookiesConsent = (): ICookiesConsent | undefined => {
  const consent = Cookies.get(COOKIES_CONSENT_KEY)
  try {
    return consent ? JSON.parse(consent) : undefined
  } catch (error) {
    console.error(error)
    return undefined
  }
}
/**
 * Saves consent for cookies
 * @param token
 */
export const saveCookiesConsent = (consent: ICookiesConsent) => {
  Cookies.set(COOKIES_CONSENT_KEY, JSON.stringify(consent), {
    expires: COOKIES_CONSENT_DURATION,
    ...cookiesBaseConfig,
  })
}
