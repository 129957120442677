import React from 'react'
import { FormControl, Theme, WithStyles, withStyles, createStyles } from '@material-ui/core'
import DatePicker from 'components/DatePicker'
import moment, { Moment } from 'moment'

interface IOwnProps {
  expirationDate: string | Date
  onDateChange: (date: Moment | null) => void
  onMonthChange: (date: Moment) => void
  calculatedDate: Date
  currentDate: Moment
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      paddingTop: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(4),
    },
  })

const PendingTerminationForm: React.SFC<TProps> = ({
  calculatedDate,
  currentDate,
  onDateChange,
  classes,
  onMonthChange,
  expirationDate,
}) => {
  const blockDays = (day: Moment) => day.date() !== calculatedDate?.getDate()

  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <DatePicker
        id="pending-termination-date-picker"
        date={currentDate}
        onDateChange={onDateChange}
        datePickerWithoutInput
        isDayBlocked={blockDays}
        onMonthYearChange={onMonthChange}
        minDate={moment()}
        maxDate={moment(expirationDate).add(1, 'day')}
      />
    </FormControl>
  )
}

export default withStyles(styles)(PendingTerminationForm)
