import { Theme, createStyles } from '@material-ui/core'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    alignRight: {
      textAlign: 'right',
    },
    icon: {
      fontSize: 18,
    },
  })
