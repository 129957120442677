import { Card, CardContent, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { IPaginatedQueryParams, UserRoleRecord } from '@omnicar/sam-types'
import { getAllowedProvidersForBlacklists, getVehicleBlacklist } from 'api/api'
import ContractProviderSelect from 'components/admin/ContractProvider/Select'
import { LayoutBlock } from 'components/Mui/Layout'
import { Panel, PanelContent, PanelHeader } from 'components/Mui/Panel'
import Typography from 'components/Typography'
import React from 'react'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { getAuth } from 'utils/localStorage'
import { createQueryFromTable, createTableStateFromPagination, defaultTableState, ITableState } from 'utils/react-table'
import BlacklistInput from './BlacklistInput'
import BlacklistTable, { BlacklistedVehicleOrderByType, IBlacklistedVehicleResponse } from './BlacklistTable'

const styles = (theme: Theme) =>
  createStyles({
    inputWrapper: {
      overflow: 'visible',
      marginBottom: '10px',
    },
    headerTitle: { marginLeft: theme.spacing(-2) },
  })

type TProps = WithStyles<typeof styles>
export type RoleProvider = {
  id: number
  administrativeName: string
  inactive?: boolean
}

interface IState {
  loading: boolean
  initialProvider?: UserRoleRecord
  blackListedVehicles: IBlacklistedVehicleResponse[]
  activeContractProvider?: number
  tableQuery: IPaginatedQueryParams<BlacklistedVehicleOrderByType>
  tableState: ITableState
  providers: RoleProvider[]
  searchQuery: string
}

class Blacklist extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    const authData = getAuth()
    const currentRole =
      authData &&
      authData.roles &&
      authData.providerInfo &&
      authData.roles.find((r) => r.contractProvider.id === authData.providerInfo?.providerId)
    this.state = {
      loading: false,
      blackListedVehicles: [],
      initialProvider: currentRole,
      activeContractProvider: currentRole && currentRole.contractProvider.id,
      providers: [],
      searchQuery: '',
      tableState: {
        ...defaultTableState(10),
        sorted: [{ desc: true, id: 'id' }],
        resized: [],
      },
      tableQuery: {
        pagination: {
          limit: 10,
          offset: 0,
          orderBy: 'id',
          orderDirection: 'DESC',
        },
        search: '',
      },
    }
  }

  async componentDidMount() {
    const providers = await this.getAllowedProviders()
    this.loadData(providers)
  }

  render() {
    const {
      blackListedVehicles,
      initialProvider,
      tableQuery,
      tableState,
      searchQuery,
      activeContractProvider,
      providers,
    } = this.state
    const { classes } = this.props

    return (
      <Panel>
        <PanelHeader>
          <Typography className={classes.headerTitle} variant="subheading">
            {t('Vehicle Blacklist')}
          </Typography>
        </PanelHeader>
        <PanelContent>
          <AppContext.Consumer>
            {({ role }) =>
              !!providers &&
              !!providers.length &&
              role === 'admin' && (
                <LayoutBlock>
                  <ContractProviderSelect
                    initialProvider={initialProvider}
                    providers={providers}
                    onActiveProviderChanged={this.handleProviderChange}
                  />
                </LayoutBlock>
              )
            }
          </AppContext.Consumer>
          <AppContext.Consumer>
            {({ role }) => (
              <>
                {role === 'admin' && (
                  <Card className={classes.inputWrapper}>
                    <CardContent>
                      <BlacklistInput onCreated={this.handleBlacklistCreated} activeProvider={activeContractProvider} />
                    </CardContent>
                  </Card>
                )}
                <Card>
                  <CardContent>
                    <BlacklistTable
                      onDeletedRecord={this.loadData}
                      blackListedVehicles={blackListedVehicles}
                      onUpdateTableQueryAndState={this.setStateAndQuery}
                      onUpdateSearchQuery={this.handleSearchQueryUpdate}
                      tableQuery={tableQuery}
                      tableState={tableState}
                      searchQuery={searchQuery}
                      providerId={activeContractProvider}
                      readOnly={role !== 'admin'}
                    />
                  </CardContent>
                </Card>
              </>
            )}
          </AppContext.Consumer>
        </PanelContent>
      </Panel>
    )
  }

  private handleProviderChange = (providerId?: number) => {
    if (providerId && providerId !== this.state.activeContractProvider) {
      this.setState({ activeContractProvider: providerId }, this.loadData)
    }
  }

  private handleBlacklistCreated = () => {
    this.loadData()
  }

  private setStateAndQuery = (state?: Partial<ITableState>) => {
    const tableState: ITableState = { ...this.state.tableState, ...state }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleSearchQueryUpdate = (searchQuery: string) => {
    this.setState({ searchQuery }, this.loadData)
  }

  private getAllowedProviders = async () => {
    const res = await getAllowedProvidersForBlacklists()
    if (res.data) {
      return { providers: res.data }
    }

    return undefined
  }

  private loadData = async (additionalNewState?: any) => {
    const { loading, tableQuery, searchQuery, activeContractProvider } = this.state
    if (!loading) {
      this.setState({ loading: true })

      if (activeContractProvider) {
        const res = await getVehicleBlacklist(tableQuery, searchQuery, activeContractProvider)
        if (res.data) {
          // if (searching) {
          //     this.setState({ loading: false })
          //     return
          // }
          const { result, pagination } = res.data
          const newTableState = createTableStateFromPagination(pagination)
          const tableState = { ...this.state.tableState, ...newTableState }

          this.setState({
            loading: false,
            blackListedVehicles: result as IBlacklistedVehicleResponse[],
            tableState,
            ...additionalNewState,
          })
        } else {
          this.setState({ loading: false })
        }
      }
    }
  }
}

export default withStyles(styles)(Blacklist)
