import { Theme, createStyles } from '@material-ui/core'

export default ({ breakpoints }: Theme) =>
  createStyles({
    root: {
      lineHeight: '32px',
      [breakpoints.up('sm')]: {
        lineHeight: '64px',
      },
    },
  })
