import { Button, DialogActions, Theme, WithStyles, withStyles } from '@material-ui/core'
import { IAdminCustomer } from '@omnicar/sam-types'
import ContractFlowCustomer from 'components/admin/Contract/Flow/Customer'
import { LayoutColumn, LayoutRow } from 'components/Mui/Layout'
import React from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel } from 'types/contractFlow'

interface IOwnProps {
  customerLocked: boolean
  onCustomerChange?: (value: IAdminCustomer, valid: boolean) => void
  onCustomerLockedChange?: (locked: boolean) => void
  value: IAdminCustomer
  valid: boolean
  onClose: () => void
  customerUpdates: number
  dealerPaidWarranty: boolean
}

type TProps = WithStyles<typeof styles> & IOwnProps
// import classNames from 'classnames'

interface IState {
  changed: boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    freeContractTab: {
      backgroundColor: customTheme.palette.freeContract[500],
    },
  })

class CustomerWrapper extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    this.resetState()
  }

  public render() {
    const { classes, dealerPaidWarranty } = this.props
    return (
      <React.Fragment>
        <LayoutRow columns={1}>
          <LayoutColumn>
            <ContractFlowCustomer
              active={ContractFlowActivePanel.customer}
              activeEnum={ContractFlowActivePanel.customer}
              flow={'CREATE'}
              locked={this.props.customerLocked}
              onChange={this.handleCustomerChange}
              onLockedChange={this.handleLockedChange}
              value={this.props.value}
              valid={this.props.valid}
              freeContract={false}
              dealerPaidWarranty={dealerPaidWarranty}
              customerUpdates={this.props.customerUpdates}
            />
          </LayoutColumn>
        </LayoutRow>
        <DialogActions className={classes.actions}>
          <React.Fragment>
            {/* <Button
              className={classNames(classes.button, classes.createNewButton)}
              onClick={this.handleCancel}
              color="primary"
              variant="contained"
              data-e2e={'FreeWarrantyDialog__dialog-createnew-button'}
            >
              {t('Cancel')}
            </Button> */}
            <Button
              disabled={false}
              // {!this.state.changed}
              className={classes.button}
              onClick={this.handleDone}
              color="secondary"
              variant="contained"
              autoFocus={true}
              data-e2e={'FreeWarrantyDialog__dialog-close-button'}
            >
              {t('Done')}
              {/* Actually Next but for now Done */}
            </Button>
          </React.Fragment>
        </DialogActions>
      </React.Fragment>
    )
  }

  private resetState = () => {
    this.setState({ changed: false })
  }

  private handleDone = () => {
    this.resetState()
    this.props.onClose()
  }

  private handleCustomerChange = (value: IAdminCustomer, valid: boolean) => {
    this.props.onCustomerChange && this.props.onCustomerChange(value, valid)
    this.setState({ changed: true })
  }

  private handleLockedChange = (locked: boolean) => {
    this.props.onCustomerLockedChange && this.props.onCustomerLockedChange(locked)
    this.setState({ changed: true })
  }
}

export default withStyles(styles)(CustomerWrapper)
