import React, { ComponentType } from 'react'
import { WithStyles, withStyles, IconButton } from '@material-ui/core'
import styles from './styles'
import Typography from 'components/Typography'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

interface IProps extends WithStyles<typeof styles> {
  handleCloseButtonClick: () => void
  icon: ComponentType<SvgIconProps>
  heading: string
}

const AccountSettingsHeader: React.SFC<IProps> = ({ classes, handleCloseButtonClick, icon: Icon, heading }) => (
  <header className={classes.header}>
    <Typography className={classes.heading} variant="header">
      {heading}
    </Typography>
    <IconButton onClick={handleCloseButtonClick}>
      <Icon className={classes.btnIcon} />
    </IconButton>
  </header>
)
export default withStyles(styles)(AccountSettingsHeader)
