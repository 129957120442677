import { ContractState, IContractDetailsRecord, IWorkshopOperationCollection } from '@omnicar/sam-types'
import ContractDetailsEconomyOverview from 'components/admin/Contract/Details/EconomyOverview'
import ContractDetailsInvoiceList from 'components/admin/Contract/Details/InvoiceList'
import ContractDetailsWorkshopOperationList from 'components/admin/Contract/Details/WorkshopOperationList'
import React from 'react'
import { AppContext } from 'store/appContext'

interface IProps {
  contractPrettyId: string
  workshopOperationsUpdateCounter: number
  contractSpecifics: IContractDetailsRecord
  contractState: ContractState
  isReadOnly: boolean
  onWorkshopListUpdate: () => void
  handleWorkshopOperationsListChange: (operations: IWorkshopOperationCollection[]) => void
  serviceCount?: number
  isFromReportLink: boolean
}

const EconomyTab = ({
  contractState,
  contractPrettyId,
  workshopOperationsUpdateCounter,
  contractSpecifics,
  isReadOnly,
  onWorkshopListUpdate,
  handleWorkshopOperationsListChange,
  serviceCount,
  isFromReportLink,
}: IProps) => {
  const allowAddWorkshopOperation = [200, 210, 250, 400, 500, 600].includes(contractState)
  const allowRetryPayment = contractState !== ContractState.Settled // do not allow retry invoice payment for settled state

  return (
    <AppContext.Consumer>
      {({ locale }) => (
        <>
          <div>
            <ContractDetailsEconomyOverview
              id={contractPrettyId}
              updateCounter={workshopOperationsUpdateCounter}
              valueType={contractSpecifics.valueType}
              startValueType={contractSpecifics.startValueType}
              serviceCount={serviceCount}
              fromReportLink={isFromReportLink}
              settledUnits={contractSpecifics.settledUnits}
              contractState={contractState}
            />
            <ContractDetailsWorkshopOperationList
              id={contractPrettyId}
              allowEdit={!isReadOnly && allowAddWorkshopOperation}
              valueType={contractSpecifics.startValueType || contractSpecifics.valueType}
              onListUpdate={onWorkshopListUpdate}
              onLoadData={handleWorkshopOperationsListChange}
              locale={(locale as any) === '$$' ? 'en' : locale}
            />
            <ContractDetailsInvoiceList id={contractPrettyId} allowRetryPayment={!!allowRetryPayment} locale={locale} />
          </div>
        </>
      )}
    </AppContext.Consumer>
  )
}

export default EconomyTab
