import { tUntyped } from '@omnicar/sam-translate'
import { ContractState, IsoLocale } from '@omnicar/sam-types'
import { IReplacement, TranslationItem, TranslationKey } from './translationTypes'

/**
 * Translates a given phrase using replacements and a locale
 * @param key phrase to translate
 * @param replacements optional replacements as key/value object
 * @param locale optional locale to use for translations
 */
export function t(key: TranslationKey, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  return key && tUntyped(key, replacements, locale)
}

// Currencies, fuel types etc should not be added in the translation key array but checked separatelly
// TODO: All these entities should be defined in sam-types and included

export function tCurrency(key: string, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  return tUntyped(key.toUpperCase(), replacements, locale)
}

export function tFuelType(key: string, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  return tUntyped(key, replacements, locale)
}

export function tContractState(
  key: ContractState,
  replacements?: IReplacement,
  locale?: IsoLocale | undefined,
): string {
  return tUntyped(ContractState[key], replacements, locale)
}

export function tRole(key: string, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  return tUntyped(key, replacements, locale)
}

export function tChartName(key: string, replacements?: IReplacement, locale?: IsoLocale | undefined): string {
  const chartNames: TranslationKey[] = ['Contract activations', 'Contract offers']
  for (const chartName of chartNames) {
    if (key === chartName) {
      return t(key, replacements)
    }
  }
  return tUntyped(key, replacements, locale)
}

/**
 * Translates a translation item, that might be either a key (that should be translated)
 * or a value that not will be translated.
 * @param item
 * @param replacements
 * @param locale
 */
export function translateItem(item: TranslationItem, replacements?: IReplacement, locale?: IsoLocale | undefined) {
  if (!item) return ''
  if (item.key) return t(item.key, replacements, locale)
  if (item.value) return item.value
  return ''
}

/**
 * Checks if there exists either a avlue or a key
 * Equivalent to "return !!str" if the parameter would have been a string
 * E.g. to check if there exists an error message, either translated or untranslated
 * @param item
 * @returns
 */
export function isTranslationItemSet(item: TranslationItem): boolean {
  return !!item && !!(item.key || item.value)
}

export const tErrorComment = (key: string): string => tUntyped(key)
