import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

interface IOwnProps {
  className?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 56,
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  })

const PageActions: React.SFC<TProps> = ({ children, classes, className }) => (
  <aside className={classNames(classes.root, className)}>{children && children}</aside>
)

export default withStyles(styles)(PageActions)
