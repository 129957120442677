import React from 'react'
import { FormControl, IconButton, Input, InputAdornment } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'

export interface IOwnProps {
  name: string
  id: number
  iconClassName: string
  formControlClassName: string
  inputClassName: string
  removeAttachment: (id: number) => void
}

const FileUploadItem: React.SFC<IOwnProps> = ({
  name,
  id,
  iconClassName,
  formControlClassName,
  inputClassName,
  removeAttachment,
}) => (
  <FormControl classes={{ root: formControlClassName }}>
    <Input
      classes={{ input: inputClassName }}
      value={name}
      disableUnderline
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={() => removeAttachment(id)}>
            <ClearIcon className={iconClassName} />
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>
)

export default FileUploadItem
