import { CardContent, FormControlLabel, Radio, RadioGroup, Theme, WithStyles, withStyles } from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import { PaymentGateway } from '@omnicar/sam-types'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React, { ChangeEvent } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { ContractFlowType } from 'types/contractFlow'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'

interface IOwnProps {
  active: boolean
  value: PaymentGateway
  flow: ContractFlowType
  onChange: (paymentGateway: PaymentGateway) => void
  paymentGateways: PaymentGateway[] | undefined
  freeContract: boolean
}

type TProps = WithStyles<typeof styles> & IOwnProps

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
  })

const ContractFlowPaymentGateway: React.SFC<TProps> = (props: TProps) => {
  const { classes, value, onChange, paymentGateways, active, flow, freeContract } = props

  return (
    <Panel disabled={!active || flow === 'ADJUST'}>
      <PanelHeader>
        <PanelTitle>{t('Payment method')}</PanelTitle>
      </PanelHeader>
      <PanelContent>
        <Card
          className={
            active || flow === 'ADJUST'
              ? classNames(classes.cardActive, freeContract && classes.freeContractActive)
              : ''
          }
          data-e2e={'ContractFlowPaymentGateway'}
        >
          <CardContent>
            <RadioGroup
              name="paymentMethod"
              value={value}
              // tslint:disable-next-line:jsx-no-lambda
              onChange={(e: ChangeEvent<{}>, value: string) => {
                onChange(value as PaymentGateway)
              }}
            >
              {paymentGateways &&
                paymentGateways.map((paymentGateway: PaymentGateway, i: number) => (
                  <FormControlLabel
                    disabled={!active || flow === 'ADJUST'}
                    key={`ContractFlowPaymentGateway-label-${i}`}
                    value={paymentGateway}
                    control={<Radio />}
                    label={getGatewayLabel(paymentGateway)}
                  />
                ))}
            </RadioGroup>
          </CardContent>
        </Card>
      </PanelContent>
    </Panel>
  )
}

const getGatewayLabel = (paymentGateway: PaymentGateway) => {
  let label = ''

  switch (paymentGateway) {
    case 'V4':
      label = t('One-time Dealer Payment')
      break
    case 'Stripe':
      label = t('Customer Credit Card (standard)')
      break
    case 'B2B':
      label = t('EDI charge')
      break
    case 'NONE':
      label = t('Manual Payment (Cash)')
      break
    default:
      break
  }

  return label
}

export default withStyles(styles)(ContractFlowPaymentGateway)
