import React from 'react'
import { Button, WithStyles, Theme, withStyles } from '@material-ui/core'
import { Edit as EditIcon, DeleteOutlineOutlined as DeleteIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { createPanelStyles } from 'theme'

interface IOwnProps {
  editEnabled: boolean
  deleteEnabled: boolean
  onDelete: () => void
  onEdit: () => void
  disabled: boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    iconButton: {
      maxHeight: '30px',
      minWidth: '30px',
      float: 'right',
      marginRight: theme.spacing(1),
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

class InternalNoteActionButtons extends React.Component<TProps, never> {
  public render() {
    const { editEnabled, deleteEnabled, onDelete, onEdit, disabled, classes } = this.props

    return (
      <>
        {deleteEnabled && (
          <Button className={classNames(classes.iconButton)} onClick={onDelete} disabled={disabled} variant="outlined">
            <DeleteIcon />
          </Button>
        )}
        {editEnabled && (
          <Button className={classes.iconButton} onClick={onEdit} disabled={disabled} variant="outlined">
            <EditIcon />
          </Button>
        )}
      </>
    )
  }
}

export default withStyles(styles)(InternalNoteActionButtons)
