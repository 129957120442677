/* tslint:disable max-classes-per-file */
import ActionTypes from 'actions/ActionTypes'
import ActionTypeKeys from 'actions/ActionTypeKeys'
import { applicationInitialState } from './applicationInitialState'

export const applicationReducer = (state = applicationInitialState, action: ActionTypes) => {
  switch (action.type) {
    /***********************************************
     * Reducers for application
     *
     **********************************************/
    case ActionTypeKeys.APPLICATION_UPDATE:
      return {
        ...state,
        ...action.props,
      }

    case ActionTypeKeys.APPLICATION_RESET:
      return {
        ...applicationInitialState,
      }

    case ActionTypeKeys.APPLICATION_TRANSACTION_UPDATE:
      return {
        ...state,
        transaction: action.transaction,
      }

    case ActionTypeKeys.APPLICATION_TRANSACTION_RESET:
      return {
        ...state,
        transaction: applicationInitialState.transaction,
      }

    default:
      return state
  }
}
