import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { t } from 'translations/translationFunctions'
import { LayoutBlock } from 'components/Mui/Layout'
import React from 'react'
import { createDialogStyles } from 'theme'
import { IContractFlow, ContractFlowType } from 'types/contractFlow'
import memoize from 'lodash.memoize'
import classNames from 'classnames'
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons'
import errors from 'utils/notify/errors'
import { TranslationKey } from 'translations/translationTypes'
import { ResponseErrors } from '@omnicar/sam-types'

interface IOwnProps {
  contract: IContractFlow
  error: boolean
  errorMessage: ResponseErrors | ''
  open: boolean
  prettyIdentifier: string
  sending: boolean
  onClose: () => void
  onCreateNew: () => void
  onToOffer: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createDialogStyles(theme, {
    content: {
      minHeight: 111,
    },
    actions: {
      minHeight: 36,
    },
    text: {
      marginRight: theme.spacing(1),
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
      fontSize: 16,
    },
    toOfferButton: {
      marginleft: theme.spacing(1),
      paddingleft: theme.spacing(1),
    },
    closeButton: {
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    createNewButton: {
      marginRight: theme.spacing(1),
    },
  })

const getTitle = memoize(
  (error: boolean, localizedErrorMessage: string, flowType: ContractFlowType, sending: boolean): TranslationKey => {
    let title: TranslationKey

    if (error && localizedErrorMessage) {
      title =
        flowType === 'CREATE'
          ? 'An error occured while creating the contract offer: %ERRMSG'
          : 'An error occured while creating the contract adjustment offer: %ERRMSG'
    } else if (error && !localizedErrorMessage) {
      title =
        flowType === 'CREATE'
          ? 'An error occured while creating the contract offer'
          : 'An error occured while creating the contract adjustment offer'
    } else if (sending) {
      title = flowType === 'CREATE' ? 'Sending the offer' : 'Sending adjustment offer'
    } else {
      title = flowType === 'CREATE' ? 'The offer has been sent' : 'The adjustment offer has been sent'
    }

    return title
  },
)

const getText = memoize(
  (error: boolean, flowType: ContractFlowType, sending: boolean): TranslationKey => {
    let text: TranslationKey | ''

    if (error) {
      text = ''
    } else if (sending) {
      text =
        flowType === 'CREATE'
          ? 'Please hold on as we send the contract offer'
          : 'Please hold on as we send the contract adjustment offer'
    } else {
      text =
        flowType === 'CREATE'
          ? 'Click the button to proceed to the contract offer'
          : 'Click the button to proceed to the contract adjustment offer'
    }

    return text
  },
)

const ContractFlowSendDialog: React.SFC<TProps> = ({
  open,
  classes,
  error,
  errorMessage,
  sending,
  prettyIdentifier,
  contract,
  onClose,
  onToOffer,
  onCreateNew,
}) => {
  const { flowType } = contract
  const localizedErrorMessage = (errorMessage && errors[errorMessage] && t(errors[errorMessage])) || errorMessage

  const title = getTitle(error, localizedErrorMessage, flowType, sending)
  const text = getText(error, flowType, sending)
  const captionOnToOffer = flowType === 'CREATE' ? 'Proceed to contract offer' : 'Proceed to contract adjustment offer'

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      data-e2e={'ContractFlowSendDialog__dialog'}
    >
      <DialogTitle className={classes.title}>{t(title, { ERRMSG: localizedErrorMessage })}</DialogTitle>
      <DialogContent className={classes.content}>
        {sending ? (
          <React.Fragment>
            <LayoutBlock dense={true}>{text && t(text)}</LayoutBlock>
            <CircularProgress size={32} color="secondary" />
          </React.Fragment>
        ) : (
          <>
            <span className={classes.text}>{text && t(text)}</span>

            {!error && (
              <Button
                className={classNames(classes.button, classes.toOfferButton)}
                onClick={onToOffer}
                color="secondary"
                variant="contained"
                autoFocus={true}
                data-e2e={'ContractFlowSendDialog__dialog-proceed-button'}
              >
                {t(captionOnToOffer)}
              </Button>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!sending && (
          <React.Fragment>
            <Button
              className={classNames(classes.button, classes.closeButton)}
              onClick={onClose}
              variant="outlined"
              data-e2e={'ContractFlowSendDialog__dialog-back-button'}
            >
              <ArrowBackIosIcon className={classes.buttonIcon} />
              {t('Back')}
            </Button>

            {!error && (
              <Button
                className={classNames(classes.button, classes.createNewButton)}
                onClick={onCreateNew}
                color="primary"
                variant="contained"
                data-e2e={'ContractFlowSendDialog__dialog-createnew-button'}
              >
                {t('Create a new contract')}
              </Button>
            )}
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ContractFlowSendDialog)
