import { ContractActionType, IContractActionResponse, TActionOrderBy } from '@omnicar/sam-types'
import { getContractActionList } from 'api/api'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React from 'react'
import ReactTable, { Column, SortingRule } from 'react-table'
import {
  createQueryFromTable,
  createTableStateFromPagination,
  defaultTableState,
  ITableQuery,
  ITableState,
} from 'utils/react-table'
import 'react-table/react-table.css'
import { formatDate } from '@omnicar/sam-format'
import Paper from 'components/Mui/Paper'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import SuspendReasonCode from './SuspendReason/SuspendReasonCode'

interface IQuery extends ITableQuery<TActionOrderBy> {}

interface IOwnProps {
  id: string
}

type TProps = IOwnProps

interface IState {
  edit: boolean
  loading: boolean
  records: IContractActionResponse[]
  tableColumns: Column[]
  tableState: ITableState
  tableQuery: IQuery
}

/**
 * These are the labels/captions for column headers in the table.
 */
const headerKeys: { [key: string]: TranslationKey } = {
  id: 'ID',
  created: 'Date',
  userName: 'User',
  action: 'Type / Comment',
  comment: 'Details',
}

const headerText = (data: any): string => {
  if (data && data.column && data.column.id && typeof data.column.id === 'string') {
    return t(headerKeys[data.column.id])
  } else {
    return ''
  }
}

class ContractDetailsActionList extends React.Component<TProps, IState> {
  public static getColumns = (id: string): Column[] => [
    {
      Header: headerText,
      accessor: 'id',
      show: false,
    },
    {
      Header: headerText,
      accessor: 'created',
      headerClassName: 'ContractDetailsActionList__table-header',
      Cell: ({ value, original }: { value: string; original: IContractActionResponse }) => {
        const isError: boolean = original?.severityType === 'Error'
        const classNameError: string = !isError ? '' : 'text-color-error'

        return (
          <div className={'ContractDetailsActionList__table-header-date ' + classNameError}>
            {formatDate(new Date(value), { showTime: true })}
          </div>
        )
      },
    },
    {
      Header: headerText,
      accessor: 'userName',
      headerClassName: 'ContractDetailsActionList__table-header',
      Cell: ({ value, original }: { value: string; original: IContractActionResponse }) => {
        const isError: boolean = original?.severityType === 'Error'
        const classNameError: string = !isError ? '' : 'text-color-error'

        return <span className={classNameError}>{value}</span>
      },
    },
    {
      Header: headerText,
      accessor: 'action',
      headerClassName: 'ContractDetailsActionList__table-header',
      Cell: ({ value, original }: { value: string; original: IContractActionResponse }) => {
        const possibleTranslation = ContractActionType.hasOwnProperty(value) ? t(ContractActionType[value]) : value

        const isError: boolean = original?.severityType === 'Error'
        const classNameError: string = !isError ? '' : 'text-color-error'

        return (
          <div className={'state ' + classNameError}>
            <span title={possibleTranslation + ' - [eng: ' + ContractActionType[value] + ' code]'}>
              {!isError ? '' : t('Error') + ': '}
              {possibleTranslation}
            </span>
          </div>
        )
      },
    },
    {
      Header: headerText,
      accessor: 'comment',
      headerClassName: 'ContractDetailsActionList__table-header',
      minWidth: 130,
      Cell: ({ value, original }: { value: string; original: IContractActionResponse }) => {
        const isError: boolean = original?.severityType === 'Error'
        const classNameError: string = !isError ? '' : 'text-color-error'

        return (
          <span className={classNameError} title={'eng: ' + value}>
            <code>[eng]</code> {value}
            {original.action === ContractActionType.suspend && <SuspendReasonCode action={original} prettyId={id} />}
          </span>
        )
      },
    },
  ]

  public state: IState = {
    edit: false,
    loading: false,
    records: [],
    tableColumns: [],
    tableState: {
      ...defaultTableState(5),
      resized: [],
      sorted: [{ desc: true, id: 'created' }],
    },
    tableQuery: {
      search: '',
      pagination: {
        limit: 10,
        offset: 0,
        orderBy: 'created',
        orderDirection: 'DESC',
      },
    },
  }

  public componentDidMount() {
    this.initColumns()
    this.loadData()
  }

  public componentDidUpdate(prevProps: TProps) {
    if (this.props.id !== prevProps.id) {
      this.loadData()
    }
  }

  public render() {
    const { tableColumns, tableState, records, loading } = this.state

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{t('Events')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Paper className="ContractDetailsActionListTable">
            <ReactTable
              className="react-table"
              columns={tableColumns}
              data={records}
              defaultPageSize={5}
              defaultSorted={tableState.sorted}
              loading={loading}
              LoadingComponent={TableLoadingIndicator as React.ReactType}
              noDataText={!loading ? t('No Results Found') : ''}
              manual={true}
              minRows={3}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              page={tableState.page}
              pageSize={tableState.pageSize}
              pages={tableState.pages}
              showPaginationBottom={records.length > 0}
              pageText={t('Page')}
              ofText={t('of')}
              rowsText={t('rows')}
            />
          </Paper>
        </PanelContent>
      </Panel>
    )
  }

  private initColumns = () => {
    const tableColumns = ContractDetailsActionList.getColumns(this.props.id)

    this.setState({ tableColumns })
  }

  private loadData = async () => {
    const { loading, tableQuery } = this.state
    const { id } = this.props

    if (!loading) {
      this.setState({ loading: true })

      const response = await getContractActionList(id, tableQuery)

      if (response.data) {
        const { result, pagination } = response.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }

        this.setState({ loading: false, records: result, tableState })
      } else {
        this.setState({ loading: false })
      }
    }
  }

  private handlePageChange = (page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page, pageSize } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState: ITableState = { ...this.state.tableState, sorted }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }
}

export default ContractDetailsActionList
