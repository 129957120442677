import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { t } from 'translations/translationFunctions'
import './index.css'
import { Close as CloseIcon } from '@material-ui/icons'
import { TranslationKey } from 'translations/translationTypes'

interface IProps {
  open: boolean
  onClose: () => void
}

interface ICookie {
  name: string
  issuer: string
  purpose: TranslationKey
  duration: {
    value: number
    unit: TranslationKey
  }
  link?: string
}

const tableHeaders: { name: TranslationKey; class: string }[] = [
  { name: 'Name', class: 'small-cell' },
  { name: 'Issuer', class: 'small-cell' },
  { name: 'Purpose', class: 'big-cell' },
  { name: 'Duration', class: 'small-cell' },
]
const necessaryCookies: ICookie[] = [
  {
    name: '__auth...',
    issuer: 'Omnicar',
    purpose: 'Used to authenticate the user',
    duration: {
      value: 5,
      unit: 'days',
    },
  },
  {
    name: '__stripe_mid',
    issuer: 'Stripe',
    purpose:
      "This cookie is used by Stripe to uniquely identify a visitor during a session. It is typically set on the domain of the website or application where Stripe is integrated. The cookie helps Stripe to track the user's session and facilitate payment processing.",
    duration: {
      value: 1,
      unit: 'year',
    },
    link: 'https://stripe.com/cookies-policy/legal',
  },

  {
    name: '__stripe_sid',
    issuer: 'Stripe',
    purpose:
      "This cookie is used by Stripe to identify a user's session. It is typically set on the domain of the website or application where Stripe is integrated and is used to maintain session state for security purposes.",
    duration: {
      value: 30,
      unit: 'minutes',
    },
    link: 'https://stripe.com/cookies-policy/legal',
  },
]
const functionalCookies: ICookie[] = [
  {
    name: 'CookieConsent',
    issuer: 'Omnicar',
    purpose: 'Stores the user’s cookie consent state for the current domain',
    duration: {
      value: 1,
      unit: 'year',
    },
  },
]
const analyticalCookies: ICookie[] = [
  {
    name: '_ga',
    issuer: 'Google Analytics',
    purpose:
      'Used by Google Analytics to distinguish users. It is similar to the _gid cookie but has a longer expiration time. This cookie is typically set when a user first visits a website and is used to track unique visitors over time.',
    duration: {
      value: 2,
      unit: 'years',
    },
    link: 'https://policies.google.com/privacy',
  },
  {
    name: '_gid',
    issuer: 'Google Analytics',
    purpose:
      'Used by Google Analytics to distinguish users. It stores a unique identifier for each user visiting a website. This cookie is set on each page load and is used to differentiate between different users and sessions.',
    duration: {
      value: 24,
      unit: 'hours',
    },
    link: 'https://policies.google.com/privacy',
  },
  {
    name: '_gat',
    issuer: 'Google Analytics',
    purpose:
      'Used by Google Analytics to throttle request rate, limiting the collection of data on high-traffic websites. This cookie does not store any user information. It is set to ensure that data is collected in a controlled manner and to prevent excessive server load.',
    duration: {
      value: 1,
      unit: 'minute',
    },
    link: 'https://policies.google.com/privacy',
  },
]
const tables: { title: TranslationKey; cookies: ICookie[] }[] = [
  {
    title: 'Necessary',
    cookies: necessaryCookies,
  },
  {
    title: 'Functional',
    cookies: functionalCookies,
  },
  {
    title: 'Analytical',
    cookies: analyticalCookies,
  },
]

const CookiesPolicyPopup: React.SFC<IProps> = ({ onClose, open }) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Box className="CookiesPolicy__header">
          <Typography variant="h5">{t('Cookies Policy')}</Typography>
          <Button variant="text" onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Typography variant="body1" paragraph>
          {t(
            'We use cookies on this website in order to gather user statistics, improve your user experience and be able to ensure that the site works properly.',
          )}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'We may occasionally update this information as needed. Therefore, we recommend that you check this page regularly to find the most up-to-date information about the cookies we use on our website.',
          )}
        </Typography>

        <Typography variant="h6" paragraph>
          {t('What are cookies?')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'A cookie is a text file that is stored on your computer, mobile phone or tablet when you visit a website. We use the following types of cookies on our website: Persistent Cookie, First-Party Cookie, Secure Cookie, Third-Party Cookies.',
          )}
        </Typography>

        <Typography variant="body1" paragraph>
          {t('The cookies that we use:')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            "Third-Party Cookies: Third-party cookies are set by domains or websites other than the one the user is currently visiting. These cookies are often used for tracking and advertising purposes, allowing third-party services to collect data about the user's browsing behavior across different websites.",
          )}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            "Persistent Cookies: Persistent cookies, also called permanent or stored cookies, are cookies that remain on a user's device for a specified duration, even after the browsing session ends.",
          )}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'First-Party Cookies: First-party cookies are set by the website or domain that the user is directly interacting with. They are used to enhance user experience, remember user preferences, and provide personalized content.',
          )}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'Secure Cookies: Secure cookies are cookies that are transmitted over an encrypted connection (HTTPS) between the browser and the server. They are used to ensure the confidentiality and integrity of the data exchanged between the user and the website.',
          )}
        </Typography>

        <Typography variant="h6" paragraph>
          {t('Why and how do we use cookies?')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'We use cookies for multiple purposes, such as to improve the services we offer you. Some of our services actually require cookies to be able to work, while others have the sole purpose of making our services run smoother for you. We have chosen to categorise our cookies and their purpose in accordance with the following:',
          )}
        </Typography>

        <Typography variant="h6" paragraph>
          {t('Necessary')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'Data from these cookies enables you to use our website and its functions. These cookies are necessary for the website to be able to function correctly. Without necessary cookies, certain services on our website wouldn’t work at all, or the website wouldn’t run as smoothly as it should. These cookies do not collect any information for purposes such as marketing.',
          )}
        </Typography>

        <Typography variant="h6" paragraph>
          {t('Functional')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'Functional cookies are used to facilitate your use of our website. Functional cookies enable the website to remember any choices you make, such as responses to a form or previous language choices, so that you do not have to repeat these choices every time you visit our website.',
          )}
        </Typography>

        <Typography variant="h6" paragraph>
          {t('Analytical')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'Analytical cookies gather information on how a website is used in order to improve its functionality. For example, these cookies can reveal which pages are the most visited, help to document problems with a website and show if the advertising on a website has been effective or not.',
          )}
        </Typography>

        <Typography variant="h6" paragraph>
          {t('How can I check and manage the cookies used on your website?')}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'Normally, your web browser or device allows you to change the settings for the cookies in use and the extent of their use. Go to your browser or device settings to read more about how to adjust the settings for cookies. For example, you can choose to block all cookies, only accept cookies from first parties or delete cookies once you close the browser.',
          )}
        </Typography>

        <Typography variant="body1" paragraph>
          {t(
            'If you choose to remove cookies or disable future cookies, there is a risk that you won’t be able to access certain pages or functions on our website. If you adjust your cookie settings, this also affects other websites that you visit.',
          )}
        </Typography>

        {tables.map((table) => (
          <div key={table.title} className="CookiesPolicy__table-wrapper">
            <Typography variant="h5" paragraph>
              {t(table.title)}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                    <TableCell key={header.name} classes={{ root: `CookiesPolicy__${header.class}` }}>
                      {t(header.name)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {table.cookies.map((cookie) => (
                  <TableRow key={cookie.name}>
                    <TableCell>{cookie.name}</TableCell>
                    <TableCell>
                      {cookie.link ? (
                        <a href={cookie.link} target="_blank" rel="noopener noreferrer">
                          {cookie.issuer}
                        </a>
                      ) : (
                        cookie.issuer
                      )}
                    </TableCell>
                    <TableCell>{t(cookie.purpose)}</TableCell>
                    <TableCell>{`${cookie.duration.value} ${t(cookie.duration.unit)}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default CookiesPolicyPopup
