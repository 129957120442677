import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing }: Theme) => {
  return createStyles({
    paper: {
      width: '75%',
    },
    header: {
      ...theme.palette.gradients.secondary,
      padding: spacing(3),
      marginBottom: spacing(3),
    },
    link: {
      '&, &:active, &:visited': {
        color: 'white',
        textDecoration: 'none',
      },
    },
    providerInfo: {
      color: 'white',
    },
    userInfo: {
      marginLeft: spacing(3),
      marginRight: spacing(3),
    },
    menu: {
      listStyle: 'none',
      paddingLeft: 0,
    },
    menuButton: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: spacing(3),
      marginRight: spacing(3),
      color: theme.palette.text.light,
    },
    iconRight: { marginRight: spacing(1) },
  })
}
