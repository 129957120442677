import React from 'react'
import classNames from 'classnames'
import { Card as MUICard } from '@material-ui/core'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'

interface IOwnProps {
  className?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    root: {},
    cardRoot: {
      borderRadius: 10,
    },
  })

const Card: React.SFC<TProps> = ({ children, className, classes, ...rest }) => (
  <MUICard {...rest} className={classNames(classes.root, className)} classes={{ root: classes.cardRoot }}>
    {children}
  </MUICard>
)

export default withStyles(styles)(Card)
