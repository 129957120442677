import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    hr: {
      width: '75%',
      marginTop: spacing(3),
      marginBottom: spacing(3),
      border: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
    },
    header: {
      marginBottom: spacing(2),
    },
    headerWrap: {
      display: 'flex',
    },
    headerTemplateName: {
      marginRight: spacing(3),
    },
    stateActive: {
      color: theme.palette.context.accept[500],
    },
    statePending: {
      color: theme.palette.context.attention[500],
    },
    stateInactive: {
      color: theme.palette.context.warning[500],
    },
    linkActivationIcon: {
      marginRight: spacing(1),
    },
    alignRight: {
      marginLeft: 'auto',
    },
    textWhite: {
      color: 'white',
    },
    activationButtonMobile: {
      marginBottom: spacing(2),
      textAlign: 'center',
    },
    additionalOptions: {
      marginTop: spacing(3),
    },
    additionalOptionsTitle: {
      marginBottom: spacing(2),
      marginTop: spacing(3),
    },
  })
