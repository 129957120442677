import * as Yup from 'yup'

type YupTest = {
  name: string
  message: string | ((params: object & Partial<Yup.TestMessageParams>) => string)
  test: (this: Yup.TestContext, value?: any) => boolean | Yup.ValidationError | Promise<boolean | Yup.ValidationError>
  callbackStyleAsync?: boolean
}

export const stringNotEmpty = (errorMessage: string): YupTest => {
  return {
    name: 'notEmpty',
    message: errorMessage,
    test: (value) => value && value.trim(),
  }
}

export const stringMaxLength = (max: number, errorMessage: string): YupTest => {
  return {
    name: 'lengthLessThanMax',
    message: errorMessage,
    test: (value) => value && value.length < max,
  }
}
