import ActionTypes from 'actions/ActionTypes'
import ActionTypeKeys from 'actions/ActionTypeKeys'
import { contractSettlementInitialState } from './contractSettlementState'
import { arrToHash, modifyFiles } from 'utils/redux'

export const contractSettlementReducer = (state = contractSettlementInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.SAVE_CONTRACT_STATISTICS:
      return {
        ...state,
        balanceStatistics: { ...action.payload },
      }
    case ActionTypeKeys.SAVE_SETTLE_ACTIONS:
      return {
        ...state,
        settleActions: {
          ...arrToHash(action.payload),
        },
        attachments: contractSettlementInitialState.attachments,
      }
    case ActionTypeKeys.UPDATE_SETTLE_ACTIONS:
      return {
        ...state,
        settleActions: {
          ...state.settleActions,
          ...arrToHash(action.payload),
        },
      }
    case ActionTypeKeys.SAVE_SETTLEMENT_ATTACHMENTS:
      return {
        ...state,
        attachments: {
          ...state.attachments,
          ...modifyFiles(state.attachments, action.payload),
        },
      }
    case ActionTypeKeys.REMOVE_SETTLEMENT_ATTACHMENT: {
      const attachments = { ...state.attachments }
      delete attachments[action.payload]
      return {
        ...state,
        attachments,
      }
    }
    case ActionTypeKeys.SET_SETTLEMENT_ACTIONS_FILTER:
      return {
        ...state,
        actionFilters: {
          ...state.actionFilters,
          [action.payload.name]: action.payload.value,
        },
      }
    case ActionTypeKeys.RESET_SETTLEMENT_ACTION_FILTERS:
      return {
        ...state,
        actionFilters: contractSettlementInitialState.actionFilters,
      }
    default:
      return state
  }
}
