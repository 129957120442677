import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing }: Theme) =>
  createStyles({
    showMore: {
      width: '100%',
      border: 0,
      outline: 0,
      padding: spacing(2),
      cursor: 'pointer',
      userSelect: 'none',
      textAlign: 'center',
    },

    borderTop: {
      borderTop: `1px solid ${theme.palette.border.light}`,
    },

    root: {
      marginTop: spacing(2),
    },
  })
