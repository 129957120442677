import {
  ContractListOrderByType,
  IContractListRecord,
  IPaginatedQueryParams,
  TContractObject,
} from '@omnicar/sam-types'
import { getCustomerContracts } from 'api/api'
import React from 'react'
import ReactTable, { Column, SortingRule } from 'react-table'
import { t } from 'translations/translationFunctions'
import 'react-table/react-table.css'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { getColumns } from 'components/admin/Contract/List'
import { PageNavigation } from 'components/Mui/Page'
import { ITab } from 'components/Mui/Page/PageNavigation'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Paper from 'components/Mui/Paper'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import { contractDetailsPath } from 'routes/paths'
import { contractStateStyles } from 'theme/styles/contractState'
import { Accessors } from 'types/contractFilterTypes'
import browserHistory from 'utils/history'
import { createQueryFromTable, createTableStateFromPagination, defaultTableState, ITableState } from 'utils/react-table'

interface IProps {
  id: string
}

interface IState {
  loading: boolean
  records: IContractListRecord[]
  tableColumns: Column[]
  tableState: ITableState
  tableQuery: IPaginatedQueryParams<ContractListOrderByType>
  activeProductType: TContractObject
}

const styles = ({ typography, spacing }: Theme) =>
  createStyles({
    tab: {
      fontSize: typography.button.fontSize,
      height: '40px',
    },
    activetab: {
      backgroundColor: 'white',
    },
    fitContent: {
      height: 'fit-content',
      paddingLeft: '8px',
    },
    ...contractStateStyles({ color: true }),
  })

type TProps = WithStyles<typeof styles> & IProps

const vehicleTab: TContractObject = 'Vehicle'

class CustomerContractList extends React.Component<TProps, IState> {
  public state: IState = {
    loading: false,
    records: [],
    tableColumns: [],
    tableState: {
      ...defaultTableState(5),
      resized: [],
      sorted: [{ desc: true, id: 'prettyIdentifier' }],
    },
    tableQuery: {
      search: '',
      pagination: {
        limit: 5,
        offset: 0,
        orderBy: 'prettyIdentifier',
        orderDirection: 'DESC',
      },
    },
    activeProductType: vehicleTab,
  }

  public componentDidMount() {
    this.initColumns()
    this.loadData()
  }

  public render() {
    const { classes } = this.props
    const { tableColumns, tableState, records, loading, activeProductType } = this.state
    const self = this
    const tabs = self.getTabs()

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{t('Contracts')}</PanelTitle>
        </PanelHeader>
        <PageNavigation
          className={classes.fitContent}
          tabClassName={classes.tab}
          activeTabClassName={classes.activetab}
          active={activeProductType}
          onChange={this.handleTabChange}
          tabs={tabs}
        />
        <PanelContent>
          <Paper>
            <ReactTable
              className="react-table react-table--clickable"
              columns={tableColumns}
              data={records}
              defaultPageSize={10}
              defaultSorted={tableState.sorted}
              loading={loading}
              LoadingComponent={TableLoadingIndicator}
              noDataText={!loading ? t('No Results Found') : ''}
              manual={true}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              page={tableState.page}
              pageSize={tableState.pageSize}
              pages={tableState.pages}
              showPaginationBottom={records.length > 0}
              minRows={3}
              // tslint:disable-next-line jsx-no-lambda
              getTrProps={(state: any, rowInfo: any) => ({
                onClick: () => {
                  self.handleRowClick(rowInfo.row)
                },
              })}
              pageText={t('Page')}
              ofText={t('of')}
              rowsText={t('rows')}
            />
          </Paper>
        </PanelContent>
      </Panel>
    )
  }

  private handleTabChange = (newActive: TContractObject) => {
    this.setState({ activeProductType: newActive }, () => {
      this.initColumns()
      this.loadData()
    })
  }

  private getTabs = (): Array<ITab<TContractObject>> => {
    return [
      { title: t('Vehicle'), name: vehicleTab, e2e: 'customer-page__tabs--vehicle' },
      { title: t('Product'), name: 'Product', e2e: 'customer-page__tabs--product' },
    ]
  }

  private handleRowClick = (data: IContractListRecord) => {
    browserHistory.push(contractDetailsPath(data.prettyIdentifier))
  }

  private initColumns = () => {
    const { activeProductType } = this.state
    const tableColumns = getColumns(this.props.classes, Accessors[activeProductType])

    this.setState({ tableColumns })
  }

  private loadData = async () => {
    const { loading, tableQuery, activeProductType } = this.state
    const { id } = this.props

    if (!loading) {
      this.setState({ loading: true })

      const response = await getCustomerContracts(id, tableQuery, activeProductType)

      if (response.data) {
        const { result, pagination } = response.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }

        this.setState({ loading: false, records: result, tableState })
      } else {
        this.setState({ loading: false })
      }
    }
  }

  private handlePageChange = (page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page, pageSize } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState: ITableState = { ...this.state.tableState, sorted }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }
}

export default withStyles(styles)(CustomerContractList)
