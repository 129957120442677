import { IAppConfig } from './interface'

export const CONFIG: IAppConfig = {
  env: (process && process.env && (process.env.REACT_APP_BRANCH || process.env.REACT_APP_ENVIRONMENT)) || 'local',
  appName: 'Fragus JustGO - Admin',

  serverRefetchDataThresholdMillis: 10 * 1000, // 5 seconds.

  browser: {
    disableBrowserCheck: false,
    supportedBrowsers: ['chrome', 'firefox', 'edge', 'safari'],
  },

  providersWithHiddenWarrantyFees: [13, 145, 265],

  startMileageComponent: {
    MIN_KM: 1,
    MAX_KM: 999999,
  },
}
