import { Theme, createStyles } from '@material-ui/core'

export default ({ spacing }: Theme) =>
  createStyles({
    contentPadding: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
    lineHeight: {
      marginBottom: spacing(1),
    },
  })
