import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(3),
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      marginTop: spacing(1),
    },
    btnSubmit: {
      alignSelf: 'center',
      marginTop: spacing(5),
    },
    iconRight: {
      marginRight: spacing(0.5),
    },
    inputError: {
      color: theme.palette.context.warning[500],
    },
  })
