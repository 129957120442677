import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { compose } from 'recompose'
import { theme } from 'theme'
import ButtonSpinner from './Spinner'
import { ButtonProps } from '@material-ui/core/Button'
import classNames from 'classnames'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary[500],
    },
    iconLeft: {
      display: 'inherit',
      paddingRight: '4px',
    },
    icon: {
      fontSize: '16px',
    },
    spinner: {
      color: 'rgba(255,255,255,1)',
    },
  })

interface IOwnProps extends ButtonProps {
  showSpinner?: boolean
  IconComponent?: React.ComponentClass<any> | React.StatelessComponent<any>
  IconClasses?: any
  classesButton?: any
  spinnerClasses?: any
}
type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  delaySpinner: boolean
  timeout?: NodeJS.Timer
}
class SpinnerButton extends React.PureComponent<TProps, IState> {
  public state: IState = {
    delaySpinner: true,
    timeout: undefined,
  }

  public componentDidUpdate(prevProps: TProps) {
    if (!prevProps.showSpinner && this.props.showSpinner) {
      const timeout = this.setupSpinnerDelay()
      this.setState({ timeout })
    } else if (!this.props.showSpinner && this.state.timeout) {
      this.resetSpinnerDelay(this.state.timeout)
      this.setState({ timeout: undefined })
    }
  }

  public render() {
    const {
      children,
      classes,
      showSpinner,
      IconComponent,
      disabled,
      IconClasses,
      classesButton,
      spinnerClasses,
      ...rest
    } = this.props
    const { delaySpinner } = this.state

    const spinnerActive = showSpinner && !delaySpinner

    return (
      <Button {...rest} disabled={disabled || spinnerActive} classes={classesButton}>
        <span className={classes.iconLeft}>
          {spinnerActive ? (
            <ButtonSpinner className={classNames(classes.spinner, IconClasses, spinnerClasses)} />
          ) : (
            IconComponent && <IconComponent className={classNames(classes.icon, IconClasses)} />
          )}
        </span>
        {children}
      </Button>
    )
  }

  /**
   * Setup Spinner
   * Create and run timer delay before showing spinner
   */
  private setupSpinnerDelay = () => {
    return setTimeout(() => {
      this.setState({ delaySpinner: false })
    }, 300)
  }

  /**
   * Reset Spinner
   * Reset timer delay and State prop controlling it
   */
  private resetSpinnerDelay = (delay: NodeJS.Timer) => {
    this.setState({ delaySpinner: true })
    clearTimeout(delay)
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(SpinnerButton)
