import { ButtonProps } from '@material-ui/core/Button'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { TContractObject } from '@omnicar/sam-types'
import classNames from 'classnames'
import { ILinkProps } from 'containers/App/Admin/AppNavigation/AppNavigation'
import React from 'react'
import { contractObjectTypeStyles } from '../../../theme/styles/contractObjectType'
import { t } from '../../../translations/translationFunctions'
import { TranslationKey } from '../../../translations/translationTypes'
import { PageNavigationTab } from '../../Mui/Page'

interface IEndAdornment {
  content: string | number
  tooltip: string
}

interface ITab {
  title: string
  name: TContractObject
  endAdornment?: IEndAdornment
  e2e: string
}

interface ILinkButton {
  tab: { title: TranslationKey; type: TContractObject; endAdornment?: IEndAdornment }
  component: React.ComponentType<ButtonProps>
  linkComponent: React.SFC<any>
  linkProps: ILinkProps
  icon?: React.ComponentType<SvgIconProps>
}

interface IProps {
  active: boolean
  tabClass?: string
  rootClass?: string
  colorClass?: string
  linksButtons: ILinkButton[]
  border?: boolean
}

interface IState {
  activeTab: ITab | undefined
  tabs: ITab[]
}

const styles = ({ typography, spacing }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    tab: {
      fontSize: typography.button.fontSize,
      height: 28,
      lineHeight: '27px',
      marginRight: spacing(2),
      paddingLeft: 13,
      paddingRight: 13,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    tabWithBorder: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 5,
    },
    hidden: {
      display: 'none',
    },
    endAdornment: {
      paddingLeft: '5px',
      fontWeight: 'bold',
    },

    ...contractObjectTypeStyles({ color: true, backgroundColor: true, borderColor: true }),
  })

const initialState = {
  activeTab: undefined,
  tabs: [],
}

class LinkButtonTabs extends React.Component<IProps & WithStyles<typeof styles>, IState> {
  public state: IState = initialState

  public render() {
    const { classes, rootClass, linksButtons, tabClass, active, border } = this.props
    const { activeTab } = this.state

    return (
      <nav>
        {linksButtons &&
          active &&
          linksButtons.map((b, index) => {
            const tab = this.getTab(b.tab)
            return (
              <div className={classNames(rootClass || classes.root)} key={`NavButton-${tab.name}-${index}`}>
                <b.component component={b.linkComponent}>
                  {b.icon && <b.icon />}
                  <PageNavigationTab
                    className={classNames(classes.tab, tabClass, border && classes.tabWithBorder)}
                    active={activeTab && activeTab.name === tab.name}
                    title={tab.title}
                    endAdornment={
                      tab.endAdornment
                        ? {
                            content: tab.endAdornment.content,
                            className: classes.endAdornment,
                            title: tab.endAdornment.tooltip,
                          }
                        : undefined
                    }
                    name={tab.name}
                    onClick={this.handleChange}
                    e2e={tab.e2e}
                  />
                </b.component>
              </div>
            )
          })}
      </nav>
    )
  }

  private handleChange = (activeTab: ITab) => {
    this.setState({ activeTab })
  }

  private getTab = (partialTab: {
    title: TranslationKey
    type: TContractObject
    endAdornment?: { content: string | number; tooltip: string }
  }): ITab => {
    return {
      title: t(partialTab.title),
      name: partialTab.type,
      endAdornment: partialTab.endAdornment,
      e2e: `type-tab__tabs--${partialTab.type.toLowerCase()}`,
    }
  }
}

export default withStyles(styles)(LinkButtonTabs)
