import { Theme, createStyles } from '@material-ui/core'
import { theme } from '../../../../../theme'

export default ({ spacing }: Theme) =>
  createStyles({
    regNumber: {
      marginBottom: spacing(2),
    },
    details: {
      display: 'flex',
    },
    vehicleDetail: {
      flexBasis: '50%',
      marginBottom: spacing(1),
    },
    vehicleDetailIcon: {
      marginRight: spacing(3),
      fontSize: 19,
      color: theme.palette.text.light,
    },
    vin: {
      textTransform: 'uppercase',
      fontSize: 15,
    },
    btnShowMore: {
      textAlign: 'center',
      width: '100%',
      marginTop: spacing(2),
    },
    btnShowMoreLabel: {
      color: theme.palette.text.light,
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
