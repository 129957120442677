import React from 'react'

interface IProps {
  className?: string
}

const LayoutColumn: React.SFC<IProps> = ({ className, children, ...rest }) => (
  <section {...rest} className={className}>
    {children && children}
  </section>
)

export default LayoutColumn
