import React, { Component, ChangeEvent, KeyboardEvent } from 'react'
import { WithStyles, withStyles, Button, TextField, Dialog, DialogContent } from '@material-ui/core'
import { Check as CheckIcon, SettingsBackupRestore as ResetFormIcon } from '@material-ui/icons'
import styles from './styles'
import Typography from 'components/Typography'
import { t } from 'translations/translationFunctions'
import { Formik, Form, FormikProps } from 'formik'
import * as Yup from 'yup'
import { getCityFromZip, initZipCityCountry } from '@omnicar/sam-zip-city'
import { IAdminCustomer, TIsoCountry } from '@omnicar/sam-types'
import { getProviderCountry } from 'utils/localStorage'
import SpinnerButton from 'components/Mui/SpinnerButton'
import { preventEventTrigger } from 'utils/formik'
import { verifyPassword } from 'api/api'
import { emailPattern } from 'utils/regex'

interface IProps extends WithStyles<typeof styles> {
  customer: IAdminCustomer
  onFormDirty: (dirty: boolean) => void
  onSubmit: (user: IAdminCustomer) => void
  onCancel: () => void
  cancelCount: number
}

interface IState {
  dialogOpen?: boolean
  passwordCheckLoading: boolean
}

interface IAccountSettingsFormValues extends IAdminCustomer {
  password: string
  retypeEmail: string
  cancelCount: number
}

class AccountSettingsUserForm extends Component<IProps, IState> {
  state: Readonly<IState> = {
    passwordCheckLoading: false,
  }
  private country: TIsoCountry = 'DK'

  private validationSchema = Yup.object().shape({
    name: Yup.string().required(t('The name is required')),
    email: Yup.string()
      .required(t('Email address is required'))
      .matches(emailPattern, t('Invalid email address'))
      .max(255, t('The email address cannot be more than %count characters', { count: 255 })),
    phone: Yup.string()
      .required(t('Phone is required'))
      .matches(/^[0-9 +#-]{6,20}$/, t('Invalid phone number')),
    zip: Yup.string().required(t('Zip is required')),
    city: Yup.string().required(t('City is required')),
    password: Yup.string(),
  })

  public componentDidMount() {
    const country = getProviderCountry()
    if (country) {
      this.country = country as TIsoCountry
    }
    initZipCityCountry({ isoCountryCode: this.country })
  }

  public render() {
    const { classes, customer, onFormDirty, onSubmit, onCancel, cancelCount } = this.props
    const { passwordCheckLoading } = this.state
    return (
      <Formik
        initialValues={{ ...customer, password: '', retypeEmail: '', cancelCount }}
        validate={this.validate}
        validationSchema={this.validationSchema}
        // tslint:disable-next-line jsx-no-lambda
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true) // todo: show spinner
          const updatedUser = this.mapFormValuesToIAdminCustomer(values)
          await onSubmit(updatedUser)
          setSubmitting(false)
        }}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          dirty,
          isValid,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          resetForm,
          isSubmitting,
          submitForm,
          validateForm,
        }: FormikProps<IAccountSettingsFormValues>) => {
          return (
            <>
              <Form>
                <div className={`ActionBar ${classes.actionBar}`}>
                  <Typography variant="subheading">{t('User details')}</Typography>
                  <div>
                    <SpinnerButton
                      className={classes.btnSave}
                      color="primary"
                      variant="contained"
                      disabled={!dirty && !isValid}
                      onClick={async () => {
                        const validationResult = await validateForm(values)
                        if (!!validationResult && Object.values(validationResult).every((v) => v === undefined)) {
                          this.openPasswordCheckDialog()
                        }
                      }}
                      showSpinner={isSubmitting}
                      IconComponent={CheckIcon}
                    >
                      {t('Save')}
                    </SpinnerButton>
                    <Button variant="contained" disabled={!dirty && !isValid} onClick={onCancel}>
                      <ResetFormIcon className={classes.btnIcon} />
                      {t('Restore')}
                    </Button>
                  </div>
                </div>
                <div className={`Form ${classes.form}`}>
                  {values.cvr && (
                    <>
                      <TextField
                        name="cvr"
                        label={t('CVR')}
                        className={classes.input}
                        value={values.cvr}
                        onChange={handleChange}
                        // tslint:disable-next-line jsx-no-lambda
                        onBlur={(e: ChangeEvent<any>) => {
                          onFormDirty(dirty)
                          handleBlur(e)
                        }}
                        fullWidth={true}
                      />
                      <Typography variant="body2" className={classes.inputError}>
                        {errors.cvr && touched.cvr && errors.cvr}
                      </Typography>
                    </>
                  )}

                  <TextField
                    name="name"
                    label={t('Name')}
                    className={classes.input}
                    value={values.name}
                    onChange={handleChange}
                    // tslint:disable-next-line jsx-no-lambda
                    onBlur={(e: ChangeEvent<any>) => {
                      onFormDirty(dirty)
                      handleBlur(e)
                    }}
                    fullWidth={true}
                    disabled={true}
                  />
                  <Typography variant="body2" className={classes.inputError}>
                    {errors.name && touched.name && errors.name}
                  </Typography>

                  <TextField
                    name="email"
                    label={t('Email')}
                    className={classes.input}
                    value={values.email}
                    onChange={handleChange}
                    // tslint:disable-next-line jsx-no-lambda
                    onBlur={(e: ChangeEvent<any>) => {
                      onFormDirty(dirty)
                      handleBlur(e)
                    }}
                    fullWidth={true}
                  />
                  <Typography variant="body2" className={classes.inputError}>
                    {errors.email && touched.email && errors.email}
                  </Typography>
                  {!isSubmitting && touched.email && this.props.customer.email !== values.email && (
                    <>
                      <TextField
                        name="retypeEmail"
                        autoComplete="off"
                        label={t('Re-type email')}
                        className={classes.input}
                        value={values.retypeEmail}
                        onChange={handleChange}
                        onCut={preventEventTrigger}
                        onCopy={preventEventTrigger}
                        onPaste={preventEventTrigger}
                        // tslint:disable-next-line jsx-no-lambda
                        onBlur={(e: ChangeEvent<any>) => {
                          onFormDirty(dirty)
                          handleBlur(e)
                        }}
                        fullWidth={true}
                        inputProps={{
                          autoComplete: 'new-password',
                        }}
                      />
                      <Typography variant="body2" className={classes.inputError}>
                        {errors.retypeEmail && touched.retypeEmail && errors.retypeEmail}
                      </Typography>
                    </>
                  )}

                  <TextField
                    autoFocus={true}
                    name="phone"
                    label={t('Phone')}
                    className={classes.input}
                    value={values.phone}
                    onChange={handleChange}
                    // tslint:disable-next-line jsx-no-lambda
                    onBlur={(e: ChangeEvent<any>) => {
                      onFormDirty(dirty)
                      handleBlur(e)
                    }}
                    fullWidth={true}
                  />
                  <Typography variant="body2" className={classes.inputError}>
                    {errors.phone && touched.phone && errors.phone}
                  </Typography>

                  <TextField
                    name="zip"
                    label={t('Zipcode')}
                    className={classes.input}
                    value={values.zip}
                    // tslint:disable-next-line jsx-no-lambda
                    onChange={handleChange}
                    // tslint:disable-next-line jsx-no-lambda
                    onBlur={(e: ChangeEvent<any>) => {
                      onFormDirty(dirty)
                      this.handleZipBlur(e, setFieldValue)
                      handleBlur(e)
                    }}
                    fullWidth={true}
                  />
                  <Typography variant="body2" className={classes.inputError}>
                    {errors.zip && touched.zip && errors.zip}
                  </Typography>
                  <TextField
                    name="city"
                    label={t('City')}
                    className={classes.input}
                    value={values.city}
                    onChange={handleChange}
                    // tslint:disable-next-line jsx-no-lambda
                    onBlur={(e: ChangeEvent<any>) => {
                      onFormDirty(dirty)
                      handleBlur(e)
                    }}
                    fullWidth={true}
                  />
                  <Typography variant="body2" className={classes.inputError}>
                    {errors.city && touched.city && errors.city}
                  </Typography>
                  {values.ean && (
                    <>
                      <TextField
                        name="ean"
                        label={t('EAN')}
                        className={classes.input}
                        value={values.ean}
                        onChange={handleChange}
                        // tslint:disable-next-line jsx-no-lambda
                        onBlur={(e: ChangeEvent<any>) => {
                          onFormDirty(dirty)
                          handleBlur(e)
                        }}
                        fullWidth={true}
                        disabled={true}
                      />
                      <Typography variant="body2" className={classes.inputError}>
                        {errors.ean && touched.ean && errors.ean}
                      </Typography>
                    </>
                  )}
                </div>
              </Form>
              <Dialog open={!!this.state.dialogOpen}>
                <DialogContent className={classes.dialogContent}>
                  <Typography variant="body2">{t('Please enter your password to update your user details')}</Typography>
                  <TextField
                    autoFocus={true}
                    helperText={touched.password && errors.password && errors.password}
                    error={errors.password && touched.password ? true : false}
                    label={t('Enter your password')}
                    fullWidth={true}
                    name="password"
                    disabled={false}
                    onChange={(e: React.ChangeEvent<any>) => {
                      handleChange(e)
                      setFieldError('password', undefined)
                    }}
                    onBlur={handleBlur}
                    onCut={preventEventTrigger}
                    onCopy={preventEventTrigger}
                    onPaste={preventEventTrigger}
                    onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
                      // Trigger password validation and sumbit if enter was pressed
                      if (e.which === 13 && !isSubmitting && !passwordCheckLoading) {
                        this.validateAndVerifyPassword({ password: values.password }, submitForm, setFieldError)
                      }
                    }}
                    type="password"
                    value={values.password}
                  />
                  <div className={classes.buttonGroup}>
                    <SpinnerButton
                      color="primary"
                      variant="contained"
                      // tslint:disable-next-line:jsx-no-lambda
                      onClick={() =>
                        this.validateAndVerifyPassword({ password: values.password }, submitForm, setFieldError)
                      }
                      showSpinner={passwordCheckLoading}
                      disabled={isSubmitting || passwordCheckLoading}
                    >
                      {t('Verify password')}
                    </SpinnerButton>
                    <Button
                      variant="contained"
                      // tslint:disable-next-line:jsx-no-lambda
                      onClick={() => this.handleClosePasswordCheckDialog(setFieldValue)}
                      disabled={isSubmitting}
                    >
                      {t('Cancel')}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )
        }}
      </Formik>
    )
  }

  private validateAndVerifyPassword = async (
    values: { password: string },
    handleSubmit: () => Promise<void>,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    this.setState({ passwordCheckLoading: true }, async () => {
      if (!values.password || values.password.length < 6) {
        setFieldError('password', t('Password must have at least %count characters', { count: 6 }))
        return
      }

      const res = await verifyPassword(values)
      if (res.statusCode !== 201) {
        return
      }
      await handleSubmit()
    })
    this.setState({ passwordCheckLoading: false })
  }

  private validate = (values: IAccountSettingsFormValues) => {
    let errors: Partial<IAccountSettingsFormValues> = {}
    // Only set re-type email error if email was changed
    if (values.email !== this.props.customer.email && (!values.retypeEmail || values.retypeEmail !== values.email)) {
      errors.retypeEmail = t('Emails do not match')
    }
    return errors
  }

  private openPasswordCheckDialog = () => {
    this.setState({ dialogOpen: true })
  }

  private handleClosePasswordCheckDialog = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    setFieldValue('password', '')
    this.setState({ dialogOpen: false })
  }

  private handleZipBlur = async (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, val: any) => void,
  ) => {
    const zipcode = e.currentTarget.value
    const city = await getCityFromZip({
      zipcode,
      isoCountryCode: this.country,
    })

    if (city) {
      setFieldValue('city', city)
    }
  }

  private mapFormValuesToIAdminCustomer = (formValues: IAccountSettingsFormValues): IAdminCustomer => {
    return {
      id: formValues.id,
      prettyIdentifier: formValues.prettyIdentifier,
      name: formValues.name,
      email: formValues.email,
      emailBounceType: formValues.emailBounceType,
      phone: formValues.phone,
      address: formValues.address,
      city: formValues.city,
      zip: formValues.zip,
      customerType: formValues.customerType,
      cvr: formValues.cvr,
      ean: formValues.ean,
      companyName: formValues.companyName,
      vatLookup: formValues.vatLookup,
    }
  }
}

export default withStyles(styles)(AccountSettingsUserForm)
