import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginTop: -6,
      borderTopWidth: 6,
      borderTopStyle: 'solid',
      padding: spacing(2),
    },
    warning: {
      borderColor: theme.palette.context.warning[500],
      backgroundColor: theme.palette.context.warning[50],
      color: theme.palette.context.warning[800],
    },
    attention: {
      borderColor: theme.palette.context.attention[500],
      backgroundColor: theme.palette.context.attention[50],
      color: theme.palette.context.attention[800],
    },
  })
