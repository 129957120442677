import { CustomerType, IAdminCustomer } from '@omnicar/sam-types'
import { tDot } from 'utils/string'
import { validEan, validEmail, validPhonenumber, validZipcode } from './regex'

export type CustomerFieldName = 'cvr' | 'companyName' | 'email' | 'name' | 'ean' | 'phone' | 'address' | 'zip' | 'city'

export const validateCustomer = (
  values: IAdminCustomer,
  type: CustomerType,
  isCustomerEmailAlreadyExistsAsUser?: boolean,
) => {
  const errors: Partial<IAdminCustomer> = {}

  // Validate email field.
  if (values.email.length <= 0) {
    errors.email = tDot('Email is required')
  } else if (!validEmail(values.email)) {
    errors.email = tDot('Invalid email address')
  } else if (values.email.length > 128) {
    errors.email = tDot('Email cannot be more than %count characters', { count: 128 })
  } else if (isCustomerEmailAlreadyExistsAsUser) {
    errors.email = tDot("This email for customer cannot be used since it's already in use by a user") // Note: User here is a Seller/Admin/SuperAdmin/etc.
  }

  // Validate name field.
  if (values.name.length <= 0) {
    errors.name = tDot('Name is required')
  } else if (values.name.length > 128) {
    errors.name = tDot('Name cannot be more than %count characters', { count: 128 })
  }

  // Validate business fields.
  if (type === 'BUSINESS') {
    if (!values.cvr || values.cvr.length <= 0) {
      errors.cvr = tDot('CVR is required')
    } else if (values.cvr && values.cvr.length > 128) {
      errors.cvr = tDot('CVR number cannot be more than %count characters', { count: 128 })
    }
    if (values.ean && values.ean.length > 0 && !validEan(values.ean)) {
      errors.ean = tDot('EAN must be 13 digits')
    }
    if (!values.companyName || values.companyName.length <= 0) {
      errors.companyName = tDot('Company name is required')
    } else if (values.companyName && values.companyName.length > 128) {
      errors.companyName = tDot('Company name cannot be more than %count characters', { count: 128 })
    }
  }

  // Validate phone field.
  if (values.phone.length <= 0) {
    errors.phone = tDot('Phone is required')
  } else if (!validPhonenumber(values.phone)) {
    errors.phone = tDot('Invalid phone number')
  }

  // Validate address field.
  if (values.address.length <= 0) {
    errors.address = tDot('Address is required')
  } else if (values.address.length > 128) {
    errors.address = tDot('Address cannot be more than %count characters', { count: 128 })
  }

  // Validate zip field.
  const zip = `${values.zip}`
  if (zip.length <= 0) {
    errors.zip = tDot('Zip is required')
  } else if (!validZipcode(zip)) {
    errors.zip = tDot('Invalid zipcode')
  }

  // Validate city field.
  if (values.city.length <= 0) {
    errors.city = tDot('City is required')
  } else if (values.city.length > 128) {
    errors.city = tDot('City cannot be more than %count characters', { count: 128 })
  }

  return errors
}

export const preventEventTrigger = (e: React.ChangeEvent<any>) => {
  e.preventDefault()
}
