import { CardContent, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import {
  IContractOptionResponse,
  IContractTemplateResponse,
  IGenericContractTemplateResponse,
} from '@omnicar/sam-types'
import { PriceSpecification } from '@omnicar/sam-types/types/priceSpecification'
import classNames from 'classnames'
import IncludedOptionsList from 'components/IncludedOptionsList'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React, { ChangeEvent } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel, ContractFlowType, IContractFlow } from 'types/contractFlow'
import { tDot } from 'utils/string'

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  isKeepDisabled?: boolean // If true then this will keep this element/component disabled.
  templates: IGenericContractTemplateResponse[]
  templateCurrent: IContractTemplateResponse | undefined
  flow: ContractFlowType
  freeContract: boolean
  shouldUpdate: boolean
  validVehicle: boolean
  onChange: (template: IGenericContractTemplateResponse | undefined, customContract?: IContractFlow) => void
  contract: IContractFlow
}

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  templateCurrent: IContractTemplateResponse | undefined
  isTemplateAvailable: boolean
}

const initialState: IState = {
  templateCurrent: undefined,
  isTemplateAvailable: true,
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    propertyWrapper: {
      paddingTop: '5px',
    },
    propertyList: {
      maxHeight: '90px',
      overflowY: 'auto',
    },
    property: {
      paddingLeft: '10px',
      fontSize: 13,
    },
    checkMark: {
      fontSize: 15,
    },
    label: {
      fontSize: 13,
      color: '#7c7c7c',
    },
    missedTemplateError: {
      color: theme.palette.error.main,
      fontWeight: 'bolder',
    },
  })

class ContractFlowTemplate extends React.Component<TProps, IState> {
  public state: IState = initialState

  public componentDidUpdate(oldProps: TProps) {
    const { active, activeEnum, templates, flow } = this.props

    const newTemplateCurrent = this.props.templateCurrent
    const oldTemplateCurrent = oldProps.templateCurrent

    if (
      active >= activeEnum &&
      templates.length &&
      newTemplateCurrent &&
      (newTemplateCurrent !== oldTemplateCurrent || !this.state.templateCurrent)
    ) {
      this.setState({
        templateCurrent: newTemplateCurrent,
        isTemplateAvailable:
          flow === 'ADJUST' && newTemplateCurrent
            ? this.checkIfTemplateIsAvailable(newTemplateCurrent, templates)
            : true,
      })
    } else if (this.props.freeContract && newTemplateCurrent !== oldTemplateCurrent && !newTemplateCurrent) {
      // Form was cleared
      this.setState(initialState)
    }

    if (newTemplateCurrent !== oldTemplateCurrent && !newTemplateCurrent) {
      // Clear the selected template as well!!
      this.setState(initialState)
    }

    if (this.props.templates.length > 0 && oldProps.templates.length <= 0) {
      // console.debug('got templates')
    }
    if (this.props.templates.length <= 0 && oldProps.templates.length > 0) {
      // console.debug('lost templates')
    }
  }

  public render() {
    const { classes, active, activeEnum, isKeepDisabled, freeContract } = this.props

    const isActive = active >= activeEnum
    return (
      <Panel disabled={!isActive || isKeepDisabled}>
        <PanelHeader>
          <PanelTitle>{t('Contract')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card
            className={isActive ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}
            data-e2e={'ContractFlowTemplate'}
          >
            <CardContent>{this.renderInsideCardContent()}</CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private renderInsideCardContent() {
    const { classes, templates, active, activeEnum, freeContract, contract } = this.props
    const { templateCurrent } = this.state

    if (templates?.length === 1) {
      const selectedContract = contract.template

      return (
        <>
          <InputLabel className={classes.inputLabel}>{t('Select service or warranty product')}</InputLabel>
          <div></div> {/* (!) NOTE: This div important due to some weird bug with rendering only one item. */}
          <Select
            disabled={true}
            fullWidth={true}
            value={this.templateKey(selectedContract)}
            data-e2e={'ContractFlowTemplate__template'}
          >
            <MenuItem
              key={`ContractFlowTemplate-|${selectedContract?.id}|${selectedContract?.serviceVariantId}|`}
              value={this.templateKey(selectedContract)}
              data-e2e={`ContractFlowTemplate-${selectedContract?.description || ''}`}
            >
              {selectedContract?.description || selectedContract?.name}
            </MenuItem>
          </Select>
          {this.renderIncludedInContract()}
        </>
      )
    }

    const isActive = active >= activeEnum
    const isDisabled =
      !isActive || !templates.length || (templates.length === 1 && this.props.flow !== 'ADJUST' && !freeContract)
    return (
      <>
        <InputLabel className={classes.inputLabel}>{t('Select service or warranty product')}</InputLabel>
        {templates?.length > 0 ? (
          <Select
            disabled={isDisabled}
            fullWidth={true}
            value={this.templateKey(templateCurrent)}
            onChange={(e: ChangeEvent<any>) => {
              this.handleChange(e)
            }}
            data-e2e={'ContractFlowTemplate__template'}
          >
            {templates
              .sort((a, b) => a.weight - b.weight)
              .map((template: IGenericContractTemplateResponse) => (
                <MenuItem
                  key={`ContractFlowTemplate-|${template?.id}|${template?.serviceVariantId}|`}
                  value={this.templateKey(template)}
                  data-e2e={`ContractFlowTemplate-${template?.description || ''}`}
                >
                  {template?.description || template?.name}
                </MenuItem>
              ))}
          </Select>
        ) : (
          <>{tDot('No contracts available for vehicle')}</>
        )}
        {this.renderIncludedInContract()}
      </>
    )
  }

  private renderIncludedInContract() {
    const { classes, contract } = this.props
    const { templateCurrent, isTemplateAvailable } = this.state

    return (
      <>
        {!isTemplateAvailable && (
          <div className={classNames(classes.propertyWrapper)}>
            <Typography variant="caption" className={classes.label}>
              <span className={classNames(classes.inputLabel, classes.missedTemplateError)}>
                {t(
                  'The agreement type this contract uses is no longer available, please choose a new type of agreement',
                )}
              </span>
            </Typography>
          </div>
        )}
        {(isTemplateAvailable && templateCurrent && contract.serviceInfo && contract.serviceInfo.length > 0 && (
          <div className={classNames(classes.propertyWrapper)}>
            <Typography variant="caption" className={classes.label}>
              <span className={classes.inputLabel}>
                {t('Included in %templateName', { templateName: templateCurrent.description })}
              </span>
            </Typography>
            <IncludedOptionsList
              itemsListWrapperClassName={classNames(classes.propertyList)}
              options={this.mapServiceInfoToContractOption(contract.serviceInfo)}
              showAbbreviation={false}
              descriptionClassName={classNames(classes.property)}
              checkmarkIconClassName={classNames(classes.checkMark)}
              useDefaultAbbreviationClass={false}
            />
          </div>
        )) ||
          (isTemplateAvailable &&
            templateCurrent &&
            templateCurrent.properties &&
            templateCurrent.properties.length > 0 && (
              <div className={classNames(classes.propertyWrapper)}>
                <Typography variant="caption" className={classes.label}>
                  <span className={classes.inputLabel}>
                    {t('Included in %templateName', { templateName: templateCurrent.description })}
                  </span>
                </Typography>
                <IncludedOptionsList
                  itemsListWrapperClassName={classNames(classes.propertyList)}
                  options={templateCurrent.properties}
                  showAbbreviation={false}
                  descriptionClassName={classNames(classes.property)}
                  checkmarkIconClassName={classNames(classes.checkMark)}
                  useDefaultAbbreviationClass={false}
                />
              </div>
            ))}
      </>
    )
  }

  private checkIfTemplateIsAvailable = (
    template: IGenericContractTemplateResponse,
    templates: IGenericContractTemplateResponse[],
  ) => templates.some((t) => this.templateKey(t) === this.templateKey(template))

  // Map service information to contract options for display.
  private mapServiceInfoToContractOption(serviceInfo: string[]) {
    const contractOptions: IContractOptionResponse[] = []
    const price: PriceSpecification = {
      currency: 'kr',
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
    }

    serviceInfo.forEach((element) => {
      contractOptions.push({
        abbreviation: '',
        calculationMethod: null,
        conditionText: '',
        contractProviderId: null,
        description: element,
        id: 0,
        internalName: element,
        optionGroup: null,
        price: price,
        showOnWebcalc: false,
        warranty: undefined,
        weight: 0,
      })
    })

    return contractOptions
  }

  private templateKey = (template: IGenericContractTemplateResponse | undefined): string => {
    const returnValue = template ? JSON.stringify({ id: template.id, serviceVariantId: template.serviceVariantId }) : ''

    return returnValue
  }

  private handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const template = this.props.templates.find((template) => this.templateKey(template) === e.target.value)

    if (template !== this.props.templateCurrent) {
      this.props.onChange(template)
    }
  }
}

export default withStyles(styles)(ContractFlowTemplate)
