import classNames from 'classnames'
import { t } from 'translations/translationFunctions'
import React from 'react'

export function renderContractProviderRow(label: string | undefined, inactive: boolean, inactiveClass: string) {
  return (
    <>
      <div className={classNames(inactive && inactiveClass)}>
        {label}
        {!!inactive && ` (${t('DEACTIVATED')})`}
      </div>
    </>
  )
}
