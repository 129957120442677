import React, { SFC } from 'react'
import { tContractState } from 'translations/translationFunctions'
import { IApiVehicleContract, ContractState } from '@omnicar/sam-types'
import { withStyles, WithStyles, CardActionArea } from '@material-ui/core'
import Typography from 'components/Typography'
import styles from './styles'
import classNames from 'classnames'

interface IProps extends WithStyles<typeof styles> {
  contract: IApiVehicleContract
  selected: boolean
  onContractClick: (selectedContract: IApiVehicleContract | undefined) => void
}

const CustomerPortalContractDetailsVehicleListItemContractListContract: SFC<IProps> = ({
  classes,
  contract,
  selected,
  onContractClick,
}) => {
  return (
    <CardActionArea
      className={classNames(classes.root, { [classes.indicateSelected]: selected }, classes.clickableContractCard)}
      // tslint:disable-next-line:jsx-no-lambda
      onClick={() => onContractClick(contract)}
    >
      <div className={classes.header}>
        <Typography variant="display1" className={classes.template}>
          {contract.contractTemplateExternalName}
        </Typography>
        <Typography
          variant="subtitle"
          className={classNames({
            [classes.active]: [ContractState.Active, ContractState.ActivePendingTermination].includes(
              contract.contractState,
            ),
            [classes.inactive]: contract.contractState > ContractState.ActivePendingTermination,
            [classes.pending]: contract.contractState < ContractState.Active,
            [classes.indentSelected]: selected,
          })}
        >
          {tContractState(contract.contractState)}
        </Typography>
      </div>
      <Typography variant="subtitle" className={classes.prettyId}>
        {contract.prettyIdentifier}
      </Typography>
    </CardActionArea>
  )
}

export default withStyles(styles)(CustomerPortalContractDetailsVehicleListItemContractListContract)
