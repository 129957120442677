import AccountSettings from 'components/admin/AccountSettings'
import React from 'react'
import { AppContext } from 'store/appContext'
import Outage from '../../../components/admin/Outage'
import AppAreaBackground from './AppAreaBackground'
import AppContent from './AppContent/AppContent'
import AppHeader from './AppHeader'
import AppNavigation from './AppNavigation/AppNavigation'
import './index.css'
import classNames from 'classnames'
import { isShowLogoOnWebNonWhiteBG } from 'utils/localStorage'

interface IState {
  isAccountSettingsOpen: boolean
  hasActiveOutage: boolean
}

interface IProps {
  initTranslations: () => Promise<any>
}

class Admin extends React.Component<IProps, IState> {
  public state = {
    isAccountSettingsOpen: false,
    hasActiveOutage: false,
  }

  public async componentDidMount() {
    this.props.initTranslations()
  }

  public render() {
    const { isAccountSettingsOpen, hasActiveOutage } = this.state

    return (
      <AppContext.Consumer>
        {({ providerInfo, isDev }) => (
          <section className="Admin">
            <div className="Admin__inner">
              <Outage onOutageChange={this.handleOutageChange} />
              <section className={classNames('Admin__areabackground', hasActiveOutage ? 'Admin__padding-top' : '')}>
                <AppAreaBackground />
              </section>
              <header
                className={classNames(
                  'Admin__header',
                  hasActiveOutage && 'Admin__padding-top',
                  isShowLogoOnWebNonWhiteBG() && 'Admin__extra-height',
                )}
              >
                <AppHeader providerInfo={providerInfo} onToggleAccountSettings={this.toggleAccountSettingsOpen} />
              </header>
              <nav className="Admin__navigation">
                <AppNavigation providerInfo={providerInfo} />
              </nav>
              <main className="Admin__content">
                <AppContent isDev={isDev} />
              </main>
            </div>

            <AccountSettings open={isAccountSettingsOpen} onClose={this.toggleAccountSettingsOpen} />
          </section>
        )}
      </AppContext.Consumer>
    )
  }

  private toggleAccountSettingsOpen = () => {
    this.setState({ isAccountSettingsOpen: !this.state.isAccountSettingsOpen })
  }

  private handleOutageChange = (hasActiveOutage: boolean) => {
    if (this.state.hasActiveOutage !== hasActiveOutage) this.setState({ hasActiveOutage })
  }
}

export default Admin
