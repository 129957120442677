import React from 'react'
import { createPanelStyles } from 'theme'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IInternalContractNoteResponse as Note } from '@omnicar/sam-types/types/admin/contract/internalContractNotes'
import { Card, Typography } from '@material-ui/core'
import { formatDate } from '@omnicar/sam-format/dist/formatDate/formatDate'
import classNames from 'classnames'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import InternalNoteActionButtons from './InternalNoteActionButtons'
import InternalNoteInputForm from '../InternalNoteInput'
import InternalNoteAttachments from '../InternalNoteAttachments'

interface IOwnProps {
  note: Note
  disabled: boolean
  onSubmit: (note: Note, attachments?: File[]) => Note | undefined
  onDelete: (id: number) => void
  maxNoteLenght: number
  maxNrOfAttachmentFiles: number
  prettyIdentifier: string
  displayAttachments?: boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    smallLabel: {
      fontSize: '10px',
      fontWeight: 'bold',
      paddingRight: '10px',
    },
    indent: {
      paddingLeft: theme.spacing(2),
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px',
    },
    spaceBetween: {
      justifyContent: 'space-between',
    },
    paddingLeft: {
      paddingLeft: theme.spacing(1),
    },
    paragraph: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      maxWidth: '57vw',
    },
    boldLabel: {
      fontWeight: 'bold',
    },
    fadedLabel: {
      fontStyle: 'italic',
      color: 'grey !important',
    },
    card: {
      border: '1px solid lightgray',
      paddingBottom: theme.spacing(1),
    },
    noteContainer: {
      padding: theme.spacing(1),
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  editing: boolean
  displayAttachments: boolean
  filesToUpload?: File[]
  saving: boolean
}

class InternalNoteListItem extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      editing: false,
      displayAttachments: false,
      saving: false,
    }
  }

  componentDidUpdate(oldprops: TProps) {
    const newNote = this.props.note
    const oldNote = oldprops.note

    if (oldNote.note !== newNote.note || oldNote.internalContractNoteId !== newNote.internalContractNoteId) {
      this.setState({
        editing: false,
        saving: false,
        displayAttachments: false,
      })
    }
  }

  public render() {
    const { classes, note, disabled, maxNoteLenght, maxNrOfAttachmentFiles, prettyIdentifier } = this.props
    const { editing, saving, displayAttachments } = this.state
    const fromOldContract = note.contractPrettyIdentifier !== prettyIdentifier
    const imageAttachments = note.attachments.filter((a) => a.fileType.toLocaleLowerCase() !== 'pdf')
    const pdfAttachments = note.attachments.filter((a) => a.fileType.toLocaleLowerCase() === 'pdf')
    const isCurrentUsersNote = !!note.writtenByCurrentUser && !fromOldContract

    return (
      <Card className={classNames(classes.card, classes.noteContainer)}>
        <div className={classNames(classes.flexRow, classes.spaceBetween)}>
          <Typography
            className={classNames(classes.boldLabel, classes.paddingLeft, fromOldContract && classes.fadedLabel)}
            variant="body1"
          >
            {note.writtenByUser}
          </Typography>
          <div className={classes.flexRow}>
            {fromOldContract && (
              <Typography className={classNames(classes.smallLabel, classes.fadedLabel)} variant="body1">
                {`(${note.contractPrettyIdentifier})`}
              </Typography>
            )}
            <Typography
              className={classNames(classes.smallLabel, fromOldContract && classes.fadedLabel)}
              variant="body1"
            >
              {formatDate(new Date(note.date), { showTime: true })}
            </Typography>
          </div>
        </div>
        <div className={classes.indent}>
          {editing ? (
            <InternalNoteInputForm
              initialNote={note}
              label={t('Edit note')}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              disabled={disabled}
              maxNoteLenght={maxNoteLenght}
              maxNrOfAttachmentFiles={maxNrOfAttachmentFiles}
              saving={saving}
              willUnmount={true}
              isExistingNote={true}
              allowAttachmentsOnly={true}
            />
          ) : (
            <div>
              {!!note && (
                <Typography
                  className={classNames(classes.paragraph, fromOldContract && classes.fadedLabel)}
                  variant="body1"
                >
                  {note.note}
                </Typography>
              )}
              <AppContext.Consumer>
                {({ isSuperAdmin, role }) => (
                  <InternalNoteActionButtons
                    editEnabled={isCurrentUsersNote}
                    deleteEnabled={isCurrentUsersNote || ((isSuperAdmin || role === 'admin') && !fromOldContract)}
                    onDelete={this.handleDelete}
                    onEdit={this.handleEdit}
                    disabled={disabled}
                  />
                )}
              </AppContext.Consumer>
              {!!note.attachments && !!note.attachments.length && (
                <InternalNoteAttachments
                  imageAttachments={imageAttachments}
                  pdfAttachments={pdfAttachments}
                  initialDisplayAttachments={displayAttachments}
                  editing={false}
                />
              )}
            </div>
          )}
        </div>
      </Card>
    )
  }

  private handleSubmit = async (note: Note, filesToUpload: File[] | undefined) => {
    const { onSubmit } = this.props
    this.setState({ saving: true })
    const updatedNote = await onSubmit(note, filesToUpload)
    if (updatedNote) {
      this.setState({ editing: false, filesToUpload: undefined })
    }
    this.setState({ saving: false })
  }

  private handleCancel = () => {
    this.setState({ editing: false })
  }

  private handleDelete = async () => {
    const { onDelete, note } = this.props
    await onDelete(note.internalContractNoteId)
  }

  private handleEdit = () => {
    this.setState({ editing: !this.state.editing })
  }
}

export default withStyles(styles)(InternalNoteListItem)
