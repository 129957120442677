import { Button, InputLabel, Typography } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { AttachFile as AttachFileIcon, Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons'
import classNames from 'classnames'
import SpinnerButton from 'components/Mui/SpinnerButton'
import React, { ChangeEvent } from 'react'
import { createPanelStyles, palette } from 'theme'
import { t } from 'translations/translationFunctions'

interface IOwnProps {
  noteId: number
  onSubmit: () => boolean
  onUpdateAttachmentsList: (attachments: File[]) => void
  attachments: File[]
  allowAttachmentsOnly: boolean
  disabled: boolean
  totalNrOfAttachements: number
  maxNrOfAttachmentFiles: number
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    attachmentsList: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 'fit-content',
      maxWidth: '60vw',
    },
    spaceBetween: {
      justifyContent: 'space-between',
    },
    boldLabel: {
      fontWeight: 'bold',
    },
    deleteIcon: {
      color: palette.context.warning[500],
      '&:hover': {
        color: palette.context.warning[700],
      },
    },
    border: {
      border: '1px solid lightgray',
      borderRadius: '5px',
      marginTop: '3px',
      padding: '3px',
    },
    filesContainer: {
      paddingTop: '5px',
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>
interface IState {
  saving: boolean
}

class InternalNoteFileUpload extends React.Component<TProps, IState> {
  public state: IState = {
    saving: false,
  }

  render() {
    const {
      classes,
      disabled,
      attachments,
      maxNrOfAttachmentFiles,
      totalNrOfAttachements,
      allowAttachmentsOnly,
      noteId,
    } = this.props
    const { saving } = this.state
    const fileCount = (attachments ? attachments.length : 0) + totalNrOfAttachements

    return (
      <>
        <input
          id={`NoteInputForm-${noteId}-file-picker`}
          accept=".pdf, .jpeg, .png"
          type="file"
          hidden={true}
          onChange={this.handleFileSelect}
          data-e2e="DialogWorkshopOperationsUpload__file-upload-input"
          multiple
        />
        <InputLabel htmlFor={`NoteInputForm-${noteId}-file-picker`}>
          <Button key={`NoteInputForm-${noteId}-file-select`} variant="outlined" component="span">
            <AttachFileIcon className={classes.panelActionsButtonIcon} />
            {t('Select file')}
          </Button>
        </InputLabel>
        {!!attachments && !!attachments.length && (
          <div className={classes.filesContainer}>
            <Typography className={classes.boldLabel}>{`${t('Added attachments')}: `}</Typography>
            <Typography className={classes.boldLabel}>{`(${fileCount} / ${maxNrOfAttachmentFiles})`}</Typography>
            <div className={classNames(classes.flexRow, classes.spaceBetween, classes.border)}>
              <div className={classes.attachmentsList}>
                {attachments.map((f, i) => {
                  return (
                    <div key={`NoteInputForm-${noteId}-new-pdf-attachments_${i}`}>
                      <Button
                        className={classes.attachment}
                        title={t('Remove attached file')}
                        disabled={disabled || saving}
                        onClick={() => this.handleRemoveAttachmentFromUploadList(f)}
                      >
                        <CancelIcon className={classNames(classes.deleteIcon, classes.panelActionsButtonIcon)} />
                        {f.name}
                      </Button>
                    </div>
                  )
                })}
              </div>
              {!allowAttachmentsOnly && (
                <SpinnerButton
                  onClick={this.handleSubmit}
                  className={classes.btnSubmit}
                  variant="outlined"
                  color="secondary"
                  disabled={disabled}
                  showSpinner={saving}
                  IconComponent={SaveIcon}
                >
                  {t('Save attachments only')}
                </SpinnerButton>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  private handleSubmit = () => {
    const { onSubmit } = this.props
    this.setState({ saving: true })
    onSubmit()
    this.setState({ saving: false })
  }

  private handleRemoveAttachmentFromUploadList = (file: File) => {
    const { onUpdateAttachmentsList, attachments } = this.props
    const updatedFileList = attachments.filter((f) => f.name !== file.name)

    onUpdateAttachmentsList(updatedFileList)
  }

  private handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const { maxNrOfAttachmentFiles, totalNrOfAttachements, attachments, onUpdateAttachmentsList } = this.props
    const fileList: FileList | null = event.currentTarget.files
    // If atleast one file was selected
    if (fileList && fileList.length) {
      const totalNrOfFiles = fileList.length + attachments.length + totalNrOfAttachements
      const nrOfFilesToAdd =
        totalNrOfFiles >= maxNrOfAttachmentFiles ? totalNrOfFiles - maxNrOfAttachmentFiles : fileList.length

      for (let i = 0; i < nrOfFilesToAdd; i++) {
        // File name has to be unique
        if (!attachments.some((f) => f.name === fileList[i].name)) {
          attachments.push(fileList[i])
        }
      }
      onUpdateAttachmentsList(attachments)
    }
  }
}

export default withStyles(styles)(InternalNoteFileUpload)
