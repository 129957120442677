import React from 'react'
import classNames from 'classnames'
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles'

interface IProps {
  className?: string
}

type TProps = IProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `0 ${spacing(1)}px 0 ${spacing(2)}px`,
      marginBottom: spacing(1),
      minHeight: spacing(4),
    },
  })

const PanelHeader: React.SFC<TProps> = ({ classes, className, children }) => (
  <header className={classNames(classes.root, className)}>{children && children}</header>
)

export default withStyles(styles)(PanelHeader)
