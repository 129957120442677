import React, { ChangeEvent } from 'react'

import { t } from 'translations/translationFunctions'
import { IIncludedContractOption, IContractOptionResponse } from '@omnicar/sam-types'
import { LayoutBlock, LayoutColumn, LayoutRow } from 'components/Mui/Layout'
import Paper from 'components/Mui/Paper'

import Typography from 'components/Typography'

import { FormControlLabel, Checkbox } from '@material-ui/core'
import { createStyles, WithStyles } from '@material-ui/core/styles'

import { termsPdfUrl } from 'api/api'
import { IContractFlow } from 'types/contractFlow'
import { basename } from 'path'

export type TContractOption = IIncludedContractOption & IContractOptionResponse

interface IProps {
  classes?: any
  contract: IContractFlow
  options: TContractOption[] | undefined | null
  termsOfTradeRef: string | undefined | null
  onChangeAllTermsChecked: (isAllTermsChecked: boolean) => void
}

// type TProps = RouteComponentProps<IRouteProps> & WithStyles<typeof styles>
type TProps = WithStyles<typeof styles> & IProps

interface IState {
  hasEula1: boolean
  hasEula2: boolean // Terms of Trade.
  isEula1Checked: boolean
  isEula2Checked: boolean
  eulaOptions: Map<number, boolean> | null
}

// export const styles = ({ spacing }: Theme) =>
export const styles = createStyles({
  addSpaceAfter: {
    marginRight: `1.3em`,
  },
})

export default class TermsOfServiceForm extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    const hasEula2 = this.props.termsOfTradeRef ? true : false

    this.state = {
      hasEula1: true,
      hasEula2: hasEula2,
      isEula1Checked: false,
      isEula2Checked: false,
      eulaOptions: props.options ? this.initEulaOptions(props.options) : null,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: TProps) {
    if (nextProps.options && this.props.options !== nextProps.options) {
      this.setState({ eulaOptions: this.initEulaOptions(nextProps.options) })
    }
    if (nextProps.termsOfTradeRef && this.props.termsOfTradeRef !== nextProps.termsOfTradeRef) {
      this.setState({ hasEula2: !nextProps.termsOfTradeRef ? false : true })
    }
  }

  public render() {
    const { classes, options, termsOfTradeRef } = this.props

    const optionsValue = this.state.eulaOptions
    const hasEula2 = this.state.hasEula2

    const termsOfTradeLink = termsOfTradeRef ? termsPdfUrl(termsOfTradeRef) : ''

    return (
      <div className="AcceptTermsForm">
        <React.Fragment>
          <LayoutRow>
            <LayoutColumn>
              {true && (
                <LayoutBlock>
                  <Paper data-e2e="ContractFlowSummary">
                    <React.Fragment>
                      <section className={classes.section}>
                        <Typography className={classes.sectionItem} variant="body2">
                          <FormControlLabel
                            key={`ContractFlowPagePayment__buynow-eula1`}
                            className={classes.checkbox}
                            label={
                              <div className={classes.sectionItem}>
                                {t(
                                  'I have read and accepted the Terms and Conditions of the Subscription Agreement and allow the Subscription Agreement Provider to contact me by phone and/or email with information and services relevant to my car and Subscription Agreement',
                                )}
                              </div>
                            }
                            control={
                              <Checkbox
                                checked={this.state.isEula1Checked}
                                onChange={this.handleEula1Change}
                                data-e2e={`ContractFlowPagePayment__buynow-eula1-checkbox`}
                              />
                            }
                          />
                        </Typography>
                      </section>

                      {options &&
                        options.map((option: IContractOptionResponse, i: number) => (
                          <div key={'TermsOfServiceForm__options_div_' + i}>
                            {option.termsOfService && (
                              <section className={classes.section}>
                                <Typography className={classes.sectionItem} variant="body2">
                                  <FormControlLabel
                                    key={'TermsOfServiceForm__options_label_' + i}
                                    className={classes.checkbox}
                                    label={
                                      <div className={classes.sectionItem}>
                                        <span className={classes.addSpaceAfter}>{`${t(
                                          'I have read and accepted the',
                                        )}`}</span>
                                        <a
                                          href={option.termsOfService.ref}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          title={option.termsOfService.name}
                                        >
                                          {`${t('terms for')} ${option.description}`}
                                        </a>
                                      </div>
                                    }
                                    control={
                                      <Checkbox
                                        name={`euloptincheckbox${option.id}`}
                                        checked={!optionsValue ? false : optionsValue.get(option.id)}
                                        onChange={this.handleEulaOptionsChange}
                                        data-e2e={`ContractFlowPagePayment__optionscheckboxeul_${i}`}
                                      />
                                    }
                                  />
                                </Typography>
                              </section>
                            )}
                          </div>
                        ))}

                      {hasEula2 && (
                        <section className={classes.section}>
                          <Typography className={classes.sectionItem} variant="body2">
                            <FormControlLabel
                              key={`ContractFlowPagePayment__buynow-eula2`}
                              className={classes.checkbox}
                              label={
                                <div className={classes.sectionItem}>
                                  <span className={classes.addSpaceAfter}>{`${t(
                                    'I have read and accepts the generel',
                                  )} `}</span>
                                  <a
                                    href={termsOfTradeLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={!termsOfTradeRef ? '' : basename(termsOfTradeRef)}
                                  >
                                    {t('terms of trade')}
                                  </a>
                                </div>
                              }
                              control={
                                <Checkbox
                                  checked={this.state.isEula2Checked}
                                  onChange={this.handleEula2Change}
                                  data-e2e={`ContractFlowPagePayment__buynow-eula2-checkbox`}
                                />
                              }
                            />
                          </Typography>
                        </section>
                      )}
                    </React.Fragment>
                  </Paper>
                </LayoutBlock>
              )}
            </LayoutColumn>
          </LayoutRow>
        </React.Fragment>
      </div>
    )
  }

  /**
   * Returns a new Map with the same IDs as in eulaOptions.
   */
  private initEulaOptions(eulaOptions: TContractOption[]) {
    const newEulaOptions: Map<number, boolean> = new Map<number, boolean>()

    eulaOptions.forEach((option) => {
      if (option.termsOfService) {
        newEulaOptions.set(option.id, false)
      }
    })

    return newEulaOptions
  }

  private isAllTermsChecked = (
    isEula1Checked: boolean,
    isEula2Checked: boolean,
    eulaOptions: Map<number, boolean> | null,
  ) => {
    const { hasEula1, hasEula2 } = this.state

    let eulaOptionsAgreed: boolean = true
    if (eulaOptions) {
      eulaOptions.forEach((value) => (eulaOptionsAgreed = eulaOptionsAgreed && value))
    } else {
      eulaOptionsAgreed = false
    }

    if (hasEula1 && !hasEula2) {
      return isEula1Checked && eulaOptionsAgreed
    } else {
      return isEula1Checked && isEula2Checked && eulaOptionsAgreed
    }
  }

  private handleEula1Change = (e: ChangeEvent<HTMLInputElement>) => {
    const { isEula2Checked, eulaOptions } = this.state
    this.setState({ isEula1Checked: e.currentTarget.checked })

    this.props.onChangeAllTermsChecked(this.isAllTermsChecked(e.currentTarget.checked, isEula2Checked, eulaOptions))
  }

  private handleEula2Change = (e: ChangeEvent<HTMLInputElement>) => {
    const { isEula1Checked, eulaOptions } = this.state
    this.setState({ isEula2Checked: e.currentTarget.checked })

    this.props.onChangeAllTermsChecked(this.isAllTermsChecked(isEula1Checked, e.currentTarget.checked, eulaOptions))
  }

  private handleEulaOptionsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { isEula1Checked, isEula2Checked } = this.state

    const name: string = e.currentTarget.name
    const digits: string = name.replace(/[\D]/g, '')
    const id: number = +digits

    const eulaOptions = this.state.eulaOptions
    eulaOptions && eulaOptions.set(id, e.currentTarget.checked)
    this.setState({ eulaOptions })

    this.props.onChangeAllTermsChecked(this.isAllTermsChecked(isEula1Checked, isEula2Checked, eulaOptions))
  }
}
