import { Theme, createStyles } from '@material-ui/core'
import { isUseWhiteBGTheme } from 'utils/localStorage'
import { theme } from 'theme'
import { activeTab as activeTabColor } from 'theme'

const buttonColor = '#425D7E' // Light-blue
const labelColor = activeTabColor // Blue

export default ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      paddingLeft: spacing(3),
    },
    label: {
      color: palette.primary.main,
    },
    leftIcon: {
      marginRight: spacing(1),
      color: palette.secondary.dark,
    },
    leftButtonIcon: {
      color: '#eeeeee',
      marginRight: '0.5ch', // 1 ch = relative to width of the "0" (zero)
    },
    iconSmall: {
      fontSize: 24,
    },
    iconNotification: {
      fill: isUseWhiteBGTheme() ? buttonColor : palette.grey[600],
      height: '24px',
      width: '24px',
      marginRight: spacing(1),

      '& text': {
        fill: 'white',
      },
    },
    iconNotificationNew: {
      fill: palette.secondary.main,
      animation: 'newNotification 0.35s ease-out 0s 2 forwards',
    },
    '@keyframes newNotification': {
      '0%': {
        width: '24px',
      },
      '40%': {
        width: '36px',
      },
      '60%': {
        width: '36px',
      },
      '100%': {
        width: '24px',
      },
    },
    iconNotificationText: {
      fontSize: '12px',
    },
    iconNotificationTextSmall: {
      fontSize: '10px',
    },
    link: {
      justifyContent: 'flex-start',
      paddingLeft: spacing(3.5),
      marginBottom: spacing(0.5),
    },
    fab: {
      display: 'flex',
      marginBottom: spacing(3),
      justifyContent: 'center',
    },
    fabItem: {
      width: '87%',
      color: '#eeeeee',
      backgroundColor: palette.primary.main,
      '&:hover': {
        backgroundColor: isUseWhiteBGTheme() ? palette.primary[600] : palette.primary[600],
      },
    },
    margin: {
      marginLeft: spacing(1),
    },
    dropDownMenu: {
      paddingLeft: '50px',
      zIndex: 3,
      display: 'flex',
      flexDirection: 'column',
      height: 'fit-content',
    },
    tabClass: {
      color: palette.primary.main,
      borderColor: palette.primary.main,
    },
    activeTab: {
      color: 'white',
      backgroundColor: isUseWhiteBGTheme() ? buttonColor : theme.palette.text.light,
    },
    activeLink: {
      paddingLeft: 24,
      borderLeft: '4px solid ' + labelColor,
      borderRadius: '0 4px 4px 0',
    },
    logoWrapperFragus: {
      position: 'absolute',
      height: '100%',
      width: '-webkit-fill-available',
    },
    logoFragus: {
      position: 'absolute',
      maxWidth: '100%',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '32px',
      bottom: '216px',
    },
    topNavigationHasExtraHeight: {
      bottom: '-32px',
    },
  })
