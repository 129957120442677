import {
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Close as CloseIcon, Delete as DeleteIcon, Save as SaveIcon, Search as SearchIcon } from '@material-ui/icons'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { ApiError, INullableRole, IUserListRecord, UserRoleRecord } from '@omnicar/sam-types'
import { createUser, deleteUser, getUser, getUserRoles, patchUser } from 'api/api'
import classNames from 'classnames'
import RoleTableRow from 'components/admin/User/Form/RoleTableRow'
import SpinnerButton from 'components/Mui/SpinnerButton'
import Typography from 'components/Typography'
import { Formik, FormikProps } from 'formik'
import React, { ChangeEvent, Fragment } from 'react'
import { compose } from 'recompose'
import { theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { getRoles } from 'utils/localStorage'
import { emailPattern } from 'utils/regex'
import { array, object, string } from 'yup'

const styles = (theme: Theme) =>
  createStyles({
    content: {
      minWidth: 450,
    },
    icon: {
      color: theme.palette.text.secondary,
      top: -3,
    },
    textfield: {
      margin: 'dense',
    },
    select: {
      minHeight: theme.spacing(5),
    },
    inputError: {
      color: customTheme.palette.context.warning[500],
    },
    deleteButton: {
      color: 'rgba(255,255,255,1)',
      backgroundColor: customTheme.palette.context.warning[500],
    },
    button: {
      marginLeft: theme.spacing(1.5),
    },
    iconButton: {
      marginRight: theme.spacing(1),
      fontSize: '16px',
    },
    inactiveRow: {
      backgroundColor: 'rgba(0,0,0,0.2)',
      fontStyle: 'italic',
      padding: '5px',
      borderRadius: '5px',
    },
    filterButtonWrapper: {
      paddingTop: '20px',
    },
    filterButton: {
      fontWeight: 'normal',
      color: customTheme.palette.context.attention[500],
      border: '1px solid',
      borderColor: customTheme.palette.context.attention[500],
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '2px',
      marginTop: '10px',
      marginBottom: '3px',
    },
    filterButtonClicked: {
      color: 'white',
      backgroundColor: customTheme.palette.context.attention[500],
    },
    clearIcon: {
      cursor: 'pointer',
    },
    noResults: {
      padding: `${theme.spacing(4)}px 0`,
      textAlign: 'center',
    },
    disabledProvider: {
      opacity: 0.2,
    },
    enabledProvider: {
      backgroundColor: colors.cyan[400],
    },
  })

interface IOwnProps {
  record?: IUserListRecord
  open: boolean
  onClose: (update: boolean) => void
  commonUserRoles: UserRoleRecord[]
}

type TProps = IOwnProps & WithStyles<typeof styles>
type FormUser = Pick<IUserListRecord, 'name' | 'email' | 'phone' | 'localeCode' | 'roles' | 'departmentName'>
type TRole = INullableRole['role']

interface IState {
  deleting: boolean
  loading: boolean
  loaded: boolean
  deleteWarning: boolean
  availableProviders: UserRoleRecord['contractProvider'][]
  initialValues: FormUser
  userExists: boolean
  userExistsOnProvider: string
  creationConfirm: boolean
  includeInactiveProviders: boolean
  searchQuery: string
}

class UserForm extends React.Component<TProps, IState> {
  public state: IState = {
    deleting: false,
    loading: false,
    loaded: false,
    deleteWarning: false,
    availableProviders: [],
    initialValues: {
      name: '',
      email: '',
      phone: '',
      roles: [],
      localeCode: '',
    },
    userExists: false,
    userExistsOnProvider: '',
    creationConfirm: false,
    includeInactiveProviders: false,
    searchQuery: '',
  }

  public componentDidMount() {
    const allRoles = getRoles()
    const adminRoles = allRoles.filter((r) => r.role === 'admin')

    this.setState({
      availableProviders: adminRoles.map((r) => r.contractProvider),
    })
  }

  static getDerivedStateFromProps(nextProps: IOwnProps, prevState: IState) {
    const { commonUserRoles, record } = nextProps
    const { availableProviders } = prevState

    const initialRoles = availableProviders.map((p) => {
      const role = commonUserRoles.find((r) => r.contractProvider.id === p.id)
      return { providerId: p.id, role: role ? role.role : null } as INullableRole
    })

    const initialValues: FormUser = {
      name: record ? record.name : '',
      email: record ? record.email : '',
      phone: record ? record.phone : '',
      departmentName: record?.departmentName,
      roles: initialRoles,
      localeCode: record ? record.localeCode : '',
    }

    return {
      initialValues,
    }
  }

  public render() {
    const { classes, record } = this.props
    const {
      deleteWarning,
      loading,
      deleting,
      availableProviders,
      initialValues,
      creationConfirm,
      userExists,
      userExistsOnProvider,
      includeInactiveProviders,
      searchQuery,
    } = this.state

    const filteredProviders = availableProviders.filter(
      (prov) =>
        searchQuery === '' ||
        String(prov.id) === searchQuery ||
        prov.administrativeName.toLowerCase().includes(searchQuery.toLowerCase()),
    )

    // Only allow admins to change the role

    const errorClasses = classNames(classes.textfield, classes.inputError)

    return (
      <Fragment>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          className={'UserForm'}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle>{record ? t('Update User') : t('Create user')}</DialogTitle>
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            // tslint:disable-next-line:jsx-no-lambda
            validationSchema={() => {
              return object().shape({
                name: string()
                  .required(t('The name is required'))
                  .max(255, t('The name cannot be more than %count characters', { count: 255 })),
                email: string()
                  .required(t('Email address is required'))
                  .matches(emailPattern, t('Invalid email address'))
                  .max(255, t('The email address cannot be more than %count characters', { count: 255 })),
                phone: string().matches(/^[0-9 +#-]{6,20}$/, t('Invalid phone number')),
                roles: array().test(
                  'test roles values',
                  t('Permission for at least one of the providers is required'),
                  function (value) {
                    return !value.every((e: INullableRole) => e.role === null)
                  },
                ),
              })
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              submitForm,
              touched,
              values,
              dirty,
              setFieldValue,
            }: FormikProps<IUserListRecord>) => (
              <Fragment>
                <DialogContent className={classes.content}>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      autoFocus={true}
                      name="name"
                      type="text"
                      className={classes.textfield}
                      label={t('Name')}
                      fullWidth={true}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography variant="body2" className={errorClasses}>
                      {errors.name && touched.name && errors.name}
                    </Typography>
                    <TextField
                      name="email"
                      type="text"
                      className={classes.textfield}
                      label={t('Email')}
                      fullWidth={true}
                      value={values.email}
                      onChange={(e) => {
                        handleChange(e)
                        this.handleEmailChange(e.target.value)
                      }}
                      onBlur={handleBlur}
                    />
                    <Typography variant="body2" className={errorClasses}>
                      {errors.email && touched.email && errors.email}
                    </Typography>
                    <TextField
                      name="phone"
                      type="text"
                      className={classes.textfield}
                      label={t('Phone')}
                      fullWidth={true}
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography variant="body2" className={errorClasses}>
                      {errors.phone && touched.phone && errors.phone}
                    </Typography>
                    <TextField
                      name="departmentName"
                      type="text"
                      className={classes.textfield}
                      label={t('Department')}
                      fullWidth={true}
                      value={values.departmentName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography variant="body2" className={errorClasses}>
                      {errors.name && touched.name && errors.name}
                    </Typography>
                    {availableProviders.length && (
                      <>
                        <div className={classes.filterButtonWrapper}>
                          <span>
                            <FormControlLabel
                              control={<Switch checked={includeInactiveProviders} />}
                              label={t('Include deactivated')}
                              onChange={this.handleIncludeInactiveChanged}
                            />
                            <TextField
                              name="filter"
                              type="text"
                              className={classes.textfield}
                              label={t('Filter on provider id/name')}
                              fullWidth={true}
                              value={searchQuery}
                              onChange={this.handleSearchQueryChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.clearIcon}
                                    onClick={this.clearSearchQuery}
                                  >
                                    {searchQuery ? <CloseIcon /> : <SearchIcon />}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </span>
                        </div>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('Provider ID')}</TableCell>
                              <TableCell>{t('Provider')}</TableCell>
                              <TableCell>{t('Role')}</TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {availableProviders.length ? (
                              availableProviders.map((provider, index) => (
                                <>
                                  {(includeInactiveProviders || !provider.inactive) && (
                                    <RoleTableRow
                                      key={provider.administrativeName}
                                      provider={provider}
                                      labelEndAdornment={provider.inactive ? ` (${t('DEACTIVATED')})` : undefined}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      classes={classes}
                                      name={`roles[${index}].role`}
                                      value={this.getRoleValue(values, index)}
                                      handleRoleCopying={() =>
                                        this.handleRoleCopying(this.getRoleValue(values, index), setFieldValue)
                                      }
                                      isButtonAvailable={index === 0}
                                      customClass={
                                        (provider.inactive ? classes.inactiveRow : '') +
                                        ' ' +
                                        (!filteredProviders.some((prov) => prov.id === provider.id)
                                          ? classes.disabledProvider
                                          : searchQuery !== ''
                                          ? classes.enabledProvider
                                          : '')
                                      }
                                      filterEnabled={filteredProviders.some((prov) => prov.id === provider.id)}
                                    />
                                  )}
                                </>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>
                                  <Typography className={classes.noResults} variant="subtitle">
                                    {t('No Results Found')}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </>
                    )}
                    <Typography variant="body2" className={errorClasses}>
                      {errors.roles && touched.roles && errors.roles}
                    </Typography>
                  </form>
                </DialogContent>

                <DialogActions>
                  {this.props.record && (
                    <Button
                      onClick={this.handleDelete}
                      className={classNames(classes.deleteButton, classes.button)}
                      disabled={deleteWarning || loading}
                      variant="contained"
                      size="small"
                    >
                      <DeleteIcon className={classes.iconButton} />
                      {t('Delete')}
                    </Button>
                  )}

                  <SpinnerButton
                    className={classes.button}
                    onClick={submitForm}
                    size="small"
                    color="secondary"
                    showSpinner={loading}
                    IconComponent={SaveIcon}
                    IconClasses={classes.iconButton}
                    variant="contained"
                    disabled={!touched || !dirty || Object.keys(errors).length !== 0}
                  >
                    {this.props.record ? t('Update') : t('Save')}
                  </SpinnerButton>

                  <Button
                    size="small"
                    onClick={this.handleClose}
                    disabled={deleteWarning || loading}
                    variant="outlined"
                    className={classes.button}
                  >
                    <CloseIcon className={classes.iconButton} />
                    {t('Cancel')}
                  </Button>
                </DialogActions>
              </Fragment>
            )}
          </Formik>
        </Dialog>
        <Dialog open={userExists}>
          <DialogContent>
            <DialogTitle>{t('User already exists')}</DialogTitle>
            <DialogContentText>
              {t('An email address cannot be shared across multiple users')} {' (' + userExistsOnProvider + ')'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeUserExists} color="primary">
              {t('Ok')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={creationConfirm}>
          <DialogTitle>{t('User has been created')}</DialogTitle>
          <DialogContentText>
            {t('Go to the login page and enter the new users email to set up a password for the new account')}
          </DialogContentText>
          <DialogActions>
            <Button onClick={this.confimationClose} color="primary">
              {t('Ok')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteWarning}>
          <DialogTitle>{t('Are you sure you want to delete this user?')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('The user will no longer be able to use JustGO, and will no longer appear in the list of users')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SpinnerButton onClick={this.deleteUser} color="secondary" showSpinner={deleting}>
              {t('Yes')}
            </SpinnerButton>
            <Button onClick={this.cancelDelete} color="primary">
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }

  private getRoleValue(values: IUserListRecord, index: number): TRole | undefined {
    const roles = values.roles
    return roles && roles[index].role
  }

  private handleIncludeInactiveChanged = () => {
    const { includeInactiveProviders } = this.state
    this.setState({ includeInactiveProviders: !includeInactiveProviders })
  }

  private handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: event.target.value })

  private clearSearchQuery = (e: any) => {
    this.setState({ searchQuery: '' })
  }

  private confimationClose = () => {
    this.setState({ creationConfirm: false })
  }

  private handleEmailChange = async (email: string) => {
    if (email) {
      try {
        const response = await getUser(email)
        if (response.data) {
          let providerName: string = ''
          if (response.data.userId) {
            const commonUserRoles =
              (response.data && (await getUserRoles(response.data.userId).then(({ data }) => data))) || []
            if (commonUserRoles.length === 1) {
              providerName =
                commonUserRoles[0].contractProvider.id + ' ' + commonUserRoles[0].contractProvider.administrativeName
            }
          }

          this.setState({ userExists: true, userExistsOnProvider: providerName })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  private handleRoleCopying(role: TRole | undefined, setFieldValue: any) {
    const { roles } = this.state.initialValues
    if (role !== undefined && roles) {
      setFieldValue(
        'roles',
        roles.map((r) => ({ ...r, role })),
      )
    }
  }

  private closeUserExists = () => {
    this.setState({ userExists: false, userExistsOnProvider: '' })
  }

  private handleClose = () => {
    this.props.onClose(false)
  }

  private handleDelete = () => {
    this.setState({ deleteWarning: true })
  }

  private cancelDelete = () => {
    this.setState({ deleteWarning: false })
  }

  private deleteUser = async () => {
    const { record } = this.props

    if (record && record.userId) {
      this.setState({ deleting: true })
      const response = await deleteUser(record.userId)
      this.setState({ deleteWarning: false, deleting: false }, () => {
        if (response.data) {
          this.props.onClose(true)
        }
      })
    }
  }

  private handleSubmit = async (formData: FormUser) => {
    this.setState({ loading: true }, async () => {
      const { record, onClose } = this.props
      const { initialValues } = this.state
      let rolesDifference: INullableRole[] | undefined

      if (formData.roles) {
        rolesDifference = formData.roles.filter((currentRole) => {
          return initialValues.roles
            ? !initialValues.roles.some(
                (initRole) => initRole.role === currentRole.role && initRole.providerId === currentRole.providerId,
              )
            : false
        })
      }

      const userRecord: IUserListRecord = { ...formData, roles: rolesDifference }
      let response: IJsonStatus<IUserListRecord, ApiError>
      if (record && record.userId) {
        response = await patchUser(record.userId, userRecord)
      } else {
        response = await createUser(userRecord)
        if (response.data) {
          this.setState({ creationConfirm: true })
        }
      }

      if (response.data) {
        this.setState({ loading: false }, () => onClose(true))
      } else {
        // @TODO: Show error of some sort
        this.setState({ loading: false })
      }
    })
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(UserForm)
