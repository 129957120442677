import ActionTypes from 'actions/ActionTypes'
import ActionTypeKeys from 'actions/ActionTypeKeys'
import { contractFilterInitialState } from './contractFilterInitialState'

export const contractFilterParamsReducer = (state = contractFilterInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.CONTRACT_FILTER_UPDATE:
      return {
        ...state,
        ...action.props,
      }

    case ActionTypeKeys.CONTRACT_FILTER_RESET:
      return {
        ...contractFilterInitialState,
      }

    default:
      return state
  }
}
