import 'babel-polyfill'
import 'whatwg-fetch'
import App from 'containers/App/App'
import { envOverlay } from 'env-overlay'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import browserHistory from 'utils/history'
import store from 'utils/store'
import './style/main.css'
import MainBoundary from 'containers/App/MainBoundary'
import { ToastContainer, Zoom } from 'react-toastify'
import { initSentry } from 'utils/sentry'

envOverlay((process && process.env && (process.env.REACT_APP_BRANCH || process.env.REACT_APP_ENVIRONMENT)) || '', {
  corner: 'left',
  onLoaded: (node: HTMLDivElement) => {
    node.addEventListener('click', () => {
      node.style.display = 'none'
    })
  },
})

const index = (
  <>
    <ToastContainer
      pauseOnHover={true}
      pauseOnFocusLoss={false}
      hideProgressBar={true}
      position={'top-center'}
      autoClose={4000}
      theme="colored"
      transition={Zoom}
      style={{ width: '30vw', letterSpacing: '0.17ch', whiteSpace: 'pre-line' }}
    />
    <MainBoundary>
      <Provider store={store}>
        <Router>
          <ConnectedRouter history={browserHistory}>
            <App />
          </ConnectedRouter>
        </Router>
      </Provider>
    </MainBoundary>
  </>
)

initSentry()

ReactDOM.render(index, document.getElementById('root') as HTMLElement)
