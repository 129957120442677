import { CardContent, IconButton } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Edit as EditIcon } from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import { getLocale } from '@omnicar/sam-translate'
import { IsoLocale } from '@omnicar/sam-types'
import classNames from 'classnames'
import DatePicker from 'components/DatePicker'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import moment, { Moment } from 'moment'
import React from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel, ContractFlowType } from 'types/contractFlow'
import { localeToLanguage } from 'utils/locale'

const allowedYearsIntoTheFuture: number = 25

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  isHidden: boolean
  contractStartDateValue: Date
  onStartDateChange: (startDate: Date | undefined) => void
  freeContract: boolean
  flowType: ContractFlowType
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    root: {
      fontSize: 14,
      paddingTop: '20px',
      paddingBottom: '16px',
    },
    buttonUnstyled: {
      cursor: 'default',
      all: 'initial',
      font: 'inherit',
      color: 'inherit',
    },
    showSelected: {
      color: '#0e7679',
    },
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    rowLabel: {
      whiteSpace: 'nowrap',
      color: customTheme.palette.text.light,
    },
    value: {
      textAlign: 'right',
      paddingRight: theme.spacing(3),
    },
    ghost: {
      opacity: 0.6,
    },
    iconButton: {
      paddingRight: theme.spacing(2),
      width: 'inherit',
      height: 'inherit',
      fontSize: '18px',
    },
    startDateContainer: {
      position: 'absolute',
      zIndex: 2,
      left: '30%',
    },
  })

interface IState {
  isShowDatePicker: boolean
  currentLocale: string | undefined
}
class ContractFlowStartDate extends React.Component<TProps, IState> {
  public state: IState = { isShowDatePicker: false, currentLocale: undefined }

  public render() {
    const { active, activeEnum, classes, isHidden, contractStartDateValue, freeContract } = this.props
    const { isShowDatePicker } = this.state

    const isActive = active >= activeEnum
    const isDisableShowButton = isShowDatePicker

    return (
      <>
        <Panel disabled={!isActive} hidden={isHidden}>
          <PanelHeader>
            <PanelTitle>{t('Start Date')}</PanelTitle>
          </PanelHeader>
          <PanelContent>
            <Card
              className={isActive ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}
              data-e2e={'ContractFlowStartDate'}
            >
              <CardContent className={classes.root}>
                <div className={classes.row}>
                  <div className={classes.rowLabel}>{'Start date of contract'}:</div>
                  <div className={classes.value}>
                    <button
                      disabled={isDisableShowButton}
                      className={classNames(classes.buttonUnstyled, isDisableShowButton && classes.showSelected)}
                      onClick={this.handleShowDatePicker}
                    >
                      <IconButton disabled={isDisableShowButton} classes={{ root: classes.iconButton }}>
                        &nbsp;{/* Note: &nbsp; is important here to keep the styling intact. */}
                        {contractStartDateValue ? (
                          <EditIcon fontSize="inherit" />
                        ) : (
                          <div className={classes.ghost}>~</div>
                        )}
                      </IconButton>
                      {!!contractStartDateValue && formatDate(contractStartDateValue)}
                    </button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </PanelContent>
        </Panel>
        {isShowDatePicker && this.renderStartDatePicker()}
      </>
    )
  }

  private initLocale = (locale: IsoLocale | string) => {
    const localeOrLanguage = locale.toLowerCase()
    try {
      // First, try load with a possible full locale code ('xx-yy').
      require('moment/locale/' + localeOrLanguage)
      moment.locale(localeOrLanguage)

      return
    } catch (error) {}

    try {
      // Then if that fails, try load with only the language part ('xx').
      const language = localeToLanguage(localeOrLanguage)

      require('moment/locale/' + language)
      moment.locale(language)
      this.setState({ currentLocale: locale })
    } catch (error) {
      console.warn('DatePicker: initLocale(..) failed for some reason, locale = ' + locale + '\n' + error)
    }
  }

  private renderStartDatePicker = () => {
    const { classes, contractStartDateValue } = this.props

    const localeOrLanguage = getLocale()
    if (localeOrLanguage && this.state.currentLocale !== localeOrLanguage) {
      this.initLocale(localeOrLanguage)
      this.setState({ currentLocale: localeOrLanguage })
    }

    return (
      <div className={classes.startDateContainer}>
        <DatePicker
          id="warranty-start-date"
          date={contractStartDateValue && moment(contractStartDateValue)}
          initialDate={moment(new Date())}
          onDateChange={this.handleDateChange}
          onOutsideClick={this.handleHideDatePicker}
          dateRangeLength={allowedYearsIntoTheFuture}
          datePickerWithoutInput
        />
      </div>
    )
  }

  private handleShowDatePicker = () => {
    this.setState({ isShowDatePicker: true })
  }

  private handleHideDatePicker = () => {
    this.setState({ isShowDatePicker: false })
  }

  private handleDateChange = (startDateMoment: Moment | null, closePicker?: boolean) => {
    this.setState({ isShowDatePicker: false })
    this.props.onStartDateChange(startDateMoment?.toDate())
  }
}

export default withStyles(styles)(ContractFlowStartDate)
