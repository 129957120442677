import { Hidden, IconButton, withWidth } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Person as PersonIcon,
} from '@material-ui/icons'
import { logout } from 'api/api'
import { ReactComponent as JustGOLogo } from 'assets/svgs/JustGo-rgb-dark.svg'
import classNames from 'classnames'
import Outage from 'components/admin/Outage'
import React from 'react'
import { compose } from 'recompose'
import { clearTokens } from 'utils/auth'
import AppHeaderMenuFloating from './Menu/Floating'
import AppHeaderMenuSidebar from './Menu/Sidebar'
import styles from './styles'

interface IOwnProps {
  onToggleSidebar: (isSidebarOpen: boolean) => void
  onToggleAccountSettings: () => void
  isSidebarOpen: boolean
}

interface IState {
  open: boolean
  hasActiveOutage: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

class AppHeader extends React.Component<TProps, IState> {
  public state = {
    open: false,
    hasActiveOutage: false,
  }

  public render() {
    const { classes, isSidebarOpen } = this.props
    const { open, hasActiveOutage } = this.state

    return (
      <div className={classNames(hasActiveOutage ? classes.header : '')}>
        <Outage onOutageChange={this.handleOutageChange} />
        <div className={classNames(classes.root, hasActiveOutage ? classes.paddingTop : '')} data-e2e={'AppHeader'}>
          <Hidden lgUp={true}>
            <IconButton className={classes.arrowBack} onClick={this.toggleSidebar}>
              {isSidebarOpen ? <CloseIcon /> : <ArrowBackIcon />}
            </IconButton>
          </Hidden>
          <JustGOLogo className={classes.justGOLogo} width={200} />
          <div className={classes.options} data-e2e={'AppHeader__account'}>
            <IconButton onClick={this.toggleMenu}>
              <Hidden smUp={true}>
                <MenuIcon className={classes.menuIcon} />
              </Hidden>
              <Hidden xsDown={true}>
                <PersonIcon className={classes.menuIcon} />
              </Hidden>
            </IconButton>
          </div>

          <Hidden xsDown={true}>
            <AppHeaderMenuFloating
              open={open}
              onToggle={this.toggleMenu}
              onLogOut={this.handleLogOut}
              onToggleAccountSettings={this.toggleAccountSettings}
            />
          </Hidden>

          <Hidden smUp={true}>
            <AppHeaderMenuSidebar
              open={open}
              onToggle={this.toggleMenu}
              onLogOut={this.handleLogOut}
              onToggleAccountSettings={this.toggleAccountSettings}
            />
          </Hidden>
        </div>
      </div>
    )
  }

  private toggleSidebar = () => {
    const { isSidebarOpen, onToggleSidebar: toggleSidebar } = this.props
    if (toggleSidebar) {
      toggleSidebar(!isSidebarOpen)
    }
  }

  private handleLogOut = async () => {
    await logout()
    this.toggleMenu()
    clearTokens()
  }

  private toggleMenu = () => {
    this.setState(({ open }) => ({
      open: !open,
    }))
  }

  private toggleAccountSettings = () => {
    this.toggleMenu()
    this.props.onToggleAccountSettings()
  }

  private handleOutageChange = (hasActiveOutage: boolean) => {
    if (this.state.hasActiveOutage !== hasActiveOutage) this.setState({ hasActiveOutage })
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles), withWidth())(AppHeader)
