import React from 'react'
import { Typography } from '@material-ui/core'
import { t } from 'translations/translationFunctions'

export interface ISettleHeaderProps {
  descWrapperClassName: string
}

const ContractSettleHeader: React.SFC<ISettleHeaderProps> = ({ descWrapperClassName }) => (
  <>
    <Typography variant="h4" gutterBottom>
      {t('Is it time to settle the contract?')}
    </Typography>
    <div className={descWrapperClassName}>
      <p>
        {t(
          'Here you may make any final payments to settle your contract. First, you need to make sure that neither party owes the other money. Then, you can complete the settlement.',
        )}
      </p>
    </div>
  </>
)

export default ContractSettleHeader
