import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      backgroundColor: 'white',
    },
    creditCardIcon: {
      height: spacing(4),
      marginRight: spacing(2),
    },
    cardInfoWrap: {
      width: '100%',
    },
    cardInfo: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    cardExpires: {
      marginTop: 0.5 * spacing(1),
    },
    bottom: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 'auto',
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      height: spacing(6),
      backgroundColor: palette.secondary[50],
    },
    labelActive: {
      color: palette.secondary.main,
    },
    bottomInactive: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.border.lighter}`,
    },
    btnDelete: {
      color: theme.palette.context.warning[500],
    },
  })
