import { Button, InputLabel, TableCell, TableRow } from '@material-ui/core'
import { WithStyles } from '@material-ui/core/styles'
import { INullableRole, UserRoleRecord } from '@omnicar/sam-types'
import RoleSelect from 'components/admin/User/Form/RoleSelect'
import React, { ChangeEvent } from 'react'
import { t } from 'translations/translationFunctions'

interface IProps {
  value: INullableRole['role'] | undefined
  name: string
  handleChange: (e: ChangeEvent<any>) => void
  handleBlur: (e: ChangeEvent<any>) => void
  provider: UserRoleRecord['contractProvider']
  isButtonAvailable: boolean
  handleRoleCopying: (e: ChangeEvent<any>) => void
  customClass?: string
  labelEndAdornment?: string
  filterEnabled?: boolean
}

export default class RoleTableRow extends React.Component<IProps & WithStyles> {
  render() {
    const {
      provider,
      handleChange,
      handleBlur,
      classes,
      name,
      value,
      handleRoleCopying,
      isButtonAvailable,
      customClass,
      labelEndAdornment,
      filterEnabled,
    } = this.props

    return (
      <TableRow className={customClass}>
        <TableCell>
          <InputLabel>{provider.id}</InputLabel>
        </TableCell>
        <TableCell>
          <InputLabel>{`${provider.administrativeName}${labelEndAdornment ? labelEndAdornment : ''}`}</InputLabel>
        </TableCell>
        <TableCell>
          <RoleSelect
            handleChange={handleChange}
            handleBlur={handleBlur}
            classes={classes}
            name={name}
            value={value}
            filterEnabled={filterEnabled}
          />
        </TableCell>
        <TableCell>
          {isButtonAvailable && (
            <Button size="small" onClick={handleRoleCopying} variant="outlined" className={classes.button}>
              {t('Copy to all')}
            </Button>
          )}
        </TableCell>
      </TableRow>
    )
  }
}
