import { Drawer, withStyles, WithStyles } from '@material-ui/core'
import {
  IApiOtherWithContracts,
  IApiVehicleContract,
  IApiVehicleWithContracts,
  IProviderWithContracts,
  TContractObject,
} from '@omnicar/sam-types'
import classNames from 'classnames'
import CustomerPortalVehicleList from 'components/customer/Contract/Details/VehicleList'
import { PageNavigation } from 'components/Mui/Page'
import { ITab } from 'pages/customer/ContractDetailsPage'
import React, { Component } from 'react'
import styles from './styles'

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  onCloseSidebar: () => void
  vehicles: IApiVehicleWithContracts[]
  providers: IProviderWithContracts[]
  selectedContract: IApiVehicleWithContracts | IApiOtherWithContracts | undefined
  onContractSelect: (
    selectedContract: IApiVehicleContract | undefined,
    selectedVehicle: IApiVehicleWithContracts | IApiOtherWithContracts | undefined,
  ) => void
  tabSetup: {
    hideTabs: boolean
    tabs: ITab<TContractObject>[]
    activeTab: TContractObject
    tabClasses: { container: string; tab: string; activeTab: string }
    handleTabChange: (tab: any) => void
  }
}

class VehicleListMobile extends Component<IProps> {
  public render() {
    const {
      classes,
      open,
      onCloseSidebar,
      providers,
      vehicles,
      onContractSelect,
      selectedContract,
      tabSetup,
    } = this.props
    const { tabs, activeTab, hideTabs, handleTabChange, tabClasses } = tabSetup

    return (
      <Drawer
        variant="temporary"
        open={open}
        // tslint:disable-next-line:jsx-no-lambda
        onClose={onCloseSidebar}
        classes={{
          paper: classes.paper,
          modal: classes.modal,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {!hideTabs && (
          <div className={classNames(tabClasses.container, classes.tabContainer)}>
            <PageNavigation
              className={classes.longContainer}
              active={activeTab}
              tabClassName={classNames(tabClasses.tab, classes.tab)}
              activeTabClassName={tabClasses.activeTab}
              onChange={handleTabChange}
              tabs={tabs}
            />
          </div>
        )}
        <CustomerPortalVehicleList
          vehicles={vehicles}
          providers={providers}
          handleContractSelect={onContractSelect}
          selectedContract={selectedContract}
        />
      </Drawer>
    )
  }
}

export default withStyles(styles)(VehicleListMobile)
