import { IconButton, withWidth } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { AccountCircle as AccountCircleIcon, MoreVert as MoreVertIcon } from '@material-ui/icons'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import { logout } from 'api/api'
import classNames from 'classnames'
import GlobalSearch from 'components/admin/GlobalSearch'
import LanguageSelector from 'components/LanguageSelector'
import PresentCountry from 'components/PresentCountry'
import Typography from 'components/Typography'
import React from 'react'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { clearTokens } from 'utils/auth'
import history from 'utils/history'
import { getWebTitle, isShowLogoOnWeb, isShowLogoOnWebNonWhiteBG } from 'utils/localStorage'
import AppHeaderMenuFloating from './Menu/Floating'
import InformationMenu from './Menu/Floating/InformationMenu'
import styles from './styles'

interface IOwnProps {
  providerInfo: IContractProviderInfo | undefined
  onToggleAccountSettings: () => void
}

interface IState {
  open: boolean
  infoMenuOpen: boolean
  providerInfo: IContractProviderInfo | undefined
}

type TProps = IOwnProps & WithStyles<typeof styles>

class AppHeader extends React.Component<TProps, IState> {
  public state = {
    open: false,
    infoMenuOpen: false,
    providerInfo: undefined,
  }

  public goHome = () => {
    history.push('/') // homePagePath
  }

  public render() {
    const { classes } = this.props
    const { open, infoMenuOpen } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.goHome} onClick={this.goHome}>
          {this.renderLogoTitleDiv()}
        </div>
        <div className={classes.options}>
          <div className={classes.search}>{<GlobalSearch defaultPlaceholder={t('Search for contracts')} />}</div>

          <AppContext.Consumer>
            {({ isSuperAdmin }) =>
              (process.env.REACT_APP_ENVIRONMENT === 'development' || isSuperAdmin) && (
                <div className={classes.languageSelector}>
                  <LanguageSelector />
                </div>
              )
            }
          </AppContext.Consumer>

          <IconButton
            className={classNames(classes.headerMenuIcon, classes.accountCircleIcon)}
            onClick={this.toggleMenu}
            data-e2e={'AppHeader__account'}
            title={t('My account') + ' / ' + t('Account Settings')}
          >
            <AccountCircleIcon className={classes.menu} />
          </IconButton>

          <IconButton
            className={classNames(classes.headerMenuIcon, classes.moreVertIcon)}
            onClick={this.toggleInfoMenu}
            data-e2e={'AppHeader__information'}
            title={t('What is updated and how to contact us, etc')}
          >
            <MoreVertIcon className={classes.menu} />
          </IconButton>

          <AppContext.Consumer>
            {({ isSuperAdmin }) =>
              (process.env.REACT_APP_ENVIRONMENT === 'development' || isSuperAdmin) && (
                <div className={classes.presentCountry}>
                  <PresentCountry />
                </div>
              )
            }
          </AppContext.Consumer>
        </div>

        <AppHeaderMenuFloating
          open={open}
          onToggle={this.toggleMenu}
          onLogOut={this.handleLogOut}
          onToggleAccountSettings={this.toggleAccountSettings}
        />
        <div>
          <InformationMenu
            open={infoMenuOpen}
            providerInfo={this.props.providerInfo}
            onToggle={this.toggleInfoMenu}
            onLogOut={this.handleLogOut}
            onToggleAccountSettings={this.toggleAccountSettings}
          />
        </div>
      </div>
    )
  }

  private renderLogoTitleDiv() {
    const { classes, providerInfo } = this.props

    const administrativeName = !providerInfo ? '' : providerInfo.administrativeName
    const titleToRender = getWebTitle() === null ? administrativeName : getWebTitle() // Note: getWebTitle() can be an empty string ('') to show no title at all.

    if (providerInfo && providerInfo.webLogoBanner && (isShowLogoOnWeb() || titleToRender)) {
      return (
        <div
          className={classNames(isShowLogoOnWebNonWhiteBG() ? classes.absoluteBrandContainer : classes.brandContainer)}
        >
          {isShowLogoOnWeb() && <img className={classes.brandLogo} src={providerInfo.webLogoBanner} alt="Logo" />}
          <div className={classes.brand}>
            <Typography variant="header" className={classes.companyName}>
              {titleToRender}
            </Typography>
          </div>
        </div>
      )
    } else {
      return (
        <div className={classes.brand}>
          <Typography variant="header" className={classes.companyName}>
            {titleToRender}
          </Typography>
        </div>
      )
    }
  }

  private handleLogOut = async () => {
    this.toggleMenu()
    await logout()
    clearTokens()
  }

  private toggleMenu = () => {
    this.setState({ open: !this.state.open })
  }

  private toggleInfoMenu = () => {
    this.setState({ infoMenuOpen: !this.state.infoMenuOpen, providerInfo: this.state.providerInfo })
  }

  private toggleAccountSettings = () => {
    this.toggleMenu()
    this.props.onToggleAccountSettings()
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles), withWidth())(AppHeader)
