import { IJsonStatus } from '@omnicar/sam-tfetch'
import {
  ApiError,
  ContractState,
  CustomerUpdateResponse,
  IAdminCustomer,
  IContractDetailsRecord,
  Other,
  Vehicle,
} from '@omnicar/sam-types'
import ContractDetailsActionList from 'components/admin/Contract/Details/ActionList'
import InternalNote from 'components/admin/Contract/Details/InternalNote'
import ContractDetailsSpecifics from 'components/admin/Contract/Details/Specifics'
import CustomerDetails from 'components/admin/Customer/Details'
import ProductDetails from 'components/admin/Product/Details'
import { LayoutRow } from 'components/Mui/Layout'
import React from 'react'
import { AppContext } from 'store/appContext'

interface IProps {
  customerRecord: IAdminCustomer
  contractPrettyId: string
  contractState: ContractState
  contractStateName: string
  isContractExternalWarranty: boolean
  isReadOnly: boolean
  vehicleRecord: Vehicle | Other
  productRecordLoading: boolean
  initVehicleRecord: () => Promise<void>
  contractSpecifics: IContractDetailsRecord
  isContractSpecificsLoading: boolean
  initContractSpecifics: () => Promise<void>
  isFromReportLink: boolean
  handleCustomerPatch: (
    contractIdent: string,
    editedCustomerRecord: IAdminCustomer,
    initialCustomerRecord: IAdminCustomer,
  ) => Promise<IJsonStatus<CustomerUpdateResponse, ApiError> | undefined>
  handleUpdateCustomerDetailsState: (customerRecord: IAdminCustomer) => void
}

const GeneralTab = ({
  customerRecord,
  contractPrettyId,
  isContractExternalWarranty,
  isReadOnly,
  vehicleRecord,
  productRecordLoading,
  initVehicleRecord,
  contractSpecifics,
  isContractSpecificsLoading,
  initContractSpecifics,
  isFromReportLink,
  contractState,
  contractStateName,
  handleCustomerPatch,
  handleUpdateCustomerDetailsState,
}: IProps) => {
  const allowEdit = !isReadOnly && [100, 200, 250].includes(contractState)
  const allowEditCustomer = allowEdit && customerRecord.customerType === 'PRIVATE'

  return (
    <div>
      <AppContext.Consumer>
        {({ isSuperAdmin, providerInfo, role }) => (
          <LayoutRow columns={2}>
            <CustomerDetails
              customerIdentifier={customerRecord?.prettyIdentifier ? customerRecord?.prettyIdentifier : ''}
              contractPrettyId={contractPrettyId}
              customerRecord={customerRecord}
              onPatchCallback={async (customerPrettyId: string, editedCustomerRecord: IAdminCustomer) => {
                const res: IJsonStatus<CustomerUpdateResponse, ApiError> | undefined = await handleCustomerPatch(
                  contractPrettyId,
                  editedCustomerRecord,
                  customerRecord,
                )
                return res!
              }}
              allowEdit={
                (!providerInfo || !providerInfo.inactive) &&
                (allowEditCustomer || (isContractExternalWarranty && isSuperAdmin))
              }
              onUpdateCustomerDetailsState={handleUpdateCustomerDetailsState}
            />
            <ProductDetails
              id={contractPrettyId}
              allowEdit={
                (!providerInfo || !providerInfo.inactive) &&
                (allowEdit || (isContractExternalWarranty && isSuperAdmin)) &&
                !isReadOnly
              }
              record={vehicleRecord}
              loading={productRecordLoading}
              initVehicleRecord={initVehicleRecord}
            />
          </LayoutRow>
        )}
      </AppContext.Consumer>
      <ContractDetailsSpecifics
        id={contractPrettyId}
        record={contractSpecifics}
        loading={isContractSpecificsLoading}
        initContractSpecifics={initContractSpecifics}
        fromReportLink={isFromReportLink}
        contractState={contractState}
        contractStateName={contractStateName}
      />
      <InternalNote id={contractPrettyId} isReadOnly={isReadOnly} />
      <ContractDetailsActionList id={contractPrettyId} />
    </div>
  )
}

export default GeneralTab
