import ActionTypeKeys from './ActionTypeKeys'
import { IContractBalanceStatistics, ISettlementActionRequest, ISettlementActionResponse } from '@omnicar/sam-types'
import { Thunk, BoundThunk } from 'types/thunk'
import * as api from 'api/api'
import { ContractActionType } from '@omnicar/sam-types'
import { contractSettlementInitialState } from 'reducers/contractSettlement/contractSettlementState'
import { TActionFilterName } from 'types/settlement'

export interface ISaveContractStatistics {
  readonly type: ActionTypeKeys.SAVE_CONTRACT_STATISTICS
  readonly payload: IContractBalanceStatistics
}
export const saveContractStatistics = (payload: IContractBalanceStatistics) => ({
  type: ActionTypeKeys.SAVE_CONTRACT_STATISTICS,
  payload,
})

export type TInitBalanceStatistics = BoundThunk<typeof initBalanceStatistics>
export const initBalanceStatistics = (id: string): Thunk<Promise<IContractBalanceStatistics | undefined>> => async (
  dispatch,
) => {
  const statistics = await api.getContractBalanceStatistics(id)
  if (statistics && statistics.data) {
    dispatch(saveContractStatistics(statistics.data))
  } else {
    dispatch(saveContractStatistics(contractSettlementInitialState.balanceStatistics))
  }
  return statistics.data
}

export interface ISaveSettleActions {
  readonly type: ActionTypeKeys.SAVE_SETTLE_ACTIONS
  readonly payload: ISettlementActionResponse[]
}
export const saveSettleActions = (payload: ISettlementActionResponse[]) => ({
  type: ActionTypeKeys.SAVE_SETTLE_ACTIONS,
  payload,
})

export type TInitSettleActions = BoundThunk<typeof initSettleActions>
export const initSettleActions = (id: string): Thunk<Promise<ISettlementActionResponse[] | undefined>> => async (
  dispatch,
) => {
  const actions = await api.getContractSettlementActions(id)
  if (actions && actions.data) {
    dispatch(saveSettleActions(actions.data))
  }
  return actions.data
}

export interface IUpdateSettleActions {
  readonly type: ActionTypeKeys.UPDATE_SETTLE_ACTIONS
  readonly payload: ISettlementActionResponse[]
}
export const updateSettleActions = (payload: ISettlementActionResponse[] | ISettlementActionResponse) => ({
  type: ActionTypeKeys.UPDATE_SETTLE_ACTIONS,
  payload,
})

export type TUpdateSettleActions = BoundThunk<typeof updateSettleActionsThunk>
export const updateSettleActionsThunk = (id: string): Thunk<Promise<ISettlementActionResponse[] | undefined>> => async (
  dispatch,
) => {
  const actions = await api.getContractSettlementActions(id)
  if (actions && actions.data) {
    dispatch(updateSettleActions(actions.data))
  }
  return actions.data
}

export type TEditSettleAction = BoundThunk<typeof editSettleAction>
export const editSettleAction = (
  id: string,
  data: ISettlementActionRequest,
): Thunk<Promise<ISettlementActionResponse | undefined>> => async (dispatch) => {
  const action = await api.patchSettleAction(id, data)
  if (action && action.data) {
    dispatch(updateSettleActions([action.data]))
  }
  return action.data
}

export interface ISaveSettlementAttachments {
  readonly type: ActionTypeKeys.SAVE_SETTLEMENT_ATTACHMENTS
  readonly payload: FileList
}
export const saveSettlementAttachments = (payload: FileList) => ({
  type: ActionTypeKeys.SAVE_SETTLEMENT_ATTACHMENTS,
  payload,
})

export interface IRemoveSettlementAttachment {
  readonly type: ActionTypeKeys.REMOVE_SETTLEMENT_ATTACHMENT
  readonly payload: number
}
export const removeSettlementAttachment = (payload: number) => ({
  type: ActionTypeKeys.REMOVE_SETTLEMENT_ATTACHMENT,
  payload,
})

export interface ISetSettlementActionFilter {
  readonly type: ActionTypeKeys.SET_SETTLEMENT_ACTIONS_FILTER
  readonly payload: { name: TActionFilterName; value: ContractActionType[] | boolean }
}

export const setSettlementActionFilter = (payload: {
  name: TActionFilterName
  value: ContractActionType[] | boolean
}) => ({
  type: ActionTypeKeys.SET_SETTLEMENT_ACTIONS_FILTER,
  payload,
})

export type TSetActionFilter = BoundThunk<typeof setActionsFilter>
export const setActionsFilter = (name: TActionFilterName, value: ContractActionType[] | boolean): Thunk<void> => (
  dispatch,
) => dispatch(setSettlementActionFilter({ name, value }))

export interface IResetSettlementActionFilters {
  readonly type: ActionTypeKeys.RESET_SETTLEMENT_ACTION_FILTERS
}
export const resetSettlementActionsFilters = () => ({
  type: ActionTypeKeys.RESET_SETTLEMENT_ACTION_FILTERS,
})

export type TResetActionsFilter = BoundThunk<typeof resetActionsFilters>
export const resetActionsFilters = (): Thunk<void> => (dispatch) => dispatch(resetSettlementActionsFilters())
