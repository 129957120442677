import ActionTypes from 'actions/ActionTypes'
import ActionTypeKeys from 'actions/ActionTypeKeys'

export interface IContractSearchState {
  query?: string
}

export interface IWarrantySearchState {
  query?: string
}

export const contractSearchInitialState: IContractSearchState = {}

export const warrantySearchInitialState: IWarrantySearchState = {}

export const contractSearchReducer = (state = contractSearchInitialState, action: ActionTypes) => {
  switch (action.type) {
    /***********************************************
     * Reducers for contract search
     **********************************************/
    case ActionTypeKeys.CONTRACTSEARCH_UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      }
    default:
      return state
  }
}

export const warrantySearchReducer = (state = warrantySearchInitialState, action: ActionTypes) => {
  switch (action.type) {
    /***********************************************
     * Reducers for warranty search
     **********************************************/
    case ActionTypeKeys.WARRANTYSEARCH_UPDATE_QUERY:
      return { ...state, query: action.query }
    default:
      return state
  }
}
