import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Edit as EditIcon } from '@material-ui/icons'
import { formatDate, formatMileage } from '@omnicar/sam-format'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { getLocale } from '@omnicar/sam-translate'
import {
  ApiError,
  IAdminCustomer,
  IAvailableFreeWarranty,
  IAvailableFreeWarrantyDurationPrice,
  ICreateDelaerPaidContractRequest,
  ICreateDelaerPaidContractResponse,
  IsoLocale,
  Vehicle,
  VehicleAlongItsContracts,
} from '@omnicar/sam-types'
import { createFreeWarranty } from 'api/api'
import classNames from 'classnames'
import CustomerWrapper from 'components/admin/Contract/Flow/Customer/Wrapper'
import ConfirmDialog from 'components/ConfirmDialog'
import DatePicker from 'components/DatePicker'
import { Card } from 'components/Mui/Card'
import { LayoutBlock } from 'components/Mui/Layout'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Typography from 'components/Typography'
import moment, { Moment } from 'moment'
import React, { ChangeEvent } from 'react'
import { createDialogStyles } from 'theme'
import { t } from 'translations/translationFunctions'
import { TranslationItem, TranslationKey } from 'translations/translationTypes'
import { errorMessage } from 'utils/errorHandling'
import { localeToLanguage } from 'utils/locale'
import { getProvider } from 'utils/localStorage'
import notify from 'utils/notify/notify'
import { localFuelTypeName } from 'utils/vehicle'
import { addMonths } from '../../../utils/date'
import { freeWarrantyBorderColor, freeWarrantyButtonColor } from './FreeWarrantyColors'

interface IOwnProps {
  open: boolean
  vehicleAlongItsContracts: VehicleAlongItsContracts
  startMileage: number | undefined
  customerId?: number
  customer: IAdminCustomer
  style?: any
  onClose: () => void
  onWarrantyCreated: () => void
  warranty: IAvailableFreeWarranty
  areAnyCustomerPricesSet: boolean
  onCustomerChange?: (value: IAdminCustomer, valid: boolean) => void
  onCustomerLockedChange?: (locked: boolean) => void
  customerLocked: boolean
  customerValid: boolean
  vehicleUpdates: number
  customerUpdates: number
  durationPrice: IAvailableFreeWarrantyDurationPrice
}

interface IState {
  dialogState: DialogState
  showCustomerComponent: ShowCustomerComponent
  closedDialogMessageDidReachedMax: boolean
  errMsg: TranslationItem
  jsxSuccess: JSX.Element
  termsAccepted: boolean
  startDate: Date | null
  endDate: Date | null
  currentLocale: string | undefined
  isDatePickerShown: boolean
  allowedDistanceMileage: number | undefined // Maximum End-mileage/distance at contract expiration. NOTE: Not the same as maxEndMileage / maxProductEndMileageCap.
  reference: string | undefined
}

type DialogState = 'BeforeSend' | 'Sending' | 'Created' | 'ErrorAfterSend' | 'SystemPreventsActivation'

type ShowState = DialogState | 'MissingInformation' | 'ShowingCustomerComponent' | 'CustomerNotReady' | 'ReadyToSend'

type ShowCustomerComponent = 'Yes' | 'No' | 'Maybe'

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createDialogStyles(theme, {
    dataWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '70px',
      paddingLeft: '70px',
    },
    referenceWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    content: {
      minHeight: 111,
    },
    actions: {
      minHeight: 36,
    },
    createNewButton: {
      marginRight: theme.spacing(1),
    },
    left: {
      textAlign: 'left',
      padding: 0,
    },
    right: {
      textAlign: 'right',
    },
    centerChilds: {
      justifyContent: 'center',
    },
    centeredTable: {
      borderSpacing: 0,
    },
    veryImportant: {
      color: 'red',
    },
    dealerPaidTitle: {
      borderTop: `${theme.spacing(1.5)}px solid ${freeWarrantyBorderColor}`,
      textAlign: 'center',
    },
    cardDealerPaid: {
      borderLeftColor: freeWarrantyBorderColor,
    },
    buttonDealerPaid: {
      backgroundColor: freeWarrantyButtonColor,
    },
    warning: {
      borderSize: '0.2em',
      borderColor: freeWarrantyBorderColor,
    },
    warningCardContent: {
      border: '0.3em solid orange',
      borderRadius: 'inherit',
    },
    warningText: {
      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: '1.15em',
    },
    buttonSomeHorizontalMargin: {
      marginRight: '0.5em',
      marginLeft: '0.5em',
    },
    startDateContainer: {
      position: 'absolute',
      zIndex: 2,
      top: '23%',
      right: '5%',
    },
    startDateText: {
      textAlign: 'right',
      fontWeight: '600',
      color: theme.palette.text.primary,
      position: 'relative',
    },
    delayedStartDateText: {
      color: freeWarrantyBorderColor,
      fontWeight: '600',
    },
  })

interface ITableRow {
  title: string
  value: string
  important?: boolean
  veryImportant?: boolean
  editAction?: () => {}
  isStartDateRow?: boolean
}

class FreeWarrantyDialog extends React.Component<TProps, IState> {
  private titles: { [key in ShowState]: TranslationKey } = {
    MissingInformation: 'Missing information',
    ShowingCustomerComponent: 'Please add missing data',
    BeforeSend: 'Dealer paid warranty contract',
    ReadyToSend: 'Dealer paid warranty contract',
    Sending: 'Creating dealer paid warranty contract ...',
    Created: 'The warranty contract has been created',
    ErrorAfterSend: 'An error occured creating dealer paid warranty contract',
    CustomerNotReady: 'Missing customer information',
    SystemPreventsActivation: 'System prevents activation of the contract for this vehicle',
  }

  private defaultState: IState = {
    dialogState: 'BeforeSend',
    showCustomerComponent: 'Maybe',
    closedDialogMessageDidReachedMax: false,
    errMsg: undefined,
    jsxSuccess: <div />,
    termsAccepted: false,
    isDatePickerShown: false,
    startDate: this.props.warranty.contractStartDate,
    endDate: null,
    allowedDistanceMileage: undefined,
    currentLocale: undefined,
    reference: undefined,
  }

  public constructor(props: TProps) {
    super(props)
    this.state = this.defaultState
  }

  public render() {
    const { vehicleAlongItsContracts, startMileage, onClose, open, classes, warranty, durationPrice } = this.props

    const durationMonths = durationPrice.durationMonths
    const validateErrors = this.validateCreateInfo()
    const showState = this.getShowState()
    const jsxMainContent = this.renderMainContent(
      showState,
      validateErrors,
      warranty,
      durationMonths,
      vehicleAlongItsContracts,
      startMileage || 0,
    )
    const closeButtonText = this.getCloseButtonText(showState)
    const showSendButton =
      showState === 'ReadyToSend' || showState === 'CustomerNotReady' || showState === 'MissingInformation'
    const enableSendButton = showState === 'ReadyToSend' && this.state.termsAccepted
    const title = this.titles[showState]
    const dialogTitle: string = t(title)

    return (
      <Dialog
        open={open}
        onClose={onClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        data-e2e={'FreeWarrantyDialog__dialog'}
      >
        <DialogTitle className={classes.dealerPaidTitle}>{dialogTitle}</DialogTitle>
        <DialogContent className={classes.content}>
          <LayoutBlock dense={true}>{jsxMainContent}</LayoutBlock>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {showState !== 'Sending' && (
            <React.Fragment>
              {showSendButton && (
                <Button
                  disabled={!enableSendButton}
                  className={classNames(classes.button, classes.buttonDealerPaid, classes.buttonSomeHorizontalMargin)}
                  onClick={this.handleSendRequest}
                  color="primary"
                  variant="contained"
                  data-e2e={'FreeWarrantyDialog__dialog-createnew-button'}
                >
                  {t('Sign Dealer Paid Warranty')}
                </Button>
              )}
              {showState !== 'ShowingCustomerComponent' && (
                <Button
                  className={classNames(classes.button, classes.buttonSomeHorizontalMargin)}
                  onClick={this.handleClose}
                  color="secondary"
                  variant="contained"
                  autoFocus={true}
                  data-e2e={'FreeWarrantyDialog__dialog-close-button'}
                >
                  {t(closeButtonText)}
                </Button>
              )}
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    )
  }

  // ===================================================================
  // State handling
  // ===================================================================

  private resetState = () => {
    // eslint-disable-next-line
    this.setState(this.defaultState)
  }

  private handleTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ termsAccepted: e.currentTarget.checked })
  }

  private getShowState = (): ShowState => {
    const customerValid = this.customerValid()
    const dialogState: DialogState = this.state.dialogState
    let showState: ShowState = dialogState
    if (dialogState === 'BeforeSend') {
      if (this.state.showCustomerComponent === 'Yes') {
        showState = 'ShowingCustomerComponent'
      } else if (this.state.showCustomerComponent === 'Maybe' && !customerValid) {
        this.setState({ showCustomerComponent: 'Yes' })
        showState = 'ShowingCustomerComponent'
      } else if (!customerValid) {
        showState = 'CustomerNotReady'
      } else if (this.validateCreateInfo().length > 0) {
        showState = 'MissingInformation'
      } else {
        showState = 'ReadyToSend'
      }
    }
    return showState
  }

  private handleShowDatePicker = () => this.setState({ isDatePickerShown: true })

  private handleHideDatePicker = () => this.setState({ isDatePickerShown: false })

  // ===================================================================
  // Render content parts
  // ===================================================================

  private getCloseButtonText = (state: ShowState) => {
    let buttonText: TranslationKey = ''
    if (state === 'ReadyToSend') {
      buttonText = 'Cancel'
    } else {
      buttonText = 'Click the button to close'
    }
    return buttonText
  }

  private renderMainContent = (
    state: ShowState,
    validateErrors: string[],
    w: IAvailableFreeWarranty,
    durationMonths: number,
    vehicleAlongItsContracts: VehicleAlongItsContracts,
    startMileage: number,
  ) => {
    if (state === 'ErrorAfterSend') {
      //TODO: marko, break out from this in a cleaner way
      // return <div>Reload the application</div>
      return <div />
    }

    return (
      <div className={this.props.classes.centerChilds}>
        {state === 'MissingInformation' && this.renderErrorList(validateErrors)}
        {state === 'ShowingCustomerComponent' && this.renderCustomerComponent()}
        {state === 'CustomerNotReady' &&
          this.renderReadyToSend(w, durationMonths, vehicleAlongItsContracts, startMileage)}
        {state === 'ReadyToSend' && this.renderReadyToSend(w, durationMonths, vehicleAlongItsContracts, startMileage)}
        {state === 'Sending' && this.renderSending()}
        {state === 'Created' && this.state.jsxSuccess}
        {/* {state === 'ErrorAfterSend' && this.state.errMsg} */}
      </div>
    )
  }

  private renderVehicleInfo = (
    v: Vehicle | VehicleAlongItsContracts,
    startMileage: number | undefined,
    endDate?: Date,
    warrantyName?: string,
  ): JSX.Element => {
    const vehicleRows = this.vehicleRows(v, startMileage, endDate, warrantyName)
    const jsx = this.renderTable(vehicleRows, true)
    return jsx
  }

  private vehicleRows = (
    v: Vehicle | VehicleAlongItsContracts,
    startMileage: number | undefined,
    endDate?: Date,
    warrantyName?: string,
  ): ITableRow[] => {
    const rows = []

    warrantyName && rows.push({ title: t('Warranty'), value: warrantyName })
    endDate && rows.push({ title: t('Valid to'), value: formatDate(endDate) })
    rows.push({
      title: t('Vehicle'),
      value: `${v.brand.name} ${v.model.name} ${localFuelTypeName(v)} ${v.modelYear ? v.modelYear : ''}`,
      important: true,
    })
    rows.push({
      title: t('Registration Date'),
      value: formatDate(v.regDate ? new Date(v.regDate) : new Date()),
      important: true,
    })
    rows.push({ title: t('Vehicle Id No'), value: v.vin || '', important: true })
    rows.push({ title: t('Registration Number'), value: v.regNumber || '', important: true })
    rows.push({
      title: t('Current mileage'),
      value: `${formatMileage(startMileage || 0, { symbolDisplayType: 'APPEND' })}`,
      important: true,
    })

    return rows
  }

  private userRows = (): ITableRow[] => {
    const rows: ITableRow[] = []
    if (this.customerValid()) {
      rows.push({
        title: t('Customer'),
        value: `${this.props.customer.name}`,
        important: true,
        editAction: () => this.renderEditButton(this.handleShowCustomerComponent),
      })
      rows.push({ title: t('Email'), value: `${this.props.customer.email}` })
    } else {
      rows.push({
        title: t('Customer'),
        value: t(`Missing customer info`),
        important: true,
        veryImportant: true,
        editAction: () => this.renderEditButton(this.handleShowCustomerComponent),
      })
    }
    return rows
  }

  private initLocale = (locale: IsoLocale | string) => {
    const localeOrLanguage = locale.toLowerCase()
    try {
      // First, try load with a possible full locale code ('xx-yy').
      require('moment/locale/' + localeOrLanguage)
      moment.locale(localeOrLanguage)

      return
    } catch (error) {}

    try {
      // Then if that fails, try load with only the language part ('xx').
      const language = localeToLanguage(localeOrLanguage)

      require('moment/locale/' + language)
      moment.locale(language)
      this.setState({ currentLocale: locale })
    } catch (error) {
      console.warn('DatePicker: initLocale(..) failed for some reason, locale = ' + locale + '\n' + error)
    }
  }

  private renderStartDatePicker = () => {
    const { classes, warranty } = this.props
    const { startDate } = this.state

    const localeOrLanguage = getLocale()
    if (localeOrLanguage && this.state.currentLocale !== localeOrLanguage) {
      this.initLocale(localeOrLanguage)
      this.setState({ currentLocale: localeOrLanguage })
    }

    return (
      <div className={classes.startDateContainer}>
        <DatePicker
          id="warranty-start-date"
          date={startDate && moment(startDate)}
          initialDate={moment(warranty.contractStartDate)}
          onDateChange={this.handleDateChange}
          onOutsideClick={this.handleHideDatePicker}
          dateRangeLength={3}
          datePickerWithoutInput
        />
      </div>
    )
  }

  private renderCustomerComponent = () => {
    return (
      <CustomerWrapper
        customerLocked={this.props.customerLocked}
        onCustomerChange={this.props.onCustomerChange}
        onCustomerLockedChange={this.props.onCustomerLockedChange}
        value={this.props.customer}
        valid={this.customerValid()}
        onClose={this.handleCustomerCompnentClose}
        customerUpdates={this.props.customerUpdates}
        dealerPaidWarranty={true}
      />
    )
  }

  private renderSending = () => (
    <React.Fragment>
      <div>{t('Please hold on as the warranty contract is created')}</div>
      <CircularProgress size={32} color="secondary" />
    </React.Fragment>
  )

  private renderReadyToSend = (
    w: IAvailableFreeWarranty,
    durationMonths: number,
    v: VehicleAlongItsContracts,
    startMileage: number,
  ) => {
    // Refactor?
    const { isDatePickerShown, closedDialogMessageDidReachedMax, allowedDistanceMileage, reference } = this.state

    // ---- Local status flags ------------
    let didReachedMaxProductEndMileageCap = false
    let isAdjustedAllowedDistanceMileageForThisDuration = false
    let hasAllowedDistanceMileage = false
    // ------------------------------------

    const startDate: Date = w.contractStartDate
    const endDate = this.state.endDate || addMonths(startDate, durationMonths)
    const dp = this.props.durationPrice
    const strDealerFeeExVat = dp.customerPrice ? t('agreed fee') : `${dp.totalPrice.price} ${dp.totalPrice.currency}`
    const strCustomerPriceIncVat = dp.customerPrice
      ? `${dp.customerPrice.priceInclVat} ${dp.customerPrice.currency}`
      : ''

    // -------------------------------------------------------------------------------------- //
    const maxProductEndMileageCap: number = w.maxEndMileage // This is the product odometer max limit.

    const durationItems: IAvailableFreeWarrantyDurationPrice[] = w.durationsPrices
    const categoryForThisDuration: IAvailableFreeWarrantyDurationPrice = durationItems.filter(
      (item: IAvailableFreeWarrantyDurationPrice) => {
        return durationMonths === item.durationMonths
      },
    )[0]
    hasAllowedDistanceMileage = !!categoryForThisDuration.allowedDistanceMileage

    let localizedMessageDidReachedMax: string = ''
    let allowedDistanceMileageForThisDuration: undefined | number = categoryForThisDuration.allowedDistanceMileage // This is the allowed driving km during the whole warranty duration.
    let yearlyMileageLimitForThisDuration: undefined | number = undefined
    if (allowedDistanceMileageForThisDuration) {
      // Adjust the Allowed-Distance-Mileage if needed.
      if (maxProductEndMileageCap < startMileage + allowedDistanceMileageForThisDuration) {
        // If we reach max end mileage cap/limit, then we must recude the allowed driving km to still stay withing the product max cap for this product.
        const oldAllowedDistanceMileageForThisDuration = allowedDistanceMileageForThisDuration
        allowedDistanceMileageForThisDuration = maxProductEndMileageCap - startMileage // Adjust this value to to still stay withing the product max cap for this product.
        didReachedMaxProductEndMileageCap = true
        isAdjustedAllowedDistanceMileageForThisDuration = true

        const formattedMaxProductEndMileageCap: string = formatMileage(maxProductEndMileageCap, {
          symbolDisplayType: 'APPEND',
        })
        const formattedAllowedDistanceMileageForThisDuration: string = formatMileage(
          allowedDistanceMileageForThisDuration,
          {
            symbolDisplayType: 'APPEND',
          },
        )
        const formattedOldAllowedDistanceMileageForThisDuration: string = formatMileage(
          oldAllowedDistanceMileageForThisDuration,
          {
            symbolDisplayType: 'APPEND',
          },
        )

        localizedMessageDidReachedMax =
          t(
            '%absoluteMaxEndMileageCap is the absolute maximum End-Mileage Odometer reading for this product. Which means that the Warranty expires if the mileage reaches its limit during this period of %durationMonths months. Therefore the driving mileage during this period may not exceed %allowedDistanceMileageDuringThisDuration (reduced from %oldAllowedDistanceMileageDuringThisDuration)',
            {
              absoluteMaxEndMileageCap: formattedMaxProductEndMileageCap,
              durationMonths: categoryForThisDuration.durationMonths,
              allowedDistanceMileageDuringThisDuration: formattedAllowedDistanceMileageForThisDuration,
              oldAllowedDistanceMileageDuringThisDuration: formattedOldAllowedDistanceMileageForThisDuration,
            },
          ) + '. '
      }

      if (!allowedDistanceMileage && allowedDistanceMileageForThisDuration) {
        this.setState({ allowedDistanceMileage: allowedDistanceMileageForThisDuration })
      }
      yearlyMileageLimitForThisDuration = !allowedDistanceMileageForThisDuration
        ? 0
        : Math.round(12 * (allowedDistanceMileageForThisDuration / categoryForThisDuration.durationMonths))
    }
    // -------------------------------------------------------------------------------------- //

    // -- End Mileage ----------------------------------------------------------------------- //
    let endMileage
    if (
      didReachedMaxProductEndMileageCap ||
      !categoryForThisDuration ||
      !categoryForThisDuration.allowedDistanceMileage
    ) {
      endMileage = w.maxEndMileage
    } else {
      endMileage = startMileage + categoryForThisDuration.allowedDistanceMileage
    }
    // -------------------------------------------------------------------------------------- //

    const warrantyRows: ITableRow[] = []
    warrantyRows.push({ title: t('Warranty Product'), value: w.warrantyName, important: true })
    warrantyRows.push({ title: t('Duration'), value: `${durationMonths} ${t('months')}`, important: true })
    warrantyRows.push({
      title: t('Warranty Start Date'),
      value: `${formatDate(startDate)}`,
      important: true,
      isStartDateRow: true,
      editAction: () => this.renderEditButton(this.handleShowDatePicker),
    })
    warrantyRows.push({ title: t('Warranty End Date'), value: `${formatDate(endDate)}`, important: true })
    /*
    // warrantyRows.push({
    //   // Product Max Limit Odometer Reading
    //   title: t('Max mileage'),
    //   value: `${formatMileage(w.maxEndMileage, { symbolDisplayType: 'APPEND' })}`,
    //   important: true,
    // })
    warrantyRows.push({
      // Product's maximum final kilometer meter-reading.
      title: t('Product Max End-mileage'),
      value: `${formatMileage(w.maxEndMileage, { symbolDisplayType: 'APPEND' })}`,
      important: false,
    })
    */
    warrantyRows.push({
      // End-mileage.
      title: t('Odometer at expiration'),
      value: `${formatMileage(endMileage, { symbolDisplayType: 'APPEND' })}`,
      important: true,
    })
    if (yearlyMileageLimitForThisDuration) {
      // NOTE: This (allowedDistanceMileageForThisDuration) currently only exists for warranties in Finland! (not the same as maxEndMileage)
      warrantyRows.push({
        title: t('Yearly Mileage Limit'),
        value: `${formatMileage(yearlyMileageLimitForThisDuration, { symbolDisplayType: 'NONE' })} ${t('km/Year')}`,
        important: true,
      })
    }

    const summaryRows: ITableRow[] = !this.props.areAnyCustomerPricesSet
      ? [
          {
            title: t('Total Cost ex VAT'),
            value: strDealerFeeExVat,
            important: true,
          },
        ]
      : strCustomerPriceIncVat
      ? [
          {
            title: t('Price Incl VAT'),
            value: strCustomerPriceIncVat,
            important: true,
          },
        ]
      : []
    const vehicleRows = this.vehicleRows(v, startMileage)
    const userRows = this.userRows()
    const tableRows = warrantyRows.concat(vehicleRows, userRows, summaryRows)
    const classes = this.props.classes
    const ref = w.warrantyTermsRef
    const provider = getProvider()
    const providerName = (provider && provider.administrativeName) || 'Dealer' // This is not right, but how should do?
    const localizedAgreeTextShowFee = t(
      `By clicking Sign Dealer Paid Warranty below you agree that %Dealer will pay Fragus %PriceExVat + VAT for a warranty on above mentioned vehicle on given terms`,
      { PriceExVat: strDealerFeeExVat, Dealer: providerName },
    )
    const localizedAgreeTextHideFee = t(
      `By clicking Sign Dealer Paid Warranty below you agree that %Dealer will pay Fragus the agreed fee for a warranty on above mentioned vehicle on given terms`,
      { Dealer: providerName },
    )
    const localizedAgreeText = this.props.areAnyCustomerPricesSet
      ? localizedAgreeTextHideFee
      : localizedAgreeTextShowFee
    const localizedAgreeTextJsx = this.stringToJsx(localizedAgreeText)

    const status = {
      didReachedMaxProductEndMileageCap,
      isAdjustedAllowedDistanceMileageForThisDuration,
      hasAllowedDistanceMileage,
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('dev message: status:')
      console.log(status)
    }

    return (
      <React.Fragment>
        <div className={classes.dataWrapper}>
          {this.renderTable(tableRows, true)}
          {isDatePickerShown && this.renderStartDatePicker()}
          <div className={classes.referenceWrapper}>
            <label htmlFor="reference">{`${t('Reference')}:`}</label>
            <input name="reference" value={reference} onChange={this.handleReferenceChange} />
          </div>
        </div>
        <Panel>
          <PanelHeader>
            <PanelTitle>{''}</PanelTitle>
          </PanelHeader>
          <PanelContent>
            <Card className={classNames(classes.warning)}>
              <CardContent className={classNames(classes.warningCardContent)}>
                <React.Fragment>
                  <Typography variant="subheading" className={classNames(classes.warningText)}>
                    {localizedAgreeTextJsx}
                  </Typography>
                </React.Fragment>
              </CardContent>
            </Card>
          </PanelContent>
        </Panel>
        <FormControlLabel
          key={`FreeWarrantyDialog__buynow-eula2`}
          className={classNames(classes.checkbox, classes.warningText)}
          label={
            <div className={classes.sectionItem}>
              <span className={classes.addSpaceAfter}>{`${t(
                'I have read and accept above terms as well as the',
              )} `}</span>
              <a
                href={ref}
                target="_blank"
                title={t(`Terms for %product`, { product: w.warrantyName })}
                rel="noopener noreferrer"
              >
                {t('terms for the warranty')}
              </a>
            </div>
          }
          control={
            <Checkbox
              checked={this.state.termsAccepted}
              onChange={this.handleTermsChange}
              data-e2e={`FreeWarrantyDialog__terms-checkbox`}
            />
          }
        />
        {didReachedMaxProductEndMileageCap && (
          <ConfirmDialog
            open={!closedDialogMessageDidReachedMax}
            titleTrl={t('NOTE')}
            onConfirm={() => this.setState({ closedDialogMessageDidReachedMax: true })}
            onCancel={() => null}
            contentTrlText={
              localizedMessageDidReachedMax +
              ' - ' +
              t('Since the warranty mileage is not enough for the customer, it is advised to choose another product') +
              '.'
            }
            doHideCancelButton={true}
          />
        )}
      </React.Fragment>
    )
  }

  private handleReferenceChange = (e: ChangeEvent<HTMLInputElement>) => this.setState({ reference: e.target.value })

  private renderTable(tableData: ITableRow[], allRowsAreImportant: boolean = false) {
    const { classes } = this.props
    const isDelayed = this.checkDateDelay(this.state.startDate)
    const startDateClassName = classNames(classes.startDateText, isDelayed ? classes.delayedStartDateText : '')
    const startDateText = this.state.startDate && moment(this.state.startDate).format('DD.MM.YYYY')
    return (
      <table className={classes.centeredTable}>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.value}>
              <td
                className={classNames(
                  classes.left,
                  row.isStartDateRow && isDelayed ? classes.delayedStartDateText : '',
                )}
              >{`${row.title}:`}</td>
              {!row.important && !allRowsAreImportant && <td className={classes.right}>{row.value}</td>}
              {(row.important || allRowsAreImportant) && !row.veryImportant && (
                <td className={classes.right}>
                  {row.isStartDateRow ? (
                    <span className={startDateClassName}>{startDateText || ''}</span>
                  ) : (
                    <strong>{row.value}</strong>
                  )}
                </td>
              )}
              {row.veryImportant && (
                <td className={classes.right}>
                  <strong className={classes.veryImportant}>{row.value}</strong>
                </td>
              )}
              <td />
              <td>{row.editAction && row.editAction()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  private renderEditButton = (onClickAction: () => void) => {
    const classes = this.props.classes
    return (
      <Button className={classes.panelActionsButton} onClick={onClickAction} size="small" variant="outlined">
        <EditIcon className={classes.panelActionsButtonIcon} />
      </Button>
    )
  }

  private renderErrorList = (errorList: string[]) => {
    return (
      <React.Fragment>
        {errorList.map((str, ix) => (
          <div key={`errlist-${ix}`}>{str}</div>
        ))}
        {!this.customerValid() && (
          // eslint-disable-next-line
          <button className={this.props.classes.warrantyButton} onClick={this.handleShowCustomerComponent}>
            {t('Edit Customer Info')}
          </button>
        )}
      </React.Fragment>
    )
  }

  private stringToJsx(dangerousHtml: string): JSX.Element {
    return <div className="Container" dangerouslySetInnerHTML={{ __html: dangerousHtml }} />
  }

  private renderSuccessMsg = (
    description: string,
    startDate: Date,
    endDate: Date,
    v: Vehicle | VehicleAlongItsContracts,
    startMileage: number | undefined,
  ) => {
    return (
      <div>{this.renderVehicleInfo(v, startMileage, endDate, description)}</div>
      // <div dangerouslySetInnerHTML={createMarkup()} />
    )
  }
  // ===================================================================
  // Validate data
  // ===================================================================

  /**
   * Returns an array of error messages. If all is OK then the array is empty
   */
  private validateCreateInfo = (): string[] => {
    const { warranty, durationPrice, vehicleAlongItsContracts } = this.props
    const durationMonths = durationPrice.durationMonths
    const errVehicle = this.validateVehicleInfo(vehicleAlongItsContracts)
    const errCustomer = this.validateCustomerInfo()
    const ret: string[] = errVehicle.concat(errCustomer)
    if (!warranty || !warranty.warrantyId) {
      ret.push(t('Warranty type missing'))
    }
    if (!durationMonths) {
      ret.push(t('Number of months is missing'))
    }
    if (!vehicleAlongItsContracts || !vehicleAlongItsContracts.regDate) {
      ret.push(t('Vehicle registration date missing'))
    }
    return ret
  }

  private validateVehicleInfo = (v?: Vehicle): string[] => {
    if (!v) {
      return ['Missing vehicle info']
    }
    const ret: string[] = []
    if (!v.brand || (!v.brand.name && !v.brand.id)) {
      ret.push(t('Missing vehicle brand'))
    }
    if (!v.model || (!v.model.name && !v.model.id)) {
      ret.push(t('Missing vehicle model'))
    }
    if (!v.fuelType || (!v.fuelType.name && !v.fuelType.id)) {
      ret.push(t('Missing vehicle fuel type'))
    }
    return ret
  }

  private validateCustomerInfo = () => {
    const msgCustomerInfoMissing = t('Missing customer info')
    return this.customerValid() ? [] : [msgCustomerInfoMissing]
  }

  private customerValid(): boolean {
    const p: IOwnProps = this.props
    // Usinge a some extra parantheses to make it easier to read
    return p.customerValid &&
      (p.customerId ||
        (p.customer &&
          p.customer.name &&
          p.customer.phone &&
          p.customer.email &&
          p.customer.address &&
          p.customer.zip &&
          p.customer.city))
      ? true
      : false
  }

  // ===================================================================
  // Handlers
  // ===================================================================

  private handleShowCustomerComponent = () => {
    this.setState({ showCustomerComponent: 'Yes' })
  }

  private handleCustomerCompnentClose = () => {
    this.setState({ showCustomerComponent: 'No' })
  }

  private handleDateChange = (value: Moment | null, closePicker?: boolean) => {
    const { durationPrice } = this.props
    this.setState({
      startDate: value && value.toDate(),
      endDate: value && addMonths(value.toDate(), durationPrice.durationMonths),
      isDatePickerShown: closePicker || false,
    })
  }

  private handleClose = () => {
    this.resetState()
    this.props.onClose()
  }

  private handleSendRequest = async () => {
    try {
      const { vehicleAlongItsContracts } = this.props

      this.setState({ dialogState: 'Sending' })

      const vehicleForRequest: VehicleAlongItsContracts = { ...vehicleAlongItsContracts }
      vehicleForRequest.contracts = { contracts: [], durations: [], mileageDurationsMap: null }

      // -- START of ICreateDelaerPaidContractRequest ----------------------------------------
      // NOTE: This is request for creating a (free) Warranty the OLD / LEGACY way (a Dealer Paid Warranty).
      const startMileage: number = this.props.startMileage || 0
      const { allowedDistanceMileage, reference } = this.state
      const req: ICreateDelaerPaidContractRequest = {
        prettyIdentifier: null,
        warrantyId: this.props.warranty ? this.props.warranty.warrantyId : 0,
        warrantyLengthMonths: this.props.durationPrice.durationMonths,
        vehicleAlongItsContracts: vehicleForRequest,
        startMileage: startMileage,
        customerId: this.props.customerId,
        customer: this.props.customer,
        startDate: this.state.startDate || null,
        finlandPriceId: this.props.durationPrice.finlandPriceId,
        endMileage: !allowedDistanceMileage ? undefined : startMileage + allowedDistanceMileage,
        reference,
      }
      // -- END of ICreateDelaerPaidContractRequest ----------------------------------------

      const res: IJsonStatus<ICreateDelaerPaidContractResponse, ApiError> = await createFreeWarranty(req)
      const errMsg = errorMessage(res)

      if (this.state.dialogState === 'Sending' && errMsg && errMsg.key) {
        if (
          errMsg.key ===
          'You can not create a contract type or option with warranty because there already exists a warranty on the vehicle'
        ) {
          const message =
            'JustGO prevented you to create a contract type or option with warranty because there already exists an active warranty with this VIN or license number'
          notify.warning({ message: t(message) })

          this.setState({ dialogState: 'ErrorAfterSend' })
          return
        } else if (errMsg.key === 'System prevents activation of the contract for this vehicle') {
          this.setState({ dialogState: 'SystemPreventsActivation' })
          return
        }
      }

      const d: ICreateDelaerPaidContractResponse = res.data! // We know data has a value since errMsg is null
      if (!errMsg && !d.success) {
        this.setState({ errMsg: { value: d.error }, dialogState: 'ErrorAfterSend' })

        const message = `Something went wrong signing dealer paid warranty, warranty might not be registered, product ID = ${this.props.warranty.externalId}, name = ${this.props.warranty.description}`
        console.warn(message)
        notify.warning({ message: message })

        return
      }

      if (!this.props.customerId) {
        await this.lockUser(d.customerId)
      }

      this.props.onWarrantyCreated()
      const startDate = d.startDate
      const endDate = d.endDate
      const description = this.props.warranty.description
      const vaic = d.req.vehicleAlongItsContracts
      const jsxSuccess = this.renderSuccessMsg(description, startDate, endDate, vaic, d.req.startMileage)
      this.setState({ jsxSuccess, dialogState: 'Created' })
    } catch (err) {
      this.setState({
        dialogState: 'ErrorAfterSend',
      })

      const message = `Something went wrong signing dealer paid warranty, warranty might not be registered, product ID = ${this.props.warranty.externalId}, name = ${this.props.warranty.description}`
      console.warn(message)
      notify.error({ message: message })
    }
  }

  private lockUser = async (newCustomerId: number) => {
    this.props.onCustomerChange && this.props.onCustomerChange({ ...this.props.customer, id: newCustomerId }, true)
    this.props.onCustomerLockedChange && this.props.onCustomerLockedChange(true)
  }
  // Check if a new startDate is after warranty contractStartDate in time
  private checkDateDelay = (date: Date | null) =>
    !!date && moment(date).isAfter(this.props.warranty.contractStartDate, 'day')
}

export default withStyles(styles)(FreeWarrantyDialog)
