import React, { ChangeEvent } from 'react'
import { Card, CardContent, Button, CircularProgress, TextField } from '@material-ui/core'
import { Info as InfoIcon, EvStation } from '@material-ui/icons'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import {
  IAvailableFreeWarranty,
  IAdminCustomer,
  Vehicle,
  VehicleAlongItsContracts,
  IAvailableFreeWarrantyDurationPrice,
} from '@omnicar/sam-types'
import { isTranslationItemSet, t, translateItem } from 'translations/translationFunctions'
import { Panel, PanelHeader, PanelTitle, PanelContent } from 'components/Mui/Panel'
import { createPanelStyles } from 'theme'
import cn from 'classnames'
import FreeWarrantyDialog from 'components/admin/FreeWarranty/FreeWarrantyDialog'
import { freeWarrantyBorderColor } from './FreeWarrantyColors'
import { formatDate, formatCurrency } from '@omnicar/sam-format'
import Typography from 'components/Typography'
import debounce from 'lodash.debounce'
import { TranslationItem } from 'translations/translationTypes'
import history from 'utils/history'

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    card: {
      minHeight: '9em',
      minWidth: '30em',
      // minWidth: '90em',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    warrantyNameData: {
      minWidth: 'fit-content',
      fontWeight: theme.typography.fontWeightMedium,
      textDecoration: 'none',
      overflow: 'auto',
      textOverflow: 'ellipsis',
      lineHeight: `${theme.spacing(3)}px`,
      maxHeight: theme.spacing(3),
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      marginRight: `${2}vw`,
    },
    startDateData: {
      marginRight: `${1}vw`,
      fontSize: '13px',
    },
    warrantyPrice: {
      minWidth: '5em',
    },
    invisible: {
      visibility: 'hidden',
    },
    cardDealerPaid: {
      borderLeftColor: freeWarrantyBorderColor,
    },
    warrantyButton: {
      cursor: 'pointer',
      color: theme.palette.common.white,
    },
    tableRoot: {
      width: '100%',
    },
    tableHeader: {
      minWidth: '5.5em',
    },
    tableFooterCell: {
      paddingTop: '1em',
      textAlign: 'center',
      fontStyle: 'italic',
    },
    tableCell: {
      fontSize: '24px',
      textAlign: 'center',
      padding: '2px',
    },
    leftAlign: {
      textAlign: 'left',
    },
    priceSwitchContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      marginTop: theme.spacing(2),
      width: '45%',
    },
    priceSwitchButton: {
      marginTop: theme.spacing(1),
      width: '100%',
      border: `1px solid ${theme.palette.text.primary}`,
    },
    priceSwitchButtonDisabled: {
      border: `1px solid ${theme.palette.text.disabled}`,
    },
    priceSwitchButtonActive: {
      border: `1px solid transparent`,
      backgroundColor: theme.palette.grey[300],
    },
    vehicleDescription: {
      color: '#333',
    },
    evStationIcon: {
      color: '#77aabb',
      verticalAlign: 'bottom',
    },
    evStationDescription: {
      verticalAlign: 'super',
      fontWeight: 'bold',
    },
  })

interface IOwnProps {
  vehicleAlongItsContracts: VehicleAlongItsContracts
  startMileage: number | undefined
  customer: IAdminCustomer
  customerId?: number
  style?: any
  onCustomerChange?: (value: IAdminCustomer, valid: boolean) => void
  onCustomerLockedChange?: (locked: boolean) => void
  customerLocked: boolean
  customerValid: boolean
  vehicleUpdates: number
  customerUpdates: number
  contractUpdates: number
  onStartMileageChange?: (startMileage: number | undefined) => void
  onWarrantyCreated: () => void
  showCurrentMileageField: boolean
  doClose?: () => void
  onFetchedWarranties?: (
    fetchAvailableWarrantiesError: string,
    vehicleHasActiveWarranty: boolean,
    hasAvailableWarranties: boolean,
  ) => void

  fetching: boolean
  onFreeWarrantyButtonClick: (w: IAvailableFreeWarranty, dp: IAvailableFreeWarrantyDurationPrice) => Promise<void>
  chosenWarranty: IAvailableFreeWarranty | null
  durationPrice: IAvailableFreeWarrantyDurationPrice | null
  availableWarranties: IAvailableFreeWarranty[]
  areAnyCustomerPricesSet: boolean
  getAvailableWarranties: () => Promise<void>
  statusText: TranslationItem
}

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  dialogOpen: boolean
  warrantyCreated: boolean
  vehicleHasActiveWarranty: boolean
  minMilageDiffPerYear: number
  fetchAvailableWarrantiesError: string
  isCustomerPriceShown: boolean
  areAllOverTheMileageLimit: boolean
}

class FreeWarrantyComponent extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    this.state = {
      dialogOpen: false,
      warrantyCreated: false,
      fetchAvailableWarrantiesError: '',
      vehicleHasActiveWarranty: false,
      minMilageDiffPerYear: 0,
      isCustomerPriceShown: true,
      areAllOverTheMileageLimit: false,
    }
  }

  public componentDidUpdate(prevProps: TProps) {
    if (prevProps.startMileage !== this.props.startMileage) {
      this.debouncedAllOverTheMileageLimitCheck()
    }
  }

  private renderVehicleDescription(vehicle: Vehicle): string {
    let v = `${vehicle.brand.name} `
    v += `${vehicle.model.name} `
    v += `${vehicle.fuelType.name} `
    v += `- ${!vehicle.modelYear ? '' : vehicle.modelYear + ' - '}` // Model Year if there is any.
    v += `${!vehicle.regNumber ? vehicle.vin : vehicle.regNumber} `

    return v
  }

  public render() {
    const {
      classes,
      fetching,
      statusText,
      startMileage,
      areAnyCustomerPricesSet,
      vehicleAlongItsContracts,
    } = this.props

    const vehicle: Vehicle = vehicleAlongItsContracts

    return (
      <React.Fragment>
        <Panel disabled={false}>
          <PanelHeader>
            <PanelTitle>
              <h3>{t('Purchase Dealer Paid Warranty')}</h3>
              <p className={classes.vehicleDescription}>{this.renderVehicleDescription(vehicle)}</p>
            </PanelTitle>
          </PanelHeader>
          <PanelContent>
            <Card className={cn(classes.cardActive, classes.cardDealerPaid)} data-e2e="ContractFlowFreeWarrantInner">
              <CardContent className={classes.card}>
                {fetching && (
                  <div className={classes.center}>
                    <CircularProgress size={'5em'} color="secondary" />
                  </div>
                )}
                {!fetching && this.props.showCurrentMileageField && (
                  <React.Fragment>
                    <TextField
                      fullWidth={true}
                      disabled={false}
                      autoFocus={true}
                      value={startMileage === undefined ? '' : startMileage}
                      margin="dense"
                      label={t('Current mileage for vehicle')}
                      onChange={this.handleCurrentMileageTextChange}
                    />
                  </React.Fragment>
                )}
                {!fetching && isTranslationItemSet(statusText) && <div>{translateItem(statusText)}</div>}
                {!fetching && !isTranslationItemSet(statusText) && this.renderWarrantyTable()}
              </CardContent>
            </Card>
            {areAnyCustomerPricesSet && this.renderWarrantyPriceSwitchButtons()}
          </PanelContent>
        </Panel>
        {this.state.dialogOpen && this.props.chosenWarranty && (
          <FreeWarrantyDialog
            open={this.state.dialogOpen}
            onWarrantyCreated={this.handleWarrantyCreated}
            vehicleAlongItsContracts={this.props.vehicleAlongItsContracts}
            startMileage={this.props.startMileage}
            customer={this.props.customer}
            customerId={this.props.customerId}
            warranty={this.props.chosenWarranty}
            areAnyCustomerPricesSet={this.props.areAnyCustomerPricesSet}
            onClose={this.handleSendDialogClose}
            onCustomerChange={this.props.onCustomerChange}
            onCustomerLockedChange={this.props.onCustomerLockedChange}
            customerLocked={this.props.customerLocked}
            customerValid={this.props.customerValid}
            contractUpdates={this.props.contractUpdates}
            vehicleUpdates={this.props.vehicleUpdates}
            customerUpdates={this.props.customerUpdates}
            oneTimeFeeYear={this.props.chosenWarranty.oneTimeFeeYear}
            durationPrice={this.props.durationPrice}
          />
        )}
      </React.Fragment>
    )
  }

  // =========================================================
  // Action Handlers
  // =========================================================

  private handleCurrentMileageTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const valueDigits: string = value
      .replace(/[^0-9]/g, '')
      .replace(/[e]/g, 'x')
      .substr(0, 6)

    if (valueDigits.length === 0) {
      this.handleCurrentMileageChange(undefined)
    } else {
      this.handleCurrentMileageChange(Number.parseFloat(valueDigits))
    }
  }

  private handleCurrentMileageChange = (startMileage: number | undefined) => {
    this.props.onStartMileageChange && this.props.onStartMileageChange(startMileage)
  }

  private handleWarrantyCreated = () => {
    this.setState({ warrantyCreated: true })
    this.props.onWarrantyCreated()
  }

  private handleSendDialogClose = async () => {
    this.setState({ dialogOpen: false })
    if (this.state.warrantyCreated && !this.props.doClose) {
      this.props.getAvailableWarranties()
    }
    if (this.props.doClose && this.state.warrantyCreated) {
      this.props.doClose()
    }
    this.state.warrantyCreated && history.go(0)
  }

  private handlePriceSwitch = (isCustomerPriceShown: boolean) => this.setState({ isCustomerPriceShown })

  // =========================================================
  // Sub Renders
  // =========================================================

  private renderWarrantyTable = (): JSX.Element => {
    const { availableWarranties, startMileage, classes } = this.props
    const hasStartMileage = startMileage !== undefined

    const dealerPaidWarranties = availableWarranties.filter((w) => !w.isForbidDealerPaid)
    const durationsInTable = this.getDurationsInTable(dealerPaidWarranties)
    let commonStartDate: undefined | Date | null

    for (const w of dealerPaidWarranties) {
      if (commonStartDate === undefined) {
        commonStartDate = w.contractStartDate
      } else if (commonStartDate !== w.contractStartDate) {
        commonStartDate = null
        break
      }
    }

    const regularWarranties: IAvailableFreeWarranty[] = []
    const evWarranties: IAvailableFreeWarranty[] = []
    dealerPaidWarranties.forEach((w) =>
      w.fuelTypes?.length === 1 && w.fuelTypes[0] === 'Electric' ? evWarranties.push(w) : regularWarranties.push(w),
    )
    const evIconNeeded = regularWarranties.length > 0 && evWarranties.length > 0

    return (
      <React.Fragment>
        <h3>
          {hasStartMileage
            ? `${t('Select the type and duration in months of your wanted warranty')}:`
            : t('Set current mileage before choosing warranty')}
        </h3>
        <table className={classes.tableRoot}>
          {this.renderWarrantyTableHeader(dealerPaidWarranties, commonStartDate, durationsInTable)}
          <tbody>
            {!evIconNeeded &&
              dealerPaidWarranties.map((w) => this.renderWarrantyRow(w, commonStartDate, durationsInTable))}

            {evIconNeeded && (
              <>
                {regularWarranties.map((w) => this.renderWarrantyRow(w, commonStartDate, durationsInTable))}
                <tr>
                  <td>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan={1 + durationsInTable.length}>
                    <EvStation className={classes.evStationIcon} fontSize="large" />
                    <span className={classes.evStationDescription}>{t('warranty for EV cars')}:</span>
                  </td>
                </tr>
                {evWarranties.map((w) => this.renderWarrantyRow(w, commonStartDate, durationsInTable))}
              </>
            )}
          </tbody>
          {this.renderWarrantyTableFooter(commonStartDate, durationsInTable)}
        </table>
      </React.Fragment>
    )
  }

  private renderWarrantyTableFooter = (commonStartDate: undefined | Date | null, durations: number[]) => {
    const { classes } = this.props
    return (
      <tfoot>
        {commonStartDate && (
          <tr>
            <td colSpan={durations.length + 1} className={classes.tableFooterCell}>
              {t('Start date for warranties is %startDate', { startDate: formatDate(commonStartDate) })}
            </td>
          </tr>
        )}
      </tfoot>
    )
  }

  private renderWarrantyTableHeader = (
    arW: IAvailableFreeWarranty[],
    commonStartDate: undefined | Date | null,
    durations: number[],
  ) => {
    const { classes } = this.props

    return (
      <thead>
        <tr>
          <th className={classes.leftAlign}>{t('Product')}</th>
          {!commonStartDate && <th>{t('Start Date')}</th>}
          <th colSpan={durations.length}>{t('Duration (months)')}</th>
        </tr>
        <tr>
          <th colSpan={commonStartDate ? 1 : 2} />
          {durations.map((dur) => (
            <th key={dur} className={classes.tableHeader} title={`${dur} ${t('months')}`}>
              {`${dur} ${t('mos.')}`}
            </th>
          ))}
        </tr>
      </thead>
    )
  }
  private calculateMaxMonths = (maxEndMileage: number, maxYears: number) => {
    const { minMilageDiffPerYear } = this.state
    return this.props.startMileage !== undefined
      ? Math.floor(12 * Math.min((maxEndMileage - this.props.startMileage) / minMilageDiffPerYear, maxYears))
      : 0
  }
  // Check if all warranties have over the limit mileage - for disabling price switch buttons
  private handleAllOverTheMileageLimitCheck = (warranties: IAvailableFreeWarranty[]) => {
    if (!this.props.areAnyCustomerPricesSet) {
      return
    }
    const overTheMileageLimitWarranties = warranties.reduce(
      (acc: IAvailableFreeWarranty[], w: IAvailableFreeWarranty) => {
        const maxMonths = this.calculateMaxMonths(w.maxEndMileage, w.maxYears)
        return this.props.startMileage !== undefined && maxMonths <= 0 ? acc.concat(w) : acc
      },
      [],
    )
    return overTheMileageLimitWarranties.length > 0 && overTheMileageLimitWarranties.length === warranties.length
      ? this.setState({ areAllOverTheMileageLimit: true })
      : this.setState({ areAllOverTheMileageLimit: false })
  }

  private debouncedAllOverTheMileageLimitCheck = debounce(
    () => this.handleAllOverTheMileageLimitCheck(this.props.availableWarranties.filter((w) => !w.isForbidDealerPaid)),
    300,
  )

  private renderWarrantyRow = (
    w: IAvailableFreeWarranty,
    commonStartDate: undefined | Date | null,
    durations: number[],
  ) => {
    const { classes } = this.props
    const maxMonths = this.calculateMaxMonths(w.maxEndMileage, w.maxYears)
    const hasTooltip = false
    return (
      <tr key={w.warrantyId}>
        <Typography variant="body2" tag="td">
          {hasTooltip && <InfoIcon className={classes.infoIcon} />}
          <a
            className={classes.warrantyNameData}
            style={{
              color: w.warrantyColor,
              backgroundColor: !w.warrantyBGColor ? '' : w.warrantyBGColor,
              paddingLeft: '4px',
              paddingRight: '4px',
              marginLeft: '-4px',
              marginRight: '-4px',
            }}
            href={w.warrantyTermsRef}
            title={t('Terms for %product', { product: w.description })}
            target="_blank"
            rel="noopener noreferrer"
          >
            {w.description}
          </a>
        </Typography>
        {!commonStartDate && <td className={classes.startDateData}>{formatDate(w.contractStartDate)}</td>}
        {this.props.startMileage !== undefined &&
          maxMonths > 0 &&
          durations.map((dur) => this.renderWarrantyCell(w, dur, maxMonths))}
        <td colSpan={durations.length}>
          {this.props.startMileage !== undefined && maxMonths <= 0 && (
            <div className={this.props.classes.warrantyButtons}>{t('Over mileage limit')}</div>
          )}
        </td>
      </tr>
    )
  }

  private getDurationsInTable = (arW: IAvailableFreeWarranty[]) => {
    const setActuallyUsedDurations = new Set<number>()
    for (const w of arW) {
      for (const dp of w.durationsPrices) {
        setActuallyUsedDurations.add(dp.durationMonths)
      }
    }
    return Array.from(setActuallyUsedDurations).sort()
  }

  private handleFreeWarrantyButtonClick = () => this.setState({ dialogOpen: true })

  private renderWarrantyCell = (w: IAvailableFreeWarranty, duration: number, maxMonths: number) => {
    const { onFreeWarrantyButtonClick, areAnyCustomerPricesSet, classes } = this.props
    if (duration > maxMonths) {
      return <td />
    }
    const dp = w.durationsPrices.find((dp) => dp.durationMonths === duration)
    if (!dp) {
      return <td />
    }

    const priceToShow =
      areAnyCustomerPricesSet && this.state.isCustomerPriceShown
        ? dp.customerPrice
          ? dp.customerPrice.priceInclVat
          : null
        : dp.totalPrice.price
    const onButtonClick = () => {
      this.handleFreeWarrantyButtonClick()
      onFreeWarrantyButtonClick(w, dp)
    }

    const buttonText = !priceToShow
      ? '-'
      : formatCurrency(priceToShow, {
          showDecimals: false,
          symbolDisplayType: 'NONE',
        }) + ':-'

    const buttonTooltip = !priceToShow
      ? ''
      : `${duration} ${t('months')}, ${t('price')}: ${formatCurrency(priceToShow, {
          showDecimals: false,
          symbolDisplayType: 'APPEND',
        })}`

    return (
      <td key={`wtd-${w.warrantyId}-${duration}`} className={classes.tableCell}>
        <Button
          style={{ backgroundColor: w.warrantyColor, color: !w.warrantyBGColor ? '' : w.warrantyBGColor }}
          variant="contained"
          size="small"
          key={`wbtn-${w.warrantyId}-${duration}`}
          className={classes.warrantyButton}
          onClick={onButtonClick}
          title={buttonTooltip}
        >
          {buttonText}
        </Button>
      </td>
    )
  }

  private renderWarrantyPriceSwitchButtons = () => {
    const { classes, startMileage } = this.props
    const { isCustomerPriceShown, areAllOverTheMileageLimit } = this.state

    return (
      <div className={classes.priceSwitchContainer}>
        <Button
          onClick={() => this.handlePriceSwitch(true)}
          classes={{
            root: cn(classes.priceSwitchButton, isCustomerPriceShown ? classes.priceSwitchButtonActive : ''),
            disabled: classes.priceSwitchButtonDisabled,
          }}
          disabled={!startMileage || areAllOverTheMileageLimit}
        >
          {t('Customer price (incl VAT)')}
        </Button>
        <Button
          onClick={() => this.handlePriceSwitch(false)}
          classes={{
            root: cn(classes.priceSwitchButton, !isCustomerPriceShown ? classes.priceSwitchButtonActive : ''),
            disabled: classes.priceSwitchButtonDisabled,
          }}
          disabled={!startMileage || areAllOverTheMileageLimit}
        >
          {t('Dealer fee (excl VAT)')}
        </Button>
      </div>
    )
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(FreeWarrantyComponent)
