export const getMapLength = (map: Map<string, object>): number => {
  return getMapSize(map)
}

/**
 * Will return the actual size/length Map even if the contents have been
 * stored directly without using the set()-method.
 *
 * NOTE:
 * If the contents of a map was stored directly via setting its properties
 * (using [], etc), and not stored using the built-in method .set(), then
 * size wont work.
 */
export const getMapSize = (map: Map<string, object>): number => {
  if (!map) {
    return 0
  }

  const size = map.size
  const len = Object.keys(map).length

  return size || len
}
