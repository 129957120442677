import React from 'react'
import { IRootState } from 'reducers/initialState'
import { connect } from 'react-redux'
import { IOutageResponse } from '@omnicar/sam-types'
import { toast } from 'react-toastify'
import { TypeOptions } from 'react-toastify/dist/types'

interface IProps {
  onOutageChange?: (hasActiveOutage: boolean) => void
}

interface IActionProps {
  outage: IOutageResponse | null
}

type TProps = IProps & IActionProps

const toastId = 'outage-id'
let oldOutage: IOutageResponse | undefined

class Outage extends React.Component<TProps> {
  public render() {
    const { onOutageChange } = this.props
    const outage = this.props.outage || undefined
    const toastType: TypeOptions = (outage && this.getToastType(outage.severityLevel || 1)) || toast.TYPE.DEFAULT

    onOutageChange && onOutageChange(!!outage)

    oldOutage &&
      outage &&
      oldOutage.severityLevel !== outage.severityLevel &&
      toast.update(toastId, {
        render: outage.headerText,
        type: toastType,
      })

    oldOutage = outage

    // no outage remove
    if (outage === undefined) {
      toast.dismiss(toastId)
    }

    return (
      (
        <>
          {!!outage &&
            toast(outage.headerText, {
              autoClose: false,
              closeButton: false,
              closeOnClick: false,
              type: toastType,
              toastId: toastId,
              draggable: false,
            })}
        </>
      ) && false
    )
  }

  private getToastType = (severityLevel: number): TypeOptions => {
    let toastType: TypeOptions = toast.TYPE.DEFAULT

    switch (severityLevel) {
      case 1:
        toastType = toast.TYPE.INFO
        break
      case 2:
        toastType = toast.TYPE.WARNING
        break
      case 3:
        toastType = toast.TYPE.ERROR
        break
    }
    return toastType
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    outage: state.outage,
  }
}

//export default withStyles(styles)(connect(mapStateToProps)(Outage))
export default connect(mapStateToProps)(Outage)
