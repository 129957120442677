import {
  Card,
  CardContent,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { ContractState, SettlementPaymentType } from '@omnicar/sam-types'
import classNames from 'classnames'
import ConfirmDialog from 'components/ConfirmDialog'
import { IReduxHiddenVATProps, withHiddenVAT } from 'components/ContractDisplayConfig/withHiddenVAT'
import { Panel, PanelContent } from 'components/Mui/Panel'
import SpinnerButton from 'components/Mui/SpinnerButton'
import { Form, FormikProps } from 'formik'
import React from 'react'
import { compose, withState } from 'recompose'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { getCurrency, removeVAT } from 'utils/localStorage'

interface IOwnProps {
  customerName: string
  balance: number
  id: string
  isSettled: boolean
  settledUnits?: number | null
  calculationMethod?: number
  isRefund: boolean
  contractState?: ContractState
}

interface IStateProps {
  dialogShown: boolean
  setDialogShown: (d: boolean) => boolean
}

interface ISettleFormValues {
  paymentAmount: number
  type: SettlementPaymentType
  comment: string
  shareComment: boolean
}

type TProps = IOwnProps &
  WithStyles<typeof styles> &
  IStateProps &
  IReduxHiddenVATProps &
  FormikProps<ISettleFormValues>

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    cardTitleText: {
      textTransform: 'uppercase',
    },
    customerNameText: {
      color: palette.primary.main,
      fontSize: spacing(2),
      paddingLeft: spacing(0.5),
    },
    form: {
      padding: spacing(2),
    },
    formRow: {
      display: 'flex',
      marginTop: spacing(5),
    },
    inputError: {
      color: palette.error.dark,
      height: spacing(2),
    },
    formControl: {
      padding: spacing(1),
      minWidth: 120,
    },
    inputLabel: {
      textTransform: 'uppercase',
      fontSize: spacing(1.5),
      color: palette.primary.main,
      fontWeight: 500,
    },
    disabled: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    exclVat: {
      paddingLeft: spacing(0.5),
      color: palette.grey[600],
    },

    titleDialog: {
      textAlign: 'center',
    },
    buttonSpinner: {
      marginRight: spacing(1),
    },
    confirmButton: { marginLeft: spacing(1) },
    refundNotificationText: {
      color: palette.error.main,
      fontSize: spacing(1.75),
      marginBottom: spacing(3.75),
    },
    paymentTypeValue: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    paymentTypeRefund: {
      color: palette.error.main,
    },
    paymentTypeCharge: {},
    paymentTypeLabel: {},
    paymentTypeNote: {
      fontSize: spacing(1.25),
    },
  })

const options: readonly {
  label: 'Stripe payment' | 'Cash payment' | 'Cash payout' | 'Forgive' | 'Stripe credit'
  value: SettlementPaymentType
  isPayout: boolean
}[] = [
  {
    label: 'Stripe payment',
    value: SettlementPaymentType.stripePayment,
    isPayout: false,
  },
  {
    label: 'Cash payment',
    value: SettlementPaymentType.cashPayment,
    isPayout: false,
  },
  {
    label: 'Forgive',
    value: SettlementPaymentType.providerPayment,
    isPayout: false,
  },
  {
    label: 'Cash payout',
    value: SettlementPaymentType.cashPayout,
    isPayout: true,
  },
] as const

const SettlementRegulationForm: React.SFC<TProps> = ({
  classes,
  customerName,
  isSubmitting,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  isValid,
  isSettled,
  settledUnits,
  dialogShown,
  setDialogShown,
  contractState,
  hiddenVAT,
}) => {
  // remove stripe options if contract has CancelledSubscription state
  const filteredOptions = options.filter(
    (o) => !(contractState === ContractState.CancelledSubscription && SettlementPaymentType.stripePayment === o.value),
  )
  const isDisabled = isSettled || !settledUnits
  const handleDialogCancel = () => setDialogShown(false)
  const handleDialogConfirm = () => {
    handleSubmit()
    setDialogShown(false)
  }
  const handleShowDialog = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault()
    setDialogShown(true)
  }
  const isDebtType = [
    SettlementPaymentType.cashPayment,
    SettlementPaymentType.providerPayment,
    SettlementPaymentType.stripePayment,
  ].includes(values.type)
  const showWarning = values.paymentAmount >= Number(process.env.REACT_APP_MAX_PAYMENT || 9000)

  return (
    <AppContext.Consumer>
      {() => (
        <>
          <Panel>
            <PanelContent>
              <Card>
                <CardContent classes={{ root: isDisabled ? classes.disabled : '' }}>
                  {/* {isRefund && (
                    <Typography variant="h6" className={classes.refundNotificationText}>
                      {t(
                        'There is no possibility to refund. Refund must otherwise be done during settlement. However, you may create a credit for the customer.',
                      )}
                    </Typography>
                  )} */}
                  <Typography variant="h6">
                    <span className={classes.cardTitleText}>{isDebtType ? t('Charge from:') : t('Payment to:')}</span>
                    <span className={classes.customerNameText}>{customerName}</span>
                  </Typography>
                  <Form disabled={isSubmitting} className={classes.form} onSubmit={handleShowDialog}>
                    <div className={classes.formRow}>
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <label className={classes.inputLabel}>
                          {t(hiddenVAT ? 'Amount (%currency)' : 'Amount (%currency incl VAT)', {
                            currency: getCurrency(),
                          })}
                        </label>
                        <OutlinedInput
                          type="number"
                          labelWidth={0}
                          name="paymentAmount"
                          value={values.paymentAmount}
                          endAdornment={<InputAdornment position="end">{getCurrency()}</InputAdornment>}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                          className={SettlementPaymentType.cashPayout === values.type ? classes.paymentTypeRefund : ''}
                        />
                        {!hiddenVAT && (
                          <Typography className={classes.exclVat}>
                            {removeVAT(values.paymentAmount) &&
                              `${removeVAT(values.paymentAmount)} ${getCurrency()} ${t('ex. VAT')}`}
                          </Typography>
                        )}
                        {showWarning && (
                          <Typography variant="body2" className={classes.inputError}>
                            {t('Please note that you have chosen a very high amount')}
                          </Typography>
                        )}

                        <Typography variant="body2" className={classes.inputError}>
                          {errors.paymentAmount && touched.paymentAmount && errors.paymentAmount}
                        </Typography>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth className={classes.formControl}>
                        <label className={classes.inputLabel}>{t('Payment method')}</label>
                        <Select
                          value={values.type}
                          onChange={handleChange}
                          disabled={isSubmitting}
                          input={<OutlinedInput labelWidth={0} name="type" />}
                        >
                          {filteredOptions.map((o) => (
                            <MenuItem key={o.value} value={o.value}>
                              <div
                                className={classNames(
                                  classes.paymentTypeValue,
                                  o.isPayout ? classes.paymentTypeRefund : classes.paymentTypeCharge,
                                )}
                              >
                                <div className={classes.paymentTypeLabel}>
                                  {t(o.label as typeof options[number]['label'])}
                                </div>
                                <div className={classes.paymentTypeNote}>{o.isPayout && t('refund type')}</div>
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <label className={classes.inputLabel}>{t('Comment')}</label>
                        <OutlinedInput
                          labelWidth={0}
                          name="comment"
                          value={values.comment}
                          multiline
                          rows={4}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.shareComment}
                              onChange={handleChange}
                              name="shareComment"
                              color="primary"
                            />
                          }
                          label={t('Send email to the customer')}
                          disabled={isSubmitting || values.comment === ''}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <SpinnerButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        showSpinner={isSubmitting}
                        spinnerClasses={classes.buttonSpinner}
                        className={classes.confirmButton}
                        IconComponent={CheckIcon}
                        disabled={!isValid || isSubmitting}
                      >
                        {t('Confirm payment')}
                      </SpinnerButton>
                    </div>
                  </Form>
                </CardContent>
              </Card>
            </PanelContent>
          </Panel>
          <ConfirmDialog
            titleTrl={t('Are you sure?')}
            contentTrlText={t('Contract will be settled after successful payment')}
            open={dialogShown}
            onConfirm={handleDialogConfirm}
            onCancel={handleDialogCancel}
            titleClassName={classes.titleDialog}
          />
        </>
      )}
    </AppContext.Consumer>
  )
}

export default compose<TProps, IOwnProps>(
  withHiddenVAT,
  withStyles(styles),
  withState('dialogShown', 'setDialogShown', false),
)(SettlementRegulationForm)
