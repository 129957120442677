import { Button, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Add as AddIcon,
  CalendarToday as CalendarTodayIcon,
  Edit as EditIcon,
  Extension as ExtensionIcon,
  LocalGasStation as LocalGasStationIcon,
  FiberSmartRecord as ProductIcon,
  RemoveSharp as ReduceIcon,
  TextFields as TypeIcon,
} from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import { Other } from '@omnicar/sam-types/types/product'
import classNames from 'classnames'
import React, { ChangeEvent } from 'react'
import { compose } from 'recompose'
import Typography from '../../../../components/Typography'
import { createPanelStyles } from '../../../../theme'
import { t } from '../../../../translations/translationFunctions'
import { getRole } from '../../../../utils/localStorage'
import { localFuelTypeName } from '../../../../utils/vehicle'

interface IOwnProps {
  showEditExternalWarrantyWarning?: boolean
  forbidEditingVIN?: boolean
  record: Other
  loading: boolean
  loaded?: boolean
  initProductRecord?: () => void
  initData: () => void
  onChange: (record: Other, error?: { serialNumber?: string; itemNumber?: string }) => void
  editing: boolean
  error: {
    serialNumber?: string
    itemNumber?: string
  }
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  loading: boolean
  loaded: boolean
  title: string
  showItemNumRow: boolean
  showSerialNumRow: boolean
  hasAccessToProducts: boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    button: {
      marginLeft: theme.spacing(2),
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    shiftRight: {
      float: 'right',
    },
    smallbtn: {
      width: '5%',
      minWidth: '30px',
    },
    iconbtn: {
      position: 'absolute',
      bottom: '1vh',
      transition: 'bottom 200ms',
      right: '0.5%',
      zIndex: 100,
    },
    relative: {
      position: 'relative',
    },
    shiftDown: {
      transition: 'bottom 200ms',
      bottom: '-3.5vh',
    },
  })

const baseRecord: Other = {
  serialNumber: '',
  serialNumber2: '',
  itemNumber: '',
  itemNumber2: '',
  regDate: '',
  modelYear: 0,
  brand: {
    name: '',
  },
  model: {
    name: '',
  },
  fuelType: {
    name: '',
  },
  typeName: '',
}

class OtherDetails extends React.Component<TProps, IState> {
  public state: IState = {
    loading: false,
    loaded: this.props.loaded!,
    title: this.props.record.itemNumber || '',
    showItemNumRow: false,
    showSerialNumRow: false,
    hasAccessToProducts: false,
  }

  private role = getRole()

  public componentDidMount() {
    const { initProductRecord, record } = this.props
    initProductRecord && initProductRecord()
    if (initProductRecord && this.props.record) {
      if (record.itemNumber2 && record.itemNumber2.length > 0) {
        this.setState({ showItemNumRow: true })
      }

      if (record.serialNumber2 && record.serialNumber2.length > 0) {
        this.setState({ showSerialNumRow: true })
      }
    }
  }

  public render() {
    const { classes, loading, forbidEditingVIN, editing, error } = this.props
    const { title, showItemNumRow, showSerialNumRow } = this.state

    const allowEditVin = this.role === 'admin' && !forbidEditingVIN
    const record = 'serialNumber' in this.props.record ? this.props.record : baseRecord

    return (
      <>
        <div>
          <Typography className={classes.cardTitle} variant="title">
            <span title={t('Item Number')}>
              <ProductIcon className={classes.cardTitleIcon} />
              <span className={classes.cardTitleText}>{record.itemNumber || title}</span>
              {record.itemNumber2 && (
                <span title={t('Secondary Item Number')}>{` - ${record.itemNumber2.toUpperCase()}`}</span>
              )}
            </span>
          </Typography>
          {loading && <CircularProgress size={20} color="secondary" />}
        </div>
        {!editing ? (
          <React.Fragment>
            {record.itemNumber2 && (
              <div className={classes.presentationField}>
                <span title={t('Secondary Item Number')}>
                  <ProductIcon className={classes.presentationIcon} />
                  {record.itemNumber2.toUpperCase()}
                </span>
              </div>
            )}
            <div className={classes.presentationField}>
              <span title={t('Serial Number')}>
                <EditIcon className={classes.presentationIcon} />
                {record.serialNumber ? record.serialNumber.toUpperCase() : ''}
              </span>

              {record.serialNumber2 && (
                <span title={t('Secondary Serial Number')}>{' -' + record.serialNumber2.toUpperCase()}</span>
              )}
            </div>
            {record.serialNumber2 && (
              <div className={classNames(classes.presentationField, classes.inline)}>
                <span title={t('Secondary Serial Number')}>
                  <EditIcon className={classes.presentationIcon} />
                  {record.serialNumber2.toUpperCase()}
                </span>
              </div>
            )}

            <div className={classes.presentationField}>
              <span title={t('Registration Date')}>
                <CalendarTodayIcon className={classes.presentationIcon} />
                {record.regDate ? formatDate(new Date(record.regDate)) : ''}
              </span>
            </div>
            <div className={classes.presentationField}>
              <span title={t('Product Model')}>
                <ExtensionIcon className={classes.presentationIcon} />
                {record.brand.name} {record.model.name}
              </span>
            </div>
            {record.fuelType && (
              <div className={classes.presentationField}>
                <span title={t('Fuel Type')}>
                  <LocalGasStationIcon className={classes.presentationIcon} />
                  {localFuelTypeName(record)}
                </span>
              </div>
            )}
            <div className={classes.presentationField}>
              <span title={t('Unit Name')}>
                <TypeIcon className={classes.presentationIcon} />
                {!record.typeName ? 'n/a' : record.typeName}
              </span>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classNames(classes.relative, classes.shortItem, classes.paddingLeft)}>
              <TextField
                className={classes.inputField}
                margin="dense"
                fullWidth={true}
                label={t('Item Number')}
                name="itemNumber"
                onChange={this.handleChange}
                error={error.itemNumber ? true : false}
                helperText={error.itemNumber}
                type="text"
                value={record.itemNumber ? record.itemNumber.toUpperCase() : undefined}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ProductIcon className={classNames(classes.inputIcon, error.itemNumber && classes.errorColor)} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                tabIndex={50}
                className={classNames(
                  classes.shiftRight,
                  classes.smallbtn,
                  classes.iconbtn,
                  showItemNumRow && classes.shiftDown,
                )}
                type="button"
                title={showItemNumRow ? t('Hide second item number row') : t('Show second item number row')}
                onClick={() => {
                  this.setState({ showItemNumRow: !showItemNumRow })
                }}
              >
                {showItemNumRow ? <ReduceIcon className={classes.btnIcon} /> : <AddIcon className={classes.btnIcon} />}
              </Button>
            </div>

            {showItemNumRow && (
              <TextField
                className={classes.inputField}
                margin="dense"
                fullWidth={true}
                label={t('Secondary Item Number')}
                name="itemNumber2"
                onChange={this.handleSecondariesChange}
                type="text"
                value={record.itemNumber2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ProductIcon className={classNames(classes.inputIcon)} />
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <div className={classNames(classes.relative, classes.shortItem, classes.paddingLeft)}>
              <TextField
                className={classes.inputField}
                margin="dense"
                fullWidth={true}
                label={t('Serial Number')}
                name="serialNumber"
                onChange={this.handleChange}
                type="text"
                error={error.serialNumber ? true : false}
                helperText={error.serialNumber}
                value={record.serialNumber}
                disabled={!allowEditVin}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditIcon className={classNames(classes.inputIcon, error.serialNumber && classes.errorColor)} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                tabIndex={50}
                className={classNames(
                  classes.shiftRight,
                  classes.smallbtn,
                  classes.iconbtn,
                  showSerialNumRow && classes.shiftDown,
                )}
                type="button"
                title={showSerialNumRow ? t('Hide second serial number row') : t('Show second serial number row')}
                onClick={() => {
                  this.setState({ showSerialNumRow: !showSerialNumRow })
                }}
              >
                {showSerialNumRow ? (
                  <ReduceIcon className={classes.btnIcon} />
                ) : (
                  <AddIcon className={classes.btnIcon} />
                )}
              </Button>
            </div>

            {showSerialNumRow && (
              <TextField
                className={classes.inputField}
                margin="dense"
                fullWidth={true}
                label={t('Secondary Serial Number')}
                name="serialNumber2"
                onChange={this.handleSecondariesChange}
                type="text"
                value={record.serialNumber2}
                disabled={!allowEditVin}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EditIcon className={classNames(classes.inputIcon)} />
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('Registration Date')}
              name="regDate"
              onChange={this.handleChange}
              type="text"
              value={record.regDate ? formatDate(new Date(record.regDate)) : ''}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('Product Brand')}
              name="brand.name"
              onChange={this.handleChange}
              type="text"
              value={record.brand.name}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ExtensionIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              margin="dense"
              fullWidth={true}
              label={t('Product Model')}
              name="model.name"
              onChange={this.handleChange}
              type="text"
              value={record.model.name}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ExtensionIcon className={classes.inputIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {record.fuelType && (
              <TextField
                className={classes.inputField}
                margin="dense"
                fullWidth={true}
                label={t('Fuel Type')}
                name="fuelType.name"
                onChange={this.handleChange}
                type="text"
                value={localFuelTypeName(record)}
                disabled={true} // If at some occasion this field is no longer disabled we must handdle localFuelTypeName
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalGasStationIcon className={classes.inputIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <div className={classes.presentationField}>
              <span title={t('Unit Name')}>
                <TypeIcon className={classes.presentationIcon} />
                {!record.typeName ? 'n/a' : `${record.typeName}`}
              </span>
            </div>
          </React.Fragment>
        )}
      </>
    )
  }

  private handleSecondariesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value.toUpperCase()
    const record = {
      ...this.props.record,
      [name]: value,
    }

    this.props.onChange(record)
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = name === 'itemNumber' || name === 'serialNumber' ? e.target.value.toUpperCase() : e.target.value
    const { error } = this.props

    if (name === 'serialNumber') {
      error.serialNumber = value.length < 1 ? t('Invalid serial number') : undefined
    }

    if (name === 'itemNumber') {
      error.itemNumber = value.length < 1 ? t('Invalid item number') : undefined
    }

    const record = {
      ...this.props.record,
      [name]: value,
    }

    this.props.onChange(record, error)
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(OtherDetails)
