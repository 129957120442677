import {
  IAdminCustomer,
  IContractInfo,
  ICreateDelaerPaidContractRequest,
  IOptionInfo,
  ISetPaymentMethodResponse,
  ISubscription,
  Other,
  PaymentGateway,
  PriceSpecification,
  Vehicle,
} from '@omnicar/sam-types'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { getPaymentInformation, getPublicKey, signDealerPaidWarranty } from 'api/api'
import BillingSummary from 'components/admin/Contract/Flow/BillingSummary'
import SuccessfulPaymentDialog from 'components/admin/Contract/Flow/BillingSummary/SuccessfulPaymentDialog'
import PaymentDialogWrapper from 'components/admin/Contract/Flow/PaymentDialog'
import React from 'react'
import { t } from 'translations/translationFunctions'
import { LocaleType } from 'types/locale'
import { localeToLanguage } from 'utils/locale'
import notify from 'utils/notify/notify'

interface IProps {
  onBack: () => void
  onPreviewContract: () => void
  contractPrettyIdentifier: string
  dialogWrapperClassName?: string
  dialogTitleClassName?: string
  closeButtonClassName?: string
  locale: string
}

interface IState {
  subscriptions: ISubscription[]
  downpayment: PriceSpecification
  additionalOptions: IOptionInfo[]
  termsOfTradeUrl: string
  hasLoaded: boolean
  isSuccessDialogOpen: boolean
  customer: IAdminCustomer | undefined
  vehicle: Vehicle | Other | undefined
  contract: IContractInfo | undefined
  paymentStatus: null | ISetPaymentMethodResponse['status']
  totalAmount: PriceSpecification | null
  minimumTotalAmount: PriceSpecification | null
  contractPaymentGateway: PaymentGateway
}

let stripePromise: null | Promise<Stripe | null> = null

export default class ContractFlowPageSummary extends React.Component<IProps, IState> {
  public state: IState = {
    subscriptions: [],
    downpayment: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: '',
    },
    additionalOptions: [],
    termsOfTradeUrl: '',
    hasLoaded: false,
    isSuccessDialogOpen: false,
    customer: undefined,
    vehicle: undefined,
    contract: undefined,
    paymentStatus: null,
    totalAmount: null,
    minimumTotalAmount: null,
    contractPaymentGateway: 'NONE' as PaymentGateway,
  }

  public async componentDidMount() {
    const { contractPrettyIdentifier, locale, onBack } = this.props
    const language = localeToLanguage(locale) as LocaleType
    const res = await getPaymentInformation(contractPrettyIdentifier)

    if (!res.data) {
      onBack()
      return
    }

    const {
      downpayment,
      subscriptions,
      contract,
      customer,
      product,
      contractProvider,
      totalAmount,
      minimumTotalAmount,
      paymentGateway,
    } = res.data

    switch (paymentGateway) {
      case 'Stripe':
        const ret = await getPublicKey()

        if (!ret.data) {
          return notify.error({
            message: t('Payment: Error retrieving public key from %PaymentProvider', { PaymentProvider: 'Stripe' }),
          })
        }

        const { publicKey, stripeAccount } = ret.data
        stripePromise = loadStripe(publicKey, { stripeAccount, locale: language })

        this.setState({
          downpayment,
          customer,
          vehicle: product,
          contract,
          subscriptions,
          termsOfTradeUrl: contractProvider.termsUrl,
          additionalOptions: contract.additionalOptions,
          hasLoaded: true,
          totalAmount,
          minimumTotalAmount,
          contractPaymentGateway: paymentGateway,
        })
        break
      case 'V4':
        // -- START of ICreateDelaerPaidContractRequest ----------------------------------------
        // NOTE: This is request for creating a contract the NEW WAY (a Dealer Paid Warranty or Service-Agreement).
        const startMileage: number = contract.startMileage || 0
        const req: ICreateDelaerPaidContractRequest = {
          prettyIdentifier: contractPrettyIdentifier, // (!) Important, so we know what contract to send to V4.
          warrantyId: null,
          warrantyLengthMonths: contract.duration,
          vehicleAlongItsContracts: product as Vehicle,
          startMileage: startMileage,
          customerId: customer.id,
          customer: customer,
          startDate: contract.startDate,
          finlandPriceId: undefined,
          endMileage: contract.mileage + startMileage || 0,
        }
        // -- END of ICreateDelaerPaidContractRequest ----------------------------------------

        this.setState({
          hasLoaded: true,
          isSuccessDialogOpen: true,
          paymentStatus: 'processing',
        })

        const res = await signDealerPaidWarranty(req)

        if (res.statusCode === 200) {
          this.setState({
            hasLoaded: true,
            isSuccessDialogOpen: true,
            paymentStatus: 'succeeded',
          })
        } else {
          // TODO: Handle this show error message a break out /or throw by catching it in above layer showing -20230127
          notify.error({
            message: 'Something failed during purchasing dealer paid contract',
          })

          // throw new Error('Something failed')
          this.setState({
            hasLoaded: false,
            isSuccessDialogOpen: false,
            paymentStatus: null,
          })
        }
        break
      default:
    }
  }

  public componentWillUnmount = () => (stripePromise = null)

  public render() {
    const { onBack, contractPrettyIdentifier, onPreviewContract } = this.props
    const {
      downpayment,
      subscriptions,
      additionalOptions,
      termsOfTradeUrl,
      customer,
      contract,
      vehicle,
      hasLoaded,
      isSuccessDialogOpen,
      paymentStatus,
      totalAmount,
      minimumTotalAmount,
      contractPaymentGateway,
    } = this.state

    if (!hasLoaded) {
      return null
    }

    return (
      <PaymentDialogWrapper onBack={onBack}>
        {contractPaymentGateway === 'Stripe' && (
          <BillingSummary
            onBack={onBack}
            onSuccessfulPayment={this.handleSuccessfulPayment}
            downpayment={downpayment}
            subscriptions={subscriptions}
            additionalOptions={additionalOptions}
            termsOfTradeUrl={termsOfTradeUrl}
            stripePromise={stripePromise}
            contractPrettyIdentifier={contractPrettyIdentifier}
            contract={contract}
            customer={customer}
            product={vehicle}
            totalAmount={totalAmount}
            minimumTotalAmount={minimumTotalAmount}
          />
        )}
        {contractPaymentGateway === 'Stripe' ? (
          <SuccessfulPaymentDialog
            isOpen={isSuccessDialogOpen}
            onPreviewContract={onPreviewContract}
            paymentStatus={paymentStatus}
          />
        ) : (
          <SuccessfulPaymentDialog
            isOpen={isSuccessDialogOpen}
            onPreviewContract={onPreviewContract}
            paymentStatus={paymentStatus}
            textProcessing={t('Your purchase is being processed, the Contract will be activated soon') + '...'}
            textSucceeded={t('Congratulations, your contract is now active')}
          />
        )}
      </PaymentDialogWrapper>
    )
  }

  private handleSuccessfulPayment = (paymentStatus: ISetPaymentMethodResponse['status']) =>
    this.setState({
      isSuccessDialogOpen: true,
      paymentStatus,
    })
}
