import React from 'react'
import { OutlinedInput, WithStyles, createStyles, FormControl, Theme, withStyles, Typography } from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import { Form, FormikBag, FormikProps, withFormik } from 'formik'
import * as Yup from 'yup'
import SpinnerButton from 'components/Mui/SpinnerButton'
import classnames from 'classnames'
import { getRole } from 'utils/localStorage'
import { bindDispatch } from 'utils/redux'
import { connect } from 'react-redux'
import { initBalanceStatistics, TInitBalanceStatistics } from 'actions/contractSettlement'
import { setSettledUnits } from 'api/api'
import { AppContext } from 'store/appContext'
import { ContractValueType } from '@omnicar/sam-types'

interface IOwnProps {
  units: number
  id: string
  initBalanceStatistics: TInitBalanceStatistics
  initContractSpecifics: () => Promise<void>
  isDisabled: boolean
  descriptionText: string
  valueType: Exclude<ContractValueType, undefined>
}

interface IUnitsValues {
  units: number
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    unitsForm: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    unitsInfo: {
      width: spacing(25),
      backgroundColor: palette.background.paper,
    },
    formControl: {
      paddingRight: spacing(1.5),
    },
    formLabel: {
      fontWeight: 500,
      textTransform: 'uppercase',
      fontSize: spacing(1.5),
    },
    asterix: {
      color: palette.error.main,
      paddingLeft: spacing(0.5),
    },
    submitButton: {
      marginBottom: spacing(3.5),
    },
    inputError: {
      color: palette.error.dark,
      height: spacing(2),
    },
    disabled: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    descriptionText: {
      minHeight: spacing(3),
      paddingLeft: spacing(0.5),
      color: palette.grey[600],
    },
  })

const UnitsForm: React.SFC<TProps & FormikProps<IUnitsValues>> = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  classes,
  isSubmitting,
  isValid,
  errors,
  touched,
  isDisabled,
  descriptionText,
  valueType,
}) => {
  const isSettlementAllowed = getRole() === 'admin'
  const showWarningHighMileage = values.units >= Number(process.env.REACT_APP_MAX_MILEAGE || 1000000)

  return (
    <AppContext.Consumer>
      {() => (
        <>
          <Form className={classnames(classes.unitsForm, isDisabled ? classes.disabled : '')} onSubmit={handleSubmit}>
            <FormControl classes={{ root: classes.formControl }}>
              <label className={classes.formLabel}>
                {t('Settled %valueType', { valueType: t(valueType) })}
                <span className={classes.asterix}>*</span>
              </label>
              <OutlinedInput
                id="units"
                labelWidth={0}
                fullWidth={true}
                disabled={isSubmitting || !isSettlementAllowed}
                required
                value={values.units}
                margin="dense"
                // endAdornment={<InputAdornment position="end">km</InputAdornment>}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                className={classes.unitsInfo}
              />
              <label className={classes.descriptionText}>{descriptionText}</label>
            </FormControl>
            <SpinnerButton
              type="submit"
              variant="contained"
              color="primary"
              showSpinner={isSubmitting}
              disabled={!isValid || !isSettlementAllowed}
              className={classes.submitButton}
            >
              {t('Submit')}
            </SpinnerButton>
          </Form>
          {showWarningHighMileage && (
            <Typography variant="body2" className={classes.inputError}>
              {t('Please note that you have chosen a very high mileage')}
            </Typography>
          )}
          <Typography variant="body2" className={classes.inputError}>
            {errors.units && touched.units && errors.units}
          </Typography>
        </>
      )}
    </AppContext.Consumer>
  )
}

const EnhancedUnitsForm = withFormik<TProps, IUnitsValues>({
  mapPropsToValues: (props: IOwnProps) => ({
    units: props.units,
  }),
  validationSchema: () =>
    Yup.object().shape({
      units: Yup.number().typeError(t('Must be a number')).required(t('Required field')).positive().integer(),
    }),
  handleSubmit: async (values: IUnitsValues, formikBag: FormikBag<TProps, IUnitsValues>) => {
    const { setSubmitting, resetForm } = formikBag
    const { id, initBalanceStatistics, initContractSpecifics } = formikBag.props
    setSubmitting(true)
    const res = await setSettledUnits(id, { units: values.units })
    if (res.data) {
      await Promise.all([initBalanceStatistics(id), initContractSpecifics()])
    }
    resetForm()
  },
  enableReinitialize: true,
})(UnitsForm)

export default withStyles(styles)(connect(null, bindDispatch({ initBalanceStatistics }))(EnhancedUnitsForm))
