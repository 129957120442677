import { Button, CardContent, CircularProgress, FormControl, InputAdornment, TextField } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import {
  Brand,
  ContractType,
  FuelType,
  ICarCollection,
  Model,
  Vehicle,
  VehicleAlongItsContracts,
  VehiclePartial,
} from '@omnicar/sam-types'
import { getFuelTypes, getLicenseLookup } from 'api/api'
import classNames from 'classnames'
import DatePicker from 'components/DatePicker'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import debounce from 'lodash.debounce'
import isEqualWith from 'lodash.isequalwith'
import moment, { Moment } from 'moment'
import React, { ChangeEvent, FocusEvent } from 'react'
import { contractFlowInitialState } from 'reducers/contractFlow/contractFlowInitialState'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t, tFuelType } from 'translations/translationFunctions'
import errors from 'utils/notify/errors'
import notify from 'utils/notify/notify'
import { REQUIRED_VIN_LENGTH } from 'utils/regex'
import { SearchFieldDataType } from '../types'
import SearchField from './SearchField'

const IS_DEBUG_THIS_COMPONENT = false // For debugging purposes, the default is false

const debugPrint = (txt: any) => {
  IS_DEBUG_THIS_COMPONENT && console.log(txt)
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    date: {
      marginLeft: 0,
      marginRight: 0,
    },
    icon: {
      color: theme.palette.text.secondary,
      top: -3,
    },
    noOverflow: {
      overflow: 'visible',
    },
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    shiftRight: {
      float: 'right',
    },
    smallbtn: {
      width: '5%',
      minWidth: '30px',
    },
    topItem: {
      zIndex: 10,
    },
  })

type SearchErrors = Record<Partial<InputTypes>, string | undefined>

type InputTypes = SearchFieldDataType | 'vin'

type VehicleSearchTypes = 'reg' | 'vin'

interface IOwnProps {
  vehicleAlongItsContracts: VehicleAlongItsContracts
  contractType: ContractType
  cars?: ICarCollection
  freeContract: boolean
  isoCountry: string | null
  onChange: (vehicle: Vehicle, isValid: boolean) => void
  onTemplatesLookup: (vehicle: Vehicle) => void
  onFormReset: () => void
  resetIcon: React.ComponentType<SvgIconProps>
  showHeader: boolean
  freeContractActiveClass: string
  resettingForm: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  brandInput: string
  activeBrand?: Brand
  brands: Brand[]
  modelInput: string
  activeModel?: Model
  models: Model[]
  fuelTypeInput: string
  fuelTypes: FuelType[]
  activeFuelType?: FuelType
  touched: Record<InputTypes, boolean>
  dateFocused: boolean
  haveLookedUpTemplate: boolean
  oldValues: { regNumber: string | undefined; vin: string | undefined }
  brandRef: React.Ref<any>
  isRegVINLookupActivated: boolean
  loadingLookup: boolean
}

// @TODO: The id:s needs to be present for prices, either do country specific fallbacks, or scrap the whole fallbacks (the id:s might change in future or be different on different databases (which they most likely are), so suggestion is to scrap the fallbacks and error out or leave it without the id's and forbid payment.) /marko
const fallbackFuelTypes = [
  {
    // "fuel_type_id": 1,   // NOTE: This ID might change in future or be different on different databases (which they most likely are)!
    name: 'Petrol',
  },
  {
    // "fuel_type_id": 2,   // NOTE: This ID might change in future or be different on different databases (which they most likely are)!
    name: 'Diesel',
  },
  {
    // "fuel_type_id": 10,  // NOTE: This ID might change in future or be different on different databases (which they most likely are)!
    name: 'Electric',
  },
  {
    name: 'Cng',
  },
]

class ContractFlowVehicleForm extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    this.searchRegNoOrVIN = debounce(this.searchRegNoOrVIN, 300)

    const vehicle: Vehicle = props.vehicleAlongItsContracts as Vehicle
    this.state = {
      brandInput: vehicle.brand.name,
      brands: [],
      modelInput: vehicle.model.name,
      models: [],
      fuelTypeInput: vehicle.fuelType.name,
      fuelTypes: [],
      touched: {
        brand: false,
        model: false,
        fuelType: false,
        vin: false,
      },
      dateFocused: false,
      haveLookedUpTemplate: false,
      oldValues: { regNumber: '', vin: '' },
      brandRef: React.createRef(),
      isRegVINLookupActivated: true,
      loadingLookup: false,
    }
  }

  public componentDidMount() {
    const { isoCountry } = this.props

    if (this.props.cars) {
      this.processCarCollection()
      this.updateOptions()
      this.fetchAllFuelTypes(isoCountry)
    }
  }

  public componentDidUpdate(oldProps: TProps) {
    debugPrint('componentDidUpdate(..)')
    const { brands } = this.state
    const oldVehicle = oldProps.vehicleAlongItsContracts
    const currVehicle = this.props.vehicleAlongItsContracts

    let newBrand = null
    let newModel = null
    let newFuelType = null

    if (oldVehicle.brand !== currVehicle.brand && currVehicle.brand) {
      newBrand = currVehicle.brand
    }

    if (oldVehicle.model !== currVehicle.model && currVehicle.model) {
      newModel = currVehicle.model
    }

    if (oldVehicle.fuelType !== currVehicle.fuelType && currVehicle.fuelType) {
      newFuelType = currVehicle.fuelType
    }

    if (!oldProps.cars && this.props.cars) {
      this.processCarCollection()
    }
    if (brands.length > 0) {
      if (!isEqualWith(oldVehicle, currVehicle)) {
        this.updateOptions()
      }
    }

    newBrand && this.setState({ brandInput: newBrand.name })
    newModel && this.setState({ modelInput: newModel.name })
    newFuelType && this.setState({ fuelTypeInput: newFuelType.name })
  }

  /**
   * Fetch country specific Fuel-Types.
   *
   * @param isoCountry The country code is needed for country specific Fuel-Types.
   */
  private async fetchAllFuelTypes(isoCountry: string | null) {
    if (!isoCountry) {
      console.warn('Warning: No isoCountry for fuelTypes')
    }

    let fuelTypes: FuelType[] | null = null

    // Fuel Types.
    if (!isoCountry) {
      console.warn('"isoCountry" prop is empty in VehicleMapperForm, cannot use country specific Fuel-Types')

      fuelTypes = fallbackFuelTypes // Use fallback fuel-types.
      console.warn('Falling back on hardcoded default Fuel-Types, instead of country specific')
    } else {
      let response: any

      try {
        response = await getFuelTypes(isoCountry)
        fuelTypes = response.data
      } catch (e) {
        console.warn('Fetching fuelTypes failed, response:')
        console.warn(response)
        console.warn('Message: ' + e)

        fuelTypes = fallbackFuelTypes // Use fallback fuel-types.
        console.warn('Falling back on hardcoded default Fuel-Types, instead of country specific')
      }
    }

    if (fuelTypes) {
      this.setState({ fuelTypes })
    }
  }

  public render() {
    const {
      handleBlur,
      handleBrandChange,
      brandInputChange,
      handleModelChange,
      modelInputChange,
      handleFuelTypeChange,
      fuelTypeInputChange,
      handleClearForm,
      handleClearModel,
      handleClearFuelType,
      handleClearBrand,
    } = this
    const {
      classes,
      vehicleAlongItsContracts,
      contractType,
      freeContract,
      showHeader,
      freeContractActiveClass,
    } = this.props
    const { brandInput, brands, models, modelInput, fuelTypes, fuelTypeInput, loadingLookup } = this.state
    const errors = this.getErrorMessages()

    let inputCaption = null
    let mappedFuelTypeInput = fuelTypeInput
    for (const fuelType of fuelTypes) {
      if (fuelTypeInput.toLowerCase() === fuelType.name.toLowerCase()) {
        const item: any = fuelType
        inputCaption = !item.name ? '' : tFuelType(item.name)
        mappedFuelTypeInput = inputCaption
      }
    }

    const mappedFuelTypes = fuelTypes.map((item: FuelType) => ({
      name: item.name,
      id: item.id,
      caption: tFuelType(item.name),
    }))

    return (
      <Panel>
        {showHeader && (
          <PanelHeader>
            <PanelTitle>{t('Vehicle')}</PanelTitle>
          </PanelHeader>
        )}
        <PanelContent>
          <Card
            className={classNames(
              classes.topItem,
              classes.cardActiveLight,
              classes.noOverflow,
              freeContract && freeContractActiveClass,
            )}
            data-e2e="ContractFlowVehicleForm"
          >
            <CardContent>
              {contractType === 'CUSTOM' && (
                <React.Fragment>
                  <Button
                    className={classNames(classes.shiftRight, classes.smallbtn)}
                    type="reset"
                    title={t('Clear form')}
                    onClick={handleClearForm}
                  >
                    <this.props.resetIcon className={classes.btnIcon} />
                  </Button>
                  <TextField
                    label={t('License Plate')}
                    margin="dense"
                    value={vehicleAlongItsContracts.regNumber}
                    fullWidth={true}
                    data-e2e={'ContractFlowVehicleForm__regNumber'}
                    // tslint:disable-next-line jsx-no-lambda
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.onChange({
                        ...vehicleAlongItsContracts,
                        regNumber: e.target.value.toUpperCase(),
                      })
                    }}
                    // tslint:disable-next-line:jsx-no-lambda
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      if (e.target.value.length > 1) {
                        this.searchRegNoOrVIN(e.target.value, 'reg')
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {loadingLookup && <CircularProgress size={'20px'} />}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    label={t('VIN number')}
                    margin="dense"
                    value={vehicleAlongItsContracts.vin}
                    fullWidth={true}
                    error={errors.vin ? true : false}
                    helperText={errors.vin}
                    data-e2e={'ContractFlowVehicleForm__vin'}
                    // tslint:disable-next-line jsx-no-lambda
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      if (e.target.value.length > 1) {
                        this.searchRegNoOrVIN(e.target.value, 'vin')
                      }
                    }}
                    // tslint:disable-next-line jsx-no-lambda
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.onChange({
                        ...vehicleAlongItsContracts,
                        vin: e.target.value.toUpperCase(),
                      })

                      if (e.target.value.length === REQUIRED_VIN_LENGTH) {
                        this.searchRegNoOrVIN(e.target.value, 'vin')
                      }
                    }}
                  />
                </React.Fragment>
              )}
              <SearchField
                ref={this.state.brandRef}
                type={'brand'}
                inputLabel={t('Brand')}
                inputValue={brandInput}
                options={brands}
                onChange={handleBrandChange}
                onInputValueChange={brandInputChange}
                // tslint:disable-next-line:jsx-no-lambda
                itemToString={() => brandInput}
                selectedItem={brandInput as any}
                errorMessage={errors.brand}
                data-e2e={'ContractFlowVehicleForm__brand'}
                onBlur={handleBlur}
                onClear={handleClearBrand}
              />
              <SearchField
                type={'model'}
                inputLabel={t('Model')}
                inputValue={modelInput}
                options={models}
                onChange={handleModelChange}
                onInputValueChange={modelInputChange}
                // tslint:disable-next-line:jsx-no-lambda
                itemToString={() => modelInput}
                selectedItem={modelInput as any}
                errorMessage={errors.model}
                data-e2e={'ContractFlowVehicleForm__model'}
                onBlur={handleBlur}
                onClear={handleClearModel}
              />
              <SearchField
                type={'fuelType'}
                inputLabel={t('Fuel Type')}
                // inputValue={fuelTypeInput}
                inputValue={mappedFuelTypeInput}
                inputCaption={inputCaption}
                options={mappedFuelTypes}
                onChange={handleFuelTypeChange}
                onInputValueChange={fuelTypeInputChange}
                // tslint:disable-next-line:jsx-no-lambda
                itemToString={() => fuelTypeInput}
                selectedItem={fuelTypeInput as any}
                errorMessage={errors.fuelType}
                data-e2e={'ContractFlowVehicleForm__fuelType'}
                onBlur={handleBlur}
                onClear={handleClearFuelType}
              />
              <FormControl fullWidth={true} margin="dense" data-e2e={'ContractFlowVehicleForm__datePicker'}>
                <DatePicker
                  id="contract-flow-regdate-date-picker"
                  date={vehicleAlongItsContracts.regDate ? moment(vehicleAlongItsContracts.regDate) : null}
                  data-e2e={'ContractFlowVehicleForm__datePicker'}
                  onDateChange={this.onDateChange}
                  showLabel={true}
                  labelText={t('First Registration Date')}
                />
              </FormControl>
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private handleClearForm = () => {
    this.props.onFormReset()
    this.setState({ isRegVINLookupActivated: true })
  }

  private handleClearBrand = () => {
    const { vehicleAlongItsContracts } = this.props

    this.onChange({
      ...vehicleAlongItsContracts,
      brand: { ...contractFlowInitialState.product.brand },
      model: { ...contractFlowInitialState.product.model },
      fuelType: { ...(contractFlowInitialState.product.fuelType as any) },
    })

    this.setState({
      haveLookedUpTemplate: false,
    })
  }

  private handleClearModel = () => {
    const { vehicleAlongItsContracts } = this.props

    this.onChange({
      ...vehicleAlongItsContracts,
      model: { ...contractFlowInitialState.product.model },
      fuelType: { ...(contractFlowInitialState.product.fuelType as any) },
    })

    this.setState({
      haveLookedUpTemplate: false,
    })
  }

  private handleClearFuelType = () => {
    const { vehicleAlongItsContracts } = this.props

    this.onChange({
      ...vehicleAlongItsContracts,
      fuelType: { ...(contractFlowInitialState.product.fuelType as any) },
    })

    this.setState({
      haveLookedUpTemplate: false,
    })
  }

  public onDateChange = (val: Moment | null): void => {
    const { vehicleAlongItsContracts } = this.props
    const newVehicle = { ...vehicleAlongItsContracts, regDate: val ? val.toDate().toISOString() : undefined }
    this.onChange(newVehicle)
  }

  private searchRegNoOrVIN = async (value: string, searchType: VehicleSearchTypes) => {
    // Cancel lookup if form is being cleared
    if (this.props.resettingForm) {
      return
    }

    const { isRegVINLookupActivated } = this.state
    if (!isRegVINLookupActivated) {
      return
    }

    this.setState({ loadingLookup: true })
    const lookup = await getLicenseLookup(value)
    const vehicle: Vehicle = this.props.vehicleAlongItsContracts as Vehicle

    if (lookup.data && lookup.data.lookup.vehicle) {
      const data = lookup.data.lookup.vehicle
      const { brand, model, fuelType } = data

      this.setState({
        brandInput: brand.name,
        activeBrand: brand,
        modelInput: model.name,
        activeModel: model,
        fuelTypeInput: fuelType.name,
        activeFuelType: fuelType,
        isRegVINLookupActivated: false,
      })

      // Create a new "clean" vehicle.
      // Note: That we do not want the extra parameters (like enginePower, vehicleType, etc) from the lookup
      //       since this is a lookup for "Free/Custom Contract" and there is no "Custom" fields for these
      //       extra parameters.
      const newVehicle: Vehicle = {
        brand,
        model,
        fuelType,
        modelYear: 0,
        // if user searches on vin number in registration number field or vice versa we need to set this value
        regNumber: data.regNumber,
        vin: data.vin,

        regDate: !vehicle.regDate ? data.regDate : vehicle.regDate,
      }

      if (this.state.oldValues.regNumber !== value && this.state.oldValues.vin !== value) {
        notify.success({ message: t('Succeeded fetching information for Vehicle') + ': ' + value })
      }

      this.setState({ oldValues: { regNumber: newVehicle.regNumber, vin: newVehicle.vin } })
      this.onChange(newVehicle as VehicleAlongItsContracts, true) //todo: change to correct type
    } else {
      if (lookup.errorData && lookup.errorData.message) {
        const freeContract = this.props
        const errorMessage = lookup.errorData.message
        const messageReplacements = lookup.errorData.messageReplacements
        const localizedErrorMessage =
          (errorMessage && errors[errorMessage] && t(errors[errorMessage], messageReplacements)) || errorMessage

        if (
          this.state.oldValues.regNumber !== value &&
          this.state.oldValues.vin !== value &&
          !(freeContract && errorMessage === 'REGNO_NO_CONTRACTS_AVAILABLE')
        ) {
          notify.warning({ message: localizedErrorMessage })
        }

        if (lookup.errorData.params && lookup.errorData.params.matchedData) {
          const vehiclePartial: VehiclePartial = lookup.errorData.params.matchedData

          this.setState({
            brandInput: (vehiclePartial.brand && vehiclePartial.brand.name) || '',
            activeBrand: vehiclePartial.brand,
            modelInput: (vehiclePartial.model && vehiclePartial.model.name) || '',
            activeModel: vehiclePartial.model,
            fuelTypeInput: (vehiclePartial.fuelType && vehiclePartial.fuelType.name) || '',
            activeFuelType: vehiclePartial.fuelType,
            isRegVINLookupActivated: false,
          })

          // Create a new "clean" vehicle.
          // Note: That we do not want the extra parameters (like enginePower, vehicleType, etc) from the lookup
          //       since this is a lookup for "Free/Custom Contract" and there is no "Custom" fields for these
          //       extra parameters.
          const newVehicle: VehiclePartial = {
            brand: vehiclePartial.brand,
            model: vehiclePartial.model,
            fuelType: vehiclePartial.fuelType,
            modelYear: 0,
            regNumber: !vehicle.regNumber ? vehiclePartial.regNumber : vehicle.regNumber, // If there already exists a regNumber keep it, otherwise replace it with one from lookup.
            vin: !vehicle.vin ? vehiclePartial.vin : vehicle.vin, // If there already exists VIN keep it, otherwise replace it with one from lookup.
            regDate: !vehicle.regDate ? vehiclePartial.regDate : vehicle.regDate,
          }

          this.onChange(newVehicle as VehicleAlongItsContracts, true)
        }

        let oldValues = this.state.oldValues
        searchType.valueOf() === 'vin' ? (oldValues.vin = value) : (oldValues.regNumber = value)
        this.setState({ oldValues })
      } else {
        notify.warning({ message: t('Failed fetching information for Vehicle') + ': ' + value })
        console.warn('searchRegNoOrVIN(..): Did not find any looup data for Vehicle: ' + value)
        console.warn(lookup)
      }
    }
    this.setState({ loadingLookup: false })
  }

  private processCarCollection = () => {
    const { cars } = this.props
    if (cars) {
      const brands = cars.brands.map((item) => {
        return { id: item.id, name: item.name }
      })

      this.setState({ brands })
    }
  }

  private updateOptions = () => {
    const vehicle: Vehicle = this.props.vehicleAlongItsContracts as Vehicle

    let models: Model[] = []
    let fuelTypes: FuelType[] = []

    if (vehicle) {
      if (vehicle.brand.id) {
        models = this.getBrandModels(vehicle.brand.id)
        if (vehicle.model.id) {
          const model = this.getModelById(vehicle.brand.id, vehicle.model.id)
          if (model) {
            // Attach a caption (with a translation of name) to every fuel type item.
            // fuelTypes = model.fuelTypes.map(item => ({ name: item.name, id: item.id, caption: t(item.name) }))
            fuelTypes = model.fuelTypes.map((item) => ({ name: item.name, id: item.id, caption: tFuelType(item.name) }))
          }
        }
      }
    }
    this.setState({ models, fuelTypes })
  }

  private handleBlur = (fieldType: InputTypes) => {
    const touched = { ...this.state.touched }
    touched[fieldType] = true
    this.setState({ touched })
  }

  private brandInputChange = (val: string) => {
    this.handleBrandChange({ name: val })
  }

  private handleBrandChangeLocal = (brand: Brand, fn?: () => void) => {
    const { activeBrand } = this.state
    if (!activeBrand || brand.name !== activeBrand.name) {
      this.setState(
        {
          brandInput: brand.name,
          activeBrand: brand,
          modelInput: '',
          activeModel: undefined,
          fuelTypeInput: '',
          activeFuelType: undefined,
        },
        () => {
          fn && fn()
        },
      )
    }
  }

  private handleBrandChange = (newBrand: Brand | null) => {
    const self = this
    const { vehicleAlongItsContracts } = this.props
    const oldBrand = vehicleAlongItsContracts.brand

    let brand: Brand

    if (newBrand === null) {
      brand = { name: '' }
    } else {
      brand = newBrand
    }

    if (newBrand && newBrand.name !== oldBrand.name) {
      this.handleBrandChangeLocal(brand, () => {
        self.onChange({
          ...vehicleAlongItsContracts,
          brand,
          model: { ...contractFlowInitialState.product.model },
          fuelType: { ...(contractFlowInitialState.product.fuelType as any) },
        })
      })
    }
  }

  private modelInputChange = (val: string) => {
    this.handleModelChange({ name: val })
  }

  private handleModelChangeLocal = (model: Model, fn?: () => void) => {
    const { activeModel } = this.state

    if (!activeModel || model.name !== activeModel.name) {
      this.setState(
        {
          modelInput: model.name,
          activeModel: model,
          fuelTypeInput: '',
          activeFuelType: undefined,
        },
        () => {
          fn && fn()
        },
      )
    }
  }

  private handleModelChange = (newModel: Model | null) => {
    const self = this
    const { vehicleAlongItsContracts } = this.props
    const oldModel = vehicleAlongItsContracts.model

    let model: Model

    if (newModel === null) {
      model = { name: '' }
    } else {
      model = newModel
    }

    if (newModel && newModel.name !== oldModel.name) {
      this.handleModelChangeLocal(model, () => {
        self.onChange({
          ...vehicleAlongItsContracts,
          model: { id: model.id, name: model.name },
          fuelType: { ...(contractFlowInitialState.product.fuelType as any) },
        })
      })
    }
  }

  private handleFuelTypeChangeLocal = (fuelType: FuelType, fn?: () => void) => {
    const { activeFuelType } = this.state

    if (fuelType && (!activeFuelType || fuelType.name !== activeFuelType.name)) {
      this.setState(
        {
          fuelTypeInput: fuelType.name,
          activeFuelType: fuelType,
        },
        () => {
          fn && fn()
        },
      )
    }
  }

  private handleFuelTypeChange = (newFuelType: any | null) => {
    const self = this
    const { vehicleAlongItsContracts } = this.props
    const oldFuelType: FuelType = vehicleAlongItsContracts.fuelType

    const { fuelTypes } = this.state
    let fuelType: any

    if (newFuelType === null) {
      fuelType = { name: '', caption: '' }
    } else {
      fuelType = {
        name: !newFuelType.name ? newFuelType.caption : newFuelType.name,
        caption: tFuelType(newFuelType.name),
        id: newFuelType.id,
      }
    }

    for (const item of fuelTypes) {
      const ftItem: any = item

      if (fuelType.caption && fuelType.caption.toLowerCase() === ftItem.caption && ftItem.caption.toLowerCase()) {
        fuelType = ftItem
      }
    }

    if (newFuelType && newFuelType.name !== oldFuelType.name) {
      this.handleFuelTypeChangeLocal(fuelType, () => {
        self.onChange({
          ...vehicleAlongItsContracts,
          fuelType: fuelType,
        })
      })
    }
  }

  private fuelTypeInputChange = (val: string) => {
    const { fuelTypes } = this.state

    let matchedFuelType = { name: val, caption: val, id: 0 }

    for (const ft of fuelTypes) {
      if (val.toLowerCase() === ft.name.toLowerCase() || val.toLowerCase() === tFuelType(ft.name).toLowerCase()) {
        matchedFuelType = { name: ft.name, caption: tFuelType(ft.name), id: 0 }
        break
      }
    }

    this.handleFuelTypeChange(matchedFuelType)
  }

  private getBrandById = (brandId: number) =>
    this.props.cars && this.props.cars.brands.find((item) => item.id === brandId)

  private getModelById = (brandId: number, modelId: number) => {
    const brand = this.getBrandById(brandId)
    if (!brand) {
      return
    }
    return brand.models.find((item) => item.id === modelId)
  }

  private getBrandModels = (brandId: number) => {
    const brand = this.getBrandById(brandId)
    if (!brand) {
      return []
    }
    return brand.models.map((model) => ({ name: model.name, id: model.id }))
  }

  private getErrorMessages = () => {
    const errors: SearchErrors = { brand: undefined, model: undefined, fuelType: undefined, vin: undefined }
    const { contractType } = this.props
    const vehicle: Vehicle = this.props.vehicleAlongItsContracts as Vehicle
    const { touched } = this.state

    if (contractType === 'CUSTOM') {
      if (touched.vin && (!vehicle.vin || vehicle.vin.length !== REQUIRED_VIN_LENGTH)) {
        errors.vin = t('Invalid vin number')
      }

      return errors
    }

    if (touched.brand && !vehicle.brand.id) {
      errors.brand = t('You must select a supported brand')
    }
    if (touched.model && !vehicle.model.id) {
      errors.model = t('You must select a supported model')
    }
    if (touched.fuelType && !vehicle.fuelType.id) {
      errors.fuelType = t('You must select a supported fuel type')
    }

    return errors
  }

  private checkValid = (vehicle: Vehicle) => {
    const baseValidation = !!(vehicle.regDate && vehicle.brand.name && vehicle.model.name && vehicle.fuelType.name)

    if (this.props.contractType === 'CUSTOM') {
      if (vehicle?.vin?.length !== REQUIRED_VIN_LENGTH) {
        /*
         * NOTE:
         * This VIN check here is not 100% blocking, due to the fact that some (very few) VINs have been
         * incorrectly saved (in otherwise correct vehicle information) in the lookup register.
         * - So we let these vehicle pass anyway, for time being.
         */
        console.warn(`Detected that vehicle VIN seems to be anomalous, VIN = "${vehicle?.vin?.length}"`)
      }
      return baseValidation && vehicle.vin ? true : false
    } else {
      // Ensure ids of brand/model/fuelType
      return baseValidation && !!(vehicle.brand.id && vehicle.model.id && vehicle.fuelType.id)
    }
  }

  private lookupTemplates = (vehicle: Vehicle) => {
    this.props.onTemplatesLookup(vehicle)
  }

  private onChange = (vehicleAlongItsContracts: VehicleAlongItsContracts, triggeredByLookup?: boolean) => {
    const isValid = this.checkValid(vehicleAlongItsContracts)
    debugPrint('ContractFlowVehicleForm: onChange(): isValid = ' + isValid)

    if (
      vehicleAlongItsContracts.regDate &&
      vehicleAlongItsContracts.brand.name &&
      vehicleAlongItsContracts.model.name &&
      vehicleAlongItsContracts.fuelType.name
    ) {
      // Only do a lookup if relevant info has changed
      // Scope hax are required to remove vin/regNumber from vehicle objects
      let vehicle1: Partial<Vehicle>
      let vehicle2: Partial<Vehicle>
      {
        const { vin, regNumber, ...oldVehicle } = { ...this.props.vehicleAlongItsContracts }
        vehicle1 = { ...oldVehicle }
      }
      {
        const { vin, regNumber, ...newVehicle } = { ...vehicleAlongItsContracts }
        vehicle2 = { ...newVehicle }
      }
      // only lookup if values acually changed
      if (isValid && !isEqualWith(vehicle1, vehicle2)) {
        this.setState({ haveLookedUpTemplate: true })
        this.lookupTemplates(vehicleAlongItsContracts)
      }
    }

    debugPrint(
      '\nContractFlowVehicleForm: onChange(): HITTTT!!! About to invoke at parent - vehicleAlongItsContracts\n',
    )
    debugPrint(vehicleAlongItsContracts)
    debugPrint('\n')

    this.props.onChange(vehicleAlongItsContracts, isValid)
  }
}

export default withStyles(styles)(ContractFlowVehicleForm)
