import React from 'react'
import { TableCell, TableRow, WithStyles, withStyles, createStyles, Theme, Tooltip, Fade } from '@material-ui/core'
import classnames from 'classnames'
import { Info as InfoIcon } from '@material-ui/icons'
import { palette } from 'theme'

export interface IOwnProps {
  value: string | number
  title: string
  details?: string
  tooltip?: string
  addValueColor?: boolean
  titleNote?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing, typography }: Theme) =>
  createStyles({
    rootRow: {
      height: spacing(4.5),
      '& > *': {
        borderBottom: 'unset',
      },
    },
    cellAmount: {
      paddingRight: spacing(1),
      fontSize: spacing(1.75),
    },
    cellText: {
      textTransform: 'uppercase',
      color: palette.primary[500],
      fontSize: spacing(1.75),
    },
    details: {
      paddingTop: 0,
      paddingBottom: 0,
      color: palette.primary[500],
      textTransform: 'uppercase',
      fontSize: spacing(1.75),
      lineHeight: `${spacing(1.25)}px`,
    },
    infoIcon: {
      fontSize: spacing(2),
      marginRight: spacing(1),
    },
    titleWrapper: {
      display: 'flex',
    },
    tooltipIcon: {
      paddingLeft: spacing(1),
    },
    tooltip: {
      maxWidth: spacing(27),
      fontSize: typography.pxToRem(12),
      '& p': {
        lineHeight: `${spacing(1.25)}px`,
      },
    },
    redValue: {
      color: palette.context.warning[500],
    },
    greenValue: {
      color: palette.context.accept[500],
    },
    titleCellWrapper: {
      display: 'inline-block',
      float: 'left',
    },
    titleNote: {
      display: 'block',
      flexDirection: 'column',
      textTransform: 'none',
      fontSize: spacing(1.5),
      color: palette.text.light,
      lineHeight: spacing(0.1),
    },
  })

const StatisticsItem: React.SFC<TProps> = ({ value, title, classes, details, tooltip, addValueColor, titleNote }) => (
  <>
    <TableRow classes={{ root: classes.rootRow }}>
      <TableCell classes={{ root: classnames(classes.cellText) }}>
        <div className={classes.titleCellWrapper}>
          <div className={classes.titleWrapper}>
            <span>{title}</span>
            <span className={classnames(tooltip ? classes.tooltipIcon : '')}>
              {tooltip && (
                <Tooltip
                  title={tooltip}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              )}
            </span>
          </div>
          {titleNote && <span className={classes.titleNote}>{titleNote}</span>}
        </div>
      </TableCell>
      <TableCell
        classes={{
          root: classnames(
            classes.cellAmount,
            addValueColor !== undefined && (addValueColor ? classes.redValue : classes.greenValue),
          ),
        }}
      >
        {value}
      </TableCell>
    </TableRow>
    {details && (
      <TableRow classes={{ root: classnames(classes.rootRow, classes.details) }}>
        <TableCell classes={{ root: classes.details }}>{details}</TableCell>
      </TableRow>
    )}
  </>
)

export default withStyles(styles)(StatisticsItem)
