import { TranslationKey } from 'translations/translationTypes'
import { ErrorType } from './notify'

const errors: { [key in ErrorType | string]: TranslationKey } = {
  GENERIC_ERROR: 'Something went wrong',
  GENERIC_BAD_REQUEST_ERROR: 'Bad request',
  GENERIC_CONTRACT_PROVIDER_NOT_FOUND: 'Provider not found',
  GENERIC_CONTRACT_PROVIDER_ISOCOUNTRY_NOT_FOUND: 'Localization not found',
  GENERIC_CONTRACT_TEMPLATES_NOT_FOUND: 'No templates found',
  GENERIC_CONTRACT_TEMPLATE_NOT_FOUND: 'Template not found',
  GENERIC_CONTRACT_OPTIONS_NOT_FOUND: 'Options not found',
  GENERIC_CONTRACT_OPTION_ABBREVIATION_ALREADY_EXISTS: 'Option abbreviation already used',
  GENERIC_COUNTRY_NOT_FOUND: 'Country not found',
  GENERIC_CONTRACT_PROVIDER_ID_MISSING: 'Provider id missing',
  GENERIC_NO_CATALOGUE_FOUND: 'Catalogue not found',
  GENERIC_INVALID_TOKEN: 'Invalid token',
  GENERIC_CONTRACT_NOT_FOUND: 'Contract not found',
  GENERIC_CONTACT_PERSON_NOT_FOUND: 'Contact information not found',
  GENERIC_ADDRESS_NOT_FOUND: 'Address not found',
  GENERIC_NO_LOCALES_FOUND: 'Locales not found',
  GENERIC_INTERNAL_CONTRACT_NOTE_NOT_FOUND: 'Internal note for contract not found',
  GENERIC_INVOICE_NOT_FOUND: 'Generic invoice not found',
  GENERIC_INVOICE_LINE_NOT_FOUND: 'Generic invoice line not found',
  GENERIC_FILE_TYPE_NOT_ALLOWED: 'File type not allowed',
  GENERIC_FILE_SIZE_EXCEEDED: 'File size is too big',
  GENERIC_UNAUTHORIZED: 'You are not authorized to view this content',
  GENERIC_FORBIDDEN_ROLE: 'You cannot perform this operation',
  GENERIC_MISSING_FILE: 'File is missing',
  GENERIC_FILE_UPLOAD_ERROR: 'Something went wrong while uploading file',
  GENERIC_VALIDATION: 'Validation error',
  GENERIC_USER_NOT_FOUND: 'User not found',
  GENERIC_USER_ALREADY_EXISTS: 'User already exists',
  GENERIC_USER_CONFIGURATION_NOT_FOUND: 'User configuration not found',
  GENERIC_STRIPE_ACCOUNT_NOT_FOUND: 'Stripe account not found',
  GENERIC_USERNAME_OR_PASSWORD_NOT_FOUND: 'Username or password not found',
  GENERIC_CUSTOMER_NOT_FOUND: 'Customer not found',
  GENERIC_CUSTOMER_ALREADY_EXISTS: 'Customer already exists',
  GENERIC_TOKEN_NOT_FOUND: 'Token not found',
  GENERIC_TOKEN_EXPIRED: 'Token expired',
  GENERIC_RESOURCE_NOT_FOUND: 'The resource you requested does not exist',
  GENERIC_NETWORK_TIMEOUT: 'The network request timed out',

  DEFAULT_CARD_CANNOT_BE_DELETED_ERROR: 'You can not delete an active credit card',
  INVALID_CARD_TOKEN_ERROR: 'Invalid card token',
  INVALID_CARD_ERROR: 'Invalid card',
  INSUFFICIENT_FUNDS_ERROR: 'Insufficient funds',
  MANUAL_INVOICE_PAYMENT_FAILED: 'Manual invoice payment failed',

  REGNO_VEHICLE_NOT_FOUND: 'Vehicle not found',
  FUEL_TYPE_NOT_SUPPORTED: 'Fuel type not supported',
  REGNO_ACTIVE_CONTRACT_EXISTS: 'Active contract already exists for vehicle',
  REGNO_NO_CONTRACTS_AVAILABLE: 'No contracts available for vehicle',
  REGNO_NO_CONTRACTS_AVAILABLE_FUELTYPE_NOT_SUPPORTED: 'No contracts available for vehicle with this fuel type',
  REGNO_NO_CONTRACTS_AVAILABLE_BLACKLISTED: 'No contracts available for vehicle (blacklisted)',

  VIN_NOT_OF_REQUIRED_LENGTH: 'No contracts available for this type of vehicle, vehicle type not yet supported',

  ITEM_SERIALNO_ACTIVE_CONTRACT_EXISTS: 'Active contract already exists for product',

  VEHICLE_TOO_OLD: 'Vehicle not supported (max %maxYears years old)',
  BRAND_NO_CONTRACTS_AVAILABLE: 'No contracts available for vehicle brand',

  VEHICLE_BLACKLIST_NOT_FOUND: 'Could not find blacklisted vehicle',
  DUPLICATE_RECORD: 'Duplicate record detected',

  MODELS_NO_MODELS: 'No models found',
  BRANDS_NO_BRANDS: 'No brands found',
  FUELTYPES_NO_FUELTYPES: 'No fuel types found',
  OPTIONS_NO_OPTIONS: 'No options found',
  CONTRACTS_NO_CONTRACTS: 'No templates found',
  INVALID_REGISTRATION_DATE: 'Invalid registration date',
  PRICE_TEMPLATE_NOT_FOUND: 'Price template not found',
  PRICE_MODEL_NOT_FOUND: 'Price model not found',
  PRICE_PRICE_NOT_FOUND: 'Price not found',
  UNSUPPORTED_CONTRACT_TYPE: 'Template type not supported',
  CONTRACT_ENDS_BEFORE_START: 'Contract End Date has already been reached',

  CONTRACT_TRANSITION_INVALID_OR_ILLEGAL: 'Contract not allowed to transition to this state',
  CONTRACT_MISSING_OR_INVALID_INFORMATION: 'Contract missing or invalid information',

  WARRANTY_FREE_CONTRACT_NOT_ALLOWED: 'It is not possible create free contracts with warranties',
  WARRANTY_TEMPLATE_ID_NOT_FOUND: 'The choosen contract template is not available',
  WARRANTY_START_MILAGE_MISSING: 'The vehicles current milage must be given',
  WARRANTY_START_MILAGE_EXCEEDS_WARRANTY_MAX_MILAGE:
    'You can not create contract with chosen warranty option, because the vehicles current milage exceeds the upper limit for this warranty. Try chose another warranty type, if possible',
  WARRANTY_END_MILAGE_EXCEEDS_WARRANTY_MAX_MILAGE:
    'The chosen milage for the contract in addition with the vehicles current mileage exceeds the upper milage limit for this type of warranty. Try to lower the milage limit for the contract or chose another warranty type',

  WARRANTY_ALREADY_EXISTS_FOR_VEHICLE: 'There already exists a warranty for this vehicle',
  WARRANTY_MODEL_NOT_FOUND: 'The vehicle model was not found in our database',
  WARRANTY_NOT_AVAILABLE_FOR_VEHICLE: 'The chosen warranty does not exist for this vehicle',
  CONTRACT_ACTIVATION_PREVENTED_BY_SYSTEM_FOR_THIS_VEHICLE:
    'System prevents activation of the contract for this vehicle', // Note: Some Fragus Country CEOs insists to have this notification instead of WARRANTY_ALREADY_EXISTS_FOR_VEHICLE.

  VAT_INFORMATION_NOT_FOUND: 'Information about VAT not found',

  INVALID_IMPORT_FILE: 'The file you tried to import is invalid',
  TOO_MANY_RECORDS: 'The import file cannot have more than 1000 lines',
  INVALID_COLUMN_COUNT:
    'The import file has too many or too few columns, please read the upload instructions for more info',
  INVALID_HEADER: 'The import file has one or more invalid headers, please read the upload instructions for more info',
  INVALID_DATE:
    'The import file has at least one invalid date value, please read the upload instructions for more info',

  INVALID_PRODUCT_TYPE: 'The product type is not yet supported',

  PASSWORD_MISMATCH: 'Passwords do not match',
  PASSWORD_VALIDATION_ERROR: 'Password is not valid',
  WRONG_CURRENT_PASSWORD: 'Current password is incorrect',
  WRONG_PASSWORD: 'Password is wrong',

  TIMEOUT: 'Network timed out',
  TOO_MANY_REQUESTS: 'Too many requests, please wait a minute',
  OTHER: 'A network error occured',

  OFFER_REQUEST_NOT_FOUND: 'Offer request not found',
  OFFER_REQUEST_ILLEGAL_TRANSITION: 'Unable to approve/reject offer request',
  GATEWAY_NOT_FOUND: 'Gateway not found',
  GENERIC_NODE_ROLE_NOT_FOUND: 'Node Role was not found',
  CARD_AUTHENTICATION_REQUIRED: 'Card AthenticationError',
  CARD_ERROR: 'Card Error',
  STRIPE_CUSTOMER_NOT_CORRECTLY_CREATED: 'Stripe Customer Not Created Error',

  CONTRACT_ENDS: 'Contract is coming to its expiry date',

  AX_SERVICE_UNAVAILABLE: 'Autoexperten Service is unavailable',
  VEHICLE_NO_CONTRACTS_AVAILABLE: '%message',
}

export default errors
