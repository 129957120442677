import ActionTypeKeys from './ActionTypeKeys'
import { IContractFilters } from 'reducers/contractFilterParams/contractFilterInitialState'

export interface IContractFilterParamsUpdate {
  readonly type: ActionTypeKeys.CONTRACT_FILTER_UPDATE
  readonly props: Partial<IContractFilters>
}

export interface IContractFilterParamsReset {
  readonly type: ActionTypeKeys.CONTRACT_FILTER_RESET
}

export const contractFilterUpdate = (props: Partial<IContractFilters>): IContractFilterParamsUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_FILTER_UPDATE,
    props,
  }
}

export const contractFilterReset = (): IContractFilterParamsReset => {
  return {
    type: ActionTypeKeys.CONTRACT_FILTER_RESET,
  }
}
