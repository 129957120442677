import { WithStyles, withStyles, Button, createStyles, Theme } from '@material-ui/core'
import Typography from 'components/Typography'
import React from 'react'
import { t } from 'translations/translationFunctions'
import classNames from 'classnames'
import { SaveAlt as SaveAltIcon } from '@material-ui/icons'
import { TranslationKey } from 'translations/translationTypes'
import { theme } from 'theme'

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    pdfLink: {
      flexBasis: '50%',
      [breakpoints.up('sm')]: {
        flexBasis: '100%',
        marginBottom: spacing(1.5),
      },

      '&:link, &:visited, &:hover, &:active': {
        color: theme.palette.text.regular,
        textDecoration: 'none',
      },
    },
    linkInner: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    textLightGrey: {
      color: theme.palette.text.light,
    },
    linkIcon: {
      marginRight: spacing(1.5),
      fontSize: 20,
    },
  })

interface IOwnProps {
  pdfHref: any
  datae2e: string
  title: TranslationKey
}

type TProps = IOwnProps & WithStyles<typeof styles>

const DownloadPDFButton: React.SFC<TProps> = ({ classes, pdfHref, datae2e, title }) => {
  return (
    <Button className={classes.pdfLink} href={pdfHref} target="blank" data-e2e={datae2e}>
      <Typography className={classNames(classes.linkInner, classes.textLightGrey)} tag="span" variant="button">
        <SaveAltIcon className={classes.linkIcon} />
        {t(title)} (PDF)
      </Typography>
    </Button>
  )
}

export default withStyles(styles)(DownloadPDFButton)
