import React, { StatelessComponent } from 'react'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'
import { Button } from '@material-ui/core'

const styles = {
  icon: {
    fontSize: 14,
    // marginRight: '0.15vw'
  },
  caption: {
    marginLeft: '0.45em'
  }
}

interface ILinkButtonProps {
  classes?: any
  caption: string
  href: string
  target?: string
  title?: string
}
type TProps = ILinkButtonProps

const LinkButton: StatelessComponent<TProps> = ({
  classes,
  caption,
  href,
  target,
  title
}) => {
  if (!target) {
    target = '_blank'
  }

  return (
    <div>
      <Button
        variant='text'
        color='secondary'
        href={href}
        target={target}
        title={title}
      >
        <CloudDownloadIcon
          style={styles.icon}
        />
        <span
          style={styles.caption}
        >
          {caption}
        </span>
      </Button>

    </div>
  )
}

export default LinkButton
