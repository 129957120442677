import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { formatCurrency, formatMileage } from '@omnicar/sam-format'
import { NumberType } from '@omnicar/sam-format/dist/formattedNumber/NumberTypes'
import { IMileageChart, IPaymentChart, PriceSpecification } from '@omnicar/sam-types'
import { IValueChart } from '@omnicar/sam-types/types/admin/chart/contractCharts'
import classNames from 'classnames'
import { IReduxHiddenVATProps, withHiddenVAT } from 'components/ContractDisplayConfig/withHiddenVAT'
import Typography from 'components/Typography'
import React, { SFC } from 'react'
import { compose } from 'recompose'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { TTab } from '..'

interface IDataProp {
  paymentChartData: IPaymentChart | undefined
  mileageChartData: IMileageChart | undefined
  valueChartData: IValueChart | undefined
}

interface IOwnProps {
  data: IDataProp
  activeTab: TTab
  onTabSelect: (activeTab: TTab) => void
  translations: {
    value: TranslationKey
    valueAtExperation: TranslationKey
    valueAtExperationCalc: TranslationKey
    shortValue: TranslationKey
  }
  valueType: 'Mileage' | 'Hours' | 'Services'
  serviceCount?: number
  contractMaxServiceCount?: number
  settledUnits?: number | null
}
type TProps = IOwnProps & WithStyles<typeof styles> & IReduxHiddenVATProps

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.lighter[500],
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
    },
    tab: {
      padding: spacing(2),
      flex: 1,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    payments: {
      boxShadow: `inset -4px -4px 2px 0px rgba(221,221,221,1)`,
      backgroundColor: theme.palette.background.lighter,
    },
    mileage: {
      boxShadow: `inset -4px 4px 2px 0px rgba(221,221,221,1)`,
      backgroundColor: theme.palette.background.lighter,
    },
    active: {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    marginBottom8: {
      marginBottom: spacing(1),
    },
    marginTop: {
      marginTop: spacing(8),
    },
    warning: {
      backgroundColor: theme.palette.context.warning[500],
    },
    lightWarning: {
      backgroundColor: theme.palette.context.warning[200],
    },
    warningText: {
      color: 'white',
    },
  })

const lightWarningLimit = 0.6
const warningLimit = 0.75

const SelectableTabs: SFC<TProps> = ({
  classes,
  data,
  activeTab,
  onTabSelect,
  translations,
  valueType,
  serviceCount,
  settledUnits,
  hiddenVAT,
}) => {
  const getPrice = (price?: PriceSpecification) => {
    if (!price) {
      return 0
    } else {
      return hiddenVAT ? price.priceInclVat : price.price
    }
  }
  const { paymentChartData, /*mileageChartData,*/ valueChartData } = data
  // payments tab
  const paymentsEarnings = getPrice(paymentChartData?.totalEarnings)
  const paymentsCosts = paymentChartData?.totalCosts.price || 0
  const paymentsBalance = getPrice(paymentChartData?.totalBalance)
  const totalFailedPayments = getPrice(paymentChartData?.totalFailedPayments)
  // mileage/hours tab
  const totalExpectedValue = valueChartData ? valueChartData.totalExpectedValue : 0
  const contractMaxServices = valueChartData && valueChartData.contractEndValue
  const showSettledUnits = settledUnits !== null && settledUnits !== undefined
  let displayLightWarning = false
  let displayWarning = false

  if (valueType === 'Services' && serviceCount && contractMaxServices) {
    const serviceProcentageUsed = serviceCount / contractMaxServices
    displayLightWarning = serviceProcentageUsed > lightWarningLimit
    displayWarning = serviceProcentageUsed > warningLimit
  }

  const valueTotal = valueChartData ? valueChartData.totalValue : 0
  const typeToUpper: NumberType = valueType.toUpperCase() as NumberType

  // TODO: this data is missing from API
  // const mileageExpected = mileageChartData ? mileageChartData.totalExpectedMileage : 0

  return (
    <div className={classNames(classes.root)}>
      <div
        className={classNames('PaymentsTab', classes.tab, classes.payments, activeTab === 'payments' && classes.active)}
        // tslint:disable-next-line jsx-no-lambda
        onClick={() => onTabSelect('payments')}
      >
        <Typography className={classNames(classes.marginBottom8)} variant="subheading">
          {t('Payments')}
        </Typography>
        <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
          <Typography variant="body1">{t('Total price')}</Typography>
          <Typography variant="body1">{formatCurrency(paymentsEarnings, { symbolDisplayType: 'APPEND' })}</Typography>
        </div>
        <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
          <Typography variant="body1">{t('Workshop costs')}</Typography>
          <Typography variant="body1">{formatCurrency(paymentsCosts, { symbolDisplayType: 'APPEND' })}</Typography>
        </div>
        <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
          <Typography variant="body1">{t(hiddenVAT ? 'Balance' : 'Balance excl. VAT')}</Typography>
          <Typography variant="body1">{formatCurrency(paymentsBalance, { symbolDisplayType: 'APPEND' })}</Typography>
        </div>

        <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
          <Typography variant="body1">{t('Total unpaid')}</Typography>
          <Typography variant="body1">
            {formatCurrency(totalFailedPayments, { symbolDisplayType: 'APPEND' })}
          </Typography>
        </div>
      </div>
      <div
        className={classNames('MileageTab', classes.tab, classes.mileage, activeTab === 'value' && classes.active)}
        // tslint:disable-next-line jsx-no-lambda
        onClick={() => onTabSelect('value')}
      >
        <Typography variant="subheading">{t(translations.value)}</Typography>
        <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
          <Typography variant="body1">{t(translations.valueAtExperation)}</Typography>
          {totalExpectedValue !== null && (
            <Typography variant="body1">
              {valueType === 'Mileage'
                ? formatMileage(totalExpectedValue, { symbolDisplayType: 'APPEND' })
                : `${totalExpectedValue} ${t(translations.shortValue)}`}
            </Typography>
          )}
        </div>
        {/* 
            TODO: hidden as data is missing from API
            <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
            <Typography variant="body1">{t('Mileage today (calculated)')}</Typography>
            <Typography variant="body1">{formatMileage(mileageExpected, { symbolDisplayType: 'APPEND' })}</Typography>
          </div> */}

        <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
          <Typography variant="body1">{t(translations.valueAtExperationCalc)}</Typography>
          {valueTotal !== null && (
            <Typography variant="body1">
              {valueType === 'Mileage'
                ? formatMileage(valueTotal, { numberType: typeToUpper, symbolDisplayType: 'APPEND' })
                : `${valueTotal} ${t(translations.shortValue)}`}
            </Typography>
          )}
        </div>
        {showSettledUnits && (
          <div className={classNames(classes.spaceBetween, classes.marginBottom8)}>
            <Typography variant="body1">{t('Settled mileage')}</Typography>
            <Typography variant="body1">
              {formatMileage(settledUnits!, { numberType: typeToUpper, symbolDisplayType: 'APPEND' })}
            </Typography>
          </div>
        )}

        {valueType === 'Services' && serviceCount !== undefined && contractMaxServices && (
          <div
            className={classNames(
              classes.spaceBetween,
              classes.marginTop,
              classes.marginBottom8,
              (displayWarning && classes.warning) || (displayLightWarning && classes.lightWarning),
            )}
          >
            <Typography className={classNames(displayWarning && classes.warningText)} variant="body1">
              {t('%nr1 services out of %nr2', {
                nr1: serviceCount,
                nr2: contractMaxServices,
              })}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default compose<TProps, IOwnProps>(withHiddenVAT, withStyles(styles))(SelectableTabs)
