import React from 'react'
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import EnhancedUnitsForm from './UnitsForm'
import { t } from 'translations/translationFunctions'
import { ContractValueType } from '@omnicar/sam-types'
import { getContractDetailsChartData } from 'api/api'

interface IOwnProps {
  id: string
  settledUnits: number | null
  isDisabled: boolean
  valueType: Exclude<ContractValueType, undefined>
  initContractSpecifics: () => Promise<void>
  serviceCount: number
}

type TProps = IOwnProps & WithStyles<typeof styles> & IState

interface IState {
  calculatedUnits?: number
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    unitsInfoWrapper: {
      width: '100%',
      marginTop: spacing(6),
    },
    shrinkIcon: {
      transform: 'rotate(180deg)',
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  })

class ContractUnitsInfo extends React.Component<TProps & IState> {
  public state: IState = {
    calculatedUnits: undefined,
  }

  public componentDidMount() {
    this.props.settledUnits === null && this.loadCalculatedUnits()
  }

  public componentDidUpdate(prevProps: TProps) {
    const { id, settledUnits, serviceCount } = this.props

    if (prevProps.id !== id || prevProps.serviceCount !== serviceCount) {
      settledUnits === null && this.loadCalculatedUnits()
    }
  }

  render() {
    const { id, classes, initContractSpecifics, isDisabled, valueType } = this.props
    const { units, descriptionText } = this.getUnitsAndDescription()

    return (
      <div className={classes.unitsInfoWrapper}>
        <EnhancedUnitsForm
          id={id}
          units={units}
          isDisabled={isDisabled}
          initContractSpecifics={initContractSpecifics}
          descriptionText={descriptionText}
          valueType={valueType}
        />
      </div>
    )
  }

  private loadCalculatedUnits = async () => {
    const { id, valueType, serviceCount } = this.props

    if (valueType === 'Services') {
      this.setState({ calculatedUnits: serviceCount })
      return
    }

    const res = await getContractDetailsChartData(id)
    if (res.data) {
      this.setState({ calculatedUnits: res.data.valueChart.totalValue })
    }
  }

  private getUnitsAndDescription = (): { descriptionText: string; units: number } => {
    const { calculatedUnits } = this.state
    const { settledUnits, valueType } = this.props
    const unitsName = { valueType: t(valueType) }
    let descriptionText = ''
    let units = settledUnits || 0

    if (!calculatedUnits) {
      return { descriptionText, units }
    }

    if (settledUnits === null && (calculatedUnits || valueType !== 'Services')) {
      descriptionText = calculatedUnits
        ? t('Calculated %valueType', unitsName)
        : t('Workshop Operations %valueType', unitsName)
      units = calculatedUnits
    }

    return { descriptionText, units }
  }
}

export default withStyles(styles)(ContractUnitsInfo)
