import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import * as React from 'react'
import { createTypographyStyles } from 'theme'

const styles = (theme: Theme) =>
  createTypographyStyles(theme, {
    typography: {
      margin: 0,
    },
  })

export type VariantType =
  | 'display1'
  | 'display2'
  | 'header-light'
  | 'header'
  | 'subheading'
  | 'title'
  | 'subtitle'
  | 'subtitle1'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'

export type HTMLTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span'

interface IOwnProps {
  variant: VariantType
  tag?: HTMLTagType
  className?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const Typography: React.SFC<TProps> = ({ tag = 'div', variant, classes, className = '', children, ...rest }) => {
  const HTMLTag = tag
  return (
    <HTMLTag className={classNames(classes.typography, classes[variant], className)} {...rest}>
      {children && children}
    </HTMLTag>
  )
}

export default withStyles(styles)(Typography)
