import { IThemePalette } from './core/palette'

import { palette } from './core/palette'
import { palette as paletteWhiteBG } from './core/paletteWhiteBG'

export interface ICustomTheme {
  palette: IThemePalette
  paletteWhiteBG: IThemePalette
}

export const theme: ICustomTheme = {
  palette,
  paletteWhiteBG,
}

export * from './styles/panel'
export * from './styles/typography'
export * from './styles/dialog'
export * from './core/palette'
export { palette as whiteBG } from './core/paletteWhiteBG'
