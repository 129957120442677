import React, { ChangeEvent } from 'react'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { t } from 'translations/translationFunctions'
import { Button } from '@material-ui/core'
import { connect, Dispatch } from 'react-redux'
import { IRootState } from 'reducers/initialState'
import { selectSettlementAttachments } from 'selectors/contractSettlement'
import { TAttachment, Resource } from 'types/settlement'
import {
  IRemoveSettlementAttachment,
  ISaveSettlementAttachments,
  removeSettlementAttachment,
  saveSettlementAttachments,
} from 'actions/contractSettlement'
import FileUploadItem from './FileUploadItem'
import ActionTypes from 'actions/ActionTypes'

interface IReduxStateProps {
  attachments: Resource<TAttachment>[]
}
interface IReduxDispatchProps {
  saveAttachments: (files: FileList) => ISaveSettlementAttachments
  removeAttachment: (id: number) => IRemoveSettlementAttachment
}

type TProps = WithStyles<typeof styles> & IReduxStateProps & IReduxDispatchProps

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    uploadContainer: {
      paddingLeft: spacing(2),
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      display: 'flex',
      justifyContent: '',
    },
    icon: {
      fontSize: spacing(1.75),
    },
    row: {
      paddingLeft: spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      marginBottom: spacing(1),
    },
    buttonText: {
      color: palette.primary.main,
    },
    formControl: {
      width: '100%',
    },
    input: {
      textAlign: 'start',
    },
  })

const SettlementFilesUpload: React.SFC<TProps> = ({ classes, attachments, saveAttachments, removeAttachment }) => {
  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.currentTarget.files
    if (fileList && fileList.length) {
      saveAttachments(fileList)
    }
  }
  const isMaxAttachmentsNumber = attachments.length > 2 //allow only 3 attachments
  return (
    <>
      <div className={classes.uploadContainer}>
        <input
          id="file-picker"
          accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .zip"
          type="file"
          hidden={true}
          onChange={handleFileSelect}
          disabled={isMaxAttachmentsNumber}
        />
        <label htmlFor="file-picker">
          <Button disabled={isMaxAttachmentsNumber} className={classes.buttonText} variant="text" component="span">
            <AttachFileIcon className={classes.icon} />
            {t('Attach file')}
          </Button>
        </label>
      </div>
      <div className={classes.row}>
        {attachments.length ? (
          attachments.map((a) => (
            <FileUploadItem
              key={a.id}
              id={a.id}
              name={a.name}
              removeAttachment={removeAttachment}
              iconClassName={classes.icon}
              formControlClassName={classes.formControl}
              inputClassName={classes.input}
            />
          ))
        ) : (
          <div />
        )}
      </div>
    </>
  )
}

const mapState = (state: IRootState) => ({
  attachments: selectSettlementAttachments(state),
})

const mapDispatch = (dispatch: Dispatch<ActionTypes>) => ({
  saveAttachments: (files: FileList) => dispatch(saveSettlementAttachments(files)),
  removeAttachment: (id: number) => dispatch(removeSettlementAttachment(id)),
})

export default withStyles(styles)(connect(mapState, mapDispatch)(SettlementFilesUpload))
