import * as React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { TableCell, TableRow } from '@material-ui/core'
import { getCurrency } from 'utils/localStorage'

interface IOwnProps {
  text: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    headerTableText: {
      fontSize: spacing(2.75),
      color: palette.text.primary,
      textTransform: 'uppercase',
      paddingRight: spacing(0.5),
    },
    currencyText: {
      fontSize: spacing(2.25),
      color: palette.primary[500],
    },
    headerCell: {
      textAlign: 'center',
      paddingBottom: spacing(3),
    },
    rootRow: {
      height: spacing(4),
      '& > *': {
        borderBottom: 'unset',
      },
    },
  })

const StatisticsHeaderItem: React.SFC<TProps> = ({ text, classes }) => (
  <TableRow classes={{ root: classes.rootRow }}>
    <TableCell colSpan={2} classes={{ root: classes.headerCell }}>
      <span className={classes.headerTableText}>{text}</span>
      <span className={classes.currencyText}>({getCurrency()})</span>
    </TableCell>
  </TableRow>
)

export default withStyles(styles)(StatisticsHeaderItem)
