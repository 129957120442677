import React from 'react'
import { formatDate } from '@omnicar/sam-format'
import { SnackbarContent } from '@material-ui/core'
import { t } from 'translations/translationFunctions'

export interface IOwnProps {
  autoSettlement: boolean
  snackbarContentClassName: string
  settlementDate: Date | string
  userName: string
  message: 'This contract has been settled automatically on %date' | 'This contract has been settled by %user on %date'
}

const InfoMessage: React.SFC<IOwnProps> = ({ snackbarContentClassName, settlementDate, message, userName }) => (
  <SnackbarContent
    classes={{ root: snackbarContentClassName }}
    message={t(message, {
      date: formatDate(new Date(settlementDate), { showTime: true }),
      user: userName,
    })}
  />
)

export default InfoMessage
