import { IsoLocale } from '@omnicar/sam-types'
import { t } from 'translations/translationFunctions'
import { IReplacement, TranslationKey } from 'translations/translationTypes'

export const firstCharUpper = (value: string) => {
  return value ? `${value.charAt(0).toUpperCase()}${value.slice(1)}` : value
}

/**
 * Translate the paramter and make sure it ends with a period before return
 * (string.endsWith()?)
 */
export const tDot = (key: TranslationKey, replacements?: IReplacement, locale?: IsoLocale | undefined): string => {
  const text: string = t(key, replacements, locale).trim()
  const len = text.length

  if ('.' !== text.slice(len - 2, len - 1)) {
    return text + '.'
  }
  return text
}

export const numPad = (num: number, padLen: number): string => {
  const padChar = '0'
  return num.toString().padStart(padLen, padChar)
}

export const oneline = (str: string) => str.replace(/[\r\n]\s*/g, ' ').replace(/^\s+/g, '')

export const encloseInParenthesis = (str: string) => `(${str})`
