import { t } from 'translations/translationFunctions'
import OverviewContracts from 'components/admin/Overview/Contracts'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Page, PageHeader, PageTitle, PageContent } from 'components/Mui/Page'

type TProps = RouteComponentProps<any>

const OverviewPage: React.SFC<TProps> = ({ ...rest }) => (
  <Page>
    <PageHeader>
      <PageTitle>{t('Overview')}</PageTitle>
    </PageHeader>
    <PageContent noPadding={true}>
      <OverviewContracts {...rest} />
    </PageContent>
  </Page>
)

export default OverviewPage
