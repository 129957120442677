import { IContractFlow } from '../../../../types/contractFlow'
import { debugPrint } from '../../../../utils/miscs'

/**
 *
 * @param debugHasCalculated Use this variable only for debug info locally.
 */
export const isContractReadyForCalculation = (
  contract: IContractFlow,
  loading: boolean,
  freeContract: boolean,
  debugHasCalculated?: boolean,
) => {
  const IS_DEBUG: boolean = false // Default is false.
  const { template, duration, mileage, product, startMileage, value, startValue, startValueType, valueType } = contract

  const isMileageDisabled: boolean = !!contract.state.isMileageDisabled

  if (IS_DEBUG) {
    console.debug('\nisContractReadyForCalculation(..):--------------')
    console.debug('--------------\n')
    console.debug('      debugHasCalculated = ' + debugHasCalculated)
    console.debug('                 loading = ' + loading)
    console.debug('              !!template = ' + !!template)
    console.debug('            freeContract = ' + freeContract)
    console.debug('            startMileage = ' + startMileage)
    console.debug('              startValue = ' + startValue)
    console.debug('          startValueType = ' + startValueType)
    console.debug('       isMileageDisabled = ' + isMileageDisabled)
    console.debug('   template?.priceSource = ' + template?.priceSource)
    console.debug('--------------\n')

    console.debug('contract:')
    console.debug(contract)
  }

  if (loading) {
    debugPrint(IS_DEBUG, `! Will return false, due to loading = ${loading}`)
    return false
  }

  if (!template) {
    debugPrint(IS_DEBUG, `! Will return false, due to !template = ${!template}`)
    return false
  }

  if (!isMileageDisabled && template.calculationMethod === 200) {
    // Must have a start mileage.
    if (typeof startMileage !== 'number' && (typeof startValue !== 'number' || !startValueType)) {
      debugPrint(IS_DEBUG, `! Will return false, due to case ABC`)
      return false
    }
  }

  let ret = false

  // If it's a vehicle contract
  if ('vin' in product) {
    if (template.serviceVariantId) {
      if (isMileageDisabled) {
        ret = !!(duration.value && product.regNumber && product.regDate)
      } else {
        ret = !!(mileage.value && duration.value && startMileage !== undefined && product.regNumber && product.regDate)
      }
    } else if (template.priceSource === 'V4PricingTool') {
      if (!startMileage) {
        debugPrint(IS_DEBUG, `! Will return false, due to startMileage = ${startMileage}`)
        return false
      }

      // V4PricingTool contract.
      ret = !!(
        // mileage.value !== undefined &&
        // mileage.value !== null &&
        (value && value.value !== undefined && value.value !== null && valueType && duration.value)
      )
    } else {
      if (freeContract) {
        ret = !!(value && value.value && valueType && duration.value)
      } else {
        // Standard contract
        ret = !!(
          mileage.value !== undefined &&
          mileage.value !== null &&
          value &&
          value.value !== undefined &&
          value.value !== null &&
          valueType &&
          duration.value &&
          product.brand.id &&
          product.model.id &&
          product.fuelType.id
        )
      }
    }
  }

  // If it's a product contract
  else if ('itemNumber' in product) {
    if (template.serviceVariantId) {
      ret = !!(
        (value && valueType && value.value) ||
        (!value &&
          !valueType &&
          duration.value &&
          product.itemNumber &&
          product.regDate &&
          startValue &&
          startValueType)
      )
    } else {
      if (freeContract) {
        const startValueValid = !!(startValue !== undefined && startValue >= 0 && startValueType)

        ret = !!((valueType ? !!value : true) && duration && duration.value > 0 && startValueValid)
      }
    }
  }

  debugPrint(IS_DEBUG, `isContractReadyForCalculation(..): will return value: ${ret}`)
  return ret
}
