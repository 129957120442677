import { Theme, createStyles } from '@material-ui/core'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    cards: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      paddingLeft: 0,
    },
    card: {
      flexBasis: '100%',
      height: spacing(19),
      [breakpoints.up('sm')]: {
        flexBasis: `calc(50% - ${spacing(2)}px)`, // Note: Potentially the "new" spacing may give a bug here in the calculation!
        '&:nth-child(2n + 1)': {
          marginRight: spacing(2),
        },
      },
      marginBottom: spacing(2),
    },
    spinner: {
      margin: 'auto',
    },
    diaologText: {
      marginBottom: spacing(3),
      fontSize: spacing(2),
      textAlign: 'center',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
