import { CardContent, IconButton } from '@material-ui/core'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Edit as EditIcon } from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import { Vehicle } from '@omnicar/sam-types'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Typography from 'components/Typography'
import React from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ContractFlowType } from 'types/contractFlow'
import { kW2horsepower, localFuelTypeName } from 'utils/vehicle'

interface IProps extends WithStyles<typeof styles> {
  isUsingV4PTAsSource: boolean
  vehicle: Vehicle
  flow: ContractFlowType
  onEditEnginePower: any
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    detailsContainer: {
      paddingBottom: '16px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      '&:first-child': {
        marginBottom: `${theme.spacing(3)}px`,
      },
    },
    rowAdditionalDetails: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: `${theme.spacing(2)}px`,
    },
    additionalDetailsContainer: {
      backgroundColor: '#EAF9F9',
      margin: '-6px',
      padding: '6px',
    },
    field: {
      flexBasis: '33.3333%',
    },
    subtitle: {
      color: customTheme.palette.text.light,
    },
    // disabled: {
    //   color: 'rgba(0, 0, 0, 0.38)',
    // },
    iconButton: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      width: 'inherit',
      height: 'inherit',
      fontSize: '18px',
    },
  })

class ContractFlowVehicleDetails extends React.Component<IProps, never> {
  public render() {
    const { isUsingV4PTAsSource, vehicle, classes, onEditEnginePower } = this.props

    if (!vehicle) {
      return <div>--Vehicle data is missing (in ContractFlowVehicleDetails)--</div>
    }

    const isCaravan: boolean = vehicle.vehicleType === 'Caravan'

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{t('Vehicle')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card className={classes.cardActive} data-e2e="contractFlowVehicleDetails">
            <CardContent>
              <div className={classes.detailsContainer}>
                <section className={classes.row}>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Brand')}
                    </Typography>
                    <Typography variant="title" data-e2e="contractFlowVehicleDetails__brand">
                      {vehicle.brand && vehicle.brand.name ? vehicle.brand.name : '--'}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Model')}
                    </Typography>
                    <Typography variant="title" data-e2e="contractFlowVehicleDetails__model">
                      {vehicle.model && vehicle.model.name ? vehicle.model.name : '--'}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Fuel Type')}
                    </Typography>
                    <Typography variant="title" data-e2e="contractFlowVehicleDetails__fuelType">
                      {vehicle.fuelType && vehicle.fuelType.name ? localFuelTypeName(vehicle) : '--'}
                    </Typography>
                  </div>
                </section>
                <section className={classes.row}>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('License Plate')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__regNumber">
                      {vehicle.regNumber}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('VIN number')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__vin">
                      {vehicle.vin}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Registration Date')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__regDate">
                      {vehicle.regDate && formatDate(vehicle.regDate)}
                    </Typography>
                  </div>
                </section>
              </div>
              {/* {---------------} */}
              <div className={classes.additionalDetailsContainer}>
                <section className={classes.rowAdditionalDetails}>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Model Year')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__modelYear">
                      {!vehicle.modelYear ? 'n/a' : vehicle.modelYear}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Engine Max Power')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__engineMaxPower">
                      {!vehicle.engineMaxPower ? 'n/a' : `${vehicle.engineMaxPower} kW`}
                      {!vehicle.engineMaxPower ? '' : ` (${Math.round(kW2horsepower(vehicle.engineMaxPower))} hp)`}
                      {isUsingV4PTAsSource && !isCaravan && (
                        <IconButton onClick={onEditEnginePower} classes={{ root: classes.iconButton }}>
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Cylinder Volume')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__cylinderVolume">
                      {!vehicle.cylinderVolume ? 'n/a' : `${vehicle.cylinderVolume} cc (cm3)`}
                    </Typography>
                  </div>
                </section>
                <section className={classes.rowAdditionalDetails}>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Transmission Type')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__transmissionType">
                      {!vehicle.transmissionType ? 'n/a' : vehicle.transmissionType}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Drive Type')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__driveType">
                      {!vehicle.driveType ? 'n/a' : `${vehicle.driveType}`}
                    </Typography>
                  </div>
                  <div className={classes.field}>
                    <Typography className={classes.subtitle} variant="subtitle">
                      {t('Vehicle Type')}
                    </Typography>
                    <Typography variant="body1" data-e2e="contractFlowVehicleDetails__vehicleType">
                      {!vehicle.vehicleType ? 'n/a' : vehicle.vehicleType}
                    </Typography>
                  </div>
                </section>
              </div>
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }
}

export default withStyles(styles)(ContractFlowVehicleDetails)
