import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { trackPageView } from 'utils/analytics'

export class Tracker extends Component<Pick<RouteComponentProps<any>, 'location'>, {}> {
  public componentDidMount() {
    const page = this.props.location.pathname
    trackPageView(page)
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Pick<RouteComponentProps<any>, 'location'>) {
    const currentPage = this.props.location.pathname
    const nextPage = nextProps.location.pathname

    if (currentPage !== nextPage) {
      trackPageView(nextPage)
    }
  }

  public render() {
    return <>{this.props.children}</>
  }
}

export function WithTracker<T = object>(Component: React.ComponentType<any>, innerProps?: T) {
  return (props: Pick<RouteComponentProps<any>, 'location'>): React.ReactNode => {
    const finalInnerProps = { ...props, ...(innerProps || {}) }

    return (
      <Tracker {...props}>
        <Component {...finalInnerProps} />
      </Tracker>
    )
  }
}
