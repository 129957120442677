import ActionTypeKeys from 'actions/ActionTypeKeys'
import ActionTypes from 'actions/ActionTypes'
import { routerReducer } from 'react-router-redux'
import { combineReducers, Reducer } from 'redux'
import { applicationReducer } from './application/applicationReducer'
import { contractDisplayConfigReducer } from './contractDisplayConfig/reducer'
import { contractFilterParamsReducer } from './contractFilterParams/contractFilterReducer'
import { contractFlowReducer } from './contractFlow/contractFlowReducer'
import { contractSearchReducer, warrantySearchReducer } from './contractSearch/contractSearchInitialState'
import { contractSettlementReducer } from './contractSettlement/contractSettlementReducer'
import { initialState, IRootState } from './initialState'
import { outageReducer } from './outageReducer'

// @TODO: Use "as any" before, which is wrong - will "{}" work properly?
const appReducer: Reducer<IRootState> = combineReducers<IRootState>({
  application: applicationReducer,
  router: routerReducer,
  contractFlow: contractFlowReducer,
  contractSearch: contractSearchReducer,
  outage: outageReducer,
  contractSettlement: contractSettlementReducer,
  warrantySearch: warrantySearchReducer,
  contractFilterParams: contractFilterParamsReducer,
  contractDisplayConfig: contractDisplayConfigReducer,
})

const rootReducer = (state: IRootState | undefined = initialState, action: ActionTypes): IRootState => {
  if (action.type === ActionTypeKeys.STORE_RESET) {
    state = { ...initialState }
  }

  return appReducer(state, action)
}

export default rootReducer
