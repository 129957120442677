import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints, palette, typography }: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      height: spacing(6),
      ...theme.palette.gradients.secondary,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [breakpoints.up('sm')]: {
        padding: spacing(3),
        height: spacing(12),
      },
    },
    brand: {
      display: 'flex',
      flexDirection: 'column',
    },
    brandVisual: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(1),
    },
    logo: {
      height: 32,
    },
    options: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    company: {
      textTransform: 'uppercase',
    },
    contact: {
      fontWeight: typography.fontWeightLight,
      lineHeight: 1,
    },
    text: {
      color: palette.primary.contrastText,
    },
    email: {
      marginRight: spacing(2),
    },
    link: {
      '&, &:link, &:visited, &:hover, &:active': {
        color: 'white',
        textDecoration: 'none',
      },
    },
    menuIcon: {
      color: palette.primary.contrastText,
      fontSize: 26,
    },
    companyName: {
      color: 'white',
    },
    arrowBack: {
      color: 'white',
      marginRight: spacing(2),
    },
    paddingTop: {
      marginTop: '63px',
      height: '120px',
    },
    header: {
      height: '115px',
    },
    justGOLogo: {
      display: 'block',
      width: '250px',
      [breakpoints.down('xs')]: {
        width: '150px',
      },
    },
  })
