import { Card, CardContent } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatPhone } from '@omnicar/sam-format'
import {
  IContactPersonRequest,
  IContractProviderInfo,
  IContractProviderResponse,
  PaymentGateway,
} from '@omnicar/sam-types'
import { getContractProviderDetails } from 'api/api'
import classNames from 'classnames'
import React from 'react'
import { AppContext } from '../../../../store/appContext'
import { getProvider } from '../../../../utils/localStorage'

interface IOwnProps {
  className?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    subtitle: {
      marginBottom: spacing(2),
    },
    content: {
      display: 'flex',
      fontSize: spacing(1.75),
      color: '#111',
      margin: '-6px',
      padding: '6px',
    },
    column: {
      width: '50%',
    },
    pair: {
      display: 'flex',
    },
    cell: {
      width: '50%',
    },
  })

interface IState {
  paymentGateways: PaymentGateway[]
  fragusContactPersonName: string
  contactPerson: IContactPersonRequest | undefined
  webCalcEnabled: boolean | null
  isAllowContractsWithoutVehiclePriceModel: boolean | null
}

class SuperAdminProviderInfo extends React.Component<TProps, IState> {
  state: IState = {
    paymentGateways: [],
    fragusContactPersonName: '',
    contactPerson: undefined,
    webCalcEnabled: null,
    isAllowContractsWithoutVehiclePriceModel: null,
  }

  componentDidMount = async () => {
    const providerInfo: IContractProviderInfo | undefined = getProvider()
    const response: any = await getContractProviderDetails(providerInfo!.providerId)

    // console.log('getContractProviderDetails')
    // console.log(response?.data)

    if (response?.data) {
      const providerResponse: IContractProviderResponse = response?.data

      this.setState({
        paymentGateways: providerResponse.paymentGateways,
        fragusContactPersonName: providerResponse.fragusContactPersonName || '',
        contactPerson: providerResponse.contactPerson,
        webCalcEnabled: providerResponse.webCalcEnabled,
        isAllowContractsWithoutVehiclePriceModel: providerResponse.isAllowContractsWithoutVehiclePriceModel,
      })
    }
  }

  render(): React.ReactNode {
    const { classes, className } = this.props
    const {
      paymentGateways,
      fragusContactPersonName,
      contactPerson,
      webCalcEnabled,
      isAllowContractsWithoutVehiclePriceModel,
    } = this.state

    const paymentGatewayString: string = paymentGateways.join(', ')
    const providerContactPerson: string = `${contactPerson?.name || ''}, ${formatPhone(contactPerson?.phone || '')}, ${
      contactPerson?.email || ''
    }`

    return (
      <AppContext.Consumer>
        {({ isSuperAdmin, providerInfo }) =>
          isSuperAdmin && (
            <div className={className}>
              {/* <Typography className={classes.subtitle} variant="subtitle" tag="h4">
                {'Provider info/details (only visible to SuperAdmins)'}
              </Typography> */}
              <Card>
                <CardContent>
                  <div className={classNames('bg-for-only-superadmins', classes.content)}>
                    {/* --- LEFT double column. --------------------------------------------------------------- */}
                    <div className={classes.column}>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Active/Deactivate provider:</div>
                        <div className={classes.cell}>
                          {!providerInfo?.inactive ? 'Active provider' : 'DEACTIVATED provider'}
                        </div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Prices ONLY FROM V4-PricingTool:</div>
                        <div className={classes.cell}>{this.toYesNo(providerInfo?.isUsingV4PricingTool)}</div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Use V4PT for SIGNING only:</div>
                        <div className={classes.cell}>{this.toYesNo(providerInfo?.isUseV4PTOnlyForSigning)}</div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Webshop/Webcalc enabled:</div>
                        <div className={classes.cell}>{this.toYesNo(webCalcEnabled)}</div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Dealer logo enabled:</div>
                        <div className={classes.cell}>{this.toYesNo(providerInfo?.isShowLogoOnWeb)}</div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Require (price)-model (on non-V4PT contracts):</div>
                        <div className={classes.cell}>{this.toYesNo(!isAllowContractsWithoutVehiclePriceModel)}</div>
                      </div>
                    </div>
                    {/* ---------------------------------------------------------------------------------------- */}

                    {/* --- RIGHT double column. --------------------------------------------------------------- */}
                    <div className={classes.column}>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Provider ID (internal):</div>
                        <div className={classes.cell}>{providerInfo!.providerId}</div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Provider payment gateways:</div>
                        <div className={classes.cell}>{paymentGatewayString}</div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Fragus contact person:</div>
                        <div className={classes.cell}>{fragusContactPersonName || '-'}</div>
                      </div>
                      <div className={classes.pair}>
                        <div className={classes.cell}>Dealer contact person:</div>
                        <div className={classes.cell}>{providerContactPerson}</div>
                      </div>
                    </div>
                    {/* ---------------------------------------------------------------------------------------- */}
                  </div>
                </CardContent>
              </Card>
            </div>
          )
        }
      </AppContext.Consumer>
    )
  }

  private toYesNo = (value: boolean | undefined | null) => (!value ? 'No' : 'Yes')
}

export default withStyles(styles)(SuperAdminProviderInfo)
