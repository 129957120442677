import { CardContent } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IInternalContractNoteResponse as Note } from '@omnicar/sam-types/types/admin/contract/internalContractNotes'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Typography from 'components/Typography'
import React from 'react'
import { createPanelStyles } from 'theme'
import * as customTheme from 'theme/core/palette'
import { t } from 'translations/translationFunctions'
import { createInternalContractNote, getInternalContractNotes } from '../../../../../api/api'
import InternalNoteInputForm from './InternalNoteInput'
import InternalNoteList from './InternalNoteList/index'

interface IOwnProps {
  id: string
  isReadOnly?: boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    boundary: {
      margin: theme.spacing(2),
    },
    fadedText: {
      color: customTheme.palette.text.light,
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  notes: Note[]
}

class InternalNote extends React.Component<TProps, IState> {
  public state: IState = {
    notes: [],
  }

  public componentDidMount() {
    this.initInternalContractNotes()
  }

  public componentDidUpdate(oldProps: TProps) {
    if (oldProps.id !== this.props.id) {
      this.initInternalContractNotes()
    }
  }

  public render() {
    const { classes, id, isReadOnly } = this.props
    const { notes } = this.state
    const maxNoteLenght = 200
    const maxNrOfAttachmentFiles = 10

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{t('Notes')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card>
            <CardContent className={classes.boundary}>
              {!notes.length && isReadOnly && (
                <Typography className={classes.fadedText}>{t('This contract has no notes yet')}</Typography>
              )}
              {!isReadOnly && (
                <InternalNoteInputForm
                  onSubmit={this.createNewNote}
                  cancelDisabled={true}
                  label={`${t('Add a new internal note for contract')}`}
                  initialNote={{
                    internalContractNoteId: -1,
                    note: '',
                    date: new Date(),
                    writtenByUser: undefined,
                    contractPrettyIdentifier: undefined,
                    writtenByCurrentUser: undefined,
                    attachments: [],
                  }}
                  disabled={false}
                  maxNoteLenght={maxNoteLenght}
                  maxNrOfAttachmentFiles={maxNrOfAttachmentFiles}
                  displayAttachments={true}
                  editing={false}
                />
              )}
              <InternalNoteList
                notes={notes}
                isReadOnly={isReadOnly}
                maxNoteLenght={maxNoteLenght}
                maxNrOfAttachmentFiles={maxNrOfAttachmentFiles}
                prettyIdentifier={id}
                onDeleteNote={this.handleDeleteNote}
                onUpdateNote={this.handleNoteUpdate}
              />
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private initInternalContractNotes = async () => {
    const { id } = this.props
    const response = await getInternalContractNotes(id)
    if (response && response.data) {
      this.setState({ notes: response.data })
    }
  }

  private handleDeleteNote = (noteId: number) => {
    const notes = this.state.notes.filter((n) => n.internalContractNoteId !== noteId)
    this.setState({ notes: notes })
  }

  private createNewNote = async (newNote: Note, attachments?: File[]) => {
    const { id } = this.props
    const formData: FormData = new FormData()
    if (attachments) {
      for (const a of attachments) {
        formData.append('attachments', a)
      }
    }
    formData.append('note', newNote.note)

    const res = await createInternalContractNote(id, formData)
    if (res && res.ok) {
      const data = await res.json<Note>()
      if (data) {
        // Add the new note to the start of the array
        const newNotes = [data].concat(this.state.notes)
        this.setState({ notes: newNotes })
      }
      return true
    }

    return false
  }

  private handleNoteUpdate = (updatedNote: Note) => {
    const notes = Object.assign(this.state.notes, {})
    const noteIndex = notes.findIndex((n) => n.internalContractNoteId === updatedNote.internalContractNoteId)
    if (noteIndex >= 0) {
      notes[noteIndex] = updatedNote
      this.setState({ notes })
    }
  }
}

export default withStyles(styles)(InternalNote)
