import React from 'react'
import { t } from 'translations/translationFunctions'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { createDialogStyles } from 'theme'
import { ISetPaymentMethodResponse } from '@omnicar/sam-types'

/**
 * @param textProcessing Optional text during processing.
 * @param textProcessing Optional text on succeeded.
 */
interface IOwnProps {
  isOpen: boolean
  onPreviewContract: () => void
  paymentStatus: ISetPaymentMethodResponse['status']
  textProcessing?: string
  textSucceeded?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) => createDialogStyles(theme, {})

const SuccessfulPaymentDialog: React.SFC<TProps> = ({
  isOpen,
  onPreviewContract,
  paymentStatus,
  classes,
  textProcessing,
  textSucceeded,
}) => (
  <Dialog open={isOpen} disableBackdropClick={true} disableEscapeKeyDown={true}>
    <DialogTitle className={classes.title}>
      {paymentStatus === 'succeeded' ? t('Congratulations') : t('Processing your purchase') + '...'}
    </DialogTitle>
    <DialogContent className={classes.content}>
      {paymentStatus === 'succeeded'
        ? textSucceeded || t('Your payment is accepted, the contract is now active')
        : textProcessing ||
          t('Your payment is accepted and is now being processed. Your Contract will be activated soon.')}
    </DialogContent>
    <DialogActions className={classes.actions}>
      {paymentStatus === 'succeeded' ? (
        <Button onClick={onPreviewContract} variant="outlined">
          {t('View your contract')}
        </Button>
      ) : (
        <CircularProgress size={32} color="secondary" />
      )}
    </DialogActions>
  </Dialog>
)

export default withStyles(styles)(SuccessfulPaymentDialog)
