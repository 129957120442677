import React from 'react'
import classNames from 'classnames'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'

interface IProps {
  className?: string
}

type TProps = IProps & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    root: {},
  })

const PanelContent: React.SFC<TProps> = ({ classes, className, children }) => (
  <main className={classNames(classes.root, className)}>{children && children}</main>
)

export default withStyles(styles)(PanelContent)
