import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'
import rowWithIDImg from './RowWithID.png'
import rowWithoutIDImg from './RowWithoutID.png'
import { t } from 'translations/translationFunctions'

const styles = () =>
  createStyles({
    uploadImg: {
      maxWidth: '550px',
    },
  })

type TProps = WithStyles<typeof styles>

class BlacklistFileUploadInstructions extends React.Component<TProps> {
  render() {
    const { classes } = this.props

    return (
      <>
        <p>
          <Typography variant="subtitle1">{t('Add a vehicle to the blacklist')}</Typography>
          <img
            className={classes.uploadImg}
            src={rowWithoutIDImg}
            alt="Vehicle blacklist row with values for brand, model, fuel type"
          />
          <Typography>{t('All rows missing an id will be added to the blacklist.')}</Typography>
        </p>
        <p>
          <Typography variant="subtitle1">{t('Updating an existing blacklisted vehicle record')}</Typography>
          <img
            className={classes.uploadImg}
            src={rowWithIDImg}
            alt="Vehicle blacklist row with values for id, brand, model, fuel type"
          />
          <Typography>{t('A row with an id will update the record with that id.')}</Typography>
        </p>
        <p>
          <Typography variant="subtitle1">{t('Constraints and limitations')}</Typography>
          <Typography>
            {t(
              'Duplicate records are not allowed (ie a record with the same brand, model and fueltype as another record).',
            )}
          </Typography>
          <Typography>{t('An upload file cannot have more than 1000 rows.')}</Typography>
          <Typography>
            {t('A record cannot be deleted using the upload function, please use the JustGO UI for this.')}
          </Typography>
        </p>
      </>
    )
  }
}

export default withStyles(styles)(BlacklistFileUploadInstructions)
