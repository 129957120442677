import React from 'react'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import { CalendarToday as CalendarIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { getDateFormatInCapitalLetters } from '@omnicar/sam-format'
import { DateRangePicker as DRP, DateRangePickerShape, FocusedInputShape } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment, { Moment } from 'moment'
import { compose } from 'recompose'
import './index.css'
import 'moment/locale/sv'
import 'moment/locale/nb'
import 'moment/locale/da'
import 'moment/locale/fi'
import 'moment/locale/en-gb'
import { t } from 'translations/translationFunctions'
import { AppContext } from 'store/appContext'
import { localeToLanguage } from 'utils/locale'

export interface IOnDatesChangeArgs {
  startDate: Moment | null
  endDate: Moment | null
}

interface IOwnProps {
  minDate: Moment | null
  maxDate: Moment | null
  isDisabled?: boolean
}

type OwnProps = IOwnProps &
  Pick<DateRangePickerShape, 'displayFormat' | 'startDate' | 'endDate' | 'startDateId' | 'endDateId' | 'onDatesChange'>

type TProps = OwnProps & WithStyles<typeof styles>

interface IState extends IOnDatesChangeArgs {
  focusedInput: FocusedInputShape | null
}

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    calendarIcon: {
      fontSize: 16,
    },
  })

class DateRangePicker extends React.Component<TProps, IState> {
  static contextType = AppContext
  constructor(props: TProps) {
    super(props)

    this.state = this.getInitialStateFromProps(props)
  }

  componentDidMount() {
    // This translates the things inside the date range picker such as the name of the months.
    // Since the date range picker is a built in react component and not one we created outselves, language have to be set differently for this one.
    moment.locale(localeToLanguage(this.context.locale))
    // This is to make sure that the date range picker has the correct dates when the component is mounted.
    this.setState(this.getInitialStateFromProps())
  }

  public componentDidUpdate(oldProps: TProps) {
    moment.locale(localeToLanguage(this.context.locale))
    const { startDate, endDate } = this.props
    if ((oldProps.startDate && startDate === null) || (oldProps.endDate && endDate === null)) {
      // This would mean that the filters have been cleared
      this.setState(this.getInitialStateFromProps())
      return
    }

    if (oldProps.startDate !== startDate || oldProps.endDate !== endDate) {
      // This would mean that the filters have been changed
      this.setState({ startDate, endDate })
    }
  }

  public render() {
    const { classes, startDateId, endDateId, isDisabled } = this.props
    const { focusedInput, startDate, endDate } = this.state
    const startDatePlaceholderText = t('Start date')
    const endDatePlaceholderText = t('End date')

    return (
      <div className={classes.root} data-e2e={this.props['data-e2e']}>
        <IconButton
          onClick={this.handleIconClick}
          data-e2e="DateRangepicker__clear-btn"
          disabled={isDisabled && isDisabled}
        >
          <CalendarIcon className={classes.calendarIcon} />
        </IconButton>
        <DRP
          // tslint:disable-next-line jsx-no-lambda
          onDatesChange={({ startDate, endDate }) => this.handleChangeDates({ startDate, endDate })}
          onFocusChange={this.handleFocusChange}
          isOutsideRange={this.isOutsideRange}
          initialVisibleMonth={this.initialVisibleMonth}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          startDateId={startDateId}
          endDateId={endDateId}
          displayFormat={getDateFormatInCapitalLetters()}
          noBorder={true}
          customArrowIcon={<>&mdash;</>}
          showClearDates={true}
          minimumNights={0}
          disabled={isDisabled && isDisabled}
          startDatePlaceholderText={startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
        />
      </div>
    )
  }

  private getInitialStateFromProps = ({ startDate, endDate }: TProps = this.props) => ({
    startDate,
    endDate,
    focusedInput: null,
  })

  private handleChangeDates = ({ startDate, endDate }: IOnDatesChangeArgs) => {
    this.setState({ startDate, endDate })
    this.props.onDatesChange({ startDate, endDate })
  }

  /**
   * When changing date picker in focus
   */
  private handleFocusChange = (focusedInput: FocusedInputShape | null) => {
    this.setState({ focusedInput })
  }

  /**
   * When clicking the calendar icon
   */
  private handleIconClick = () => {
    this.setState({ focusedInput: 'startDate' })
  }

  /**
   * Is Date within allowed selectable range
   */
  private isOutsideRange = (date: Moment) => {
    const { minDate, maxDate } = this.props
    if ((minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))) {
      return true
    }

    return false
  }

  /**
   * What date to show when changing input focus
   */
  private initialVisibleMonth = (): Moment => {
    const { focusedInput, startDate, endDate } = this.state
    const { minDate, maxDate } = this.props

    let initialVisible

    if (focusedInput === 'endDate') {
      initialVisible = endDate || maxDate
    } else {
      initialVisible = startDate || minDate
    }

    if (!initialVisible) {
      initialVisible = moment()
    }

    return initialVisible
  }
}

export default compose<TProps, OwnProps>(withStyles(styles))(DateRangePicker)
