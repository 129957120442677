import React, { StatelessComponent, ChangeEvent } from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import {
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  InputLabel,
  Input,
  Chip,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'

import styles from './styles'

interface IOwnProps {
  classes?: any
  helperText: string
  label: string
  name: string
  onChange: (name: string, value: any) => void
  options: any[]
  selectedValues: number[]
  placeholder?: string
}
type TProps = IOwnProps & WithStyles<typeof styles>

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MultipleSelect: StatelessComponent<TProps> = ({
  classes,
  selectedValues,
  name,
  placeholder,
  options,
  onChange,
  helperText,
  label,
}) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    let value: string | number | string[] | number[] = e.target.value

    if (value[value.length - 1] === undefined) {
      value = []
    }

    onChange(name, value)
  }

  const renderValue = (selected: any) => {
    const renderValues: any = selected || []
    if (placeholder && renderValues.length === 0) {
      return <em className={classes.placeholder}>{placeholder}</em>
    }
    return (
      <div className={classes.chips}>
        {renderValues.map((val: string) => {
          const title = getTitleFromId(val)
          return (
            <Chip
              classes={{ root: classes.chip, label: classes.chipLabel }}
              key={`filterDetailsField-chip-${name}-${val}`}
              label={title}
              className={classes.chip}
            />
          )
        })}
      </div>
    )
  }

  const getTitleFromId = (id: string) => {
    const opt = options.find((opt) => opt.id === +id)
    return (opt && opt.title) || undefined
  }

  return (
    <FormControl className={classes.formControl} data-e2e={`filterDetailsField-${name}`}>
      <InputLabel shrink={placeholder ? true : undefined} htmlFor={`filterDetailsField-${name}`}>
        {label}
      </InputLabel>
      <Select
        displayEmpty={true}
        multiple={true}
        value={selectedValues}
        onChange={(e: ChangeEvent<any>) => {
          handleChange(e)
        }}
        name={name}
        input={<Input id={`filterDetailsField-${name}`} />}
        renderValue={renderValue}
        MenuProps={MenuProps}
        classes={{ selectMenu: classes.selectMenu }}
      >
        {placeholder && (
          <MenuItem selected={true}>
            <em> {placeholder}</em>
          </MenuItem>
        )}
        {options.map((option) => {
          return (
            <MenuItem
              key={`filter-details-field-${name}-${option.id}`}
              value={option.id}
              data-e2e={`filterField-chip-${name}`}
            >
              <Checkbox checked={selectedValues && selectedValues.indexOf(option.id) > -1} />
              <ListItemText primary={option.title} />
            </MenuItem>
          )
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default compose<TProps, IOwnProps>(withStyles(styles))(MultipleSelect)
