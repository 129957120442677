import React from 'react'
import classNames from 'classnames'
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles'

interface IOwnProps {
  className?: string
  dense?: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(2),
    },
    dense: {
      marginBottom: spacing(3),
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

const LayoutBlock: React.SFC<TProps> = ({ className, children, classes, dense, ...props }) => (
  <div className={classNames(classes.root, className, dense && classes.dense)} {...props}>
    {children && children}
  </div>
)

export default withStyles(styles)(LayoutBlock)
