import { Button } from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import Typography from 'components/Typography'
import React, { Component } from 'react'
import { t } from 'translations/translationFunctions'
import { reportErrors } from 'utils/sentry'

interface IState {
  error?: any
}

class MainBoundary extends Component<{}, IState> {
  public state = {
    error: undefined,
  }

  public componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error })
    if (reportErrors) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
        Sentry.captureException(error)
      })
    }
  }

  public render() {
    if (this.state.error) {
      console.error(this.state.error)
      return (
        <>
          <Typography variant="header" tag="h2">
            {t('Something went wrong. Please try to refresh the application.')}
          </Typography>
          <Button href="/">{t('Refresh')}</Button>
        </>
      )
    } else {
      return this.props.children
    }
  }
}

export default MainBoundary
