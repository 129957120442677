import React from 'react'
import classNames from 'classnames'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'

interface IProps {
  className?: string
}

type TProps = IProps & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  })

const PanelActions: React.SFC<TProps> = ({ classes, className, children }) => (
  <div className={classNames(classes.root, className)}>{children && children}</div>
)

export default withStyles(styles)(PanelActions)
