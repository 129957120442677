import React from 'react'
import { Card, CardContent, CardHeader, IconButton, Typography } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import classnames from 'classnames'
import SetlementActionFilters from './ActionFilters'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { IRootState } from 'reducers/initialState'
import { selectNumberOfFilters } from 'selectors/contractSettlement'
import { t } from 'translations/translationFunctions'

interface IOwnProps {
  actionListLength: number
  children: React.ReactNode
  isSettled: boolean
}

interface IStateProps {
  expanded: boolean
  setExpanded: (e: boolean) => boolean
}

interface IReduxState {
  numberOfFilters: number
}

type TProps = IOwnProps & WithStyles<typeof styles> & IReduxState & IStateProps
const styles = ({ palette, typography, spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: palette.background.paper,
    },
    actionsContainer: {
      paddingTop: spacing(5),
    },
    cardHeader: {
      backgroundColor: '#DFE5F0',
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      position: 'relative',
      zIndex: 2,
    },
    cardContent: {
      overflowY: 'scroll',
      minHeight: spacing(25),
      maxHeight: spacing(65),
      backgroundColor: '#DFE5F0',
      '&::-webkit-scrollbar': {
        width: spacing(1),
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        borderRadius: spacing(0.75),
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: spacing(0.75),
        backgroundColor: '#C2CEE2',
      },
    },
    emptyCardContent: {
      height: 'unset',
      overflow: 'unset',
    },
    emptyActionsListText: {
      fontSize: spacing(2.5),
      color: palette.grey[400],
    },
    iconButton: {
      color: palette.primary[500],
    },
    alertIcon: {
      position: 'absolute',
      top: spacing(1.5),
      right: spacing(0.75),
      borderRadius: '100%',
      color: palette.background.default,
      backgroundColor: palette.secondary.main,
      width: spacing(2.25),
      height: spacing(2.25),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: typography.fontWeightMedium,
    },
  })

const ActionFiltersWrapper: React.SFC<TProps> = ({
  actionListLength,
  children,
  expanded,
  setExpanded,
  classes,
  numberOfFilters,
  isSettled,
}) => {
  return (
    <div className={isSettled ? '' : classes.actionsContainer}>
      <Card>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            action: classes.iconButton,
          }}
          action={
            <>
              <IconButton onClick={() => setExpanded(!expanded)} disabled={!actionListLength && numberOfFilters === 0}>
                <FilterList />
              </IconButton>
              {numberOfFilters > 0 && (
                <Typography className={classes.alertIcon} variant="caption">
                  {numberOfFilters}
                </Typography>
              )}
            </>
          }
          title={t('Settlement regulation actions')}
        />
        <SetlementActionFilters expanded={expanded} numberOfFilters={numberOfFilters} />
        <div className={classnames(classes.cardContent, !actionListLength ? classes.emptyCardContent : '')}>
          <CardContent>{children}</CardContent>
        </div>
      </Card>
    </div>
  )
}

const mapState = (state: IRootState) => ({
  numberOfFilters: selectNumberOfFilters(state),
})

export default compose<TProps, IOwnProps>(
  withStyles(styles),
  withState('expanded', 'setExpanded', false),
  connect(mapState),
)(ActionFiltersWrapper)
