import React, { ChangeEvent } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Close as CloseIcon, AttachFile as AttachFileIcon } from '@material-ui/icons'
import Typography from 'components/Typography'
import { getSigmaSql, uploadPayoutPaymentConnections } from 'api/api'
import notify from 'utils/notify/notify'

interface IOwnProps {
  open: boolean
  onClose: () => void
}

interface IState {
  txt: string
  isFetching: boolean
  isUploading: boolean
  uploadFile: File | undefined
}

const initialState: IState = {
  txt: 'Fetching...',
  isFetching: true,
  isUploading: false,
  uploadFile: undefined,
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    allowOverflow: {
      overflow: 'visible',
    },
    content: {
      minWidth: spacing(62),
    },
    button: {
      marginTop: spacing(3),
      marginRight: spacing(1),
    },
    icon: {
      marginRight: spacing(1),
      fontSize: 16,
    },
    textarea: {
      fontFamily: 'Courier New',
    },
  })

class SigmaSqlDownload extends React.Component<TProps, IState> {
  public state: IState = { ...initialState }

  public componentDidUpdate(prevProps: IOwnProps) {
    // reset state when dialog is closed
    if (!prevProps.open && this.props.open) {
      this.setState({ ...initialState })
      this.fetchSigmaSql()
    }
  }

  public render() {
    const { open, onClose, classes } = this.props
    const { txt, isUploading } = this.state

    const title = `Sigma SQL - Copy SQL to https://dashboard.stripe.com/sigma/queries (Make sure you are logged in to right Stripe account).
    After the report is run download the result file with button down to the right (behind cookie acceptance blah blah bar)`

    return (
      <Dialog
        open={open}
        onClose={onClose}
        data-e2e="Dialog--download-economy-report"
        classes={{ paper: classes.allowOverflow }}
      >
        <DialogTitle>
          <Typography variant="subtitle">{title}</Typography>
        </DialogTitle>
        <DialogContent className={classes.content} classes={{ root: classes.allowOverflow }}>
          <Button onClick={this.fetchSigmaSql} variant="outlined">
            Fetch SQL
          </Button>
          <textarea
            rows={20}
            value={txt}
            readOnly={true}
            style={{ fontFamily: 'Courier New', width: '100%', overflow: 'scroll' }}
          />
          <br />
          <div>
            After you have downloaded the result file from Sigma upload it to SAM
            <input
              id="file-picker"
              accept=".csv"
              type="file"
              hidden={true}
              onChange={this.handleFileSelect}
              data-e2e="DialogWorkshopOperationsUpload__file-upload-input"
            />
            <label htmlFor="file-picker">
              <Button variant="outlined" component="span" disabled={isUploading}>
                <AttachFileIcon className={classes.icon} />
                {'Upload'}
              </Button>
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            <CloseIcon className={classes.icon} />
            {'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public fetchSigmaSql = async () => {
    this.setState({ isFetching: true })
    try {
      const result = await getSigmaSql()
      const txt = result.data
      const errmessage = (result.errorData && result.errorData.message) || ''
      this.setState({ txt: txt || `Noget fejlde ${errmessage}`, isFetching: false })
    } catch (err) {
      this.setState({ txt: `Noget fejlde ${err.message || err}`, isFetching: false })
    }
  }

  private handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.currentTarget.files
    const uploadFile = fileList && fileList.length && fileList[0]
    if (uploadFile) {
      await this.uploadFile(uploadFile)
    }
  }

  private uploadFile = async (uploadFile: File) => {
    try {
      this.setState({ isUploading: true })
      const formData: FormData = new FormData()
      formData.append('payoutpayment', uploadFile)
      const res = await uploadPayoutPaymentConnections(formData)
      if (res && res.status >= 200 && res.status <= 299) {
        this.fetchSigmaSql()
        notify.success({ message: 'File was uploaded' })
      }
    } catch (error) {
      notify.error()
      console.error(error.message || error)
    } finally {
      this.setState({ isUploading: false })
    }
  }
}

export default withStyles(styles)(SigmaSqlDownload)
