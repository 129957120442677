import Slider from '@material-ui/core/Slider'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import React, { ChangeEvent } from 'react'
import { IContractFlowMileageDuration } from 'types/contractFlow'

export type TMarks = IMark[]
interface IMark {
  value: number
  label: string
}

interface IOwnProps {
  onChange: (value: number) => void
  minLabel: string
  maxLabel: string
  selectedLabel: string
  convertedLabel: string
  data: IContractFlowMileageDuration
  marks: TMarks
  disabled: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  selectedabel: {
    height: '42px',
    display: 'flex',
    'justify-content': 'space-between',
  },
  convertedLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightMedium,
    'text-align': 'center',
    opacity: 0.8,
  },
  slider: {
    padding: `20px 0`,
    margin: `0 0`,
    '& .MuiSlider-track': {
      height: 3,
      opacity: 0.95,
      marginTop: 7,
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      marginTop: 7,
      backgroundColor: 'currentColor',
    },
    '& .MuiSlider-thumb': {
      opacity: 0.95,
      marginTop: 0,
      marginBottom: 0,
      height: 17,
      width: 17,
      backgroundColor: '#eee',
      border: '3px solid currentColor',
      '&:focus, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: '0px 0px 0px 8px rgba(34, 87, 89, 0.16)',
      },
      '&:hover': {
        boxShadow: '0px 0px 0px 8px rgba(34, 87, 89, 0.25)',
      },
    },
    '& .MuiSlider-mark': {
      opacity: 0.7,
      marginTop: 4,
      backgroundColor: 'currentColor',
      height: 10,
      width: 3,
      '&.MuiSlider-markActive': {
        opacity: 0.9,
      },
    },
    '& .MuiSlider-markLabel': {
      opacity: 0.95,
      paddingTop: 10,
      fontSize: '0.875rem',
    },
    '& .MuiSlider-markLabelActive': {},
  },
  pointer: {
    cursor: 'pointer',
  },
})

const ContractFlowDurationMileageSlider: React.SFC<TProps> = (props: TProps) => {
  const { minLabel, maxLabel, selectedLabel, convertedLabel, data, onChange, disabled, classes, marks } = props

  return (
    <main data-e2e={'ContractFlowDurationMileageSlider'}>
      <div className={classes.selectedabel}>
        <span
          className={classes.pointer}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={(e: ChangeEvent<{}>) => {
            onChange(data.min)
          }}
          data-e2e={'ContractFlowDurationMileageSlider__minLabel'}
        >
          {minLabel}
        </span>
        <Typography className={classes.title} variant="display1">
          {selectedLabel}
        </Typography>
        <span
          className={classes.pointer}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={(e: ChangeEvent<{}>) => {
            onChange(data.max)
          }}
          data-e2e={'ContractFlowDurationMileageSlider__maxLabel'}
        >
          {maxLabel}
        </span>
      </div>
      <Slider
        className={classes.slider}
        disabled={disabled}
        value={data.value}
        min={data.min}
        max={data.max}
        step={data.step}
        marks={marks || []}
        valueLabelDisplay="auto"
        // tslint:disable-next-line:jsx-no-lambda
        onChange={(event: ChangeEvent<any>, newValue: number | number[]) => {
          onChange(newValue as number /*+ data.min*/)
        }}
        data-e2e={'ContractFlowDurationMileageSlider__slider'}
      />
      <div className={classes.convertedLabel}>{convertedLabel}</div>
    </main>
  )
}

export default withStyles(styles)(ContractFlowDurationMileageSlider)
