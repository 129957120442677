import {
  BottomNavigation,
  BottomNavigationAction,
  Hidden,
  Tab,
  Tabs,
  WithStyles,
  withStyles,
  withWidth,
} from '@material-ui/core'
import {
  CreditCard as CreditCardIcon,
  Description as DescriptionIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons'
import {
  ContractState,
  IApiOtherWithContracts,
  IApiVehicleContract,
  IApiVehicleWithContracts,
  IInvoice,
} from '@omnicar/sam-types'
import ActionTypes from 'actions/ActionTypes'
import { IContractDisplayConfigSetHiddenVAT, setHiddenVAT } from 'actions/contractDisplayConfigActions'
import { getPaymentFailureReasonForCustomer, getStatusOfHiddenPriceForCustomer } from 'api/api'
import classNames from 'classnames'
import CustomerContractDetailsTabsCreditCards from 'containers/App/CustomerPortal/Tabs/CreditCards'
import CustomerContractDetailsTabsInvoices from 'containers/App/CustomerPortal/Tabs/Invoices'
import CustomerContractDetailsTabsMain from 'containers/App/CustomerPortal/Tabs/Main'
import CustomerContractDetailsTabsNotSelected from 'containers/App/CustomerPortal/Tabs/NotSelected'
import ExpirationDialog from 'pages/admin/ContractDetailsPage/ExpirationDialog'
import React, { ChangeEvent, Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router'
import { compose } from 'recompose'
import { creditCardPath, customerContractDetailsPath, customerInvoicesTabPath } from 'routes/paths'
import { t } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'
import { checkWhetherHideVAT } from 'utils/contract'
import { mapVehicleToGeneric } from 'utils/genericProduct'
import browserHistory from 'utils/history'
import CustomerContractDetailsHeader from './Header'
import InfoBar from './InfoBar'
import CustomerProductContractDetailsHeader from './ProductHeader'
import styles from './styles'

interface IOwnProps {
  contract?: IApiVehicleContract
  vehicle?: IApiVehicleWithContracts | IApiOtherWithContracts
  providerCountry?: string
}

interface IRouteProps {
  prettyIdentifier: string
}

interface IReduxDispatchProps {
  setHiddenVAT: (hiddenVAT: boolean) => IContractDisplayConfigSetHiddenVAT
}

type TProps = RouteComponentProps<IRouteProps> & IOwnProps & WithStyles<typeof styles> & IReduxDispatchProps

interface IState {
  selectedTab: number
  isRetrySuccessful: boolean
  invoices?: IInvoice[]
  failedPaymentReason?: string
  hiddenPriceSection: boolean
}

class CustomerContractDetails extends Component<TProps, IState> {
  public state = {
    selectedTab: 0,
    isRetrySuccessful: false,
    invoices: undefined,
    failedPaymentReason: undefined,
    hiddenPriceSection: false,
  }
  public componentDidMount() {
    // needed if we are landing on a specific tab page
    const selectedTab = this.getActiveTab() || 0
    this.setState({ selectedTab })

    this.getFailedPaymentReason()
    this.updateHiddenVAT()
    this.setPriceSectionVisibility()
  }

  public componentDidUpdate(prevProps: IOwnProps) {
    const { contract: prevContract } = prevProps
    const { contract } = this.props
    // reset selected tab when selecting new contract
    if (prevContract?.serviceContractId !== contract?.serviceContractId) {
      this.setState({ selectedTab: 0 })
      this.getFailedPaymentReason()
      this.updateHiddenVAT()
      this.setPriceSectionVisibility()
    }
  }

  public render() {
    const { classes, vehicle, contract } = this.props
    const { selectedTab, isRetrySuccessful, failedPaymentReason } = this.state

    // should tab/nav be disabled
    const navEnabled = contract && contract.contractState >= 200 && contract.paymentGateway === 'Stripe'

    const mapping = vehicle && mapVehicleToGeneric(vehicle)
    const isVehicle = vehicle && 'vin' in vehicle

    return (
      <div className={classNames(classes.root, { [classes.nothingSelected]: !contract })}>
        {contract && (
          <>
            <div className={classNames(classes.component, classes.header)}>
              {isVehicle ? (
                <CustomerContractDetailsHeader
                  vehicle={mapping && mapping.vehicle}
                  typeIcon={mapping && mapping.icon}
                />
              ) : (
                <CustomerProductContractDetailsHeader
                  vehicle={mapping && mapping.vehicle}
                  typeIcon={mapping && mapping.icon}
                />
              )}
            </div>
            <InfoBar
              contract={contract}
              isRetrySuccessful={isRetrySuccessful}
              failedPaymentReason={failedPaymentReason}
            />
          </>
        )}

        {!contract ? (
          <CustomerContractDetailsTabsNotSelected />
        ) : (
          /** only show tabs if contract is not in offer state and payment gateway is Stripe */
          <Hidden xsDown={true}>
            <div className={classes.component}>
              <Tabs
                className={classes.tabs}
                value={selectedTab}
                onChange={this.handleTabChange}
                fullWidth={true}
                data-e2e={'CustomerContractDetails__tabs'}
                indicatorColor="primary"
              >
                <Tab
                  label={t('Details')}
                  icon={<DescriptionIcon />}
                  data-e2e={'CustomerContractDetails__tabs-details'}
                />
                <Tab
                  label={t('Payment')}
                  icon={<CreditCardIcon />}
                  data-e2e={'CustomerContractDetails__tabs-payments'}
                  disabled={!navEnabled}
                />
                <Tab
                  label={t('Payments')}
                  icon={<FileCopyIcon />}
                  data-e2e={'CustomerContractDetails__tabs-invoices'}
                  disabled={!navEnabled}
                />
              </Tabs>
            </div>
          </Hidden>
        )}
        <div className={classes.component}>
          <Switch>
            <Route path={customerContractDetailsPath()} exact children={this.renderTabs()} />
            <Route path={creditCardPath()} exact children={this.renderTabs()} />
            <Route path={customerInvoicesTabPath()} exact children={this.renderTabs()} />
          </Switch>
        </div>

        <Hidden smUp={true}>
          <BottomNavigation
            value={selectedTab}
            onChange={this.handleTabChange}
            className={classes.bottomNav}
            showLabels={true}
          >
            <BottomNavigationAction
              label={<span className={classes.bottomNavLabel}>{t('Contract details')}</span>}
              value={0}
              icon={<DescriptionIcon />}
            />
            <BottomNavigationAction
              label={<span className={classes.bottomNavLabel}>{t('Credit card')}</span>}
              value={1}
              icon={<CreditCardIcon />}
              disabled={!navEnabled}
              className={!navEnabled ? classes.bottomNavItemDisabled : ''}
            />
            <BottomNavigationAction
              label={<span className={classes.bottomNavLabel}>{t('Invoice details')}</span>}
              value={2}
              icon={<FileCopyIcon />}
              disabled={!navEnabled}
              className={!navEnabled ? classes.bottomNavItemDisabled : ''}
            />
          </BottomNavigation>
        </Hidden>
      </div>
    )
  }

  private updateHiddenVAT = () => {
    const { contract, setHiddenVAT, providerCountry } = this.props
    setHiddenVAT(
      checkWhetherHideVAT(
        contract?.contractProductType === 'Warranty',
        providerCountry,
        contract?.contractStartDate ? new Date(contract.contractStartDate) : undefined,
      ),
    )
  }

  private renderTabs = () => {
    const { contract, vehicle } = this.props
    const { selectedTab, invoices, hiddenPriceSection } = this.state
    const navEnabled = contract && contract.contractState >= 200 && contract.paymentGateway === 'Stripe'
    const mapping = vehicle && mapVehicleToGeneric(vehicle)

    return (
      <>
        <CustomerContractDetailsTabsMain
          contract={contract}
          vehicle={mapping && mapping.vehicle}
          hidden={selectedTab !== 0}
          hiddenPriceSection={hiddenPriceSection}
        />
        <CustomerContractDetailsTabsCreditCards
          contract={contract}
          hidden={selectedTab !== 1}
          disabled={!navEnabled}
          onTabRedirect={this.handleInvoiceTabRedirect}
          invoices={invoices}
        />
        <CustomerContractDetailsTabsInvoices
          contract={contract}
          hidden={selectedTab !== 2}
          disabled={!navEnabled}
          onSuccessfulRetry={this.handleSuccessfulRetry}
          onInvoicesSave={this.handleInvoicesSave}
        />
        {contract && (
          <ExpirationDialog prettyIdentifier={contract.prettyIdentifier} contractState={contract.contractState} />
        )}
      </>
    )
  }

  private mappedTabPaths = (pi: string) => ({
    0: customerContractDetailsPath(pi),
    1: creditCardPath(pi),
    2: customerInvoicesTabPath(pi),
  })

  private getActiveTab = () => {
    const { pathname } = this.props.location
    const { prettyIdentifier } = this.props.match.params

    const tabsPaths = this.mappedTabPaths(prettyIdentifier)
    const currentTab = Number(Object.keys(tabsPaths).find((key) => tabsPaths[key] === pathname))

    return currentTab
  }

  private handleInvoiceTabRedirect = () => {
    this.setState({ selectedTab: 2 })
    browserHistory.replace(this.getTabPath(2))
  }

  private handleTabChange = (e: ChangeEvent<{}>, selectedTab: number) => {
    trackEvent('Customer portal', 'Select tab', `${selectedTab}`)
    this.setState({ selectedTab })
    browserHistory.replace(this.getTabPath(selectedTab))
  }

  private getTabPath = (tab: number): string => {
    const { prettyIdentifier } = this.props.contract!
    const tabPaths = this.mappedTabPaths(prettyIdentifier)

    return tabPaths[tab] || customerContractDetailsPath(prettyIdentifier)
  }

  private handleSuccessfulRetry = () => this.setState({ isRetrySuccessful: true })
  private handleInvoicesSave = (invoices?: IInvoice[]) => this.setState({ invoices })

  private getFailedPaymentReason = async (): Promise<void> => {
    const contractState = this.props.contract?.contractState
    const prettyIdentifier = this.props.contract?.prettyIdentifier

    if (prettyIdentifier && contractState === ContractState.Suspended) {
      const response = await getPaymentFailureReasonForCustomer(prettyIdentifier)

      response.statusCode === 200 && response.data && this.setState({ failedPaymentReason: response.data })
    }

    return
  }

  private setPriceSectionVisibility = async () => {
    const { contract } = this.props

    if (contract) {
      const { statusCode, data } = await getStatusOfHiddenPriceForCustomer(contract.prettyIdentifier)
      this.setState({ hiddenPriceSection: statusCode === 200 && !!data })
    }
  }
}

const mapDispatch = (dispatch: Dispatch<ActionTypes>) => ({
  setHiddenVAT: (hiddenVAT: boolean) => dispatch(setHiddenVAT(hiddenVAT)),
})

export default compose<TProps, IOwnProps>(
  withRouter,
  withStyles(styles),
  withWidth(),
  connect(null, mapDispatch),
)(CustomerContractDetails)
