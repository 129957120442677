import { IsoLocale } from '@omnicar/sam-types'
import { theme } from 'theme'

// Types & Interfaces
export interface IDisplayConfigs {
  showRownoCol: boolean
  showLinks: boolean
  locale: IsoLocale | undefined // undefined === use SAM default format (= the providers locale)
  localeFormats: { [key in IsoLocale]?: string }
}

// Sizes
export const reportSize = {
  pageMarginRight: 30, // Place for possible scroll bar and a little bit more
  pageMarginBottom: 75, // Enough place for not some outer page component adding scroll bar for unknown reason
  initialBlockSize: 456, // Just some random value, that will be overwritten anyway
}

// Colors for different needs... don't know if we should keep these, or if we have some standard
// in the program somewhere that we should use instead

export const reportColor = {
  headerBgColor: '#DCDCDC',
  evenRowBgColor: '#ffffff',
  oddRowBgColor: '#E8F6FC', // Soft cyan.
  emptyRowBgColor: '#eeeeee',
  labelColor: theme.palette.primary[500],
  buttonColor: theme.palette.secondary[700],
  buttonFgColor: 'white',
  resizableBlockBorderColor: 'red',
  scrollableBlockBorderColor: 'blue',
  tableCellBorderColor: 'gray',
}

export const tableCellBorderStyle = {
  width: 1,
  style: 'solid',
  color: reportColor.tableCellBorderColor,
}

export const borders = {
  resizableBlockBorder: `1px solid ${reportColor.resizableBlockBorderColor}`,
  scrollableBlockBorder: `1px solid ${reportColor.scrollableBlockBorderColor}`,
  tableCellBorder: `1px solid ${reportColor.tableCellBorderColor}`,
}

export const defaultLocales: { [key in IsoLocale]?: string } = {
  // To be fetched from server.. just as backup
  'sv-SE': 'YYYY-MM-DD',
  'da-DK': 'D.M.YYYY',
}
