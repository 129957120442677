import { ThunkDispatch } from '../types/thunk'
import { bindActionCreators } from 'redux'
import { Hash, Resource, TAttachment, TSettlementAttachments } from 'types/settlement'

export const bindDispatch = (actionCreators: any) => (dispatch: ThunkDispatch) =>
  bindActionCreators(actionCreators, dispatch)

export const arrToHash = <T>(arr: Resource<T>[]): Hash<T> =>
  arr.reduce(
    (xs, x) => ({
      ...xs,
      [x.id]: x,
    }),
    {},
  )
export const hashToArr = <T>(xs: Hash<T>): Resource<T>[] => Object.values(xs)

const nextFileId = (files: TAttachment[]) => {
  const maxId = files.reduce((maxId, file) => Math.max(file.id, maxId), -1)
  return maxId + 1
}

export const modifyFiles = (existingFiles: TSettlementAttachments, files: FileList): TSettlementAttachments => {
  const lastMaxId = nextFileId(hashToArr(existingFiles))
  return Array.from(files).reduce((acc, file, i) => {
    const id = lastMaxId + i + 1
    const url = URL.createObjectURL(file) // since it's not the best idea to store non-serializable items in the store
    return {
      ...acc,
      [id]: { id, name: file.name, url, type: file.type },
    }
  }, {})
}
