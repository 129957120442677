import React, { Component } from 'react'
import Typography from 'components/Typography'
import styles from './styles'
import { WithStyles, withStyles, Collapse, Hidden, Button, withWidth } from '@material-ui/core'
import {
  DirectionsCar as CarIcon,
  LocalGasStation as FuelIcon,
  CalendarToday as CalendarIcon,
} from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import { t, tFuelType } from 'translations/translationFunctions'
import { compose } from 'recompose'
import { IGenericTypeWithContracts } from 'utils/genericProduct'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

interface IOwnProps {
  vehicle: IGenericTypeWithContracts | undefined
  typeIcon: React.ComponentType<SvgIconProps> | undefined
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  vehicleDetailsCollapsed: boolean
}

class CustomerContractDetailsHeader extends Component<TProps, IState> {
  public state = {
    vehicleDetailsCollapsed: false,
  }
  public render() {
    const { classes, vehicle } = this.props
    const { vehicleDetailsCollapsed } = this.state

    const Icon: React.ComponentType<SvgIconProps> = this.props.typeIcon || CarIcon

    return (
      <div>
        {vehicle && (
          <React.Fragment>
            <Typography className={classes.regNumber} variant="header" tag="h2">
              {vehicle.identifier1}
            </Typography>

            {/** Mobile */}
            <Hidden smUp={true}>
              <div>
                <div>
                  <Icon className={classes.vehicleDetailIcon} />
                  <span>{`${vehicle.brandName} ${vehicle.modelName}`}</span>
                </div>
                <Collapse in={vehicleDetailsCollapsed}>
                  <div>
                    <Typography className={`${classes.vehicleDetailIcon} ${classes.vin}`} variant="body1" tag="span">
                      {t(vehicle.identifierTitle2)}
                    </Typography>
                    <span>{vehicle.identifier2}</span>
                  </div>
                  {!!vehicle.fuelTypeName && (
                    <div>
                      <FuelIcon className={classes.vehicleDetailIcon} />
                      <span>{vehicle.fuelTypeName}</span>
                    </div>
                  )}
                  <div>
                    <CalendarIcon className={classes.vehicleDetailIcon} />
                    <span>{formatDate(vehicle.registrationDate)}</span>
                  </div>
                </Collapse>

                <Button className={classes.btnShowMore} onClick={this.toggleCollapseVehicleDetails}>
                  <Typography className={classes.btnShowMoreLabel} variant="button">
                    {vehicleDetailsCollapsed ? t('Hide details') : t('Show details')}
                  </Typography>
                </Button>
              </div>
            </Hidden>

            {/** Desktop */}
            <Hidden xsDown={true}>
              <div className={classes.details}>
                <div className={classes.vehicleDetail}>
                  <div>
                    <Icon className={classes.vehicleDetailIcon} />
                    <span>{`${vehicle.brandName} ${vehicle.modelName}`}</span>
                  </div>

                  <div>
                    <Typography className={`${classes.vehicleDetailIcon} ${classes.vin}`} variant="body1" tag="span">
                      {t(vehicle.identifierTitle2)}
                    </Typography>
                    <span>{vehicle.identifier2}</span>
                  </div>
                </div>
                <div className={classes.vehicleDetail}>
                  {!!vehicle.fuelTypeName && (
                    <div>
                      <FuelIcon className={classes.vehicleDetailIcon} />
                      <span>{tFuelType(vehicle.fuelTypeName)}</span>
                    </div>
                  )}
                  <div>
                    <CalendarIcon className={classes.vehicleDetailIcon} />
                    <span>{formatDate(vehicle.registrationDate)}</span>
                  </div>
                </div>
              </div>
            </Hidden>
          </React.Fragment>
        )}
      </div>
    )
  }
  private toggleCollapseVehicleDetails = () => {
    this.setState({ vehicleDetailsCollapsed: !this.state.vehicleDetailsCollapsed })
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles), withWidth())(CustomerContractDetailsHeader)
