import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 800,
    },
    header: {
      borderBottom: `1px solid ${theme.palette.border.light}`,
      paddingBottom: spacing(2),
    },
    component: {
      marginTop: spacing(2),

      paddingLeft: spacing(3),
      paddingRight: spacing(3),

      [breakpoints.up('md')]: {
        paddingLeft: spacing(10),
      },
    },
    componentTitle: {
      marginLeft: spacing(3),
    },
    tabs: {
      marginTop: spacing(2),
      marginBottom: spacing(6),
    },
    bottomNav: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: `1px solid ${theme.palette.border.light}`,
    },
    bottomNavItemDisabled: {
      color: theme.palette.text.lighter,
    },
    bottomNavLabel: {
      display: 'inline-block',
      marginTop: 4,
    },
    nothingSelected: {
      textAlign: 'center',
    },
  })
