import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: '40%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      minHeight: spacing(6),
      [breakpoints.up('sm')]: {
        minHeight: spacing(8),
      },
    },
    actionBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(3),
    },
    btnIcon: {
      color: theme.palette.primary[500],
      marginRight: spacing(0.5),
    },
    btnCancel: {
      color: theme.palette.context.warning[500],
    },
    heading: {
      color: theme.palette.primary[500],
    },
    form: {
      padding: `0 ${spacing(3)}px ${spacing(3)}px`,
    },
    input: {
      display: 'block',
      marginTop: spacing(1),
    },
    hr: {
      width: '100%',
      borderColor: theme.palette.border.light[500],
      borderTopWidth: 0,
    },
  })
