import React, { ComponentType } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import {
  Button,
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { AddCircleOutline as AddIcon } from '@material-ui/icons'
import { t, tFuelType } from 'translations/translationFunctions'
import { FuelType, IContractProviderInfo } from '@omnicar/sam-types'
import { getProvider } from 'utils/localStorage'
import { stringMaxLength, stringNotEmpty } from 'utils/yup'
import * as Yup from 'yup'
import SpinnerButton from 'components/Mui/SpinnerButton'
import { createVehicleBlacklist, getFuelTypes } from 'api/api'
import BlacklistFileUpload from './BlacklistFileUpload'
import { LayoutActions, LayoutActionsLeft, LayoutActionsRight } from 'components/Mui/Layout'
import { TranslationKey } from 'translations/translationTypes'
import Typography from 'components/Typography'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import Dialog from '@material-ui/core/Dialog'
import SearchField from 'components/admin/Contract/Flow/Vehicle/Form/SearchField'
import classNames from 'classnames'

export interface IBlacklistedVehicle {
  id?: number
  brand: string
  model: string
  fuelType: string
}

export interface IBlacklistedVehicleForm {
  id?: number
  brand: string
  model: string
  fuelType: string
}

const initialValues: IBlacklistedVehicleForm = {
  brand: '',
  model: '',
  fuelType: '',
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    wrapper: {
      width: 'fit-content',
      padding: `0px 5px`,
      margin: spacing(1),
      display: 'flex',
    },
    formWrapper: {
      width: 'fit-content',
      padding: `0px 5px`,
      margin: spacing(1),
      display: 'flex',
    },
    submitButton: {
      margin: 'auto',
      height: '32px',
      padding: '0px 20px',
    },
    cancleButton: {
      margin: 'auto 10px',
    },
    icon: {
      marginRight: spacing(1),
      fontSize: 14,
    },
    field: {
      marginRight: '10px',
    },
  })

interface IProps {
  onCreated: () => void
  activeProvider: number
  saveButtonLabel: string
  saveButtonIcon?: ComponentType<SvgIconProps>
  handleSubmit?: (values: IBlacklistedVehicle) => Promise<boolean>
  onCancel?: () => void
  initialBlacklist?: IBlacklistedVehicleForm
  className?: string
  hideFileUpload?: true
  disableReset?: true
}

type TProps = IProps & WithStyles<typeof styles>

interface IState {
  uploading: boolean
  uploadFile: File | undefined
  isLoadingFile: boolean
  uploadDialogOpen: boolean
  fuelTypes: FuelType[]
  fuelType: FuelType
}

const fallbackFuelTypes = [
  {
    name: 'Petrol',
  },
  {
    name: 'Diesel',
  },
  {
    name: 'Electric',
  },
  {
    name: 'Cng',
  },
]

class BlacklistInput extends React.Component<TProps, IState> {
  state: Readonly<IState> = {
    uploading: false,
    uploadFile: undefined,
    isLoadingFile: false,
    uploadDialogOpen: false,
    fuelTypes: [],
    fuelType: { name: '' },
  }

  validation = Yup.object().shape({
    brand: Yup.string()
      .required('Required field')
      .test(stringNotEmpty('Cannot be empty'))
      .test({ name: 'not*', message: 'Invalid value', test: (value) => value !== '*' })
      .test(stringMaxLength(225, `Cannot be longer than ${225}`)),
    model: Yup.string()
      .required('Required field')
      .test(stringNotEmpty('Cannot be empty'))
      .test(stringMaxLength(225, `Cannot be longer than ${225}`)),
    fuelType: Yup.string()
      .required('Required field')
      .test(stringNotEmpty('Cannot be empty'))
      .test(stringMaxLength(225, `Cannot be longer than ${225}`)),
  })

  componentDidMount(): void {
    const provider: IContractProviderInfo | undefined = getProvider()
    const isoCountry: any = !(provider && provider.country) ? null : (provider.country as string)
    this.fetchAllFuelTypes(isoCountry)
  }

  render() {
    const {
      className,
      classes,
      initialBlacklist,
      onCancel,
      activeProvider,
      hideFileUpload,
      saveButtonLabel,
      saveButtonIcon: SaveButtonIcon,
    } = this.props
    const { uploading, uploadDialogOpen, isLoadingFile, fuelTypes } = this.state

    return (
      <div className={classNames(className)}>
        <LayoutActions>
          <LayoutActionsLeft>
            <Formik
              initialValues={initialBlacklist || initialValues}
              onSubmit={this.handleSubmit}
              validationSchema={this.validation}
            >
              {({ values, errors, touched, handleChange, handleBlur, dirty }) => {
                let inputCaption = null
                let mappedFuelTypeInput = values.fuelType
                for (const ft of fuelTypes) {
                  if (values.fuelType && values.fuelType.toLowerCase() === ft.name.toLowerCase()) {
                    const item: any = ft
                    inputCaption = !item.name ? '' : tFuelType(item.name)
                    mappedFuelTypeInput = inputCaption
                  }
                }

                const mappedFuelTypes = fuelTypes.map((item: FuelType) => ({
                  name: item.name,
                  id: item.id,
                  caption: tFuelType(item.name),
                }))

                return (
                  <Form className={classes.formWrapper}>
                    <FormControl margin="dense" className={classes.field}>
                      <TextField
                        name="brand"
                        color="primary"
                        label={t('Brand')}
                        fullWidth={false}
                        value={values.brand}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.brand && !!errors.brand}
                        helperText={!!touched.brand && !!errors.brand && t(errors.brand as TranslationKey)}
                      />
                    </FormControl>
                    <FormControl margin="dense" className={classes.field}>
                      <TextField
                        name="model"
                        color="primary"
                        label={t('Model')}
                        fullWidth={false}
                        value={values.model}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.model && !!errors.model}
                        helperText={!!touched.model && !!errors.model && t(errors.model as TranslationKey)}
                      />
                      <Typography variant="caption">{t('* means all models')}</Typography>
                    </FormControl>
                    <div className={classes.field}>
                      <SearchField
                        name={'fuelType'}
                        type={'fuelType'}
                        inputLabel={t('Fuel Type')}
                        // inputValue={fuelTypeInput}
                        inputValue={mappedFuelTypeInput}
                        inputCaption={inputCaption}
                        options={mappedFuelTypes}
                        onChange={(e: any) => this.handleFuelTypeChange(e, handleChange)}
                        onInputValueChange={(e: any) => this.fuelTypeInputChange(e, handleChange)}
                        // tslint:disable-next-line:jsx-no-lambda
                        itemToString={() => values.fuelType}
                        selectedItem={values.fuelType as any}
                        errorMessage={!!touched.fuelType && !!errors.fuelType && t(errors.model as TranslationKey)}
                        data-e2e={'BlacklistInputForm__fuelType'}
                        onBlur={handleBlur}
                        onClear={() => this.handleClearFuelType(handleChange)}
                      />
                      <Typography variant="caption">{t('* means all fuel types')}</Typography>
                    </div>
                    <SpinnerButton
                      className={classes.submitButton}
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={uploading || isLoadingFile || !dirty}
                      showSpinner={uploading}
                      aria-label={`edit ${1} action`}
                    >
                      <>
                        {saveButtonLabel ? (
                          <Typography>{saveButtonLabel}</Typography>
                        ) : (
                          <>{SaveButtonIcon ? <SaveButtonIcon /> : <AddIcon />}</>
                        )}
                      </>
                    </SpinnerButton>
                    {onCancel && (
                      <Button variant="outlined" className={classes.cancleButton} onClick={onCancel}>
                        {t('Cancel')}
                      </Button>
                    )}
                  </Form>
                )
              }}
            </Formik>
          </LayoutActionsLeft>
          {!hideFileUpload && (
            <LayoutActionsRight>
              <Button variant="contained" color="primary" onClick={this.handleUploadClicked}>
                {t('Upload')}
              </Button>
              <Dialog open={uploadDialogOpen} onClose={this.handleUploadClicked}>
                <DialogContent>
                  <BlacklistFileUpload
                    allowedFileTypes=".csv, .xls, .xlsx"
                    disabled={uploading}
                    activeProvider={activeProvider}
                    onUpload={this.handleFileUploaded}
                  />
                </DialogContent>
              </Dialog>
            </LayoutActionsRight>
          )}
        </LayoutActions>
      </div>
    )
  }

  /**
   * Fetch country specific Fuel-Types.
   *
   * @param isoCountry The country code is needed for country specific Fuel-Types.
   */
  private async fetchAllFuelTypes(isoCountry: string | null) {
    let fuelTypes: FuelType[] | null = null

    // Fuel Types.
    if (!isoCountry) {
      fuelTypes = fallbackFuelTypes // Use fallback fuel-types.
    } else {
      let response: any

      try {
        response = await getFuelTypes(isoCountry)
        fuelTypes = response.data
      } catch (e) {
        fuelTypes = fallbackFuelTypes // Use fallback fuel-types.
      }
    }

    if (fuelTypes) {
      this.setState({ fuelTypes })
    }
  }

  private handleFileUploaded = () => {
    this.setState({ uploadDialogOpen: false })
    this.props.onCreated()
  }

  private fuelTypeInputChange = (val: string, formValueChange: (fuelType: string) => any) => {
    const { fuelTypes } = this.state

    let matchedFuelType = { name: val, caption: val, id: 0 }

    for (const ft of fuelTypes) {
      if (val.toLowerCase() === ft.name.toLowerCase() || val.toLowerCase() === tFuelType(ft.name).toLowerCase()) {
        matchedFuelType = { name: ft.name, caption: tFuelType(ft.name), id: 0 }
        break
      }
    }

    this.handleFuelTypeChange(matchedFuelType, formValueChange)
  }

  private handleClearFuelType = (formValueChange: (fuelType: any) => any) => {
    formValueChange({ target: { type: 'fuelType', name: 'fuelType', value: '' } })
  }

  private handleFuelTypeChange = (newFuelType: any | null, formValueChange: (fuelType: any) => any) => {
    const { fuelTypes } = this.state
    let fuelType: any

    if (newFuelType === null) {
      fuelType = { name: '', caption: '' }
    } else {
      fuelType = {
        name: !newFuelType.name ? newFuelType.caption : newFuelType.name,
        caption: tFuelType(newFuelType.name),
        id: newFuelType.id,
      }
    }

    for (const item of fuelTypes) {
      const ftItem: any = item

      if (fuelType.caption && fuelType.caption.toLowerCase() === ftItem.caption && ftItem.caption.toLowerCase()) {
        fuelType = ftItem
      }
    }

    if (newFuelType) {
      this.setState(newFuelType, () => {
        formValueChange({ target: { type: 'fuelType', name: 'fuelType', value: fuelType.name } })
      })
    }
  }

  private handleUploadClicked = () => {
    this.setState({ uploadDialogOpen: !this.state.uploadDialogOpen })
  }

  private handleSubmit = async (
    values: IBlacklistedVehicleForm,
    formikHelpers: FormikHelpers<IBlacklistedVehicleForm>,
  ) => {
    const { activeProvider, disableReset } = this.props
    const { uploading } = this.state

    if (!uploading) {
      this.setState({ uploading: true })
      if (this.props.handleSubmit) {
        const res = await this.props.handleSubmit({ ...values })
        if (res) {
          !disableReset && formikHelpers.resetForm()
        }
      } else {
        const res = await createVehicleBlacklist({ ...values }, activeProvider)
        if (res.data) {
          !disableReset && formikHelpers.resetForm()
          this.props.onCreated()
          this.setState({ uploading: false })
          return
        }
      }
      this.setState({ uploading: false })
    }
  }
}

export default withStyles(styles)(BlacklistInput)
