import React from 'react'
import { isTranslationItemSet, t, translateItem } from 'translations/translationFunctions'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { createPanelStyles } from 'theme'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import {
  IAdminCustomer,
  VehicleAlongItsContracts,
  IAvailableFreeWarranty,
  IAvailableFreeWarrantyDurationPrice,
  IContractProviderInfo,
} from '@omnicar/sam-types'
import classNames from 'classnames'
import {
  freeWarrantyBorderColor,
  freeWarrantyButtonColor,
  freeWarrantyButtonColorHover,
  freeWarrantyTextColor,
} from './FreeWarrantyColors'
import SpinnerButton from 'components/Mui/SpinnerButton'
import { Add as AddWarrantyIcon } from '@material-ui/icons'
import FreeWarrantyComponent from './freeWarrantyComponent'
import { compose } from 'recompose'
import { TranslationItem } from 'translations/translationTypes'
import { getProvider } from 'utils/localStorage'

type DialogStatus = 'CLOSED' | 'ADD_WARRANTY'

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    button: {
      // marginLeft: theme.spacing.unit * 3,
    },
    buttonDealerPaidEnabled: {
      backgroundColor: freeWarrantyButtonColor,
      borderWidth: '0.2em',
      borderStyle: 'solid',
      color: freeWarrantyTextColor,
      borderColor: freeWarrantyBorderColor,
      '&:hover': {
        backgroundColor: freeWarrantyButtonColorHover,
        borderColor: freeWarrantyButtonColorHover,
      },
    },
    buttonDealerPaidDisabled: {
      backgroundColor: '#e0e0e0',
      color: '#000000',
      borderWidth: '0.2em',
      borderStyle: 'solid',
      borderColor: freeWarrantyBorderColor,
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  })

interface IOwnProps {
  contractUpdates: number
  customer: IAdminCustomer
  customerId?: number
  customerLocked: boolean
  customerUpdates: number
  customerValid: boolean
  onCustomerChange?: (value: IAdminCustomer, valid: boolean) => void
  onCustomerLockedChange?: (locked: boolean) => void
  onStartMileageChange?: (startMileage: number | undefined) => void
  onWarrantyCreated: () => void
  showCurrentMileageField: boolean
  startMileage: number | undefined
  style?: any
  vehicleAlongItsContracts: VehicleAlongItsContracts
  vehicleUpdates: number

  fetching: boolean
  onFreeWarrantyButtonClick: (w: IAvailableFreeWarranty, dp: IAvailableFreeWarrantyDurationPrice) => Promise<void>
  chosenWarranty: IAvailableFreeWarranty | null
  durationPrice: IAvailableFreeWarrantyDurationPrice | null
  availableWarranties: IAvailableFreeWarranty[]
  areAnyCustomerPricesSet: boolean
  getAvailableWarranties: () => Promise<void>
  statusText: TranslationItem
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  dialogOpen: DialogStatus
  minMilageDiffPerYear: number
  providerInfo: IContractProviderInfo | null
}

class FreeWarrantyButton extends React.Component<TProps, IState> {
  public state: IState = {
    dialogOpen: 'CLOSED',
    minMilageDiffPerYear: 0,
    providerInfo: null,
  }

  public componentDidMount(): void {
    const provider: IContractProviderInfo | undefined = getProvider()

    this.setState({ providerInfo: provider || null })
  }

  public render() {
    const { classes, statusText } = this.props
    const { dialogOpen, providerInfo } = this.state

    const isActive = !isTranslationItemSet(statusText)
    const isShowButton: boolean = !providerInfo?.isUsingV4PricingTool

    return (
      isShowButton && (
        <div>
          <div className={classes.flexContainer}>
            {isActive ? (
              <SpinnerButton
                className={classNames(classes.button, classes.buttonDealerPaidEnabled)}
                variant="contained"
                onClick={this.handleAddWarrantyClick}
                disabled={false}
                showSpinner={false}
                IconComponent={AddWarrantyIcon}
                IconClasses={classes.panelActionsButtonIcon}
              >
                {t('Dealer Paid Warranty')}
              </SpinnerButton>
            ) : (
              <Button
                className={classNames(classes.button, classes.buttonDealerPaidDisabled)}
                disabled={false}
                variant="contained"
              >
                {' '}
                {translateItem(statusText)}
              </Button>
            )}
          </div>
          <Dialog open={dialogOpen === 'ADD_WARRANTY'} maxWidth={false}>
            <DialogContent>
              <FreeWarrantyComponent
                onWarrantyCreated={this.props.onWarrantyCreated}
                vehicleAlongItsContracts={this.props.vehicleAlongItsContracts}
                startMileage={this.props.startMileage}
                customer={this.props.customer}
                customerId={this.props.customerId}
                style={this.props.style}
                onCustomerChange={this.props.onCustomerChange}
                onCustomerLockedChange={this.props.onCustomerLockedChange}
                customerLocked={this.props.customerLocked}
                customerValid={this.props.customerValid}
                vehicleUpdates={this.props.vehicleUpdates}
                customerUpdates={this.props.customerUpdates}
                contractUpdates={this.props.contractUpdates}
                onStartMileageChange={this.props.onStartMileageChange}
                showCurrentMileageField={false}
                doClose={this.closeDialog}
                fetching={this.props.fetching}
                onFreeWarrantyButtonClick={this.props.onFreeWarrantyButtonClick}
                chosenWarranty={this.props.chosenWarranty}
                durationPrice={this.props.durationPrice}
                availableWarranties={this.props.availableWarranties}
                areAnyCustomerPricesSet={this.props.areAnyCustomerPricesSet}
                getAvailableWarranties={this.props.getAvailableWarranties}
                statusText={statusText}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                {t('Cancel')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    )
  }

  private closeDialog = () => {
    this.setState({ dialogOpen: 'CLOSED' })
  }

  private handleAddWarrantyClick = () => {
    this.setState({ dialogOpen: 'ADD_WARRANTY' })
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(FreeWarrantyButton)
