import { withStyles, WithStyles } from '@material-ui/core/styles'
import { IApiOtherWithContracts, IApiVehicleContract, IApiVehicleWithContracts } from '@omnicar/sam-types'
import { IProviderWithContracts } from '@omnicar/sam-types/types/admin/contractProvider'
import Typography from 'components/Typography'
import React from 'react'
import { t } from 'translations/translationFunctions'
import ProviderCard from './ProviderCard'
import styles from './styles'
import CustomerPortalContractDetailsVehicleListVehicle from './Vehicle'

interface IProps extends WithStyles<typeof styles> {
  providers: IProviderWithContracts[]
  selectedContract: IApiVehicleWithContracts | IApiOtherWithContracts | undefined
  handleContractSelect: (
    selectedContract: IApiVehicleContract | undefined,
    selectedVehicle: IApiVehicleWithContracts | IApiOtherWithContracts | undefined,
  ) => void
}

const CustomerPortalContractDetailsVehicleList: React.SFC<IProps> = ({
  handleContractSelect,
  classes,
  providers,
  selectedContract,
}) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="header-light">
        {t('Contracts')}
      </Typography>
      <section>
        {providers &&
          providers.map((providerWithContracts, set) => (
            <ProviderCard key={`ContractDetails-${set}`} provider={providerWithContracts.provider}>
              {providerWithContracts.vehicles &&
                providerWithContracts.vehicles.map((vehicle: IApiVehicleWithContracts, i) => (
                  <CustomerPortalContractDetailsVehicleListVehicle
                    key={`ContractDetails-${set}_vehicle_${i}`}
                    vehicle={vehicle}
                    handleContractSelect={handleContractSelect}
                    selectedContract={selectedContract}
                  />
                ))}
              {providerWithContracts.products &&
                providerWithContracts.products.map((vehicle: IApiVehicleWithContracts | IApiOtherWithContracts, i) => (
                  <CustomerPortalContractDetailsVehicleListVehicle
                    key={`ContractDetails-${set}_product_${i}`}
                    vehicle={vehicle}
                    handleContractSelect={handleContractSelect}
                    selectedContract={selectedContract}
                  />
                ))}
            </ProviderCard>
          ))}
      </section>
    </div>
  )
}

export default withStyles(styles)(CustomerPortalContractDetailsVehicleList)
