import { ContractValueType } from '@omnicar/sam-types'
import { TranslationKey } from './translationTypes'

export interface IValueTypeTranslations {
  durationAndValueTotal: TranslationKey
  value: TranslationKey
  shortValue: TranslationKey
  startValue: TranslationKey
  valueAtExperation: TranslationKey
  valueAtExperationCalc: TranslationKey
  expectedValue: TranslationKey
  selectTotalValue: TranslationKey
}

const hoursTranslations: IValueTypeTranslations = {
  durationAndValueTotal: 'Duration and hours total',
  shortValue: 'h',
  value: 'Hours',
  startValue: 'Start Hours',
  valueAtExperation: 'Hours at expiration',
  valueAtExperationCalc: 'Hours at expiration (calculated)',
  expectedValue: 'Expected hours',
  selectTotalValue: 'Select hours (in total)',
}

const mileageTranslations: IValueTypeTranslations = {
  durationAndValueTotal: 'Duration and mileage total',
  shortValue: 'km',
  value: 'Mileage',
  startValue: 'Start Mileage',
  valueAtExperation: 'Mileage at expiration',
  valueAtExperationCalc: 'Mileage at expiration (calculated)',
  expectedValue: 'Expected mileage',
  selectTotalValue: 'Select kilometers (in total)',
}

const serviceTranslations: IValueTypeTranslations = {
  durationAndValueTotal: 'Duration and services total',
  value: 'Services',
  startValue: 'Start services',
  shortValue: 'services',
  valueAtExperation: '',
  valueAtExperationCalc: '',
  expectedValue: '',
  selectTotalValue: 'Select services (in total)',
}

export const valueTypeTranslations: Record<Exclude<ContractValueType, undefined>, IValueTypeTranslations> = {
  Hours: hoursTranslations,
  Mileage: mileageTranslations,
  Services: serviceTranslations,
}
