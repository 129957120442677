import Avatar from '@material-ui/core/Avatar'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { setLocale } from '@omnicar/sam-translate'
import { IsoLocale } from '@omnicar/sam-types'
import { getLocaleRecords, patchUserLocale } from 'api/api'
import React, { MouseEvent } from 'react'
import { AppContext } from 'store/appContext'
import { theme as customTheme } from 'theme'
import history from 'utils/history'
import { getPreferredUserLocale } from 'utils/locale'
import { getUserInfo, isUserSuperAdmin, isUseWhiteBGTheme } from 'utils/localStorage'
import { debugPrint } from 'utils/miscs'

const IS_DEBUG_PRINT_THIS_COMPONENT: boolean = false // The default is false.

interface ILanguageSelectorProps {}

type TProps = ILanguageSelectorProps & WithStyles<typeof styles>

const lightColor = '#888888' // Grey
const containerColor = () => (isUseWhiteBGTheme() ? lightColor : '#ccc')
const selectedColor = () =>
  isUseWhiteBGTheme() ? customTheme.paletteWhiteBG.background.regular : customTheme.palette.background.regular

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    mainContainer: {
      display: 'flex',
      marginTop: '3px',
    },
    container: {
      paddingLeft: '0.4em',
      paddingRight: '0.4em',
      marginRight: '0.1em',
      marginLeft: '0.1em',
      color: containerColor(),
      fontSize: '1rem;',
      textAlign: 'center',
      cursor: 'pointer',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    small: {
      width: spacing(2),
      height: spacing(2),
      fontSize: '0.875*rem;',
    },
    selected: {
      width: spacing(3),
      height: spacing(3),
      color: selectedColor(),
      fontSize: '1rem',
      fontWeight: 'bold',
      backgroundColor: isUseWhiteBGTheme() ? palette.primary.main : '#eee',
      cursor: 'default',
      marginRight: '-0.1em',
      marginLeft: '-0.1em',
    },
    large: {
      width: spacing(4),
      height: spacing(4),
    },
  })

interface IState {
  langsToRender: string[]
  selectedLang: null | string
  languageMap: null | any
  email: null | string
}

const languageMapDefault = {
  en: {
    locale: 'en',
    name: 'english',
    nameLocal: 'english',
  },
}

type TLocale = IsoLocale | ''

const LANGUAGE_CODE_DEFAULT: TLocale = 'en'

class LanguageSelector extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    const userInfo = getUserInfo()

    this.state = {
      langsToRender: [LANGUAGE_CODE_DEFAULT],
      selectedLang: null,
      languageMap: null,
      email: !userInfo ? null : userInfo.email,
    }

    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, '\n-------------------------------------------')
    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'constructor(): email = ' + userInfo?.email)
  }

  public async componentDidMount() {
    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'componentDidMount()')

    await this.fetchAndInitMaps() // Note: Important that this comes first!
    this.activatePreferredLangCode()
  }

  public componentDidUpdate(oldProps: Readonly<TProps>, oldState: Readonly<IState>): void {
    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'componentDidUpdate(): selectedLang = ' + this.state.selectedLang)
    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'componentDidUpdate(): languageMap:')
    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, this.state.languageMap)

    const { selectedLang } = this.state

    if (!selectedLang) {
      this.activatePreferredLangCode()
    }
  }

  public async activatePreferredLangCode() {
    const email = this.state.email
    const langsArray = this.state.langsToRender

    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'activatePreferredLangCode(): email = ' + email)

    if (email) {
      await getPreferredUserLocale(email, langsArray).then(async (userSelectedLocaleCode: string) => {
        if (userSelectedLocaleCode) {
          // const langCode = userSelectedLocaleCode.toLowerCase().substring(0, 2) // NOTE: For now support only one version of language (not en-GB or en-US).
          const langCode = userSelectedLocaleCode === '$$' ? '$$' : userSelectedLocaleCode.toLowerCase().substring(0, 2) // NOTE: For now support only one version of language (not en-GB or en-US).

          if (this.setSAMLanguage(langCode)) {
            debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'activatePreferredLangCode(): selectedLang = ' + langCode)
            this.setState({ selectedLang: langCode })
          }
        } else {
          debugPrint(
            IS_DEBUG_PRINT_THIS_COMPONENT,
            'activatePreferredLangCode(): selectedLang = ' + LANGUAGE_CODE_DEFAULT,
          )
          this.setState({ selectedLang: LANGUAGE_CODE_DEFAULT })
        }
      })
    }
  }

  public render() {
    const { classes } = this.props
    const { languageMap, selectedLang } = this.state

    const html: any = []
    const capitalize = this.capitalize

    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'render(): selectedLang = ' + selectedLang)
    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, 'render(): languageMap:')
    debugPrint(IS_DEBUG_PRINT_THIS_COMPONENT, languageMap)

    this.state.langsToRender.forEach((element) => {
      let tooltipText = ''
      if (languageMap) {
        if (!languageMap[element]) {
          console.warn('Warning: Locale language names missing: ' + element)
        } else {
          if (element === LANGUAGE_CODE_DEFAULT) {
            tooltipText = capitalize(languageMap[element].name)
          } else {
            tooltipText =
              capitalize(languageMap[element].nameLocal) + ' (' + capitalize(languageMap[element].name) + ')'
          }
        }
      }
      if (element === selectedLang) {
        html.push(
          <div key={element} className={classes.container}>
            <AppContext.Consumer>
              {({ changeLocaleHandler }) => (
                <Avatar
                  className={classes.selected}
                  title={tooltipText}
                  onClick={this.eventHandlerWithParameter(changeLocaleHandler)}
                >
                  {element.toUpperCase()}
                </Avatar>
              )}
            </AppContext.Consumer>
          </div>,
        )
      } else {
        html.push(
          <div key={element}>
            <AppContext.Consumer>
              {({ changeLocaleHandler }) => (
                <div
                  className={classes.container}
                  title={tooltipText}
                  onClick={this.eventHandlerWithParameter(changeLocaleHandler)}
                >
                  {element}
                </div>
              )}
            </AppContext.Consumer>
          </div>,
        )
      }
    })

    return <div className={classes.mainContainer}>{html}</div>
  }

  /**
   * Note:
   * Due to that Labda/arrow -functions are forbidden, this method takes
   * care of of the parameter that is sent with the event from onClick.
   */
  private eventHandlerWithParameter = (changeLocaleHandler: any) => (event: MouseEvent<HTMLInputElement>) => {
    const clickedLanguage = this.handleOnClick(event)

    if (clickedLanguage) {
      changeLocaleHandler(clickedLanguage) // Fire the language with the changeLocaleHandler that we got from appContext.
    }
  }

  private handleOnClick = (event: MouseEvent<HTMLInputElement>): string | null => {
    const clickedLanguage = event.currentTarget.innerText.toLowerCase()
    const languageMap = this.state.languageMap

    if (clickedLanguage !== this.state.selectedLang) {
      const newLocale = !languageMap ? LANGUAGE_CODE_DEFAULT : languageMap[clickedLanguage].locale
      this.setLocale(newLocale)

      try {
        this.setState({ selectedLang: clickedLanguage })
        history.push(history[history.length - 1])
        this.saveUserLangCode(clickedLanguage)
      } catch (error) {
        console.error(
          `Something went wrong changing language, clickedLanguage: ${clickedLanguage}, message: ${
            (error as any).message
          }`,
        )
      }

      return clickedLanguage
    }

    return null
  }

  /**
   * @returns True if succeeded.
   */
  // Method used for debugging.
  private setLocale = (locale: TLocale): boolean => {
    try {
      return setLocale(locale)
    } catch (e) {
      console.error('Failed setLocale(..)')
      console.error(e)
      return false
    }
  }

  private async saveUserLangCode(locale: string) {
    const email = this.state.email

    if (email && locale) {
      const response = await patchUserLocale(locale)
      if (!response.data || !response.data.isSuccess) {
        console.error('ERROR: Failed saving locale: ' + locale)
      }
    }
  }

  /**
   * Returns the string with first character in upper case and rest in lower case.
   */
  private capitalize = (str: string): string => {
    if (!str) {
      return ''
    } else {
      const len = str.length

      if (len) {
        const first = str.substr(0, 1)
        const body = str.substr(1)

        return first.toUpperCase() + body.toLowerCase()
      } else {
        return ''
      }
    }
  }

  private getLangFromLocale = (locale: string): string => {
    return locale.substr(0, 2)
  }

  private setSAMLanguage(localeCode: string): boolean {
    const languageMap = this.state.languageMap

    if (!languageMap) {
      return false
    } else {
      const langCode = localeCode.substr(0, 2).toLowerCase() // NOTE: For now support only one version of language.

      if (!languageMap[langCode]) {
        if (langCode === LANGUAGE_CODE_DEFAULT) {
          return this.setLocale('')
        } else {
          return false
        }
      } else {
        const newLocale = languageMap[langCode].locale
        return this.setLocale(newLocale)
      }
    }
  }

  private async fetchAndInitMaps() {
    const response: any = await getLocaleRecords()

    if (response && response.data) {
      const localeRecordsObj = JSON.parse(response.data)
      const localeRecords = localeRecordsObj.localeRecords

      this.setState({
        langsToRender: this.initLangsToRender(localeRecords),
        languageMap: this.initlanguageMaps(localeRecords),
      })
    }
  }

  private initLangsToRender(localeRecords: Map<string, any>) {
    const langsArray: string[] = []

    if (localeRecords) {
      // Array with languages to show.
      for (const key of Object.keys(localeRecords)) {
        if (key) {
          langsArray.push(this.getLangFromLocale(key))
        }
      }
    }

    if (!langsArray.includes(LANGUAGE_CODE_DEFAULT)) {
      langsArray.push(LANGUAGE_CODE_DEFAULT) // Force 'en' lang code if it doesn't exist already.
    }

    langsArray.sort()

    if (isUserSuperAdmin()) {
      langsArray.push('$$') // Will show the language '$$', that shows translation keys.
    }

    return langsArray
  }

  /**
   * Contructs and returns a map of language-code -> locale, name and localName.
   * @param localeRecords
   */
  private initlanguageMaps(localeRecords: Map<string, any>) {
    const languageMap = languageMapDefault

    if (localeRecords) {
      // Init hashmap with languages pointing to locales.
      for (const [key, value] of Object.entries(localeRecords)) {
        if (value.name && value.nameLocal) {
          languageMap[this.getLangFromLocale(key)] = {
            locale: key,
            name: value.name,
            nameLocal: value.nameLocal,
          }
        }
      }
    }

    languageMap['$$'] = {
      locale: '$$',
      name: 'Show translation keys',
      nameLocal: '',
    }

    return languageMap
  }
}

export default withStyles(styles)(LanguageSelector)
