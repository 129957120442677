import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'
import PageNavigationTab from './PageNavigationTab'

interface IOwnProps {
  active: string
  className?: string
  tabClassName?: string
  activeTabClassName?: string
  tabs: ITab[]
  onChange: (name: string) => void
}

export interface ITab<T = string> {
  title: string
  name: T
  e2e?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 2,
      height: 56,
    },
  })

class PageNavigation extends React.Component<TProps, never> {
  public render() {
    const { handleChange } = this
    const { classes, className, tabClassName, activeTabClassName, tabs, active } = this.props

    return (
      <nav className={classNames(classes.root, className)}>
        {tabs.map(({ title, name, e2e }, index) => (
          <PageNavigationTab
            className={classNames(tabClassName, active === name && activeTabClassName)}
            active={active === name}
            key={`PageNavigationTab-${name}-${index}`}
            title={title}
            name={name}
            onClick={handleChange}
            e2e={e2e}
          />
        ))}
      </nav>
    )
  }

  private handleChange = (name: string) => {
    this.props.onChange(name)
  }
}

export default withStyles(styles)(PageNavigation)
