import { Button, CircularProgress } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { Launch as LaunchIcon, LocalPrintshop as LocalPrintshopIcon } from '@material-ui/icons'
import { formatCurrency, formatDate, formatMileage } from '@omnicar/sam-format'
import { IContractCreationData, IGenericContractOptionResponse, Other, Vehicle } from '@omnicar/sam-types'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import classNames from 'classnames'
import LabelIncludingVat from 'components/Mui/Label/IncludingVat'
import Paper from 'components/Mui/Paper'
import Typography from 'components/Typography'
import React from 'react'
import { theme as customTheme } from 'theme'
import { t, tCurrency } from 'translations/translationFunctions'
import { valueTypeTranslations } from 'translations/ValueTypeTranslations'
import { IContractFlow } from 'types/contractFlow'
import { localFuelTypeName } from 'utils/vehicle'

interface IOwnProps {
  freeContract: boolean
  contract: IContractFlow
  creationData: IContractCreationData
  providerInfo: IContractProviderInfo | undefined
  printDisabled: boolean
  onPrint: () => Promise<string>
  contractUpdates: number
  locale: string
}

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  printActive: boolean
  printRef: string
  printClicked: boolean
  transaction: boolean
}

const getFormattedPrice = (price?: number): string | number => (price ? formatCurrency(price) : 0)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `0 0 0 ${theme.spacing(1)}`,
    },
    header: {
      padding: theme.spacing(2),
      paddingBottom: 0,
      display: 'flex',
      borderRadius: 'inherit',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      alignItems: 'center',
    },
    titleHeader: {
      color: customTheme.palette.text.light,
      padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    headerLeft: {
      flex: 1,
    },
    headerRight: {
      flex: 1,
      textAlign: 'right',
    },
    headerTitle: {
      color: customTheme.palette.text.light,
      marginBottom: theme.spacing(1),
    },
    section: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      '&:nth-child(even)': {
        backgroundColor: customTheme.palette.background.lighter,
      },
      '&:first-child': {
        paddingTop: 0,
      },
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
    sectionHeader: {
      marginBottom: theme.spacing(1),
    },
    sectionItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    sectionItemLabel: {
      marginRight: theme.spacing(2),
      color: customTheme.palette.text.light,
    },
    sectionItemValue: {
      whiteSpace: 'nowrap',
    },
    totalHeader: {
      color: theme.palette.primary[500],
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    totalHeaderLabel: {},
    totalHeaderValue: {
      whiteSpace: 'nowrap',
    },
    button: {
      borderRadius: 5,
      marginLeft: theme.spacing(1),
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
      fontSize: 14,
    },
    printButton: {
      borderRadius: 5,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary[50],
      },
    },
    freeContractTitle: {
      color: customTheme.palette.freeContract[800],
      fontWeight: theme.typography.fontWeightMedium,
    },
    titlePrintActive: {
      color: 'white',
    },
    transaction: {
      color: 'black',
      marginRight: theme.spacing(1),
    },
  })

class ContractFlowSummary extends React.Component<TProps, IState> {
  public state: IState = {
    printActive: false,
    printRef: '/',
    printClicked: false,
    transaction: false,
  }

  public componentDidUpdate(prevProps: TProps) {
    if (this.props.contractUpdates > prevProps.contractUpdates) {
      this.setState({
        printActive: false,
        printClicked: false,
        printRef: '/',
      })
    }
  }

  public render() {
    const { printActive, printRef, printClicked } = this.state
    const { classes, contract, freeContract, providerInfo, printDisabled } = this.props
    const { product, template, options, value, duration, payments, valueType, startValueType } = contract
    const buttonPrintProps = printActive ? { href: printRef, target: '_blank' } : { onClick: this.handlePrintOffer }
    const textCreating = `You are creating a free contract!`
    const textAdjusting = `You are adjusting a free contract!`

    const typeTranslations = valueTypeTranslations[valueType || '']

    const isProviderUsingV4PricingTool = providerInfo!.isUsingV4PricingTool || providerInfo!.isUseV4PTOnlyForSigning

    const isDealerPaid = contract.v4SupportedPaymentTypes.includes('DealerPaid')

    const isCustomerSubscription: boolean =
      contract.v4SupportedPaymentTypes.length === 0 || contract.v4SupportedPaymentTypes.includes('CustomerSubscription')

    const printChildren = printActive ? (
      <React.Fragment>
        <LaunchIcon className={classes.buttonIcon} />
        {t('PDF')}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {this.state.transaction ? (
          <CircularProgress className={classes.transaction} size={12} color="secondary" />
        ) : (
          <LocalPrintshopIcon className={classes.buttonIcon} />
        )}
        {t('Print Offer')}
      </React.Fragment>
    )

    const printTerms = (
      <React.Fragment>
        <LocalPrintshopIcon className={classes.buttonIcon} />
        {t('Print Conditions')}
      </React.Fragment>
    )

    const startValues = this.getStartValues()

    const isVehicle = 'regNumber' in product || 'vin' in product

    return (
      <Paper className={classes.root} data-e2e="ContractFlowSummary">
        <header className={classes.header}>
          <div className={classes.headerLeft}>
            <Typography variant="subtitle">{t('Contract Offer')}</Typography>
            {freeContract && (
              <Typography variant="caption" className={classes.freeContractTitle}>
                {t(contract.flowType === 'CREATE' ? textCreating : textAdjusting)}
              </Typography>
            )}
          </div>
          <div className={classes.headerRight}>
            {contract.template && (
              <Button
                className={classNames(classes.button)}
                variant="outlined"
                size="small"
                disabled={printDisabled}
                data-e2e={'ContractFlowPagePayment__button-printOffer'}
                href={contract.template.termsOfService.ref}
                target={'_blank'}
              >
                {printTerms}
              </Button>
            )}
            {!(isProviderUsingV4PricingTool && isDealerPaid) && (
              <Button
                className={classNames(classes.button, printClicked && classes.printButton)}
                variant="outlined"
                size="small"
                disabled={printDisabled}
                data-e2e={'ContractFlowPagePayment__button-printOffer'}
                {...buttonPrintProps}
              >
                {printChildren}
              </Button>
            )}
          </div>
        </header>
        <section className={classes.titleHeader}>
          <Typography className={classes.headerTitle} variant="title">
            {providerInfo && providerInfo.administrativeName}
          </Typography>
          <Typography className={classes.sectionItem} variant="body2">
            <span className={classes.sectionItemLabel}>{t('Start Date')}:</span>
            <span className={classes.sectionItemValue}>
              {contract && formatDate(contract.payments.contractStartDate)}
            </span>
          </Typography>
          <Typography className={classes.sectionItem} variant="body2">
            <span className={classes.sectionItemLabel}>{t('End Date')}:</span>
            <span className={classes.sectionItemValue}>
              {contract && formatDate(contract.payments.contractEndDate)}
            </span>
          </Typography>
        </section>
        <main>
          {product && (
            <section className={classes.section}>
              <Typography className={classes.sectionHeader} variant="display1">
                {product.brand.name} {product.model.name}
                {product.fuelType && product.fuelType.name && `, ${localFuelTypeName(product)}`}
              </Typography>
              <Typography className={classes.sectionItem} variant="body2">
                {isVehicle ? (
                  <>
                    <span className={classes.sectionItemLabel}>{t('Registration Number')}:</span>
                    <span className={classes.sectionItemValue}>{(product as Vehicle).regNumber}</span>
                  </>
                ) : (
                  <>
                    <span className={classes.sectionItemLabel}>{t('Serial Number')}:</span>
                    <span className={classes.sectionItemValue}>{(product as Other).serialNumber}</span>
                  </>
                )}
              </Typography>
              <Typography className={classes.sectionItem} variant="body2">
                {isVehicle ? (
                  <>
                    <span className={classes.sectionItemLabel}>{t('VIN')}:</span>
                    <span className={classes.sectionItemValue}>{(product as Vehicle).vin}</span>
                  </>
                ) : (
                  <>
                    <span className={classes.sectionItemLabel}>{t('Item Number')}:</span>
                    <span className={classes.sectionItemValue}>{(product as Other).itemNumber}</span>
                  </>
                )}
              </Typography>
              <Typography className={classes.sectionItem} variant="body2">
                <span className={classes.sectionItemLabel}>{t('Registration Date')}:</span>
                <span className={classes.sectionItemValue}>{product.regDate && formatDate(product.regDate)}</span>
              </Typography>
              {startValueType !== 'Services' && (
                <Typography className={classes.sectionItem} variant="body2">
                  <span className={classes.sectionItemLabel}>{startValues.title || t('Start conditions')}:</span>
                  <span className={classes.sectionItemValue}>{startValues.value || '-'}</span>
                </Typography>
              )}
            </section>
          )}
          {template && payments && (
            <section className={classes.section}>
              <Typography className={classes.sectionHeader} variant="display1">
                {template.description}
              </Typography>
              <Typography className={classes.sectionItem} variant="body2">
                {value ? (
                  <>
                    <span className={classes.sectionItemLabel}>{t(typeTranslations.durationAndValueTotal)}:</span>
                    <span className={classes.sectionItemValue}>{`${duration.value} ${t('mos.')} / ${
                      (value && value.value) || ''
                    } ${t(typeTranslations.shortValue)}`}</span>
                  </>
                ) : (
                  <>
                    <span className={classes.sectionItemLabel}>{t('Duration')}:</span>
                    <span className={classes.sectionItemValue}>{`${duration.value} ${t('mos.')}`}</span>
                  </>
                )}
              </Typography>
              {isProviderUsingV4PricingTool && isCustomerSubscription && (
                <Typography className={classes.sectionItem} variant="body2">
                  <span className={classes.sectionItemLabel}>{t('Price per month')}:</span>
                  <span className={classes.sectionItemValue}>{`${formatCurrency(
                    payments.amountPrPayment.priceInclVat,
                  )} ${tCurrency(payments.amountPrPayment.currency)}`}</span>
                </Typography>
              )}
            </section>
          )}
          {template && options.length > 0 && (
            <section className={classes.section}>
              <Typography className={classes.sectionHeader} variant="display1">
                {t('Options')}
              </Typography>
              {options.map((option: IGenericContractOptionResponse, i: number) => (
                <Typography key={i} className={classes.sectionItem} variant="body2">
                  <span className={classes.sectionItemLabel}>{option.description}</span>
                  <span className={classes.sectionItemValue}>{`${formatCurrency(option.price.priceInclVat)} ${tCurrency(
                    option.price.currency,
                  )}`}</span>
                </Typography>
              ))}
            </section>
          )}
          {payments && (
            <section className={classes.section}>
              {isProviderUsingV4PricingTool && isDealerPaid && (
                <Typography variant="subtitle">{t('Dealer payment')}</Typography>
              )}
              {isProviderUsingV4PricingTool && isDealerPaid && (
                <Typography className={classes.totalHeader} variant="header">
                  <span className={classes.totalHeaderLabel}>{t('Total price')}:</span>

                  <span className={classes.totalHeaderValue}>{`${getFormattedPrice(
                    payments.contractCost.priceInclVat,
                  )} ${payments.contractCost.currency}`}</span>
                </Typography>
              )}

              {isProviderUsingV4PricingTool && isCustomerSubscription && (
                <Typography variant="subtitle">{t('Customer subscription')}</Typography>
              )}
              {isCustomerSubscription && (
                <Typography className={classes.totalHeader} variant="header">
                  <span className={classes.totalHeaderLabel}>{t('Price per month')}:</span>
                  <span className={classes.totalHeaderValue}>{`${formatCurrency(
                    payments.amountPrPayment.priceInclVat,
                  )} ${payments.amountPrPayment.currency}`}</span>
                </Typography>
              )}
              {!(isProviderUsingV4PricingTool && isDealerPaid) && (
                <Typography className={classes.sectionItem} variant="body2">
                  <span className={classes.sectionItemLabel}>{t('Downpayment')}:</span>
                  <span className={classes.sectionItemValue}>{`${formatCurrency(payments.downpayment.priceInclVat)} ${
                    payments.downpayment.currency
                  }`}</span>
                </Typography>
              )}
              {!(isProviderUsingV4PricingTool && isDealerPaid) && (
                <Typography className={classes.sectionItem} variant="body2">
                  <span className={classes.sectionItemLabel}>{t('Payment date')}:</span>
                  <span className={classes.sectionItemValue}>{`${formatDate(payments.firstPaymentDate)}`}</span>
                </Typography>
              )}
              <LabelIncludingVat locale={this.props.locale} />
            </section>
          )}
        </main>
      </Paper>
    )
  }

  private getStartValues = (): { title: string | undefined; value: string | undefined } => {
    const { contract } = this.props
    const { startValue, startValueType } = contract
    let title: string | undefined
    let value: string | undefined

    if (startValue !== undefined && startValueType) {
      const translations = valueTypeTranslations[startValueType]
      title = t(translations.startValue)
      value = `${startValue} ${translations.shortValue}`
      if (startValueType === 'Mileage') {
        value = `${formatMileage(startValue)}`
      }
    } else if (contract.startMileage) {
      const translations = valueTypeTranslations['Mileage']
      title = t(translations.startValue)
      value = `${formatMileage(contract.startMileage)} ${translations.shortValue}`
    }

    return { title, value }
  }

  private handlePrintOffer = async () => {
    this.setState({ transaction: true })
    const request = await this.props.onPrint()
    if (request !== 'Error') {
      this.setState({ printActive: true, printRef: request, printClicked: true, transaction: false })
    } else {
      this.setState({ printActive: false, printRef: '/', printClicked: false, transaction: false })
    }
  }
}

export default withStyles(styles)(ContractFlowSummary)
