import { Theme, createStyles } from '@material-ui/core/styles'
import merge from 'lodash.merge'

export const createDialogStyles = ({ spacing, palette }: Theme, customStyles?: any) => {
  const styles = merge(
    {
      title: {
        borderTop: `${spacing(1.5)}px solid ${palette.secondary[500]}`,
        textAlign: 'center',
      },
      content: {
        minWidth: 580,
        textAlign: 'center',
      },
      actions: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: spacing(3),
      },
      button: {
        boxShadow: 'none',
      },
    },
    customStyles || {},
  )

  return createStyles(styles)
}
