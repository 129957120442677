import { IAdminCustomer, IAdminUserInfo, IsoLocale, UserRole, UserRoleRecord } from '@omnicar/sam-types'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import { IPasswordValidation } from 'pages/LogInPage/LogInPanel/Authenticate'

const tokenKey = 'aasfSDWE23r'
const authKey = 'auth'
const includeInactiveKey = 'inclInactive'
const tokenRefreshStartedKey = 'refreshStarted'

export const setTokenRefreshStarted = (time: number) => {
  localStorage.setItem(tokenRefreshStartedKey, time.toString())
  return time
}

export const getTokenRefreshStarted = () => {
  const time = Number(localStorage.getItem(tokenRefreshStartedKey))
  return isNaN(time) ? 0 : time
}

export const setToken = (token: string) => {
  localStorage.setItem(tokenKey, token)
  return token
}

export const getToken = () => {
  return localStorage.getItem(tokenKey)
}

export const clearToken = () => {
  localStorage.removeItem(tokenKey)
}

export const isLoggedIn = () => {
  return getToken() !== null
}

export const getIncludeInactive = () => {
  const includeInactive = localStorage.getItem(includeInactiveKey)

  return !includeInactive || JSON.parse(includeInactive)
}

export const setIncludeInactive = (includeInactive: boolean) => {
  localStorage.setItem(includeInactiveKey, JSON.stringify(includeInactive))
}

export const getAuth = (): IPasswordValidation | undefined => {
  const auth = localStorage.getItem(authKey)
  if (!auth) {
    return undefined
  }
  return JSON.parse(auth) as IPasswordValidation
}

export const setAuth = (auth: IPasswordValidation) => {
  localStorage.setItem(authKey, JSON.stringify(auth))
}

export const getCurrency = (): string => {
  const auth = getAuth()
  if (!auth || !auth.providerInfo || !auth.providerInfo.currency) {
    return ''
  }
  return auth.providerInfo.currency
}

export const getProvider = (): IContractProviderInfo | undefined => {
  const auth = getAuth()
  if (!auth || !auth.providerInfo) {
    return undefined
  }
  return auth.providerInfo
}

/**
 * @returns Return true if use_white_bg_on_web is set on the provider.
 */
export const isUseWhiteBGTheme = (): boolean => {
  const providerInfo = getProvider()
  if (!providerInfo || !providerInfo.isUseWhiteBGOnWeb) {
    return false
  }
  return providerInfo.isUseWhiteBGOnWeb
}

/**
 * @returns Return true if show_logo_on_web is set on the provider.
 */
export const isShowLogoOnWeb = (): boolean => {
  const providerInfo = getProvider()
  if (!providerInfo || !providerInfo.isShowLogoOnWeb) {
    return false
  }
  return providerInfo.isShowLogoOnWeb
}

export const isShowLogoOnWebNonWhiteBG = (): boolean => {
  return !isUseWhiteBGTheme() && isShowLogoOnWeb()
}

export const getWebTitle = (): string | undefined | null => {
  const providerInfo = getProvider()
  if (!providerInfo) {
    return undefined
  } else {
    return providerInfo.webTitle
  }
}

export const getProviderLocale = (): IsoLocale | undefined => {
  const auth = getAuth()
  if (!auth || !auth.providerInfo) {
    return undefined
  }
  return auth.providerInfo.locale
}

export const getProviderCountry = (): string | null => {
  return getCountry()
}

// @depricated Use getProviderCountry() instead.
export const getCountry = (): string | null => {
  const auth = getAuth()
  if (!auth || !auth.providerInfo || !auth.providerInfo.country) {
    return null
  }
  return auth.providerInfo.country
}

export const getRole = (): UserRole | undefined => {
  const auth = getAuth()
  if (!auth || !auth.role) {
    return undefined
  }
  return auth.role
}

export const getCustomerInfo = (): IAdminCustomer | undefined => {
  const auth = getAuth()
  if (!auth || !auth.customerInfo) {
    return undefined
  }
  return auth.customerInfo
}

export const setCustomerInfo = (customer: IAdminCustomer) => {
  const auth = getAuth()
  if (auth) {
    auth.customerInfo = { ...customer }
    setAuth(auth)
  }
}

export const getUserInfo = (): IAdminUserInfo | undefined => {
  const auth = getAuth()
  if (!auth || !auth.userInfo) {
    return undefined
  }
  return auth.userInfo
}

export const setUserInfo = (user: IAdminUserInfo) => {
  const auth = getAuth()
  if (auth) {
    auth.userInfo = { ...user }
    setAuth(auth)
  }
}

export const getRoles = (): UserRoleRecord[] => {
  const auth = getAuth()
  if (!auth || !auth.roles) {
    return []
  }
  return auth.roles
}

export const removeVAT = (amount: number) => {
  const provider = getProvider()
  if (!amount || !provider || !provider.vatPct) return undefined
  return (amount / (provider.vatPct + 1)).toFixed(2)
}

/**
 * Is the currently logged in user a SuperAdmin.
 */
export const isUserSuperAdmin = (): boolean => {
  const samAdminAuth = getAuth()
  return !!(samAdminAuth && samAdminAuth.isSuperAdmin)
}
