import { Theme, createStyles } from '@material-ui/core'

export default (theme: Theme) => {
  return createStyles({
    formControl: {
      marginBottom: theme.spacing(2),
      maxWidth: '100%',
      width: '100%',
    },
    selectMenu: {
      borderRight: '24px solid rgba(0,0,0,0)',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      height: theme.spacing(3),
    },
    chipLabel: {
      fontSize: theme.typography.button.fontSize,
      color: theme.palette.grey['700'],
      fontWeight: theme.typography.fontWeightMedium,
    },
    placeholder: {
      height: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
    },
  })
}
