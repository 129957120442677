import { Theme, createStyles } from '@material-ui/core'

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(3),
    },
    vehicleIcon: {
      marginRight: spacing(1),
      fontSize: 20,
    },
    cardContent: {
      paddingTop: spacing(2),
      paddingLeft: 0,
      paddingRight: 0,

      '&:last-child': {
        paddingBottom: 0,
      },
    },
  })
