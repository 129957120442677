import moment from 'moment'
import { ContractFlowActivePanel, ContractFlowActiveStep, IContractFlow } from 'types/contractFlow'
import { getCurrency } from 'utils/localStorage'

export const contractFlowInitialState: IContractFlow = {
  flowType: 'CREATE',
  contractType: 'STANDARD',
  v4ProductType: null,
  v4SupportedPaymentTypes: [],
  product: {
    regDate: '',
    modelYear: 0,
    regNumber: '',
    brand: {
      name: '',
      id: 0,
    },
    vin: '',
    model: {
      name: '',
      id: 0,
    },
    fuelType: {
      name: '',
      id: 0,
      caption: '',
    } as any,
  },
  contractObjectType: 'Vehicle',
  vehicleAlongItsContracts: {
    regDate: '',
    modelYear: 0,
    regNumber: '',
    brand: {
      name: '',
      id: 0,
    },
    vin: '',
    model: {
      name: '',
      id: 0,
    },
    fuelType: {
      name: '',
      id: 0,
    },
    contracts: {
      contracts: [],
      durations: [],
      mileageDurationsMap: null,
    },
    hasActiveWarranty: false,
  },
  template: undefined,
  options: [],
  startMileage: undefined,
  contractStartDateISO: null,
  duration: {
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    wantedMax: 0,
    wantedValue: 0,
  },
  mileage: {
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    wantedMax: 0,
    wantedValue: 0,
  },
  value: { value: 0, min: 0, max: 0, step: 0, wantedMax: 0, wantedValue: 0 },
  valueType: 'Mileage',
  startValueType: 'Mileage',
  startValue: 0,
  payments: {
    amountPrPayment: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    downpayment: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    distributedAmount: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    isDownpaymentDistributed: false,
    firstPaymentDate: '',
    contractStartDate: '',
    contractEndDate: '',
    providerPayments: 0,
    providerShare: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    providerCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerShare: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    contractCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerPayments: 0,
    customAmountPrPayment: 0,
    noPayment: false,
  },
  paymentGateway: 'Stripe',
  customer: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip: '',
    customerType: 'PRIVATE',
    cvr: '',
    ean: '',
    companyName: '',
  },
  invoiceCustomer: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip: '',
    customerType: 'PRIVATE',
    cvr: '',
    ean: '',
    companyName: '',
  },
  state: {
    options: [],
    templates: [],
    durations: null,
    mileageDurationsMap: undefined,
    licenseLookup: false,
    licenseSkip: false,
    customerSearched: false,
    active: ContractFlowActivePanel.searchVehicle,
    step: ContractFlowActiveStep.details,
    startMileage: false,
    valid: { customer: false },
    isMileageDisabled: false,
    hasGivenEnginePower: false,
    userGivenEnginePowerKW: null,
    originalEnginePowerKWFromLookup: null,
  },
  reference: '',
  customTerms: '',
  customMessage: '',
}

export const productContractFlowInitialState: IContractFlow = {
  flowType: 'CREATE',
  contractType: 'CUSTOM',
  v4ProductType: null,
  v4SupportedPaymentTypes: [],
  product: {
    regDate: new Date().toISOString(),
    modelYear: moment().year(),
    itemNumber: '',
    itemNumber2: undefined,
    serialNumber: '',
    serialNumber2: undefined,
    typeName: '',
    brand: {
      name: '',
      id: undefined,
    },
    model: {
      name: '',
      id: undefined,
    },
    fuelType: {
      name: '',
      id: undefined,
    },
  },
  contractObjectType: 'Product',
  vehicleAlongItsContracts: {
    regDate: new Date().toISOString(),
    modelYear: moment().year(),
    itemNumber: '',
    itemNumber2: undefined,
    serialNumber: '',
    serialNumber2: undefined,
    typeName: '',
    brand: {
      name: '',
      id: undefined,
    },
    model: {
      name: '',
      id: undefined,
    },
    fuelType: {
      name: '',
      id: undefined,
    },
    contracts: {
      contracts: [],
      durations: [],
      mileageDurationsMap: null,
    },
    hasActiveWarranty: false,
  },
  template: undefined,
  options: [],
  startMileage: undefined,
  contractStartDateISO: null,
  duration: {
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    wantedMax: 0,
    wantedValue: 0,
  },
  mileage: {
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    wantedMax: 0,
    wantedValue: 0,
  },
  value: { value: 0, min: 0, max: 0, step: 0, wantedMax: 0, wantedValue: 0 },
  valueType: undefined,
  startValueType: undefined,
  startValue: 0,
  payments: {
    amountPrPayment: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    downpayment: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    distributedAmount: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    isDownpaymentDistributed: false,
    firstPaymentDate: '',
    contractStartDate: '',
    contractEndDate: '',
    providerPayments: 0,
    providerShare: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    providerCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerShare: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    contractCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerPayments: 0,
    customAmountPrPayment: 0,
    noPayment: false,
  },
  paymentGateway: 'Stripe',
  customer: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip: '',
    customerType: 'PRIVATE',
    cvr: '',
    ean: '',
    companyName: '',
  },
  invoiceCustomer: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip: '',
    customerType: 'PRIVATE',
    cvr: '',
    ean: '',
    companyName: '',
  },
  state: {
    options: [],
    templates: [],
    durations: null,
    mileageDurationsMap: undefined,
    licenseLookup: false,
    licenseSkip: false,
    customerSearched: false,
    active: ContractFlowActivePanel.vehicle,
    step: ContractFlowActiveStep.details,
    startMileage: false,
    valid: { customer: false },
    isMileageDisabled: false,
    hasGivenEnginePower: false,
    userGivenEnginePowerKW: null,
    originalEnginePowerKWFromLookup: null,
  },
  reference: '',
  customTerms: '',
  customMessage: '',
}

export const customContractFlowInitialState: IContractFlow = {
  flowType: 'CREATE',
  contractType: 'CUSTOM',
  v4ProductType: null,
  v4SupportedPaymentTypes: [],
  product: {
    regDate: '',
    modelYear: 0,
    regNumber: '',
    brand: {
      name: '',
      id: 0,
    },
    vin: '',
    model: {
      name: '',
      id: 0,
    },
    fuelType: {
      name: '',
      id: 0,
      caption: '',
    } as any,
  },
  contractObjectType: 'Vehicle',
  vehicleAlongItsContracts: {
    regDate: '',
    modelYear: 0,
    regNumber: '',
    brand: {
      name: '',
      id: 0,
    },
    vin: '',
    model: {
      name: '',
      id: 0,
    },
    fuelType: {
      name: '',
      id: 0,
    },
    contracts: {
      contracts: [],
      durations: [],
      mileageDurationsMap: null,
    },
    hasActiveWarranty: false,
  },
  template: undefined,
  options: [],
  startMileage: undefined,
  contractStartDateISO: null,
  duration: {
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    wantedMax: 0,
    wantedValue: 0,
  },
  mileage: {
    value: 0,
    min: 0,
    max: 0,
    step: 0,
    wantedMax: 0,
    wantedValue: 0,
  },
  value: { value: 0, min: 0, max: 0, step: 0, wantedMax: 0, wantedValue: 0 },
  valueType: 'Mileage',
  startValueType: 'Mileage',
  startValue: 0,
  payments: {
    amountPrPayment: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    downpayment: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    distributedAmount: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    isDownpaymentDistributed: false,
    firstPaymentDate: '',
    contractStartDate: '',
    contractEndDate: '',
    providerPayments: 0,
    providerShare: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    providerCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerShare: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    contractCost: {
      price: 0,
      priceInclVat: 0,
      vatShare: 0,
      currency: getCurrency(),
    },
    customerPayments: 0,
    customAmountPrPayment: 0,
    noPayment: false,
  },
  paymentGateway: 'Stripe',
  customer: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip: '',
    customerType: 'PRIVATE',
    cvr: '',
    ean: '',
    companyName: '',
  },
  invoiceCustomer: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip: '',
    customerType: 'PRIVATE',
    cvr: '',
    ean: '',
    companyName: '',
  },
  state: {
    options: [],
    templates: [],
    durations: null,
    mileageDurationsMap: undefined,
    licenseLookup: false,
    licenseSkip: false,
    customerSearched: false,
    active: ContractFlowActivePanel.vehicle,
    step: ContractFlowActiveStep.details,
    startMileage: false,
    valid: { customer: false },
    isMileageDisabled: false,
    hasGivenEnginePower: false,
    userGivenEnginePowerKW: null,
    originalEnginePowerKWFromLookup: null,
  },
  reference: '',
  customTerms: '',
  customMessage: '',
}
