import React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../reducers/initialState'
import { selectHiddenVAT } from '../../selectors/contractDisplayConfig'

export interface IReduxHiddenVATProps {
  hiddenVAT: boolean
}

export const withHiddenVAT = <T extends IReduxHiddenVATProps>(Component: React.ComponentType<T>) => {
  const ConnectedHiddenVATComponent = (props: T) => {
    return <Component {...props} />
  }

  const mapStateToProps = (state: IRootState) => {
    return {
      hiddenVAT: selectHiddenVAT(state),
    }
  }

  return connect(mapStateToProps)(ConnectedHiddenVATComponent)
}
