import React, { Component } from 'react'
import { withStyles, WithStyles, CardActionArea, CardContent, Typography } from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import styles from './styles'
import { Card } from 'components/Mui/Card'

interface IProps extends WithStyles<typeof styles> {
  className?: string
  onClick: () => void
}

class NewCreditCard extends Component<IProps> {
  public render() {
    const { className, classes, onClick } = this.props

    return (
      <Card component="li" className={`${className ? className : ''} ${classes.root}`} data-e2e={'NewCreditCard'}>
        <CardActionArea className={classes.buttonWrap} onClick={onClick}>
          <CardContent>
            <Typography className={classes.button} variant="button">
              {t('Add Card')}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
}

export default withStyles(styles)(NewCreditCard)
