import { CircularProgress } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import React from 'react'

interface IProps extends WithStyles<typeof styles> {
  open: boolean
  textLine1?: string // Optional line of text.
  textLine2?: string // Optional line of text.
}

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    color: 'white',
    outline: 'none',
    margin: '0 65% 0 35%',
  },
  headerText: {
    fontSize: 24,
    color: 'white',
    backgroundColor: '#777',
    padding: '4px 4px 4px 4px',
  },
})

class LoadingOverlay extends React.Component<IProps, {}> {
  public render() {
    const { classes, open, textLine1, textLine2 } = this.props

    return (
      <Modal className={classes.root} open={open}>
        <div>
          <CircularProgress className={classes.progress} size={50} />
          <div>
            {textLine1 && <div className={classes.headerText}>{textLine1}</div>}
            {textLine2 && <div className={classes.headerText}>{textLine2}</div>}
          </div>
        </div>
      </Modal>
    )
  }
}

export default withStyles(styles)(LoadingOverlay)
