import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory()

export const openInNewTab = (url: string) => {
  const win = window.open(url, '_blank')
  if (win) {
    win.focus()
  }
}

export default browserHistory
