import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles } from '@material-ui/core'
import { IReportSearchResponse } from '@omnicar/sam-types'
import classNames from 'classnames'
import { findDOMNode } from 'react-dom'
import ReportResultTableBody from './ReportResultTableBody'
import { reportSize, IDisplayConfigs, reportColor, borders } from './ReportConstants'
import { t } from 'translations/translationFunctions'

const styles = () =>
  createStyles({
    root: {
      // border: borders.scrollableBlockBorder,
      display: 'block',
      overflowY: 'scroll',
      overflowX: 'auto',
      width: '100%',
    },
    tbl: {
      borderCollapse: 'collapse',
      width: '100%',
    },
    trHead: {
      backgroundColor: reportColor.headerBgColor,
      border: 'none',
      borderBottom: borders.tableCellBorder,
      position: 'sticky',
      top: 0,
      height: '3em',
      maxHeight: '3em',
    },
    th: {
      backgroundColor: reportColor.headerBgColor,
      border: 'none',
      borderBottom: borders.tableCellBorder,
      position: 'sticky',
      top: 0,
      height: '3em',
      maxHeight: '3em',
    },
    thDiv: {
      height: '3em',
      maxHeight: '3em',
      overflowY: 'hidden',
    },
  })

interface IOwnProps {
  reportData: IReportSearchResponse | null
  searchId: number
  fetchingData: boolean
  displayConfigs: IDisplayConfigs
  buttonsPanelHeight: number
}
type TProps = IOwnProps & WithStyles<typeof styles>
interface IState {
  windowWidth: number | null
  windowHeight: number | null
  thisTop: number | null
  thisLeft: number | null
  minRow: number
  maxRow: number
  buttonsPanelHeight: number
}

class ReportResultTable extends Component<TProps, IState> {
  public state: IState = {
    windowHeight: null,
    windowWidth: null,
    thisTop: null,
    thisLeft: null,
    minRow: 0,
    maxRow: 5,
    buttonsPanelHeight: 0,
  }

  componentDidMount() {
    this.updateWindowDimensions()
  }

  componentDidUpdate() {
    this.updateWindowDimensions()
  }

  public render() {
    const { classes } = this.props

    if (this.props.fetchingData) {
      return <h1>{t('Fetching data...')}</h1>
    }

    const rd = this.props.reportData
    if (!rd || !Object.entries(rd.cols).length) {
      return <div />
    }
    if (!rd.data || !rd.data.length) {
      return <h1>{t('No data found')}</h1>
    }
    const { searchId, displayConfigs } = this.props
    const { windowHeight, windowWidth, thisTop, thisLeft, minRow, maxRow } = this.state

    // maxWidth: reportSize.initialBlockSize,
    const rootStyles = { height: '100%', width: '100%', maxHeight: reportSize.initialBlockSize }
    if (windowHeight !== null && windowWidth !== null && thisTop !== null && thisLeft !== null) {
      // const maxHeightValue = windowHeight - thisTop - reportSize.pageMarginBottom
      // const maxWidthValue = windowWidth - thisLeft - reportSize.pageMarginRight
      // rootStyles.maxWidth = maxWidthValue
      // rootStyles.maxHeight = maxHeightValue > 800 ? 800 : maxHeightValue
    }

    return (
      <div className={classes.root} style={rootStyles} key={this.key('tbwrap')} onScroll={this.handleScroll}>
        <table className={classNames(classes.tbl)} key={this.key('table')}>
          <thead key={this.key('thead')}>
            <tr className={classes.trHead} key={this.key('theadrow')}>
              {Object.entries(rd.cols).map(([colName, colDef]) => (
                <th className={classes.th} key={this.key('tbwrap', 0, colName)}>
                  <div className={classes.thDiv}>{colDef.header}</div>
                </th>
              ))}
            </tr>
          </thead>
          <ReportResultTableBody
            reportData={rd}
            searchId={searchId}
            minRow={minRow}
            maxRow={maxRow}
            displayConfigs={displayConfigs}
          />
        </table>
      </div>
    )
  }

  private handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const element = e.currentTarget
    const scrollHeight = element.scrollHeight
    const scrollTop = element.scrollTop
    const clientHeight = element.clientHeight
    const rd = this.props.reportData
    const nRows = rd ? rd.data.length : 0
    const minRow = ((scrollTop - clientHeight * 0) / scrollHeight) * nRows - 1
    const maxRow = ((scrollTop + clientHeight * 1) / scrollHeight) * nRows + 1
    this.setState({ minRow, maxRow })
  }

  private updateWindowDimensions = () => {
    const element = findDOMNode(this)
    let offsetTop: number | null = null
    let offsetLeft: number | null = null
    if (element instanceof HTMLElement) {
      const rect = element.getBoundingClientRect()
      offsetTop = rect.top
      offsetLeft = rect.left
    }
    if (
      window.innerWidth !== this.state.windowWidth ||
      window.innerHeight !== this.state.windowHeight ||
      offsetTop !== this.state.thisTop ||
      offsetLeft !== this.state.thisLeft
    ) {
      this.setState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        thisTop: offsetTop,
        thisLeft: offsetLeft,
      })
    }
  }

  // ==========================================================================
  // Key generation
  // ==========================================================================

  private key = (elem: string, rowIndex?: number, colName?: string): string => {
    return `key-${this.props.searchId}-${elem}-${rowIndex ? rowIndex : 0}-${colName ? colName : ''}`
  }
}

export default withStyles(styles)(ReportResultTable)
