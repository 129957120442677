import React from 'react'
import { IApiVehicleWithContracts, IApiVehicleContract, IApiOtherWithContracts } from '@omnicar/sam-types'
import { CardContent } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import CustomerPortalContractDetailsVehicleListItemHeader from './Header'
import CustomerPortalContractDetailsVehicleListItemContractList from './ContractList'
import styles from './styles'
import { Card } from 'components/Mui/Card'
import { IGenericTypeWithContracts, mapVehicleToGeneric } from 'utils/genericProduct'

interface IProps extends WithStyles<typeof styles> {
  vehicle: IApiVehicleWithContracts | IApiOtherWithContracts
  selectedContract: IApiVehicleWithContracts | IApiOtherWithContracts | undefined
  handleContractSelect: (
    selectedContract: IApiVehicleContract | undefined,
    selectedVehicle: IApiVehicleWithContracts | IApiOtherWithContracts | undefined,
  ) => void
}

class CustomerPortalContractDetailsVehicleListVehicle extends React.Component<IProps> {
  public render() {
    const { classes, vehicle, selectedContract } = this.props

    const mappedVehicle = mapVehicleToGeneric(vehicle)
    const titles = this.getTitles(mappedVehicle.vehicle, 'vin' in vehicle)

    return (
      <section className={classes.root} data-e2e={'CustomerPortalContractDetailsVehicleListItem__contracts'}>
        <Card>
          <CardContent className={classes.cardContent}>
            <CustomerPortalContractDetailsVehicleListItemHeader
              vehicle={mappedVehicle && mappedVehicle.vehicle}
              title={titles.title}
              subTitles={titles.subTitles}
            />
            <CustomerPortalContractDetailsVehicleListItemContractList
              contracts={vehicle.contracts}
              selectedContract={selectedContract}
              handleContractSelect={this.handleContractClick}
            />
          </CardContent>
        </Card>
      </section>
    )
  }

  private getTitles = (vehicle: IGenericTypeWithContracts, isVehicle: boolean) => {
    const title = isVehicle ? undefined : `${vehicle.brandName} ${vehicle.modelName}`
    const subTitles = isVehicle
      ? [`${vehicle.brandName} ${vehicle.modelName}`]
      : [vehicle.identifier1, vehicle.identifier2]
    return { title, subTitles }
  }

  private handleContractClick = (selectedContract: IApiVehicleContract | undefined) =>
    this.props.handleContractSelect(selectedContract, this.props.vehicle)
}

export default withStyles(styles)(CustomerPortalContractDetailsVehicleListVehicle)
