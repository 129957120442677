import React, { StatelessComponent, ChangeEvent } from 'react'
import { Select, MenuItem, FormControl } from '@material-ui/core'
import { getRole } from 'utils/localStorage'
import { INullableRole } from '@omnicar/sam-types'
import { WithStyles } from '@material-ui/core/styles'
import { t, tRole } from 'translations/translationFunctions'

type TRole = INullableRole['role']

interface IProps {
  value: TRole | undefined
  name: string
  handleChange: (e: ChangeEvent<any>) => void
  handleBlur: (e: ChangeEvent<any>) => void
  filterEnabled?: boolean
}

const RoleSelect: StatelessComponent<IProps & WithStyles> = ({
  classes,
  value,
  handleBlur,
  handleChange,
  name,
  filterEnabled = true,
}) => {
  const rolesEnabled = getRole() === 'admin'
  const roles: TRole[] = ['seller', 'admin', 'reports', 'observer', null]

  const handleOnChange = (e: ChangeEvent<any>) => {
    const { value } = e.target
    let newEvent = { ...e }
    newEvent.target.value = value === 'null' ? null : value

    handleChange(newEvent)
  }

  const formatRoleName = (role: TRole): string => {
    return role ? tRole(role.replace(/^\w/, (c) => c.toUpperCase())) : t('None')
  }

  return (
    <FormControl fullWidth={true} disabled={!rolesEnabled || !filterEnabled}>
      <Select className={classes.select} value={`${value}`} name={name} onChange={handleOnChange} onBlur={handleBlur}>
        {roles.map((r) => (
          <MenuItem key={formatRoleName(r)} value={`${r}`}>
            {formatRoleName(r)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RoleSelect
