import React, { Component } from 'react'
import { IApiVehicleContract, ICreditCardRecord, ContractState, IInvoice } from '@omnicar/sam-types'
import * as api from 'api/api'
import {
  WithStyles,
  withStyles,
  withWidth,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core'
import CreditCard from 'components/customer/Contract/Details/CreditCard'
import NewCreditCard from 'components/customer/Contract/Details/NewCreditCard'
import LoadingOverlay from 'components/LoadingOverlay'
import styles from './styles'
import { t } from 'translations/translationFunctions'
import notify from 'utils/notify/notify'
import { compose } from 'recompose'
// import { trackEvent } from 'utils/analytics'
import browserHistory from 'utils/history'
import { stripeAddCardPath } from 'routes/paths'
import { getParameterByName } from 'utils/path'

interface IOwnProps {
  contract: IApiVehicleContract | undefined
  hidden: boolean
  disabled: boolean
  invoices?: IInvoice[]
  onTabRedirect: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  cards: [number, ICreditCardRecord[]]
  showAddCreditCardDialog: boolean
  isStripeLoaded: boolean
  isLoading: boolean
  isNewCardAdded: boolean
  isPopupShown: boolean
}

class CustomerContractDetailsTabsCreditCards extends Component<TProps, IState> {
  public state: IState = {
    cards: [0, []],
    showAddCreditCardDialog: false,
    isStripeLoaded: false,
    isLoading: false,
    isNewCardAdded: false,
    isPopupShown: false,
  }

  public componentDidUpdate(prevProps: IOwnProps, prevState: IState) {
    const { cards } = this.state
    const { contract, hidden } = this.props

    // check if list of cards belongs to selected contract
    const contractChanged = contract && contract.serviceContractId !== cards[0]

    // visible and changed
    if (!hidden && contractChanged) {
      const cards: [number, ICreditCardRecord[]] = [contract!.serviceContractId, []]
      this.setState({ cards }, () => this.getCreditCards())
    }
  }

  public async componentDidMount() {
    const { hidden } = this.props

    // load if component is visible
    if (!hidden) {
      this.getCreditCards()
    }

    const isNewAdded = getParameterByName('isNewAdded', window.location.href)
    if (isNewAdded) {
      this.setState({ isNewCardAdded: !!isNewAdded })
    }
  }

  public render() {
    const { classes, contract, hidden } = this.props
    const { cards, isLoading, isPopupShown } = this.state

    // allow changes to credit cards associated with contract (states 'Active' or 'Suspended')
    const creditCardChangesAllowed = contract
      ? [ContractState.Active, ContractState.ActivePendingTermination, ContractState.Suspended].includes(
          contract.contractState,
        )
      : false

    return (
      <div hidden={hidden} data-e2e="CustomerContractDetailsTabsCreditCards">
        {(cards[1].length > 0 || creditCardChangesAllowed) && (
          <React.Fragment>
            <ul className={classes.cards}>
              {cards[1].map((card) => (
                <CreditCard
                  className={classes.card}
                  key={card.cardId}
                  card={card}
                  onDeleteCard={this.handleDeleteCard}
                  onMakeCardDefault={this.handleMakeDefaultCard}
                  changesAllowed={creditCardChangesAllowed}
                />
              ))}
              {creditCardChangesAllowed && <NewCreditCard className={classes.card} onClick={this.handleAddCard} />}
            </ul>
          </React.Fragment>
        )}
        <LoadingOverlay open={isLoading} />
        <Dialog open={isPopupShown}>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText classes={{ root: classes.diaologText }}>
              {t(
                'Your new card has been successfully activated, you are now being redirected to the invoice list where you manually can trigger missing payments',
              )}
            </DialogContentText>
            <CircularProgress className={classes.spinner} size={24} color="secondary" />
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  private getCreditCards = async () => {
    const { contract, disabled } = this.props

    if (contract && !disabled) {
      this.setState({ isLoading: true })
      const res = await api.getContractCreditCards(contract.prettyIdentifier)

      if (res.data) {
        // sort list: put active first
        const creditCards: ICreditCardRecord[] = res.data ? res.data.sort((a, b) => +b.isDefault - +a.isDefault) : []
        const cards: [number, ICreditCardRecord[]] = [contract.serviceContractId, creditCards]

        // update state
        this.setState({ isLoading: false, cards }, () => this.handleShowPopup())
      }
    }
  }

  private handleDelayedClosePopup = () =>
    setTimeout(() => {
      this.setState({
        isPopupShown: false,
        isNewCardAdded: false,
      })
      this.props.onTabRedirect()
    }, 5000)

  private handleShowPopup = () => {
    const { cards, isNewCardAdded } = this.state
    const { invoices } = this.props
    const hasUnpaidInvoices = invoices && invoices.some((i) => i.state === 'Pending')

    if (!isNewCardAdded || cards[0] === 0 || !hasUnpaidInvoices) {
      return
    }
    this.setState({ isPopupShown: true }, this.handleDelayedClosePopup)
  }

  private handleDeleteCard = async (cardId: string) => {
    const { contract } = this.props

    // delete card in api
    this.setState({ isLoading: true })
    await api.deleteCreditCard(contract!.prettyIdentifier, cardId)

    // remove card from list
    const creditCards = this.state.cards[1].filter((card) => card.cardId !== cardId)
    const cards: [number, ICreditCardRecord[]] = [this.state.cards[0], creditCards]

    // update state and notify user
    this.setState({ isLoading: false, cards }, () => notify.success({ message: t('Credit card has been deleted') }))
  }

  private handleMakeDefaultCard = async (cardId: string) => {
    const { contract } = this.props

    this.setState({ isLoading: true })
    await api.makeCreditCardDefault(contract!.prettyIdentifier, cardId)

    // make new card default
    const creditCards = this.state.cards[1].map((card) => {
      const res = { ...card }
      if (card.cardId !== cardId) {
        res.isDefault = false
      } else {
        res.isDefault = true
      }
      return res
    })

    // put new card first in list
    const cards: [number, ICreditCardRecord[]] = [this.state.cards[0], creditCards]

    // update state and notify user
    this.setState({ isLoading: false, cards }, () => notify.success({ message: t('Credit card is now default') }))
  }

  private handleAddCard = () => {
    this.setState({ isLoading: true })
    const prettyId = this.props.contract ? this.props.contract.prettyIdentifier : ''
    if (prettyId) {
      browserHistory.push(stripeAddCardPath(prettyId))
    }
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles), withWidth())(CustomerContractDetailsTabsCreditCards)
