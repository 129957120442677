import Chip from '@material-ui/core/Chip'
import InfoRounded from '@material-ui/icons/InfoRounded'
import ContractListWrapper from 'components/admin/Contract/ContractListWrapper'
import { Page, PageContent, PageHeader, PageTitle } from 'components/Mui/Page'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'

type TProps = RouteComponentProps<any>

function renderInactiveProvider() {
  return (
    <div>
      <Chip
        icon={<InfoRounded />}
        label={
          <span>
            {`${t('Provider has been deactivated')}`}
            {'. '}
            {`${t('You can still search for and view service agreements but not alter them')}`}
          </span>
        }
      />
    </div>
  )
}

const ContractListPage: React.SFC<TProps> = ({ ...rest }) => (
  <Page>
    <AppContext.Consumer>
      {({ providerInfo }) => !!providerInfo && providerInfo.inactive && renderInactiveProvider()}
    </AppContext.Consumer>
    <PageHeader>
      <PageTitle>{t('Contracts')}</PageTitle>
    </PageHeader>
    <PageContent noPadding={true}>
      <ContractListWrapper {...rest} />
    </PageContent>
  </Page>
)

export const ProductContractListPage: React.SFC<TProps> = ({ ...rest }) => (
  <Page>
    <AppContext.Consumer>
      {({ providerInfo }) => !!providerInfo && providerInfo.inactive && renderInactiveProvider()}
    </AppContext.Consumer>
    <PageHeader>
      <PageTitle>{t('Contracts')}</PageTitle>
    </PageHeader>
    <PageContent noPadding={true}>
      <ContractListWrapper {...rest} contractObjectType="Product" />
    </PageContent>
  </Page>
)

export default ContractListPage
