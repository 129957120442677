import React from 'react'
import { createPanelStyles } from 'theme'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  IInternalContractNoteResponse as Note,
  IInternalContractNoteRequest as NoteUpdateRequest,
} from '@omnicar/sam-types/types/admin/contract/internalContractNotes'
import InternalNoteListItem from './InternalNoteListItem'
import { deleteInternalContractNote, patchInternalContractNote } from '../../../../../../api/api'
import classNames from 'classnames'

interface IOwnProps {
  notes: Note[]
  prettyIdentifier: string
  isReadOnly: boolean
  maxNoteLenght: number
  maxNrOfAttachmentFiles: number
  onDeleteNote: (noteId: number) => void
  onUpdateNote: (updatedNote: Note) => void
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    itemMargin: {
      marginTop: 22,
      marginRight: 10,
      marginLeft: 10,
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {}

class InternalNoteList extends React.Component<TProps, IState> {
  public render() {
    const { notes, prettyIdentifier, isReadOnly, maxNoteLenght, maxNrOfAttachmentFiles, classes } = this.props
    return (
      <span className={classNames(!!notes.length && classes.itemMargin)}>
        {notes.map((note, i) => (
          <span key={`Internal_Note-${i}`} className={classNames(classes.itemMargin)}>
            <InternalNoteListItem
              note={note}
              onSubmit={this.handleNoteUpdate}
              onDelete={this.handleDeleteNote}
              maxNoteLenght={maxNoteLenght}
              maxNrOfAttachmentFiles={maxNrOfAttachmentFiles}
              prettyIdentifier={prettyIdentifier}
              disabled={isReadOnly}
            />
          </span>
        ))}
      </span>
    )
  }

  private handleNoteUpdate = async (note: NoteUpdateRequest, attachments?: File[]) => {
    const { prettyIdentifier, onUpdateNote } = this.props
    const formData: FormData = new FormData()
    if (attachments) {
      for (const a of attachments) {
        formData.append('attachments', a)
      }
    }

    const jsonNote = JSON.stringify(note)
    formData.append('note', jsonNote)
    const res = await patchInternalContractNote(prettyIdentifier, note.internalContractNoteId || -1, formData)
    if (res && res.ok) {
      const data = await res.json<Note>()
      if (data) {
        onUpdateNote(data)
        return data
      }
    }

    return undefined
  }

  private handleDeleteNote = async (id: number) => {
    const { prettyIdentifier, onDeleteNote } = this.props
    const res = await deleteInternalContractNote(prettyIdentifier, id)
    if (res.statusCode && res.statusCode === 210) {
      onDeleteNote(id)
    }
  }
}

export default withStyles(styles)(InternalNoteList)
