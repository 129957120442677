import { DurationOptions, IContractTemplateResponse } from '@omnicar/sam-types'
import { IContractFlowMileageDuration } from 'types/contractFlow'
import { IDurationMileageValuesVer1 } from 'components/admin/Contract/Flow/DurationMileageVer1'

const isDurationSupported = (duration: DurationOptions, template: IContractTemplateResponse) => {
  for (const option of duration.options) {
    for (const price of option.prices) {
      if (price.contractId === template.id) {
        return true
      }
    }
  }
  return false
}

export const getDurationList = (durations: DurationOptions[], template: IContractTemplateResponse | undefined) => {
  return template && durations.length
    ? durations.filter((d) => isDurationSupported(d, template)).map((d) => d.duration)
    : [0]
}

export const getDurationValues = (dataList: number[]): IContractFlowMileageDuration => {
  const list = dataList.length ? dataList : [0]

  return {
    value: Math.min(...list),
    min: Math.min(...list),
    max: Math.max(...list),
    step: list.length > 1 ? list[1] - list[0] : 0,
    wantedValue: Math.min(...list),
    wantedMax: Math.max(...list),
  }
}

export const getClosestAllowedMileageForDuration = (
  template: IContractTemplateResponse | undefined,
  durationOptions: DurationOptions[],
  values: IDurationMileageValuesVer1,
  durationValue: number,
): IContractFlowMileageDuration => {
  const mileageList = getMileageList(durationOptions, durationValue, template)
  const mileage = getMileageValues(mileageList)
  const mileageValue =
    values.mileage.value > 0 ? values.mileage.value : getClosestAllowedValue(values.mileage.value, mileageList)
  return { ...mileage, value: mileageValue, wantedValue: values.mileage.wantedValue }
}

export const getMileageList = (
  durations: DurationOptions[],
  duration: number,
  template: IContractTemplateResponse | undefined,
) => {
  console.info('\n-> getMileageList(..):')
  console.info('durations:')
  console.info(durations)
  console.info('duration = ' + duration)
  if (!durations) {
    return [0]
  }

  const selectedDuration = durations.find((d) => d.duration === duration)
  const mileageList: number[] = []

  if (selectedDuration && template) {
    selectedDuration.options.forEach((option) => {
      const allowed = option.prices.filter((price) => price.contractId === template.id).length >= 1
      if (allowed) {
        mileageList.push(option.mileage)
      }
    })

    return mileageList
  } else {
    return [0]
  }
}

export const getMileageValues = (dataList: number[]): IContractFlowMileageDuration => {
  const list = dataList.length ? dataList : [0]

  return {
    value: Math.min(...list),
    min: Math.min(...list),
    max: Math.max(...list),
    step: list.length > 1 ? list[1] - list[0] : 0,
    wantedValue: Math.min(...list),
    wantedMax: Math.max(...list),
  }
}

export const getClosestAllowedValue = (value: number, list: number[]) => {
  if (value === 0) {
    return list[Math.floor(list.length / 2)]
  }
  let closestValue = list[0]
  let diff = Math.abs(value - closestValue)

  list.forEach((val) => {
    const newdiff = Math.abs(value - val)
    if (newdiff < diff) {
      diff = newdiff
      closestValue = val
    }
  })

  return closestValue
}
