import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, typography, breakpoints }: Theme) =>
  createStyles({
    root: {
      margin: 0,
      [breakpoints.up('md')]: {
        width: '100%',
      },
    },
    header: {
      color: theme.palette.text.light,
      fontWeight: typography.fontWeightLight,
      marginBottom: spacing(2),
    },
  })
