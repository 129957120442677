import { WithStyles, Theme, withStyles, CardContent, TextField } from '@material-ui/core'
import { Panel, PanelHeader, PanelTitle, PanelContent } from 'components/Mui/Panel'
import { t } from 'translations/translationFunctions'
import { createPanelStyles, theme as customTheme } from 'theme'
import React, { ChangeEvent } from 'react'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
  })

interface IOwnProps {
  active: boolean
  freeContract: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const ContractFlowReference: React.SFC<TProps> = ({ active, classes, onChange, value, freeContract }) => {
  return (
    <Panel disabled={!active}>
      <PanelHeader>
        <PanelTitle>{t('Reference')}</PanelTitle>
      </PanelHeader>
      <PanelContent>
        <Card className={active ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}>
          <CardContent>
            <TextField
              fullWidth={true}
              disabled={!active}
              value={value}
              margin="dense"
              label={t('Add an optional reference (max %count characters)', { count: 128 })}
              onChange={onChange}
            />
          </CardContent>
        </Card>
      </PanelContent>
    </Panel>
  )
}

export default withStyles(styles)(ContractFlowReference)
