import { t } from 'translations/translationFunctions'
import CustomerList from 'components/admin/Customer/List'
import { Page, PageContent, PageHeader, PageTitle } from 'components/Mui/Page'
import React from 'react'

const CustomerListPage: React.SFC<{}> = () => (
  <Page>
    <PageHeader>
      <PageTitle>{t('Customers')}</PageTitle>
    </PageHeader>
    <PageContent noPadding={true}>
      <CustomerList />
    </PageContent>
  </Page>
)

export default CustomerListPage
