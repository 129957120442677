
export class AllTermsCheckedOnContract {
  public static setAllChecked(prettyIdentifier: string) {
    sessionStorage.setItem(this.getKey(prettyIdentifier), 'true')
  }

  public static isAllChecked(prettyIdentifier: string): string | null {
    return sessionStorage.getItem(this.getKey(prettyIdentifier))
  }

  public static clear(prettyIdentifier: string) {
    return sessionStorage.removeItem(this.getKey(prettyIdentifier))
  }

  private static getKey(prettyIdentifier: string) {
    const key = "all-terms-checked-on-contract"

    return key + '-' + prettyIdentifier
  }
}

export default AllTermsCheckedOnContract
