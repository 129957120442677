import React, { SFC } from 'react'
import styles from './styles'
import { WithStyles, withStyles } from '@material-ui/core'
import logo from 'assets/images/Fragus icon-256x256-tr.png'
import Typography from 'components/Typography'
import { t } from 'translations/translationFunctions'
import { Link } from 'react-router-dom'

type TProps = WithStyles<typeof styles>

const Error404Page: SFC<TProps> = ({ classes }) => (
  <section className={classes.root}>
    <div className={classes.content}>
      <div className={classes.title}>
        <span>4</span>
        <img className={classes.logo} src={logo} alt="" />
        <span>4</span>
      </div>
      <Typography className={classes.subtitle} variant="header">
        {t('Page not found')}
      </Typography>
      <Link className={classes.link} to="/">
        <Typography variant="title">{t('Click here')}</Typography>
      </Link>
    </div>
  </section>
)

export default withStyles(styles)(Error404Page)
