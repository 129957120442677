import Slider from '@material-ui/core/Slider'
import React, { ChangeEvent } from 'react'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import { IContractFlowMileageDuration } from 'types/contractFlow'

interface IOwnProps {
  onChange: (value: number) => void
  minLabel: string
  maxLabel: string
  selectedLabel: string
  convertedLabel: string
  data: IContractFlowMileageDuration
  disabled: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  selectedabel: {
    height: '42px',
    display: 'flex',
    'justify-content': 'space-between',
  },
  convertedLabel: {
    marginTop: theme.spacing(1.5),
    'text-align': 'center',
  },
  slider: {
    padding: `20px 0`,
    margin: `-20px 0`,
  },
  pointer: {
    cursor: 'pointer',
  },
})

const ContractFlowDurationMileageSlider: React.SFC<TProps> = (props: TProps) => {
  const { minLabel, maxLabel, selectedLabel, convertedLabel, data, onChange, disabled, classes } = props

  return (
    <main data-e2e={'ContractFlowDurationMileageSlider'}>
      <div className={classes.selectedabel}>
        <span
          className={classes.pointer}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={(e: ChangeEvent<{}>) => {
            onChange(data.min)
          }}
          data-e2e={'ContractFlowDurationMileageSlider__minLabel'}
        >
          {minLabel}
        </span>
        <Typography className={classes.title} variant="display1">
          {selectedLabel}
        </Typography>
        <span
          className={classes.pointer}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={(e: ChangeEvent<{}>) => {
            onChange(data.max)
          }}
          data-e2e={'ContractFlowDurationMileageSlider__maxLabel'}
        >
          {maxLabel}
        </span>
      </div>
      <Slider
        className={classes.slider}
        disabled={disabled}
        // Note the offsetting of value, max and in the onChange
        // The step prop is zero based and not based on the min value
        // See https://codesandbox.io/s/9onnvnpmop and try with min=1, max=7, step=3
        value={data.value - data.min}
        min={0}
        max={data.max - data.min}
        step={data.step}
        // tslint:disable-next-line:jsx-no-lambda
        onChange={(event: ChangeEvent<any>, newValue: number | number[]) => {
          onChange((newValue as number) + data.min)
        }}
        data-e2e={'ContractFlowDurationMileageSlider__slider'}
      />
      <div className={classes.convertedLabel}>{convertedLabel}</div>
    </main>
  )
}

export default withStyles(styles)(ContractFlowDurationMileageSlider)
