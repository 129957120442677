import { Button, Hidden, WithStyles, withStyles, withWidth } from '@material-ui/core'
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons'
import { ContractState, IApiVehicleContract } from '@omnicar/sam-types'
import classNames from 'classnames'
import CustomerContractDetailsInfo from 'components/customer/Contract/Details/Info'
import IncludedOptionsList from 'components/IncludedOptionsList'
import Typography from 'components/Typography'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { t, tContractState } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'
import { IGenericTypeWithContracts } from 'utils/genericProduct'
import styles from './styles'

interface IOwnProps {
  vehicle: IGenericTypeWithContracts | undefined
  contract: IApiVehicleContract | undefined
  hidden: boolean
  hiddenPriceSection: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

class CustomerContractDetailsTabsMain extends Component<TProps> {
  public render() {
    const { contract, classes, hidden, hiddenPriceSection } = this.props
    const showAdditionals = contract && this.contractHasAdditionalOptions(contract)
    const showIncluded = contract && this.contractHasIncludedOptions(contract)

    return (
      <div hidden={hidden} data-e2e="CustomerContractDetailsTabsMain">
        {contract && (
          <>
            {this.renderContractHeader()}
            {contract.activationUrl && (
              <Hidden smUp={true}>
                <div className={classes.activationButtonMobile}>{this.renderActivationButton()}</div>
              </Hidden>
            )}
            <CustomerContractDetailsInfo contract={contract} hiddenPriceSection={hiddenPriceSection} />
            <hr className={classes.hr} />

            {showAdditionals && (
              <>
                <Typography variant="subtitle" tag="h2">
                  <span className={classes.additionalOptionsTitle}>{t('Options purchased to the subscription')}</span>
                </Typography>
                <div className={classes.additionalOptions}>
                  <IncludedOptionsList
                    options={contract.includedOptions}
                    filterBy="ADDITIONAL"
                    showAbbreviation={true}
                    useDefaultAbbreviationClass={true}
                  />
                </div>
              </>
            )}

            {showIncluded && (
              <>
                <Typography variant="subtitle" tag="h2" className={classes.additionalOptionsTitle}>
                  <span>{t('Included in subscription')}</span>
                </Typography>
                <div className={classes.additionalOptions}>
                  <IncludedOptionsList
                    options={contract.includedOptions}
                    filterBy="PROPERTY"
                    showAbbreviation={false}
                    useDefaultAbbreviationClass={true}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    )
  }

  private renderContractHeader = () => {
    const { classes, contract } = this.props
    return (
      <>
        {contract && (
          <div className={classes.header}>
            <div className={classes.headerWrap}>
              <Typography variant="display1" className={classes.headerTemplateName}>
                {contract.contractTemplateExternalName}
              </Typography>
              <Typography
                variant="subtitle"
                className={`
              ${
                [ContractState.Active, ContractState.ActivePendingTermination].includes(contract.contractState) &&
                classes.stateActive
              }
              ${contract.contractState > ContractState.ActivePendingTermination && classes.stateInactive}
              ${contract.contractState < ContractState.Active && classes.statePending}
              `}
              >
                {tContractState(contract.contractState)}
              </Typography>
              {contract.activationUrl && <Hidden xsDown={true}>{this.renderActivationButton()}</Hidden>}
            </div>
            <Typography variant="subtitle">{contract.prettyIdentifier}</Typography>
          </div>
        )}
      </>
    )
  }

  private renderActivationButton = () => {
    const { classes, contract } = this.props
    return (
      <>
        {contract && (
          <Button
            className={classNames(classes.alignRight)}
            variant="contained"
            color="secondary"
            href={contract.activationUrl}
            target="blank"
            component="button"
            size="small"
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => trackEvent('Customer portal', 'Navigate to activation')}
            data-e2e={'CustomerContractDetailsTabsMain__offer-activate'}
          >
            <OpenInNewIcon className={classNames(classes.linkActivationIcon)} />
            <Typography className={classNames(classes.textWhite)} variant="button">
              {t('Activate offer')}
            </Typography>
          </Button>
        )}
      </>
    )
  }

  private contractHasAdditionalOptions = (contract: IApiVehicleContract) => {
    return contract.includedOptions.filter((c) => c.assoc === 'ADDITIONAL').length > 0
  }

  private contractHasIncludedOptions = (contract: IApiVehicleContract) => {
    return contract.includedOptions.filter((c) => c.assoc === 'PROPERTY').length > 0
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles), withWidth())(CustomerContractDetailsTabsMain)
