import { CircularProgress, createStyles, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'
import { compose } from 'recompose'

interface IOwnProps {
  loading: boolean
}
type TProps = IOwnProps & WithStyles<typeof styles>

const styles = () => createStyles({})

const TableLoadingIndicator: React.SFC<TProps> = ({ loading }) => {
  return loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <CircularProgress size={32} style={{ color: '#111111' }} />
      </div>
    </div>
  ) : null
}

export default compose<TProps, IOwnProps>(withStyles(styles))(TableLoadingIndicator)
