import React from 'react'
import { withStyles, WithStyles, createStyles, StyleRules } from '@material-ui/core/styles'
import { theme } from 'theme'
import { isShowLogoOnWeb, isUseWhiteBGTheme } from 'utils/localStorage'

const getCurrentTheme = (): StyleRules<'root' | 'topline' | 'rootOfRoot'> => {
  return !isUseWhiteBGTheme()
    ? {
        root: {
          ...theme.palette.gradients.secondary,
          height: isShowLogoOnWeb() ? '113%' : '100%',
        },
        topline: {
          height: isShowLogoOnWeb() ? '60px' : '0px',
          backgroundColor: 'white',
        },
        rootOfRoot: {
          height: '100%',
        },
      }
    : {
        root: { ...theme.paletteWhiteBG.gradients.secondary, height: '100%' },
        topline: {},
        rootOfRoot: {
          height: '100%',
        },
      }
}

const styles = () => createStyles(getCurrentTheme())

const AppAreaBackground = ({ classes }: WithStyles<typeof styles>) => {
  return (
    <div className={classes.rootOfRoot}>
      <div className={classes.topline} />
      <div className={classes.root} />
    </div>
  )
}

export default withStyles(styles)(AppAreaBackground)
