import React from 'react'
import { RouteComponentProps } from 'react-router'
import Spinner from 'react-spinkit'
import { t } from 'translations/translationFunctions'
import { IPreparePaymentResponse, IPreparePaymentRequest } from '@omnicar/sam-types'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { ApiError } from '@omnicar/sam-types'
import { getContractSpecifics, getContractCreationData } from '../../../api/api'
import { ShoppingCart as ShoppingCartIcon, ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons'
import { LayoutActions, LayoutActionsLeft, LayoutActionsRight, LayoutColumn, LayoutRow } from 'components/Mui/Layout'
import TermsOfServiceForm, { TContractOption } from '../../../components/TermsOfServiceForm'
import { Button } from '@material-ui/core'
import { createStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { theme as customTheme } from 'theme'
import notify from 'utils/notify/notify'
import browserHistory from 'utils/history'
import { launchCheckoutWindow } from '../../../api/StripeClient'
import { IContractFlow } from 'types/contractFlow'
import './StripeCheckoutPage.css'
import { AllTermsCheckedOnContract } from 'utils/sessionStorage'

interface IReducerProps {
  contractFlow: IContractFlow
}

interface IRouteProps {
  serviceContractIdAsString: string
}

interface IState {
  checkoutSessionId: string
  errMsg: string
  isAllTermsChecked: boolean
  mode: Mode
  stripePublicKey: string
  stripeAccount: string
  isInvalidContract: boolean
  optionsWithTerms: TContractOption[] | null
  termsOfTradeRef: string | null
}

enum Mode {
  init,
  missingContractId,
  invalidContractId,
  error,
  initCheckAllTerms,
  checkAllTerms,
  readyForPrepare,
  Preparing,
  readyForCallStripe,
  CallingStripe,
}

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginTop: '130px',
    },
    acceptTermsContainer: {
      marginTop: '5vh',
    },
    stripeCheckoutContainer: {
      marginTop: '10vh',
    },
    button: {
      marginLeft: spacing(1),
    },
    buttonIcon: {
      marginRight: spacing(1),
      fontSize: 16,
    },
    section: {
      padding: `${spacing(2)}px ${spacing(1)}px`,
      '&:nth-child(even)': {
        backgroundColor: customTheme.palette.background.lighter,
      },
      '&:first-child': {
        paddingTop: spacing(2),
      },
      '&:last-child': {
        paddingBottom: spacing(2),
      },
    },
    addSpaceAfter: {
      marginRight: `0.3em`,
    },
    sectionItem: {
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: spacing(1),
      fontSize: 14,
    },
  })

export type TProps = IReducerProps & RouteComponentProps<IRouteProps> & WithStyles<typeof styles>

export abstract class AbstractStripeCheckoutPage extends React.Component<TProps, IState> {
  protected prettyIdentifier: string | undefined = undefined

  private dicText: Map<Mode, string> = new Map([
    [Mode.init, t('Preparing payment......')],
    [Mode.missingContractId, t('Missing contract ID.')],
    [Mode.invalidContractId, t('Invalid contract ID.')],
    [Mode.error, t('An error occurred while preparing payment.')],
    [Mode.initCheckAllTerms, t('Loading contract information...')],
    [Mode.checkAllTerms, t('Terms of Service')],
    [Mode.readyForPrepare, t('Preparing payment......')],
    [Mode.Preparing, t('Preparing payment......')],
    [
      Mode.readyForCallStripe,
      t('You are now being transferred to the payment page, where you can complete your payment.'),
    ],
    [Mode.CallingStripe, t('You are now being transferred to the payment page, where you can complete your payment.')],
  ])

  constructor(props: TProps) {
    super(props)

    this.state = {
      mode: Mode.init,
      errMsg: '',
      stripePublicKey: '',
      stripeAccount: '',
      checkoutSessionId: '',
      isInvalidContract: false,
      isAllTermsChecked: false,
      optionsWithTerms: null,
      termsOfTradeRef: null,
    }
  }

  public componentDidMount() {
    const { match } = this.props
    const serviceContractIdAsString: string = match.params.serviceContractIdAsString
    if (!serviceContractIdAsString) {
      this.setState({ mode: Mode.missingContractId })
    } else if (!this.isValidContractId(serviceContractIdAsString)) {
      this.setState({ mode: Mode.invalidContractId })
    } else {
      this.prettyIdentifier = serviceContractIdAsString

      if (this.skipTermsCheck() || AllTermsCheckedOnContract.isAllChecked(this.prettyIdentifier)) {
        this.setState({ mode: Mode.readyForPrepare })
      } else {
        this.setState({ mode: Mode.initCheckAllTerms })
        this.initData()
      }
    }
  }

  public renderTerms() {
    const classes = this.props.classes
    const contract = this.props.contractFlow

    const { optionsWithTerms, termsOfTradeRef } = this.state
    const allowSubmit = this.state.isAllTermsChecked

    return (
      <React.Fragment>
        <h1>{this.getText(Mode.checkAllTerms)}</h1>

        <section className={classes.section}>
          <TermsOfServiceForm
            classes={classes}
            contract={contract}
            options={optionsWithTerms as TContractOption[]}
            termsOfTradeRef={termsOfTradeRef}
            onChangeAllTermsChecked={this.onChangeAllTermsChecked}
          />
        </section>

        <LayoutActions>
          <LayoutActionsLeft>
            <Button onClick={this.onBackClick} size="small" variant="outlined">
              {/*<Button size="small" variant="outlined">*/}
              <ArrowBackIosIcon className={classes.buttonIcon} />
              {t('Back')}
            </Button>
          </LayoutActionsLeft>
          <LayoutActionsRight>
            <React.Fragment>
              <Button
                className={classes.button}
                size="small"
                color="secondary"
                variant="contained"
                disabled={!allowSubmit}
                onClick={this.handlePurchaseContract}
                data-e2e={'ContractFlowPagePayment__button-purchaseOffer'}
              >
                <ShoppingCartIcon className={classes.buttonIcon} />
                {t('Agree and continue')}
              </Button>
            </React.Fragment>
          </LayoutActionsRight>
        </LayoutActions>
      </React.Fragment>
    )
  }

  public render() {
    const classes = this.props.classes

    const isRenderTerms: boolean = this.state.mode === Mode.checkAllTerms
    const message: string = this.getText(this.state.mode)

    switch (this.state.mode) {
      case Mode.readyForPrepare:
        this.setState({ mode: Mode.Preparing })
        this.doPreparePayment()
        break
      case Mode.readyForCallStripe:
        this.setState({ mode: Mode.CallingStripe })
        this.doCallCheckout()
        break
      default:
        break
    }

    return (
      <LayoutRow columns={3} className={classes.root}>
        <LayoutColumn />
        <LayoutColumn>
          {isRenderTerms && <div className={classes.acceptTermsContainer}>{this.renderTerms()}</div>}
          {!isRenderTerms && (
            <div className={classes.stripeCheckoutContainer}>
              <h1 className="message">{message}</h1>
              <Spinner className="spinner" name="circle" color="black" fadeIn="quarter" />
            </div>
          )}
        </LayoutColumn>
        <LayoutColumn />
      </LayoutRow>
    )
  }

  protected abstract receiptPath(input: string): string

  protected abstract errorPath(input: string): string

  protected abstract sendPrepareCheckoutRequest(
    body: IPreparePaymentRequest,
  ): Promise<IJsonStatus<IPreparePaymentResponse, ApiError>>

  protected abstract skipTermsCheck(): boolean

  protected async doCatch(error: Error | string, info?: object) {
    // Display fallback UI
    const msg: string = error instanceof Error ? error.message : error
    this.setState({ mode: Mode.error, errMsg: msg })
    notify.error({ message: msg, errorType: 'GENERIC_FILE_UPLOAD_ERROR' })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    await this.delay(2000)

    browserHistory.goBack()
  }

  private initData = async () => {
    const contractId = this.props.match.params.serviceContractIdAsString

    // Run in parallell.
    const [isSuccess1, isSuccess2] = await Promise.all([
      this.fetchContractCreationData(),
      this.fetchtContractSpecifics(contractId),
    ])

    if (isSuccess1 && isSuccess2) {
      this.setState({
        mode: Mode.checkAllTerms,
        isInvalidContract: false,
      })
    } else {
      this.setState({ isInvalidContract: true })
      this.doCatch(t('There was an error trying to load contract information. Please try again in a little while.'))
    }
  }

  private fetchContractCreationData = async () => {
    const response = await getContractCreationData()

    if (response && response.data && response.data.termsOfTradeRef) {
      const termsOfTradeRef = response.data.termsOfTradeRef
      this.setState({ termsOfTradeRef })
      return true
    } else if (response && response.data) {
      return true
    }
    if (response.statusCode && response.statusCode === 404) {
      this.setState({ isInvalidContract: true })
    }
    return false
  }

  private fetchtContractSpecifics = async (contractId: string) => {
    const response = await getContractSpecifics(contractId)

    if (response && response.data && response.data.includedOptions) {
      const optionsWithTerms: any = []

      response.data.includedOptions.forEach((item) => {
        if (item.termsOfService) {
          optionsWithTerms.push(item)
        }
      })

      this.setState({ optionsWithTerms })
      return true
    } else if (response.statusCode && response.statusCode === 404) {
      this.setState({ isInvalidContract: true })
    }

    return false
  }

  private onBackClick() {
    browserHistory.goBack()
  }

  private onChangeAllTermsChecked = (isAllTermsChecked: boolean) => {
    this.setState({ isAllTermsChecked })
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  private async doPreparePayment() {
    try {
      // Prepare parameters.
      const prettyIdentifier = this.prettyIdentifier || ''
      const prepRequest: IPreparePaymentRequest = {
        prettyIdentifier,
        cancel_url: this.localUrl(this.errorPath(prettyIdentifier)),
        success_url: this.localUrl(this.receiptPath(prettyIdentifier)),
      }

      // Do the calls.
      const preparPaymenResponse = await this.sendPrepareCheckoutRequest(prepRequest)

      // Error checking -- TODO handle more properly.
      if (!preparPaymenResponse) {
        throw new Error('Did not get any response on sendPreparePaymentRequest')
      }
      const resp: IPreparePaymentResponse | undefined = preparPaymenResponse.data
      if (!resp || !resp.stripePublicKey || !resp.stripeAccount || !resp.checkoutSessionId) {
        throw new Error('Invalid response from sendPreparePaymentRequest')
      }

      this.setState({
        mode: Mode.readyForCallStripe,
        stripePublicKey: resp.stripePublicKey,
        stripeAccount: resp.stripeAccount,
        checkoutSessionId: resp.checkoutSessionId,
      })
    } catch (error) {
      this.doCatch(error)
    }
  }

  private async doCallCheckout() {
    try {
      await launchCheckoutWindow(this.state.stripePublicKey, this.state.stripeAccount, this.state.checkoutSessionId)
    } catch (error) {
      this.doCatch(t('There was an error trying to contact the payment processor. Please try again in a little while.'))
    }
  }

  private isValidContractId(contractId: string): boolean {
    return /^\d{3,4}-\d{7}$/.test(contractId)
  }

  private localUrl(page: string): string {
    return `${window.location.protocol}//${window.location.host}${page}`
  }

  private getText(mode: Mode): string {
    const message: string | undefined = this.dicText.get(mode)
    if (!message) {
      throw new Error('Text for mode missing')
    }
    return message
  }

  private handlePurchaseContract = async () => {
    this.onContinueClick()
  }

  private onContinueClick() {
    this.setState({ mode: Mode.readyForPrepare })
  }
}
