import { applyMiddleware, compose, createStore } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import rootReducer from 'reducers/rootReducer'
import { initialState, IRootState } from 'reducers/initialState'
import { routerMiddleware } from 'react-router-redux'

// @TODO: Again - do not allow any!!
const configureStore = (history: any) => {
  const middlewares = [
    // Add other middleware on this line...
    thunk as ThunkMiddleware<IRootState, any>,

    // Redux middleware that spits an error on you when you try to mutate your state either inside
    // a dispatch or between dispatches.
    // @NOTE: Disabled as we do not want error outputs in PROD
    // reduxImmutableStateInvariant(),

    // Connected React Router middleware
    routerMiddleware(history),
  ]

  return createStore<IRootState>(rootReducer, initialState, compose(applyMiddleware(...middlewares)))
}

export default configureStore
