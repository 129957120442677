import { refreshTokens } from 'api/api'
import { setToken, getAuth, clearToken, setTokenRefreshStarted, setAuth, getTokenRefreshStarted } from './localStorage'
import store from './store'
import { storeReset } from 'actions/storeActions'
import { getRefreshToken, saveRefreshToken, removeRefreshToken } from 'utils/cookies'
import { sleep } from './date'

const REFRESH_TOKEN_INTERVAL = 55 * 60 * 1000 // 55 minutes

export const handleRefreshToken = async () => {
  const currentRefreshToken = getRefreshToken()

  if (!currentRefreshToken) {
    clearTokens()
    return
  }

  const anotherRefreshStarted = await waitForTokenRefresh()

  if (anotherRefreshStarted) {
    return
  }

  const response = await refreshTokens(currentRefreshToken)
  const { token, refreshToken, userInfo } = response.data || {}

  if (response.statusCode === 200 && token && refreshToken && userInfo) {
    const isSameUser = userInfo.email === getAuth()?.userInfo?.email

    if (!isSameUser) {
      clearTokens()
      return
    }

    setToken(token)
    saveRefreshToken(refreshToken)
    // wait 2 seconds before resetting started date
    // to prevent multiple refreshes
    await sleep(2000)
    setTokenRefreshStarted(0)
  } else {
    clearTokens()
  }
}

export const setRefreshTokenInterval = () =>
  setInterval(() => {
    handleRefreshToken()
  }, REFRESH_TOKEN_INTERVAL)

/**
 * Clears refresh-token and access-token.
 * Also, clears cookies/localStorage/redux.
 */
export const clearTokens = () => {
  // clear tokens
  removeRefreshToken()
  clearToken()
  // clear refreshing date
  setTokenRefreshStarted(0)
  // clear local storage auth data
  setAuth({ valid: false })
  // reset store
  store.dispatch(storeReset())
}

/**
 * Waits for token refresh if needed.
 * @returns true if should wait for another refresh, false otherwise
 **/
export const waitForTokenRefresh = async (): Promise<boolean> => {
  const now = new Date().getTime()
  const shouldWait = (now - getTokenRefreshStarted()) / 1000 < 2 // wait if refresh requested less than 2 seconds ago

  if (shouldWait) {
    // wait 2 seconds for token refresh before retry
    await sleep(2000)
  } else {
    // set token refresh started date
    setTokenRefreshStarted(now)
  }
  return shouldWait
}
