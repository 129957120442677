import * as React from 'react'
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons'

interface IProps {
  onCancel: () => void
  onSubmit: () => void
  onEdit: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  isEditing: boolean
}

type TProps = IProps & WithStyles<typeof styles>
const styles = (theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 0,
      width: 'inherit',
      height: 'inherit',
      fontSize: 'inherit',
    },
  })

const ContractStartDateInput: React.SFC<TProps> = ({
  onCancel,
  onEdit,
  onSubmit,
  isEditing,
  value,
  classes,
  onChange,
}) =>
  isEditing ? (
    <>
      <input value={value} className="ContractDetailsSpecifics__edit-input" onChange={onChange} />

      <IconButton onClick={onCancel} classes={{ root: classes.iconButton }}>
        <ClearIcon fontSize="inherit" color="error" />
      </IconButton>

      <IconButton onClick={onSubmit} classes={{ root: classes.iconButton }}>
        <CheckIcon fontSize="inherit" color="primary" />
      </IconButton>
    </>
  ) : (
    <IconButton onClick={onEdit} classes={{ root: classes.iconButton }}>
      <EditIcon fontSize="inherit" />
    </IconButton>
  )

export default withStyles(styles)(ContractStartDateInput)
