import React, { PureComponent, CSSProperties } from 'react'
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import { theme } from 'theme'
import classNames from 'classnames'

interface IProps extends WithStyles<typeof styles> {
  leadingSVG?: React.SVGProps<SVGSVGElement>
  title: string
  value: number | string
  styling?: { root?: CSSProperties; title?: CSSProperties; value?: CSSProperties; valueActive?: CSSProperties }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: spacing(1.5),
    },
    svg: {
      margin: 'auto',
      marginRight: '10px',
    },
    title: {
      color: theme.palette.text.light,
    },
    value: {
      fontSize: 40,
    },
    valueActive: {
      textDecoration: 'underline',
    },
    extraMargin: {
      marginLeft: '20px',
    },
  })

class SingleValue extends PureComponent<IProps, {}> {
  public render() {
    const { classes, title, value, styling, leadingSVG } = this.props

    return (
      <div className={classNames(classes.root, styling && styling.root)}>
        <div className={classes.titleWrapper}>
          {!!leadingSVG && (
            <svg className={classes.svg} viewBox="-9 -8 19 16" height={15} width={15}>
              {leadingSVG}
            </svg>
          )}
          <Typography variant="body2" className={classNames(classes.title, styling && styling.title)}>
            {title}
          </Typography>
        </div>
        <Typography
          variant="header"
          className={classNames(
            classes.value,
            styling && styling.value,
            styling && styling.valueActive,
            leadingSVG && classes.extraMargin,
          )}
        >
          {value}
        </Typography>
      </div>
    )
  }
}

export default withStyles(styles)(SingleValue)
