import React, { SFC, CSSProperties } from 'react'
import StripeTOS from 'components/admin/Stripe/TOS'
import { CardContent, createStyles, WithStyles, withStyles, Theme, Tooltip } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import Typography from 'components/Typography'
import { t } from 'translations/translationFunctions'
import { theme } from 'theme'
import { Card } from 'components/Mui/Card'
import { getProviderCountry } from '../../../../../../utils/localStorage'

interface IProps extends WithStyles<typeof styles> {
  className: CSSProperties
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    title: {
      marginBottom: spacing(1),
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center',
    },
    tooltip: {
      display: 'none',
      fontSize: 16,
      color: theme.palette.text.light,
      marginLeft: spacing(1),
    },
  })

const Stripe: SFC<IProps> = ({ classes }) => (
  <>
    <div className={classes.headerRow}>
      <Typography className={classes.title} variant="subheading">
        {t('Finance')}
      </Typography>
      <Tooltip title={''} className={classes.tooltip}>
        <InfoIcon />
      </Tooltip>
    </div>
    <Card>
      <CardContent>
        <StripeTOS providerCountry={!getProviderCountry() ? '' : getProviderCountry()} />
      </CardContent>
    </Card>
  </>
)

export default withStyles(styles)(Stripe)
