import React from 'react'
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export type BalanceChartShape =
  | 'outlinedCircle'
  | 'outlinedSquare'
  | 'dubbleSquare'
  | 'dubbleCricle'
  | 'square'
  | 'circle'
  | 'diamond'

interface IOwnProps {
  className?: string
  fill: string
  row?: number
  positionInPixels: { x: number; y: number }
  shape: BalanceChartShape
  strokeWidth?: number
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    square: {
      paddingBottom: '5px',
      transform: 'translate(-4.5px, -5px)',
      rotate: '180',
    },
    triangle: {
      width: '10px !important',
      height: '10px !important',
      transform: 'translate(-29.5px, -9px)',
    },
    downwardsTriangle: {
      width: '10px !important',
      height: '10px !important',
      transform: 'rotate(180deg) translate(-28.5px, -9px)',
    },
    diamond: {
      width: '10px !important',
      height: '10px !important',
      transform: 'rotate(45deg) translate(-4.5px, -5px)',
    },
    dubbleSquare: {
      transform: 'translate(2.5px, 2.5px)',
    },
  })

class SVGMark extends React.Component<TProps> {
  render() {
    let renderShape: () => JSX.Element
    switch (this.props.shape) {
      case 'outlinedSquare':
      case 'square':
        renderShape = this.renderSquare
        break
      case 'outlinedCircle':
      case 'circle':
        renderShape = this.renderCircle
        break
      case 'diamond':
        renderShape = this.renderDiamond
        break
      case 'dubbleCricle':
        renderShape = this.renderRadioButton
        break
      case 'dubbleSquare':
        renderShape = this.renderDubbleSquare
        break
      default:
        renderShape = () => <></>
        break
    }

    return <g>{renderShape()}</g>
  }

  private renderRadioButton = () => {
    const { fill } = this.props

    return (
      <g>
        <circle
          className={classNames(this.props.className)}
          fill={'white'}
          stroke={fill}
          strokeWidth="3px"
          cx="0"
          cy="0"
          r={5.5}
        />
        <circle
          className={classNames(this.props.className)}
          fill={fill}
          stroke={fill}
          strokeWidth="0px"
          cx="0"
          cy="0"
          r={2}
        />
      </g>
    )
  }

  private renderDubbleSquare = () => {
    const { classes, fill, strokeWidth } = this.props
    return (
      <g className={classes.square}>
        <rect width={10} height={10} fill={'white'} stroke={fill} strokeWidth={strokeWidth} />
        <rect className={classes.dubbleSquare} width={5} height={5} fill={fill} />
      </g>
    )
  }

  private renderSquare = () => {
    const { classes, fill, shape, strokeWidth } = this.props
    return (
      <rect
        width={10}
        height={10}
        className={classes.square}
        fill={shape === 'outlinedSquare' ? 'white' : fill}
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    )
  }

  private renderDiamond = () => {
    const { className, classes, fill, strokeWidth } = this.props
    return (
      <rect
        width={10}
        height={10}
        className={classNames(className, classes.diamond)}
        fill={fill}
        stroke={fill}
        strokeWidth={strokeWidth}
      />
    )
  }

  private renderCircle = () => {
    const { fill, shape, strokeWidth } = this.props

    return (
      <circle
        className={classNames(this.props.className)}
        fill={shape === 'outlinedCircle' ? 'white' : fill}
        stroke={fill}
        strokeWidth={strokeWidth}
        cx="0"
        cy="0"
        r={5.5}
      />
    )
  }
}

export default withStyles(styles)(SVGMark)
