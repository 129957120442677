import React, { ChangeEvent } from 'react'
import { t } from 'translations/translationFunctions'
import {
  Button,
  Checkbox,
  Chip,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  Tooltip,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import { ContractActionType } from '@omnicar/sam-types'
import { SelectProps } from '@material-ui/core/Select'
import { ISettlementActionFilters, TActionFilterName, SHARED_COMMENTS, TYPE, UNSHARED_COMMENTS } from 'types/settlement'
import { connect } from 'react-redux'
import { bindDispatch } from 'utils/redux'
import { setActionsFilter, resetActionsFilters } from 'actions/contractSettlement'
import { IRootState } from 'reducers/initialState'
import { selectSettlementActionFilters, selectActionTypeOptions } from 'selectors/contractSettlement'

interface IOwnProps {
  expanded: boolean
  numberOfFilters: number
}

interface IReduxStateProps {
  actionFilters: ISettlementActionFilters
  actionTypeOptions: ContractActionType[]
}

interface IReduxDispatchProps {
  setActionsFilter: (name: TActionFilterName, value: ContractActionType[] | boolean) => void
  resetActionsFilters: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles> & IReduxDispatchProps & IReduxStateProps

const styles = ({ spacing }: Theme) =>
  createStyles({
    chip: {
      marginRight: spacing(0.5),
      backgroundColor: '#C2CEE2',
    },
    container: {
      flexDirection: 'column',
    },
    formContainer: {
      alignItems: 'center',
      paddingTop: spacing(2),
    },
    label: {
      paddingRight: spacing(1.5),
    },
    expanded: {
      margin: 0,
      backgroundColor: '#DFE5F0',
    },
  })
const SettlementActionFilters: React.SFC<TProps> = ({
  expanded,
  actionTypeOptions,
  actionFilters,
  classes,
  numberOfFilters,
  setActionsFilter,
  resetActionsFilters,
}) => {
  const renderValue = (selected: SelectProps['value']) => (
    <div>
      {(selected as ContractActionType[]).map((option) => (
        <Chip key={option} label={ContractActionType[option]} classes={{ root: classes.chip }} />
      ))}
    </div>
  )
  const handleSelectMultiple = (e: React.ChangeEvent<{ value: unknown; name: string }>) =>
    setActionsFilter(e.target.name as TActionFilterName, e.target.value as ContractActionType[])

  const handleChecked = (e: React.ChangeEvent<{ checked: boolean; name: string }>) =>
    setActionsFilter(e.target.name as TActionFilterName, e.target.checked)

  const clearAllFilters = () => resetActionsFilters()
  return (
    <ExpansionPanel elevation={0} expanded={expanded} classes={{ root: classes.expanded }}>
      <ExpansionPanelDetails classes={{ root: classes.container }}>
        <FormControl fullWidth>
          <InputLabel htmlFor={`actionFiltersField`}>{t('Contract action type')}</InputLabel>
          <Select
            multiple
            value={actionFilters.type}
            onChange={(e: ChangeEvent<any>) => handleSelectMultiple(e)}
            input={<Input id={`actionFiltersField`} />}
            renderValue={renderValue}
            name={TYPE}
          >
            {actionTypeOptions.map((option: ContractActionType) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={actionFilters.type.indexOf(option) > -1} />
                {/* <ListItemText primary={t(ContractActionType[option] as keyof typeof ContractActionType)} /> */}
                <ListItemText primary={t(ContractActionType['' + option])} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormGroup row classes={{ root: classes.formContainer }}>
          <FormLabel component="legend" classes={{ root: classes.label }}>
            {t('Settlement comments')}
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={actionFilters.sharedComments}
                onChange={handleChecked}
                name={SHARED_COMMENTS}
                color="secondary"
              />
            }
            label={t('shared')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={actionFilters.unsharedComments}
                onChange={handleChecked}
                name={UNSHARED_COMMENTS}
                color="secondary"
              />
            }
            label={t('not shared')}
          />
        </FormGroup>
        <div>
          {numberOfFilters > 0 && (
            <Tooltip title={t('Clear all filters')} placement="top">
              <Button onClick={clearAllFilters}>{t('Clear all filters')}</Button>
            </Tooltip>
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const mapState = (state: IRootState) => ({
  actionFilters: selectSettlementActionFilters(state),
  actionTypeOptions: selectActionTypeOptions(state),
})

export default withStyles(styles)(
  connect(mapState, bindDispatch({ setActionsFilter, resetActionsFilters }))(SettlementActionFilters),
)
