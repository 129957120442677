import ActionTypes from 'actions/ActionTypes'
import ActionTypeKeys from 'actions/ActionTypeKeys'
import { contractFlowInitialState } from './contractFlowInitialState'

export const contractFlowReducer = (state = contractFlowInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.CONTRACTFLOW_UPDATE:
      return {
        ...state,
        ...action.values,
      }

    case ActionTypeKeys.CONTRACTFLOW_RESET:
      return {
        ...contractFlowInitialState,
      }

    case ActionTypeKeys.CONTRACTFLOW_UPDATE_ACTIVE_PANEL:
      return {
        ...state,
        state: {
          ...state.state,
          active: action.active,
        },
      }

    case ActionTypeKeys.CONTRACTFLOW_UPDATE_ACTIVE_STEP:
      return {
        ...state,
        state: {
          ...state.state,
          active: action.step,
        },
      }

    case ActionTypeKeys.CONTRACTFLOW_UPDATE_STATE:
      return {
        ...state,
        state: {
          ...state.state,
          ...action.state,
        },
      }

    case ActionTypeKeys.CONTRACTFLOW_UPDATE_CONTRACT_TYPE:
      return {
        ...state,
        contractType: action.contractType,
      }

    default:
      return state
  }
}
