import React from 'react'
import {
  Vehicle,
  VehicleAlongItsContracts,
  IAvailableFreeWarranty,
  IAvailableFreeWarrantyDurationPrice,
  IAvailableFreeWarrantyRequest,
  IAvailableFreeWarrantyResponse,
  ApiError,
  ProductAlongItsContracts,
  TVehicleType,
} from '@omnicar/sam-types'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { ContractFlowActivePanel } from 'types/contractFlow'
import { errorMessage } from 'utils/errorHandling'
import { getAvailableFreeWarranties } from 'api/api'
import equal from 'fast-deep-equal'
import { TranslationItem, TranslationKey } from 'translations/translationTypes'
import { getProvider } from 'utils/localStorage'

interface IState {
  isDisableThisThing: boolean // If true then will disable this component from rendering and fetching any data etc.
  chosenWarranty: IAvailableFreeWarranty | null
  fetching: boolean
  warrantyCreated: boolean
  durationPrice: IAvailableFreeWarrantyDurationPrice | null
  availableWarranties: IAvailableFreeWarranty[]
  areAnyCustomerPricesSet: boolean
  vehicleHasActiveWarranty: boolean
  hasNotVehicleBeenFoundInDatabase: boolean
  minMilageDiffPerYear: number

  fetchAvailableWarrantiesError: TranslationItem
  statusText: TranslationItem
}

interface IInjectedProps {
  fetching: boolean
  onFreeWarrantyButtonClick: (w: IAvailableFreeWarranty, dp: IAvailableFreeWarrantyDurationPrice) => void
  chosenWarranty: IAvailableFreeWarranty | null
  durationPrice: IAvailableFreeWarrantyDurationPrice | null
  availableWarranties: IAvailableFreeWarranty[]
  statusText: TranslationItem
}

interface IExternalProps {
  activePanel: ContractFlowActivePanel
  vehicleAlongItsContracts: VehicleAlongItsContracts | ProductAlongItsContracts
  startMileage: number | undefined
  onWarrantyCreated: () => void
  showCurrentMileageField: boolean
  doClose?: () => void
  onUpdateAvailableWarranties?: (hasAvailbleWarranties: boolean) => void
  onFetchedWarranties?: (
    fetchAvailableWarrantiesError: TranslationItem,
    vehicleHasActiveWarranty: boolean,
    hasAvailableWarranties: boolean,
  ) => void
  [k: string]: any
}

// Error messages
const commonMsg: { [key: string]: TranslationKey } = {
  GENERIC_ERROR: 'Something went wrong',
}

const msg: { [key: string]: TranslationKey } = {
  ...commonMsg,
  VEHICLE_DATA_MISSING: 'Vehicle data not complete',
  missingStartMileage: 'Current mileage is missing',
}

export default <P extends IExternalProps>(Component: React.ComponentType<P & IInjectedProps>) =>
  class WithFreeWarranty extends React.Component<P, IState> {
    public state: IState = {
      isDisableThisThing: false,
      fetching: false,
      chosenWarranty: null,
      durationPrice: null,
      warrantyCreated: false,
      fetchAvailableWarrantiesError: undefined,
      availableWarranties: [],
      areAnyCustomerPricesSet: false,
      vehicleHasActiveWarranty: false,
      hasNotVehicleBeenFoundInDatabase: false,
      minMilageDiffPerYear: 0,
      statusText: undefined,
    }

    private constructor(props: P) {
      super(props)

      const provider = getProvider()

      if (provider?.isUsingV4PricingTool) {
        this.state.isDisableThisThing = true
      }
    }

    public componentDidMount() {
      this.refreshWarranties()
      this.updateStatusText()
    }

    public componentDidUpdate(prevProps: P) {
      if (!equal(this.props.vehicleAlongItsContracts, prevProps.vehicleAlongItsContracts)) {
        this.refreshWarranties()
      }
      this.updateStatusText()
    }

    public render() {
      if (this.state.isDisableThisThing) {
        return ''
      }

      const { vehicleAlongItsContracts } = this.props
      const {
        fetching,
        chosenWarranty,
        durationPrice,
        availableWarranties,
        areAnyCustomerPricesSet,
        statusText,
      } = this.state

      if (process.env.NODE_ENV !== 'production') {
        console.log('dev message: availableWarranties:')
        console.log(availableWarranties)
      }

      // --- Filter the Warranties based on fueltypes. --------------------------------------
      const vehicleFuelTypeName =
        vehicleAlongItsContracts && vehicleAlongItsContracts.fuelType && vehicleAlongItsContracts.fuelType?.name
      const availableWarrantiesBasedOnFuelType: IAvailableFreeWarranty[] = []
      availableWarranties.forEach((warranty: IAvailableFreeWarranty) => {
        if (!warranty.fuelTypes) {
          // If fuelTypes is null, then it is available for all fueltypes.
          availableWarrantiesBasedOnFuelType.push(warranty)
        } else {
          warranty.fuelTypes.forEach((ftName) => {
            const warrantyFuelTypeName = ftName && ftName.toLowerCase()
            if (vehicleFuelTypeName && warrantyFuelTypeName === vehicleFuelTypeName.toLowerCase()) {
              availableWarrantiesBasedOnFuelType.push(warranty)
            }
          })
        }
      })
      // ------------------------------------------------------------------------------------

      if (process.env.NODE_ENV !== 'production') {
        console.log('dev message: vehicleFuelTypeName:')
        console.log(vehicleFuelTypeName)
        console.log('dev message: availableWarrantiesBasedOnFuelType:')
        console.log(availableWarrantiesBasedOnFuelType)
      }

      return (
        <Component
          {...this.props}
          fetching={fetching}
          onFreeWarrantyButtonClick={this.handleFreeWarrantyButtonClick}
          chosenWarranty={chosenWarranty}
          durationPrice={durationPrice}
          availableWarranties={availableWarrantiesBasedOnFuelType}
          areAnyCustomerPricesSet={areAnyCustomerPricesSet}
          statusText={statusText}
        />
      )
    }

    private refreshWarranties = () => {
      if (this.state.isDisableThisThing) {
        return
      }

      if (this.existsVehicleInfo()) {
        this.updateAvailableWarranties()
        this.updateStatusText()
      } else {
        this.clearAvailableWarranties({ key: msg.VEHICLE_DATA_MISSING })
      }
    }

    private existsVehicleInfo = (): boolean => {
      const { vehicleAlongItsContracts } = this.props
      if (!vehicleAlongItsContracts || !('vin' in vehicleAlongItsContracts)) return false

      const v: VehicleAlongItsContracts | undefined = vehicleAlongItsContracts as VehicleAlongItsContracts
      try {
        const ret = !!(v && v.brand.name && v.model.name && v.fuelType.name && v.regDate && v.vin && v.regNumber)
        return ret
      } catch (err) {
        console.warn(err)
        return false
      }
    }

    private clearAvailableWarranties = (errmsg: TranslationItem) => {
      this.setState({ fetchAvailableWarrantiesError: errmsg, availableWarranties: [] })
    }

    private updateAvailableWarranties = async () => {
      const { vehicleAlongItsContracts, onFetchedWarranties } = this.props
      if (!vehicleAlongItsContracts || !('vin' in vehicleAlongItsContracts)) {
        onFetchedWarranties && onFetchedWarranties(undefined, false, false)
        return this.clearAvailableWarranties({ key: msg.VEHICLE_DATA_MISSING })
      }
      const vehicle: Vehicle | undefined = vehicleAlongItsContracts as Vehicle

      if (!vehicle || !vehicle.vin) {
        onFetchedWarranties && onFetchedWarranties(undefined, false, false)
        return this.clearAvailableWarranties({ key: msg.VEHICLE_DATA_MISSING })
      }

      this.setState({ fetching: true })
      const req: IAvailableFreeWarrantyRequest = {
        vehicleId: vehicle.id,
        vin: vehicle.vin,
        regNumber: vehicle.regNumber || '',
        regDate: vehicle.regDate ? vehicle.regDate : '',
        brandName: vehicle.brand.name,
        brandId: vehicle.brand.id,
        vehicleModelName: vehicle.model.name,
        vehicleModelId: vehicle.model.id,
        modelYear: vehicle.modelYear,
        fuelTypeName: vehicle.fuelType.name,
        fuelTypeId: vehicle.fuelType.id,
        startMileage: 0, // We send 0 mileage for get all types of warranties, filter out before show available durations
        engineMaxPower: !vehicle.engineMaxPower ? 0 : Math.round(vehicle.engineMaxPower), // Maximum power in kW, integer number like 132.
        vehicleType: vehicle.vehicleType as TVehicleType,
      }

      const res: IJsonStatus<IAvailableFreeWarrantyResponse, ApiError> = await getAvailableFreeWarranties(req)

      if (res.statusCode === 499) {
        this.setState({
          hasNotVehicleBeenFoundInDatabase: true,
          statusText: { key: 'Vehicle can not be found in our database' },
        })
        onFetchedWarranties && onFetchedWarranties(undefined, false, false)
        return
      } else {
        this.setState({
          hasNotVehicleBeenFoundInDatabase: false,
        })
      }

      const errMsg = errorMessage(res)
      if (errMsg) {
        this.setState({ warrantyCreated: false, statusText: errMsg })
      }

      const vehicleHasActiveWarranty = res.data ? res.data.vehicleHasActiveWarranty : false
      const availableWarranties = res.data ? res.data.availableWarranties : []
      const areAnyCustomerPricesSet = availableWarranties.some((w) =>
        w.durationsPrices.some((p) => p.customerPrice !== null),
      )

      if (errMsg) {
        console.error('FreeWarrantyHOC: An error occured, message:')
        console.error(errMsg)
      }

      this.setState({
        fetchAvailableWarrantiesError: errMsg || undefined,
        vehicleHasActiveWarranty,
        availableWarranties,
        areAnyCustomerPricesSet,
        fetching: false,
        minMilageDiffPerYear: res.data ? res.data.minMilageDiffPerYear : 0,
      })

      onFetchedWarranties && onFetchedWarranties(errMsg, vehicleHasActiveWarranty, availableWarranties.length > 0)
    }

    private handleFreeWarrantyButtonClick = (
      warranty: IAvailableFreeWarranty,
      warrantyDurationPrice: IAvailableFreeWarrantyDurationPrice,
    ) =>
      this.setState({
        chosenWarranty: warranty,
        durationPrice: warrantyDurationPrice,
      })

    private updateStatusText = (): TranslationItem => {
      const existsVehicleInfo = this.existsVehicleInfo()

      const { activePanel, showCurrentMileageField, wasModelNameReMappedAtStartup } = this.props
      const {
        fetchAvailableWarrantiesError,
        vehicleHasActiveWarranty,
        availableWarranties,
        statusText,
        hasNotVehicleBeenFoundInDatabase,
      } = this.state

      const hasStartMileage = this.props.startMileage !== undefined

      let ret: TranslationItem = undefined
      if (hasNotVehicleBeenFoundInDatabase) {
        ret = { key: 'Vehicle can not be found in our database' }
        return ret
      }

      if (fetchAvailableWarrantiesError && fetchAvailableWarrantiesError.key !== msg.GENERIC_ERROR) {
        ret = fetchAvailableWarrantiesError
      } else if (
        (activePanel >= 0 && activePanel <= ContractFlowActivePanel.searchVehicle) ||
        !this.existsVehicleInfo()
      ) {
        ret = { key: 'Vehicle data not complete' }
      } else if (!availableWarranties.length && existsVehicleInfo && wasModelNameReMappedAtStartup) {
        ret = { key: 'An active warranty already exists on this vehicle' }
      } else if (!hasStartMileage && !showCurrentMileageField) {
        ret = { key: 'Current mileage is missing' }
      } else if (vehicleHasActiveWarranty) {
        ret = { key: 'Vehicle already has active warranty' }
      } else if (!availableWarranties.length || availableWarranties.length === 0) {
        ret = { key: 'No warranties available for this vehicle' }
      }

      if (JSON.stringify(statusText) !== JSON.stringify(ret)) {
        this.setState({ statusText: ret })
      }

      return ret
    }
  }
