import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints }: Theme) => {
  return createStyles({
    paper: {
      position: 'relative',
      backgroundColor: theme.palette.background.light,
      border: 0,
      paddingTop: spacing(3),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      [breakpoints.up('sm')]: {
        width: '50%',
      },
    },
    modal: {
      '&, [class*=Backdrop]': {
        top: spacing(6),

        [breakpoints.up('sm')]: {
          top: spacing(12),
        },
      },
    },
    tabContainer: {
      width: '100%',
    },
    longContainer: {
      height: '100px',
    },
    tab: {
      width: '100%',
      height: '60px !important',
      fontWeight: 'normal',
      fontSize: '1.2em',
    },
  })
}
