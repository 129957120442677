import React from 'react'
import Typography from '../../../../../../../../components/Typography'
import image from '../upload_example_image.jpg'
import { t } from '../../../../../../../../translations/translationFunctions'
import { formatMileage } from '@omnicar/sam-format'
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles'

interface IOwnProps {
  verticalSpacingClass: string
  validImportHeaders?: string[]
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    imageWrapper: {
      position: 'relative',
    },
    image: { width: '100%' },
    blackbox: {
      padding: spacing(1),
      backgroundColor: 'rgba(0,0,0,.9)',
      position: 'absolute',
      height: 'fit-content',
      top: '10%',
      bottom: '2%',
      left: '10%',
      right: '10%',
    },
    blackboxText: {
      color: 'white',
    },
    headers: {
      fontStyle: 'italic',
    },
    columnList: {
      listStyle: 'none',
      padding: 0,
      margin: `${spacing(1)} 0`,
    },
  })

class ImportInstructionsVehicle extends React.Component<TProps> {
  render() {
    const { classes, verticalSpacingClass, validImportHeaders } = this.props

    const headers = this.getHeaders(validImportHeaders)

    return (
      <>
        <Typography className={verticalSpacingClass} variant="body1">
          <a href="/workshop_import_template.xlsx" target="_blank">
            {t('Download XLS example')}
          </a>
        </Typography>
        <div className={classes.imageWrapper}>
          <img className={classes.image} src={image} alt="" />
          <div className={classes.blackbox}>
            <Typography className={classes.blackboxText} variant="body1">
              {t('The first line must contain the headers ')}
              <em className={classes.headers}>
                {validImportHeaders
                  ? validImportHeaders.map((h, i) => (
                      <span key={`Import-Instructions-Vehicle-${h}_${i}`}>
                        {h}
                        {i < validImportHeaders.length - 1 && ','}{' '}
                      </span>
                    ))
                  : 'date, price, mileage, vin, description'}
              </em>
            </Typography>
            <ul className={classes.columnList}>
              <Typography className={classes.blackboxText} variant="body1">{`A:
                    ${(headers && headers.dateHeader) || 'Date'} (${t(
                'Repair Date (date format: day/month/year)',
              )})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`B:
                    ${(headers && headers.priceHeader) || 'Price'} (${t('Invoiced amount ex. tax')})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`C:
                    ${(headers && headers.mileageHeader) || 'Mileage'} (${t('Current mileage (km)')})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`D: ${
                (headers && headers.vinHeader) || 'VIN'
              } (${t('Chassis number')})`}</Typography>
              <Typography className={classes.blackboxText} variant="body1">{`F: ${
                (headers && headers.descriptionHeader) || 'Description'
              } (${t('Text on invoice line')})`}</Typography>
            </ul>
            <Typography className={classes.blackboxText} variant="body1">
              {t('Max. %number lines per upload', {
                number: formatMileage(1000),
              })}
            </Typography>
          </div>
        </div>
      </>
    )
  }

  private getHeaders = (headers?: string[]) => {
    if (!headers || !headers.length) return undefined

    return {
      dateHeader: headers[0],
      priceHeader: headers[1],
      mileageHeader: headers[2],
      vinHeader: headers[3],
      descriptionHeader: headers[4],
    }
  }
}

export default withStyles(styles)(ImportInstructionsVehicle)
