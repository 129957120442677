import { Theme, createStyles } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary[500],
      height: theme.spacing(12),
      position: 'relative',
    },
  })
