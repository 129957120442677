import React from 'react'
import classNames from 'classnames'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { palette, createTypographyStyles } from 'theme'

interface IProps {
  className?: string
}

type TProps = IProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createTypographyStyles(theme, {
    root: {
      flexGrow: 2,
      color: palette.text.light,
    },
  })

const PanelTitle: React.SFC<TProps> = ({ classes, className, children }) => (
  <div className={classNames(classes.root, classes.subtitle, className)}>{children && children}</div>
)

export default withStyles(styles)(PanelTitle)
