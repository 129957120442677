import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Page, PageContent, PageHeader, PageTitle } from 'components/Mui/Page'
import { t } from 'translations/translationFunctions'

import PaymentSummary from 'pages/admin/ContractFlowPage/Payment/PaymentDialog'
import { contractDetailsPath } from 'routes/paths'
import { AppContext } from 'store/appContext'

interface IRouteProps {
  serviceContractIdAsString: string
}

type TProps = RouteComponentProps<IRouteProps>

export default class StripePaymentPage extends React.Component<TProps> {
  public render() {
    const { match, history } = this.props
    const { goBack } = history
    const { serviceContractIdAsString } = match.params

    return (
      <Page>
        <PageHeader>
          <PageTitle>{t('Payment')}</PageTitle>
        </PageHeader>
        <PageContent>
          <AppContext.Consumer>
            {({ locale }) => (
              <PaymentSummary
                onBack={goBack}
                onPreviewContract={this.gotoContract}
                contractPrettyIdentifier={serviceContractIdAsString}
                locale={locale}
              />
            )}
          </AppContext.Consumer>
        </PageContent>
      </Page>
    )
  }

  private gotoContract = () => {
    const { history, match } = this.props
    const { serviceContractIdAsString } = match.params

    history.push(contractDetailsPath(serviceContractIdAsString))
  }
}
