import { BounceType } from 'postmark/dist/client/models'
import { TranslationKey } from 'translations/translationTypes'

const bounceMessages: { [key in BounceType | string]: TranslationKey } = {
  HardBounce:
    'Hard Bounce: Could not deliver message. This could be because the the email address does not exist or the email has been blocked by mailbox provider',
  SoftBounce:
    'Soft bounce: Could not deliver message. This could be because the email account is disabled, inbox is full or receiver side delay',
  DnsError: 'DNS Error: Could not connect to the specific mail server this email belongs to',
  Blocked: 'ISP Block: Could not deliver message because it got blocked by mail provider',
  Unknown: 'Could not deliver message for unknown reasons',
}

export default bounceMessages
