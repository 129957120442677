import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatDate } from '@omnicar/sam-format'
import { IChartDateValue } from '@omnicar/sam-types'
import { IValueChart } from '@omnicar/sam-types/types/admin/chart/contractCharts'
import moment from 'moment'
import React, { Component } from 'react'
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  LineMarkSeriesPoint,
  XAxis,
  YAxis,
} from 'react-vis'
import 'react-vis/dist/style.css'
import Typography from '../../../../../../components/Typography'
import { theme } from '../../../../../../theme'
import { t } from '../../../../../../translations/translationFunctions'
import { TranslationKey } from '../../../../../../translations/translationTypes'
import Tooltip from '../ChartDateTooltip'

interface IProps extends WithStyles<typeof styles> {
  data: IValueChart
  translations: {
    value: TranslationKey
    expectedValue: TranslationKey
  }
}

interface IState {
  tooltipValue: IChartDateValue | undefined
}

interface ILegendItem {
  title: string
  color: string
  strokeWidth: number
  strokeStyle?: string
  strokeDasharray?: string
  disabled?: boolean
}

interface ILegendItems extends Array<ILegendItem> {}

const styles = () =>
  createStyles({
    root: {},
    tooltip: {
      display: 'flex',
    },
    exVat: {
      textAlign: 'right',
      color: theme.palette.text.light,
      fontStyle: 'italic',
    },
    legend: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })

class EconomyBalanceChart extends Component<IProps, IState> {
  public state: IState = {
    tooltipValue: undefined,
  }

  public render() {
    const { classes, data, translations } = this.props
    const { tooltipValue } = this.state
    const curveName = 'curveMonotoneX'

    // transform data
    const value = data ? this.transform(data.seriesValue) : []
    const expectedValue = data ? this.transform(data.seriesExpectedValue) : []

    const legendItems: ILegendItems = [
      {
        title: t(translations.value),
        color: theme.palette.secondary[500],
        strokeWidth: 3,
      },
      {
        title: t(translations.expectedValue),
        color: '#ccc',
        strokeStyle: 'dashed',
        strokeWidth: 3,
      },
    ]

    return (
      <div className={classes.root}>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />
        <FlexibleWidthXYPlot
          margin={{ left: 60 }}
          height={300}
          xType="time"
          xDomain={[new Date(moment(data.contractStartDate).startOf('month').toDate()), new Date(data.contractEndDate)]}
        >
          <YAxis />
          <XAxis tickTotal={0} />
          <HorizontalGridLines />
          <LineMarkSeries
            data={expectedValue}
            stroke="#ccc"
            fill="#ccc"
            strokeWidth={3}
            strokeStyle="dashed"
            size={3}
            curve={curveName}
          />
          <LineMarkSeries
            data={value}
            stroke={theme.palette.secondary[500]}
            fill={theme.palette.secondary[500]}
            strokeWidth={3}
            size={3}
            curve={curveName}
            // tslint:disable-next-line jsx-no-lambda
            onValueMouseOver={(datapoint) => {
              this.setState({ tooltipValue: datapoint as IChartDateValue })
            }}
            // tslint:disable-next-line jsx-no-lambda
            onValueMouseOut={() => {
              this.setState({ tooltipValue: undefined })
            }}
          />
          {tooltipValue && (
            <Hint value={tooltipValue}>
              <Tooltip value={tooltipValue} variant="mileage" />
            </Hint>
          )}
        </FlexibleWidthXYPlot>
        <div className={classes.legend}>
          <div>
            <Typography variant="subheading">{t('Startdate')}</Typography>
            <Typography variant="body2">
              {!data.contractStartDate ? '–' : formatDate(data.contractStartDate)}
            </Typography>
          </div>
          <div>
            <Typography variant="subheading">{t('Duration')}</Typography>
            <Typography variant="body2">{`${data.duration.current} / ${data.duration.total}`}</Typography>
          </div>
          <div>
            <Typography variant="subheading">{t('Enddate')}</Typography>
            <Typography variant="body2">{!data.contractEndDate ? '–' : formatDate(data.contractEndDate)}</Typography>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Transforms x from string > Date
   */
  private transform = (list: IChartDateValue[]): LineMarkSeriesPoint[] =>
    list.map((value) => ({
      ...value,
      x: new Date(value.x),
    }))
}
export default withStyles(styles)(EconomyBalanceChart)
