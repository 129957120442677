import { IAdminCustomer, IContractCreationData, PaymentGateway, UserRole } from '@omnicar/sam-types'
import ContractFlowPayments from 'components/admin/Contract/Flow/Payments'
import WarningMessage from 'components/admin/Contract/Flow/WarningMessage'
import { LayoutBlock } from 'components/Mui/Layout'
import React from 'react'
import { ContractFlowActivePanel, IContractFlow } from 'types/contractFlow'

interface IProps {
  active: ContractFlowActivePanel
  userRole: UserRole
  contract: IContractFlow
  freeContract: boolean
  hasVehicleRequiredInfo: boolean
  prettyIdentifier: string | undefined
  creationData: IContractCreationData | undefined
  onContractChange: (contract: Partial<IContractFlow>) => void
  onResetContractClick: () => void
  onCustomerChange: (value: IAdminCustomer, valid: boolean) => void
  onCustomerLockedChange: (locked: boolean) => void
  onWarrantyCreated: () => void
  readyForCalculation: () => boolean
  style?: any
  paymentGateways: PaymentGateway[]
  nextButtonDisabledWarning: string
  valid: { customer: boolean }
  contractUpdates: number
  vehicleUpdates: number
  customerUpdates: number
  allowNext: boolean
  onNext: () => void
  locale: string
}

class PaymentsColumn extends React.Component<IProps, never> {
  public render() {
    const {
      userRole,
      contract,
      freeContract,
      hasVehicleRequiredInfo,
      onContractChange,
      readyForCalculation,
      prettyIdentifier,
      paymentGateways,
      allowNext,
      onNext,
      locale,
    } = this.props

    return (
      <div data-e2e="PaymentsColumn">
        <LayoutBlock dense={true} data-e2e="PaymentsColumn__adjust">
          <ContractFlowPayments
            userRole={userRole}
            freeContract={freeContract}
            onChange={onContractChange}
            readyForCalculation={readyForCalculation}
            flow={contract.flowType}
            hasVehicleRequiredInfo={hasVehicleRequiredInfo}
            prettyIdentifier={prettyIdentifier}
            contract={contract}
            paymentGateways={paymentGateways}
            allowNext={allowNext}
            onNext={onNext}
            locale={locale}
          />
        </LayoutBlock>
        {/* Do not show error if we are using manual search */}
        {!contract.state.licenseSkip && <WarningMessage message={this.props.nextButtonDisabledWarning} />}
      </div>
    )
  }
}

export default PaymentsColumn
