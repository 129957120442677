import { Button, Drawer } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { ExitToApp as ExitToAppIcon, Person as PersonIcon } from '@material-ui/icons'
import Typography from 'components/Typography'
import React from 'react'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import styles from './styles'

interface IOwnProps {
  open: boolean
  onToggle: () => void
  onLogOut: () => void
  onToggleAccountSettings: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

class AppHeaderMenuSidebar extends React.Component<TProps> {
  public render() {
    const { classes, open, onToggle, onLogOut, onToggleAccountSettings } = this.props

    return (
      <AppContext.Consumer>
        {({ userInfo, providerInfo }) => (
          <Drawer anchor="right" open={open} onClose={onToggle} classes={{ paper: classes.paper }}>
            {userInfo && providerInfo && (
              <>
                <header className={classes.header}>
                  <div></div>
                </header>
                <section>
                  <Typography className={classes.userInfo} variant="title">
                    {userInfo.name}
                  </Typography>
                  <Typography className={classes.userInfo} variant="subtitle">
                    {userInfo.email}
                  </Typography>
                </section>
              </>
            )}
            <ul className={classes.menu}>
              <li>
                <Button className={classes.menuButton} onClick={onToggleAccountSettings}>
                  <PersonIcon className={classes.iconRight} />
                  {t('My account')}
                </Button>
              </li>
              <li>
                <Button className={classes.menuButton} onClick={onLogOut}>
                  <ExitToAppIcon className={classes.iconRight} />
                  {t('Sign out')}
                </Button>
              </li>
            </ul>
          </Drawer>
        )}
      </AppContext.Consumer>
    )
  }
}

export default withStyles(styles)(AppHeaderMenuSidebar)
