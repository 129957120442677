import { CardContent, createStyles, Theme, Tooltip, WithStyles, withStyles } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { getLocale } from '@omnicar/sam-translate'
import { IsoLocale, TContractObject } from '@omnicar/sam-types'
import { getValidHeadersFoWorkshopUpload } from 'api/api'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import Typography from 'components/Typography'
import React, { Component } from 'react'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import UploadWorkshopButton from './UploadWorkshopOperationButton'

interface IProps extends WithStyles<typeof styles> {}

interface IState {
  workshopOperationsDialog: boolean
  validWorkshopImportHeaders?: Record<TContractObject, string[]>
  currentLocale: IsoLocale
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    title: {
      marginBottom: spacing(1),
    },
    headerRow: {
      display: 'flex',
      alignItems: 'center',
    },
    cardPadding: {
      padding: spacing(2),
    },
    tooltip: {
      display: 'none',
      fontSize: 16,
      color: theme.palette.text.light,
      marginLeft: spacing(1),
    },
    buttonIcon: {
      fontSize: 16,
      marginRight: spacing(2),
      color: 'white',
    },
    uploadButton: {
      color: 'white',
      backgroundColor: theme.palette.secondary[500],
      '&:hover': {
        backgroundColor: theme.palette.secondary[700],
      },
    },
    paddingRight: {
      paddingRight: '24px',
    },
    buttonsBlock: {
      display: 'flex',
    },
  })

class Costs extends Component<IProps, IState> {
  public state: IState = {
    workshopOperationsDialog: false,
    validWorkshopImportHeaders: undefined,
    currentLocale: 'en-GB',
  }

  public async componentDidMount() {
    const locale = getLocale()

    const headers = await getValidHeadersFoWorkshopUpload(locale || 'en-GB')
    if (headers.data) {
      this.setState({ validWorkshopImportHeaders: headers.data })
    }
    this.setState({ currentLocale: locale || 'en-GB' })
  }

  public async componentDidUpdate() {
    const newLocale = getLocale()
    if (newLocale && this.state.currentLocale !== newLocale) {
      const headers = await getValidHeadersFoWorkshopUpload(newLocale)
      if (headers.data) {
        this.setState({ validWorkshopImportHeaders: headers.data })
      }

      this.setState({ currentLocale: newLocale as IsoLocale })
    }
  }

  public render() {
    const { classes } = this.props
    const { validWorkshopImportHeaders } = this.state

    return (
      <>
        <div>
          <div className={classes.headerRow}>
            <Typography className={classes.title} variant="subheading">
              {t('Costs')}
            </Typography>
            <Tooltip title={''} className={classes.tooltip}>
              <InfoIcon />
            </Tooltip>
          </div>
          <Card className={classNames(classes.cardPadding)}>
            <CardContent className={classes.buttonsBlock}>
              <div className={classes.paddingRight}>
                <UploadWorkshopButton
                  className={classNames(classes.uploadButton)}
                  text={t('Upload Workshop Operations For %type', { type: t('Vehicles') })}
                  onClick={this.toggleWorkshopOperationsDialog}
                  contractObjectType={'Vehicle'}
                  validImportHeaders={validWorkshopImportHeaders && validWorkshopImportHeaders['Vehicle']}
                />
              </div>
              <div>
                <UploadWorkshopButton
                  className={classNames(classes.uploadButton)}
                  text={t('Upload Workshop Operations For %type', { type: t('Other Products') })}
                  onClick={this.toggleWorkshopOperationsDialog}
                  contractObjectType={'Product'}
                  validImportHeaders={validWorkshopImportHeaders && validWorkshopImportHeaders['Product']}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      </>
    )
  }

  private toggleWorkshopOperationsDialog = (open?: boolean) => {
    // if open is set: open, else flip state
    const workshopOperationsDialog = typeof open !== 'undefined' ? open : !this.state.workshopOperationsDialog
    this.setState({ workshopOperationsDialog })
  }
}

export default withStyles(styles)(Costs)
