import { Dialog, DialogTitle } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Typography from 'components/Typography'
import React, { SFC } from 'react'
import { theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'

interface IOwnProps {
  onBack: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing, typography }: Theme) =>
  createStyles({
    dialogWrapper: {
      maxWidth: '75%',
    },
    dialogTitle: {
      background: '#F7F7F7',
      textAlign: 'center',
      fontWeight: typography.fontWeightMedium,
      textDecoration: 'underline',
      paddingTop: `${spacing(1)}px`,
      paddingBottom: `${spacing(1)}px`,
    },
    closeButton: {
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      color: customTheme.palette.text.regular,
    },
  })

const PaymentDialog: SFC<TProps> = ({ onBack, children, classes }) => (
  <Dialog
    open={true}
    disableBackdropClick={true}
    disableEscapeKeyDown={true}
    classes={{ paper: classes.dialogWrapper }}
    onClose={onBack}
  >
    <DialogTitle classes={{ root: classes.dialogTitle }}>
      <Typography variant="header" className={classes.dialogTitle}>
        {t('Confirm Your Subscription')}
      </Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onBack}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    {children}
  </Dialog>
)

export default withStyles(styles)(PaymentDialog)
