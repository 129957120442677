import React, { SFC } from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core'
import CostsSection from './Costs'
import StripeSection from './Stripe'
import { AppContext } from 'store/appContext'
import classNames from 'classnames'

interface IProps extends WithStyles<typeof styles> {
  active: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    section: {
      '&:not(:last-child)': {
        marginBottom: spacing(3),
      },
    },
    hiddenVisibility: {
      visibility: 'hidden',
    },
  })

const FinanceTab: SFC<IProps> = ({ classes, active }) => (
  <div hidden={!active}>
    <AppContext.Consumer>
      {({ providerInfo, role }) => (
        <>
          {role !== 'observer' && (
            <section className={classes.section}>
              <CostsSection />
            </section>
          )}
          <section
            className={classNames(
              classes.section,
              providerInfo && providerInfo.parentProviderId && classes.hiddenVisibility,
            )}
          >
            <StripeSection />
          </section>
        </>
      )}
    </AppContext.Consumer>
  </div>
)

export default withStyles(styles)(FinanceTab)
