import { TextField, Button } from '@material-ui/core'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { t } from 'translations/translationFunctions'
import { validPassword } from 'utils/regex'
import './LogInPanel.css'
import { resetPassword } from 'api/api'
import { homePagePath, loginPath } from 'routes/paths'
import history from 'utils/history'
import qs from 'qs'
import { Formik } from 'formik'
import notify from 'utils/notify/notify'

interface IParams {
  user?: string
}

interface IState {
  loading: boolean
  resetError: boolean
}

interface IFormValues {
  newPassword: string
  repeatPassword: string
}

class ResetPassword extends React.Component<RouteComponentProps<IParams>, IState> {
  public state = {
    loading: false,
    resetError: false,
  }

  private initialFormValues: IFormValues = {
    newPassword: '',
    repeatPassword: '',
  }

  public render() {
    const { resetError } = this.state
    const { handleBack, initialFormValues } = this

    return (
      <div className="LogInPanel LogInPanel-ResetPassword">
        <CSSTransition classNames="cardSlide" in={!resetError} timeout={220} unmountOnExit={true}>
          <div className="LogInPanel__inner">
            <div className="LogInPanel__header">
              <h2 className="LogInPanel__title">{t('Please enter your new password and repeat it to change')}</h2>
            </div>
            <Formik initialValues={initialFormValues} onSubmit={this.handleCreateNewPassword} validate={this.validate}>
              {({ errors, values, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="LogInPanel__main">
                    <TextField
                      autoComplete="off"
                      autoFocus={true}
                      helperText={touched.newPassword && errors.newPassword && errors.newPassword}
                      error={touched.newPassword && errors.newPassword ? true : false}
                      label={t('Enter your new password')}
                      fullWidth={true}
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      value={values.newPassword}
                    />
                    <TextField
                      autoComplete="off"
                      helperText={touched.repeatPassword && errors.repeatPassword && errors.repeatPassword}
                      error={touched.repeatPassword && errors.repeatPassword ? true : false}
                      label={t('Repeat your new password')}
                      fullWidth={true}
                      name="repeatPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      value={values.repeatPassword}
                    />
                  </div>
                  <div className="LogInPanel__action">
                    <div className="LogInPanel__action-secondary" />
                    <div className="LogInPanel__action-primary">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={() => handleSubmit}
                      >
                        {t('Reset Password')}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </CSSTransition>
        <CSSTransition in={resetError} timeout={220} classNames="cardSlide" unmountOnExit={true}>
          <div className="LogInPanel__inner">
            <div className="LogInPanel__header">
              <h2 className="LogInPanel__title">{t('The password could not be reset. Please try again later.')}</h2>
            </div>
            <div className="LogInPanel__main" />
            <div className="LogInPanel__action">
              <div className="LogInPanel__action-secondary" />
              <div className="LogInPanel__action-primary">
                <Button variant="contained" color="primary" onClick={handleBack}>
                  {t('Back')}
                </Button>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }

  private validate = (values: IFormValues) => {
    const errors: Partial<IFormValues> = {}
    if (!validPassword(values.newPassword)) {
      errors.newPassword = t(
        'The password should minimum be 6 characters, have 1 lowercase letter and 1 uppercase letter',
      )
    }
    if (values.newPassword !== values.repeatPassword) {
      errors.repeatPassword = t('Passwords do not match')
    }
    return errors
  }

  private handleCreateNewPassword = async (values: IFormValues) => {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (!params || !params.hasOwnProperty('reset-token')) {
      this.setState({ resetError: true })
      return
    }
    const response = await resetPassword({ password: values.newPassword }, params['reset-token'])
    // 204 No content
    if (response.statusCode && response.statusCode === 204) {
      notify.success({ message: t('Password changed'), time: 4 })
      history.replace(loginPath)
    } else {
      this.setState({ resetError: true })
    }
  }

  private handleBack = () => {
    history.replace(homePagePath)
  }
}

export default ResetPassword
