import { Other, ProductAlongItsContracts } from '@omnicar/sam-types'
import { ProductPartial } from '@omnicar/sam-types/types/productPartial'
import { FiberSmartRecord } from '@material-ui/icons'

/**
 * @return Returns true if all fields has values.
 */
export const productHasRequiredInfo = (
  product: Other | ProductPartial | undefined | null,
  isIgnoreSerialNum: boolean = false,
): boolean => {
  if (isIgnoreSerialNum) {
    if (
      // NOTE: serial number and item number omitted!
      !product ||
      !product.brand ||
      !product.model ||
      !product.regDate ||
      !product.modelYear ||
      !product.typeName ||
      product.regDate === 'Invalid date'
    ) {
      return false
    } else {
      if (!product) {
        return false
      }

      try {
        return (
          // NOTE: serial number and item number omitted!
          product.brand &&
          product.brand.name.length > 0 &&
          product.model &&
          product.model.name.length > 0 &&
          product.regDate.length > 0 &&
          product.typeName.length > 0
        )
      } catch (err) {
        return false
      }
    }
  } else {
    if (
      !product ||
      !product.serialNumber ||
      !product.itemNumber ||
      !product.brand ||
      !product.model ||
      !product.modelYear ||
      !product.regDate ||
      !product.typeName ||
      product.regDate === 'Invalid date'
    ) {
      return false
    } else {
      try {
        return (
          product.serialNumber.length > 0 &&
          product.itemNumber.length > 0 &&
          product.brand.name.length > 0 &&
          product.model.name.length > 0 &&
          product.typeName.length > 0 &&
          product.regDate.length > 0
        )
      } catch (err) {
        return false
      }
    }
  }
}

export const partialProductToProduct = (productPartial: ProductPartial): Other => {
  return {
    ...productPartial,
    modelYear: productPartial.modelYear || 0,
  } as Other
}

export const partialProductToProductAlongItsContracts = (productPartial: ProductPartial): ProductAlongItsContracts => {
  return {
    ...productPartial,
    modelYear: productPartial.modelYear || 0,
  } as ProductAlongItsContracts
}

export const hasItemSerialNumber = (product: { itemNumber?: string; serialNumber?: string }) => {
  return product.serialNumber && product.itemNumber
}

export const ProductIcon = FiberSmartRecord
