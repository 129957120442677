export const homePagePath = '/'
export const overviewPagePath = '/overview'
export const contractDetailsBaseRoute = 'contract-details'
export const contractReportDetailsBaseRoute = 'contract'
export const productBaseRoute = 'product-'
export const contractListPath = `/contracts`
export const productContractListPath = `/product-contracts`

export const contractDetailsPath = (identifier?: string) =>
  `/${contractDetailsBaseRoute}/${identifier || ':prettyIdentifier(\\d{3,4}-\\d{7})'}`

export const reportContractDetailsPath = (identifier?: string) =>
  `/${contractReportDetailsBaseRoute}/${identifier || ':prettyIdentifier(\\d{3,4}-\\d{7})'}`

export const getContractDetailsPath = (isReportPage: boolean, identifier?: string) =>
  isReportPage ? reportContractDetailsPath(identifier) : contractDetailsPath(identifier)

export const customerContractDetailsPath = (identifier?: string) =>
  `/customer/contracts/${identifier || ':prettyIdentifier(\\d{3,4}-\\d{7})'}`
export const creditCardPath = (identifier?: string, isNewAdded: boolean = false) =>
  `${customerContractDetailsPath(identifier)}/credit-cards${isNewAdded ? '?isNewAdded=1' : ''}`
export const customerInvoicesTabPath = (identifier?: string) =>
  `${customerContractDetailsPath(identifier)}/invoice-details`
export const contractDetailsEconomyPath = (identifier?: string) => `${contractDetailsPath(identifier)}/economy`

export const getContractDetailsEconomyPath = (isReportPage: boolean, identifier?: string) =>
  isReportPage ? reportContractDetailsEconomyPath(identifier) : contractDetailsEconomyPath(identifier)

export const reportContractDetailsEconomyPath = (identifier?: string) =>
  `${reportContractDetailsPath(identifier)}/economy`

export const contractDetailsSettlePath = (identifier?: string) => `${contractDetailsPath(identifier)}/settle`
export const contractFlowPath = ({ identifier, offerOnly }: { identifier?: string; offerOnly: boolean }) => {
  if (offerOnly) {
    return '/contract-offer'
  } else {
    return `/contract-offer/${identifier || ':prettyIdentifier(\\d{3,4}-\\d{7})?'}`
  }
}
export const customerListPath = '/customers'
export const customerDetailsPath = (identifier?: string) =>
  `/customers/${identifier || ':prettyIdentifier(\\d{3,4}-\\d{5})'}`
export const administrationPath = '/administration'
export const loginPath = '/login'
export const loginPathWithDest = (dest?: string) => `/login?dest=${dest || ':dest(\\d{3,4}-\\d{7})'}`
export const loginUserPath = (user?: string, dest?: string) =>
  dest ? `/login/${user || ':user'}?dest=${dest || ':dest(\\d{3,4}-\\d{7})'}` : `/login/${user || ':user'}`
export const loginAuthPath = (user?: string, dest?: string) =>
  dest ? loginAuthPathWithDest(user, dest) : `/login/${user || ':user'}/authenticate`
export const loginAuthPathWithDest = (user?: string, dest?: string) =>
  `/login/${user || ':user'}/authenticate?dest=${dest || ':dest(\\d{3,4}-\\d{7})'}`
export const loginForgotPath = (user?: string) => `/login/${user || ':user'}/forgot-password`
export const loginResetPath = '/reset-password'
export const login404Path = (user?: string) => `/login/${user || ':user'}/404`
export const userListPath = 'users'
export const userDetailsPath = (name?: string) => `/users/${name || ':name'}`
export const pageNotFoundPath = '/404'
export const unAuthorizedPath = '/401'

export const stripePaymentPath = (serviceContractIdAsString?: string) =>
  `/payment/stripe/${serviceContractIdAsString || ':serviceContractIdAsString'}`
export const paymentErrorPath = (identifier: string) => `${contractDetailsPath(identifier)}`
export const paymentReceiptPath = (identifier: string) => `${contractDetailsPath(identifier)}`

export const stripeAddCardPath = (serviceContractIdAsString?: string) =>
  `/payment/addcard/${serviceContractIdAsString || ':serviceContractIdAsString'}`
export const addCardErrorPath = (identifier: string) => `${creditCardPath(identifier)}`
export const addCardReceiptPath = (identifier: string) => `${creditCardPath(identifier, true)}`
export const releaseNotesPath = '/release-notes'
export const operationStatusPath = `/operation-status`
