import { IAdminCustomer } from '@omnicar/sam-types'
import { getCustomer, patchCustomer } from 'api/api'
import CustomerContractList from 'components/admin/Customer/Contract/List'
import CustomerDetails from 'components/admin/Customer/Details'
import { Page, PageContent, PageHeader, PageTitle } from 'components/Mui/Page'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { AppContext } from 'store/appContext'

interface IRouteProps {
  prettyIdentifier: string
}

type TProps = RouteComponentProps<IRouteProps>

interface IState {
  customerName: string
  customer: IAdminCustomer | undefined
}

const initialState: IState = {
  customerName: '',
  customer: undefined,
}

class CustomerDetailsPage extends React.Component<TProps, IState> {
  state = initialState

  componentDidMount = async () => {
    const { match } = this.props

    const customerPrettyId = match.params.prettyIdentifier
    const result = await getCustomer(customerPrettyId)
    const customer: IAdminCustomer = result.data!

    this.setState({ customer })
  }

  public render() {
    const { match } = this.props
    const { customerName, customer } = this.state

    return (
      <Page>
        <PageHeader>
          <PageTitle fullHeight={true}>{customerName}</PageTitle>
        </PageHeader>
        <PageContent>
          <AppContext.Consumer>
            {({ role }) => (
              <CustomerDetails
                customerIdentifier={match.params.prettyIdentifier}
                contractPrettyId={''}
                allowEdit={role !== 'observer'}
                allowPasswordChange={true}
                customerRecord={customer!}
                onPatchCallback={patchCustomer}
                onUpdateCustomerNameState={this.handleUpdateCustomerNameState}
              />
            )}
          </AppContext.Consumer>
          <CustomerContractList id={match.params.prettyIdentifier} />
        </PageContent>
      </Page>
    )
  }

  private handleUpdateCustomerNameState = (customerName: string) => {
    this.setState({
      customerName,
    })
  }
}

export default CustomerDetailsPage
