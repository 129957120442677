import * as _ from 'lodash'

export function assert(isOk: boolean, msgWhenFalse: string) {
  if (!isOk) throw new Error(msgWhenFalse)
}

/**
 * Prints the text txt to the console, for debugging purposes.
 * @param isDoPrint If true then txt will be printed, ohterwise it will be ignored.
 */
export function debugPrint(isDoPrint = true, txt: string | object): void {
  isDoPrint && console.log(txt)
}

/**
 * Will return an array of the keys that are different between two objects.
 */
export const getObjectDiff = (obj1: any, obj2: any, compareRef = false): string[] => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`
      } else {
        result.splice(resultKeyIndex, 1)
      }
    }
    return result
  }, Object.keys(obj2))
}

/**
 * Halt / Wait / Delay / Sleep execution of the current thread, for millseconds amount of time.
 *
 * @note: All functions in the current call/event/thread must be prefixed with async/await,
 * up to the top level of your call/event/thread.
 *
 * Usage:
 *   await sleep(1000)   // Halts the CURRENT thread for 1 second.
 */
export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
