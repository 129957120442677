import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'

interface IOwnProps {
  active: boolean
  className?: string
  name: string
  title: string
  endAdornment?: { content: string | number; title: string; className?: string }
  e2e?: string
  onClick: (name: string) => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ shape, spacing, typography, palette }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      backgroundColor: palette.primary.main,
      borderTopLeftRadius: shape.borderRadius * 3,
      borderTopRightRadius: shape.borderRadius * 3,
      color: '#F7F7F7',
      cursor: 'pointer',
      display: 'flex',
      fontWeight: typography.fontWeightRegular,
      height: spacing(4.95),
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
      textTransform: 'uppercase',
      userSelect: 'none',
    },
    active: {
      color: palette.primary.dark,
      borderColor: palette.primary.dark,
      borderLeft: 'solid',
      borderRight: 'solid',
      borderTop: 'solid',
      backgroundColor: '#F7F7F7',
      fontWeight: typography.fontWeightMedium,
      cursor: 'initial',
    },
  })

const PageNavigationTab: React.SFC<TProps> = ({
  active,
  classes,
  className,
  title,
  onClick,
  name,
  e2e,
  endAdornment,
}) => (
  <div
    className={classNames(classes.root, active && classes.active, className)}
    data-name={name}
    data-e2e={e2e}
    // tslint:disable-next-line jsx-no-lambda
    onClick={() => {
      if (!active) {
        onClick(name)
      }
    }}
  >
    {title}
    {!!endAdornment && (
      <span className={endAdornment.className || ''} title={endAdornment.title}>
        {endAdornment.content}
      </span>
    )}
  </div>
)

export default withStyles(styles)(PageNavigationTab)
