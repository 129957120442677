import React from 'react'
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { getCountry } from 'utils/localStorage'

interface IPresentCountryProps {}

type TProps = IPresentCountryProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    container: {
      display: 'flex',
      marginTop: '3px',
      marginLeft: '-0.2em',
      marginRight: '-0.2em',
      paddingLeft: '0',
      paddingRight: '0',
    },
    flag: {
      width: spacing(3),
      height: spacing(3),
      opacity: 0.9,
    },
  })

interface IState {
  country: null | string
}

class PresentCountry extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      country: this.getCountry(),
    }
  }

  public getCountry(): string | null {
    const country: string | null = getCountry()
    return country && country.toLowerCase()
  }

  public render() {
    const { classes } = this.props
    const { country } = this.state

    if (country) {
      let img = null

      try {
        img = require(`../../assets/icons/flags/${country.toLowerCase()}.svg`)
      } catch (error) {
        console.error(`Something went wrong getting flag icon: ${(error as any)?.message}`)
        return ''
      }

      if (img) {
        return (
          <div className={classes.container}>
            <Avatar
              className={classes.flag}
              src={img}
              title={country.toUpperCase()}
              alt={country.toUpperCase() + ' flag'}
            />
          </div>
        )
      }
    }

    return <div />
  }
}

export default withStyles(styles)(PresentCountry)
