import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Close as CloseIcon, Input as InputIcon } from '@material-ui/icons'
import { exportUrl } from 'api/api'
import Typography from 'components/Typography'
import React from 'react'
import { t } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'

interface IOwnProps {
  open: boolean
  onClose: () => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    content: {
      minWidth: theme.spacing(62),
    },
    button: {
      marginRight: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
      fontSize: 16,
    },
  })

class DialogContractsDownload extends React.Component<TProps, never> {
  public render() {
    const { open, onClose, classes } = this.props

    return (
      <Dialog open={open} onClose={onClose} data-e2e={'Dialog--download-contracts-report'}>
        <DialogTitle>
          <Typography variant="subtitle">{`${t('Reports')} - ${t('Download all contracts')}`}</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            onClick={this.handleCSV}
            href={exportUrl({ type: 'contracts', format: 'csv' })}
            data-e2e="contractDownload-csv-btn"
          >
            <InputIcon className={classes.icon} />
            CSV
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            onClick={this.handleXLS}
            href={exportUrl({ type: 'contracts', format: 'xls' })}
            data-e2e="contractDownload-xls-btn"
          >
            <InputIcon className={classes.icon} />
            {t('XLS')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            <CloseIcon className={classes.icon} />
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private handleCSV = () => {
    trackEvent('File export', 'Contracts', 'csv')
    // @TODO show message that the file is downloading
    this.props.onClose()
  }

  private handleXLS = () => {
    trackEvent('File export', 'Contracts', 'xls')
    // @TODO show message that the file is downloading
    this.props.onClose()
  }
}

export default withStyles(styles)(DialogContractsDownload)
