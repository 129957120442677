import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { ContractState } from '@omnicar/sam-types'
import React from 'react'
import { trackEvent } from 'utils/analytics'
import { t } from 'translations/translationFunctions'
import classNames from 'classnames'
import { contractStateStyles } from 'theme/styles/contractState'
import { TranslationKey, IReplacement } from 'translations/translationTypes'
import { Tooltip, Fade } from '@material-ui/core'
import { compose } from 'recompose'

export interface IFilterNotification {
  title: { text: TranslationKey; replacements?: IReplacement }
  amount: number
}

export interface IFilter {
  title: TranslationKey
  count: number
  keys: ContractState[]
  notification?: IFilterNotification
  explanation?: TranslationKey
}

interface IProps {
  onChange: (filters: ContractState[], filterTitle: string) => void
  activeStates: ContractState[]
  filterList: IFilter[]
}

const styles = ({ typography, spacing }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    chip: {
      fontSize: typography.button.fontSize,
      marginRight: spacing(2),
      height: 28,
      lineHeight: '27px',
      paddingLeft: 13,
      paddingRight: 13,
      borderRadius: 5,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      whiteSpace: 'nowrap',
    },
    ...contractStateStyles({ color: true, backgroundColor: true, borderColor: true }),
    tooltipIcon: {
      display: 'inline-block',
      position: 'absolute',
      float: 'right',
      textAlign: 'center',
      backgroundColor: 'darkorange',
      color: '#eeeeee',
      fontWeight: 'bold',
      height: spacing(2.5),
      width: spacing(2.5),
      lineHeight: spacing(2.75) + 'px',
      borderRadius: spacing(2.5),
      fontSize: spacing(1.375),
      marginLeft: spacing(0.2),
      marginTop: -spacing(1),
    },
    tooltip: {
      maxWidth: spacing(27),
      fontSize: spacing(1.5),
    },
  })

class StateFilters extends React.Component<IProps & WithStyles<typeof styles>> {
  public render() {
    const { handleChipToggle } = this
    const { classes, filterList } = this.props

    return (
      <div className={classes.root}>
        {filterList.map((filter, index) => {
          const isActive = this.isActive(filter)

          return (
            <div
              className={classNames(
                classes.chip,
                isActive ? classes[`stateActive${filter.keys[0]}`] : classes[`state${filter.keys[0]}`],
              )}
              key={`CardListStateFilter-${index}`}
              onClick={handleChipToggle}
              data-id={index}
            >
              <span title={filter.explanation && t(filter.explanation)}>{`${t(filter.title)} (${filter.count})`}</span>
              {filter.notification && (
                <Tooltip
                  title={t(filter.notification.title.text, filter.notification.title.replacements)}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div className={classes.tooltipIcon}>
                    {filter.notification.amount > 99 ? '+99' : filter.notification.amount}
                  </div>
                </Tooltip>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  private isActive = (filter: IFilter) => {
    const { activeStates } = this.props
    let isStateActive = false

    filter.keys.forEach((k) => {
      if (activeStates.includes(k)) {
        isStateActive = true
        return
      }
    })

    return isStateActive
  }

  private handleChipToggle = (e: any) => {
    const filter = this.props.filterList[e.currentTarget.dataset.id]
    const isActive = this.isActive(filter)

    if (!isActive) {
      trackEvent('Contract state filter', 'Filter enabled', filter.title)

      this.props.onChange(this.props.activeStates.concat(filter.keys), filter.title)
    } else {
      trackEvent('Contract state filter', 'Filter disabled', filter.title)

      this.props.onChange(
        this.props.activeStates.filter((s) => !filter.keys.includes(s)),
        filter.title,
      )
    }
  }
}

export default compose<IProps & WithStyles<typeof styles>, IProps>(withStyles(styles))(StateFilters)
