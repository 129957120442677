export const sortColumns = (columns: any[]) => {
  columns.sort((c1, c2) => {
    if (!c1.position && c2.position) {
      return 1
    } else if (c1.position && !c2.position) {
      return -1
    }

    return 0
  })
}
