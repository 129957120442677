import { init as initFormats } from '@omnicar/sam-format'
import { setLocale } from '@omnicar/sam-translate'
import { ILoginResponse, IsoLocale, TCurrency, TIsoCountry } from '@omnicar/sam-types'
import { login, loginEmailExists, loginForgotPassword, touchUser } from 'api/api'
import { WithTracker } from 'components/Tracker/Tracker'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { t } from 'translations/translationFunctions'
import { getAvailableLocales, getPreferredUserLocale, languageToLocale, LOCALE_CODE_DEFAULT } from 'utils/locale'
import { setToken } from 'utils/localStorage'
import './LogInPage.css'
import Outage from 'components/admin/Outage'
import Paper from 'components/Mui/Paper'
import {
  homePagePath,
  login404Path,
  loginAuthPath,
  loginForgotPath,
  loginPath,
  loginResetPath,
  loginUserPath,
} from 'routes/paths'
import { saveRefreshToken } from 'utils/cookies'
import Logo from '../../assets/images/JustGO-logo.png'
import Authenticate, { IPasswordValidation } from './LogInPanel/Authenticate'
import ForgotPassword from './LogInPanel/ForgotPassword'
import ResetPassword from './LogInPanel/ResetPassword'
import User from './LogInPanel/User'
import User404 from './LogInPanel/User404'

export interface ILoginProps {
  title?: string
  logo?: string
}

interface IState {
  title: string
  logo: string
  hasActiveOutage: boolean
}

const defaultState: IState = {
  // This is translated when used
  // @TODO Use context API to let panels update the title
  title: 'Welcome',
  logo: '',
  hasActiveOutage: false,
}

// tslint:disable jsx-no-lambda
class LogInPage extends React.Component<ILoginProps, IState> {
  public static validUser = async (user: string): Promise<ILoginResponse | false> => {
    const response = await loginEmailExists({
      username: user,
      password: '',
    })

    if (response.data) {
      return response.data
    }

    return false
  }

  public static validPassword = async (username: string, password: string) => {
    const response = await login({ username, password })
    let reply: IPasswordValidation = { valid: false }
    // TODO: refactoring
    if (response.data && response.data.token && response.data.refreshToken) {
      setToken(response.data.token)
      touchUser(username)
      saveRefreshToken(response.data.refreshToken)

      if (response.data.providerInfo) {
        const providerLocale = response.data.providerInfo.locale
        const providerCountry = response.data.providerInfo.country
        const providerCurrency = response.data.providerInfo.currency

        const options = {
          countryCode: providerCountry as TIsoCountry,
          currencyCode: providerCurrency as TCurrency,
          language: providerLocale as IsoLocale, // May be used in some rare cases, like in dates.
        }
        initFormats(options)

        const response2 = await getAvailableLocales()
        const availableLocales = !response2 ? [LOCALE_CODE_DEFAULT] : response2
        const localeOrLanguage = await getPreferredUserLocale(username, availableLocales as string[])

        let locale
        if (localeOrLanguage.length === 2) {
          locale = languageToLocale(localeOrLanguage, availableLocales)
        } else {
          locale = localeOrLanguage
        }

        if (!locale) {
          setLocale(providerLocale)
        } else {
          setLocale(locale)
          initFormats({
            countryCode: providerCountry as TIsoCountry,
            currencyCode: providerCurrency as TCurrency,
            language: locale as IsoLocale, // May be used in some rare cases, like in dates.
          })
        }
      }
      const { role, roles, stripePublicKey, providerInfo, userInfo, customerInfo, isSuperAdmin, isDev } = response.data
      reply = {
        valid: true,
        role,
        roles,
        stripePublicKey,
        providerInfo,
        userInfo,
        customerInfo,
        isSuperAdmin,
        isDev,
      }
    }

    return reply
  }

  public static forgotPassword = async (username: string) => {
    // TODO: check usage of password
    const response = await loginForgotPassword({ username, password: '' })
    let reply = false

    if (response && !response.errorData) {
      reply = true
    }

    return reply
  }

  constructor(props: ILoginProps) {
    super(props)
    this.state = { ...defaultState, ...props }
  }

  public render() {
    const welcomeTitle = t('Welcome to Fragus JustGO')
    return (
      <Route
        render={({ location }) => {
          return (
            <div className="LogInPage">
              <Outage onOutageChange={this.handleOutageChange} />
              <Paper className="LogInPage__card">
                <header className="LogInPage__header">
                  <img className="LogInPage__logo" src={Logo} alt="JustGO-logo" />
                </header>
                <div className="LogInPage__info">
                  <h1 className="LogInPage__title">{welcomeTitle}</h1>
                </div>
                <main className="LogInPage__main">
                  <TransitionGroup>
                    <CSSTransition key={location.key} timeout={220} classNames="cardSlide" unmountOnExit={true}>
                      <Switch location={location}>
                        <Route exact={true} path={homePagePath} render={WithTracker(User)} />
                        <Route exact={true} path={loginPath} render={WithTracker(User)} />
                        <Route exact={true} path={loginUserPath()} render={WithTracker(User)} />
                        <Route exact={true} path={loginAuthPath()} render={WithTracker(Authenticate)} />
                        <Route exact={true} path={loginForgotPath()} render={WithTracker(ForgotPassword)} />
                        <Route exact={true} path={loginResetPath} render={WithTracker(ResetPassword)} />
                        <Route exact={true} path={login404Path()} render={WithTracker(User404)} />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </main>
              </Paper>
            </div>
          )
        }}
      />
    )
  }

  private handleOutageChange = (hasActiveOutage: boolean) => {
    if (this.state.hasActiveOutage !== hasActiveOutage) this.setState({ hasActiveOutage })
  }
}

export default LogInPage
