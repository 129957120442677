import React, { SFC } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const BriefcaseIcon: SFC<any> = props => (
  <SvgIcon {...props}>
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="baseline-work-24px">
        <polygon id="Path" points="0 0 24 0 24 24 0 24" />
        <path
          d="M20,5 L16,5 L16,3 C16,1.89 15.11,1 14,1 L10,1 C8.89,1 8,1.89 8,3 L8,5 L4,5 C2.89,5 2.01,5.89 2.01,7 L2,16 C2,17.11 2.89,18 4,18 L20,18 C21.11,18 22,17.11 22,16 L22,7 C22,5.89 21.11,5 20,5 Z M14,5 L10,5 L10,3 L14,3 L14,5 Z"
          id="Shape"
          fill="#388F81"
          fill-rule="nonzero"
        />
        <rect id="Rectangle" fill="#388F81" fill-rule="nonzero" x="3" y="20" width="3" height="3" />
        <rect id="Rectangle-Copy" fill="#388F81" fill-rule="nonzero" x="10.5" y="20" width="3" height="3" />
        <rect id="Rectangle-Copy-2" fill="#388F81" fill-rule="nonzero" x="18" y="20" width="3" height="3" />
      </g>
    </g>
  </SvgIcon>
)

export default BriefcaseIcon
