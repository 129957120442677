import { Button } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { AddCircleOutline, ExpandMore as CollapseIcon, ExpandLess as ExpandIcon } from '@material-ui/icons'
import { formatCurrency, formatDate, formatMileage } from '@omnicar/sam-format'
import {
  ContractValueType,
  IWorkshopOperationCollection,
  IWorkshopOperation as IWorkshopOperationRecord,
} from '@omnicar/sam-types'
import { getContractWorkshopOperationList } from 'api/api'
import { Panel, PanelActions, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React from 'react'
import ReactTable, { Column } from 'react-table'
import { t } from 'translations/translationFunctions'
import 'react-table/react-table.css'
import classNames from 'classnames'
import { IReduxHiddenVATProps, withHiddenVAT } from 'components/ContractDisplayConfig/withHiddenVAT'
import Paper from 'components/Mui/Paper'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { contractDetailsPath } from 'routes/paths'
import { AppContext } from 'store/appContext'
import { createPanelStyles } from 'theme'
import { valueTypeTranslations } from 'translations/ValueTypeTranslations'
import ContractDetailsWorkshopOperationListForm from './Form'

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    expandIcon: {
      fontSize: '24px',
      display: 'block',
      margin: 'auto',
    },
    indent: {
      paddingLeft: theme.spacing(2),
    },
    alignRight: {
      textAlign: 'right',
    },
    panelMargin: {
      marginTop: 22,
    },
  })

interface IOwnProps {
  allowEdit?: boolean
  id: string
  valueType: ContractValueType
  onListUpdate: () => void
  onLoadData?: (operations: any[]) => void
  locale: string
}

type TProps = IOwnProps & WithStyles<typeof styles> & IReduxHiddenVATProps

interface IState {
  edit: boolean
  loading: boolean
  formDialog: boolean
  records: IWorkshopOperationCollection[]
  record: IWorkshopOperationRecord | undefined
}

export const getColumns = (
  classes: Record<string, string>,
  valueType: 'Hours' | 'Mileage',
  hiddenVAT: boolean,
): Column[] => [
  {
    Header: '',
    expander: true,
    width: 75,
    style: { display: 'flex', fontWeight: 'bold', paddingLeft: 0 },
    Expander: ({ isExpanded, original }) => {
      if (original.operations.length <= 1) {
        return null
      }
      return isExpanded ? (
        <>
          <ExpandIcon className={classNames(classes.expandIcon, 'expander')} />
          <span>{`(${original.operations.length})`}</span>
        </>
      ) : (
        <>
          <CollapseIcon className={classNames(classes.expandIcon, 'expander')} />
          <span>{`(${original.operations.length})`}</span>
        </>
      )
    },
  },
  {
    Header: t('Date'),
    accessor: 'date',
    headerClassName: 'ContractDetailsWorkshopOperationList__table-header',
    Cell: ({ value }: { value: string }) => {
      const date = formatDate(value)
      return <div>{date}</div>
    },
  },
  {
    Header: t(valueTypeTranslations[valueType].value),
    accessor: 'value',
    headerClassName: 'ContractDetailsWorkshopOperationList__table-header',
    Cell: ({ value, original }: { value: string; original: IWorkshopOperationCollection }) => {
      let columnValue: string | number = value || ''
      if (original.operations.length <= 1) {
        columnValue = original.operations[0].value
      } else {
        columnValue = original.operations.reduce((max, o) => {
          return (max =
            Math.max(...original.operations.map((o) => o.value)) ||
            Math.max(...original.operations.map((o) => o.mileage || 0)) ||
            0)
        }, 0)
      }

      const mileage = columnValue ? formatMileage(columnValue, { symbolDisplayType: 'APPEND' }) : ''

      return (
        <div>{valueType === 'Mileage' ? mileage : `${columnValue}${valueTypeTranslations[valueType].shortValue}`}</div>
      )
    },
  },
  {
    Header: t('Description'),
    accessor: 'description',
    headerClassName: 'ContractDetailsWorkshopOperationList__table-header',
    Cell: ({ value, original }: { value: string; original: IWorkshopOperationCollection }) => {
      let description = value
      if (original.operations.length <= 1) {
        description = original.operations[0].description
      }
      return description
    },
  },
  {
    Header: t('Contract ID'),
    accessor: 'contractPrettyIdentifier',
    headerClassName: 'ContractDetailsWorkshopOperationList__table-header',
    Cell: ({ value, original }: { value: string; original: IWorkshopOperationCollection }) => {
      let contractPrettyIdentifier = value
      if (original.operations.length <= 1) {
        contractPrettyIdentifier = original.operations[0].contractPrettyIdentifier || ''
      }
      return (
        <Link to={contractDetailsPath(contractPrettyIdentifier)}>
          <div>{contractPrettyIdentifier}</div>
        </Link>
      )
    },
  },
  {
    Header: t(hiddenVAT ? 'Price' : 'Price (ex. VAT)'),
    accessor: 'price.price',
    headerClassName: 'ContractDetailsWorkshopOperationList__table-header',
    Cell: ({ value }: { value: string }) => {
      const priceValue = value || 0
      const price = formatCurrency(+priceValue, { symbolDisplayType: 'APPEND' })
      return <div>{price}</div>
    },
  },
]

const getInnerColumns = (classes: Record<string, string>, valueType: 'Hours' | 'Mileage', hiddenVAT: boolean) => [
  {
    width: 75,
  },
  {
    accessor: 'date',
    Cell: ({ value }: { value: string }) => {
      const date = formatDate(value)
      return <div className={classes.indent}>{date}</div>
    },
  },
  {
    accessor: 'value',
    Cell: (cell: any) => {
      const value = cell.value ? cell.value : ''
      const mileage = value ? formatMileage(value, { symbolDisplayType: 'APPEND' }) : ''
      return <div>{valueType === 'Mileage' ? mileage : `${value}${valueTypeTranslations[valueType].shortValue}`}</div>
    },
  },
  {
    accessor: 'description',
  },
  {
    accessor: 'contractPrettyIdentifier',
    Cell: ({ value }: { value: string }) => {
      return (
        <Link to={contractDetailsPath(value)}>
          <div>{value}</div>
        </Link>
      )
    },
  },
  {
    accessor: 'price.price',
    Cell: ({ value }: { value: string }) => {
      const priceValue = value || 0
      const price = formatCurrency(+priceValue, { symbolDisplayType: 'APPEND' })
      return <div>{price}</div>
    },
  },
]

class ContractDetailsWorkshopOperationList extends React.Component<TProps, IState> {
  public state: IState = {
    edit: false,
    loading: false,
    formDialog: false,
    record: undefined,
    records: [],
  }

  public componentDidMount() {
    this.loadData()
  }

  public componentDidUpdate(prevProps: TProps) {
    if (this.props.id !== prevProps.id) {
      this.loadData()
    }
  }

  public render() {
    const { handleRowClick } = this
    const { classes, allowEdit, valueType, hiddenVAT } = this.props
    const { records, loading, formDialog } = this.state

    // Contracts with services defaults to an 'Hours' report value
    const type = valueType && valueType !== 'Services' ? valueType : 'Hours'
    const tableColumns = getColumns(classes, type, hiddenVAT)

    const allowEditing = allowEdit !== undefined ? allowEdit : true

    return (
      <Panel className={classes.panelMargin}>
        <AppContext.Consumer>
          {({ providerInfo }) => (
            <>
              <PanelHeader>
                <PanelTitle>{t('Workshop operations')}</PanelTitle>
                <PanelActions>
                  {allowEditing && providerInfo && (
                    <Button
                      className={classes.panelActionsButton}
                      onClick={this.handleAddWorkshopOperationOpen}
                      size="small"
                      variant="outlined"
                    >
                      {/* Button "Add Operation" - It must still be possible for deactivated dealers / accounts. */}
                      {/* To add repair costs, the dealers still have to manage old contracts. */}
                      <AddCircleOutline className={classes.panelActionsButtonIcon} />
                      {t('Add Operation')}
                    </Button>
                  )}
                </PanelActions>
              </PanelHeader>
              <PanelContent>
                <Paper className="ContractDetailsWorkshopOperationList__main">
                  <ReactTable
                    className="react-table react-table--clickable"
                    columns={tableColumns}
                    data={records}
                    defaultPageSize={10}
                    minRows={3}
                    defaultSorted={[{ desc: true, id: 'date' }]}
                    loading={loading}
                    LoadingComponent={TableLoadingIndicator}
                    noDataText={!loading ? t('No Results Found') : ''}
                    showPaginationBottom={records.length > 0}
                    // tslint:disable-next-line jsx-no-lambda
                    getTdProps={(state: any, rowInfo: any, column: any) => {
                      const allowColumnClick =
                        column && column.id !== 'contractPrettyIdentifier' && (!providerInfo || !providerInfo.inactive)
                      return rowInfo && rowInfo.original && rowInfo.original.operations.length <= 1 && allowColumnClick
                        ? { onClick: () => handleRowClick(rowInfo.original.operations[0]) }
                        : {}
                    }}
                    getTrProps={(state: any, rowInfo: any) => {
                      return rowInfo && rowInfo.original
                        ? this.resolveTrStyleProp(rowInfo.original.operations[0].contractPrettyIdentifier)
                        : {}
                    }}
                    pageText={t('Page')}
                    ofText={t('of')}
                    rowsText={t('rows')}
                    SubComponent={(row) => {
                      return (
                        <ReactTable
                          className="react-table sub-table"
                          data={row.original.operations}
                          columns={getInnerColumns(classes, type, hiddenVAT)}
                          TheadComponent={() => null}
                          showPagination={false}
                          minRows={0}
                          defaultPageSize={100}
                          // tslint:disable-next-line jsx-no-lambda
                          getTdProps={(state: any, rowInfo: any, column: any) => {
                            return rowInfo && rowInfo.original && column.id !== 'contractPrettyIdentifier'
                              ? { onClick: () => handleRowClick(rowInfo.original) }
                              : {}
                          }}
                          getTrProps={(state: any, rowInfo: any) => {
                            return rowInfo && rowInfo.original
                              ? this.resolveTrStyleProp(rowInfo.original.contractPrettyIdentifier)
                              : {}
                          }}
                          pageText={t('Page')}
                          ofText={t('of')}
                          rowsText={t('rows')}
                        />
                      )
                    }}
                  />
                </Paper>
                <ContractDetailsWorkshopOperationListForm
                  open={formDialog}
                  record={this.state.record}
                  valueType={valueType}
                  prettyIdentifier={this.props.id}
                  onClose={this.handleFormClose}
                  onSaved={this.handleFormSave}
                />
              </PanelContent>
            </>
          )}
        </AppContext.Consumer>
      </Panel>
    )
  }

  private resolveTrStyleProp = (contractId: string) => {
    return { style: this.props.id === contractId ? {} : { fontStyle: 'italic', color: 'grey' } }
  }

  private loadData = async () => {
    const { loading } = this.state
    const { id } = this.props

    if (!loading) {
      this.setState({ loading: true })
      const response = await getContractWorkshopOperationList(id)

      if (response.data) {
        const { result } = response.data
        this.setState({ loading: false, records: result })
        this.props.onLoadData && this.props.onLoadData(result)
      } else {
        this.setState({ loading: false })
      }
    }
  }

  private handleRowClick = (data: IWorkshopOperationRecord) => {
    const { allowEdit } = this.props
    if (allowEdit) {
      const record = {
        ...data,
        price: typeof data.price !== 'number' ? data.price.price : 0,
      }
      this.setState({
        record,
        formDialog: true,
      })
    }
  }

  private handleAddWorkshopOperationOpen = () => {
    this.setState({ formDialog: true })
  }

  private handleFormClose = () => {
    this.setState({ formDialog: false, record: undefined })
  }

  private handleFormSave = () => {
    this.setState({
      formDialog: false,
      record: undefined,
    })
    this.loadData()
    this.props.onListUpdate()
  }
}

export default compose<TProps, IOwnProps>(withHiddenVAT, withStyles(styles))(ContractDetailsWorkshopOperationList)
