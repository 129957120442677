import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import React from 'react'
import classNames from 'classnames'

interface IOwnProps {
  className?: string
  noPadding?: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
    },
    noPadding: {
      paddingTop: 0,
    },
  })

const PageContent: React.SFC<TProps> = ({ children, classes, className, noPadding }) => (
  <main className={classNames(classes.root, noPadding && classes.noPadding, className)}>{children && children}</main>
)

export default withStyles(styles)(PageContent)
