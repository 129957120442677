import { Button } from '@material-ui/core'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { t } from 'translations/translationFunctions'
import history from 'utils/history'
import { validEmail } from 'utils/regex'
import LogInPage from '../LogInPage'
import './LogInPanel.css'
import { homePagePath } from 'routes/paths'

interface IParams {
  user?: string
}

interface IState {
  loading: boolean
  user: string
  validationFail: boolean
  mailFailure: boolean
  mailAttempt: boolean
}

class ForgotPassword extends React.Component<RouteComponentProps<IParams>, IState> {
  constructor(props: RouteComponentProps<IParams>) {
    super(props)

    const user = props.match.params.user || ''

    this.state = {
      loading: false,
      user,
      validationFail: false,
      mailFailure: false,
      mailAttempt: false,
    }
  }

  public componentDidMount() {
    const { user } = this.state
    const invalidEmail = user ? !validEmail(user) : false
    const internal = this.props.location.state && (this.props.location.state as any).internal

    if (user && !invalidEmail && !internal) {
      this.handleValidateUser(user)
    }
  }

  public render() {
    const { handleBack, handleSendMail } = this
    const { validationFail, mailAttempt, mailFailure, user } = this.state

    return (
      <div className="LogInPanel LogInPanel-User">
        <CSSTransition
          in={!validationFail && !mailFailure && !mailAttempt}
          timeout={220}
          classNames="cardSlide"
          unmountOnExit={true}
        >
          <div className="LogInPanel__inner">
            <div className="LogInPanel__header">
              <h2 className="LogInPanel__title">
                {t('Click the button to receive an email with a link to reset your password')}
              </h2>
            </div>
            <div className="LogInPanel__main" />
            <div className="LogInPanel__action">
              <div className="LogInPanel__action-secondary" />
              <div className="LogInPanel__action-primary">
                <Button
                  className="LogInPanel__action-backbutton"
                  variant="contained"
                  color="secondary"
                  onClick={handleBack}
                >
                  {t('Back')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSendMail}>
                  {t('Send Mail')}
                </Button>
              </div>
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={validationFail} timeout={220} classNames="cardSlide" unmountOnExit={true}>
          <div className="LogInPanel__inner">
            <div className="LogInPanel__header">
              <h2 className="LogInPanel__title">{`${t('Not able to find user')} ${user}`}</h2>
            </div>
            <div className="LogInPanel__main" />
            <div className="LogInPanel__action">
              <div className="LogInPanel__action-secondary" />
              <div className="LogInPanel__action-primary">
                <Button variant="contained" color="primary" onClick={handleBack}>
                  {t('Back')}
                </Button>
              </div>
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={mailAttempt} timeout={220} classNames="cardSlide" unmountOnExit={true}>
          <div className="LogInPanel__inner">
            <div className="LogInPanel__header">
              <h2 className="LogInPanel__title">
                {mailFailure
                  ? t('An error occured trying to send you an email with info on how to reset your password')
                  : t('An email has been sent with a link and instructions on how to reset your password')}
              </h2>
            </div>
            <div className="LogInPanel__main" />
            <div className="LogInPanel__action">
              <div className="LogInPanel__action-secondary" />
              <div className="LogInPanel__action-primary">
                <Button variant="contained" color="primary" onClick={handleBack}>
                  {t('Back')}
                </Button>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }

  private handleValidateUser = async (user: string) => {
    const invalidEmail = !validEmail(user)

    if (user && !invalidEmail) {
      this.setState({ loading: true })

      const valid = await LogInPage.validUser(user)

      if (!valid) {
        this.setState({ loading: false, validationFail: true })
      } else {
        this.setState({ loading: false, validationFail: false })
      }
    }
  }

  private handleSendMail = async () => {
    const { user } = this.state

    this.setState({ loading: true })

    const success = await LogInPage.forgotPassword(user)

    if (success) {
      this.setState({ mailAttempt: true })
    } else {
      this.setState({ mailAttempt: true, mailFailure: true })
    }
  }

  private handleBack = () => {
    history.replace(homePagePath)
  }
}

export default ForgotPassword
