import { Button, CardContent } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { Cancel as CancelIcon, Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons'
import { Other, Vehicle } from '@omnicar/sam-types'
import classNames from 'classnames'
import React from 'react'
import { compose } from 'recompose'
import notify from 'utils/notify/notify'
import { isVehicleUpdateResponseObject } from 'utils/types'
import { patchContractProduct } from '../../../../api/api'
import { createPanelStyles } from '../../../../theme'
import { t } from '../../../../translations/translationFunctions'
import { Card } from '../../../Mui/Card'
import { Panel, PanelActions, PanelContent, PanelHeader, PanelTitle } from '../../../Mui/Panel'
import OtherDetails from '../../Other/Details'
import VehicleDetails from '../../Vehicle/Details'

interface IOwnProps {
  allowEdit?: boolean
  forbidEditingVIN?: boolean
  id: string
  onClick?: () => void
  record: Vehicle | Other
  loading: boolean
  loaded?: boolean
  initVehicleRecord?: () => void
  fuelIcon?: React.ComponentType<SvgIconProps>
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  edit: boolean
  loading: boolean
  loaded: boolean
  record: Vehicle | Other
  oldRecord: Vehicle | Other
  error: {
    vin?: string
    regNumber?: string
    serialNumber?: string
    itemNumber?: string
  }
  extSysRegNumberUpdateMessage?: string
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    button: {
      marginLeft: theme.spacing(2),
    },
    errorColor: {
      color: theme.palette.error.main,
    },
  })

const record: Vehicle = {
  vin: '',
  regNumber: '',
  regDate: '',
  modelYear: 0,
  brand: {
    name: '',
  },
  model: {
    name: '',
  },
  fuelType: {
    name: '',
  },
}

class ProductDetails extends React.Component<TProps, IState> {
  public state: IState = {
    edit: false,
    loading: false,
    loaded: this.props.loaded!,
    record: { ...(this.props.loaded! ? this.props.record : record) },
    oldRecord: { ...record },
    error: {
      vin: undefined,
      regNumber: undefined,
      serialNumber: undefined,
      itemNumber: undefined,
    },
  }

  public componentDidMount() {
    const { initVehicleRecord } = this.props
    initVehicleRecord && initVehicleRecord()
  }

  public componentDidUpdate(prevProps: TProps) {
    if (this.props.record !== prevProps.record) {
      this.initData()
    }
  }

  public render() {
    const { classes, allowEdit, loading, forbidEditingVIN } = this.props
    const { edit, record, loaded, oldRecord, error, extSysRegNumberUpdateMessage } = this.state

    const allowEditing = allowEdit !== undefined ? allowEdit : true
    const isVehicle = 'vin' in record
    // const allowEditVin = this.role === 'admin' && !forbidEditingVIN

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{isVehicle ? t('Vehicle') : t('Product')}</PanelTitle>
          <PanelActions>
            {!edit && allowEditing && (
              <Button
                className={classes.panelActionsButton}
                onClick={this.handleEdit}
                disabled={!loaded || loading}
                size="small"
                variant="outlined"
              >
                <EditIcon className={classes.panelActionsButtonIcon} />
                {t('Edit')}
              </Button>
            )}
            {edit && allowEdit && (
              <React.Fragment>
                <Button
                  className={classNames(classes.panelActionsButton, classes.button)}
                  onClick={this.handleSave}
                  disabled={
                    !loaded ||
                    loading ||
                    JSON.stringify(oldRecord) === JSON.stringify(record) ||
                    error.vin ||
                    error.serialNumber ||
                    error.itemNumber
                      ? true
                      : false
                  }
                  size="small"
                  variant="outlined"
                  color="secondary"
                >
                  <SaveIcon className={classes.panelActionsButtonIcon} />
                  {t('Save')}
                </Button>
                <Button
                  className={classNames(classes.panelActionsButton, classes.button)}
                  onClick={this.handleCancel}
                  disabled={!loaded || loading}
                  size="small"
                  variant="outlined"
                >
                  <CancelIcon className={classes.panelActionsButtonIcon} />
                  {t('Cancel')}
                </Button>
              </React.Fragment>
            )}
          </PanelActions>
        </PanelHeader>
        <PanelContent>
          <Card>
            <CardContent>
              {isVehicle ? (
                <VehicleDetails
                  forbidEditingVIN={forbidEditingVIN}
                  editing={edit}
                  record={record as Vehicle}
                  initData={this.initData}
                  onChange={this.handleChange}
                  loading={loading}
                  loaded={loaded}
                  error={error}
                  extSysRegNumberUpdateMessage={extSysRegNumberUpdateMessage}
                />
              ) : (
                <OtherDetails
                  forbidEditingVIN={forbidEditingVIN}
                  editing={edit}
                  record={record as Other}
                  initData={this.initData}
                  onChange={this.handleChange}
                  loading={loading}
                  loaded={loaded}
                  error={error}
                />
              )}
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private initData = async () => {
    this.setState({
      record: this.props.record,
      oldRecord: this.props.record,
      loading: false,
      loaded: true,
    })
  }

  private handleCancel = () => {
    this.setState({ edit: false, record: this.state.oldRecord })
  }

  private handleSave = async () => {
    const { id } = this.props
    const { record } = this.state

    this.setState({ loading: true })

    const response = await patchContractProduct(id, record)

    if (response.data) {
      const extSysRegNumberUpdateMessage = isVehicleUpdateResponseObject(response.data)
        ? response.data.extSysRegNumberUpdateMessage
        : undefined
      this.setState({
        record: response.data,
        oldRecord: response.data,
        edit: false,
        loading: false,
        extSysRegNumberUpdateMessage,
        // title: response.data.regNumber || '',
      })

      if (!!extSysRegNumberUpdateMessage?.length) {
        notify.warning({
          message: t(
            "An error occurred in V4 request. You have to edit 'Registration number' manually in V4 (super important)!",
          ),
        })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  private handleChange = (
    record: Vehicle | Other,
    error?: { vin?: string; regNumber?: string; serialNumber?: string; itemNumber?: string },
  ) => {
    error ? this.setState({ record, error }, () => {}) : this.setState({ record })
  }

  private handleEdit = () => {
    this.setState({ edit: true, oldRecord: this.state.record })
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(ProductDetails)
