import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import React, { ChangeEvent, Component } from 'react'
import { horsepower2kW, kW2horsepower } from 'utils/vehicle'
import { createPanelStyles, theme as customTheme } from '../../theme'
import { t } from '../../translations/translationFunctions'

// Hard limits for engine power number field.
const HARD_LIMITS = {
  MAX_ENGINE_POWER_KW: 735, // 735 kW = 1000 hp.
  MIN_ENGINE_POWER_KW: 0,
  MAX_ENGINE_POWER_HP: 1000, //  1000 hp = 735 kW.
  MIN_ENGINE_POWER_HP: 0,
}

const isDisableBackdropClick = true
const isCloseOnEscapeKeyDown = true

interface IProps {
  isUsingV4PTAsSource: boolean
  initialEnginePowerKW: null | number
  lockedMinEnginePowerKW: null | number
  onConfirm: (userGivenEnginePowerKW: number | null) => void
  onCancel: () => void
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    content: {
      overflowY: 'unset',
      marginBottom: '1vh',
    },
    actionButtons: {
      marginLeft: '2px',
      marginRight: '2px',
      marginBottom: '2px',
      paddingTop: '2vh',
    },
    buttonOk: {
      fontSize: '0.775rem',
    },
    buttonCancel: {
      fontSize: '0.775rem',
      marginLeft: '12px',
    },
    description: {
      color: customTheme.palette.text.dark,
    },
    descriptionContainer: {
      paddingBottom: '2vh',
      fontSize: '14px',
    },
    radioGroupContainer: {
      textAlign: 'center',
      margin: theme.spacing(0.75),
    },
    titleRadioGroup: {
      textAlign: 'left',
      color: '#111',
      paddingBottom: '1vh',
    },
    label: {
      minWidth: '18ch',
    },
    labelUnits: {
      fontWeight: '400',
      paddingLeft: '1vw',
    },
    radioGroupValueContainer: {
      display: 'inline',
    },
    selected: {
      opacity: '1',
    },
    unselected: {
      opacity: '0.60',
    },
  })

type TProps = IProps & WithStyles<typeof styles>

type TValueRadioGroup = 'kilowatt' | 'horsepower'
interface IState {
  valueRadioGroup: TValueRadioGroup
  enginePowerKW: number | null // Power in kW is the main value for engine power.
  lockedMinKW: number
  lockedMinHP: number
  isInEditingTextFieldState: boolean
}

const initialState: IState = {
  valueRadioGroup: 'kilowatt',
  enginePowerKW: null,
  lockedMinKW: HARD_LIMITS.MIN_ENGINE_POWER_KW,
  lockedMinHP: HARD_LIMITS.MIN_ENGINE_POWER_HP,
  isInEditingTextFieldState: true,
}

type TMode = 'Missing-Engine-Power' | 'Edit-Engine-Power'
class EnginePowerRequestDialog extends Component<TProps, IState> {
  mode: TMode = 'Missing-Engine-Power'

  constructor(props: TProps) {
    super(props)

    const { initialEnginePowerKW, lockedMinEnginePowerKW } = this.props

    this.state = {
      ...initialState,
      enginePowerKW: initialEnginePowerKW || null,
      lockedMinKW: lockedMinEnginePowerKW || HARD_LIMITS.MIN_ENGINE_POWER_KW,
      lockedMinHP: !lockedMinEnginePowerKW
        ? HARD_LIMITS.MIN_ENGINE_POWER_HP
        : Math.round(kW2horsepower(lockedMinEnginePowerKW)),
    }

    if (this.props.initialEnginePowerKW) {
      this.mode = 'Edit-Engine-Power'
    }
  }

  componentDidMount(): void {
    if (!this.props.isUsingV4PTAsSource) {
      throw Error('EnginePowerRequestDialog is only implemented in isUsingV4PTAsSource')
    }
  }

  public render() {
    const { onCancel, classes } = this.props
    const { valueRadioGroup, enginePowerKW, lockedMinKW, lockedMinHP, isInEditingTextFieldState } = this.state

    const enginePowerHP: number | null = !enginePowerKW ? null : Math.round(kW2horsepower(enginePowerKW))

    const dialogTitle: string =
      this.mode === 'Missing-Engine-Power' ? t('Enter Missing Engine Power') : t('Edit Engine Power')

    return (
      <Dialog open={true} maxWidth="xs" disableEscapeKeyDown={true} onClose={this.onClose} disableAutoFocus={true}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent className={classNames(classes.content)}>
          <div className={classes.descriptionContainer}>
            <div className={classes.description}>
              {this.mode === 'Missing-Engine-Power' && (
                <>
                  {t('We are missing engine power for this vehicle.')}
                  <br />
                  <br />
                </>
              )}
            </div>
            <div className={classes.description}>{t('Please enter the correct total engine power, to continue.')}</div>
          </div>
          <div className={classes.radioGroupContainer}>
            <FormControl>
              <FormLabel className={classes.titleRadioGroup}>{t('Total Engine Power')}:</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valueRadioGroup}
                onChange={this.handleRadioGroupChange}
              >
                <div>
                  <div style={{ textAlign: 'left' }}>
                    <div
                      className={classNames(
                        classes.radioGroupValueContainer,
                        valueRadioGroup === 'kilowatt' ? classes.selected : classes.unselected,
                      )}
                    >
                      <FormControlLabel
                        className={classes.label}
                        value="kilowatt"
                        control={<Radio />}
                        label={t('Kilowatts') + ':'}
                      />
                      <TextField
                        autoFocus
                        hidden={false}
                        fullWidth={false}
                        value={!enginePowerKW ? '' : Math.round(enginePowerKW).toString()}
                        margin="dense"
                        label=""
                        onBlur={this.handleOnBlurKW}
                        onChange={this.handleValueChangeKW}
                        onClick={() => {
                          if (valueRadioGroup !== 'kilowatt') {
                            this.setState({ valueRadioGroup: 'kilowatt' })
                          }
                        }}
                        onKeyUp={this.handleOnKeyDown}
                        type="number"
                        InputProps={{
                          inputProps: {
                            style: { textAlign: 'center', fontSize: '1.25rem' },
                            min: lockedMinKW,
                            max: HARD_LIMITS.MAX_ENGINE_POWER_KW,
                          },
                        }}
                      />
                      <span className={classes.labelUnits}>{'kW'}</span>
                    </div>
                  </div>
                  {/* ------------------------------ */}
                  <div style={{ textAlign: 'left' }}>
                    <div
                      className={classNames(
                        classes.radioGroupValueContainer,
                        valueRadioGroup === 'horsepower' ? classes.selected : classes.unselected,
                      )}
                    >
                      <FormControlLabel
                        className={classes.label}
                        value="horsepower"
                        control={<Radio />}
                        label={t('Horsepower') + ':'}
                      />
                      <TextField
                        hidden={false}
                        fullWidth={false}
                        value={!enginePowerHP ? '' : Math.round(enginePowerHP).toString()}
                        margin="dense"
                        label=""
                        onBlur={this.handleOnBlurHP}
                        onChange={this.handleValueChangeHP}
                        onClick={() => {
                          if (valueRadioGroup !== 'horsepower') {
                            this.setState({ valueRadioGroup: 'horsepower' })
                          }
                        }}
                        onKeyUp={this.handleOnKeyDown}
                        type="number"
                        InputProps={{
                          inputProps: {
                            style: { textAlign: 'center', fontSize: '1.25rem' },
                            min: lockedMinHP,
                            max: HARD_LIMITS.MAX_ENGINE_POWER_HP,
                          },
                        }}
                      />
                      <span className={classes.labelUnits}>{t('hp')}</span>
                    </div>
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className={classNames(classes.actionButtons)}>
          <Button
            className={classNames(classes.buttonOk)}
            onClick={this.handleConfirm}
            variant="contained"
            color={isInEditingTextFieldState ? 'default' : 'secondary'}
          >
            {t('Ok')}
          </Button>
          <Button className={classNames(classes.buttonCancel)} onClick={onCancel} variant="contained">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && isDisableBackdropClick) {
      return
    } else if (reason === 'escapeKeyDown' && !isCloseOnEscapeKeyDown) {
      return
    }

    this.props.onCancel()
  }

  private handleOnEnterKey = () => {
    const { isInEditingTextFieldState } = this.state

    if (isInEditingTextFieldState) {
      const withinLimitsEnginePowerKW: number | null = this.valueKWIntoLimits()

      this.setState({ isInEditingTextFieldState: false, enginePowerKW: withinLimitsEnginePowerKW })
    } else {
      this.handleConfirm()
    }
  }

  private handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.handleOnEnterKey()
    }
  }

  private handleConfirm = () => {
    const withinLimitsEnginePowerKW: number | null = this.valueKWIntoLimits()

    this.props.onConfirm(!withinLimitsEnginePowerKW ? null : Math.round(withinLimitsEnginePowerKW))
  }

  private handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value

    if (value === 'kilowatt') {
      this.setState({ valueRadioGroup: 'kilowatt' })
    } else {
      this.setState({ valueRadioGroup: 'horsepower' })
    }
  }

  private handleValueChangeKW = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ valueRadioGroup: 'kilowatt' })

    const { value } = event.target

    this.setState({ enginePowerKW: Number.parseInt(value), isInEditingTextFieldState: true })
  }

  private handleValueChangeHP = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ valueRadioGroup: 'horsepower' })

    const { value } = event.target
    const wantedEnginePowerHP = Number.parseInt(value)

    this.setState({ enginePowerKW: horsepower2kW(wantedEnginePowerHP), isInEditingTextFieldState: true })
  }

  private handleOnBlurKW = () => {
    const oldEnginePowerKW = this.state.enginePowerKW
    const newEnginePowerKW = this.valueKWIntoLimits()

    if (newEnginePowerKW !== oldEnginePowerKW) {
      this.setState({ enginePowerKW: newEnginePowerKW })
    }
  }

  private handleOnBlurHP = () => {
    const withinLimitsEnginePowerHP: number | null = this.valueHPIntoLimits()

    this.setState({ enginePowerKW: !withinLimitsEnginePowerHP ? null : horsepower2kW(withinLimitsEnginePowerHP) })
  }

  private valueKWIntoLimits = (): null | number => {
    const { lockedMinEnginePowerKW } = this.props
    const wantedEnginePowerKW: null | number = this.state.enginePowerKW

    if (!wantedEnginePowerKW || wantedEnginePowerKW < HARD_LIMITS.MIN_ENGINE_POWER_KW) {
      return lockedMinEnginePowerKW || HARD_LIMITS.MIN_ENGINE_POWER_KW
    }
    if (wantedEnginePowerKW > HARD_LIMITS.MAX_ENGINE_POWER_KW) {
      return HARD_LIMITS.MAX_ENGINE_POWER_KW
    }
    if (lockedMinEnginePowerKW && wantedEnginePowerKW < lockedMinEnginePowerKW) {
      return lockedMinEnginePowerKW
    }

    return wantedEnginePowerKW
  }

  private valueHPIntoLimits = () => {
    const { enginePowerKW } = this.state
    const wantedEnginePowerHP: null | number = !enginePowerKW ? null : kW2horsepower(enginePowerKW)

    if (!wantedEnginePowerHP || wantedEnginePowerHP < HARD_LIMITS.MIN_ENGINE_POWER_HP) {
      return HARD_LIMITS.MIN_ENGINE_POWER_HP
    }
    if (wantedEnginePowerHP > HARD_LIMITS.MAX_ENGINE_POWER_HP) {
      return HARD_LIMITS.MAX_ENGINE_POWER_HP
    }

    return wantedEnginePowerHP
  }
}

export default withStyles(styles)(EnginePowerRequestDialog)
