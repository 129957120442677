import React, { Component } from 'react'
import { withStyles, WithStyles, Drawer } from '@material-ui/core'
import {
  IApiOtherWithContracts,
  IApiVehicleContract,
  IApiVehicleWithContracts,
  IProviderWithContracts,
} from '@omnicar/sam-types'
import CustomerPortalVehicleList from 'components/customer/Contract/Details/VehicleList'
import styles from './styles'

interface IProps extends WithStyles<typeof styles> {
  // vehicles: IApiVehicleWithContracts[]
  providers: IProviderWithContracts[]
  selectedContract: IApiVehicleWithContracts | IApiOtherWithContracts | undefined
  onContractSelect: (
    selectedContract: IApiVehicleContract | undefined,
    selectedVehicle: IApiVehicleWithContracts | IApiOtherWithContracts | undefined,
  ) => void
}

class VehicleListDesktop extends Component<IProps> {
  public render() {
    const { classes, /*vehicles,*/ providers, onContractSelect, selectedContract } = this.props
    return (
      <Drawer
        className={classes.root}
        variant="permanent"
        open={true}
        classes={{
          paper: classes.paper,
        }}
      >
        <CustomerPortalVehicleList
          providers={providers}
          // vehicles={vehicles}
          handleContractSelect={onContractSelect}
          selectedContract={selectedContract}
        />
      </Drawer>
    )
  }
}

export default withStyles(styles)(VehicleListDesktop)
