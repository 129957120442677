interface ICountrySettings {
  SE?: boolean
  DK?: boolean
  FI?: boolean
}

const hideEANFieldForCompanyAddress: ICountrySettings = {
  SE: false,
  DK: false,
  FI: true,
}

export default hideEANFieldForCompanyAddress
