import React from 'react'
import Select, {
  ActionMeta,
  FormatOptionLabelMeta,
  GroupedOptionsType,
  OptionsType,
  OptionTypeBase,
  Styles,
  StylesConfig,
  ValueType,
} from 'react-select'
import { formatGroupLabel } from 'react-select/src/builtins'
import { theme as customTheme } from '../../theme'

type TProps<TOptionType extends OptionTypeBase> = {
  className?: string
  autoFocus?: boolean
  options?: OptionsType<TOptionType> | GroupedOptionsType<TOptionType> | undefined
  onChange?:
    | (((value: ValueType<TOptionType>, actionMeta: ActionMeta) => void) &
        ((value: ValueType<TOptionType>, action: ActionMeta) => void))
    | undefined
  value?: ValueType<TOptionType>
  formatOptionLabel?:
    | ((option: TOptionType, labelMeta: FormatOptionLabelMeta<TOptionType>) => React.ReactNode)
    | undefined
  styles?: Partial<Styles>
  isDisabled?: boolean
  defaultValue?: ValueType<TOptionType>
  formatGroupLabel?: formatGroupLabel<TOptionType>
  placeholder?: React.ReactNode
  noOptionsMessage?: (obj: { inputValue: string }) => string | null
}

const customStyles: StylesConfig = {
  control: (styles, state) => {
    return {
      ...styles,
      boxShadow: state.isFocused ? `0 0 0 1px ${customTheme.palette.secondary[500]}` : styles.borderColor,
      borderColor: state.isFocused ? customTheme.palette.secondary[500] : styles.borderColor,
      '&:hover': { borderColor: customTheme.palette.secondary[500] },
    }
  },
  option: (styles, state) => {
    return {
      ...styles,
      backgroundColor: state.isSelected
        ? customTheme.palette.secondary[500]
        : typeof styles.background === 'string'
        ? styles.background
        : '',
      '&:hover': {
        backgroundColor: state.isSelected
          ? customTheme.palette.secondary[500]
          : customTheme.palette.secondary[100] + '70',
      },
    }
  },
}

class StyledSelect<TOptionType extends OptionTypeBase> extends React.Component<TProps<TOptionType>> {
  render() {
    const {
      autoFocus,
      options,
      onChange,
      value,
      formatOptionLabel,
      styles,
      className,
      isDisabled,
      defaultValue,
      formatGroupLabel,
      placeholder,
    } = this.props

    return (
      <Select
        className={className}
        autoFocus={autoFocus}
        options={options}
        onChange={onChange}
        value={value}
        formatOptionLabel={formatOptionLabel}
        styles={{ ...styles, ...customStyles }}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        formatGroupLabel={formatGroupLabel}
        placeholder={placeholder}
      />
    )
  }
}

export default StyledSelect
