import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import React, { Fragment } from 'react'
import Downshift, { DownshiftProps, ControllerStateAndHelpers } from 'downshift'
import { FormControl, InputAdornment, TextField, MenuItem } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon, Clear as ClearIcon } from '@material-ui/icons'
import classNames from 'classnames'
import Paper from 'components/Mui/Paper'
import { SearchFieldDataType, IFueltypeOption } from '../../Vehicle/types'

interface IProps extends DownshiftProps<IFueltypeOption> {
  type: SearchFieldDataType
  inputLabel: string
  inputValue: string
  inputCaption?: string
  options: IFueltypeOption[]
  errorMessage?: string
  onBlur: (type: SearchFieldDataType) => void
  onClear?: () => void
  skipFiltering?: boolean
}

type TProps = IProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    focusedSearchResult: {
      backgroundColor: theme.palette.secondary.light,
    },
    searchResult: {
      cursor: 'pointer',
    },
    date: {
      marginLeft: 0,
      marginRight: 0,
    },
    icon: {
      color: theme.palette.text.secondary,
      top: -3,
    },
    clearIcon: {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
    results: {
      position: 'absolute',
      zIndex: 200,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      maxHeight: '260px',
      overflowY: 'scroll',
    },
    root: {
      position: 'relative',
    },
  })

class SearchField extends React.Component<TProps> {
  private inputRef: any

  public render() {
    const {
      classes,
      onChange,
      onInputValueChange,
      inputLabel,
      inputValue,
      inputCaption,
      options,
      type,
      errorMessage,
      onBlur,
      onClear,
      skipFiltering,
    } = this.props

    let filteredData = options

    if (!skipFiltering) {
      filteredData = options.filter(
        (item) =>
          !inputValue ||
          (item.caption
            ? item.caption.toLowerCase().includes(inputValue.toLowerCase())
            : item.name.toLowerCase().includes(inputValue.toLowerCase())),
      )
    }

    const hasResults = filteredData.length > 0
    return (
      <Downshift
        // tslint:disable-next-line:jsx-no-lambda
        onChange={(data: IFueltypeOption, helpers: ControllerStateAndHelpers<IFueltypeOption>) => {
          onChange && onChange(data, helpers)
          this.inputRef.blur()
        }}
        onInputValueChange={onInputValueChange}
        // tslint:disable-next-line:jsx-no-lambda
        itemToString={() => (inputCaption ? inputCaption : inputValue)}
        selectedItem={inputValue}
      >
        {({ getInputProps, getItemProps, highlightedIndex, isOpen, openMenu, closeMenu, clearSelection }) => (
          <div className={classes.root} data-e2e={`ContractFlowVehicleForm__${type}`}>
            <FormControl fullWidth={true} margin="dense">
              <TextField
                // tslint:disable-next-line jsx-no-lambda
                inputRef={(el: any) => (this.inputRef = el)}
                error={errorMessage ? true : false}
                helperText={errorMessage}
                label={inputLabel}
                {...getInputProps()}
                // tslint:disable-next-line jsx-no-lambda
                onFocus={() => {
                  if (hasResults) {
                    openMenu()
                  }
                }}
                // tslint:disable-next-line jsx-no-lambda
                onBlur={() => {
                  if (isOpen) {
                    closeMenu()
                  }
                  onBlur(type)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Fragment>
                        {inputValue && (
                          // tslint:disable-next-line:jsx-no-lambda
                          <ClearIcon
                            onClick={() => {
                              // clearSelection()
                              onClear && onClear()
                            }}
                            className={classes.clearIcon}
                          />
                        )}
                        <ArrowDropDownIcon
                          // tslint:disable-next-line:jsx-no-lambda
                          onClick={() => openMenu()}
                        />
                      </Fragment>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {isOpen && hasResults && (
              <Paper className={classes.results} data-e2e={`ContractFlowVehicleForm__${type}__Menu`}>
                {filteredData.map((data: IFueltypeOption, index: number) => (
                  <MenuItem
                    {...getItemProps({ item: data, key: `${type}_${data.id}` })}
                    className={classNames(
                      index === highlightedIndex ? classes.focusedSearchResult : null,
                      classes.searchResult,
                    )}
                  >
                    {!data.caption ? data.name : data.caption}
                  </MenuItem>
                ))}
              </Paper>
            )}
          </div>
        )}
      </Downshift>
    )
  }
}

export default withStyles(styles)(SearchField)
