import { initTranslations, getLocale } from '@omnicar/sam-translate'
import { IsoLocale, TTranslationMap } from '@omnicar/sam-types'
import { captureEvent, reportMissingTranslation } from './sentry'
import { Severity } from '@sentry/types'
import { LOCALE_CODE_DEFAULT } from 'utils/locale'
import { baseUrl } from 'api/api'

const translationsURL = `${baseUrl}${process.env.REACT_APP_TRANSLATIONS_URL}`

export const ensureTranslations = async (localeToUse: IsoLocale) => {
  const { errorCallback, fallbackLocale } = getTranslationSettings()
  const locale = !localeToUse ? fallbackLocale : localeToUse
  let success: boolean = false

  success = await initTranslations({
    translationAPIUrl: translationsURL,
    errorCallback,
    locale,
    token: 'Admin-token',
    localStorageKey: 'admin',
    cacheExpirationTime: Number(process.env.REACT_APP_TRANSLATIONS_CACHE_EXPIRATION),
  })

  if (!success) {
    captureEvent({
      message: 'Unable to download translations',
      level: Severity.Error,
      extra: {
        url: translationsURL,
        locale,
      },
    })
  }
  return success
}

export function getMappedTranslation(map: TTranslationMap, def: string): string {
  const locale = getLocale()
  return (locale && map[locale]) || map[LOCALE_CODE_DEFAULT] || def
}

const getTranslationSettings = () => {
  const fallbackLocale = LOCALE_CODE_DEFAULT

  // tslint:disable-next-line no-empty
  let errorCallback: (message: string) => void = () => {}

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      errorCallback = console.warn
      if (getLocale() !== 'en') {
        errorCallback = reportMissingTranslation(Severity.Error)
      }
      break
    case 'development':
      errorCallback = console.warn
      break
    case 'staging':
      errorCallback = console.warn
      break
    case 'demo':
      errorCallback = console.warn
      if (getLocale() !== 'en') {
        errorCallback = reportMissingTranslation(Severity.Warning)
      }
      break
    default:
      errorCallback = () => {}
      break
  }

  return { errorCallback, fallbackLocale }
}
