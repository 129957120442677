import { Theme, createStyles } from '@material-ui/core'

export default ({ spacing, palette, typography }: Theme) =>
  createStyles({
    root: {},
    sidePadding: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    bottomPaddig: {
      paddingBottom: spacing(13),
    },
    spaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cardTitle: {
      marginBottom: spacing(3),
    },
    activeHeader: {
      marginBottom: spacing(4),
    },
    topSellers: {
      marginTop: spacing(2),
    },
    roundedCard: {
      borderRadius: 10,
    },
    section: {
      display: 'flex',
      width: '100%',
      marginBottom: spacing(3),
      position: 'relative',
    },
    sectionCol: {
      flexDirection: 'column',
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      '& > div': {
        width: '40%',
        maxWidth: '50%',
        margin: 0,
        '&:first-child': {
          marginLeft: 0,
          marginRight: spacing(4),
        },
        '&:last-child': {
          marginRight: 0,
          marginLeft: spacing(4),
        },
      },
    },
    overlay: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      animation: 'enter 0.3s ease-in forwards',
    },
    '@keyframes enter': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    overlayWhite: {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
    overlayBlack: {
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
    overlayIcon: {
      margin: spacing(2),
      color: palette.secondary.main,
    },
    noDataPlaceholderContainer: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100px',
    },
    noDataPlaceholder: {
      color: palette.text.secondary,
    },
    noMargin: {
      margin: '0',
    },
    monthBox: {
      padding: '10px',
      margin: '0',
      height: 'fit-content',
    },
    clickableText: {
      cursor: 'pointer',
      fontWeight: 'normal',
    },
    monthPickerOverlay: {
      position: 'absolute',
      marginTop: '11em',
      paddingLeft: spacing(3),
      zIndex: 50,
    },
    label: {
      fontSize: typography.button.fontSize,
      color: palette.grey['700'],
      fontWeight: typography.fontWeightLight,
      padding: '0',
      margin: '0',
    },
    iconButton: {
      marginLeft: '-8px',
      padding: '10px',
    },
  })
