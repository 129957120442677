import React from 'react'
import Typography from 'components/Typography'
import { t } from 'translations/translationFunctions'
import styles from './styles'
import { WithStyles, withStyles } from '@material-ui/core'

interface IProps extends WithStyles<typeof styles> {}

const CustomerContractDetailsTabsNotSelected: React.SFC<IProps> = ({ classes }) => (
  <section className={classes.root}>
    <Typography variant="display2">{t('Choose Subscription Agreement')}</Typography>
  </section>
)

export default withStyles(styles)(CustomerContractDetailsTabsNotSelected)
