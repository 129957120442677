import { Theme, createStyles } from '@material-ui/core/styles'
import { palette } from '..'

export const createTypographyStyles = (theme: Theme, customStyles?: any) =>
  createStyles({
    display1: {
      fontSize: 16,
      [theme.breakpoints.up('sm')]: {
        fontSize: 18,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.regular,
    },
    display2: {
      fontSize: 32,
      [theme.breakpoints.up('sm')]: {
        fontSize: 64,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.lighter,
    },
    'header-light': {
      fontSize: 20,
      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
      },
      fontWeight: theme.typography.fontWeightLight,
      color: palette.text.light,
    },
    header: {
      fontSize: 20,
      [theme.breakpoints.up('sm')]: {
        fontSize: 28,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.regular,
    },
    subheading: {
      fontSize: 14,
      [theme.breakpoints.up('sm')]: {
        fontSize: 18,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.light,
    },
    title: {
      fontSize: 16,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.regular,
    },
    subtitle: {
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.light,
    },
    body1: {
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {
        fontSize: 15,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.regular,
    },
    body2: {
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {
        fontSize: 13,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.regular,
    },
    'button-medium': {
      fontSize: 14,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
      fontWeight: theme.typography.fontWeightMedium,
      color: palette.text.regular,
    },
    button: {
      fontSize: 14,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.regular,
    },
    caption: {
      fontSize: 10,
      [theme.breakpoints.up('sm')]: {
        fontSize: 12,
      },
      fontWeight: theme.typography.fontWeightRegular,
      color: palette.text.regular,
    },
    ...(customStyles || {}),
  })
