import { Theme, createStyles } from '@material-ui/core'
import { theme as customTheme } from 'theme'

export default ({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(2),
      border: `2px dashed ${palette.grey[500]}`,
      boxShadow: 'none',
    },
    button: {
      color: customTheme.palette.text.light,
    },
    buttonWrap: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
  })
