import React from 'react'
import { CancelOutlined as HardBounceIcon, ErrorOutline as SoftBounceIcon } from '@material-ui/icons'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { t } from 'translations/translationFunctions'
import bounceMessages from 'utils/bounce'
import { BounceType } from 'postmark/dist/client/models'
import classNames from 'classnames'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { theme as customTheme } from 'theme'

interface IProps {
  className: string
  bounceType: string
  displayTooltip?: boolean
  overrideColor?: boolean
}

const styles = (theme: Theme) =>
  createStyles({
    warning: {
      color: `${customTheme.palette.context.attention[600]} !important`,
    },
    error: {
      color: `${theme.palette.error.main} !important`,
    },
  })

type TProps = IProps & WithStyles<typeof styles>

class BounceIcon extends React.Component<TProps> {
  render() {
    const { classes, className, bounceType, displayTooltip, overrideColor } = this.props
    const Icon = this.getBounceIconByType(this.props.bounceType)

    return (
      <Icon
        className={classNames(
          className,
          overrideColor && (bounceType === 'SoftBounce' ? classes.warning : classes.error),
        )}
        titleAccess={displayTooltip ? t(bounceMessages[bounceType] || bounceMessages['Unknown']) : undefined}
      />
    )
  }

  private getBounceIconByType(type: string) {
    let BounceIcon: React.ComponentType<SvgIconProps> = HardBounceIcon
    switch (type as BounceType) {
      case 'SoftBounce':
        BounceIcon = SoftBounceIcon
        break
    }

    return BounceIcon
  }
}

export default withStyles(styles)(BounceIcon)
