// @TODO - Get Erik to show me how to get react router definitions loaded in
export interface IReactRouterLocation {
  pathname: string
  search: string
  hash: string
  key: string
  state: any
}

export interface IReactRouterState {
  location: IReactRouterLocation
}

export const reactRouterInitialState: IReactRouterState = {
  location: {
    hash: '',
    key: '',
    pathname: '',
    search: '',
    state: undefined,
  },
}
