import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { t } from 'translations/translationFunctions'
import classNames from 'classnames'
import Typography from 'components/Typography'
import * as React from 'react'
import { theme as customTheme } from 'theme'
import { ContractFlowActiveStep } from 'types/contractFlow'
import { isUseWhiteBGTheme } from 'utils/localStorage'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    item: {
      borderBottom: `${theme.spacing(1)}px solid ${theme.palette.primary[500]}`,
      flexBasis: '33.3333%',
      opacity: 0.4,
      paddingBottom: `${theme.spacing(1)}px`,
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    freeContract: {
      borderBottomColor: customTheme.palette.freeContract[500],
    },
    itemActive: {
      opacity: 1,
    },
    text: {
      color: isUseWhiteBGTheme() ? theme.palette.text.secondary : theme.palette.secondary.contrastText,
    },
  })

interface IOwnProps {
  step: number
  className?: string
  freeContract: boolean
}

type TProps = IOwnProps & WithStyles<typeof styles>

const ContractFlowStatusBar: React.SFC<TProps> = ({ step, classes, className = '', freeContract }) => {
  return (
    <main className={classNames(classes.root, className)}>
      <section className={classNames(classes.item, classes.itemActive, freeContract && classes.freeContract)}>
        <Typography className={classes.text} variant="subtitle">
          {t('Agreement Details')}
        </Typography>
      </section>
      <section
        className={classNames(
          freeContract && classes.freeContract,
          classes.item,
          step >= ContractFlowActiveStep.payment ? classes.itemActive : '',
        )}
      >
        <Typography className={classes.text} variant="subtitle">
          {t('Customer & Payment')}
        </Typography>
      </section>
      <section
        className={classNames(
          classes.item,
          step === ContractFlowActiveStep.send ? classes.itemActive : '',
          freeContract && classes.freeContract,
        )}
      >
        <Typography className={classes.text} variant="subtitle">
          {t('Send Offer')}
        </Typography>
      </section>
    </main>
  )
}

export default withStyles(styles)(ContractFlowStatusBar)
