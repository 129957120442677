import { theme } from 'theme'

interface IIncludedProperties {
  borderColor?: boolean
  color?: boolean
  backgroundColor?: boolean
}

export const contractStateStyles = ({ borderColor, color, backgroundColor }: IIncludedProperties) => ({
  state100: {
    borderColor: borderColor ? theme.palette.contractStates.offer[500] : undefined,
    color: color ? theme.palette.contractStates.offer[500] : undefined,
  },
  state110: {
    borderColor: borderColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    color: color ? theme.palette.contractStates.offerExpired[500] : undefined,
  },
  state120: {
    borderColor: borderColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    color: color ? theme.palette.contractStates.offerExpired[500] : undefined,
  },
  state130: {
    borderColor: borderColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    color: color ? theme.palette.contractStates.offerExpired[500] : undefined,
  },
  state200: {
    borderColor: borderColor ? theme.palette.contractStates.active[500] : undefined,
    color: color ? theme.palette.contractStates.active[500] : undefined,
  },
  state210: {
    borderColor: borderColor ? theme.palette.contractStates.active[500] : undefined,
    color: color ? theme.palette.contractStates.active[500] : undefined,
  },
  state250: {
    borderColor: borderColor ? theme.palette.contractStates.active[500] : undefined,
    color: color ? theme.palette.contractStates.active[500] : undefined,
  },
  state400: {
    borderColor: borderColor ? theme.palette.contractStates.paymentFailed[500] : undefined,
    color: color ? theme.palette.contractStates.paymentFailed[500] : undefined,
  },
  state450: {
    borderColor: borderColor ? theme.palette.contractStates.cancelledSubsctiption[500] : undefined,
    color: color ? theme.palette.contractStates.cancelledSubsctiption[500] : undefined,
  },
  state500: {
    borderColor: borderColor ? theme.palette.contractStates.contractTerminated[500] : undefined,
    color: color ? theme.palette.contractStates.contractTerminated[500] : undefined,
  },
  state600: {
    borderColor: borderColor ? theme.palette.contractStates.contractExpired[500] : undefined,
    color: color ? theme.palette.contractStates.contractExpired[500] : undefined,
  },
  state900: {
    borderColor: borderColor ? theme.palette.contractStates.contractSettled[500] : undefined,
    color: color ? theme.palette.contractStates.contractSettled[500] : undefined,
  },
  state950: {
    borderColor: borderColor ? theme.palette.contractStates.contractSettled[500] : undefined,
    color: color ? theme.palette.contractStates.contractSettled[500] : undefined,
  },
  stateActive100: {
    borderColor: borderColor ? theme.palette.contractStates.offer[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.offer[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive110: {
    borderColor: borderColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive120: {
    borderColor: borderColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive130: {
    borderColor: borderColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.offerExpired[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive200: {
    borderColor: borderColor ? theme.palette.contractStates.active[800] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.active[800] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive210: {
    borderColor: borderColor ? theme.palette.contractStates.active[800] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.active[800] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive250: {
    borderColor: borderColor ? theme.palette.contractStates.active[800] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.active[800] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive400: {
    borderColor: borderColor ? theme.palette.contractStates.paymentFailed[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.paymentFailed[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive450: {
    borderColor: borderColor ? theme.palette.contractStates.cancelledSubsctiption[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.cancelledSubsctiption[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive500: {
    borderColor: borderColor ? theme.palette.contractStates.contractTerminated[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.contractTerminated[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive600: {
    borderColor: borderColor ? theme.palette.contractStates.contractExpired[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.contractExpired[500] : undefined,
    color: color ? 'white' : undefined,
  },
  typeActiveProduct: {
    borderColor: borderColor ? theme.palette.contractTypes.productContract[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractTypes.productContract[500] : undefined,
    color: color ? 'white' : undefined,
  },
  typeActiveVehicle: {
    borderColor: borderColor ? theme.palette.contractTypes.vehicleContract[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractTypes.vehicleContract[500] : undefined,
    color: color ? 'white' : undefined,
  },
  typeProduct: {
    borderColor: borderColor ? theme.palette.contractTypes.productContract[500] : undefined,
    color: color ? theme.palette.contractTypes.productContract[500] : undefined,
  },
  typeVehicle: {
    borderColor: borderColor ? theme.palette.contractTypes.vehicleContract[500] : undefined,
    color: color ? theme.palette.contractTypes.vehicleContract[500] : undefined,
  },
  stateActive900: {
    borderColor: borderColor ? theme.palette.contractStates.contractSettled[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.contractSettled[500] : undefined,
    color: color ? 'white' : undefined,
  },
  stateActive950: {
    borderColor: borderColor ? theme.palette.contractStates.contractSettled[500] : undefined,
    backgroundColor: backgroundColor ? theme.palette.contractStates.contractSettled[500] : undefined,
    color: color ? 'white' : undefined,
  },
})
