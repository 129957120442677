import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { ContractState } from '@omnicar/sam-types'
import { checkIsSuspendedExpired } from 'api/api'
import React from 'react'
import { t } from 'translations/translationFunctions'

interface IProps {
  prettyIdentifier: string
  contractState: ContractState
}

interface IState {
  isSuspendedExpired: boolean
  open: boolean
}

class ExpirationDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isSuspendedExpired: false,
      open: false,
    }
  }

  public componentDidMount() {
    this.initData()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.prettyIdentifier !== this.props.prettyIdentifier ||
      prevProps.contractState !== this.props.contractState
    ) {
      this.initData()
    }
  }

  render() {
    const { open } = this.state
    return (
      <Dialog open={open}>
        <DialogTitle>{t('The contract is expired but some payments are missed')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => this.setState({ open: false })} variant="contained" color="primary">
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private initData = async () => {
    const { prettyIdentifier, contractState } = this.props
    const res = contractState === ContractState.Suspended && (await checkIsSuspendedExpired(prettyIdentifier))
    const isSuspendedExpired = res && res.statusCode === 200 && !!res.data
    this.setState({ isSuspendedExpired, open: isSuspendedExpired })
  }
}

export default ExpirationDialog
