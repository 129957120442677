// @todo : make this a general component

import { createStyles, Theme, withStyles, WithStyles, Button } from '@material-ui/core'
import { MailOutlined as MailIcon } from '@material-ui/icons'
import React from 'react'
import { palette } from 'theme'
import { CircularProgress } from '@material-ui/core'
import { adminCustomerLoginRequest } from 'api/api'
import notify from 'utils/notify/notify'
import { t } from 'translations/translationFunctions'

interface IState {
  sendingEmail: boolean
}

interface IOwnProps {
  value: any
  disabled: boolean
}

type TProps = WithStyles<typeof styles> & IOwnProps

const styles = (theme: Theme) =>
  createStyles({
    alignCenter: {
      textAlign: 'center',
    },
    colorLight: {
      color: palette.text.light,
    },
  })

class SendMailButton extends React.Component<TProps, IState> {
  public state: IState = {
    sendingEmail: false,
  }

  public render() {
    const { value, disabled } = this.props
    const { sendingEmail } = this.state
    return (
      <div className={this.props.classes.alignCenter}>
        <Button
          onClick={this.onResendMyPageEmailClick}
          data-value={value}
          target="_blank"
          className={this.props.classes.colorLight}
          disabled={sendingEmail || disabled}
        >
          {!sendingEmail ? <MailIcon /> : <CircularProgress color="secondary" size={24} />}
        </Button>
      </div>
    )
  }

  private onResendMyPageEmailClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()

    if (!e.currentTarget.dataset.value || e.currentTarget.dataset.value === '') {
      notify.error()
      return
    }

    const prettyIdentifier = e.currentTarget && e.currentTarget.dataset.value
    const body = { prettyIdentifier }
    this.setState({ sendingEmail: true })
    const result = await adminCustomerLoginRequest(body)
    if (result && result.statusCode) {
      this.setState({ sendingEmail: false })
      if (result.statusCode === 200) {
        notify.success({ message: t('Login email sent to customer') })
      } else if (result.statusCode === 404) {
        notify.error({ message: t('Customer not found') })
      } else {
        notify.error()
      }
    }
  }
}

export default withStyles(styles)(SendMailButton)
