import { CardActionArea, Collapse, withStyles, WithStyles } from '@material-ui/core'
import { ContractState, IApiVehicleContract } from '@omnicar/sam-types'
import classNames from 'classnames'
import React, { Component } from 'react'
import { t } from 'translations/translationFunctions'
import CustomerPortalContractDetailsVehicleListItemContractListContract from './Contract'
import styles from './styles'

interface IProps extends WithStyles<typeof styles> {
  contracts: IApiVehicleContract[]
  selectedContract: IApiVehicleContract | undefined
  handleContractSelect: (selectedContract: IApiVehicleContract | undefined) => void
}
interface IState {
  showMore: boolean
  offers: IApiVehicleContract[]
  remainingContracts: IApiVehicleContract[]
}

class CustomerPortalContractDetailsVehicleListItemContractList extends Component<IProps, IState> {
  public state = {
    showMore: false,
    offers: [],
    remainingContracts: [],
  }

  public componentDidMount() {
    const { contracts } = this.props

    // list of offers / active contracts
    const offers: IApiVehicleContract[] = contracts.filter((contract) =>
      [ContractState.Offer, ContractState.Active, ContractState.ActivePendingTermination].includes(
        contract.contractState,
      ),
    )

    // if offers/actives: show other states, else: show rest
    const remainingContracts = offers.length
      ? contracts.filter(
          (contract) =>
            ![ContractState.Offer, ContractState.Active, ContractState.ActivePendingTermination].includes(
              contract.contractState,
            ),
        )
      : contracts.slice(1)

    this.setState({ offers, remainingContracts })
  }

  public render() {
    const { showMore, offers, remainingContracts } = this.state
    const { classes, contracts } = this.props

    return (
      <div className={classes.root} data-e2e={'CustomerPortalContractDetailsVehicleListItem'}>
        {/* Offers or first contract */}
        {offers.length > 0 ? this.renderContracts(offers) : this.renderContract(contracts[0])}

        {/* Collapse panel with remaining contracts */}
        {remainingContracts.length > 0 && (
          <>
            <Collapse in={showMore}>{this.renderContracts(remainingContracts, true)}</Collapse>
            <CardActionArea
              className={classNames(classes.showMore, { [classes.borderTop]: showMore })}
              onClick={this.handleShowMore}
            >
              {!showMore ? t('Show more') : t('Show less')}
            </CardActionArea>
          </>
        )}
      </div>
    )
  }

  /**
   * Render list of contracts
   */
  private renderContracts = (contracts: IApiVehicleContract[], possiblyHidden: boolean = false) => {
    if (possiblyHidden) {
      return this.state.showMore && contracts.map((contract: IApiVehicleContract) => this.renderContract(contract))
    } else {
      return contracts.map((contract: IApiVehicleContract) => this.renderContract(contract))
    }
  }

  /**
   * Render single contract
   */
  private renderContract = (contract: IApiVehicleContract) => {
    return (
      <CustomerPortalContractDetailsVehicleListItemContractListContract
        key={contract.prettyIdentifier}
        contract={contract}
        selected={this.isSelected(contract)}
        onContractClick={this.handleContractClick}
      />
    )
  }

  private handleContractClick = (contract: IApiVehicleContract) => this.props.handleContractSelect(contract)

  private isSelected = (contract: IApiVehicleContract) =>
    this.props.selectedContract && this.props.selectedContract.serviceContractId === contract.serviceContractId

  private handleShowMore = () => this.setState(({ showMore }) => ({ showMore: !showMore }))
}

export default withStyles(styles)(CustomerPortalContractDetailsVehicleListItemContractList)
