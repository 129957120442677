import ActionTypeKeys from './ActionTypeKeys'

/*************************************************************
 * CONTRACT SEARCH ACTIONS
 *************************************************************/
export interface IContractSearchUpdate {
  readonly type: ActionTypeKeys.CONTRACTSEARCH_UPDATE_QUERY
  query: string | undefined
}

export const contractSearchUpdate = (query: string | undefined): IContractSearchUpdate => {
  return {
    type: ActionTypeKeys.CONTRACTSEARCH_UPDATE_QUERY,
    query,
  }
}

export interface IWarrantySearchUpdate {
  readonly type: ActionTypeKeys.WARRANTYSEARCH_UPDATE_QUERY
  query: string | undefined
}

export const warrantySearchUpdate = (query: string | undefined): IWarrantySearchUpdate => {
  return { type: ActionTypeKeys.WARRANTYSEARCH_UPDATE_QUERY, query }
}
