import React, { SFC } from 'react'
import UserList from 'components/admin/User/List'

interface IProps {
  active: boolean
}

const UsersTab: SFC<IProps> = ({ active }) => (
  <div hidden={!active}>
    <UserList />
  </div>
)

export default UsersTab
