import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { CircularProgress } from '@material-ui/core'
import { theme } from 'theme'
import classNames from 'classnames'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary[500],
    },
  })

interface IOwnProps {
  className?: string
  size?: number
}
type TProps = IOwnProps & WithStyles<typeof styles>

const ButtonSpinner: React.SFC<TProps> = ({ classes, className, size }) => (
  <CircularProgress className={classNames(classes.root, className)} size={size ? size : 16} />
)

export default compose<TProps, IOwnProps>(withStyles(styles))(ButtonSpinner)
