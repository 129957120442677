import { Button } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons'
import { IOptionInfo, ISetPaymentMethodResponse } from '@omnicar/sam-types'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeElements } from '@stripe/stripe-js'
import { createSetupIntent, setPaymentMethod } from 'api/api'
import cn from 'classnames'
import LoadingOverlay from 'components/LoadingOverlay'
import SpinnerButton from 'components/Mui/SpinnerButton'
import Spinner from 'components/Mui/SpinnerButton/Spinner'
import Typography from 'components/Typography'
import React from 'react'
import { theme as customTheme } from 'theme'
import { t, translateItem } from 'translations/translationFunctions'
import { TranslationItem, TranslationKey } from 'translations/translationTypes'
import notify from 'utils/notify/notify'
import TermsOfService from './TermsOfService'

const stripeTermsLink = 'https://stripe.com/en-se/legal/ach-payments/authorization'
const stripePrivacyLink = 'https://stripe.com/en-se/privacy'

const styles = ({ spacing }: Theme) =>
  createStyles({
    proceedButton: { marginLeft: spacing(1) },
    cardFormWrapper: {
      marginTop: spacing(2),
      padding: spacing(1),
      borderRadius: spacing(1),
      backgroundColor: customTheme.palette.background.lighter,
      border: '1px solid transparent',
    },
    cardFormWrapperFocused: {
      borderColor: customTheme.palette.secondary[600],
    },
    errorMessage: {
      color: customTheme.palette.context.warning[300],
      display: 'block',
      minHeight: '1em',
    },
    navWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: spacing(2),
    },
    buttonIcon: { fontSize: 16 },
    formSpinner: { color: customTheme.palette.context.attention[300] },
    buttonSpinner: {
      color: customTheme.palette.context.attention[300],
      marginRight: spacing(1),
    },
    stripeInfo: {
      display: 'block',
      justifyContent: 'space-between',
    },
    stripeLinks: {
      marginLeft: '5px',
    },
  })

const stripeFormOptions = {
  hidePostalCode: true,
  style: {
    base: { fontSize: '18px' },
    invalid: { color: customTheme.palette.context.warning[300] },
  },
}

interface IIntermediateProps {
  onBack: () => void
  onSuccessfulPayment: (status: ISetPaymentMethodResponse['status']) => void
  additionalOptions: IOptionInfo[]
  termsOfTradeUrl: string
  termsOfServiceUrl: string
  contractPrettyIdentifier: string
}

interface IPaymentProps {
  stripe: Stripe
  elements: StripeElements
}

type TProps = IPaymentProps & IIntermediateProps & WithStyles<typeof styles>

interface IState {
  areTermsAccepted: boolean
  isCardComplete: boolean
  validationError: string | undefined
  handlingSubmit: boolean
  stillHandlingSubmit: boolean
  isFocused: boolean
}

const errorCodes = {
  invalid_number: 'Invalid card number',
  invalid_expiry_year: 'Invalid expiration year',
  invalid_expiry_month: 'Invalid expiration month',
  incomplete_cvc: 'Incomplete CVC',
  invalid_expiry_year_past: 'Expiration year already passed',
  invalid_expiry_month_past: 'Expiration month already passed',
}

class PaymentForm extends React.Component<TProps, IState> {
  public state = {
    isCardComplete: false,
    validationError: undefined,
    areTermsAccepted: false,
    handlingSubmit: false,
    stillHandlingSubmit: false,
    isFocused: true,
  }

  public render() {
    const { classes, stripe, elements, onBack, additionalOptions, termsOfTradeUrl, termsOfServiceUrl } = this.props
    const {
      areTermsAccepted,
      isCardComplete,
      validationError,
      handlingSubmit,
      stillHandlingSubmit,
      isFocused,
    } = this.state

    if (!stripe || !elements) {
      return <Spinner className={classes.formSpinner} />
    }

    const canProceed = isCardComplete && areTermsAccepted
    const cardFormWrapperClassName = isFocused ? classes.cardFormWrapperFocused : ''

    return (
      <>
        <LoadingOverlay
          open={stillHandlingSubmit}
          textLine1={t('Your purchase is still being processed, please wait') + '...'}
          textLine2={t('Do not close this window until it is complete') + '.'}
        />
        <TermsOfService
          options={additionalOptions.filter((o) => o.termsOfService)}
          termsOfServiceRef={termsOfServiceUrl}
          termsOfTradeRef={termsOfTradeUrl}
          onChangeAllTermsChecked={this.handleAllTermsChecks}
        />
        <Typography variant="body2">
          <span className={classes.stripeInfo}>
            {t('Payments are made through Stripe')}
            {
              <a href={stripeTermsLink} className={classes.stripeLinks} target="_blank" rel="noopener noreferrer">
                {t('Terms')}
              </a>
            }
            {
              <a href={stripePrivacyLink} className={classes.stripeLinks} target="_blank" rel="noopener noreferrer">
                {t('Privacy')}
              </a>
            }
          </span>
        </Typography>
        <div className={cn(classes.cardFormWrapper, cardFormWrapperClassName)}>
          <CardElement
            onChange={this.handleCardChange}
            options={stripeFormOptions}
            onReady={this.handleFocus}
            onFocus={this.handleFocusOnCardInput}
            onBlur={this.handleBlurOnCardInput}
          />
        </div>
        <Typography variant="body2">
          <span className={classes.errorMessage}>
            {validationError && t(errorCodes[validationError!] || validationError)}
          </span>
        </Typography>
        <div className={classes.navWrapper}>
          <Button onClick={onBack} size="small" variant="outlined">
            <ArrowBackIosIcon className={classes.buttonIcon} />
            {t('Cancel')}
          </Button>
          <SpinnerButton
            showSpinner={handlingSubmit}
            spinnerClasses={classes.buttonSpinner}
            onClick={this.handleSubmit}
            disabled={!canProceed}
            size="small"
            color="secondary"
            variant="contained"
            className={classes.proceedButton}
          >
            {t('Confirm')}
          </SpinnerButton>
        </div>
      </>
    )
  }

  private handleAllTermsChecks = (areTermsAccepted: boolean) => {
    const cardElement = this.props.elements.getElement('card')
    this.setState({ areTermsAccepted })
    areTermsAccepted && this.handleFocus(cardElement)
  }

  private handleCardChange = (e: StripeCardElementChangeEvent) =>
    this.setState({
      isCardComplete: e.complete,
      validationError: e.error && e.error.code,
    })

  private displayError = (msg: TranslationKey = 'Something went wrong') => notify.error({ message: t(msg) })
  private displayErrorItem = (msg: TranslationItem = { key: 'Something went wrong' }) =>
    notify.error({ message: translateItem(msg) })

  private handleFocus = (element: StripeCardElement | null) => {
    if (!element) return
    element.focus()
    this.setState({ isFocused: true })
  }

  private handleFocusOnCardInput = () => this.setState({ isFocused: true })

  private handleBlurOnCardInput = () => this.setState({ isFocused: false })

  private handleSubmit = async () => {
    const { contractPrettyIdentifier, onSuccessfulPayment, stripe, elements } = this.props

    if (this.state.handlingSubmit) {
      return
    }
    this.setState({ handlingSubmit: true })

    try {
      if (!stripe || !elements) {
        return this.displayError()
      }

      const cardElement = elements.getElement('card')
      if (!cardElement) {
        return this.displayError()
      }

      const { errorData: setupIntentError, data: setupIntentData } = await createSetupIntent(contractPrettyIdentifier)

      if (setupIntentError || !setupIntentData) {
        return this.displayErrorItem(
          setupIntentError ? { value: setupIntentError.message } : { key: 'Error calling createSetupIntent' },
        )
      }
      const { clientSecret } = setupIntentData
      const { error: cardSetupError, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: cardElement },
      })

      if (cardSetupError) {
        // Error messages from stripe are translated already, and if they are not then they're probably not in our translation file anyway
        return this.displayErrorItem({ value: cardSetupError.message || 'Card Setup Error' })
      }
      if (!setupIntent || !setupIntent.payment_method) {
        return this.displayError('Missing payment method id in stripe.confirmCardSetup response')
      }

      this.setState({ stillHandlingSubmit: true })
      const { errorData: paymentMethodError, data: paymentMethodData } = await setPaymentMethod(
        contractPrettyIdentifier,
        {
          contractId: contractPrettyIdentifier,
          paymentMethodId: '' + setupIntent.payment_method,
        },
      )
      if (paymentMethodError || !paymentMethodData) {
        return this.displayErrorItem(
          paymentMethodError ? { value: paymentMethodError.message } : { key: 'Error calling setPaymentMethod' },
        )
      }
      if (!['succeeded', 'processing'].includes(paymentMethodData.status)) {
        return this.displayErrorItem({ value: paymentMethodData.status })
      }

      setTimeout(() => onSuccessfulPayment(paymentMethodData.status), 0)
    } finally {
      this.setState({ handlingSubmit: false, stillHandlingSubmit: false })
    }
  }
}

const StyledPaymentForm = withStyles(styles)(PaymentForm)

const InjectedPaymentForm: React.SFC<IIntermediateProps> = (props) => (
  <ElementsConsumer>
    {({ elements, stripe }: IPaymentProps) => <StyledPaymentForm elements={elements} stripe={stripe} {...props} />}
  </ElementsConsumer>
)

type TWrapperProps = IIntermediateProps & {
  stripePromise: Promise<Stripe | null> | null
}
const Wrapper: React.SFC<TWrapperProps> = ({ stripePromise, ...props }) => {
  if (!stripePromise) {
    return <LoadingOverlay open={true} />
  }

  return (
    <Elements stripe={stripePromise}>
      <InjectedPaymentForm {...props} />
    </Elements>
  )
}

export default Wrapper
