import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { ContractActionType, ISettlementActionResponse } from '@omnicar/sam-types'
import { initSettleActions, TInitSettleActions } from 'actions/contractSettlement'
import React from 'react'
import { connect } from 'react-redux'
import { IRootState } from 'reducers/initialState'
import { selectFilteredSettlementActions } from 'selectors/contractSettlement'
import { t } from 'translations/translationFunctions'
import { bindDispatch } from 'utils/redux'
import SettlementActionItem from './ActionItem'
import ActionFiltersWrapper from './FiltersWrapper'

interface IOwnProps {
  id: string
  isSettled: boolean
  isReadOnly: boolean
}

interface IReduxDispatchProps {
  initSettleActions: TInitSettleActions
}

interface IReduxStateProps {
  actionOptions: ContractActionType[]
  actionsList: ISettlementActionResponse[]
  numberOfFilters: number
}

type TProps = IOwnProps & IReduxDispatchProps & IReduxStateProps & WithStyles<typeof styles>
const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    emptyActionsListText: {
      fontSize: spacing(2.5),
      color: palette.grey[400],
    },
  })

class SettlementRegulationActions extends React.Component<TProps> {
  public componentDidMount() {
    this.props.initSettleActions(this.props.id)
  }

  public componentDidUpdate(prevProps: TProps) {
    if (this.props.id !== prevProps.id) {
      this.props.initSettleActions(this.props.id)
    }
  }
  public render() {
    const { isSettled, actionsList, id, classes, isReadOnly } = this.props
    return (
      <ActionFiltersWrapper actionListLength={actionsList.length} isSettled={isSettled}>
        <>
          {actionsList.length ? (
            actionsList.map((action) => (
              <SettlementActionItem
                key={action.id}
                actionId={action.id}
                type={action.type}
                username={action.userName}
                created={action.created}
                shareComment={action.shareComment}
                amount={action.amount.priceInclVat}
                settlementComment={action.settlementComment}
                isSettled={isSettled}
                id={id}
                isReadOnly={isReadOnly}
              />
            ))
          ) : (
            <div className={classes.emptyActionsListText}>{t('No settlement actions on this contract')}</div>
          )}
        </>
      </ActionFiltersWrapper>
    )
  }
}

const mapState = (state: IRootState) => ({
  actionsList: selectFilteredSettlementActions(state),
})

export default withStyles(styles)(connect(mapState, bindDispatch({ initSettleActions }))(SettlementRegulationActions))
