import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Theme } from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { Close as CloseIcon, Delete as DeleteIcon, Save as SaveIcon } from '@material-ui/icons'
import { ContractValueType, IWorkshopOperation } from '@omnicar/sam-types'
import {
  deleteContractWorkshopOperation,
  postContractWorkshopOperation,
  updateContractWorkshopOperation,
} from 'api/api'
import classNames from 'classnames'
import { IReduxHiddenVATProps, withHiddenVAT } from 'components/ContractDisplayConfig/withHiddenVAT'
import DatePicker from 'components/DatePicker'
import SpinnerButton from 'components/Mui/SpinnerButton'
import isEqualWith from 'lodash.isequalwith'
import moment, { Moment } from 'moment'
import React, { ChangeEvent } from 'react'
import { compose } from 'recompose'
import { theme as customTheme, theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { valueTypeTranslations } from 'translations/ValueTypeTranslations'
import { MAXIMUM_ADMITED_VALUE } from 'utils/regex'

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    allowOverflow: {
      overflow: 'visible',
    },
    date: {
      marginLeft: 0,
      marginRight: 0,
    },
    icon: {
      color: palette.text.secondary,
      top: -3,
    },
    button: {
      marginLeft: spacing(1.5),
    },
    iconButton: {
      marginRight: spacing(1),
      fontSize: '16px',
    },
    warningButton: {
      backgroundColor: customTheme.palette.context.warning[500],
      color: 'rgba(255,255,255,1)',
    },
    spinnerColor: {
      color: theme.palette.secondary[500],
    },
  })

const emptyRecord: IWorkshopOperation = {
  id: 0,
  price: 0,
  date: new Date(),
  value: 0,
  description: '',
}

interface IOwnProps {
  record?: IWorkshopOperation
  prettyIdentifier?: string
  open: boolean
  onClose: () => void
  onSaved: () => void
  valueType: ContractValueType
}

type TProps = IOwnProps & WithStyles<typeof styles> & IReduxHiddenVATProps

interface IState {
  record: IWorkshopOperation
  oldRecord: IWorkshopOperation
  dateValue: Moment | null
  oldDateValue: Moment | null
  deleting: boolean
  saving: boolean
  errors: {
    price?: string
    date?: string
    description?: string
    mileage?: string
  }
}

class ContractDetailsWorkshopOperationListForm extends React.Component<TProps, IState> {
  public state: IState = {
    record: { ...emptyRecord },
    oldRecord: { ...emptyRecord },
    dateValue: null,
    oldDateValue: null,
    deleting: false,
    saving: false,
    errors: {},
  }

  public componentDidUpdate(oldProps: TProps) {
    const { record } = this.props
    if (!isEqualWith(record, oldProps.record)) {
      if (record) {
        this.setState({
          record: { ...record },
          dateValue: moment(record.date),
          oldRecord: { ...record },
          oldDateValue: moment(record.date),
        })
      } else {
        this.setState({
          record: { ...emptyRecord },
          dateValue: null,
          oldRecord: { ...emptyRecord },
          oldDateValue: null,
        })
      }
    }
  }

  public render() {
    const { handleDateChange } = this
    const { classes, valueType, hiddenVAT } = this.props
    const { record, dateValue, deleting, saving, oldRecord, oldDateValue } = this.state
    const { price, description, value } = record

    const translation =
      valueType && valueType !== 'Services' ? valueTypeTranslations[valueType] : valueTypeTranslations['Hours']

    return (
      <Dialog open={this.props.open} onClose={this.handleClose} classes={{ paper: classes.allowOverflow }}>
        <DialogTitle>
          {t(this.state.record.id ? 'Update Workshop Operation Entry' : 'Create Workshop Operation Entry')}
        </DialogTitle>
        <DialogContent classes={{ root: classes.allowOverflow }}>
          <DatePicker id="workshop-operations-datepicker" date={dateValue} onDateChange={handleDateChange} />
          <TextField
            name="value"
            type="number"
            margin="dense"
            label={t(translation.value)}
            fullWidth={true}
            value={value === 0 ? '' : value}
            // tslint:disable-next-line jsx-no-lambda
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = +e.target.value

              this.setState({
                record: {
                  ...this.state.record,
                  value: value,
                },
              })
            }}
          />

          <TextField
            name="description"
            margin="dense"
            label={t('Description')}
            fullWidth={true}
            value={description}
            // tslint:disable-next-line jsx-no-lambda
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value

              this.setState({
                record: {
                  ...this.state.record,
                  description: value,
                },
              })
            }}
          />
          <TextField
            error={!!this.state.errors.price}
            helperText={this.state.errors.price}
            name="price.price"
            type="number"
            margin="dense"
            label={t(hiddenVAT ? 'Price' : 'Price (ex. VAT)')}
            fullWidth={true}
            value={price === 0 ? '' : +price}
            // tslint:disable-next-line jsx-no-lambda
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = +e.target.value
              let errors = this.state.errors

              if (value > MAXIMUM_ADMITED_VALUE) {
                errors = {
                  ...errors,
                  price: t('Amount too big'),
                }
              } else {
                delete errors.price
              }

              this.setState({
                record: {
                  ...this.state.record,
                  price: value,
                },
                errors,
              })
            }}
          />
        </DialogContent>
        <DialogActions>
          {this.props.record && (
            <SpinnerButton
              className={classNames(classes.warningButton, classes.button)}
              onClick={this.handleDelete}
              showSpinner={deleting}
              variant="contained"
              IconComponent={DeleteIcon}
              IconClasses={classes.iconButton}
            >
              {t('Delete')}
            </SpinnerButton>
          )}
          <SpinnerButton
            className={classes.button}
            onClick={this.handleSave}
            color="secondary"
            showSpinner={saving}
            variant="contained"
            IconComponent={SaveIcon}
            IconClasses={classes.iconButton}
            spinnerClasses={classes.spinnerColor}
            disabled={
              (JSON.stringify(oldRecord) === JSON.stringify(record) &&
                (dateValue && oldDateValue ? dateValue.format('dd-MM-Y') === oldDateValue.format('dd-MM-Y') : false)) ||
              !dateValue ||
              Object.keys(this.state.errors).length !== 0
            }
          >
            {this.props.record ? t('Update') : t('Save')}
          </SpinnerButton>
          <Button
            onClick={this.handleClose}
            disabled={deleting || saving}
            variant="outlined"
            className={classes.button}
          >
            <CloseIcon className={classes.iconButton} />
            {t('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private handleClose = () => {
    this.setState({ record: emptyRecord })
    this.props.onClose()
  }

  private handleSave = async () => {
    let error = true
    const { record, dateValue } = this.state
    if (!dateValue) {
      return
    }
    const updatedRecord = { ...record, date: dateValue.toDate() }

    if (this.props.record && this.state.record.id) {
      this.setState({ saving: true })
      const response = await updateContractWorkshopOperation(this.state.record.id, updatedRecord)
      error = response.data ? false : error
    } else if (this.props.prettyIdentifier) {
      this.setState({ saving: true })
      const response = await postContractWorkshopOperation(this.props.prettyIdentifier, updatedRecord)
      error = response.data ? false : error
    }

    this.setState({ saving: false })

    if (!error) {
      this.props.onSaved()
    }
    this.handleClose()
  }

  private handleDateChange = (value: Moment | null) => {
    this.setState({
      dateValue: value,
    })
  }

  private handleDelete = async () => {
    if (this.state.record && this.state.record.id) {
      this.setState({ deleting: true })
      const response = await deleteContractWorkshopOperation(this.state.record.id)
      this.setState({ deleting: false })
      if (response.data) {
        this.props.onSaved()
      }
    }
  }
}

export default compose<TProps, IOwnProps>(withHiddenVAT, withStyles(styles))(ContractDetailsWorkshopOperationListForm)
