import { Card, CardContent } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatCurrency, formatDate, formatMileage } from '@omnicar/sam-format'
import { IChartDateValue } from '@omnicar/sam-types'
import { IReduxHiddenVATProps, withHiddenVAT } from 'components/ContractDisplayConfig/withHiddenVAT'
import Typography from 'components/Typography'
import React, { SFC } from 'react'
import { compose } from 'recompose'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { getCurrency } from 'utils/localStorage'

interface IOwnProps {
  value: IChartDateValue
  variant: 'mileage' | 'payment'
}

type TProps = IOwnProps & WithStyles<typeof styles> & IReduxHiddenVATProps

const styles = ({ spacing }: Theme) =>
  createStyles({
    tooltip: {
      display: 'flex',
      position: 'relative',
    },
    expectedValueHeading: {
      marginTop: spacing(2),
    },
    expectedValueAmount: {
      marginBottom: spacing(2),
    },
    exVat: {
      textAlign: 'right',
      color: theme.palette.text.light,
      fontStyle: 'italic',
      lineHeight: 1,
      position: 'absolute',
      right: spacing(1),
      bottom: spacing(1),
    },
  })

const Tooltip: SFC<TProps> = ({ classes, value, hiddenVAT, variant }) => {
  return (
    <Card className={classes.tooltip}>
      <CardContent>
        <Typography variant="subheading">{formatDate(value.x)}</Typography>
        <Typography variant="body1" className={classes.expectedValueHeading}>
          {t('Value') + ':'}
        </Typography>

        {variant === 'mileage' ? (
          <Typography variant="title" className={classes.expectedValueAmount}>
            {formatMileage(value.y)}
          </Typography>
        ) : (
          <>
            <Typography variant="title" className={classes.expectedValueAmount}>
              {formatCurrency(value.y, { showDecimals: true })}
            </Typography>
            <Typography className={classes.exVat} variant="body2">
              {`${getCurrency()} ${hiddenVAT ? '' : t('ex. VAT')}`}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default compose<TProps, IOwnProps>(withHiddenVAT, withStyles(styles))(Tooltip)
