import { IContractBalanceStatistics, ISettlementActionResponse } from '@omnicar/sam-types'
import { Hash, ISettlementActionFilters, TSettlementAttachments } from 'types/settlement'

export interface IContractSettlementState {
  balanceStatistics: IContractBalanceStatistics
  settleActions: Hash<ISettlementActionResponse>
  actionFilters: ISettlementActionFilters
  attachments: TSettlementAttachments
}

const initialPriceSpecification = {
  price: 0,
  priceInclVat: 0,
  vatShare: 0,
  currency: '',
}

const initialActionFilters = {
  type: [],
  sharedComments: false,
  unsharedComments: false,
}

export const contractSettlementInitialState: IContractSettlementState = {
  balanceStatistics: {
    calculatedUnits: 0,
    calculationMethod: 100,
    closingDate: new Date().toDateString(),
    monthsAtClosing: 0,
    remainingMonths: 0,
    remainingCharge: initialPriceSpecification,
    workshopCosts: initialPriceSpecification,
    paidInvoicesTotalSum: initialPriceSpecification,
    contractBalance: initialPriceSpecification,
    settlementInvoicesTotalSum: initialPriceSpecification,
    settlementInvoices: {
      cashPayout: initialPriceSpecification,
      stripePayment: initialPriceSpecification,
      cashPayment: initialPriceSpecification,
      providerPayment: initialPriceSpecification,
    },
    settlementInfo: undefined,
    underOverUnitsBalance: undefined,
    pendingInvoicesTotalSum: undefined,
  },
  settleActions: {},
  actionFilters: initialActionFilters,
  attachments: {},
}
