import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    label: {
      display: 'inline-block',
      width: 200,
    },
    linksList: {
      display: 'flex',
      order: -1,
      marginBottom: spacing(2),
      [breakpoints.up('sm')]: {
        order: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    pdfLink: {
      flexBasis: '50%',
      [breakpoints.up('sm')]: {
        flexBasis: '100%',
        marginBottom: spacing(1.5),
      },

      '&:link, &:visited, &:hover, &:active': {
        color: theme.palette.text.regular,
        textDecoration: 'none',
      },
    },
    linkIcon: {
      marginRight: spacing(1.5),
      fontSize: 20,
    },
    linkInner: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    textLightGrey: {
      color: theme.palette.text.light,
    },
  })
