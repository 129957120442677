import { ApiError, ResponseErrors } from '@omnicar/sam-types'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { getNotificationText } from './notify/notify'
import { TranslationItem } from 'translations/translationTypes'

export function errorMessage<T>(res: IJsonStatus<T, ApiError>): TranslationItem {
  if (res.networkError) {
    return {
      key:
        res.networkError === 'TIMEOUT'
          ? 'Timeout looking for available warranties'
          : 'Network error looking for available warranties',
    }
  }

  let errorType: ResponseErrors | null = null
  if (res.errorData) {
    if (res.errorData.message === 'WARRANTY_ALREADY_EXISTS_FOR_VEHICLE') {
      return {
        key:
          'You can not create a contract type or option with warranty because there already exists a warranty on the vehicle',
      }
    } else if (res.errorData.message === 'CONTRACT_ACTIVATION_PREVENTED_BY_SYSTEM_FOR_THIS_VEHICLE') {
      return {
        key: 'System prevents activation of the contract for this vehicle',
      }
    }

    errorType = ((res.errorData as unknown) as ApiError).message
  } else if (res.statusCode && res.statusCode === 499) {
    return { key: 'Vehicle can not be found in our database' }
  } else if (res.statusCode && res.statusCode >= 500) {
    errorType = 'GENERIC_ERROR'
  }

  if (errorType) {
    const errMsg: string = getNotificationText({ errorType })
    return { value: errMsg }
  }

  if (!res.data) {
    return { key: 'No warranty data received from call to server' }
  }
  return undefined
}
