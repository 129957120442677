import { Button, InputAdornment, TextField } from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import { t } from 'translations/translationFunctions'
import history from 'utils/history'
import LogInPage from '../LogInPage'
import './LogInPanel.css'
import { IAdminCustomer, UserRole, UserRoleRecord } from '@omnicar/sam-types'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import { IAdminUserInfo } from '@omnicar/sam-types/types/admin/user/user'
import ActionTypes from 'actions/ActionTypes'
import { applicationUpdate, IApplicationUpdate } from 'actions/applicationActions'
import { Formik } from 'formik'
import { connect, Dispatch } from 'react-redux'
import {
  contractListPath,
  customerContractDetailsPath,
  homePagePath,
  login404Path,
  loginAuthPath,
  loginForgotPath,
  loginPath,
} from 'routes/paths'
import { trackSessionProviderId } from 'utils/analytics'
import { setAuth } from 'utils/localStorage'

interface IFormValues {
  password: string
}

interface IActionProps {
  applicationUpdate: (loggedIn: boolean) => IApplicationUpdate
}

export interface IPasswordValidation {
  valid: boolean
  role?: UserRole
  roles?: UserRoleRecord[]
  stripePublicKey?: string
  providerInfo?: IContractProviderInfo
  userInfo?: IAdminUserInfo
  customerInfo?: IAdminCustomer
  isSuperAdmin?: boolean
  isDev?: boolean
}
interface IParams {
  user?: string
  location: Location
}

interface IState {
  authenticationFail: boolean
  loading: boolean
  user: string
}

type TProps = RouteComponentProps<IParams> & IActionProps

class Authenticate extends React.Component<TProps, IState> {
  private initialFormValues: IFormValues = {
    password: '',
  }

  constructor(props: TProps) {
    super(props)

    const user = props.match.params.user || ''
    this.state = {
      authenticationFail: false,
      loading: false,
      user,
    }
  }

  public render() {
    const { goBack, initialFormValues, handleForgotPassword, handleBack, submit, validate } = this
    const { loading, authenticationFail } = this.state

    return (
      <div className="LogInPanel LogInPanel-Authenticate">
        <CSSTransition classNames="cardSlide" in={!authenticationFail} timeout={220} unmountOnExit={true}>
          <div className="LogInPanel__inner">
            <div className="LogInPanel__header">
              <h2 className="LogInPanel__title">{t('Please enter your password to finalize your log in')}</h2>
            </div>
            <Formik initialValues={initialFormValues} onSubmit={submit} validate={validate}>
              {({ errors, values, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="LogInPanel__main">
                    <TextField
                      autoFocus={true}
                      helperText={touched.password && errors.password && errors.password}
                      error={errors.password && touched.password ? true : false}
                      label={t('Enter your password')}
                      fullWidth={true}
                      name="password"
                      disabled={false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      value={values.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RemoveRedEye />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="LogInPanel__action">
                    <div className="LogInPanel__action-secondary">
                      <Button variant="text" onClick={handleForgotPassword} disabled={loading}>
                        {t('Forgot Password')}
                      </Button>
                    </div>
                    <div className="LogInPanel__action-primary">
                      <Button
                        className="LogInPanel__action-backbutton"
                        color="secondary"
                        variant="contained"
                        onClick={goBack}
                      >
                        {t('Back')}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={() => handleSubmit}
                        disabled={loading}
                      >
                        {t('Next')}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </CSSTransition>
        <CSSTransition in={authenticationFail} timeout={220} classNames="cardSlide" unmountOnExit={true}>
          <div className="LogInPanel__inner">
            <div className="LogInPanel__header">
              <h2 className="LogInPanel__title">{t('Not able to authenticate')}</h2>
            </div>
            <div className="LogInPanel__main" />
            <div className="LogInPanel__action">
              <div className="LogInPanel__action-secondary">
                <Button variant="text" onClick={handleForgotPassword}>
                  {t('Forgot Password')}
                </Button>
              </div>
              <div className="LogInPanel__action-primary">
                <Button variant="contained" color="primary" type="submit" onClick={handleBack}>
                  {t('Back')}
                </Button>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }

  private validate = (values: IFormValues) => {
    const errors: Partial<IFormValues> = {}
    if (values.password.length < 6) {
      errors.password = t('Password must have at least %count characters', { count: 6 })
    }
    return errors
  }

  private submit = (values: IFormValues) => {
    this.setState({ loading: true }, async () => {
      const { user } = this.state
      const { password } = values

      const valid = await LogInPage.validPassword(user, password)

      this.setState({ loading: false })
      if (valid.valid) {
        if (valid.providerInfo) {
          // Track the provider id for this session
          trackSessionProviderId(valid.providerInfo.providerId)
        }
        setAuth(valid)
        if (valid.role === 'customer') {
          const wantedDestination = this.getDestination()
          if (wantedDestination && this.isValidContractIdentifier(wantedDestination)) {
            history.replace(customerContractDetailsPath(wantedDestination))
          } else {
            history.replace(homePagePath)
          }
        } else {
          history.replace(contractListPath)
        }
        // set application state to loggedIn
        this.props.applicationUpdate(true)
      } else {
        history.replace(login404Path(user), {
          message: t('Password is wrong'),
          backLink: loginAuthPath(user),
        })
      }
    })
  }

  private isValidContractIdentifier = (identifier: string) => {
    // Match exact (start of string [digit]{at most and least 3}-[digit]{at most and least 7} end of string)
    const regex = new RegExp('^[0-9]{3,4}-[0-9]{7,7}$')
    return regex.test(identifier)
  }

  private handleForgotPassword = () => {
    const { user } = this.state

    history.replace(loginForgotPath(user))
  }

  private goBack = () => {
    history.replace(loginPath)
  }

  private handleBack = () => {
    const { authenticationFail } = this.state

    if (authenticationFail) {
      this.setState({ authenticationFail: false })
    }
  }

  private getDestination = () => {
    const query = new URLSearchParams(this.props.location.search)
    return query.get('dest') || undefined
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  applicationUpdate: (loggedIn: boolean) => dispatch(applicationUpdate({ loggedIn })),
})

export default connect(undefined, mapDispatchToProps)(Authenticate)
