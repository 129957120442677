import React from 'react'
import { CircularProgress, createStyles, DialogTitle, Theme, withStyles, WithStyles } from '@material-ui/core'
import { LayoutBlock } from 'components/Mui/Layout'
import { t } from 'translations/translationFunctions'

type TProps = WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    lockedDialog: {
      position: 'absolute',
      zIndex: 2,
      background: theme.palette.background.paper,
      margin: 'auto',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      boxShadow:
        '0px 11px 15px -7px rgb(0 0 0 / 20%),0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
      borderRadius: theme.spacing(0.5),
    },
    center: {
      textAlign: 'center',
    },
  })

const SettleTabLockingDialog: React.SFC<TProps> = ({ classes }) => (
  <DialogTitle className={classes.lockedDialog}>
    <LayoutBlock dense>{`${t('Please wait while the payment is being processed')}...`}</LayoutBlock>
    <div>
      <div className={classes.center}>
        <CircularProgress size={32} color="secondary" />
      </div>
    </div>
  </DialogTitle>
)

export default withStyles(styles)(SettleTabLockingDialog)
