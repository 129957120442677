import { IAdminCustomer, IOutageResponse, IsoLocale, UserRole, UserRoleRecord } from '@omnicar/sam-types'
import { IContractProviderInfo } from '@omnicar/sam-types/types/admin/contractProvider'
import { IAdminUserInfo } from '@omnicar/sam-types/types/admin/user/user'
import { createContext } from 'react'
import { LOCALE_CODE_DEFAULT } from 'utils/locale'

export interface IAppContext {
  role: UserRole | undefined
  roles: UserRoleRecord[] | undefined
  stripePublicKey: string | undefined
  providerInfo: IContractProviderInfo | undefined
  userInfo: IAdminUserInfo | undefined
  updateUserRole?: (roleId: number, contractProviderId: number) => void
  isSuperAdmin: boolean
  isDev: boolean
  locale: IsoLocale // Note: Needed explicily deep down in a couple of places (f.ex. so months can be rendered in current locale and not formatted/rendered in what country the provider and fomatting rules are set).
  changeLocaleHandler: any // Note: Needed by LanguageSelector.
  customerInfo?: IAdminCustomer | undefined
  updateCustomerInfo?: (c: IAdminCustomer) => Promise<void>
  outage: IOutageResponse | undefined | null
}

export const appStore: IAppContext = {
  role: undefined,
  roles: undefined,
  stripePublicKey: undefined,
  providerInfo: undefined,
  userInfo: undefined,
  locale: LOCALE_CODE_DEFAULT,
  isSuperAdmin: false,
  isDev: false,
  changeLocaleHandler: undefined,
  outage: undefined,
}

export const AppContext = createContext<IAppContext>(appStore)
