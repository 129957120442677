import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.border.light}`,
      },
      padding: `${spacing(2)}px 0`,
      '&:hover': {
        cursor: 'pointer',
      },
    },

    header: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    template: {
      marginRight: 0,
      marginBottom: spacing(1),
    },

    prettyId: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },

    active: {
      color: theme.palette.context.accept[500],
    },
    pending: {
      color: theme.palette.context.attention[500],
    },
    inactive: {
      color: theme.palette.context.warning[500],
    },
    indicateSelected: {
      borderRight: `${spacing(1)}px solid ${theme.palette.primary[500]}`,
    },
    indentSelected: {
      marginRight: -spacing(1),
    },
    clickableContractCard: {
      backgroundColor: '#e7e7e7',
    },
  })
