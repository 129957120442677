import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: '40%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(3),
      backgroundColor: theme.palette.background.lighter,
    },
    actionBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(3),
    },
    btnIcon: {
      fontSize: '16px',
      marginRight: spacing(0.5),
    },
    btnCancel: {
      color: theme.palette.context.warning[500],
    },
    btnSave: {
      marginLeft: spacing(2),
    },
    heading: {},
    form: {
      padding: `0 ${spacing(3)}px ${spacing(3)}px`,
    },
    input: {
      display: 'block',
      marginTop: spacing(1),
    },
    inputError: {
      color: theme.palette.context.warning[500],
    },
    hr: {
      width: '100%',
      borderColor: theme.palette.border.light[500],
      borderTopWidth: 0,
    },
    buttonGroup: {
      marginTop: spacing(3),
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
    dialogContent: {
      minWidth: '400px',
    },
  })
