import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

interface IOwnProps {
  className?: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    row: {
      display: 'flex',
    },
    topRow: {},
    bottomRow: {
      alignItems: 'bottom',
      height: 56,
      paddingTop: '8px',
    },
  })

const PageHeader: React.SFC<TProps> = ({ children, classes, className }) => {
  const childArray = React.Children.toArray(children)
  const firstChild = childArray.splice(0, 1)

  return (
    <header className={classNames(classes.root, className)}>
      <section className={classNames(classes.row, classes.topRow)}>{firstChild && firstChild}</section>
      {childArray && childArray.length > 0 && (
        <section className={classNames(classes.row, classes.bottomRow)}>{childArray}</section>
      )}
    </header>
  )
}

export default withStyles(styles)(PageHeader)
