export interface IApplicationState {
  language?: string
  transaction?: string
  loggedIn: boolean
}

export const applicationInitialState: IApplicationState = {
  language: 'en-GB',
  transaction: '',
  loggedIn: false,
}
