import * as Sentry from '@sentry/browser'
import { getToken, getAuth } from './localStorage'

export const reportErrors = process.env.REACT_APP_REPORT_ERRORS === '1'

export const initSentry = () => {
  if (reportErrors) {
    Sentry.init({
      dsn: 'https://d8b20565ba1c4d92bdd2d210cc8e82f4@sentry.io/1309780',
      environment: process.env.REACT_APP_ENVIRONMENT,
    })
  }
}

export const captureEvent = (event: Sentry.SentryEvent) => {
  if (reportErrors) {
    Sentry.captureEvent({
      ...event,
      extra: {
        auth: getAuth(),
        token: getToken(),
        ...event.extra,
      },
    })
  }
}

export const captureException = (e: any) => {
  if (reportErrors) {
    Sentry.captureException(e)
  }
}

export const captureMessage = (message: string) => {
  if (reportErrors) {
    Sentry.captureMessage(message)
  }
}

export const reportMissingTranslation = (level: Sentry.Severity) => (message: string) =>
  captureEvent({
    message,
    level,
    extra: {
      auth: getAuth(),
    },
  })

export const reportScriptFailure = (src: string, reason?: string) => {
  const event: Sentry.SentryEvent = {
    level: Sentry.Severity.Error,
    message: `Unable to load script: ${src}`,
  }
  if (reason) {
    event.extra = {
      reason,
    }
  }
  captureEvent(event)
}
