import React from 'react'
import classNames from 'classnames'
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles'

interface IProps {
  className?: string
}

type TProps = IProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-start',
    },
  })

const LayoutActionsLeft: React.SFC<TProps> = ({ classes, className, children }) => (
  <section className={classNames(classes.root, className)}>{children && children}</section>
)

export default withStyles(styles)(LayoutActionsLeft)
