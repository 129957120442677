import { Input, Button, FormControlLabel, Switch, InputAdornment } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons'
import { t, translateItem } from 'translations/translationFunctions'
import {
  IPaginatedQueryParams,
  IUserListRecord,
  INullableRole,
  UserListOrderByType,
  UserRole,
  UserRoleRecord,
} from '@omnicar/sam-types'
import { getUsers, getUserRoles } from 'api/api'
import UserForm from 'components/admin/User/Form'
import Typography from 'components/Typography'
import { Panel, PanelActions, PanelContent, PanelHeader } from 'components/Mui/Panel'
import { LayoutRow, LayoutColumn } from 'components/Mui/Layout'
import React, { ChangeEvent } from 'react'
import ReactTable, { Column, SortingRule } from 'react-table'
import { createPanelStyles } from 'theme'
import { createQueryFromTable, createTableStateFromPagination, ITableState, defaultTableState } from 'utils/react-table'
import { getAuth } from 'utils/localStorage'
import { formatPhone, formatRoleName } from '@omnicar/sam-format'
import Paper from 'components/Mui/Paper'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import { ValueType } from 'react-select'
import debounce from 'lodash.debounce'
import { TranslationItem, TranslationKey } from 'translations/translationTypes'
import { renderContractProviderRow } from 'utils/react-select'
import { theme as customTheme } from 'theme'
import StyledSelect from 'components/StyledSelect'
import { Search as SearchIcon } from '@material-ui/icons'

type TProps = WithStyles<typeof styles> & { hidden: boolean }

type TRole = INullableRole['role']

export interface IRoleRecordOption {
  value: UserRoleRecord
  label?: string
  labelItem: TranslationItem
  inactive: boolean
}

interface IAllProvidersOption {
  value: null
  label?: string
  labelItem: TranslationItem
  inactive: boolean
}

export interface IUserRoleMap {
  [providerId: number]: UserRole
}

export type IContractProviderOption = IRoleRecordOption | IAllProvidersOption

interface IState {
  currentUserRole: UserRole | null
  currentUserRolesMap: IUserRoleMap
  data: IUserListRecord[]
  form: boolean
  record: IUserListRecord | undefined
  loading: boolean
  tableColumns: Column[]
  tableState: ITableState
  tableQuery: IPaginatedQueryParams<UserListOrderByType>
  commonUserRoles: UserRoleRecord[]
  allProvidersOption: IContractProviderOption
  contractProviderOptions: IContractProviderOption[]
  activeContractProviderOption: IContractProviderOption
  searchQuery: string
  includeInactiveProviders: boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    grayedOut: {
      backgroundColor: 'rgba(0,0,0,0.2)',
      fontStyle: 'italic',
      padding: '5px',
      borderRadius: '5px',
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    filterButton: {
      fontWeight: 'normal',
      color: customTheme.palette.context.attention[500],
      border: '1px solid',
      borderColor: customTheme.palette.context.attention[500],
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '2px',
      marginTop: '10px',
      marginBottom: '3px',
    },
    filterButtonClicked: {
      color: 'white',
      backgroundColor: customTheme.palette.context.attention[500],
    },
    tableControlsLabel: { marginBottom: theme.spacing(1) },
    tableControls: { marginBottom: theme.spacing(2) },
    headerTitle: { marginLeft: theme.spacing(-2) },
    searchIcon: {
      color: theme.palette.primary.dark,
    },
  })

const headerKeys: { [key: string]: TranslationKey } = {
  name: 'User name',
  providerId: 'Provider ID',
  providerName: 'Provider Name',
  role: 'Role',
  email: 'Email',
  phone: 'Phone number',
  departmentName: 'Department',
}

const headerText = (data: any): string => {
  if (data && data.column && data.column.id && typeof data.column.id === 'string') {
    return t(headerKeys[data.column.id])
  } else {
    return ''
  }
}

export class UserList extends React.Component<TProps, IState> {
  public static getColumns = (includeProviderInfo: boolean): Column[] => {
    const primaryColumns = [
      {
        Header: headerText,
        accessor: 'name',
        headerClassName: 'e2e__header__name',
      },
      {
        Header: headerText,
        accessor: 'role',
        headerClassName: 'e2e__header__address',
        Cell: ({ value }: { value: TRole }) => <div>{formatRoleName(value)}</div>,
      },
      {
        Header: headerText,
        accessor: 'email',
        headerClassName: 'e2e__header__email',
      },
      {
        Header: headerText,
        accessor: 'phone',
        headerClassName: 'e2e__header__phone',
        Cell: ({ value }: { value: string }) => <div>{value && formatPhone(value)}</div>,
      },
      {
        Header: headerText,
        accessor: 'departmentName',
        headerClassName: 'e2e__header__departmentName',
      },
    ]
    const providerNameColumn = {
      Header: headerText,
      accessor: 'providerName',
      headerClassName: 'e2e__header__email',
    }
    const providerIdColumn = {
      Header: headerText,
      accessor: 'providerId',
      headerClassName: 'e2e__header__email',
    }

    return includeProviderInfo ? primaryColumns.concat(providerNameColumn).concat(providerIdColumn) : primaryColumns
  }

  constructor(props: TProps) {
    super(props)
    const authData = getAuth()
    const rawContractProviderOptions: IRoleRecordOption[] =
      !authData || !authData.roles
        ? []
        : authData.roles.map((r) => ({
            value: r,
            labelItem: { value: `${r.contractProvider.id} - ${r.contractProvider.administrativeName}` },
            inactive: !!r.contractProvider.inactive,
          }))
    const allProvidersOption = { value: null, labelItem: { key: 'All providers' } } as IContractProviderOption
    const contractProviderOptions = [allProvidersOption].concat(rawContractProviderOptions)

    const currentUserRole = (authData && authData.role) || null
    const currentUserRolesMap: IUserRoleMap =
      (authData &&
        authData.roles &&
        authData.roles.reduce(
          (acc, r) => ({
            ...acc,
            [r.contractProvider.id]: r.role,
          }),
          {},
        )) ||
      {}

    this.state = {
      currentUserRole,
      currentUserRolesMap,
      data: [],
      loading: false,
      form: false,
      record: undefined,
      tableColumns: UserList.getColumns(true),
      tableState: {
        ...defaultTableState(10),
        sorted: [{ desc: true, id: 'name' }],
        resized: [],
      },
      commonUserRoles: [],
      allProvidersOption,
      contractProviderOptions,
      activeContractProviderOption: allProvidersOption,
      tableQuery: {
        pagination: {
          limit: 10,
          offset: 0,
          orderBy: 'name',
          orderDirection: 'DESC',
        },
      },
      searchQuery: '',
      includeInactiveProviders: false,
    }
  }

  public componentDidMount() {
    this.loadData()
  }

  public render() {
    const {
      data,
      tableColumns,
      loading,
      tableState,
      form,
      allProvidersOption,
      contractProviderOptions,
      activeContractProviderOption,
      searchQuery,
      commonUserRoles,
      currentUserRole,
      includeInactiveProviders,
    } = this.state
    const { classes, hidden } = this.props
    const allowCreateUpdate = currentUserRole! === 'admin'

    const filteredProviderOptions = includeInactiveProviders
      ? contractProviderOptions
      : contractProviderOptions.filter((c) => !c.inactive)

    const contractProviderOptionsWithTranslations = filteredProviderOptions.map((option) => {
      if (option.label === allProvidersOption.label) {
        option.label = translateItem(option.labelItem)
      } else {
        option.label = option.labelItem && option.labelItem.value
      }

      return option
    })

    return (
      <Panel hidden={hidden}>
        <PanelHeader>
          <Typography className={classes.headerTitle} variant="subheading">
            {t('Users')}
          </Typography>
          {allowCreateUpdate && (
            <PanelActions>
              <Button
                className={classes.panelActionsButton}
                onClick={this.handleAddUserClick}
                size="small"
                variant="outlined"
              >
                <AddCircleOutlineIcon className={classes.panelActionsButtonIcon} />
                {t('Add User')}
              </Button>
            </PanelActions>
          )}
        </PanelHeader>
        <PanelContent>
          <LayoutRow columns={2} className={classes.tableControls}>
            <LayoutColumn>
              <div className={classes.flexBox}>
                <Typography variant="body" className={classes.tableControlsLabel}>
                  {t('Show users for provider')}:
                </Typography>
                <span>
                  <FormControlLabel
                    control={<Switch checked={includeInactiveProviders} />}
                    label={t('Include deactivated')}
                    onChange={this.handleFilterChanged}
                  />
                </span>
              </div>
              <StyledSelect
                options={contractProviderOptionsWithTranslations}
                // tslint:disable-next-line jsx-no-lambda
                onChange={(value: ValueType<IContractProviderOption>) =>
                  this.handleProviderChange(value as IContractProviderOption)
                }
                value={activeContractProviderOption}
                formatOptionLabel={({ label, inactive }) =>
                  renderContractProviderRow(label, inactive, classes.grayedOut)
                }
              />
            </LayoutColumn>
            <LayoutColumn>
              <Typography variant="body">{t('Filter on name/email')}:</Typography>
              <Input
                className={classes.inputField}
                fullWidth={true}
                type="text"
                placeholder={t('E.g. John Doe')}
                value={searchQuery}
                onChange={this.handleSearchQueryChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
              />
            </LayoutColumn>
          </LayoutRow>
          <Paper>
            <ReactTable
              className="react-table react-table--clickable"
              columns={tableColumns}
              data={data}
              defaultPageSize={10}
              manual={true}
              minRows={3}
              defaultSorted={tableState.sorted}
              loading={loading}
              LoadingComponent={TableLoadingIndicator}
              noDataText={!loading ? t('No Results Found') : ''}
              pageSize={tableState.pageSize}
              page={tableState.page}
              pages={tableState.pages}
              showPaginationBottom={data.length > 0}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              // tslint:disable-next-line jsx-no-lambda
              getTrProps={(state: any, rowInfo: any) => {
                const tableRecord = rowInfo && rowInfo.original

                return tableRecord && allowCreateUpdate ? { onClick: () => this.handleRowClick(tableRecord) } : {}
              }}
              pageText={t('Page')}
              ofText={t('of')}
              rowsText={t('rows')}
            />
          </Paper>
          <UserForm
            open={form}
            commonUserRoles={commonUserRoles}
            onClose={this.handleUserClose}
            record={this.state.record}
          />
        </PanelContent>
      </Panel>
    )
  }

  private handlePageChange = (page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleFilterChanged = () => {
    const { includeInactiveProviders } = this.state
    this.setState({ includeInactiveProviders: !includeInactiveProviders })
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page, pageSize } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState: ITableState = { ...this.state.tableState, sorted }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleRowClick = async (data: IUserListRecord) =>
    this.setState({
      record: data,
      form: true,
      commonUserRoles: (data.userId && (await getUserRoles(data.userId).then(({ data }) => data))) || [],
    })

  private handleAddUserClick = () => {
    this.setState({
      record: undefined,
      form: true,
      commonUserRoles: [],
    })
  }

  private handleUserClose = (update: boolean) => {
    this.setState({
      record: undefined,
      form: false,
    })
    if (update) {
      this.loadData()
    }
  }

  private loadData = async () => {
    const { loading, tableQuery, searchQuery, activeContractProviderOption } = this.state

    if (!loading && activeContractProviderOption) {
      this.setState({ loading: true })

      const response = await getUsers(
        tableQuery,
        searchQuery,
        activeContractProviderOption.value && activeContractProviderOption.value.contractProvider.id,
      )

      if (response.data) {
        const { result, pagination } = response.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }

        this.setState({ loading: false, data: result, tableState })
      } else {
        this.setState({ loading: false })
      }
    }
  }

  // tslint:disable-next-line member-ordering
  private debouncedLoadData = debounce(this.loadData, 300)

  private handleProviderChange = (option: IContractProviderOption) =>
    this.setState(
      {
        activeContractProviderOption: option as IContractProviderOption,
        tableColumns: UserList.getColumns(option.value === null),
      },
      this.loadData,
    )
  private handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState({ searchQuery: event.target.value }, this.debouncedLoadData)
}

export default withStyles(styles)(UserList)
