import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import { CustomerListOrderByType, IAdminCustomer, IPaginatedQueryParams } from '@omnicar/sam-types'
import * as api from 'api/api'
import React, { ChangeEvent } from 'react'
import ReactTable, { Column, SortingRule } from 'react-table'
import 'react-table/react-table.css'
import { customerDetailsPath } from 'routes/paths'
import browserHistory from 'utils/history'
import { createQueryFromTable, createTableStateFromPagination, ITableState, defaultTableState } from 'utils/react-table'
import { formatPhone } from '@omnicar/sam-format'
import Paper from 'components/Mui/Paper'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import { palette } from 'theme'
import { LayoutActions, LayoutContent, LayoutActionsRight } from '../../../Mui/Layout'
import { FormControl, Input, InputAdornment, InputLabel, CircularProgress } from '@material-ui/core'
import { Clear as ClearIcon, Search as SearchIcon, CheckCircleOutlined as ApprovedIcon } from '@material-ui/icons'
import { getCustomerFromEmail } from 'api/api'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import SendMailButton from './SendMailButton'
import { TranslationKey } from 'translations/translationTypes'
import { theme as customTheme } from 'theme'
import BounceIcon from 'components/Mui/BounceIcon'
import { AppContext } from 'store/appContext'

interface IState {
  customers: IAdminCustomer[]
  loading: boolean
  tableColumns: Column[]
  tableQuery: IPaginatedQueryParams<CustomerListOrderByType>
  tableState: ITableState
  hasPerformedSearch: boolean
  searchQuery: string
  searching: boolean
  sendingEmail: boolean
}

const styles = (theme: Theme) =>
  createStyles({
    alignCenter: {
      textAlign: 'center',
    },
    colorLight: {
      color: palette.text.light,
    },
    icon: {
      color: theme.palette.primary.dark,
    },
    clearIcon: {
      cursor: 'pointer',
    },
    search: {
      color: theme.palette.primary.contrastText,
      width: 450,
    },
    bouncedEmail: {
      verticalAlign: 'middle',
    },
    validEmail: {
      color: customTheme.palette.context.accept[500],
    },
    textAlignCenter: {
      textAlign: 'center',
    },
  })

const headerKeys: { [key: string]: TranslationKey } = {
  prettyIdentifier: 'Id',
  name: 'Customer name',
  address: 'Address',
  city: 'City',
  zip: 'Zipcode',
  phone: 'Phone number',
  email: 'Email',
  emailBounceType: 'Valid email address',
  MyPage: 'MyPage',
}

const headerText = (data: any): string => {
  if (data && data.column && data.column.id && typeof data.column.id === 'string') {
    return t(headerKeys[data.column.id])
  } else {
    return ''
  }
}

class CustomerList extends React.Component<WithStyles<typeof styles>, IState> {
  public columns: Column[] = [
    {
      Header: headerText,
      accessor: 'prettyIdentifier',
      headerClassName: 'e2e__header__prettyIdentifier',
      Cell: ({ value }: { value: string }) => <div className="prettyIdentifier">{value && value.split('-').pop()}</div>,
    },
    {
      Header: headerText,
      accessor: 'name',
      headerClassName: 'e2e__header__name',
      Cell: ({ original }: { original: IAdminCustomer }) => {
        return (
          <div className="customerName">
            {original.customerType === 'BUSINESS' && original.companyName ? original.companyName : original.name}
          </div>
        )
      },
    },
    {
      Header: headerText,
      accessor: 'address',
      headerClassName: 'e2e__header__address',
    },
    {
      Header: headerText,
      accessor: 'zip',
      headerClassName: 'e2e__header__zipcode',
    },
    {
      Header: headerText,
      accessor: 'city',
      headerClassName: 'e2e__header__city',
    },
    {
      Header: headerText,
      accessor: 'phone',
      headerClassName: 'e2e__header__phone',
      Cell: ({ value }: { value: string }) => <div>{value && formatPhone(value)}</div>,
    },
    {
      Header: headerText,
      accessor: 'email',
      headerClassName: 'e2e__header__email',
    },
    {
      Header: headerText,
      accessor: 'emailBounceType',
      headerClassName: 'e2e__header__email',
      headerStyle: { textAlign: 'center' },
      Cell: ({ original }: { original: IAdminCustomer }) => {
        return (
          <div className={this.props.classes.textAlignCenter}>
            {original.emailBounceType ? (
              <span>
                <BounceIcon
                  className={this.props.classes.bouncedEmail}
                  bounceType={original.emailBounceType}
                  displayTooltip={true}
                  overrideColor={true}
                />
              </span>
            ) : (
              <ApprovedIcon className={this.props.classes.validEmail} />
            )}
          </div>
        )
      },
    },
    {
      Header: headerText,
      accessor: 'MyPage',
      headerClassName: `e2e__header__MyPage`,
      headerStyle: { textAlign: 'center' },
      Cell: ({ original }: { original: IAdminCustomer }) => {
        return (
          <AppContext.Consumer>
            {({ role }) => <SendMailButton disabled={role === 'observer'} value={original.prettyIdentifier} />}
          </AppContext.Consumer>
        )
      },
    },
  ]

  public state: IState = {
    customers: [],
    loading: false,
    tableColumns: this.columns,
    tableState: {
      ...defaultTableState(10),
      sorted: [{ desc: true, id: 'prettyIdentifier' }],
      resized: [],
    },
    tableQuery: {
      pagination: {
        limit: 10,
        offset: 0,
        orderBy: 'prettyIdentifier',
        orderDirection: 'DESC',
      },
    },
    searchQuery: '',
    searching: false,
    hasPerformedSearch: false,
    sendingEmail: false,
  }

  public componentDidMount() {
    this.loadData()
    this.searchCustomers = debounce(this.searchCustomers, 500)
  }

  public render() {
    const { customers, tableColumns, loading, tableState, searching, searchQuery } = this.state
    const { classes } = this.props

    return (
      <Paper>
        <LayoutContent>
          <LayoutActions>
            <LayoutActionsRight>
              <FormControl margin="dense" className={classNames(classes.search)}>
                <InputLabel htmlFor="customer-search">{t('Search for customer')}</InputLabel>
                <Input
                  id="customer-search" // tslint:disable-next-line jsx-no-lambda
                  onChange={this.inputChanged}
                  value={this.state.searchQuery}
                  startAdornment={
                    <InputAdornment position="start">
                      <>
                        {searching && <CircularProgress /*className={classes.progress}*/ color="secondary" size={20} />}
                        {searchQuery ? (
                          <ClearIcon
                            // tslint:disable-next-line jsx-no-lambda
                            onClick={() => {
                              this.handleLookupClear()
                            }}
                            className={classes.clearIcon}
                          />
                        ) : (
                          <SearchIcon className={classes.icon} />
                        )}
                      </>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </LayoutActionsRight>
          </LayoutActions>
        </LayoutContent>
        <ReactTable
          className="react-table react-table--clickable"
          columns={tableColumns}
          data={customers}
          defaultPageSize={10}
          manual={true}
          defaultSorted={tableState.sorted}
          loading={loading}
          LoadingComponent={TableLoadingIndicator}
          noDataText={!loading ? t('No Results Found') : ''}
          pageSize={tableState.pageSize}
          page={tableState.page}
          pages={tableState.pages}
          showPaginationBottom={customers.length > 0}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortChange}
          minRows={3}
          // tslint:disable-next-line jsx-no-lambda
          getTrProps={(state: any, rowInfo: any) => {
            return {
              style: { alignItems: 'center' },
              onClick: () => {
                this.handleRowClick(rowInfo.row)
              },
            }
          }}
          pageText={t('Page')}
          ofText={t('of')}
          rowsText={t('rows')}
        />
      </Paper>
    )
  }

  private handlePageChange = (page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page, pageSize } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState: ITableState = { ...this.state.tableState, sorted }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleRowClick = (data: IAdminCustomer) => {
    browserHistory.push(customerDetailsPath(data.prettyIdentifier))
  }

  private inputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    const { searchQuery } = this.state
    if (searchQuery !== value) {
      this.setState({ searchQuery: value }, () => {
        if (value.length > 0) {
          this.searchCustomers(value)
        } else if (value.length === 0) {
          this.loadData()
        }
      })
    }
  }

  private searchCustomers = (query: string) => {
    // In case a new search has started, don't continue
    if (this.state.searchQuery !== query) {
      return
    }
    this.setState({ searching: true }, async () => {
      const result = await getCustomerFromEmail(query)
      // In case a new search has started, don't continue
      if (this.state.searchQuery !== query) {
        return
      }
      const newState: IState = { ...this.state, searching: false }
      if (result.data) {
        newState.hasPerformedSearch = true
        newState.customers = result.data.result
      }
      this.setState(newState)
    })
  }

  private handleLookupClear = () => {
    this.loadData()
    this.setState({ searchQuery: '', hasPerformedSearch: false, searching: false })
  }

  private loadData = async () => {
    const { loading, tableQuery } = this.state

    if (!loading) {
      this.setState({ loading: true })

      const response = await api.getCustomers(tableQuery)

      if (response.data) {
        const { result, pagination } = response.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }

        this.setState({ loading: false, customers: result, tableState })
      } else {
        this.setState({ loading: false })
      }
    }
  }
}

export default withStyles(styles)(CustomerList)
