import React, { SFC } from 'react'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import Typography from 'components/Typography'
import classNames from 'classnames'
import styles from './styles'
import { IGenericTypeWithContracts } from 'utils/genericProduct'

interface IProps extends WithStyles<typeof styles> {
  vehicle: IGenericTypeWithContracts
  title?: string | undefined
  subTitles?: string[] | undefined
}

const CustomerPortalContractDetailsVehicleListItemHeader: SFC<IProps> = ({ classes, vehicle, title, subTitles }) => (
  <>
    <Typography variant="title" className={classNames(classes.lineHeight, classes.contentPadding)}>
      {title || vehicle.identifier1}
    </Typography>
    {!!subTitles &&
      subTitles.map((t, i) => (
        <Typography variant="subtitle" className={classes.contentPadding} key={`ContractListItemHeader-${t}_${i}`}>
          {t}
        </Typography>
      ))}
  </>
)

export default withStyles(styles)(CustomerPortalContractDetailsVehicleListItemHeader)
