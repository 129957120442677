import { WithTracker } from 'components/Tracker/Tracker'
import AdministrationPage from 'pages/admin/AdministrationPage'
import ContractDetailsPage from 'pages/admin/ContractDetailsPage'
import ContractFlowPage from 'pages/admin/ContractFlowPage'
import ContractListPage, { ProductContractListPage } from 'pages/admin/ContractListPage'
import CustomerDetailsPage from 'pages/admin/CustomerDetailsPage'
import CustomerListPage from 'pages/admin/CustomerListPage'
import OperationStatusPage from 'pages/admin/OperationStatusPage'
import OverviewPage from 'pages/admin/OverviewPage'
import ReleaseNotesPage from 'pages/admin/ReleaseNotesPage'
import Error404Page from 'pages/Error404Page/Error404Page'
import StripePaymentPage from 'pages/payment/StripePaymentPage'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  administrationPath,
  contractDetailsPath,
  contractFlowPath,
  contractListPath,
  customerDetailsPath,
  customerListPath,
  homePagePath,
  operationStatusPath,
  overviewPagePath,
  productContractListPath,
  releaseNotesPath,
  reportContractDetailsPath,
  stripePaymentPath,
} from 'routes/paths'

interface IOwnProps {
  isDev: boolean | undefined
}

const AppContent: React.SFC<IOwnProps> = ({ isDev }) => (
  <div className="AppContent">
    <Switch>
      <Route exact={true} path={homePagePath} render={WithTracker(ContractListPage)} />
      <Route exact={true} path={overviewPagePath} render={WithTracker(OverviewPage)} />
      <Route exact={true} path={contractListPath} render={WithTracker(ContractListPage)} />
      <Route exact={true} path={productContractListPath} render={WithTracker(ProductContractListPage)} />
      <Route exact={false} path={contractDetailsPath()} render={WithTracker(ContractDetailsPage)} />
      <Route exact={false} path={reportContractDetailsPath()} render={WithTracker(ContractDetailsPage)} />
      <Route exact={false} path={contractFlowPath({ offerOnly: false })} render={WithTracker(ContractFlowPage)} />
      <Route exact={true} path={customerListPath} render={WithTracker(CustomerListPage)} />
      <Route exact={true} path={customerDetailsPath()} render={WithTracker(CustomerDetailsPage)} />
      <Route exact={true} path={administrationPath} render={WithTracker(AdministrationPage)} />
      <Route exact={true} path={stripePaymentPath()} render={WithTracker(StripePaymentPage)} />
      <Route exact={true} path={releaseNotesPath} render={WithTracker(ReleaseNotesPage)} />
      {isDev && <Route exact={true} path={operationStatusPath} render={WithTracker(OperationStatusPage)} />}
      <Route path={'*'} render={WithTracker(Error404Page)} />
    </Switch>
  </div>
)

export default AppContent
