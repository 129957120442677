/**
 * Get file extension
 * '.xlsx' and '.xls' should both return '.xls'
 */
export const getFileType = (path: string) => {
  const type = path.substr(path.lastIndexOf('.') + 1)

  switch (type) {
    case 'xlsx':
      return 'xls'
    default:
      return type
  }
}
