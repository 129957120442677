import ActionTypeKeys from './ActionTypeKeys'

export interface IStoreReset {
  readonly type: ActionTypeKeys.STORE_RESET
}

export function storeReset(): IStoreReset {
  return {
    type: ActionTypeKeys.STORE_RESET,
  }
}
